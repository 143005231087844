import React, { useEffect, useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import EditReportCommentForm from '../../forms/client-profile-forms/EditReportCommentForm';
import { updateReportComment } from '../../../api/clients';
import AppToast from '../../Toast';

const EditReportCommentDialogBox = ({
  isOpen,
  handleClose,
  selectedReport,
  fetchReports,
  clientId,
}) => {
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (selectedReport) {
      setComment(selectedReport.comment || '');
    }
  }, [selectedReport]);

  const handleEdit = (report) => {
    updateReportComment(selectedReport.reportId, report)
      .then((res) => {
        AppToast.show({ message: res.data.msg, intent: Intent.SUCCESS });
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to edit report.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchReports(clientId);
        handleClose();
      });
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={'Edit Report'}
        isCloseButtonShown={false}
      >
        <EditReportCommentForm
          comment={comment}
          closeDialog={handleClose}
          handleEdit={handleEdit}
        />
      </Dialog>
    </>
  );
};

export default EditReportCommentDialogBox;
