import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { fetchTransactionKeywords } from '../../../../../../actions/transactions';
import {
  deleteTransaction,
  updateTransaction,
  bulkUpdateTransactions,
  fetchPaginatedData,
} from '../../../../../../api/transactions';
import TransactionTable from '../../../../../../components/table/TransactionTable';
import TransactionDeleteDialogBox from '../../../../../../components/dialog/transactions/TransactionDeleteDialogBox';
import AppToast from '../../../../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import TransactionEditDialogBox from '../../../../../../components/dialog/transactions/TransactionEditDialogBox';
import TransactionBulkEditDialogBox from '../../../../../../components/dialog/transactions/TransactionBulkEditDialogBox';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import axios from 'axios';
import { downloadFile } from '../../../../../../api/data-ingestion';
import { downloadFiles } from '../../../../../../utils/functions';
import DownloadDialog from '../../../../../../components/dialog/DownloadDialogBox';

const AccountTransactionsBase = ({
  appUser,
  accountId,
  fetchTransactionKeywords,
  keywords,
  isServerSidePagination,
  ...props
}) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenBulkEditDialog, setIsOpenBulkEditDialog] = useState(false);
  const [isOpenDownloadDialog, setIsOpenDownloadDialog] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [keyword, setKeyword] = useState('');
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [downloadAmount, setDownloadAmount] = useState(0);
  const [downloadError, setDownloadError] = useState(false);
  const [downloadErrorAmount, setDownloadErrorAmount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  // pagination info
  // pageSize and pageIndex used to refetch data after edit/delete
  const [pageSize] = useState(50);
  const [pageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(undefined);
  const [resultCount, setResultCount] = useState(undefined);
  const [transactions, setTransactions] = useState([]);
  const [isPaginatedDataFetching, setIsPaginatedDataFetching] = useState(false);

  useEffect(() => {
    fetchTransactionKeywords();
  }, [fetchTransactionKeywords, accountId]);

  // for server side pagination. only call this function if needed so use hook useCallback
  // filter param is optional, if not sent defaults to empty array
  const updatePaginate = useCallback(
    (
      { pageSize, pageIndex },
      filters = [],
      searchTerm = '',
      sortOrder = {}
    ) => {
      setIsPaginatedDataFetching(true);
      fetchPaginatedData(
        accountId,
        pageSize,
        pageIndex,
        filters,
        searchTerm,
        sortOrder
      )
        .then((res) => {
          setTransactions(res.data.results);
          setPageCount(res.data.pageCount);
          setResultCount(res.data.resultCount);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsPaginatedDataFetching(false));
    },
    [accountId]
  );

  const handleDeleteDialogOpen = (transaction) => {
    // if transaction comes in as array set the selected transactions
    // or set the single transaction
    transaction instanceof Array
      ? setSelectedTransactions(transaction)
      : setTransaction(transaction);
    setIsOpenDeleteDialog(true);
  };

  const handleEditDialogOpen = (transaction) => {
    setTransaction(transaction);
    setKeyword(find(keywords, ['label', transaction.keyword]).value);
    setIsOpenEditDialog(true);
  };

  const handleBulkEditDialogOpen = (transactions) => {
    setSelectedTransactions(transactions);
    setIsOpenBulkEditDialog(true);
  };

  const handleBulkEditDialogClose = () => {
    setKeyword('');
    setIsOpenBulkEditDialog(false);
  };

  const handleDeleteDialogClose = () => {
    setTransaction({});
    setSelectedTransactions([]);
    setIsOpenDeleteDialog(false);
    setIsDeleting(false);
  };

  const handleDownloadDialogClose = () => {
    setIsOpenDownloadDialog(false);
    setDownloadError(false);
  };

  const handleCloseEdit = () => {
    setTransaction({});
    setKeyword('');
    setIsOpenEditDialog(false);
  };

  const selectKeywordFromList = (keyword) => {
    setKeyword(keyword.value);
  };

  const handleEditTransaction = async ({
    transactionId,
    amount,
    keyword,
    transactionDescription,
    transactionDate,
  }) => {
    const transaction = {
      transactionId,
      amount,
      transactionDescription,
      keyword,
      transactionDate,
    };
    updateTransaction(transaction)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit transaction.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleCloseEdit());
  };

  const handleBulkEditTransaction = ({
    amount,
    transactionDescription,
    transactionDate,
    keyword,
  }) => {
    const transactionIds = selectedTransactions.map(
      (transaction) => transaction.transactionId
    );
    const transaction = {
      amount,
      keyword,
      transactionDate,
      transactionDescription,
      transactionIds,
    };
    bulkUpdateTransactions(transaction)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to edit transaction.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleBulkEditDialogClose());
  };

  const handleDelete = (transactionItem) => {
    // if selected from table rows, use the select items array
    // otherwise use the transaction items (singular object) from dialog component

    // if items selected from row selector, enter if statement
    if (selectedTransactions.length > 0) {
      const transactionId = selectedTransactions.map(
        (transaction) => transaction.transactionId
      );
      const transactionBody = {
        transactionId,
      };
      setIsDeleting(true);
      deleteTransaction(transactionBody)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
          updatePaginate({ pageSize, pageIndex });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to delete transaction.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          handleDeleteDialogClose();
        });
    } else {
      // if single item selected from table, not row select, use transactionItem
      const transactionId = [transactionItem.transactionId];
      const transactionBody = {
        transactionId,
      };
      setIsDeleting(true);
      deleteTransaction(transactionBody)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
          updatePaginate({ pageSize, pageIndex });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to delete transaction.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          handleDeleteDialogClose();
        });
    }
  };

  const handleDownloadStatement = async (transaction) => {
    const fileId = transaction.fileId;
    if (fileId === 1) {
      AppToast.show({
        message: 'No file associated with this transaction.',
        intent: Intent.DANGER,
      });
      return;
    } else {
      // call api to get presigned url
      const presignedURL = await downloadFile(fileId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => console.error(err));

      delete axios.defaults.headers.common['Authorization'];

      const name = presignedURL.substring(
        presignedURL.indexOf('Transaction/'),
        presignedURL.indexOf('?')
      );

      const fileName = name;
      // Initiating the GET request to upload file
      axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => {
          // call download from utils function
          downloadFiles(res, fileName);
          AppToast.show({
            message: 'File downloaded successfully!',
            intent: Intent.SUCCESS,
          });
        })
        .catch(() => {
          AppToast.show({
            message: 'Failed to download file.',
            intent: Intent.DANGER,
          });
        });
    }
  };

  const handleBulkDownload = async (transactionList) => {
    // need to get these counts for the amount of downloads succeeded or failed
    let counter = 0;
    let counterError = 0;
    const countSuccess = () => {
      counter++;
    };
    const countError = () => {
      counterError++;
    };
    // remove duplicate file names to download only one file
    // and remove any items from list that have empty file names (value 1 maps to empty)
    const list = uniqBy(transactionList, 'fileId').filter(
      (item) => item.fileId !== 1
    );

    for (const file of list) {
      // call api to get presigned url
      const presignedURL = await downloadFile(file.fileId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
        });

      delete axios.defaults.headers.common['Authorization'];

      const name = presignedURL.substring(
        presignedURL.indexOf('Transaction/'),
        presignedURL.indexOf('?')
      );
      const fileName = name;
      // Initiating the GET request to download file
      await axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => {
          // call download from utils function
          downloadFiles(res, fileName);
          countSuccess();
        })
        .catch((err) => {
          setDownloadError(true);
          countError();
        });
    }
    setDownloadAmount(counter);
    setDownloadErrorAmount(counterError);
    setIsOpenDownloadDialog(true);
  };

  return (
    <>
      <TransactionTable
        transactions={transactions}
        isFetching={isPaginatedDataFetching}
        appUser={appUser}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        handleEditDialogOpen={handleEditDialogOpen}
        handleBulkEditDialogOpen={handleBulkEditDialogOpen}
        handleDownloadStatement={handleDownloadStatement}
        handleBulkDownload={handleBulkDownload}
        isServerSidePagination={isServerSidePagination}
        updatePaginate={updatePaginate}
        resultCount={resultCount}
        pageCount={pageCount}
        {...props}
      />
      <TransactionDeleteDialogBox
        isOpen={isOpenDeleteDialog}
        title={
          // if transaction is not an object use bulk title
          Object.keys(transaction).length > 0
            ? 'Delete Transaction'
            : `Delete Selected Transactions (${selectedTransactions.length})`
        }
        handleClose={handleDeleteDialogClose}
        handleDelete={handleDelete}
        transaction={transaction}
        isDeleting={isDeleting}
      />
      <TransactionEditDialogBox
        isOpen={isOpenEditDialog}
        title='Edit Transaction Information'
        handleClose={handleCloseEdit}
        transaction={transaction}
        keywords={keywords}
        keyword={keyword}
        selectKeywordFromList={selectKeywordFromList}
        handleEditTransaction={handleEditTransaction}
      />
      <TransactionBulkEditDialogBox
        title={`Edit Selected Transactions (${selectedTransactions.length})`}
        isOpen={isOpenBulkEditDialog}
        handleClose={handleBulkEditDialogClose}
        keywords={keywords}
        keyword={keyword}
        selectKeywordFromList={selectKeywordFromList}
        handleBulkEditTransaction={handleBulkEditTransaction}
      />
      <DownloadDialog
        isOpen={isOpenDownloadDialog}
        handleDownloadDialogClose={handleDownloadDialogClose}
        downloadAmount={downloadAmount}
        downloadErrorAmount={downloadErrorAmount}
        downloadError={downloadError}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  appUser: state.auth.appUser,
  keywords: state.transactions.allTransactionKeywords,
});

const AccountTransactions = connect(mapStateToProps, {
  fetchTransactionKeywords,
})(AccountTransactionsBase);

export default AccountTransactions;
