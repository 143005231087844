import React from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  WidgetHeader,
  BoldText,
  Label,
  TextValue,
  CardDiv,
} from '../styled-components';
import { amountFormatter } from '../../utils/functions';
import colors from '../../utils/colors';
import LoadSpinner from '../LoadSpinner';
import { ResponsiveLine } from '@nivo/line';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';

const ClientDayToDayVarianceGraph = ({
  dayToDayVariance,
  isDayToDayVarianceFetching,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ width: '600px' }}
    >
      {isDayToDayVarianceFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {!isDayToDayVarianceFetching &&
        dayToDayVariance &&
        dayToDayVariance.length === 0 && (
          <>
            <WidgetHeader>
              <Label>Day-to-Day Variance</Label>
            </WidgetHeader>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        )}

      {!isDayToDayVarianceFetching &&
        dayToDayVariance &&
        dayToDayVariance[3] &&
        dayToDayVariance[3][0].data.length > 1 && (
          <>
            <WidgetHeader>
              <Label>Day-to-Day Variance</Label>
            </WidgetHeader>
            <WidgetHeader padding='10px 20px' display='block'>
              <BoldText fontSize='1rem'>
                Balance as of:{' '}
                {dayToDayVariance && (
                  <TextValue>{dayToDayVariance[2]}</TextValue>
                )}
              </BoldText>
            </WidgetHeader>

            <div
              style={{
                height: '16.9rem',
                display: 'flex',
                background: `${colors.containerBackground}`,
              }}
            >
              <ResponsiveLine
                data={dayToDayVariance.length > 0 ? dayToDayVariance[3] : []}
                margin={{ top: 20, right: 50, bottom: 50, left: 100 }}
                colors={colors.primary}
                axisTop={null}
                axisRight={null}
                enablePoints={false}
                enableGridX={false}
                enableGridY={false}
                xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false }}
                xFormat='time:%b-%Y'
                yScale={{
                  type: 'linear',
                  min: dayToDayVariance[1],
                  max: dayToDayVariance[0],
                }}
                yFormat={(v) => amountFormatter(v, 2)}
                axisBottom={{
                  format: '%b-%Y',
                  orient: 'bottom',
                  tickSize: 8,
                  tickPadding: 10,
                  tickValues: 'every 2 months',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 10,
                  format: (v) => amountFormatter(v, 0),
                }}
                // needed for interactivity
                useMesh={true}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: '#a9acbe',
                        strokeWidth: 1,
                      },
                    },
                  },
                  tooltip: {
                    container: { color: colors.boldText, fontWeight: 700 },
                  },
                }}
              />
            </div>
          </>
        )}
    </CardDiv>
  );
};

export default ClientDayToDayVarianceGraph;
