import React, { useState, useEffect } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import InvestmentGroupForm from '../../forms/investment-forms/InvestmentGroupForm';
import { checkSameName } from '../../../utils/functions';
import SameNameWarning from '../SameNameWarning';
import {
  updateInvestmentGroup,
  createInvestmentGroup,
} from '../../../api/investments';
import AppToast from '../../Toast';
import { connect } from 'react-redux';
import {
  fetchInvestmentGroups,
  fetchSingleInvestmentGroup,
} from '../../../actions/investments';

const InvestmentGroupDialogBase = ({
  isOpen,
  title,
  handleClose,
  investmentGroup,
  fetchInvestmentGroups,
  fetchSingleInvestmentGroup,
  investmentGroups,
}) => {
  const groupNames = investmentGroups.map((group) => group.groupName);

  //Edit state
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);
  const [initialGroupName, setInitialGroupName] = useState('');
  const [groupState, setGroupState] = useState({});
  const [groupId, setGroupId] = useState('');
  const [isOverWriting, setIsOverWriting] = useState(false);
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    if (title === 'Edit Investment Group') {
      setInitialGroupName(investmentGroup.groupName);
      setGroupName(investmentGroup.groupName);
      setGroupId(investmentGroup.groupId);
    }
  }, [investmentGroup, title]);

  const handleUpdate = (groupName, id) => {
    updateInvestmentGroup(groupName, id)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchSingleInvestmentGroup(groupId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit client.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        closeReset();
      });
  };

  const handleEdit = (values = groupState, id = groupId) => {
    const investmentGroup = {
      ...values,
    };
    setIsTryingToSubmit(true);
    const isNameOverWritten =
      checkSameName(investmentGroup.groupName, groupNames) &&
      initialGroupName !== investmentGroup.groupName;
    if (isNameOverWritten) {
      setGroupState(investmentGroup);
      setGroupId(id);
      setIsWarningOpen(true);
    } else {
      handleUpdate(investmentGroup.groupName, id);
    }
  };

  const handleCreate = (groupName) => {
    createInvestmentGroup(groupName)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchInvestmentGroups();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create group.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeReset());
  };

  const handleCreateGroup = ({ groupName }) => {
    setIsTryingToSubmit(true);
    if (checkSameName(groupName, groupNames)) {
      setIsWarningOpen(true);
      setGroupState({ groupName });
    } else {
      handleCreate(groupName);
    }
  };

  const closeReset = () => {
    setIsTryingToSubmit(false);
    handleWarningDialogClose();
    handleClose();
  };

  const handleWarningDialogClose = () => {
    setIsTryingToSubmit(false);
    setIsWarningOpen(false);
    setIsOverWriting(false);
    setGroupState({});
    setGroupId('');
  };

  const handleIgnoreSameName = () => {
    setIsOverWriting(true);
    const groupName = groupState.groupName;
    if (title === 'Edit Investment Group') {
      handleUpdate(groupName, groupId);
    } else {
      handleCreate(groupName);
    }
  };

  return (
    <>
      {isWarningOpen && (
        <SameNameWarning
          parentTitle={title}
          isOpen={isWarningOpen}
          handleWarningDialogClose={handleWarningDialogClose}
          warningName={groupState.groupName}
          handleIgnoreSameName={handleIgnoreSameName}
          isOverwriting={isOverWriting}
        />
      )}
      <Dialog
        isOpen={isOpen}
        title={title}
        onClose={closeReset}
        isCloseButtonShown={false}
      >
        <InvestmentGroupForm
          closeDialog={closeReset}
          handleEdit={handleEdit}
          investmentGroup={investmentGroup}
          title={title}
          groupName={groupName}
          groupId={groupId}
          isTryingToSubmit={isTryingToSubmit}
          handleCreate={handleCreateGroup}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  investmentGroups: state.investments.investmentGroups,
});

const InvestmentGroupDialog = connect(mapStateToProps, {
  fetchInvestmentGroups,
  fetchSingleInvestmentGroup,
})(InvestmentGroupDialogBase);

export default InvestmentGroupDialog;
