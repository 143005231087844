import React from 'react';
import { AlignCenter } from '../../../components/styled-components';
import { Formik, Form } from 'formik';
import ModalTextField from '../../../components/formik-text-fields/ModalTextField';
import SelectFieldFormik from '../../../components/select-fields/SelectFieldFormik';
import * as Yup from 'yup';
import FormActions from '../FormActions';

const keywordSchema = Yup.object().shape({
  keywordName: Yup.string().required('Required!'),
  keywordCategory: Yup.string().required('Required!'),
});

const transformValues = (values, categories) => {
  if (values.keywordCategory) {
    const categoryId = categories.find(
      (x) => x.label === values.keywordCategory
    ).value;
    delete values.keywordCategory;
    values.keywordCategoryId = categoryId;
  }
  return values;
};

const KeywordsForm = ({
  title,
  onClose,
  keywordCategories,
  keywordName,
  keywordCategory,
  handleCreate,
  handleEdit,
}) => {
  return (
    <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
      <Formik
        initialValues={{ keywordName, keywordCategory }}
        validationSchema={keywordSchema}
        onSubmit={(values) => {
          if (title === 'Add Keyword')
            handleCreate(transformValues(values, keywordCategories));
          else handleEdit(transformValues(values, keywordCategories));
        }}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
              <ModalTextField
                type='text'
                name='keywordName'
                placeholder='Keyword'
                label='Keyword'
                value={values.keywordName || ''}
                autoComplete='off'
              />
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={values.keywordCategory || ''}
                labelName={'Keyword Category'}
                items={keywordCategories}
                name='keywordCategory'
                useLabel={true}
              />
            </AlignCenter>
            <FormActions isSubmitting={isSubmitting} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default KeywordsForm;
