import React, { useState, useMemo, useCallback } from 'react';
import SortableHeader from './components/SortableHeader';
import {
  NameLink,
  ActionIconButton,
  StyleColumnCell,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import { checkPermissions, amountFormatter, defaultAccountDataGatheringFrequency, getCollectionTypeColor, getCollectionTypeValue } from '../../utils/functions';
import permissions from '../../utils/permissions';
import RowSelector from './components/RowSelector';
import AccountDeleteDialogBox from '../../components/dialog/account-profile-dialog/AccountDeleteDialogBox';
import { TableContainer } from './components/table-styled-components';

const AccountTable = ({
  isOpen,
  appUser,
  accounts,
  isFetching,
  openEditAccountDialog,
  clientId,
  clientDataGatheringFrequency,
  title,
  handleCloseDialog,
  groupId,
  handleStagingAccountDialogOpen,
  ...props
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [account, setAccount] = useState({});
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const handleDeleteDialogOpen = useCallback(
    (accounts) => {
      accounts.length ? setSelectedAccounts(accounts) : setAccount(account);
      setIsDeleteOpen(true);
    },
    [account]
  );

  const handleDeleteDialogClose = () => {
    setSelectedAccounts([]);
    setAccount({});
    setIsDeleteOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account Name' />
        ),
        Cell: ({ cell: { value }, row }) => {
          return (
            <>
              {title === 'These accounts require additional setup' ? (
                <span>{value}</span>
              ) : (
                <NameLink
                  to={{
                    pathname: `/client-management/${clientId}/${row.original.accountId}`,
                  }}
                >
                  {value}
                </NameLink>
              )}
            </>
          );
        },
        accessor: 'accountName',
        sortType: 'caseInsensitiveSort',
        width: '10%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Number' />
        ),
        accessor: 'accountNumber',
        width: '1%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Alias' />
        ),
        accessor: 'accountAlias',
        width: '1%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Type' />
        ),
        accessor: 'accountType',
        filter: 'multipleValues',
        sortType: 'caseInsensitiveSort',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Type Category' />
        ),
        accessor: 'accountCategory',
        filter: 'multipleValues',
        sortType: 'caseInsensitiveSort',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank' />
        ),
        accessor: 'bankName',
        filter: 'multipleValues',
        sortType: 'caseInsensitiveSort',
        width: '3%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Balance' />
        ),
        Cell: ({ cell: { value } }) => {
          return <span>{amountFormatter(value)}</span>;
        },
        accessor: 'accountBalance',
        filter: 'numberRange',
        width: '2%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Last Transaction Date' />
        ),
        accessor: 'lastTransactionDate',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Balance Date' />
        ),
        accessor: 'accountBalanceDate',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        width: '4%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account Closed' />
        ),
        accessor: 'accountClosed',
        sortType: 'caseInsensitiveSort',
        filter: 'yesOrNo',
        width: '6%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'No' ? colors.inActiveColor : colors.activeColor}
            >
              {value && value.toUpperCase()}
            </StyleColumnCell>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Closed Date' />
        ),
        accessor: 'accountDateClosed',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        width: '6%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{value === null ? '-' : value}</span>;
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Data Gathering Frequency' />
        ),
        Cell: ({ cell: { value }, row }) => {
          return <span>{value > 0
            ? value
            : defaultAccountDataGatheringFrequency(row.original.bankDataGatheringFrequency,
              clientDataGatheringFrequency)
          }</span>;
        },
        accessor: 'dataGatheringFrequency',
        width: '1%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Collection Type' />
        ),
        accessor: 'collectionType',
        sortType: 'basic',
        filter: 'text',
        width: '10%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={getCollectionTypeColor(value)}
            >
              {getCollectionTypeValue(value)}
            </StyleColumnCell>
          );
        },
      },
      {
        // need id in actions column because it isn't pulling data from api
        // columns with accessor as a function also need id (see users table)
        Header: () => 'Actions',
        id: 'action',
        width: '1%',
        Cell: ({ row }) => (
          <ActionIconButton
            onClick={() =>
              title === 'Current Accounts'
                ? handleDeleteDialogOpen([row.original])
                : openEditAccountDialog(row.original)
            }
          >
            <Icon
              icon={
                title === 'Current Accounts' ? iconValue.trash : iconValue.pen
              }
              iconSize={16}
              color={colors.primary}
            />
          </ActionIconButton>
        ),
        sticky: 'right',
        isVisible:
          title === 'Current Accounts'
            ? checkPermissions(appUser.permList, [
              permissions.REMOVE_ACCOUNT_GROUP_ASSOCIATIONS,
            ])
            : checkPermissions(appUser.permList, [permissions.EDIT_ACCOUNTS]),
      },
    ],
    [appUser, clientId, openEditAccountDialog, title, handleDeleteDialogOpen, clientDataGatheringFrequency]
  );

  return (
    <TableContainer
      padding={
        title === 'Current Accounts'
          ? '0 0 4rem 0'
          : title === 'All Accounts'
            ? '0 2rem 4rem'
            : '0 2rem 4rem'
      }
    >
      <MakeTable
        isOpen={isOpen}
        appUser={appUser}
        data={accounts}
        columns={columns}
        isFetching={isFetching}
        tableTitle={title ? title : 'Accounts'}
        handleCloseDialog={handleCloseDialog}
        groupId={groupId}
        handleDeleteDialogOpen={
          title === 'These accounts require additional setup'
            ? handleStagingAccountDialogOpen
            : handleDeleteDialogOpen
        }
        {...props}
      />
      <AccountDeleteDialogBox
        isOpen={isDeleteOpen}
        title={
          // if account is not an object use bulk title
          selectedAccounts.length === 1
            ? 'Delete Account'
            : `Delete Selected Accounts (${selectedAccounts.length})`
        }
        groupId={groupId}
        handleClose={handleDeleteDialogClose}
        account={account}
        selectedAccounts={selectedAccounts}
      />
    </TableContainer>
  );
};

export default AccountTable;
