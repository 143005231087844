import React from 'react';
import { useField } from 'formik';
import {
  Label,
  FlexColumn,
  BlueprintInputWrapper,
  LightLabel,
  AlignCenter,
  ErrorMessageDiv,
} from '../styled-components';
import { DateInput } from '@blueprintjs/datetime';
import { Position, Icon } from '@blueprintjs/core';
import {
  dateFormatter,
  getDateTwentyYearsInPast,
  getDateTwentyYearsInFuture,
} from '../../utils/functions';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';

const DateSelectField = ({
  setFieldValue,
  value,
  labelName,
  isDateDisabled,
  isLightLabel,
  isBalanceAdjustment,
  minDate,
  maxDate,
  ...props
}) => {
  const [field, meta] = useField(props);
  const fieldName = field.name;
  maxDate = maxDate || getDateTwentyYearsInFuture();
  minDate = minDate || getDateTwentyYearsInPast();
  return (
    <FlexColumn>
      {isLightLabel ? (
        <LightLabel>{labelName}</LightLabel>
      ) : (
        <Label>{labelName}</Label>
      )}

      <BlueprintInputWrapper
        background={isBalanceAdjustment ? 'white' : 'transparent'}
        color={isDateDisabled && isBalanceAdjustment ? colors.lightText : null}
        cursor={isDateDisabled && isBalanceAdjustment ? 'not-allowed' : null}
        border={
          isDateDisabled && isBalanceAdjustment
            ? `1px solid ${colors.lightText}`
            : null
        }
      >
        <DateInput
          {...dateFormatter()}
          popoverProps={{ position: Position.BOTTOM }}
          onChange={(value) => {
            setFieldValue(fieldName, value);
          }}
          value={value}
          name={fieldName}
          type='date'
          minDate={minDate}
          maxDate={maxDate}
          disabled={isDateDisabled || (isDateDisabled && isBalanceAdjustment)}
        />
      </BlueprintInputWrapper>
      <div>
        {meta.touched && meta.error && Object.entries(meta.error).length > 0 ? (
          <AlignCenter justifyContent='space-between'>
            <ErrorMessageDiv fontSize='1rem'>{meta.error}</ErrorMessageDiv>
            <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
          </AlignCenter>
        ) : null}
      </div>
    </FlexColumn>
  );
};

export default DateSelectField;
