import React, { useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
} from '../../styled-components';
import colors from '../../../utils/colors';
import SelectField from '../../select-fields/SelectField';

const ShortTermModelWidgetSettingDialog = ({
  isOpen,
  handleClose,
  weekList,
  week,
  handleSelectChange,
}) => {
  const [selectedWeek, setSelectedWeek] = useState(week);

  return (
    <Dialog
      isOpen={isOpen}
      title={'Weekly Setting'}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 0 20px 0' }}>
        <AlignCenter justifyContent='space-evenly' padding='10px 0'>
          <SelectField
            selectValueFromList={setSelectedWeek}
            value={selectedWeek}
            labelName={'Number of Weeks'}
            items={weekList}
          />
          <div style={{ width: '271px' }}></div>
        </AlignCenter>
      </div>
      <AlignCenter
        justifyContent='flex-end'
        padding='10px 20px 0 0'
        borderTop={`1px solid ${colors.lightText}`}
        margin={'50px 0 0 0'}
      >
        <WhiteButton type='button' onClick={handleClose}>
          CANCEL
        </WhiteButton>
        <RegularButton
          padding='10px 30px'
          onClick={() => handleSelectChange(selectedWeek)}
        >
          SAVE
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};

export default ShortTermModelWidgetSettingDialog;
