import React from 'react';
import { Form, Formik } from 'formik';
import { AlignCenter } from '../../styled-components';
import FormActions from '../FormActions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  category: Yup.object().shape({
    label: Yup.string(),
  }).required('Required!'),
  subCategory: Yup.object().shape({
    label: Yup.string(),
  }).required('Required!'),
});

const transformValues = (values) => {
  values.categoryId = values.subCategory.value;
  delete values.category;
  delete values.subCategory;
  return values;
};

const BulkEditFeeForm = ({
  handleBulkEditFee,
  handleClose,
  categoryList,
  subCategoryList
}) => {
  const category = '';
  const subCategory = '';

  return (
    <>
      <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
        <Formik
          initialValues={{
            category,
            subCategory,
          }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            handleBulkEditFee(transformValues(values))
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            const handleCategoryChange = (item) => {
              if (item.value !== values.category.value) {
                setFieldValue('subCategory', {});
                setFieldValue('category', item);
              } else {
                setFieldValue('category', item);
              }
            };
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >                
                <AlignCenter
                  justifyContent='space-evenly'
                  padding='20px 0'
                  alignItems='flex-start'
                >
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    value={values.category.label || ''}
                    labelName='Category'
                    items={categoryList}
                    name='category'
                    hasHandleFunction={true}
                    handleSelectChange={handleCategoryChange}
                  />
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    value={values.subCategory.label || ''}
                    labelName='Sub Category'
                    items={
                      values.category.value || values.category.value === 0
                        ? subCategoryList[values.category.value]
                        : []
                    }
                    name='subCategory'
                    useItem
                  />
                </AlignCenter>
                <FormActions
                  isSubmitting={isSubmitting}
                  onClose={handleClose}
                  margin='0'
                />
              </Form>
            )
          }}
        </Formik>
      </AlignCenter>
    </>
  )
}

export default BulkEditFeeForm