import React, { useMemo } from 'react';
import { ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import RowSelector from './components/RowSelector';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const ExtractionTable = ({
  appUser,
  extractionList,
  isFetching,
  handleOpenTemplate,
  handleOpenExtractionWarningDialog,
  handleDownloadFile,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) =>
          row.original.fileStatus === 'Failed' && (
            <RowSelector
              {...row.getToggleRowSelectedProps()}
              style={{ height: '15px', width: '15px' }}
            />
          ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='File Name' />
        ),
        accessor: 'fileName',
        sortType: 'caseInsensitiveSort',
        width: '6%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Header Row' />
        ),
        accessor: 'headerRow',
        Cell: ({ cell: { value } }) => (
          <span>
            {value && value.length > 100
              ? value.substring(0, 100) + '...'
              : value
              ? value
              : '---'}
          </span>
        ),
        sortType: 'basic',
        width: '10%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Status' />
        ),
        accessor: 'fileStatus',
        sortType: 'caseInsensitiveSort',
        width: '4%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Message' />
        ),
        accessor: 'extractionMessage',
        sortType: 'caseInsensitiveSort',
        width: '8%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'timestamp',
        sortType: 'caseInsensitiveSort',
        width: '3%',
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.MANAGE_TEMPLATES,
                ]) &&
                  row.original.fileStatus === 'Success' && (
                    <MenuItem
                      text='Download as Excel'
                      onClick={() => {
                        handleDownloadFile(row.original);
                      }}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.MANAGE_TEMPLATES,
                ]) && (
                  <MenuItem
                    text='Create First Cell Template'
                    onClick={() => handleOpenTemplate(row.original, 'Cell')}
                    style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                  />
                )}
                {checkPermissions(appUser.permList, [
                  permissions.MANAGE_TEMPLATES,
                ]) && (
                  <MenuItem
                    text='Create Header Row Template'
                    onClick={() => handleOpenTemplate(row.original, 'Header')}
                    style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                  />
                )}
                {checkPermissions(appUser.permList, [
                  permissions.MANAGE_TEMPLATES,
                ]) &&
                  row.original.fileStatus === 'Failed' && (
                    <MenuItem
                      text='Retry Extraction'
                      onClick={() =>
                        handleOpenExtractionWarningDialog(row.original)
                      }
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.MANAGE_TEMPLATES,
        ]),
      },
    ],
    [
      appUser,
      handleOpenTemplate,
      handleOpenExtractionWarningDialog,
      handleDownloadFile,
    ]
  );
  return (
    <TableContainer width='100%' padding='0 0 1rem'>
      <MakeTable
        data={extractionList}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Extraction Progress'
        handleOpenExtractionWarningDialog={handleOpenExtractionWarningDialog}
        appUser={appUser}
        {...props}
      />
    </TableContainer>
  );
};

export default ExtractionTable;
