import React, { useState, useEffect } from 'react';
import { Elevation, Icon } from '@blueprintjs/core';
import { connect } from 'react-redux';
import shortid from 'shortid';
import {
  Label,
  WidgetHeader,
  WidgetBody,
  WidgetBodyHeader,
  WidgetRow,
  TransparentButton,
  MessageText,
  CardDiv,
} from '../styled-components';
import colors from '../../utils/colors';
import { paginate, amountFormatter } from '../../utils/functions';
import WidgetPagination from './WidgetPagination';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';
import ShortTermModelWidgetSettingDialog from '../dialog/client-profile-dialog/ShortTermModelWidgetSettingDialog';
import { fetchShortTermModelForecast } from '../../actions/clients';
import LoadSpinner from '../LoadSpinner';

const pageLimit = 4;

// This is used to determine how many weeks of data the widget will display from
// a minimum of 4 weeks to a maximum of 24 weeks
const weekList = Array.from(
  { length: 24 + 1 - 4 },
  (v, k) => `${k + 4}`
).map((value) => ({ value: String(value), label: String(value) }));

const ShortTermModelForecastWidgetBase = ({
  shortTermModelForecast,
  isShortTermModelForecastFetching,
  clientId,
  fetchShortTermModelForecast,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedForecasts, setPaginatedForecasts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [week, setWeek] = useState(weekList[0].value);

  useEffect(() => {
    setPaginatedForecasts(
      paginate(shortTermModelForecast, pageLimit, pageNumber)
    );
  }, [shortTermModelForecast, pageNumber]);

  const handleOpenSetting = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelectChange = (value) => {
    setWeek(value);
    fetchShortTermModelForecast(clientId, value);
    handleClose();
  };

  return (
    <>
      <CardDiv
        interactive={false}
        elevation={Elevation.TWO}
        className={'widget'}
      >
        {isShortTermModelForecastFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '150px 0',
            }}
          >
            <LoadSpinner size={100} />
          </div>
        ) : (
          <div>
            <WidgetHeader>
              <div>
                <Label
                  display={'flex'}
                  alignItems={'center'}
                  paddingBottom={'10px'}
                >
                  Short Term Modeling/Forecast
                </Label>
                <MessageText margin='0 0 0 0'>Current {week} Weeks</MessageText>
              </div>
              <TransparentButton onClick={handleOpenSetting}>
                <Icon
                  icon={iconValue.settings}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
            </WidgetHeader>
            <WidgetBody>
              <WidgetBodyHeader padding='20px 20px 10px'>
                <Label fontSize='1rem' width={'25%'}>
                  Date Range
                </Label>
                <Label fontSize='1rem' width={'20%'}>
                  Forecast
                </Label>
                <Label fontSize='1rem' width={'25%'}>
                  Prior Yr. Actual
                </Label>
                <Label fontSize='1rem' width={'30%'}>
                  Strategic Liquidity
                </Label>
              </WidgetBodyHeader>
              {paginatedForecasts.length ? (
                paginatedForecasts.map((forecast) => (
                  <WidgetRow key={shortid.generate()}>
                    <div style={{ width: '25%' }}>{forecast.dateRange}</div>
                    <div style={{ width: '20%' }}>
                      {amountFormatter(forecast.forecast)}
                    </div>
                    <div style={{ width: '25%' }}>
                      {amountFormatter(forecast.priorYearActual)}
                    </div>
                    <div style={{ width: '30%' }}>
                      {amountFormatter(forecast.strategicLiquidity)}
                    </div>
                  </WidgetRow>
                ))
              ) : (
                <NonIdealStateComp
                  icon={iconValue.warning}
                  title={'No Results.'}
                  description={'There are no results to be displayed.'}
                />
              )}
            </WidgetBody>

            <WidgetPagination
              currentIndex={pageNumber}
              recordsLength={shortTermModelForecast.length}
              setPageNumber={setPageNumber}
              pageLimit={pageLimit}
            />
          </div>
        )}
      </CardDiv>
      <ShortTermModelWidgetSettingDialog
        isOpen={isOpen}
        handleClose={handleClose}
        week={week}
        weekList={weekList}
        handleSelectChange={handleSelectChange}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const ShortTermModelForecastWidget = connect(mapStateToProps, {
  fetchShortTermModelForecast,
})(ShortTermModelForecastWidgetBase);
export default ShortTermModelForecastWidget;
