import { IconNames } from '@blueprintjs/icons';

const iconValue = {
  person: IconNames.PERSON,
  lock: IconNames.LOCK,
  warning: IconNames.WARNING_SIGN,
  check: IconNames.TICK,
  home: IconNames.HOME,
  newPerson: IconNames.NEW_PERSON,
  clients: IconNames.BRIEFCASE,
  banks: IconNames.BANK_ACCOUNT,
  download: IconNames.IMPORT,
  investments: IconNames.DOLLAR,
  settings: IconNames.COG,
  search: IconNames.SEARCH,
  caretDown: IconNames.CARET_DOWN,
  caretUp: IconNames.CARET_UP,
  pen: IconNames.EDIT,
  chevronLeft: IconNames.CHEVRON_LEFT,
  chevronRight: IconNames.CHEVRON_RIGHT,
  cross: IconNames.CROSS,
  doubleCaretVertical: IconNames.DOUBLE_CARET_VERTICAL,
  info: IconNames.INFO_SIGN,
  trash: IconNames.TRASH,
  filter: IconNames.FILTER,
  calendar: IconNames.CALENDAR,
  tick: IconNames.TICK,
  blank: IconNames.BLANK,
  folder: IconNames.FOLDER_CLOSE,
  menu: IconNames.LAYOUT_LINEAR,
  comment: IconNames.COMMENT,
  annotation: IconNames.ANNOTATION,
  dot: IconNames.DOT,
  pieChart: IconNames.PIE_CHART,
  tickCircle: IconNames.TICK_CIRCLE,
};

export default iconValue;
