import React from 'react';
import { AlignCenter, WhiteButton, RegularButton } from '../styled-components';
import colors from '../../utils/colors';
import LoadSpinner from '../LoadSpinner';
import { Icon, Tooltip } from '@blueprintjs/core';
import iconValue from '../../utils/icons';

const FormActions = ({
  isSubmitting,
  onClose,
  saveText,
  thirdButton,
  buttonText,
  action,
  isDisabled,
  margin,
  isSaveDisabled,
}) => {
  return (
    <AlignCenter
      justifyContent='flex-end'
      padding='10px 20px 0 0'
      borderTop={`1px solid ${colors.lightText}`}
      margin={margin || '50px 0 0 0'}
    >
      <WhiteButton type='button' onClick={onClose}>
        CANCEL
      </WhiteButton>
      {thirdButton && (
        <RegularButton
          padding='10px 30px'
          display='flex'
          disabled={isDisabled}
          onClick={action}
        >
          {buttonText}
          {isDisabled && (
            <span style={{ marginLeft: '10px' }}>
              <LoadSpinner size={20} />
            </span>
          )}
        </RegularButton>
      )}
      <RegularButton
        padding='10px 30px'
        display='flex'
        type='submit'
        disabled={isSubmitting || isSaveDisabled}
      >
        {saveText || 'SAVE'}
        {isSubmitting && (
          <span style={{ marginLeft: '10px' }}>
            <LoadSpinner size={20} />
          </span>
        )}
      </RegularButton>
      {isSaveDisabled && (
        <Tooltip
          content={'Not enough data to run the analysis.'}
          style={{ width: '25rem', marginLeft: '5px' }}
        >
          <Icon
            icon={iconValue.info}
            iconSize={20}
            color={colors.primary}
            style={{ marginLeft: '5px', display: 'flex' }}
          />
        </Tooltip>
      )}
    </AlignCenter>
  );
};

export default FormActions;
