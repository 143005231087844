import React from 'react';
import iconValue from '../../utils/icons';
import { Icon } from '@blueprintjs/core';
import colors from '../../utils/colors';

const WidgetPagination = ({
  currentIndex,
  recordsLength,
  setPageNumber,
  pageLimit,
  isInterestRateComparisonWidget,
}) => {
  const startIndex = recordsLength ? (currentIndex - 1) * pageLimit + 1 : 0;
  const lastIndex = !recordsLength
    ? 0
    : Number(startIndex) + Number(pageLimit) - 1 > recordsLength
    ? recordsLength
    : Number(startIndex) + Number(pageLimit) - 1;
  const totalPages = recordsLength ? Math.ceil(recordsLength / pageLimit) : 1;
  return (
    <div
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
        padding: '10px',
        borderTop: `1px solid ${colors.lightText}`,
      }}
    >
      <span>
        <button
          onClick={() => setPageNumber(currentIndex - 1)}
          disabled={
            isInterestRateComparisonWidget
              ? currentIndex === 0 || !recordsLength
              : currentIndex === 1 || !recordsLength
          }
          title='Previous Page'
          style={{ background: 'transparent', border: 'none' }}
        >
          <Icon icon={iconValue.chevronLeft} iconSize={15} />
        </button>
        <span
          style={{
            color: colors.normalText,
          }}
        >
          {isInterestRateComparisonWidget
            ? `${recordsLength > 0 ? currentIndex + 1 : 0} of ${recordsLength}`
            : `${startIndex} to ${lastIndex} of ${recordsLength}`}
        </span>
        <button
          onClick={() => setPageNumber(currentIndex + 1)}
          disabled={
            isInterestRateComparisonWidget
              ? currentIndex + 1 === recordsLength || !recordsLength
              : currentIndex === totalPages || !recordsLength
          }
          title='Next Page'
          style={{ background: 'transparent', border: 'none' }}
        >
          <Icon icon={iconValue.chevronRight} iconSize={15} />
        </button>
      </span>
    </div>
  );
};
export default WidgetPagination;
