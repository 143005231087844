import React from 'react';
import { NavItem, SubNavWrapper } from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

const AnalysisSubNav = ({
  handleCurrentComponentChange,
  currentComponent,
  appUser,
}) => {
  return (
    <>
      <SubNavWrapper>
        {checkPermissions(appUser.permList, [
          permissions.VIEW_ANALYSIS_OPEN_REPORTS,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(0)}
            className={currentComponent === 0 && 'active'}
          >
            Open Reports
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_ANALYSIS]) && (
          <NavItem className={currentComponent === 0 ? 'disabled' : 'active'}>
            Analysis
          </NavItem>
        )}
      </SubNavWrapper>
    </>
  );
};

export default AnalysisSubNav;
