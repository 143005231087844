import React from 'react';
import { Dialog } from '@blueprintjs/core';
import colors from '../../utils/colors';
import {
  AlignCenter,
  WhiteButton,
  RegularButton,
  MessageText,
} from '../../components/styled-components';
import LoadSpinner from '../LoadSpinner';

// warning dialog for same name bank and client
const SameNameWarning = ({
  parentTitle,
  handleWarningDialogClose,
  isOpen,
  handleIgnoreSameName,
  warningName,
  isOverwriting,
}) => {
  const typeName = parentTitle.toLowerCase().includes('client')
    ? 'Client'
    : parentTitle.toLowerCase().includes('investment group')
    ? 'Investment Group'
    : 'Bank';
  return (
    <>
      <Dialog
        className='small-dialog'
        isOpen={isOpen}
        title='Warning'
        onClose={handleWarningDialogClose}
        isCloseButtonShown={false}
      >
        <div
          style={{
            padding: '30px 50px 20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <MessageText margin='0 5px 0 0'>
            A {typeName} with the name
            <strong style={{ fontWeight: '700' }}>
              {` ${warningName} ` || ''}
            </strong>
            already exists, are you sure you want to continue?
          </MessageText>
        </div>
        <AlignCenter
          justifyContent='flex-end'
          padding='10px 20px 10px 0'
          borderTop={`1px solid ${colors.lightText}`}
          margin='50px 0 0 0'
        >
          <WhiteButton
            type='button'
            color={colors.red}
            onClick={() => {
              if (
                parentTitle === 'Add New Client' ||
                parentTitle === 'Add Investment Group'
              )
                handleWarningDialogClose();
              else handleWarningDialogClose(true);
            }}
            hoverColor={colors.redHover}
          >
            BACK
          </WhiteButton>
          <RegularButton
            padding='10px 30px'
            width='12rem'
            display='flex'
            onClick={() => {
              handleIgnoreSameName();
            }}
            background={colors.red}
            backgroundHover={colors.redHover}
            disabled={isOverwriting}
          >
            CONTINUE
            {isOverwriting && (
              <span style={{ marginLeft: '10px' }}>
                <LoadSpinner size={20} />
              </span>
            )}
          </RegularButton>
        </AlignCenter>
      </Dialog>
    </>
  );
};

export default SameNameWarning;
