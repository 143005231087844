import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import NumberRangeField from '../../select-fields/NumberRangeField';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import {
  dateToFilterText,
  multiSelectToFilterText,
  numberRangeToFilterText,
  dateRangeChecker,
} from '../../../utils/functions';
import TableFilterActions from './TableFilterActions';

const ValidationHistoryFilterDialogBox = ({
  isOpen,
  title,
  setFilter,
  rangeFilter,
  initialData,
  handleClose,
  setAllFilters,
  setFilterSummary,
}) => {
  const [selectedBankName, setSelectedBankName] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [bankNames, setBankNames] = useState([]);
  const [createdDateRange, setCreatedDateRange] = useState([]);
  const [completedDateRange, setCompletedDateRange] = useState(rangeFilter);
  const [lowNumFiles, setLowNumFiles] = useState('');
  const [highNumFiles, setHighNumFiles] = useState('');

  useEffect(() => {
    if (initialData.length) {
      setBankNames(
        initialData
          .map((item) => item.bankName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setAccounts(
        initialData
          .map((item) => item.accountName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setFilter('completedAt', rangeFilter);
    }
  }, [initialData, setFilter, rangeFilter]);

  const getFilterSummary = () => {
    const filters = [];
    dateRangeChecker(completedDateRange) &&
      filters.push(dateToFilterText(completedDateRange, 'Completed date'));
    selectedBankName.length &&
      filters.push(multiSelectToFilterText(selectedBankName, 'Banks'));
    selectedAccounts.length &&
      filters.push(multiSelectToFilterText(selectedAccounts, 'Accounts'));
    dateRangeChecker(createdDateRange) &&
      filters.push(dateToFilterText(createdDateRange, 'Created Date'));
    (lowNumFiles || highNumFiles) &&
      filters.push(
        numberRangeToFilterText(lowNumFiles, highNumFiles, '# of Files')
      );
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter(
      'totalFiles',
      !lowNumFiles && !highNumFiles
        ? undefined
        : [lowNumFiles || undefined, highNumFiles || undefined]
    );
    setFilter(
      'createdAt',
      dateRangeChecker(createdDateRange) ? createdDateRange : undefined
    );
    setFilter(
      'bankName',
      selectedBankName.length ? selectedBankName : undefined
    );
    setFilter(
      'accountName',
      selectedAccounts.length ? selectedAccounts : undefined
    );
    setFilter(
      'completedAt',
      dateRangeChecker(completedDateRange) ? completedDateRange : undefined
    );
    handleClose();
  };

  const handleClearFilters = () => {
    setFilterSummary([]);
    setSelectedAccounts([]);
    setSelectedBankName([]);
    setAllFilters([]);
    setCreatedDateRange([]);
    setCompletedDateRange([]);
    setLowNumFiles([]);
    setHighNumFiles([]);
    handleClose();
  };
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0' }}>
        <div style={{ marginBottom: '20px' }}>
          <DateSelectPlainField
            labelName='Completed At'
            dateRange={completedDateRange}
            handleDateChange={setCompletedDateRange}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <DateSelectPlainField
            labelName='Created At'
            dateRange={createdDateRange}
            handleDateChange={setCreatedDateRange}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <NumberRangeField
            labelName='# of Files'
            selectLow={setLowNumFiles}
            selectHigh={setHighNumFiles}
            high={highNumFiles}
            low={lowNumFiles}
            isCurrency={false}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setSelectedBankName}
            items={bankNames}
            selectedItems={selectedBankName}
            label='Bank Names'
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setSelectedAccounts}
            items={accounts}
            selectedItems={selectedAccounts}
            label='Account Names'
          />
        </div>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default ValidationHistoryFilterDialogBox;
