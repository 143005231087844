import React, { useEffect, useState } from 'react';
import { BorderComponentContainer } from '../../../../../../components/styled-components';

import {
  fetchClientAccounts,
  fetchAccountLinks,
} from '../../../../../../actions/accounts';
import { connect } from 'react-redux';
import AccountLinkDialogBox from '../../../../../../components/dialog/account-profile-dialog/AccountLinkDialogBox';
import {
  createAccountLink,
  updateAccountLink,
  removeAccountLink,
} from '../../../../../../api/accounts';
import AppToast from '../../../../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import AccountLinkSection from '../../../../../../components/accounts/account-profile/AccountLinkSection';

const AccountLinkBase = ({
  clientId,
  accountId,
  clientAccounts,
  accountLinks,
  appUser,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [linkedToAccountId, setLinkedAccount] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const { fetchClientAccounts, fetchAccountLinks } = props;

  useEffect(() => {
    fetchClientAccounts(clientId);
    fetchAccountLinks(accountId);
  }, [fetchClientAccounts, fetchAccountLinks, clientId, accountId]);

  const openAddAccountLinkDialog = () => {
    setTitle('Add Account Link');
    setIsOpen(true);
  };

  const openEditAccountLinkDialog = () => {
    setTitle('Edit Account Link');
    setLinkedAccount(accountLinks[0].linkedToId);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setTitle('');
    setLinkedAccount('');
  };

  const selectLinkedAccountFromList = (account) => {
    setLinkedAccount(account.value);
  };

  const handleCreateAccountLink = async ({ linkedToAccountId }) => {
    const account = {
      accountId,
      linkedToAccountId,
    };
    createAccountLink(account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAccountLinks(accountId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create account link.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  const handleEditAccountLink = async ({ linkedToAccountId }) => {
    const account = {
      linkedToAccountId,
    };
    updateAccountLink(accountId, account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAccountLinks(accountId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit account link.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  const handleDeleteAccountLink = async () => {
    setIsDeleting(true);
    removeAccountLink(accountId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAccountLinks(accountId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete account link.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        closeDialog();
      });
  };

  return (
    <BorderComponentContainer>
      <AccountLinkSection
        accountLinks={accountLinks}
        openAddAccountLinkDialog={openAddAccountLinkDialog}
        openEditAccountLinkDialog={openEditAccountLinkDialog}
        appUser={appUser}
      />
      <AccountLinkDialogBox
        isOpen={isOpen}
        title={title}
        setTitle={setTitle}
        closeDialog={closeDialog}
        linkedToAccountId={linkedToAccountId}
        clientAccounts={clientAccounts}
        selectLinkedAccountFromList={selectLinkedAccountFromList}
        handleCreateAccountLink={handleCreateAccountLink}
        handleEditAccountLink={handleEditAccountLink}
        handleDeleteAccountLink={handleDeleteAccountLink}
        appUser={appUser}
        isDeleting={isDeleting}
      />
    </BorderComponentContainer>
  );
};

const mapStateToProps = (state) => ({
  clientAccounts: state.accounts.clientAccounts,
  accountLinks: state.accounts.accountLinks,
});

const AccountLink = connect(mapStateToProps, {
  fetchClientAccounts,
  fetchAccountLinks,
})(AccountLinkBase);

export default AccountLink;
