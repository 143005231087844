import { Dialog, Intent } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { deleteTemplate } from '../../../api/templates';
import TreasuryTemplateForm from '../../forms/template-forms/TreasuryTemplateForm';
import AppToast from '../../Toast';
import WarningTemplateDialog from './WarningTemplateDialog';

const TreasuryTemplateDialog = ({
  appUser,
  isOpen,
  closeDialog,
  handleSubmitTemplate,
  template,
  templateId,
}) => {
  const [accountGroupName, setAccountGroupName] = useState('');
  const [isTemplateWarningOpen, setIsTemplateWarningOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (template) {
      setAccountGroupName(template.accountGroupName || '');
    }
  }, [template]);

  const handleWarningTemplateOpen = () => {
    setIsTemplateWarningOpen(true);
  };

  const handleWarningTemplateClose = () => {
    setIsTemplateWarningOpen(false);
  };

  const handleDeleteTemplate = () => {
    setIsDeleting(true);
    deleteTemplate(templateId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete template.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        handleWarningTemplateClose();
        closeDialog();
      });
  };

  return (
    <>
      {isTemplateWarningOpen && (
        <WarningTemplateDialog
          isOpen={isTemplateWarningOpen}
          handleWarningTemplateClose={handleWarningTemplateClose}
          handleDeleteTemplate={handleDeleteTemplate}
          isDeleting={isDeleting}
        />
      )}
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title='Manage Treasury Services Import Template'
        isCloseButtonShown={false}
      >
        <TreasuryTemplateForm
          appUser={appUser}
          template={template}
          accountGroupName={accountGroupName}
          closeDialog={closeDialog}
          handleSubmitTemplate={handleSubmitTemplate}
          handleWarningTemplateOpen={handleWarningTemplateOpen}
        />
      </Dialog>
    </>
  );
};

export default TreasuryTemplateDialog;
