import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { MessageText, RedText } from '../../styled-components';
import DialogDeleteActions from '../DialogDeleteActions';

const OpenReportDeleteDialog = ({
  isOpen,
  title,
  openReport,
  handleClose,
  handleDelete,
  isDeleting,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete {openReport.clientName}'s batch?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default OpenReportDeleteDialog;
