import React, { useState } from 'react';
import { Elevation } from '@blueprintjs/core';
import LoadSpinner from '../LoadSpinner';
import {
  BoldText,
  CardDiv,
  Label,
  MessageText,
  NameLink,
  WidgetBody,
  WidgetHeader,
  WidgetRow,
} from '../styled-components';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';
import WidgetPagination from './WidgetPagination';
import colors from '../../utils/colors';

const InterestRateComparisonWidget = ({
  isFetching,
  interestRates,
  isClientProfile,
  handleCurrentComponentChange,
}) => {
  const [page, setPage] = useState(0);

  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ width: '700px' }}
      height={interestRates.length ? '100%' : '375px'}
    >
      <>
        <WidgetHeader>
          <Label display={'flex'} alignItems={'center'}>
            Interest Rate Comparison
          </Label>
          {isClientProfile && (
            <NameLink
              as='p'
              onClick={() => {
                handleCurrentComponentChange(3);
              }}
            >
              View All
            </NameLink>
          )}
        </WidgetHeader>
        {isFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <LoadSpinner size={100} />
          </div>
        ) : !interestRates.length ? (
          <NonIdealStateComp
            icon={iconValue.warning}
            title={'No Results.'}
            description={'There are no results to be displayed.'}
          />
        ) : (
          <>
            <WidgetBody>
              <WidgetRow
                style={{
                  height: '100%',
                  background: colors.white,
                  alignItems: 'center',
                  borderBottom: `1px solid ${colors.lightText}`,
                }}
                justifyContent='start'
              >
                <MessageText
                  fontSize='1.25rem'
                  style={{ width: '35%', marginRight: '40px' }}
                >
                  {interestRates[page].bankName}
                </MessageText>
                <MessageText fontSize='1.25rem'>Comparison Rates</MessageText>
              </WidgetRow>
              <WidgetRow
                style={{
                  height: '100%',
                  borderBottom: `1px solid ${colors.lightText}`,
                }}
                justifyContent='flex-start'
              >
                <div style={{ width: '100%' }}>
                  <MessageText margin='0' style={{ marginBottom: '10px' }}>
                    Fixed Income
                  </MessageText>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '35%',
                        margin: '0 4rem 0 0',
                      }}
                    >
                      <div>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                          color={
                            interestRates[page].fixedIncome.lastMonth &&
                            interestRates[page].fixedIncome.lastQuarter
                              ? interestRates[page].fixedIncome.lastMonth <
                                interestRates[page].fixedIncome.lastQuarter
                                ? colors.red
                                : colors.green
                              : ''
                          }
                        >
                          {interestRates[page].fixedIncome.lastMonth
                            ? `${interestRates[
                                page
                              ].fixedIncome.lastMonth.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Last Month
                        </MessageText>
                      </div>
                      <div>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                          color={
                            interestRates[page].fixedIncome.lastMonth &&
                            interestRates[page].fixedIncome.lastQuarter
                              ? interestRates[page].fixedIncome.lastMonth >
                                interestRates[page].fixedIncome.lastQuarter
                                ? colors.red
                                : colors.green
                              : ''
                          }
                        >
                          {interestRates[page].fixedIncome.lastQuarter
                            ? `${interestRates[
                                page
                              ].fixedIncome.lastQuarter.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Last Quarter
                        </MessageText>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '65%',
                      }}
                    >
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].fixedIncome.bestRate
                            ? `${interestRates[
                                page
                              ].fixedIncome.bestRate.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Best Rate
                        </MessageText>
                      </div>
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].fixedIncome.bestInState
                            ? `${interestRates[
                                page
                              ].fixedIncome.bestInState.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Best in {interestRates[page].state}
                        </MessageText>
                      </div>
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].fixedIncome.average
                            ? `${interestRates[
                                page
                              ].fixedIncome.average.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Average
                        </MessageText>
                      </div>
                    </div>
                  </div>
                </div>
              </WidgetRow>
              <WidgetRow
                style={{
                  height: '100%',
                  borderBottom: `1px solid ${colors.lightText}`,
                }}
                justifyContent='flex-start'
              >
                <div style={{ width: '100%' }}>
                  <MessageText margin='0' style={{ marginBottom: '10px' }}>
                    Low/No-Yield Liquid
                  </MessageText>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '35%',
                        margin: '0 4rem 0 0',
                      }}
                    >
                      <div>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                          color={
                            interestRates[page].lowYield.lastMonth &&
                            interestRates[page].lowYield.lastQuarter
                              ? interestRates[page].lowYield.lastMonth <
                                interestRates[page].lowYield.lastQuarter
                                ? colors.red
                                : colors.green
                              : ''
                          }
                        >
                          {interestRates[page].lowYield.lastMonth
                            ? `${interestRates[page].lowYield.lastMonth.toFixed(
                                2
                              )}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Last Month
                        </MessageText>
                      </div>
                      <div>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                          color={
                            interestRates[page].lowYield.lastMonth &&
                            interestRates[page].lowYield.lastQuarter
                              ? interestRates[page].lowYield.lastMonth >
                                interestRates[page].lowYield.lastQuarter
                                ? colors.red
                                : colors.green
                              : ''
                          }
                        >
                          {interestRates[page].lowYield.lastQuarter
                            ? `${interestRates[
                                page
                              ].lowYield.lastQuarter.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Last Quarter
                        </MessageText>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '65%',
                      }}
                    >
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].lowYield.bestRate
                            ? `${interestRates[page].lowYield.bestRate.toFixed(
                                2
                              )}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Best Rate
                        </MessageText>
                      </div>
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].lowYield.bestInState
                            ? `${interestRates[
                                page
                              ].lowYield.bestInState.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Best in {interestRates[page].state}
                        </MessageText>
                      </div>
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].lowYield.average
                            ? `${interestRates[page].lowYield.average.toFixed(
                                2
                              )}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Average
                        </MessageText>
                      </div>
                    </div>
                  </div>
                </div>
              </WidgetRow>
              <WidgetRow
                style={{
                  height: '100%',
                  borderBottom: `1px solid ${colors.lightText}`,
                }}
                justifyContent='flex-start'
              >
                <div style={{ width: '100%' }}>
                  <MessageText margin='0' style={{ marginBottom: '10px' }}>
                    High Yield Liquid
                  </MessageText>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '35%',
                        margin: '0 4rem 0 0',
                      }}
                    >
                      <div>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                          color={
                            interestRates[page].highYield.lastMonth &&
                            interestRates[page].highYield.lastQuarter
                              ? interestRates[page].highYield.lastMonth <
                                interestRates[page].highYield.lastQuarter
                                ? colors.red
                                : colors.green
                              : ''
                          }
                        >
                          {interestRates[page].highYield.lastMonth
                            ? `${interestRates[
                                page
                              ].highYield.lastMonth.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Last Month
                        </MessageText>
                      </div>
                      <div>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                          color={
                            interestRates[page].highYield.lastMonth &&
                            interestRates[page].highYield.lastQuarter
                              ? interestRates[page].highYield.lastMonth >
                                interestRates[page].highYield.lastQuarter
                                ? colors.red
                                : colors.green
                              : ''
                          }
                        >
                          {interestRates[page].highYield.lastQuarter
                            ? `${interestRates[
                                page
                              ].highYield.lastQuarter.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Last Quarter
                        </MessageText>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '65%',
                      }}
                    >
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].highYield.bestRate
                            ? `${interestRates[page].highYield.bestRate.toFixed(
                                2
                              )}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Best Rate
                        </MessageText>
                      </div>
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].highYield.bestInState
                            ? `${interestRates[
                                page
                              ].highYield.bestInState.toFixed(2)}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Best in {interestRates[page].state}
                        </MessageText>
                      </div>
                      <div style={{ width: '33%' }}>
                        <BoldText
                          fontSize='1.5rem'
                          style={{ marginBottom: '10px' }}
                        >
                          {interestRates[page].highYield.average
                            ? `${interestRates[page].highYield.average.toFixed(
                                2
                              )}%`
                            : 'None'}
                        </BoldText>
                        <MessageText
                          fontSize='0.75rem'
                          margin='0'
                          minWidth='auto'
                        >
                          Average
                        </MessageText>
                      </div>
                    </div>
                  </div>
                </div>
              </WidgetRow>
            </WidgetBody>
          </>
        )}
        <WidgetPagination
          currentIndex={page}
          recordsLength={interestRates.length}
          setPageNumber={setPage}
          pageLimit={interestRates.length}
          isInterestRateComparisonWidget={true}
        />
      </>
    </CardDiv>
  );
};

export default InterestRateComparisonWidget;
