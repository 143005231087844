import React, { useState } from 'react';
import { Icon, Intent } from '@blueprintjs/core';
import axios from 'axios';
import iconValue from '../../../../utils/icons';
import { downloadFiles } from '../../../../utils/functions';
import {
  BoldText,
  BorderComponentContainer,
  TransparentButton,
} from '../../../../components/styled-components';
import colors from '../../../../utils/colors';
import { updateDailyMonthlyBalance } from '../../../../api/clients';
import LoadSpinner from '../../../../components/LoadSpinner';
import { downloadFile } from '../../../../api/data-ingestion';
import AppToast from '../../../../components/Toast';

const AccountActions = ({ clientId, handleOpenUploadDialog }) => {
  const [showBox, setShowBox] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleSetShowBox = () => {
    setShowBox(!showBox);
  };

  const downloadSource = async () => {
    setIsDownloading(true);
    // fileAuditId is always 2
    const fileAuditId = 2;
    const presignedURL = await downloadFile(fileAuditId)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Download Template',
          intent: Intent.DANGER,
        });
        setIsDownloading(false);
      });

    if (presignedURL) {
      const name = presignedURL.substring(
        presignedURL.indexOf('BulkAccount'),
        presignedURL.indexOf('?')
      );

      delete axios.defaults.headers.common['Authorization'];
      await axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => downloadFiles(res, name))
        .catch((err) => console.error(err))
        .finally(() => setIsDownloading(false));
    }
  };

  const handleUpdateDailyMonthlyBalance = () => {
    updateDailyMonthlyBalance(clientId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Update Accounts.',
          intent: Intent.DANGER,
        });
      });
  };

  return (
    <>
      <BorderComponentContainer
        borderRadius='5px 5px 0 0'
        borderBottom='0'
        padding='1rem 0'
        boxShadow={`0px 3px 6px ${colors.lightText}`}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: showBox ? '1rem' : '0',
            borderBottom: showBox ? `1px solid ${colors.lightText}` : '',
          }}
        >
          <BoldText margin='0 0 0 1rem'>Account Actions</BoldText>
          <Icon
            icon={showBox ? iconValue.caretUp : iconValue.caretDown}
            iconSize={20}
            color={colors.primary}
            style={{ cursor: 'pointer', marginRight: '1rem' }}
            onClick={handleSetShowBox}
          />
        </div>
        {showBox && (
          <>
            <div
              style={{
                margin: '1rem',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <TransparentButton
                margin='0 0 0 10px'
                disabled={isDownloading}
                display='flex'
                onClick={downloadSource}
              >
                Download Template
                {isDownloading && (
                  <span style={{ marginLeft: '10px' }}>
                    <LoadSpinner size={20} />
                  </span>
                )}
              </TransparentButton>
              <TransparentButton
                margin='0 0 0 10px'
                onClick={handleOpenUploadDialog}
              >
                Bulk Upload Accounts
              </TransparentButton>
              <TransparentButton
                margin='0 0 0 10px'
                onClick={handleUpdateDailyMonthlyBalance}
              >
                Calculate Daily and Monthly Tables
              </TransparentButton>
            </div>
          </>
        )}
      </BorderComponentContainer>
    </>
  );
};

export default AccountActions;
