import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  createNoteCategory,
  updateNoteCategory,
} from '../../../api/administration';
import AppToast from '../../Toast';
import { Intent } from '@blueprintjs/core';
import NoteCategoriesForm from '../../forms/global-settings-forms/NoteCategoriesForm';

const NoteCategoryDialogBox = ({
  isOpen,
  title,
  noteCategoryObj,
  handleDialogClose,
  fetchAllNotes,
  fetchSingleNoteCategory,
}) => {
  const [noteCategoryName, setNoteCategoryName] = useState('');
  const [status, setStatus] = useState('');
  const [noteCategoryId, setNoteCategoryId] = useState(undefined);

  useEffect(() => {
    if (noteCategoryObj) {
      setNoteCategoryName(noteCategoryObj.noteCategoryName || '');
      setStatus(noteCategoryObj.status || '');
      setNoteCategoryId(noteCategoryObj.noteCategoryId || undefined);
    }
  }, [noteCategoryObj]);

  const handleCreate = (notes) => {
    createNoteCategory(notes)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllNotes();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create Note Category.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleDialogClose());
  };

  const handleEdit = (notes) => {
    updateNoteCategory(noteCategoryId, notes)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchSingleNoteCategory(noteCategoryId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit Note Category.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleDialogClose());
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleDialogClose}
      isCloseButtonShown={false}
    >
      <NoteCategoriesForm
        title={title}
        onClose={handleDialogClose}
        noteCategoryName={noteCategoryName}
        status={status}
        handleEdit={handleEdit}
        handleCreate={handleCreate}
      />
    </Dialog>
  );
};

export default NoteCategoryDialogBox;
