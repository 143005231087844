import { combineReducers } from 'redux';

const completeAccounts = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_COMPLETE_ACCOUNTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_COMPLETE_ACCOUNTS_REJECTED':
    case 'FETCH_COMPLETE_ACCOUNTS_PENDING':
      return [];
    case 'FETCH_ACCOUNT_FULFILLED':
      return state.map((account) => {
        if (account.accountId === action.payload.data[0].accountId) {
          return action.payload.data[0];
        }
        return account;
      });
    default:
      return state;
  }
};

const isCompleteAccountsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_COMPLETE_ACCOUNTS_PENDING':
      return true;
    case 'FETCH_COMPLETE_ACCOUNTS_REJECTED':
    case 'FETCH_COMPLETE_ACCOUNTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const reviewAccounts = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_REVIEW_ACCOUNTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_REVIEW_ACCOUNTS_REJECTED':
    case 'FETCH_REVIEW_ACCOUNTS_PENDING':
      return [];
    case 'FETCH_ACCOUNT_FULFILLED':
      return state.map((account) => {
        if (account.accountId === action.payload.data[0].accountId) {
          return action.payload.data[0];
        }
        return account;
      });
    default:
      return state;
  }
};

const isFetchingReviewAccounts = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_REVIEW_ACCOUNTS_PENDING':
      return true;
    case 'FETCH_REVIEW_ACCOUNTS_REJECTED':
    case 'FETCH_REVIEW_ACCOUNTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const accountCategories = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_CATEGORIES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_CATEGORIES_REJECTED':
    case 'FETCH_ACCOUNT_CATEGORIES_PENDING':
      return [];
    default:
      return state;
  }
};

const accountTypes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_TYPES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_TYPES_REJECTED':
    case 'FETCH_ACCOUNT_TYPES_PENDING':
      return [];
    default:
      return state;
  }
};

const clientAccounts = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_ACCOUNTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_ACCOUNTS_REJECTED':
    case 'FETCH_CLIENT_ACCOUNTS_PENDING':
      return [];
    default:
      return state;
  }
};

const isClientAccountsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_ACCOUNTS_PENDING':
      return true;
    case 'FETCH_CLIENT_ACCOUNTS_REJECTED':
    case 'FETCH_CLIENT_ACCOUNTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const accountLinks = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_LINKS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_LINKS_REJECTED':
    case 'FETCH_ACCOUNT_LINKS_PENDING':
      return [];
    default:
      return state;
  }
};

const account = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_FULFILLED':
      return action.payload.data[0];
    case 'FETCH_ACCOUNT_PENDING':
      return {};
    default:
      return state;
  }
};

const isAccountFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_PENDING':
      return true;
    case 'FETCH_ACCOUNT_REJECTED':
    case 'FETCH_ACCOUNT_FULFILLED':
      return false;
    default:
      return state;
  }
};

const clientAccountGroups = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_ACCOUNT_GROUPS_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_ACCOUNT_GROUPS_REJECTED':
    case 'FETCH_CLIENT_ACCOUNT_GROUPS_PENDING':
      return [];
    default:
      return state;
  }
};

const accountGroupAssociations = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_GROUP_ASSOCIATIONS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_GROUP_ASSOCIATIONS_REJECTED':
    case 'FETCH_ACCOUNT_GROUP_ASSOCIATIONS_PENDING':
      return [];
    default:
      return state;
  }
};

const accountGroups = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_GROUPS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_GROUPS_PENDING':
    case 'FETCH_ACCOUNT_GROUPS_REJECTED':
      return [];
    default:
      return state;
  }
};

const isFetchingAccountGroups = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_GROUPS_PENDING':
      return true;
    case 'FETCH_ACCOUNT_GROUPS_FULFILLED':
    case 'FETCH_ACCOUNT_GROUPS_REJECTED':
      return false;
    default:
      return state;
  }
};

const accountsByGroup = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNTS_BY_GROUP_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNTS_BY_GROUP_PENDING':
    case 'FETCH_ACCOUNTS_BY_GROUP_REJECTED':
      return [];
    default:
      return state;
  }
};

const isFetchingAccountsByGroup = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNTS_BY_GROUP_PENDING':
      return true;
    case 'FETCH_ACCOUNTS_BY_GROUP_FULFILLED':
    case 'FETCH_ACCOUNTS_BY_GROUP_REJECTED':
      return false;
    default:
      return state;
  }
};

const accounts = combineReducers({
  completeAccounts,
  isCompleteAccountsFetching,
  reviewAccounts,
  isFetchingReviewAccounts,
  accountCategories,
  accountTypes,
  clientAccounts,
  accountLinks,
  account,
  isAccountFetching,
  clientAccountGroups,
  accountGroupAssociations,
  isClientAccountsFetching,
  accountGroups,
  isFetchingAccountGroups,
  accountsByGroup,
  isFetchingAccountsByGroup,
});

export default accounts;
