import React, { useEffect } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import NoteForm from '../../forms/notes/NoteForm';
import { connect } from 'react-redux';
import {
  fetchNoteCategories,
  fetchAllNotes,
  fetchClientNotes,
  fetchAccountNotes,
  fetchBatchNotes,
  fetchSingleNote,
} from '../../../actions/notes';
import { fetchUserList } from '../../../actions/users';
import { createNote, updateNote } from '../../../api/notes';
import AppToast from '../../Toast';
import { fetchSingleClientBatch } from '../../../actions/data-ingestion';

const NoteDialogBase = ({
  appUser,
  isOpen,
  title,
  note,
  clientId,
  batchId,
  userList,
  handleClose,
  noteCategories,
  fetchUserList,
  fetchAllNotes,
  fetchNoteCategories,
  fetchClientNotes,
  fetchAccountNotes,
  fetchBatchNotes,
  fetchSingleNote,
  accountId,
  templateId,
  setResetInitialFilter,
  isDashboard,
  fetchSingleClientBatch,
}) => {
  useEffect(() => {
    fetchUserList();
    fetchNoteCategories();
  }, [fetchUserList, fetchNoteCategories]);

  const handleCreate = (note) => {
    note = {
      authorId: appUser.userId,
      tableLinkedTo: clientId
        ? 'clients'
        : accountId
        ? 'accounts'
        : templateId
        ? 'templates'
        : '',
      linkedId: clientId
        ? clientId
        : accountId
        ? accountId
        : templateId
        ? templateId
        : '',
      ...note,
    };
    createNote(note)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        clientId && fetchClientNotes(clientId);
        accountId && fetchAccountNotes(accountId);
        batchId && fetchSingleClientBatch(batchId);
        isDashboard && fetchAllNotes();
        setResetInitialFilter(true);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to add note.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleEdit = (note, noteId) => {
    updateNote(note, noteId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to update note.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        clientId && fetchClientNotes(clientId);
        accountId && fetchAccountNotes(accountId);
        isDashboard && fetchAllNotes();
        batchId && fetchBatchNotes(batchId);
        fetchSingleNote(noteId);
        setResetInitialFilter(true);
        handleClose();
      });
  };
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
      className='large-dialog'
    >
      <NoteForm
        note={note}
        title={title}
        userList={userList}
        noteCategories={noteCategories}
        handleClose={handleClose}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  userList: state.users.userList,
  noteCategories: state.notes.noteCategories,
});

const NoteDialog = connect(mapStateToProps, {
  fetchUserList,
  fetchAllNotes,
  fetchNoteCategories,
  fetchClientNotes,
  fetchAccountNotes,
  fetchBatchNotes,
  fetchSingleNote,
  fetchSingleClientBatch,
})(NoteDialogBase);

export default NoteDialog;
