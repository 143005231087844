import { combineReducers } from 'redux';

const appRoles = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ROLES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ROLES_REJECTED':
      return null;
    default:
      return state;
  }
};

const isAppUserFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ROLES_PENDING':
      return true;
    case 'FETCH_ROLES_REJECTED':
    case 'FETCH_ROLES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const roles = combineReducers({
  appRoles,
  isAppUserFetching,
});

export default roles;
