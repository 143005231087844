import React, { useEffect, useState } from 'react';
import colors from '../../utils/colors';
import {
  BorderComponentContainer,
  BoldText,
  TransparentButton,
} from '../../components/styled-components';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import { monthYearOnly } from '../../utils/functions';
import { fetchCashVestSubScores } from '../../api/analysis';
import CashVestScoreWidget from '../../components/widgets/cashVest/CashVestScoreWidget';
import ScoreBreakdownWidget from '../../components/widgets/cashVest/ScoreBreakdownWidget';
import LoadSpinner from '../../components/LoadSpinner';

const CashVestScoresBreakdown = ({
  appUser,
  selectedRow,
  handleDeleteContainer,
  handleOpenEditDialog,
}) => {
  const [showBox, setShowBox] = useState(false);
  const [cashVestSubScores, setCashVestSubScores] = useState([]);
  const [
    isFetchingCashVestSubScores,
    setIsFetchingCashVestSubScores,
  ] = useState(false);

  useEffect(() => {
    if (showBox) {
      setIsFetchingCashVestSubScores(true);
      fetchCashVestSubScores(selectedRow.cashVestId)
        .then((res) => setCashVestSubScores(res.data))
        .catch(() => setCashVestSubScores([]))
        .finally(() => setIsFetchingCashVestSubScores(false));
    }
  }, [showBox, selectedRow, setIsFetchingCashVestSubScores]);

  const handleShowBox = () => setShowBox(!showBox);

  return (
    <BorderComponentContainer
      borderRadius='5px 5px 0 0'
      borderBottom='0'
      padding='1rem 0'
      marginBottom={'2rem'}
      marginTop='25px'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: showBox ? '1rem' : '0',
          borderBottom: showBox ? `1px solid ${colors.lightText}` : '',
        }}
      >
        <Icon
          icon={showBox ? iconValue.caretUp : iconValue.caretDown}
          iconSize={20}
          color={colors.primary}
          style={{ cursor: 'pointer', marginLeft: '1rem' }}
          onClick={handleShowBox}
        />
        <BoldText margin={'0 0 0 1rem'}>
          {selectedRow.startDate
            ? `cashVest Score - ${monthYearOnly(
                selectedRow.startDate
              )} - ${monthYearOnly(selectedRow.endDate)}`
            : 'cashVest Score'}
        </BoldText>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <TransparentButton
            margin='0 10px 0 0'
            onClick={() => handleDeleteContainer(selectedRow)}
          >
            Hide CashVest Score
          </TransparentButton>
          <TransparentButton
            margin='0 10px 0 0'
            onClick={() => handleOpenEditDialog(selectedRow)}
          >
            Edit
          </TransparentButton>
        </div>
      </div>
      {isFetchingCashVestSubScores && showBox && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}
      {!isFetchingCashVestSubScores && showBox && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '1rem 1rem 0',
          }}
        >
          <CashVestScoreWidget cashVestSubScores={cashVestSubScores} />
          <ScoreBreakdownWidget
            cashVestSubScores={cashVestSubScores}
            widgetTitle='Value Score'
            scoreName={'valueScore'}
            starsName={'valueScoreStar'}
            commentary={'valueScoreComment'}
          />
          <ScoreBreakdownWidget
            cashVestSubScores={cashVestSubScores}
            widgetTitle='Liquidity Proficiency'
            scoreName={'liquidityProficiency'}
            starsName={'liquidityProficiencyStar'}
            commentary={'liquidityProficiencyComment'}
          />
          <ScoreBreakdownWidget
            cashVestSubScores={cashVestSubScores}
            widgetTitle='Warnick Potential Rate Indicator'
            scoreName={'warnickRate'}
            starsName={'warnickRateStar'}
            commentary={'warnickRateComment'}
          />
          <ScoreBreakdownWidget
            cashVestSubScores={cashVestSubScores}
            widgetTitle='Cash Flow Optimization'
            scoreName={'cashflow'}
            starsName={'cashflowStar'}
            commentary={'cashflowComment'}
          />
          <ScoreBreakdownWidget
            cashVestSubScores={cashVestSubScores}
            widgetTitle='Investment Policy'
            scoreName={'investment'}
            starsName={'investmentStar'}
            commentary={'investmentComment'}
          />
        </div>
      )}
    </BorderComponentContainer>
  );
};

export default CashVestScoresBreakdown;
