import { combineReducers } from 'redux';

const userClients = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_USER_CLIENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_USER_CLIENTS_REJECTED':
    case 'FETCH_USER_CLIENTS_PENDING':
      return [];
    default:
      return state;
  }
};

const reportsInProgress = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_REPORTS_IN_PROGRESS_FULFILLED':
      return action.payload.data;
    case 'FETCH_REPORTS_IN_PROGRESS_REJECTED':
    case 'FETCH_REPORTS_IN_PROGRESS_PENDING':
      return [];
    default:
      return state;
  }
};

const isReportsInProgressFetching = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_REPORTS_IN_PROGRESS_PENDING':
      return true;
    case 'FETCH_REPORTS_IN_PROGRESS_REJECTED':
    case 'FETCH_REPORTS_IN_PROGRESS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const isUserClientsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_USER_CLIENTS_PENDING':
      return true;
    case 'FETCH_USER_CLIENTS_REJECTED':
    case 'FETCH_USER_CLIENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const negotiatedRates = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_NEGOTIATED_RATES_FULFILLED':
      return action.payload.data;
    case 'FETCH_NEGOTIATED_RATES_PENDING':
    case 'FETCH_NEGOTIATED_RATES_REJECTED':
      return [];
    case 'FETCH_SINGLE_NEGOTIATED_RATE_FULFILLED':
      return state.map((rate) => {
        if (rate.negotiatedRateId === action.payload.data[0].negotiatedRateId) {
          return action.payload.data[0];
        }
        return rate;
      });
    case 'FETCH_SINGLE_NEGOTIATED_RATE_PENDING':
    case 'FETCH_SINGLE_NEGOTIATED_RATE_REJECTED':
      return state;
    default:
      return state;
  }
};

const isFetchingNegotiatedRates = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_NEGOTIATED_RATES_PENDING':
      return true;
    case 'FETCH_NEGOTIATED_RATES_FULFILLED':
    case 'FETCH_NEGOTIATED_RATES_REJECTED':
      return false;
    default:
      return state;
  }
};

const maturingInvestments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_MATURING_INVESTMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_MATURING_INVESTMENTS_REJECTED':
    case 'FETCH_MATURING_INVESTMENTS_PENDING':
      return [];
    default:
      return state;
  }
};

const isMaturingInvestmentsFetching = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_MATURING_INVESTMENTS_PENDING':
      return true;
    case 'FETCH_MATURING_INVESTMENTS_REJECTED':
    case 'FETCH_MATURING_INVESTMENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const dashboard = combineReducers({
  userClients,
  isUserClientsFetching,
  reportsInProgress,
  isReportsInProgressFetching,
  negotiatedRates,
  isFetchingNegotiatedRates,
  maturingInvestments,
  isMaturingInvestmentsFetching,
});

export default dashboard;
