import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { AlignCenter, WhiteButton } from '../styled-components';
import StagingTransactionsInspectTable from '../table/StagingTransactionsInspectTable';
import { connect } from 'react-redux';
import { fetchTransactionKeywords } from '../../actions/transactions';
import { find } from 'lodash';
import AppToast from '../Toast';
import TransactionBulkEditDialogBox from './transactions/TransactionBulkEditDialogBox';
import TransactionEditDialogBox from './transactions/TransactionEditDialogBox';
import {
  fetchStagingPaginatedData,
  bulkUpdateStagingTransactions,
  updateStagingTransaction,
} from '../../api/transactions';

const TransactionBulkInspectDialogBase = ({
  isOpen,
  clientName,
  bankName,
  batchAccount,
  accountType,
  handleClose,
  handleBulkInspectDialogOpen,
  fetchTransactionKeywords,
  fetchSingleStagingTransaction,
  fetchSingleBatchAccount,
  appUser,
  keywords,
  isServerSidePagination,
  batchAccountIds,
  ...props
}) => {
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenBulkEditDialog, setIsOpenBulkEditDialog] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [stagingTransaction, setStagingTransaction] = useState({});
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  // pagination info
  // pageSize and pageIndex used to refetch data after edit/delete
  const [pageSize] = useState(50);
  const [pageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(undefined);
  const [resultCount, setResultCount] = useState(undefined);
  const [stagingTransactions, setStagingTransactions] = useState([]);
  const [isPaginatedDataFetching, setIsPaginatedDataFetching] = useState(false);

  useEffect(() => {
    fetchTransactionKeywords();
  }, [fetchTransactionKeywords]);

  // for server side pagination. only call this function if needed so use hook useCallback
  const updatePaginate = useCallback(
    (
      { pageSize, pageIndex },
      filters = [],
      searchTerm = '',
      sortOrder = {}
    ) => {
      setIsPaginatedDataFetching(true);
      fetchStagingPaginatedData(
        batchAccountIds,
        pageSize,
        pageIndex,
        filters,
        searchTerm,
        sortOrder
      )
        .then((res) => {
          setStagingTransactions(res.data.results);
          setPageCount(res.data.pageCount);
          setResultCount(res.data.resultCount);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsPaginatedDataFetching(false));
    },
    [batchAccountIds]
  );

  const handleOpenEditDialog = (stagingTransaction) => {
    setStagingTransaction(stagingTransaction);
    setKeyword(
      keywords.length
        ? find(keywords, ['label', stagingTransaction.keyword]).value
        : ''
    );
    setIsOpenEditDialog(true);
  };

  const handleBulkEditDialogOpen = (transactions) => {
    setSelectedTransactions(transactions);
    setIsOpenBulkEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setStagingTransaction({});
    setIsOpenEditDialog(false);
  };

  const handleBulkEditDialogClose = () => {
    setKeyword('');
    setIsOpenBulkEditDialog(false);
  };

  const handleBulkEditTransaction = ({
    amount,
    keyword,
    transactionDescription,
    transactionDate,
  }) => {
    const stagingTransactionIds = selectedTransactions.map(
      (transaction) => transaction.transactionId
    );
    const transaction = {
      amount,
      keyword,
      date: transactionDate === 'Invalid date' ? '' : transactionDate,
      description: transactionDescription,
      stagingTransactionIds,
    };
    bulkUpdateStagingTransactions(transaction)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to edit transaction.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleBulkEditDialogClose();
      });
  };

  const handleEditTransaction = async ({
    transactionId,
    amount,
    transactionDescription,
    transactionDate,
    keyword,
  }) => {
    const transaction = {
      stagingTransactionId: transactionId,
      amount,
      description: transactionDescription,
      keyword,
      date: transactionDate,
    };
    updateStagingTransaction(transaction)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit transaction',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        updatePaginate({ pageSize, pageIndex });
        handleEditDialogClose();
      });
  };

  const Title = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {`Inspect/Edit: ${clientName} / ${bankName}`}
      </div>
    );
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={<Title />}
        className={'custom-dialog-header large-dialog'}
        isCloseButtonShown={false}
      >
        <AlignCenter justifyContent='space-between'>
          <StagingTransactionsInspectTable
            stagingTransactions={stagingTransactions}
            handleOpenEditDialog={handleOpenEditDialog}
            handleBulkEditDialogOpen={handleBulkEditDialogOpen}
            isFetching={isPaginatedDataFetching}
            appUser={appUser}
            updatePaginate={updatePaginate}
            pageCount={pageCount}
            resultCount={resultCount}
            isServerSidePagination={isServerSidePagination}
            {...props}
          />
        </AlignCenter>
        <AlignCenter justifyContent='flex-end' padding='0px 20px 0px 0px'>
          <WhiteButton type='button' onClick={handleClose}>
            CLOSE
          </WhiteButton>
        </AlignCenter>
      </Dialog>
      <TransactionEditDialogBox
        isOpen={isOpenEditDialog}
        title='Edit Transaction Information'
        handleClose={handleEditDialogClose}
        transaction={stagingTransaction}
        keywords={keywords}
        keyword={keyword}
        handleEditTransaction={handleEditTransaction}
      />
      <TransactionBulkEditDialogBox
        title={`Edit Selected Transactions (${selectedTransactions.length})`}
        isOpen={isOpenBulkEditDialog}
        handleClose={handleBulkEditDialogClose}
        keywords={keywords}
        handleBulkEditTransaction={handleBulkEditTransaction}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  keywords: state.transactions.allTransactionKeywords,
  appUser: state.auth.appUser,
});

const TransactionBulkInspectDialog = connect(mapStateToProps, {
  fetchTransactionKeywords,
})(TransactionBulkInspectDialogBase);

export default TransactionBulkInspectDialog;
