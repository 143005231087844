import styled from 'styled-components';
import colors from '../../../utils/colors';

export const QuandlContainer = styled.div`
  display: flex;
  margin-left: 32px;
  width: 95%;
`;

export const Data = styled.div`
  flex-basis: 40px;
  flex-grow: 1;
`;

export const Header = styled.p`
  font-size: 1rem;
  color: white;

  @media screen and (min-width: 120px) and (max-width: 1350px) {
    font-size: 0.6rem;
  }

  @media screen and (min-width: 1351px) and (max-width: 1400px) {
    font-size: 0.65rem;
  }

  @media screen and (min-width: 1401px) and (max-width: 1519px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1520px) and (max-width: 1800px) {
    font-size: 0.75rem;
  }
`;

export const Text = styled.p`
  font-size: 0.75rem;
  color: white;

  @media screen and (min-width: 1200px) and (max-width: 1450px) {
    font-size: 0.5rem;
  }

  @media screen and (min-width: 1451px) and (max-width: 1740px) {
    font-size: 0.6rem;
  }
`;

export const Number = styled.p`
  font-size: 0.75rem;
  color: ${colors.green};
  font-weight: 700;
  padding-bottom: 3px;

  @media screen and (min-width: 1520px) and (max-width: 1829px) {
    font-size: 0.85rem;
  }

  @media screen and (min-width: 1830px) {
    font-size: 1rem;
  }
`;
