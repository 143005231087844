import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  fetchAccountInvestmentBalance,
  fetchAmountsInvestedAccounts,
  fetchAccountInterestRates,
} from '../../../../../../actions/investments';
import { fetchAccountInvestmentPaginatedData } from '../../../../../../api/investments';
import InvestmentTable from '../../../../../../components/table/InvestmentTable';
import InvestmentSummary from '../../../../../../components/investment-summary/InvestmentSummary';

const AccountInvestmentsBase = ({
  investmentAccountBalance,
  isInvestmentAccountGraphFetching,
  accountId,
  appUser,
  amountsInvestedAccounts,
  isAmountsInvestedAccountsFetching,
  accountComponent,
  fetchAccountInterestRates,
  interestRate,
  isFetchingInterestRate,
  isServerSidePagination,
  ...props
}) => {
  const { fetchAccountInvestmentBalance, fetchAmountsInvestedAccounts } = props;

  // pagination info
  // pageSize and pageIndex used to refetch data after edit/delete
  const [pageSize] = useState(50);
  const [pageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(undefined);
  const [resultCount, setResultCount] = useState(undefined);
  const [allAccountInvestments, setAllAccountInvestments] = useState([]);
  const [isPaginatedDataFetching, setIsPaginatedDataFetching] = useState(false);

  // for server side pagination. only call this function if needed so use hook useCallback
  const updatePaginate = useCallback(
    (
      { pageSize, pageIndex },
      filters = [],
      searchTerm = '',
      sortOrder = {}
    ) => {
      setIsPaginatedDataFetching(true);
      fetchAccountInvestmentPaginatedData(
        accountId,
        pageSize,
        pageIndex,
        filters,
        searchTerm,
        sortOrder
      )
        .then((res) => {
          setAllAccountInvestments(res.data.results);
          setPageCount(res.data.pageCount);
          setResultCount(res.data.resultCount);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsPaginatedDataFetching(false));
    },
    [accountId]
  );

  useEffect(() => {
    fetchAccountInvestmentBalance(accountId);
    fetchAmountsInvestedAccounts(accountId, 'account');
    fetchAccountInterestRates(accountId);
  }, [
    fetchAccountInvestmentBalance,
    fetchAmountsInvestedAccounts,
    fetchAccountInterestRates,
    accountId,
  ]);

  return (
    <>
      <InvestmentSummary
        appUser={appUser}
        accountId={accountId}
        investmentAccountBalance={investmentAccountBalance}
        isInvestmentAccountGraphFetching={isInvestmentAccountGraphFetching}
        amountsInvestedAccounts={amountsInvestedAccounts}
        isAmountsInvestedAccountsFetching={isAmountsInvestedAccountsFetching}
        accountComponent={accountComponent}
        interestRate={interestRate}
        isFetchingInterestRate={isFetchingInterestRate}
        {...props}
      />
      <InvestmentTable
        appUser={appUser}
        investments={allAccountInvestments}
        isAllInvestmentsFetching={isPaginatedDataFetching}
        title={'All Investments'}
        accountId={accountId}
        isServerSidePagination={isServerSidePagination}
        updatePaginate={updatePaginate}
        pageCount={pageCount}
        resultCount={resultCount}
        pageSize={pageSize}
        pageIndex={pageIndex}
        {...props}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  investmentAccountBalance: state.investments.investmentAccountBalance,
  isInvestmentAccountGraphFetching:
    state.investments.isInvestmentAccountGraphFetching,
  amountsInvestedAccounts: state.investments.amountsInvestedAccounts,
  isAmountsInvestedAccountsFetching:
    state.investments.isAmountsInvestedAccountsFetching,
  interestRate: state.investments.interestRate,
  isFetchingInterestRate: state.investments.inInterestRateFetching,
});

const AccountInvestments = connect(mapStateToProps, {
  fetchAccountInvestmentBalance,
  fetchAmountsInvestedAccounts,
  fetchAccountInterestRates,
})(AccountInvestmentsBase);

export default AccountInvestments;
