import React, { useState } from 'react';
import {
  MessageText,
  GlobalSettingsDescription,
  ResponsiveCenter,
} from '../styled-components';
import KeywordCategoriesTable from '../../components/table/KeywordCategoriesTable';
import KeywordCategoryDialogBox from '../dialog/global-settings-dialog/KeywordCategoryDialogBox';
import KeywordCategoriesDeactivateDialogBox from '../dialog/global-settings-dialog/KeywordCategoriesDeactivateDialogBox';

const ManageKeywordCategories = ({
  appUser,
  keywordCategories,
  isFetching,
  fetchKeywordCategories,
  fetchSingleKeywordCategory,
  ...props
}) => {
  const [title, setTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [keywordCategoryObj, setKeywordCategoryObj] = useState({});

  const [isDeactivate, setIsDeactivate] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [categories, setCategories] = useState([]);

  const openAdd = () => {
    setTitle('Add Keyword Category');
    setIsOpen(true);
  };

  const openEdit = (selectedRow) => {
    setKeywordCategoryObj(selectedRow);
    setTitle('Edit Keyword Category');
    setIsOpen(true);
  };

  const handleClose = () => {
    setTitle('');
    setIsOpen(false);
    setKeywordCategoryObj({});
  };

  const closeDeactivateDialog = () => {
    setCategoryIds([]);
    setIsDeactivate(false);
  };

  const openDeleteDialog = (selectedRows) => {
    if (selectedRows instanceof Array) {
      setCategoryIds(selectedRows.map((row) => row.categoryId));
      setCategories(selectedRows);
    } else {
      setCategoryIds([selectedRows.categoryId]);
      setCategories([selectedRows]);
    }
    setIsDeactivate(true);
  };

  return (
    <>
      <KeywordCategoriesDeactivateDialogBox
        isOpen={isDeactivate}
        handleClose={closeDeactivateDialog}
        title={
          categoryIds.length > 1
            ? `Deactivate selected Keyword Categories (${categoryIds.length})`
            : 'Deactivate Keyword Category'
        }
        categoryIds={categoryIds}
        categories={categories}
        fetchKeywordCategories={fetchKeywordCategories}
      />

      <KeywordCategoryDialogBox
        handleDialogClose={handleClose}
        isOpen={isOpen}
        title={title}
        categoryObj={keywordCategoryObj}
        fetchKeywordCategories={fetchKeywordCategories}
        fetchSingleKeywordCategory={fetchSingleKeywordCategory}
      />

      <ResponsiveCenter className={'global-setting'} minWidth={'765px'}>
        <GlobalSettingsDescription>
          <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
            Manage Keyword Categories
          </MessageText>
          <MessageText>
            Create and manage keyword Categories for use in the application.
          </MessageText>
        </GlobalSettingsDescription>
        <KeywordCategoriesTable
          appUser={appUser}
          keywordCategories={keywordCategories}
          isFetching={isFetching}
          openAdd={openAdd}
          openEdit={openEdit}
          openDeleteDialog={openDeleteDialog}
          {...props}
        />
      </ResponsiveCenter>
    </>
  );
};

export default ManageKeywordCategories;
