import { combineReducers } from 'redux';

const allUsers = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_USERS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_USERS_REJECTED':
    case 'FETCH_ALL_USERS_PENDING':
      return [];
    case 'FETCH_SINGLE_USER_FULFILLED':
      return state.map((user) => {
        if (user.userId === action.payload.data[0].userId) {
          return action.payload.data[0];
        }
        return user;
      });
    case 'FETCH_SINGLE_USER_REJECTED':
    case 'FETCH_SINGLE_USER_PENDING':
      return state;
    default:
      return state;
  }
};

const isAllUsersFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_USERS_PENDING':
      return true;
    case 'FETCH_ALL_USERS_REJECTED':
    case 'FETCH_ALL_USERS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const userList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_USER_LIST_FULFILLED':
      return action.payload.data;
    case 'FETCH_USER_LIST_PENDING':
    case 'FETCH_USER_LIST_REJECTED':
      return [];
    default:
      return state;
  }
};

const users = combineReducers({
  allUsers,
  userList,
  isAllUsersFetching,
});

export default users;
