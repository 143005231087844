import React, { useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { AlignCenter, WhiteButton } from '../../styled-components';
import LoadSpinner from '../../LoadSpinner';
import ExtractionTable from '../../table/ExtractionTable';
import TransactionTemplateDialog from '../../../components/dialog/template-dialog/TransactionTemplateDialog';
import ExtractionWarningDialog from '../../../components/dialog/data-ingestion/ExtractionWarningDialog';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../../components/Toast';
import { updateTemplate } from '../../../api/templates';
import axios from 'axios';
import {
  fetchExtractionStatus,
  retryExtraction,
  downloadIngestionFile,
} from '../../../api/data-ingestion';
import { downloadFiles } from '../../../utils/functions';

// dialog for extraction success/error
const ExtractionConfirmDialog = ({
  closeDialog,
  isOpen,
  appUser,
  isFetching,
  extractionList,
  setExtractionList,
  batchId,
  setIsExtractionListFetching,
  ...props
}) => {
  const [template, setTemplate] = useState({});
  const [isTransactionTemplateOpen, setIsTransactionTemplateOpen] =
    useState(false);
  const [isAddFirstCellTemplate, setIsAddFirstCellTemplate] = useState(false);
  const [isHeaderRowExtraction, setIsHeaderRowExtraction] = useState(false);
  const [headerRowData, setHeaderRowData] = useState(undefined);
  const [isExtractionWarningDialogOpen, setIsExtractionWarningDialogOpen] =
    useState(false);
  const [rowData, setRowData] = useState(undefined);
  const [isRetryingExtraction, setIsRetryingExtraction] = useState(false);
  const [fileAuditId, setFileAuditId] = useState(undefined);

  /**
   * Submits a template info.
   * Depending on the template the selected values submitted are different
   * Runs on formik submit
   * @param {Obj} values - Object with the fields
   * @param {string} type - the type of the template to submit [Header, Cell]
   */
  const handleSubmitTemplate = (values, type) => {
    let template = {};
    let copiedValues = Object.assign({}, values);

    // for user using the add first cell button in table
    // we know the type will always come into this function as undefined
    // set to cell
    if (type === undefined) {
      type = 'Cell';
    }

    // type will always be undefined if coming from extraction dialog form so will always be set to Cell with above if statement
    // if on the header row extraction dialog form, we change type to Header
    if (isHeaderRowExtraction) {
      type = 'Header';
    }

    // if type is Header remove cellHeaderData (first cell text variable)
    // this if check must be before defining cellHeaderData and delete copiedValues.cellHeaderData
    if (type === 'Header') {
      delete copiedValues.cellHeaderData;
    }

    // if the type is 'Cell' (can either be Header or Cell) create new variable to add to template (object sent to backend)
    const cellHeaderData = copiedValues.cellHeaderData;
    // and remove from jsonTransform
    delete copiedValues.cellHeaderData;

    // IF account number comes from file name, transform value to send to backend
    // need to change fileNameIncluded from true to 1
    // copy value, and set new value 1 and rename variable for backend to fileNameIncluded
    // ELSE, if account number is present in values delete fileNameIncluded and send rest of values.
    // same concept for special case variable
    if (values.fileNameIncluded === true && values.specialCase === true) {
      let fileNameVariable = copiedValues.fileNameIncluded;
      let specialCaseVariable = copiedValues.specialCase;
      fileNameVariable = 1;
      specialCaseVariable = 1;
      const fileNameIncluded = fileNameVariable;
      const specialCase = specialCaseVariable;
      copiedValues = { ...copiedValues, fileNameIncluded, specialCase };
      // if type is Header don't send cellHeaderData to backend
      if (type === 'Header') {
        template = {
          type: type,
          fileAuditId,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    } else if (values.fileNameIncluded === true) {
      let fileNameVariable = copiedValues.fileNameIncluded;
      fileNameVariable = 1;
      const fileNameIncluded = fileNameVariable;
      copiedValues = { ...copiedValues, fileNameIncluded };
      delete copiedValues.specialCase;
      if (type === 'Header') {
        template = {
          fileAuditId,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    } else if (values.specialCase === true) {
      let specialCaseVariable = copiedValues.specialCase;
      specialCaseVariable = 1;
      const specialCase = specialCaseVariable;
      copiedValues = { ...copiedValues, specialCase };
      delete copiedValues.fileNameIncluded;
      if (type === 'Header') {
        template = {
          fileAuditId,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    } else {
      delete copiedValues.fileNameIncluded;
      delete copiedValues.specialCase;
      if (type === 'Header') {
        template = {
          fileAuditId,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    }
    updateTemplate(template)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit template.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        closeTransactionDialog();
      });
  };

  const closeTransactionDialog = () => {
    setIsTransactionTemplateOpen(false);
    setTemplate({});
    setIsAddFirstCellTemplate(false);
    setIsHeaderRowExtraction(false);
    setFileAuditId(undefined);
    setHeaderRowData(undefined);
  };

  const handleOpenTemplate = (row, type) => {
    setTemplate({});
    if (type === 'Cell') {
      setIsAddFirstCellTemplate(true);
      setIsTransactionTemplateOpen(true);
      setFileAuditId(row.fileAuditId);
    }
    // need to set cellHeaderRow Data from row to state to be passed to cellHeaderData
    if (type === 'Header') {
      setIsTransactionTemplateOpen(true);
      setIsHeaderRowExtraction(true);
      setHeaderRowData(row.headerRow);
      setFileAuditId(row.fileAuditId);
    }
  };

  const handleOpenExtractionWarningDialog = (selectedRows) => {
    setIsExtractionWarningDialogOpen(true);
    setRowData(selectedRows);
  };

  const handleCloseExtractionWarningDialog = () => {
    setIsExtractionWarningDialogOpen(false);
    setRowData(undefined);
  };

  const handleRetryExtraction = () => {
    setIsRetryingExtraction(true);
    // if bulk selection need to filter out rows that are only failed status and map array, else use single id set as array
    const isArray = Array.isArray(rowData);
    let body;
    if (isArray) {
      body = rowData
        .filter((item) => item.fileStatus === 'Failed')
        .map((rows) => rows.fileAuditId);
    } else {
      body = [rowData.fileAuditId];
    }
    retryExtraction(body)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to retry file(s).',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsRetryingExtraction(false);
        setIsExtractionWarningDialogOpen(false);
        setIsExtractionListFetching(true);
        fetchExtractionStatus(batchId)
          .then((res) => {
            // remove counts from end off array
            setExtractionList(res.data.slice(0, -3));
          })
          .catch((err) => console.error(err))
          .finally(() => {
            setIsExtractionListFetching(false);
          });
      });
  };

  const handleDownloadFile = async (rowData) => {
    const fileAuditId = rowData.fileAuditId;
    const stageFolderName = 'canonical';
    const fileName = rowData.fileName.substring(
      0,
      rowData.fileName.lastIndexOf('.')
    );
    let fileNameWithExtension
    // eslint-disable-next-line 
    const extractFileName = (presignedURLStr) => presignedURLStr.match('(?<=\/)([^\/]+)(?=\\?)').pop();

    const presignedURL = await downloadIngestionFile(
      fileAuditId, fileName, stageFolderName
    )
      .then((res) => {
        fileNameWithExtension = extractFileName(res.data);
        return res.data;
      })
      .catch((err) => console.error(err));

    delete axios.defaults.headers.common['Authorization'];

    axios
      .get(presignedURL, { responseType: 'blob' })
      .then((res) => {
        // call download from utils function
        downloadFiles(res, fileNameWithExtension);
        AppToast.show({
          message: 'File downloaded successfully!',
          intent: Intent.SUCCESS,
        });
      })
      .catch(() => {
        AppToast.show({
          message: 'Failed to download file.',
          intent: Intent.DANGER,
        });
      });
  };

  return (
    <>
      <Dialog isOpen={isOpen} onClose={closeDialog} className={'large-dialog'}>
        <AlignCenter justifyContent='space-between'>
          {isFetching ? (
            <div
              style={{ width: '100%', marginTop: '2rem', marginBottom: '1rem' }}
            >
              <LoadSpinner size={100} />
            </div>
          ) : (
            <ExtractionTable
              appUser={appUser}
              extractionList={extractionList}
              handleDownloadFile={handleDownloadFile}
              handleOpenTemplate={handleOpenTemplate}
              handleOpenExtractionWarningDialog={
                handleOpenExtractionWarningDialog
              }
              {...props}
            />
          )}
        </AlignCenter>
        <AlignCenter justifyContent='flex-end' padding='0px 20px 0px 0px'>
          <WhiteButton type='button' onClick={closeDialog}>
            CLOSE
          </WhiteButton>
        </AlignCenter>
      </Dialog>
      <TransactionTemplateDialog
        isOpen={isTransactionTemplateOpen}
        closeDialog={closeTransactionDialog}
        handleSubmitTemplate={handleSubmitTemplate}
        template={template}
        appUser={appUser}
        isAddFirstCellTemplate={isAddFirstCellTemplate}
        isHeaderRowExtraction={isHeaderRowExtraction}
        headerRowData={headerRowData}
      />
      <ExtractionWarningDialog
        isOpen={isExtractionWarningDialogOpen}
        handleCloseExtractionWarningDialog={handleCloseExtractionWarningDialog}
        handleRetryExtraction={handleRetryExtraction}
        isRetryingExtraction={isRetryingExtraction}
      />
    </>
  );
};

export default ExtractionConfirmDialog;
