import React, { useEffect, useState } from 'react';
import { ComponentBody } from '../../../components/styled-components';
import { connect } from 'react-redux';
import InvestmentHeader from '../../../components/headers/InvestmentHeader';
import {
  fetchInvestmentGroups,
  fetchGroupInvestments,
  fetchAllInvestments,
} from '../../../actions/investments';
import LoadSpinner from '../../../components/LoadSpinner';
import AppToast from '../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import InvestmentTable from '../../../components/table/InvestmentTable';
import AddInvestmentDialogBox from '../../../components/dialog/investments/AddInvestmentDialogBox';
import InvestmentGroupDialog from '../../../components/dialog/investments/InvestmentGroupDialog';

const InvestmentGroupProfileBase = ({
  appUser,
  investmentGroups,
  isFetching,
  groupInvestments,
  allInvestments,
  fetchInvestmentGroups,
  fetchAllInvestments,
  fetchGroupInvestments,
  isGroupInvestmentFetching,
  ...props
}) => {
  const groupId = props.match.params.groupId;
  const [isOpenAddInvestments, setIsOpenAddInvestments] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    fetchAllInvestments();
    fetchInvestmentGroups();
    fetchGroupInvestments(groupId);
  }, [
    fetchGroupInvestments,
    fetchInvestmentGroups,
    fetchAllInvestments,
    groupId,
  ]);
  useEffect(() => {
    if (investmentGroups.length && !isFetching && group === undefined) {
      props.history.push({
        pathname: '/investment-management',
        state: 1,
      });
      AppToast.show({
        message: 'Investment group does not exist.',
        intent: Intent.DANGER,
      });
    }
  });

  const group = investmentGroups.find(
    (group) => group.groupId.toString() === groupId.toString()
  );

  const handleEditDialogOpen = () => {
    setIsEditOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditOpen(false);
  };

  const handleAddInvestmentsOpen = () => {
    setIsOpenAddInvestments(true);
  };

  const handleAddInvestmentsClose = () => {
    setIsOpenAddInvestments(false);
  };

  return (
    <>
      {isFetching && (
        <div>
          <LoadSpinner size={200} />
        </div>
      )}
      {!isFetching && (
        <>
          <InvestmentHeader
            appUser={appUser}
            header={`${group ? group.groupName : ''} Investments`}
            isInvestmentProfile={true}
            hasSubNav={true}
            handleAddInvestmentsOpen={handleAddInvestmentsOpen}
            handleEditDialogOpen={handleEditDialogOpen}
            {...props}
          />
          <ComponentBody padding='14rem 2rem 3rem'>
            <InvestmentTable
              appUser={appUser}
              investments={groupInvestments}
              isAllInvestmentsFetching={isGroupInvestmentFetching}
              title='All Investments'
              groupId={groupId}
              {...props}
            />
          </ComponentBody>
          <AddInvestmentDialogBox
            isOpen={isOpenAddInvestments}
            appUser={appUser}
            groupId={groupId}
            handleClose={handleAddInvestmentsClose}
            allInvestments={allInvestments}
            groupInvestments={groupInvestments}
            fetchGroupInvestments={fetchGroupInvestments}
          />
          {isEditOpen && (
            <InvestmentGroupDialog
              isOpen={isEditOpen}
              handleClose={handleEditDialogClose}
              investmentGroup={group}
              title='Edit Investment Group'
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  investmentGroups: state.investments.investmentGroups,
  groupInvestments: state.investments.groupInvestments,
  isGroupInvestmentFetching: state.investments.isGroupInvestmentFetching,
  isFetching: state.investments.isInvestmentGroupsFetching,
  allInvestments: state.investments.allInvestments,
});

const InvestmentGroupProfile = connect(mapStateToProps, {
  fetchInvestmentGroups,
  fetchGroupInvestments,
  fetchAllInvestments,
})(InvestmentGroupProfileBase);

export default InvestmentGroupProfile;
