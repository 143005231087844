import axios from 'axios';
import { setHeader } from './index';

const TREASURY_SERVICES_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/data-ingestion-treasury-services`;

export const fetchStagingFees = async (batchId) => {
  const request = await setHeader(axios);
  return request.get(`${TREASURY_SERVICES_API}/fees/staging/${batchId}`);
};

export const fetchStagingBalances = async (batchId) => {
  const request = await setHeader(axios);
  return request.get(`${TREASURY_SERVICES_API}/balances/staging/${batchId}`);
};

export const fetchStagingAccountBalances = async (batchId) => {
  const request = await setHeader(axios);
  return request.get(
    `${TREASURY_SERVICES_API}/account-balances/staging/${batchId}`
  );
};

export const fetchStandardizedDescriptions = async () => {
  const request = await setHeader(axios);
  return request.get(`${TREASURY_SERVICES_API}/standardized-description/list`);
};

export const updateStagingBalance = async (balance) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/balances/staging`, { balance });
};

export const validateStagingBalance = async (balance) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/balances/staging/validate`, {
    balance,
  });
};

export const invalidateStagingBalance = async (balance) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/balances/staging/invalidate`, {
    balance,
  });
};

export const deleteStagingBalance = async (balance) => {
  const request = await setHeader(axios);
  return request.delete(`${TREASURY_SERVICES_API}/balances/staging`, {
    data: { balance },
  });
};

export const fetchBankLevelExceptionList = async () => {
  const request = await setHeader(axios);
  return request.get(`${TREASURY_SERVICES_API}/bank-level-exception/list`);
};

export const fetchClientLevelExceptionList = async () => {
  const request = await setHeader(axios);
  return request.get(`${TREASURY_SERVICES_API}/client-level-exception/list`);
};

export const fetchFeeCategoryList = async () => {
  const request = await setHeader(axios);
  return request.get(`${TREASURY_SERVICES_API}/fee-categories/list`);
};

export const updateStagingFees = async (fees) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/fees/staging`, { fees });
};

export const validateStagingFee = async (fees) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/fees/staging/validate`, {
    fees,
  });
};

export const invalidateStagingFee = async (fees) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/fees/staging/invalidate`, {
    fees,
  });
};

export const deleteStagingFee = async (fees) => {
  const request = await setHeader(axios);
  return request.delete(`${TREASURY_SERVICES_API}/fees/staging`, {
    data: { fees },
  });
};

export const updateStagingAccountBalance = async (balance) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/account-balances/staging`, {
    balance,
  });
};

export const validateStagingAccountBalance = async (accountBalances) => {
  const request = await setHeader(axios);
  return request.put(
    `${TREASURY_SERVICES_API}/account-balances/staging/validate`,
    {
      accountBalances,
    }
  );
};

export const invalidateStagingAccountBalance = async (accountBalances) => {
  const request = await setHeader(axios);
  return request.put(
    `${TREASURY_SERVICES_API}/account-balances/staging/invalidate`,
    {
      accountBalances,
    }
  );
};

export const validateTreasuryServicesBatch = async (treasuryServices) => {
  const request = await setHeader(axios);
  return request.put(`${TREASURY_SERVICES_API}/staging/validate`, {
    treasuryServices,
  });
};
