import React from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
  MessageText,
  RedText,
} from '../../styled-components';
import colors from '../../../utils/colors';

const IgnoreAccountDialog = ({
  isOpen,
  title,
  handleClose,
  handleIgnore,
  isIgnoring,
  accountName,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <>
          <MessageText color={colors.boldText}>
            Ignoring the following accounts will remove all uploaded files associated with
            them.
          </MessageText>
          {accountName.map(account => <MessageText key={account}>{account}</MessageText>)}
        </>
      </div>
      <AlignCenter
        justifyContent='flex-end'
        padding='10px 20px 10px 0'
        borderTop={`1px solid ${colors.lightText}`}
        margin='50px 0 0 0'
      >
        <WhiteButton
          type='button'
          color={colors.red}
          onClick={handleClose}
          hoverColor={colors.redHover}
        >
          CANCEL
        </WhiteButton>
        <RegularButton
          padding='10px 30px'
          onClick={handleIgnore}
          background={colors.red}
          backgroundHover={colors.redHover}
          disabled={isIgnoring}
        >
          IGNORE
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};

export default IgnoreAccountDialog;
