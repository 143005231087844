import React, { useMemo } from 'react';
import { ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import {
  checkPermissions,
  localDateFormatter,
  amountFormatter,
  getOrdinalNumber,
} from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const BankTable = ({
  appUser,
  banks,
  isFetching,
  openEditBankDialog,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank ID' />
        ),
        accessor: 'bankId',
        sortType: 'basic',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank Name' />
        ),
        accessor: 'bankName',
        sortType: 'caseInsensitiveSort',
        width: '10%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Asset Size (m)' />
        ),
        Cell: ({ cell: { value } }) => {
          return <span>{amountFormatter(value, 0)}</span>;
        },
        accessor: 'assetSize',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Creation Date' />
        ),
        accessor: 'createdAt',
        sortType: 'customDateTimeSort',
        width: '8%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{localDateFormatter(value)}</span>;
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Active Status' />
        ),
        accessor: 'status',
        width: '6%',
        Cell: ({ cell: { value } }) => {
          return (
            <span
              style={{
                color: value === 'active' ? '#470d82' : '#f4c65b',
                fontWeight: '700',
              }}
            >
              {value.toUpperCase()}
            </span>
          );
        },
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Data Availability (Business day of the month)' />
        ),
        Cell: ({ cell: { value } }) => {
          return <span>{value>0 ? getOrdinalNumber(value) : ''}</span>;
        },
        accessor: 'dataAvailabilityDate',
        width: '5%',
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.EDIT_BANKS,
                ]) && (
                  <MenuItem
                    text='Edit Bank'
                    onClick={() => openEditBankDialog(row.original)}
                    style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                  />
                )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [permissions.EDIT_BANKS]),
      },
    ],
    [openEditBankDialog, appUser]
  );
  return (
    <TableContainer>
      <MakeTable
        data={banks}
        columns={columns}
        isFetching={isFetching}
        tableTitle='All Banks'
        {...props}
      />
    </TableContainer>
  );
};

export default BankTable;
