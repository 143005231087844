import React, { useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  AlignCenter,
  BoldText,
  FlexColumn,
  RegularButton,
} from '../../styled-components';
import { fetchReportParameters } from '../../../actions/clients';
import { connect } from 'react-redux';
import colors from '../../../utils/colors';
import LoadSpinner from '../../LoadSpinner';
import styled from 'styled-components';

const ViewParametersDialogBoxBase = ({
  isOpen,
  title,
  report,
  handleClose,
  fetchReportParameters,
  viewParameters,
  isFetchingViewParameters,
}) => {
  useEffect(() => {
    isOpen && fetchReportParameters(report.reportId);
  }, [fetchReportParameters, report, isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
      className='medium-dialog'
    >
      <AlignCenter flexDirection='column' padding='10px 0'>
        {isFetchingViewParameters ? (
          <LoadSpinner size={100} />
        ) : (
          <>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Start Date</BoldText>
                <MessageText>{viewParameters.reportStartDate}</MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Stacked Area and Stats Group(s)</BoldText>
                <MessageText>{viewParameters.stackedAreaAndStats}</MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>End Date</BoldText>
                <MessageText>{viewParameters.reportEndDate}</MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Includes All Graphs for Group(s)</BoldText>
                <MessageText>{viewParameters.includeAllGraphs}</MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Client ID</BoldText>
                <MessageText>{viewParameters.clientName}</MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>
                  Account with Minimal Transactions for Closure
                </BoldText>
                <MessageText>
                  {viewParameters.minTransacForClosureGroupName}
                </MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>All Accounts Graph Group(s)</BoldText>
                <MessageText>
                  {viewParameters.allAccountsGraph &&
                    viewParameters.allAccountsGraph.split(',').join(', ')}
                </MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Recommend ZBA Account Structure?</BoldText>
                <MessageText>{viewParameters.zbaAccountStructure}</MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Show Current Liquidity Level Amounts</BoldText>
                <MessageText>
                  {viewParameters.liquidityLevelAmounts}
                </MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Liquid Target Account?</BoldText>
                <MessageText>{viewParameters.liquidTargetAccount}</MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Stress Test Result Group(s)</BoldText>
                <MessageText>{viewParameters.stressTestResult}</MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>High Yield Target Account</BoldText>
                <MessageText>
                  {viewParameters.highYieldTargetAccountName}
                </MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Show Net Graph?</BoldText>
                <MessageText>{viewParameters.stmNetGraph}</MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Include Electronic Transaction Template?</BoldText>
                <MessageText>
                  {viewParameters.electronicTransacTemplate}
                </MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Show Previous Quarter Results</BoldText>
                <MessageText>
                  {viewParameters.stmPrevQuarterResults}
                </MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>High Yield Liquid Benchmark Override</BoldText>
                <MessageText>
                  {viewParameters.highYieldLiquidBenchmarkOverride}
                </MessageText>
              </FlexColumn>
            </AlignCenter>
            <AlignCenter justifyContent='space-between' padding='10px 40px'>
              <FlexColumn style={{ width: '425px' }}>
                <BoldText>Show Min Table?</BoldText>
                <MessageText>{viewParameters.stmMinTable}</MessageText>
              </FlexColumn>
              <FlexColumn style={{ width: '425px' }}></FlexColumn>
            </AlignCenter>
          </>
        )}
      </AlignCenter>
      <AlignCenter
        justifyContent='flex-end'
        padding='10px 20px 0 0'
        borderTop={`1px solid ${colors.lightText}`}
        margin={'50px 0 0 0'}
      >
        <RegularButton padding='10px 30px' display='flex' onClick={handleClose}>
          CLOSE
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};

const MessageText = styled.p`
  margin-top: 5px;
  color: ${colors.boldText};
`;

const mapStateToProps = (state) => ({
  viewParameters: state.clients.viewParameters,
  isFetchingViewParameters: state.clients.isFetchingViewParameters,
});

const ViewParametersDialogBox = connect(mapStateToProps, {
  fetchReportParameters,
})(ViewParametersDialogBoxBase);

export default ViewParametersDialogBox;
