import React from 'react';
import find from 'lodash/find';

import AddUserForm from './user-management-forms/AddUserForm';
import EditUserForm from './user-management-forms/EditUserForm';

const UserForm = ({
  title,
  firstName,
  lastName,
  email,
  phoneNumber,
  status,
  role,
  roles,
  resetMfaMessage,
  clientList,
  clientAssociation,
  showGreenMessage,
  handleCreateUser,
  closeDialog,
  handleEditUser,
  resetUserMfa,
  appUser,
  isResetting,
  resendConfirmationCode,
  isResendingCode,
}) => {
  clientAssociation = clientAssociation.map((label) =>
    find(clientList, ['label', label])
  );
  return (
    <>
      {title === 'Add New User' ? (
        <AddUserForm
          firstName={firstName}
          lastName={lastName}
          email={email}
          status={status}
          role={role}
          roles={roles}
          clientList={clientList}
          clientAssociation={clientAssociation}
          handleCreateUser={handleCreateUser}
          closeDialog={closeDialog}
          appUser={appUser}
        />
      ) : (
        <EditUserForm
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          status={status}
          role={role}
          roles={roles}
          resetMfaMessage={resetMfaMessage}
          clientList={clientList}
          clientAssociation={clientAssociation}
          showGreenMessage={showGreenMessage}
          closeDialog={closeDialog}
          handleEditUser={handleEditUser}
          resetUserMfa={resetUserMfa}
          appUser={appUser}
          isResetting={isResetting}
          resendConfirmationCode={resendConfirmationCode}
          isResendingCode={isResendingCode}
        />
      )}
    </>
  );
};

export default UserForm;
