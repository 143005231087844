import React from 'react';
import {
  StyleColumnCell,
  ActionIconButton,
} from '../../components/styled-components';
import MakeTable from '../../components/table/components/MakeTable';
import SortableHeader from './components/SortableHeader';
import { Icon } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const UserTable = ({
  appUser,
  users,
  isFetching,
  openEditUserDialog,
  ...props
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Name' />;
        },
        id: 'Name',
        accessor: (row) => `${row.firstName} ${row.lastName}`,
        width: '10%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Email' />;
        },
        accessor: 'email',
        width: '10%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Role' />;
        },
        accessor: 'roleName',
        width: '5%',
        // Checks value to see if its admin and assign to user others
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'Admin' ? colors.adminColor : colors.userColor}
            >
              {value ? value.toUpperCase() : null}
            </StyleColumnCell>
          );
        },
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Active Status' />;
        },
        accessor: 'status',
        width: '10%',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={
                value === 'active' ? colors.activeColor : colors.inActiveColor
              }
            >
              {value.toUpperCase()}
            </StyleColumnCell>
          );
        },
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: '1%',
        sticky: 'right',
        Cell: ({ row }) => (
          <ActionIconButton onClick={(e) => openEditUserDialog(row.original)}>
            <Icon icon={iconValue.pen} iconSize={15} color={colors.primary} />
          </ActionIconButton>
        ),
        isVisible: checkPermissions(appUser.permList, [permissions.EDIT_USERS]),
      },
    ],
    [openEditUserDialog, appUser]
  );
  return (
    <TableContainer>
      {/*This call invokes MakeTable components with given columns and data*/}
      <MakeTable
        columns={columns}
        data={users}
        isFetching={isFetching}
        tableTitle='Users'
        {...props}
      />
    </TableContainer>
  );
};

export default UserTable;
