import React, { useMemo, useState, useCallback } from 'react';
import MakeTable from './components/MakeTable';
import {
  NameLink,
  StyleColumnCell,
  ActionIconButton,
} from '../styled-components';
import SortableHeader from './components/SortableHeader';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import {
  checkPermissions,
  localDateFormatter,
  amountFormatter,
} from '../../utils/functions';
import permissions from '../../utils/permissions';
import ClientsDialogBox from '../../components/dialog/ClientsDialogBox';
import { checkSameName } from '../../utils/functions';
import AppToast from '../../components/Toast';
import { updateClient } from '../../api/clients';
import { Intent } from '@blueprintjs/core';
import { TableContainer } from './components/table-styled-components';

const ClientTable = ({
  appUser,
  clients,
  isFetching,
  fetchSingleClient,
  categories,
  types,
  clientNames,
  allowedReportFrequencies,
  ...props
}) => {
  //Client edit modal state variables
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [warningClientName, setWarningClientName] = useState('');
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);
  const [clientState, setClientState] = useState({});
  const [clientId, setClientId] = useState(undefined);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [isOverwriting, setIsOverWriting] = useState(false);

  //client edit modal functions
  const openEditClientDialog = useCallback((client) => {
    const copiedClient = Object.assign({}, client);
    copiedClient.status = client.status === 'active' ? 1 : 0;
    setSelectedClient(copiedClient);
    setIsOpen(true);
  }, []);

  const closeDialog = () => {
    setIsOpen(false);
    setIsWarningDialogOpen(false);
    setSelectedClient({});
    setClientState({});
    setClientId(undefined);
    setWarningClientName('');
    setIsTryingToSubmit(false);
    setIsOverWriting(false);
  };

  /**
   * Submits the updated client
   * If the name given already belongs to another client it throws an exception and enables a confirmation modal
   * When run with confirmation modal enabled (overwrite confirmed) it updates the client as well
   * @param {Obj} values  - fields of the submit, if none specified it defaults to what is saved in the ongoing client state (on confirm)
   * @param {string} clientId  -  the id of the client to update, if none is specified it defaults to what is save in the ongoing id state (on confirm)
   */
  const handleEditClient = (values = clientState, id = clientId) => {
    const client = {
      ...values,
    };
    setIsTryingToSubmit(true);

    const isNameOverwritten =
      checkSameName(client.clientName, clientNames) &&
      selectedClient.clientName !== client.clientName &&
      !isWarningDialogOpen;

    try {
      if (isNameOverwritten) throw new Error('NAME_OVERWRITE');
      setIsOverWriting(true);
      //No conflicts update client
      updateClient(id, client)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
          fetchSingleClient(id);
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to edit client.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          closeDialog();
        });
    } catch (err) {
      if (err.message === 'NAME_OVERWRITE') {
        setIsWarningDialogOpen(true);
        setClientState(client);
        setClientId(selectedClient.clientId);
        setWarningClientName(client.clientName);
      }
    }
  };

  const handleWarningDialogClose = () => {
    setIsTryingToSubmit(false);
    setIsWarningDialogOpen(false);
  };
  //End of client edit modal

  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Client Name' />
        ),
        accessor: 'clientName',
        Cell: ({ cell: { value }, row }) => {
          return (
            <div style={{ display: 'flex' }}>
              <NameLink
                to={{
                  pathname: `/client-management/${row.original.clientId}`,
                }}
              >
                {value}
              </NameLink>
              {row.original.hasNotes && (
                <Icon
                  icon={iconValue.comment}
                  iconSize={20}
                  style={{ paddingLeft: '8px' }}
                />
              )}
            </div>
          );
        },
        sortType: 'caseInsensitiveSort',
        width: '10%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='State' />
        ),
        accessor: 'state',
        sortType: 'caseInsensitiveSort',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Client Category' />
        ),
        accessor: 'clientCategory',
        width: '7%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Client Type' />
        ),
        accessor: 'clientType',
        sortType: 'caseInsensitiveSort',
        width: '7%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Budget Size (m)' />
        ),
        Cell: ({ cell: { value } }) => {
          return <span>{amountFormatter(value, 0)}</span>;
        },
        accessor: 'budget',
        width: '7%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Initial Interest' />
        ),
        Cell: ({ cell: { value } }) => {
          return <span>{amountFormatter(value)}</span>;
        },
        accessor: 'initialInterest',
        width: '7%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Creation Date' />
        ),
        accessor: 'createdAt',
        width: '8%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{value && localDateFormatter(value)}</span>;
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Last Updated' />
        ),
        accessor: 'updatedAt',
        width: '8%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{value && localDateFormatter(value)}</span>;
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Active Status' />
        ),
        accessor: 'status',
        width: '7%',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={
                value === 'active' ? colors.activeColor : colors.inActiveColor
              }
            >
              {value.toUpperCase()}
            </StyleColumnCell>
          );
        },
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.EDIT_CLIENTS,
                ]) && (
                  <MenuItem
                    text='Edit'
                    onClick={() => openEditClientDialog(row.original)}
                    style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                  />
                )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.EDIT_CLIENTS,
        ]),
      },
    ],
    [openEditClientDialog, appUser]
  );

  return (
    <>
      <ClientsDialogBox
        isOpen={isOpen}
        appUser={appUser}
        title={'Edit Client'}
        closeDialog={closeDialog}
        categories={categories}
        types={types}
        allowedReportFrequencies={allowedReportFrequencies}
        selectedClient={selectedClient}
        handleEditClient={handleEditClient}
        isTryingToSubmit={isTryingToSubmit}
        isWarningDialogOpen={isWarningDialogOpen}
        warningName={warningClientName}
        handleWarningDialogClose={handleWarningDialogClose}
        isOverwriting={isOverwriting}
      />
      <TableContainer>
        <MakeTable
          appUser={appUser}
          data={clients}
          columns={columns}
          isFetching={isFetching}
          tableTitle='Clients'
          {...props}
        />
      </TableContainer>
    </>
  );
};

export default ClientTable;
