import React, { useMemo, useState } from 'react';
import { Intent } from '@blueprintjs/core';
import { NameLink } from '../../styled-components';
import SortableHeader from '../../table/components/SortableHeader';
import { amountFormatter } from '../../../utils/functions';
import RowSelector from '../../table/components/RowSelector';
import { addInvestmentsToGroup } from '../../../api/investments';
import AppToast from '../../Toast';
import AddDialogTable from '../../table/components/AddDialogTable';

const AddInvestmentDialogBox = ({
  isOpen,
  handleClose,
  allInvestments,
  groupInvestments,
  groupId,
  fetchGroupInvestments,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const data = allInvestments.filter(
    (investment) =>
      !groupInvestments
        .map((investment) => investment.id)
        .includes(investment.id)
  );

  const handleAddInvestments = (investments) => {
    const investmentIdList = investments.map((investment) => investment.id);
    setIsAdding(true);
    addInvestmentsToGroup(groupId, investmentIdList)
      .then((res) => {
        AppToast.show({ message: res.data.msg, intent: Intent.SUCCESS });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to  add investments.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchGroupInvestments(groupId);
        handleClose();
        setIsAdding(false);
      });
  };
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Client / Account Relationship'
          />
        ),
        accessor: 'clientAccountRelationship',
        width: '10%',
        sortType: 'caseInsensitiveSort',
        filter: 'text',
        isVisible: true,
        Cell: ({ cell: { value }, row }) => {
          return (
            <NameLink
              to={{
                pathname: `/client-management/${row.original.clientId}/${row.original.accountId}`,
              }}
            >
              {value}
            </NameLink>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Face Value'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'faceValue',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Maturity Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'maturityDate',
        width: '8%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Investment Type'
            sizeFont={'0.85rem'}
          />
        ),
        accessor: 'investmentTypeName',
        width: '8%',
        sortType: 'caseInsensitiveSort',
        filter: 'text',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Fund' sizeFont={'0.85rem'} />
        ),
        accessor: 'fund',
        width: '8%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Interest Rate'
            sizeFont={'0.85rem'}
          />
        ),
        accessor: 'interestRate',
        Cell: ({ cell: { value } }) => `${value}%`,
        width: '5%',
        sortType: 'caseInsensitiveSort',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Liquidity Level'
            sizeFont={'0.75rem'}
            toolTipText={'This field is determined by Analytics.'}
          />
        ),
        accessor: 'liquidityLevel',
        width: '5%',
        sortType: 'basic',
        filter: 'text',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Days Until Maturity'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'daysUntilMaturity',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='ID'
            toolTipText={
              'Market Identifier for the Security (Examples: CUSIP, SEDOL, ISIN, etc.).'
            }
          />
        ),
        accessor: 'investmentId',
        width: '5%',
        sortType: 'basic',
        isVisible: true,
      },

      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Coupon Rate'
            sizeFont={'0.85rem'}
          />
        ),
        accessor: 'couponRate',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },

      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Call Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'callDate',
        width: '8%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Settlement Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'settlementDate',
        width: '5%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Settlement Amount'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'settlementAmount',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Interest at Maturity'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'interestAtMaturity',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Premium / Discount'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'premiumDiscount',
        width: '8%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank' sizeFont={'0.75rem'} />
        ),
        accessor: 'bank',
        width: '5%',
        sortType: 'caseInsensitiveSort',
        filter: 'text',
        isVisible: true,
      },
    ],
    []
  );

  return (
    <AddDialogTable
      isOpen={isOpen}
      columns={columns}
      data={data}
      title='All Investments'
      handleAdd={handleAddInvestments}
      isAdding={isAdding}
      handleClose={handleClose}
      addButtonText='Add Investment(s)'
      searchName='Investments'
    />
  );
};

export default AddInvestmentDialogBox;
