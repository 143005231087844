import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { AlignCenter, WhiteButton } from '../../styled-components';
import ValidationHistoryDownloadTable from '../../table/ValidationHistoryDownloadTable';
import AppToast from '../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import axios from 'axios';
import { downloadFiles } from '../../../utils/functions';
import { downloadIngestionFile } from '../../../api/data-ingestion';


const ValidationHistoryDownloadDialog = ({
  isOpen,
  closeDialog,
  DownloadFunction,
  data,
  ...props
}) => {

  const handleDownload = async (fileData) => {
    AppToast.show({
      message: 'File download initiated.',
      intent: Intent.SUCCESS,
    });
    const fileAuditId = fileData.fileAuditId
    const stageFolderName = 'validated'
    const fileName = fileData.uploadedFileName.substring(0, fileData.uploadedFileName.lastIndexOf('.'))
    let fileNameWithExtension
    // eslint-disable-next-line 
    const extractFileName = (presignedURLStr) =>  presignedURLStr.match('(?<=\/)([^\/]+)(?=\\?)').pop()
    const presignedURL = await downloadIngestionFile(fileAuditId, fileName, stageFolderName)
      .then((res) => {
        fileNameWithExtension = extractFileName(res.data)
        return res.data;
      })
      .catch((err => console.error(err)))

      delete axios.defaults.headers.common['Authorization'];

      axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => {
          // call download from utils function
          downloadFiles(res, fileNameWithExtension);
          AppToast.show({
            message: 'File downloaded successfully!',
            intent: Intent.SUCCESS,
          });
        })
        .catch(() => {
          AppToast.show({
            message: 'Failed to download file.',
            intent: Intent.DANGER,
          });
        });
  }

  return (
    <Dialog isOpen={isOpen} onClose={closeDialog} className={'large-dialog'}>
      <AlignCenter justifyContent='space-between'>
          <ValidationHistoryDownloadTable 
            handleDownloadFile={handleDownload}
            tableData={data}
          />
      </AlignCenter>
      <AlignCenter justifyContent='flex-end' padding='0px 20px 0px 0px'>
        <WhiteButton type='button' onClick={closeDialog}>
          CLOSE
        </WhiteButton>
      </AlignCenter>
    </Dialog>
  )
}

export default ValidationHistoryDownloadDialog;
