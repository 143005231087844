import React from 'react';
import { MultiSelect } from '@blueprintjs/select';
import { Button, NonIdealState, MenuItem, Intent } from '@blueprintjs/core';
import { Label, FlexColumn, BlueprintInputWrapper } from '../styled-components';
import { useField } from 'formik';

//Finds and returns duplicates in an array
const findDuplicates = (arr) =>
  arr.filter((item, index) => arr.indexOf(item) !== index);

const FormikMultiSelectField = ({
  items = [],
  label,
  wide,
  fill,
  intent,
  minWidth,
  selectedItems,
  setSelectedItems,
  isSingleItemMode,
  ...props
}) => {
  const [field] = useField(props);
  const labels = items.map((item) => item.label);
  const duplicateLevels = findDuplicates(labels);

  //Adds item to the selected list
  const selectItem = (item) => {
    selectedItems.push(item);
  };

  //How to render dropdown items
  //In case the item is not unique show its id(group.value) as well
  //The id helps differentiate item with the same name
  const renderItems = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = `${item.label}`;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        icon={isItemSelected(item) ? 'tick' : 'blank'}
        key={item.value}
        onClick={handleClick}
        style={{ marginBottom: '5px' }}
        text={
          label === 'Assign Clients'
            ? item.label.substr(0, item.label.lastIndexOf('-'))
            : text
        }
        label={
          label === 'Assign Clients'
            ? item.label.substr(item.label.lastIndexOf('-') + 1)
            : duplicateLevels.includes(item.label)
            ? item.value
            : ''
        }
      />
    );
  };

  // this for the select fields with filters
  const filterList = (query, items) => {
    return items.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  //Handle selecting items
  const handleItemSelect = (item) => {
    if (!isItemSelected(item)) {
      if (isSingleItemMode && selectedItems.length) return;
      selectItem(item);
    } else {
      deselectItem(item);
    }
  };

  //Returns true if item is selected
  const isItemSelected = (item) => {
    return (
      selectedItems.length && selectedItems.some((i) => i.value === item.value)
    );
  };

  //Removes the item from selected list
  const deselectItem = (item) => {
    let selectedItemLabels = selectedItems.map((item) => item.label);
    let index = selectedItemLabels.indexOf(item);
    selectedItems.splice(index, 1);
    setSelectedItems(selectedItems);
  };

  //Renders input tag
  const tagRenderer = (item) => {
    return item.label;
  };

  //Removes selected element on clicking items cross
  const handleTagRemove = (item) => {
    deselectItem(item);
  };

  //Handle clicking the large right cross button
  const handleClear = () => {
    setSelectedItems(props.name, []);
  };

  //Jxs for right element on inputProps
  const clearButton = (
    <Button icon='cross' minimal={true} onClick={handleClear} />
  );

  return (
    <FlexColumn style={wide ? { width: '100%' } : {}}>
      <Label>{label}</Label>
      <BlueprintInputWrapper minWidth={minWidth} intent={intent}>
        <MultiSelect
          {...field}
          {...props}
          items={items}
          itemRenderer={renderItems}
          itemPredicate={filterList}
          onItemSelect={handleItemSelect}
          tagRenderer={tagRenderer}
          scrollToActiveItem={true}
          placeholder='Select Multiple Items'
          noResults={<NonIdealState title='No Results Found...' />}
          popoverProps={{ minimal: true }}
          resetOnSelect={true}
          tagInputProps={{
            tagProps: {
              intent: intent ? intent : Intent.PRIMARY,
              minimal: false,
            },
            onRemove: handleTagRemove,
            rightElement: clearButton,
          }}
          selectedItems={selectedItems}
          fill={fill}
        ></MultiSelect>
      </BlueprintInputWrapper>
    </FlexColumn>
  );
};

export default FormikMultiSelectField;
