import React from 'react';
import { AlignCenter } from '../../styled-components';
import { Formik, Form } from 'formik';
import { collectionTypeList, statusList } from '../../../utils/constantsList';
import find from 'lodash/find';
import FormActions from '../FormActions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';

const BankClientRelationshipForm = ({
  title,
  closeDialog,
  status,
  collectionType,
  handleEditClientBankRelationship,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
        <Formik
          initialValues={{ status, collectionType }}
          onSubmit={(values) => {
            handleEditClientBankRelationship(values.status, values.collectionType);
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter style={{ gap: '10%' }} justifyContent='flex-start' padding='0 0 30px 50px'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  name='status'
                  value={
                    values.status >= 0
                      ? find(statusList, ['value', values.status]).label
                      : 'Active'
                  }
                  items={statusList}
                  labelName='Active Status'
                />
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  name='collectionType'
                  value={find(collectionTypeList, ['value', values.collectionType])?.label}
                  items={collectionTypeList}
                  labelName='Collection Type'
                />
              </AlignCenter>
              <FormActions isSubmitting={isSubmitting} onClose={closeDialog} />
            </Form>
          )}
        </Formik>
      </AlignCenter >
    </>
  );
};

export default BankClientRelationshipForm;
