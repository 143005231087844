import React from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  RegularButton,
  MessageText,
  RedText,
  AlignCenter,
  ResponseMessage,
} from '../../styled-components';
import colors from '../../../utils/colors';

const ClientReportDialog = ({
  isReportModalEnabled,
  onCloseReset,
  title,
  errorsArray,
  successesArray,
  clientId,
  fetchSingleClient,
}) => {
  return (
    <Dialog
      className='small-dialog'
      isOpen={isReportModalEnabled}
      title={title}
      onClose={() => {
        onCloseReset();
      }}
      isCloseButtonShown={false}
      style={{
        minWidth: '45%',
      }}
    >
      <div
        style={{
          padding: '30px 50px 20px',
        }}
      >
        <MessageText color={colors.green}>
          {successesArray.length ? (
            <>
              <ResponseMessage>
                {successesArray.map((error, i) => (
                  <span
                    style={{ padding: '5px 0px', display: 'block' }}
                    key={i}
                  >
                    {error}
                  </span>
                ))}
              </ResponseMessage>
            </>
          ) : null}
        </MessageText>
        {errorsArray.length ? (
          <RedText>
            <ResponseMessage marginTop='0'>
              {errorsArray.map((error, i) => (
                <span style={{ padding: '5px 0px', display: 'block' }} key={i}>
                  {error}
                </span>
              ))}
            </ResponseMessage>
          </RedText>
        ) : null}
      </div>
      <AlignCenter
        padding='10px 0 10px 0'
        borderTop={`1px solid ${colors.lightText}`}
        margin='50px 0 0 0'
      >
        <RegularButton
          width='13rem'
          onClick={() => {
            onCloseReset();
            fetchSingleClient(clientId);
          }}
        >
          OK
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};

export default ClientReportDialog;
