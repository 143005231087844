import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { AlignCenter, WhiteButton } from '../../styled-components';
import StagingInvestmentTable from '../../table/StagingInvestmentTable';
import { connect } from 'react-redux';
import {
  getInvestmentTypes,
  invalidateStagingInvestment,
  updateStagingInvestment,
  validateStagingInvestment,
  fetchStagingPaginatedData,
} from '../../../api/investments';
import InvestmentDialog from '../investments/InvestmentDialog';
import AppToast from '../../Toast';
import { fetchSingleBatchAccount } from '../../../actions/data-ingestion';
import InvestmentDeleteDialogBox from '../investments/InvestmentDeleteDialogBox';

const StagingInvestmentDialogBase = ({
  isOpen,
  handleClose,
  clientName,
  bankName,
  appUser,
  batchAccount,
  fetchStagingInvestments,
  fetchSingleBatchAccount,
  isServerSidePagination,
  ...props
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState({});
  const [investmentTypes, setInvestmentTypes] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedInvestments, setSelectedInvestments] = useState([]);

  // pagination info
  // pageSize and pageIndex used to refetch data after edit/delete
  const [pageSize] = useState(50);
  const [pageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(undefined);
  const [resultCount, setResultCount] = useState(undefined);
  const [stagingInvestments, setStagingInvestments] = useState([]);
  const [isPaginatedDataFetching, setIsPaginatedDataFetching] = useState(false);

  // for server side pagination. only call this function if needed so use hook useCallback
  const updatePaginate = useCallback(
    (
      { pageSize, pageIndex },
      filters = [],
      searchTerm = '',
      sortOrder = {}
    ) => {
      setIsPaginatedDataFetching(true);
      fetchStagingPaginatedData(
        batchAccount.batchAccountId,
        pageSize,
        pageIndex,
        filters,
        searchTerm,
        sortOrder
      )
        .then((res) => {
          setStagingInvestments(res.data.results);
          setPageCount(res.data.pageCount);
          setResultCount(res.data.resultCount);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsPaginatedDataFetching(false));
    },
    [batchAccount.batchAccountId]
  );

  useEffect(() => {
    isEditDialogOpen &&
      getInvestmentTypes()
        .then((res) => {
          setInvestmentTypes(res.data);
        })
        .catch((err) => {
          setInvestmentTypes([]);
        });
  }, [fetchStagingInvestments, batchAccount, isEditDialogOpen]);

  const handleOpenEditDialog = (investment) => {
    setIsEditDialogOpen(true);
    investment.bank = bankName;
    investment.clientAccountRelationship = `${clientName} ${batchAccount.account}`;
    setSelectedInvestment(investment);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedInvestment({});
  };

  const handleEditInvestment = (investment) => {
    //we don't need clientAccountRelationship and bank so deleting those from the object
    delete investment.clientAccountRelationship;
    delete investment.bank;
    investment.investmentTypeId = investmentTypes.find(
      (x) => x.label === investment.investmentTypeName
    ).value;
    updateStagingInvestment(investment, selectedInvestment.id)
      .then((res) => {
        AppToast.show({
          message: 'Investment updated successfully.',
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
      })
      .catch((err) => {
        AppToast.show({
          message: 'Fail to update investment.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleCloseEditDialog();
      });
  };

  const handleDeleteDialogOpen = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    if (isArray) setSelectedInvestments(selectedRows);
    else setSelectedInvestment(selectedRows);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setSelectedInvestments([]);
    setSelectedInvestment({});
  };

  const handleValidateInvestment = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    let stagingInvestmentIds;
    let batchAccountId = batchAccount.batchAccountId;

    if (isArray)
      stagingInvestmentIds = selectedRows
        .filter((row) => row.validationStatus === 'No')
        .map((row) => Number(row.id));

    const investment = {
      batchAccountId,
      stagingInvestmentIds: isArray
        ? stagingInvestmentIds
        : [Number(selectedRows.id)],
    };
    validateStagingInvestment(investment)
      .then((res) => {
        AppToast.show({
          message: 'Successfully validated investments.',
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
        fetchSingleBatchAccount(batchAccount.batchAccountId);
      })
      .catch((err) => {
        AppToast.show({
          message: 'Failed to validate investments.',
          intent: Intent.DANGER,
        });
      });
  };

  const handleInvalidateInvestment = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    let stagingInvestmentIds;
    let batchAccountId = batchAccount.batchAccountId;

    if (isArray)
      stagingInvestmentIds = selectedRows
        .filter((row) => row.validationStatus === 'Yes')
        .map((row) => Number(row.id));
    const investment = {
      batchAccountId,
      stagingInvestmentIds: isArray
        ? stagingInvestmentIds
        : [Number(selectedRows.id)],
    };
    invalidateStagingInvestment(investment)
      .then((res) => {
        AppToast.show({
          message: 'Successfully invalidated investments.',
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
        fetchSingleBatchAccount(batchAccount.batchAccountId);
      })
      .catch((err) => {
        AppToast.show({
          message: 'Failed to invalidate investments.',
          intent: Intent.DANGER,
        });
      });
  };

  const Title = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {`Validate/Inspect/Edit: ${clientName} / ${bankName} / ${batchAccount.account}`}
      </div>
    );
  };
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={<Title />}
        className={'custom-dialog-header large-dialog'}
        isCloseButtonShown={false}
      >
        <AlignCenter justifyContent='space-between'>
          <StagingInvestmentTable
            appUser={appUser}
            isFetching={isPaginatedDataFetching}
            stagingInvestments={stagingInvestments}
            handleValidateInvestment={handleValidateInvestment}
            handleInvalidateInvestment={handleInvalidateInvestment}
            handleOpenEditDialog={handleOpenEditDialog}
            handleDeleteDialogOpen={handleDeleteDialogOpen}
            updatePaginate={updatePaginate}
            resultCount={resultCount}
            pageCount={pageCount}
            isServerSidePagination={isServerSidePagination}
            {...props}
          />
        </AlignCenter>
        <AlignCenter justifyContent='flex-end' padding='0px 20px 0px 0px'>
          <WhiteButton type='button' onClick={handleClose}>
            CLOSE
          </WhiteButton>
        </AlignCenter>
      </Dialog>
      <InvestmentDialog
        isOpen={isEditDialogOpen}
        title='Edit Staging Investment'
        closeDialog={handleCloseEditDialog}
        investment={selectedInvestment}
        investmentTypes={investmentTypes}
        handleEditInvestment={handleEditInvestment}
        bankName={bankName}
        clientAccountRelationship={`${clientName} ${batchAccount.accountName}`}
        isStagingInvestment={true}
        {...props}
      />
      <InvestmentDeleteDialogBox
        isOpen={isDeleteDialogOpen}
        title={
          selectedInvestments.length
            ? `Delete Selected Investment (${selectedInvestments.length})`
            : 'Delete Investment'
        }
        selectedInvestments={selectedInvestments}
        investment={selectedInvestment}
        handleClose={handleDeleteDialogClose}
        batchAccountId={batchAccount.batchAccountId}
        isStagingInvestment={true}
        setResetInitialFilter={() => {}}
        updatePaginate={updatePaginate}
        pageSize={pageSize}
        pageIndex={pageIndex}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const StagingInvestmentDialog = connect(mapStateToProps, {
  fetchSingleBatchAccount,
})(StagingInvestmentDialogBase);

export default StagingInvestmentDialog;
