const VIEW_USERS = 'View Users';
const ADD_USERS = 'Add Users';
const EDIT_USERS = 'Edit Users';
const DEACTIVATE_USERS = 'Deactivate Users';
const RESET_MFA = 'Reset MFA';
const VIEW_CLIENTS = 'View Clients';
const ADD_CLIENTS = 'Add Clients';
const EDIT_CLIENTS = 'Edit Clients';
const DEACTIVATE_CLIENTS = 'Deactivate Clients';
const VIEW_BANKS = 'View Banks';
const ADD_BANKS = 'Add Banks';
const EDIT_BANKS = 'Edit Banks';
const DEACTIVATE_BANKS = 'Deactivate Banks';
const VIEW_ACCOUNTS = 'View Accounts';
const ADD_ACCOUNTS = 'Add Accounts';
const DELETE_ACCOUNTS = 'Delete Accounts';
const EDIT_ACCOUNTS = 'Edit Accounts';
const VIEW_TRANSACTIONS = 'View Transactions';
const ADD_TRANSACTIONS = 'Add Transactions';
const EDIT_TRANSACTIONS = 'Edit Transactions';
const DELETE_TRANSACTIONS = 'Delete Transactions';
const VIEW_ACCOUNT_GROUPS = 'View Account Groups';
const ADD_ACCOUNT_GROUPS = 'Add Account Groups';
const EDIT_ACCOUNT_GROUPS = 'Edit Account Groups';
const DELETE_ACCOUNT_GROUPS = 'Delete Account Groups';
const REMOVE_ACCOUNT_GROUP_ASSOCIATIONS = 'Remove Account Group Associations';
const UPLOAD_FILES = 'Upload Files';
const DOWNLOAD_FILES = 'Download Files';
const MANAGE_TEMPLATES = 'Manage Templates';
const VIEW_TEMPLATES = 'View Templates';
const DELETE_TEMPLATES = 'Delete Templates';
const START_REPORT = 'Start Report';
const VIEW_BANK_CLIENT_RELATIONSHIPS = 'View Bank Client Relationships';
const EDIT_BANK_CLIENT_RELATIONSHIPS = 'Edit Bank Client Relationships';
const VIEW_OPEN_REPORTS = 'View Open Reports';
const VIEW_BATCHES = 'View Batches';
const VIEW_DASHBOARD = 'View Dashboard';
const DELETE_OPEN_REPORTS = 'Delete Open Reports';
const VIEW_DATA_EXTRACTION_STATUS = 'View Data Extraction Status';
const VALIDATE_BATCHES = 'Validate Batches';
const DELETE_BATCHES = 'Delete Batches';
const VIEW_VALIDATION_HISTORY = 'View Validation History';
const DELETE_ACCOUNTS_FROM_BATCHES = 'Delete Accounts From Batches';
const VIEW_STAGING_TRANSACTIONS = 'View Staging Transactions';
const VALIDATE_ACCOUNTS_IN_BATCH = 'Validate Accounts in Batch';
const VIEW_ACCOUNT_BATCHES = 'View Account Batches';
const EDIT_STAGING_TRANSACTIONS = 'Edit Staging Transactions';
const DELETE_STAGING_TRANSACTIONS = 'Delete Staging Transactions';
const VALIDATE_STAGING_TRANSACTIONS = 'Validate Staging Transactions';
const IGNORE_ACCOUNTS_IN_BATCH = 'Ignore Accounts in Batch';
const RESTORE_ACCOUNTS_IN_BATCH = 'Restore Accounts in Batch';
const INVALIDATE_STAGING_TRANSACTIONS = 'Invalidate Staging Transactions';
const VIEW_STAGING_INVESTMENTS = 'View Staging Investments';
const EDIT_STAGING_INVESTMENTS = 'Edit Staging Investments';
const DELETE_STAGING_INVESTMENTS = 'Delete Staging Investments';
const VALIDATE_STAGING_INVESTMENTS = 'Validate Staging Investments';
const INVALIDATE_STAGING_INVESTMENTS = 'Invalidate Staging Investments';
const VIEW_INVESTMENTS = 'View Investments';
const ADD_INVESTMENTS = 'Add Investments';
const EDIT_INVESTMENTS = 'Edit Investments';
const DELETE_INVESTMENTS = 'Delete Investments';
const VIEW_INVESTMENT_GROUPS = 'View Investment Groups';
const ADD_INVESTMENT_GROUPS = 'Add Investment Groups';
const EDIT_INVESTMENT_GROUPS = 'Edit Investment Groups';
const DELETE_INVESTMENT_GROUPS = 'Delete Investment Groups';
const REMOVE_INVESTMENT_GROUP_ASSOCIATIONS =
  'Remove Investment Group Associations';
const ADD_INVESTMENT_GROUP_ASSOCIATIONS = 'Add Investment Group Associations';
const VIEW_USER_CLIENT_ASSOCIATION = 'View User Client Association';
const VIEW_GLOBAL_SETTINGS = 'View Global Settings';
const EDIT_GLOBAL_SETTINGS = 'Edit Global Settings';
const ADD_KEYWORDS = 'Add Keywords';
const EDIT_KEYWORDS = 'Edit Keywords';
const DELETE_KEYWORDS = 'Delete Keywords';
const VIEW_NOTE_CATEGORIES = 'View Note Categories';
const ADD_NOTE_CATEGORIES = 'Add Note Categories';
const EDIT_NOTE_CATEGORIES = 'Edit Note Categories';
const DEACTIVATE_NOTE_CATEGORIES = 'Deactivate Note Categories';
const VIEW_QUOTE_TYPES = 'View Quote Types';
const ADD_QUOTE_TYPES = 'Add Quote Types';
const EDIT_QUOTE_TYPES = 'Edit Quote Types';
const DEACTIVATE_QUOTE_TYPES = 'Deactivate Quote Types';
const ADD_NOTES = 'Add Notes';
const EDIT_NOTES = 'Edit Notes';
const VIEW_NOTES = 'View Notes';
const RESOLVE_NOTES = 'Resolve Notes';
const RESTORE_NOTES = 'Restore Notes';
const VIEW_RESOLVED_NOTES = 'View Resolved Notes';
const DELETE_NOTES = 'Delete Notes';
// below is to show the list of all the users in the system as a dropdown so they can be tagged in a note
const VIEW_USERS_LIST = 'View Users List';
const VIEW_ACCOUNT_LINKS = 'View Account Links';
const EDIT_ACCOUNT_LINKS = 'Edit Account Links';
const ADD_ACCOUNT_LINKS = 'Add Account Links';
const DELETE_ACCOUNT_LINKS = 'Delete Account Links';
const VIEW_KEYWORDS = 'View Keywords';
const ADD_NEGOTIATED_RATES = 'Add Negotiated Rates';
const EDIT_NEGOTIATED_RATES = 'Edit Negotiated Rates';
const DELETE_NEGOTIATED_RATES = 'Delete Negotiated Rates';
const VIEW_NEGOTIATED_RATES = 'View Negotiated Rates';
const VIEW_INVESTMENT_TYPES = 'View Investment Types';
const EDIT_INVESTMENT_TYPES = 'Edit Investment Types';
const ADD_INVESTMENT_TYPES = 'Add Investment Types';
const DEACTIVATE_INVESTMENT_TYPES = 'Deactivate Investment Types';
const ADD_COMMENTS = 'Add Comments';
const EDIT_COMMENTS = 'Edit Comments';
const DELETE_COMMENTS = 'Delete Comments';
const VIEW_ANALYSIS = 'View Analysis';
const VIEW_ANALYSIS_OPEN_REPORTS = 'View Analysis Open Reports';
const VIEW_MONTE_CARLO_ANALYSIS = 'View Monte Carlo Analysis';
const RUN_MONTE_CARLO_MODEL = 'Run Monte Carlo Model';
const RUN_SHORT_TERM_MODEL = 'Run Short Term Model';
const DELETE_MONTE_CARLO_MODEL = 'Delete Monte Carlo Model';
const DELETE_SHORT_TERM_MODEL = 'Delete Short Term Model';
const VIEW_SHORT_TERM_MODEL = 'View Short Term Model';
const RESEND_INVITE = 'Resend Invite';
//Permission for cashVest
const VIEW_CASH_VEST_SCORES = 'View Cash Vest Scores';
const EDIT_CASH_VEST_SCORES = 'Edit Cash Vest Scores';
//Staging treasury service permission
const VIEW_STAGING_BALANCES = 'View Staging Balances';
const EDIT_STAGING_BALANCES = 'Edit Staging Balances';
const VALIDATE_STAGING_BALANCES = 'Validate Staging Balances';
const INVALIDATE_STAGING_BALANCES = 'Invalidate Staging Balances';
const DELETE_STAGING_BALANCES = 'Delete Staging Balances';
const VIEW_STAGING_FEES = 'View Staging Fees';
const EDIT_STAGING_FEES = 'Edit Staging Fees';
const VALIDATE_STAGING_FEES = 'Validate Staging Fees';
const INVALIDATE_STAGING_FEES = 'Invalidate Staging Fees';
const DELETE_STAGING_FEES = 'Delete Staging Fees';
const VIEW_STAGING_ACCOUNT_BALANCES = 'View Staging Account Balances';
const EDIT_STAGING_ACCOUNT_BALANCES = 'Edit Staging Account Balances';
const VALIDATE_STAGING_ACCOUNT_BALANCES = 'Validate Staging Account Balances';
const INVALIDATE_STAGING_ACCOUNT_BALANCES =
  'Invalidate Staging Account Balances';
const COMPLETE_ANALYSIS = 'Complete Analysis';
const VIEW_REPORTS = 'View Reports';
const UPLOAD_REPORTS = 'Upload Reports';
const EDIT_LIQUIDITY_LEVELS = 'Edit Liquidity Levels';
const VIEW_LIQUIDITY_LEVELS = 'View Liquidity Levels';
const VIEW_QUANDL_DATA = 'View Quandl Data';
const EDIT_REPORT_COMMENTS = 'Edit Report Comments';
const GENERATE_REPORTS = 'Generate Reports';
const DOWNLOAD_REPORTS = 'Download Reports';
const FINALIZE_REPORTS = 'Finalize/Unfinalize Reports';

const permissions = {
  VIEW_USERS,
  ADD_USERS,
  EDIT_USERS,
  DEACTIVATE_USERS,
  RESET_MFA,
  VIEW_CLIENTS,
  ADD_CLIENTS,
  EDIT_CLIENTS,
  DEACTIVATE_CLIENTS,
  VIEW_BANKS,
  ADD_BANKS,
  EDIT_BANKS,
  DEACTIVATE_BANKS,
  VIEW_ACCOUNTS,
  ADD_ACCOUNTS,
  EDIT_ACCOUNTS,
  DELETE_ACCOUNTS,
  VIEW_TRANSACTIONS,
  ADD_TRANSACTIONS,
  EDIT_TRANSACTIONS,
  DELETE_TRANSACTIONS,
  VIEW_ACCOUNT_GROUPS,
  ADD_ACCOUNT_GROUPS,
  EDIT_ACCOUNT_GROUPS,
  REMOVE_ACCOUNT_GROUP_ASSOCIATIONS,
  UPLOAD_FILES,
  DOWNLOAD_FILES,
  VIEW_TEMPLATES,
  MANAGE_TEMPLATES,
  DELETE_TEMPLATES,
  START_REPORT,
  VIEW_BANK_CLIENT_RELATIONSHIPS,
  EDIT_BANK_CLIENT_RELATIONSHIPS,
  VIEW_OPEN_REPORTS,
  DELETE_OPEN_REPORTS,
  VIEW_DASHBOARD,
  VIEW_BATCHES,
  DELETE_BATCHES,
  VIEW_VALIDATION_HISTORY,
  VALIDATE_BATCHES,
  VIEW_DATA_EXTRACTION_STATUS,
  DELETE_ACCOUNTS_FROM_BATCHES,
  VIEW_STAGING_TRANSACTIONS,
  VALIDATE_ACCOUNTS_IN_BATCH,
  VIEW_ACCOUNT_BATCHES,
  EDIT_STAGING_TRANSACTIONS,
  DELETE_STAGING_TRANSACTIONS,
  VALIDATE_STAGING_TRANSACTIONS,
  IGNORE_ACCOUNTS_IN_BATCH,
  RESTORE_ACCOUNTS_IN_BATCH,
  INVALIDATE_STAGING_TRANSACTIONS,
  VIEW_STAGING_INVESTMENTS,
  EDIT_STAGING_INVESTMENTS,
  DELETE_STAGING_INVESTMENTS,
  VALIDATE_STAGING_INVESTMENTS,
  INVALIDATE_STAGING_INVESTMENTS,
  VIEW_INVESTMENTS,
  ADD_INVESTMENTS,
  EDIT_INVESTMENTS,
  DELETE_INVESTMENTS,
  VIEW_INVESTMENT_GROUPS,
  ADD_INVESTMENT_GROUPS,
  EDIT_INVESTMENT_GROUPS,
  DELETE_INVESTMENT_GROUPS,
  REMOVE_INVESTMENT_GROUP_ASSOCIATIONS,
  ADD_INVESTMENT_GROUP_ASSOCIATIONS,
  VIEW_USER_CLIENT_ASSOCIATION,
  VIEW_GLOBAL_SETTINGS,
  EDIT_GLOBAL_SETTINGS,
  VIEW_KEYWORDS,
  ADD_KEYWORDS,
  EDIT_KEYWORDS,
  DELETE_KEYWORDS,
  VIEW_NOTE_CATEGORIES,
  ADD_NOTE_CATEGORIES,
  EDIT_NOTE_CATEGORIES,
  DEACTIVATE_NOTE_CATEGORIES,
  VIEW_QUOTE_TYPES,
  ADD_QUOTE_TYPES,
  EDIT_QUOTE_TYPES,
  DEACTIVATE_QUOTE_TYPES,
  ADD_NOTES,
  EDIT_NOTES,
  VIEW_NOTES,
  RESOLVE_NOTES,
  DELETE_NOTES,
  RESTORE_NOTES,
  VIEW_RESOLVED_NOTES,
  VIEW_USERS_LIST,
  VIEW_ACCOUNT_LINKS,
  EDIT_ACCOUNT_LINKS,
  ADD_ACCOUNT_LINKS,
  DELETE_ACCOUNT_LINKS,
  VIEW_NEGOTIATED_RATES,
  ADD_NEGOTIATED_RATES,
  EDIT_NEGOTIATED_RATES,
  DELETE_NEGOTIATED_RATES,
  VIEW_INVESTMENT_TYPES,
  ADD_INVESTMENT_TYPES,
  EDIT_INVESTMENT_TYPES,
  DEACTIVATE_INVESTMENT_TYPES,
  ADD_COMMENTS,
  EDIT_COMMENTS,
  DELETE_COMMENTS,
  DELETE_ACCOUNT_GROUPS,
  VIEW_ANALYSIS,
  VIEW_ANALYSIS_OPEN_REPORTS,
  VIEW_MONTE_CARLO_ANALYSIS,
  RUN_MONTE_CARLO_MODEL,
  DELETE_MONTE_CARLO_MODEL,
  RUN_SHORT_TERM_MODEL,
  DELETE_SHORT_TERM_MODEL,
  VIEW_SHORT_TERM_MODEL,
  RESEND_INVITE,
  EDIT_CASH_VEST_SCORES,
  VIEW_CASH_VEST_SCORES,
  VIEW_STAGING_BALANCES,
  EDIT_STAGING_BALANCES,
  VALIDATE_STAGING_BALANCES,
  INVALIDATE_STAGING_BALANCES,
  DELETE_STAGING_BALANCES,
  VIEW_STAGING_FEES,
  EDIT_STAGING_FEES,
  VALIDATE_STAGING_FEES,
  INVALIDATE_STAGING_FEES,
  DELETE_STAGING_FEES,
  VIEW_STAGING_ACCOUNT_BALANCES,
  EDIT_STAGING_ACCOUNT_BALANCES,
  VALIDATE_STAGING_ACCOUNT_BALANCES,
  INVALIDATE_STAGING_ACCOUNT_BALANCES,
  COMPLETE_ANALYSIS,
  UPLOAD_REPORTS,
  VIEW_REPORTS,
  EDIT_LIQUIDITY_LEVELS,
  VIEW_LIQUIDITY_LEVELS,
  VIEW_QUANDL_DATA,
  EDIT_REPORT_COMMENTS,
  GENERATE_REPORTS,
  DOWNLOAD_REPORTS,
  FINALIZE_REPORTS,
};

export default permissions;
