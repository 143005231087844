import React from 'react';
import shortid from 'shortid';
import { MenuItem } from '@blueprintjs/core';
import moment from 'moment';
import * as Yup from 'yup';
import permissions from './permissions';
import colors from './colors';
import { collectionTypes } from './constantsList';

// pass in appUser permissions and the required permission
// if the required permission is in the list of userPermissions return true
export const checkPermissions = (userPermissions, requiredPermissions) => {
  return userPermissions.some((value) => requiredPermissions.includes(value));
};

// show list items for dropdown select
export const showListItems = (selection, { handleClick, modifiers }) => {
  return (
    <MenuItem
      key={shortid.generate()}
      active={modifiers.active}
      text={selection.label}
      onClick={handleClick}
    />
  );
};

// this for the select fields with filters
export const filterList = (query, items) => {
  return items.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

// For numeric fields to show as number of months (eg reportFrequncy, dataGatheringFrequency)
export function formattedMonthsList(allowedValues) {
  allowedValues = allowedValues.filter(n => n);
  return allowedValues.map(function (element) {
    const label = element === 1 ? '1 month' : element + ' months';
    return {
      label,
      value: element
    };
  });
}

// Used to set bank data availability dates and other fields with ' th business day of the month'
export const dataAvailabilityDateList = (allowedValues) => {
  allowedValues = allowedValues.map(value => (value === null ? undefined : value));
  return allowedValues.map(function (element) {
    const label =
      element === undefined ? 'Not Set' : getOrdinalNumber(element) + ' business day of the month';
    return {
      label,
      value: element
    };
  });
};

// Formats numbers into ordinals, eg 1st business day for the bank data availability dates in BankForm
export const getOrdinalNumber = (num) => {
  if (num % 10 === 1 && num % 100 !== 11) {
    return num + 'st';
  } else if (num % 10 === 2 && num % 100 !== 12) {
    return num + 'nd';
  } else if (num % 10 === 3 && num % 100 !== 13) {
    return num + 'rd';
  } else {
    return num + 'th';
  };
};

//Fetches the account data_gathering_frequency
export const defaultAccountDataGatheringFrequency = (clientFrequency, bankFrequency) => {
  var minFrequency = Math.min(...[clientFrequency, bankFrequency].filter(n => n));
  if (minFrequency > 0) {
    return minFrequency;
  }
  else {
    return undefined
  }
};
// date formatter for date inputs
export const dateFormatter = () => {
  const format = 'MM/DD/YYYY';
  const locale = 'en';
  return {
    formatDate: (date) => moment(date).locale(locale).format(format),
    parseDate: (str) => moment(str, format).locale(locale).toDate(),
    placeholder: 'mm/dd/yyyy',
  };
};

// convert date to local time without showing time
export const localDateFormatter = (dateVal) => {
  return moment
    .utc(`${dateVal}`, 'MM-DD-YYYY hh:mm:ss')
    .local()
    .format('MM-DD-YYYY')
    .toString();
};

// convert date to local time without showing time
export const localDateFormatterWithTime = (dateVal) => {
  return moment
    .utc(`${dateVal}`, 'MM-DD-YYYY hh:mm:ss')
    .local()
    .format('MM-DD-YYYY hh:mm A')
    .toString();
};

// convert utc time to local time, show date and time (18:30:40 utc time shows 2:30 PM eastern)
export const militaryToStandard = (time) => {
  return moment
    .utc(time, 'MM-DD-YYYY hh:mm:ss')
    .local()
    .format('MM-DD-YYYY LT');
};

export const checkSameName = (name, nameList) => {
  return nameList.includes(name.toLowerCase());
};

//Converts the report cycle start month for a client into the full report cycle, based on the report_cycle frequency.
export const calculateCycleOptions = (reportFrequency) => {
  const cycleOptions = [];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  const allowedStartMonths = new Set(
    Array.from({ length: months.length }, (_, i) => i % reportFrequency)
  );

  for (const alm of allowedStartMonths) {
    const cycleStartOptions = months.reduce((acc, _, i) => {
      if ((i - alm) % reportFrequency === 0) {
        acc.push(i);
      }
      return acc;
    }, []);

    const cycleEndOptions = cycleStartOptions.map(
      (i) => (i + reportFrequency - 1) % 12
    );

    let cycleString = '';
    for (let i = 0; i < cycleStartOptions.length; i++) {
      const start = cycleStartOptions[i];
      const end = cycleEndOptions[i];
      if (start !== end) {
        cycleString += `${months[start]}-${months[end]}`;
      } else {
        cycleString += `${months[start]}`;
      }
      if (i < cycleStartOptions.length - 1) {
        cycleString += `; `
      }
    }
    cycleOptions.push({ value: alm + 1, label: cycleString });
  }
  return cycleOptions;
};

// amount parameter needs to be string number or number
// digit is for number of decimals
export const amountFormatter = (amount, digit) => {
  return amount !== 0
    ? Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digit,
    })
    : '$0';
};

// Add percentage sign and determine desired number of digits after the decimal point
export const rateFormatter = (value, digit = 2) => {
  return !!value ? `${parseFloat(value).toFixed(digit)}%` : '-';
};

export const downloadFiles = ({ data }, fileName) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  // append to html page
  document.body.appendChild(link);
  // force download
  link.click();
  // clean up and remove link
  link.parentNode.removeChild(link);
};

/**
 * Transforms template form values to be accepted by the api
 * removes empty form fields
 * transforms fields with multiple selected columns
 * @param {obj} values
 */
export const transformValues = (values) => {
  //avoid side-effects
  let copiedValues = Object.assign({}, values);
  //make description array of numbers
  if (copiedValues.description) {
    copiedValues.description = copiedValues.description
      .toString()
      .trim()
      .split(',')
      .map((x) => +x);
  }

  // if form has account number header it needs to be sent in array of numbers
  if (copiedValues.accountNumberHeader) {
    copiedValues.accountNumberHeader = copiedValues.accountNumberHeader
      .toString()
      .trim()
      .split(',')
      .map((x) => +x);
  }

  //remove empty values
  Object.keys(copiedValues).forEach(
    (key) => copiedValues[key] === '' && delete copiedValues[key]
  );

  //make strings to int
  // if template has cellHeaderData, it needs to be a string still
  Object.keys(copiedValues).forEach((key) => {
    if (typeof copiedValues[key] === 'string' && key !== 'cellHeaderData')
      copiedValues[key] = Number(copiedValues[key]);
  });

  return copiedValues;
};

/**
 * Custom schema method to make sure fields that point to column numbers are unique
 * In case of description it makes sure the specified numbers are unique as well
 * The keyword "this" is needed since this is not an arrow function
 * The method extends a yup schema so values and methods such as path, resolve, etc
 * come from the yup class
 * This function run for every field that uses it as a restriction and checks against
 * the other restricted fields to make sure there are no duplicates
 * @param {Array.<String>} formColumnFields  - Names of columns to be restricted
 * @param {String} message - error message
 */
export const restrictFieldColumns = function (formColumnFields, message) {
  return this.test('uniqueColumn', message, function () {
    const currentField = this.path;
    const currentValue = this.resolve(Yup.ref(currentField));
    let valid = true;

    const remainingFields = formColumnFields.filter(
      (field) => field !== currentField
    );

    //Example of unique fields Name, age, location. if current field is name then the remaining are age and location
    remainingFields.forEach((field) => {
      //Val is the value of the field, the field param is the string value
      const val = this.resolve(Yup.ref(field));
      const notEmpty = currentValue && val;
      if (
        notEmpty &&
        field === 'description' &&
        val.toString().trim().split(',').indexOf(currentValue) > -1
      )
        valid = false;
      else if (
        notEmpty &&
        currentField === 'description' &&
        currentValue.toString().trim().split(',').indexOf(val) > -1
      )
        valid = false;
      else if (notEmpty && val === currentValue) valid = false;
    });
    return valid;
  });
};

/**
 * Helper method to check if the validate batch button should be enable
 * If a single item in the batch is not validated/ignored the button is disabled
 * @param {Array.<Obj>} batchItems - Items in batch
 */
export const checkBatchValidateButton = (batchItems) => {
  if (batchItems === undefined) return true;
  else if (batchItems.length === 0) return true;

  for (let item of batchItems) {
    if (item.status !== 'Validated' && item.status !== 'Ignored') return true;
  }
  return false;
};

//Takes in string date and converts it to the date
export const stringToDate = (StringDate) => {
  if (StringDate === 'Invalid date') return null;
  else if (!StringDate) return null;
  return moment(StringDate, 'MM-DD-YYYY').toDate();
};

//Takes in date object and converts it to the string format
export const dateToString = (
  dateObj,
  format = 'YYYY-MM-DD',
  useLocalTime = false
) => {
  if (!useLocalTime) return moment.utc(dateObj).format(format);
  else return moment.utc(dateObj).local().format(format);
};

export const filterExtension = (file, acceptedExtensions) => {
  // need to get the extension from the file name
  // last instance of . and take the next part after
  const extension = file.name
    .substr(file.name.lastIndexOf('.') + 1)
    .toLowerCase();

  // return true or false if extension is in the list of accepted extensions
  return acceptedExtensions.includes(extension);
};

/**
 * Adds appropriate commas , on a numeric input
 * @param {number} num
 */
export const currencyFormat = (num, hasSign) => {
  if (!num) return num;
  //remove previous commas if any and reformat
  num = currencyRemoveFormat(num);
  //if not appropriate format do not format
  if (!/^[-]?\d*\.?\d{1,2}$/.test(num)) return num;
  return (hasSign ? '$' : '') + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

/**
 * Removes commas from a previously formatted numeric input
 * @param {number} num
 */
export const currencyRemoveFormat = (num) => {
  if (!num) return num;
  return String(num).replace(/,/g, '');
};

//Function to transform filter variables to filter summary text
export const amountToFilterText = (lowValue, highValue, name) => {
  return {
    name: name,
    value: `${lowValue ? '$' + currencyFormat(lowValue) : ''} - ${highValue ? '$' + currencyFormat(highValue) : ''
      }`,
  };
};

export const numberRangeToFilterText = (lowValue, highValue, name) => {
  return { name: name, value: `${lowValue || ''} - ${highValue || ''}` };
};

export const percentageToFilterText = (lowValue, highValue, name) => {
  return {
    name: name,
    value: `${lowValue ? lowValue + '%' : ''} - ${highValue ? highValue + '%' : ''
      }`,
  };
};

// get number of months between two dates
export const getMonthDifference = (startDate, endDate) => {
  const formattedStartDate = moment(startDate, 'MM-DD-YYYY').format(
    'YYYY-MM-DD'
  );
  const formattedEndDate = moment(endDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
  // if less than one month (.diff will show 0 if less than full month), show 1 Month, else show number of months
  if (moment(formattedEndDate).diff(formattedStartDate, 'months') === 0) {
    return 'Less than 1';
  } else {
    return moment(formattedEndDate).diff(formattedStartDate, 'months');
  }
};

// date parameter should have the format of "MM-DD-YYYY"
// get month and date in format of MM/YYYY
export const monthYearOnly = (date) => {
  const month = date.slice(0, 2);
  const year = date.slice(6);
  const monthYearDate = `${month}/${year}`;
  return monthYearDate;
};

// takes a range of two dates and converts the dates to a string
// MMM = a three letter month abbreviation, e.g. Sep, Oct, Nov
// in the form of "MMM DD(th) YYYY to MMM DD(th) YYYY"
export const dateToFilterText = (dateRange, name) => {
  const [lowValue, highValue] = dateRange;
  return {
    name: name,
    value: `${lowValue ? dateToString(lowValue, 'MMM Do YYYY') : ''} to ${highValue ? dateToString(highValue, 'MMM Do YYYY') : ''
      }`,
  };
};

export const textToFilterText = (value, name) => {
  return { name: name, value: value || '' };
};

export const multiSelectToFilterText = (values, name) => {
  const joinedValues = values ? values : '';
  return { name: name, value: joinedValues };
};

export const dateRangeChecker = (dateRange) => {
  const [min, max] = dateRange || [];
  return min || max ? true : false;
};
// End of filter summary functions

/**
 * Function that gives the date range of value passed in, one month, two months, three months etc.
 * Used as a default filter on certain tables (investments, validation history)
 */
export const getMonthRange = (val) => {
  const month = new Date();
  month.setMonth(month.getMonth() - val);
  return [month, new Date()];
};

/**
 * Function that takes in an end date and returns a start date based by
 * subtracting the 'numberOfYears' given in the parameter from the end year
 */
export const getXYearsAgo = (numberOfYears, endDate) => {
  const endDateYear = endDate.getFullYear();
  let startDate = new Date(endDate.valueOf());
  startDate.setYear(endDateYear - Number(numberOfYears));
  return startDate;
};

/**
 * Function that gives the date range from today and today plus given days
 * @param {Number} days to add.
 */
export const getXDaysFromNow = (numOfDays) => {
  const daysFromNow = new Date();
  daysFromNow.setDate(daysFromNow.getDate() + parseInt(numOfDays));
  return [new Date(), daysFromNow];
};

export const getWeekRange = () => {
  const lastWeek = new Date();
  lastWeek.setHours(0, 0, 0, 0);
  lastWeek.setDate(lastWeek.getDate() - 7);
  return [lastWeek, new Date()];
};

export const getTwoWeeksRange = () => {
  // create date object with no hours set.
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setHours(0, 0, 0, 0);
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
  return [twoWeeksAgo, new Date()];
};

export const getOneYearRange = () => {
  // create date object with no hours set.
  const oneYearAgo = new Date();
  oneYearAgo.setHours(0, 0, 0, 0);
  oneYearAgo.setDate(oneYearAgo.getDate() - 365);
  return [oneYearAgo, new Date()];
};

/**
 * Paginates a list of items and returns the paginated result
 * @param {Array} array
 * @param {int} pageSize
 * @param {int} pageNumber
 */
export const paginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

/**
 * Returns number with its ordinal suffix
 * ex 1st, 2nd, 3rd
 * @param {int} n
 */
export const getNumberWithOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0;
};

// checks if enter key is pressed to prevent form submit on forms with multi select
export const onKeyDown = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

//This function console logged the difference on the backend and frontend permission
export const permissionMatcher = (appUser) => {
  console.log(
    Object.values(permissions).filter(
      (item) => !appUser.permList.includes(item)
    ),
    appUser.permList.filter(
      (item) => !Object.values(permissions).includes(item)
    )
  );
  return;
};

export const getDateTwentyYearsInFuture = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 20);
  return date;
};

export const getDateTwentyYearsInPast = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 20);
  return date;
};

// this function checks if date range is greater than 1 year.
export const checkForDisable = (
  endRange,
  accountGroup,
  clientAccountGroups,
  numberOfMonths = 12
) => {
  if (endRange && accountGroup) {
    const minDate = stringToDate(
      clientAccountGroups.find((item) => item.label === accountGroup).minDate
    );

    if (minDate) {
      const monthDifference = moment(endRange).diff(minDate, 'months', true);
      // disable if less than 12 months
      return monthDifference < numberOfMonths;
    } else return true;
  } else return true;
};


export const computeCollectionType = (accountsCount, accountsSentIn) => {
  if (accountsSentIn === 0) {
    return collectionTypes.DirectAccess;
  }
  else if (accountsSentIn > 0 && accountsSentIn === accountsCount) {
    return collectionTypes.SentIn;
  }
  return collectionTypes.Multiple;
}

export const getCollectionTypeColor = (value) => {
  switch (value) {
    case 0:
    case collectionTypes.DirectAccess:
      return colors.greyPlaceholderText;
    case 1:
    case collectionTypes.SentIn:
      return colors.purpleHover;
    case 2:
    case collectionTypes.Multiple:
      return colors.yellow;
    default:
      return colors.greyPlaceholderText
  }
}

export const getCollectionTypeValue = (value) => {
  switch (value) {
    case 0:
      return collectionTypes.DirectAccess;
    case 1:
      return collectionTypes.SentIn;
    case 2:
      return collectionTypes.Multiple;
    default:
      return collectionTypes.DirectAccess;
  }
}
