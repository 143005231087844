import { combineReducers } from 'redux';

const appUser = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_APP_USER_FULFILLED':
      return action.payload.data;
    case 'FETCH_APP_USER_REJECTED':
    case 'LOGOUT':
      return null;
    default:
      return state;
  }
};

const isAppUserFetching = (state = false, action) => {
  switch (action.type) {
    // return true when action is initialized,
    // and this happens before pending
    case '@@INIT':
    case 'FETCH_APP_USER_PENDING':
      return true;
    case 'FETCH_APP_USER_REJECTED':
    case 'FETCH_APP_USER_FULFILLED':
      return false;
    default:
      return state;
  }
};

const auth = combineReducers({
  appUser,
  isAppUserFetching,
});

export default auth;
