import React, { useEffect } from 'react';
import TreasuryData from './TreasuryData';
import FederalFundsRange from './FederalFundsRange';
import EffectiveFederalFunds from './EffectiveFederalFunds';
import AaFinancialCommercialPaper from './AaFinancialCommercialPaper';
import AaNonFinancialCommercialPaper from './AaNonFinancialCommercialPaper';
import A2P2CommercialPaper from './A2P2CommercialPaper';
import CorporateBond from './CorporateBond';
import { QuandlContainer } from './styled-components';
import { fetchQuandlData } from '../../../actions/quandl';
import { connect } from 'react-redux';

const QuandlDataBase = ({ quandlData, ...props }) => {
  const { fetchQuandlData } = props;

  useEffect(() => {
    // on home page, fetch quandl data
    if (props.match.url === '/dashboard') {
      fetchQuandlData();
    }
  }, [props.match.url, fetchQuandlData]);
  return (
    <QuandlContainer>
      <TreasuryData quandlData={quandlData} />
      <FederalFundsRange quandlData={quandlData} />
      <EffectiveFederalFunds quandlData={quandlData} />
      <AaFinancialCommercialPaper quandlData={quandlData} />
      <AaNonFinancialCommercialPaper quandlData={quandlData} />
      <A2P2CommercialPaper quandlData={quandlData} />
      <CorporateBond quandlData={quandlData} />
    </QuandlContainer>
  );
};

const mapStateToProps = (state) => ({
  quandlData: state.quandl.quandlData,
});

const QuandlData = connect(mapStateToProps, {
  fetchQuandlData,
})(QuandlDataBase);

export default QuandlData;
