import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { MessageText } from '../../styled-components';
import AppToast from '../../Toast';
import { deactivateKeywordCategories } from '../../../api/administration';
import DialogDeleteActions from '../DialogDeleteActions';

const KeywordCategoriesDeactivateDialogBox = ({
  isOpen,
  handleClose,
  title,
  categoryIds,
  categories,
  fetchKeywordCategories,
}) => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  //Disable if there are keywords assigned to any of the categories selected
  const isDisabled = categories.some(
    (category) => category.keywordsAssigned === true
  );

  const handleDeactivateCategories = () => {
    setIsDeactivating(true);
    deactivateKeywordCategories(categoryIds)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchKeywordCategories();
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to deactivate keyword category.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeactivating(false);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <MessageText>
          {isDisabled
            ? `The selected Keyword ${
                categoryIds.length > 1
                  ? 'Categories cannot be deactivated as there are keywords still assigned to them.'
                  : 'Category cannot be deactivated as there are keywords still assigned to it.'
              }`
            : 'Are you sure you want to deactivate' +
              (categoryIds.length > 1
                ? ' these keyword categories?'
                : ' this keyword category?')}
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeactivating}
        isDisabled={isDisabled}
        deleteText='DEACTIVATE'
        handleClose={handleClose}
        handleDelete={handleDeactivateCategories}
      />
    </Dialog>
  );
};

export default KeywordCategoriesDeactivateDialogBox;
