import React from 'react';
import { AlignCenter, FlexColumn } from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormActions from '../FormActions';

const groupSchema = Yup.object().shape({
  groupName: Yup.string().required('Required!'),
});

const InvestmentGroupForm = ({
  closeDialog,
  handleCreate,
  handleEdit,
  title,
  groupId,
  groupName,
  isTryingToSubmit,
}) => {
  return (
    <>
      {
        <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
          <Formik
            initialValues={{
              groupName,
            }}
            validateOnBlur={false}
            validationSchema={groupSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              if (title === 'Edit Investment Group')
                handleEdit(values, groupId);
              else handleCreate(values);
            }}
          >
            {({ values }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AlignCenter justifyContent='flex-start' padding='0 50px'>
                  <FlexColumn style={{ width: '100%' }}>
                    <ModalTextField
                      type='text'
                      name='groupName'
                      placeholder='Group Name'
                      label='Group Name'
                      value={values.groupName || ''}
                      autoComplete='off'
                    />
                  </FlexColumn>
                </AlignCenter>
                <FormActions
                  isSubmitting={isTryingToSubmit}
                  onClose={closeDialog}
                />
              </Form>
            )}
          </Formik>
        </AlignCenter>
      }
    </>
  );
};

export default InvestmentGroupForm;
