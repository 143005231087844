import React, { useEffect, useState } from 'react';
import { Dialog, Radio, RadioGroup } from '@blueprintjs/core';
import TableFilterActions from './TableFilterActions';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import {
  multiSelectToFilterText,
  textToFilterText,
} from '../../../utils/functions';

const StagingAccountBalanceFilterDialogBox = ({
  isOpen,
  handleClose,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
}) => {
  const [accountNumberList, setAccountNumberList] = useState([]);
  const [afpCodeList, setAfpCodeList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const [accountNumbers, setAccountNumbers] = useState([]);
  const [afpCodes, setAfpCodes] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [validated, setValidated] = useState('');

  useEffect(() => {
    if (initialData.length) {
      setAccountNumberList(
        initialData
          .map((item) => item.accountNumber)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setAfpCodeList(
        initialData
          .map((item) => item.afpCode)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setDescriptionList(
        initialData
          .map((item) => item.description)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData]);

  const getFilterSummary = () => {
    const filters = [];
    accountNumbers.length &&
      filters.push(multiSelectToFilterText(accountNumbers, 'Account Numbers'));
    afpCodes.length &&
      filters.push(multiSelectToFilterText(afpCodes, 'AFP Codes'));
    descriptions.length &&
      filters.push(multiSelectToFilterText(descriptions, 'Descriptions'));
    validated && filters.push(textToFilterText(validated, 'Validated?'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter(
      'accountNumber',
      accountNumbers.length ? accountNumbers : undefined
    );
    setFilter('afpCode', afpCodes.length ? afpCodes : undefined);
    setFilter('description', descriptions.length ? descriptions : undefined);
    setFilter('validationStatus', validated ? validated : undefined);
    handleClose();
  };
  const handleClearFilters = () => {
    setFilterSummary([]);
    setAllFilters([]);
    setAccountNumberList([]);
    setAccountNumbers([]);
    setAfpCodeList([]);
    setAfpCodes([]);
    setDescriptionList([]);
    setDescriptions([]);
    setValidated('');
    handleClose();
  };

  const handleValidateChanged = (event) => {
    setValidated(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title='Set Filters'
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0', marginBottom: '10px' }}>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setAccountNumbers}
            items={accountNumberList}
            selectedItems={accountNumbers}
            label='Account Numbers'
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setAfpCodes}
            items={afpCodeList}
            selectedItems={afpCodes}
            label='AFP Codes'
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setDescriptions}
            items={descriptionList}
            selectedItems={descriptions}
            label='Descriptions'
          />
        </div>
        <div
          style={{ marginBottom: '20px', width: '271px' }}
          className='radio-group'
        >
          <RadioGroup
            label={'Validated?'}
            onChange={handleValidateChanged}
            selectedValue={validated}
            inline={true}
          >
            <Radio label='Yes' value='Yes' style={{ marginRight: '20px' }} />
            <Radio label='No' value='No' style={{ marginRight: '20px' }} />
            <Radio label='None' value='' />
          </RadioGroup>
        </div>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default StagingAccountBalanceFilterDialogBox;
