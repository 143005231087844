import React, { useEffect, useState } from 'react';
import { Dialog, Radio, RadioGroup } from '@blueprintjs/core';
import TableFilterActions from './TableFilterActions';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import {
  multiSelectToFilterText,
  textToFilterText,
} from '../../../utils/functions';

const StagingBalancesFilterDialogBox = ({
  isOpen,
  handleClose,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
}) => {
  const [descriptionList, setDescriptionList] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [
    standardizedDescriptionList,
    setStandardizedDescriptionList,
  ] = useState([]);
  const [standardizedDescriptions, setStandardizedDescriptions] = useState([]);
  const [validated, setValidated] = useState('');
  const [AFPCodes, setAFPCodes] = useState([]);
  const [AFPCodeList, setAFPCodeList] = useState([]);

  useEffect(() => {
    if (initialData.length) {
      setDescriptionList(
        initialData
          .map((item) => item.description)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setStandardizedDescriptionList(
        initialData
          .map((item) => item.standardizedDescription)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setAFPCodeList(
        initialData
          .map((item) => item.afpCode)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData]);

  const getFilterSummary = () => {
    const filters = [];
    AFPCodes.length &&
      filters.push(multiSelectToFilterText(AFPCodes, 'AFP Codes'));
    descriptions.length &&
      filters.push(multiSelectToFilterText(descriptions, 'Descriptions'));
    standardizedDescriptions.length &&
      filters.push(
        multiSelectToFilterText(
          standardizedDescriptions,
          'Standardized Descriptions'
        )
      );
    validated && filters.push(textToFilterText(validated, 'Validated?'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('description', descriptions.length ? descriptions : undefined);
    setFilter(
      'standardizedDescription',
      standardizedDescriptions.length ? standardizedDescriptions : undefined
    );
    setFilter('validationStatus', validated ? validated : undefined);
    setFilter('afpCode', AFPCodes.length ? AFPCodes : undefined);
    handleClose();
  };

  const handleClearFilters = () => {
    setAllFilters([]);
    setDescriptionList([]);
    setStandardizedDescriptions([]);
    setDescriptions([]);
    setStandardizedDescriptionList([]);
    setFilterSummary([]);
    setValidated('');
    setAFPCodes([]);
    setAFPCodeList([]);
    handleClose();
  };

  const handleValidateChanged = (event) => {
    setValidated(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title='Set Filters'
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0', marginBottom: '10px' }}>
        <>
          <div style={{ marginBottom: '20px' }}>
            <MultiSelectField
              setSelectedItems={setAFPCodes}
              items={AFPCodeList}
              selectedItems={AFPCodes}
              label='AFP Codes'
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <MultiSelectField
              setSelectedItems={setDescriptions}
              items={descriptionList}
              selectedItems={descriptions}
              label='Descriptions'
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <MultiSelectField
              setSelectedItems={setStandardizedDescriptions}
              items={standardizedDescriptionList}
              selectedItems={standardizedDescriptions}
              label='Standardized Descriptions'
            />
          </div>
          <div
            style={{ marginBottom: '20px', width: '271px' }}
            className='radio-group'
          >
            <RadioGroup
              label={'Validated?'}
              onChange={handleValidateChanged}
              selectedValue={validated}
              inline={true}
            >
              <Radio label='Yes' value='Yes' style={{ marginRight: '20px' }} />
              <Radio label='No' value='No' style={{ marginRight: '20px' }} />
              <Radio label='None' value='' />
            </RadioGroup>
          </div>
        </>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default StagingBalancesFilterDialogBox;
