import React, { useEffect, useState } from 'react';
import { Dialog, Radio, RadioGroup } from '@blueprintjs/core';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import TableFilterActions from './TableFilterActions';
import RadioPagination from '../../RadioPagination';
import NumberRangeField from '../../select-fields/NumberRangeField';
import {
  multiSelectToFilterText,
  numberRangeToFilterText,
  textToFilterText,
} from '../../../utils/functions';

const StagingFeesTableFilterDialogBox = ({
  isOpen,
  handleClose,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
}) => {
  const [bankCategoryList, setBankCategoryList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [bankLevelExceptionList, setBankLevelExceptionList] = useState([]);
  const [clientLevelExceptionList, setClientLevelExceptionList] = useState([]);
  const [bankCategories, setBankCategories] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [bankLevelExceptions, setBankLevelExceptions] = useState([]);
  const [clientLevelExceptions, setClientLevelExceptions] = useState([]);
  const [unitPriceLow, setUnitPriceLow] = useState('');
  const [unitPriceHigh, setUnitPriceHigh] = useState('');
  const [validated, setValidated] = useState('');

  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    if (initialData.length) {
      setBankCategoryList(
        initialData
          .map((item) => item.bankCategory)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setDescriptionList(
        initialData
          .map((item) => item.description)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setCategoryList(
        initialData
          .map((item) => item.category)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setSubCategoryList(
        initialData
          .map((item) => item.subCategory)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setBankLevelExceptionList(
        initialData
          .map((item) => item.bankLevelException)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setClientLevelExceptionList(
        initialData
          .map((item) => item.clientLevelException)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData]);

  const getFilterSummary = () => {
    const filters = [];
    bankCategories.length &&
      filters.push(multiSelectToFilterText(bankCategories, 'Bank Categories'));
    descriptions.length &&
      filters.push(multiSelectToFilterText(descriptions, 'Descriptions'));
    categories.length &&
      filters.push(multiSelectToFilterText(categories, 'Categories'));
    subCategories.length &&
      filters.push(multiSelectToFilterText(subCategories, 'Sub Category'));
    bankLevelExceptions.length &&
      filters.push(
        multiSelectToFilterText(bankLevelExceptions, 'Bank Level Exception')
      );
    clientLevelExceptions.length &&
      filters.push(
        multiSelectToFilterText(clientLevelExceptions, 'Client Level Exception')
      );
    (unitPriceLow || unitPriceHigh) &&
      filters.push(
        numberRangeToFilterText(unitPriceLow, unitPriceHigh, 'Unit Price')
      );
    validated && filters.push(textToFilterText(validated, 'Validated?'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter(
      'bankCategory',
      bankCategories.length ? bankCategories : undefined
    );
    setFilter('description', descriptions.length ? descriptions : undefined);
    setFilter('category', categories.length ? categories : undefined);
    setFilter('subCategory', subCategories.length ? subCategories : undefined);
    setFilter(
      'bankLevelException',
      bankLevelExceptions.length ? bankLevelExceptions : undefined
    );
    setFilter(
      'clientLevelException',
      clientLevelExceptions.length ? clientLevelExceptions : undefined
    );
    setFilter(
      'unitPrice',
      !unitPriceLow && !unitPriceHigh
        ? undefined
        : [unitPriceLow || undefined, unitPriceHigh || undefined]
    );
    setFilter('validationStatus', validated ? validated : undefined);
    handleClose();
  };

  const handleClearFilters = () => {
    setAllFilters([]);
    setFilterSummary([]);
    setBankCategoryList([]);
    setDescriptionList([]);
    setSubCategoryList([]);
    setBankLevelExceptionList([]);
    setClientLevelExceptionList([]);
    setBankCategories([]);
    setDescriptions([]);
    setSubCategories([]);
    setBankLevelExceptions([]);
    setClientLevelExceptions([]);
    setUnitPriceLow('');
    setUnitPriceHigh('');
    setValidated('');
    handleClose();
  };

  const handleValidateChanged = (event) => {
    setValidated(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title='Set Filters'
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0', marginBottom: '10px' }}>
        {pageNumber === 0 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setBankCategories}
                items={bankCategoryList}
                selectedItems={bankCategories}
                label='Bank Categories'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setDescriptions}
                items={descriptionList}
                selectedItems={descriptions}
                label='Descriptions'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setCategories}
                items={categoryList}
                selectedItems={categories}
                label='Category'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setSubCategories}
                items={subCategoryList}
                selectedItems={subCategories}
                label='Sub Category'
              />
            </div>
          </>
        )}
        {pageNumber === 1 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <NumberRangeField
                labelName='Unit Price'
                selectLow={setUnitPriceLow}
                selectHigh={setUnitPriceHigh}
                low={unitPriceLow}
                high={unitPriceHigh}
                isCurrency={true}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setBankLevelExceptions}
                items={bankLevelExceptionList}
                selectedItems={bankLevelExceptions}
                label='Bank Level Exceptions'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setClientLevelExceptions}
                items={clientLevelExceptionList}
                selectedItems={clientLevelExceptions}
                label='Client Level Exceptions'
              />
            </div>
            <div
              style={{ marginBottom: '20px', width: '271px' }}
              className='radio-group'
            >
              <RadioGroup
                label={'Validated?'}
                onChange={handleValidateChanged}
                selectedValue={validated}
                inline={true}
              >
                <Radio
                  label='Yes'
                  value='Yes'
                  style={{ marginRight: '20px' }}
                />
                <Radio label='No' value='No' style={{ marginRight: '20px' }} />
                <Radio label='None' value='' />
              </RadioGroup>
            </div>
          </>
        )}
      </div>
      <RadioPagination
        pages={2}
        onClickSetPage={setPageNumber}
        page={pageNumber}
      />
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default StagingFeesTableFilterDialogBox;
