import React from 'react';
import { ComponentHeader, HeaderText } from '../styled-components';
import QuandlData from './header-data/QuandlData';

const DashBoardHeader = ({ header, appUser, ...props }) => {
  const { open } = props;
  return (
    <ComponentHeader className={open}>
      <QuandlData {...props} />
      <HeaderText>
        {header} {appUser.firstName}
      </HeaderText>
    </ComponentHeader>
  );
};

export default DashBoardHeader;
