import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@blueprintjs/core';
import NotesTable from '../../table/NotesTable';
import { AlignCenter, RegularButton } from '../../styled-components';
import { fetchBatchNotes } from '../../../actions/notes';

const ViewNotesDialogBase = ({
  isOpen,
  title,
  handleClose,
  batch,
  appUser,
  fetchBatchNotes,
  batchNotes,
  isFetchingBatchNotes,
}) => {
  useEffect(() => {
    batch.batchId && fetchBatchNotes(batch.batchId);
  }, [batch, fetchBatchNotes]);
  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      className='large-dialog border-less-header'
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div
        style={{
          padding: '30px 50px 20px',
          maxHeight: '650px',
          display: 'block',
          overflowY: 'auto',
        }}
      >
        <NotesTable
          appUser={appUser}
          notes={batchNotes}
          isFetching={isFetchingBatchNotes}
          batchId={batch.batchId}
        />
      </div>
      <AlignCenter padding='10px 20px' justifyContent='flex-end'>
        <RegularButton padding='10px 30px' onClick={handleClose}>
          CLOSE
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  batchNotes: state.notes.batchNotes,
  isFetchingBatchNotes: state.notes.isFetchingBatchNotes,
});

const ViewNotesDialog = connect(mapStateToProps, { fetchBatchNotes })(
  ViewNotesDialogBase
);

export default ViewNotesDialog;
