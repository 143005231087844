import React from 'react';
import iconValue from '../../../utils/icons';
import { Icon } from '@blueprintjs/core';
import colors from '../../../utils/colors';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  setPageSize,
  nextPage,
  previousPage,
  pageIndex,
  pageSize,
  rows,
  visibleRows,
  customPageOptions,
  runUpdatePaginate,
  isServerSidePagination,
}) => {
  const options = customPageOptions ? customPageOptions : [10, 25, 50, 75, 100];
  return (
    <>
      <span>
        <span style={{ color: colors.normalText, marginRight: '50px' }}>
          Rows per page{' '}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              if (isServerSidePagination) runUpdatePaginate();
            }}
            style={{ border: 0, color: colors.normalText, fontSize: '1rem' }}
          >
            {options.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>{' '}
        </span>
        <button
          onClick={() => {
            previousPage();
            if (isServerSidePagination) runUpdatePaginate();
          }}
          disabled={!canPreviousPage}
          title='Previous Page'
          style={{ background: 'transparent', border: 'none' }}
        >
          <Icon icon={iconValue.chevronLeft} iconSize={15} />
        </button>
        <span
          style={{
            color: colors.normalText,
          }}
        >
          {(rows && visibleRows === 0) || !rows
            ? 0
            : pageIndex === 0
            ? pageIndex + 1
            : pageIndex * pageSize + 1}{' '}
          to{' '}
          {visibleRows === 0 ? visibleRows : pageIndex * pageSize + visibleRows}{' '}
          of {!rows ? 0 : rows}
        </span>
        <button
          onClick={() => {
            nextPage();
            if (isServerSidePagination) runUpdatePaginate();
          }}
          disabled={!canNextPage}
          title='Next Page'
          style={{ background: 'transparent', border: 'none' }}
        >
          <Icon icon={iconValue.chevronRight} iconSize={15} />
        </button>
      </span>
    </>
  );
};

export default Pagination;
