import React, { useEffect } from 'react';
import ClientsWidget from '../components/widgets/ClientsWidget';
import DashBoardHeader from '../components/headers/DashBoardHeader';
import {
  ComponentBody,
  WidgetContainer,
} from '../components/styled-components';
import { connect } from 'react-redux';
import {
  fetchClientsForUser,
  fetchReportsInProgress,
  fetchNegotiatedRates,
  fetchMaturingInvestments,
} from '../actions/dashboard';
import { fetchAllNotes } from '../actions/notes';
import { fetchMaturityLimit } from '../actions/administration';
import { fetchAllClients } from '../actions/clients';
import LoadSpinner from '../components/LoadSpinner';
import ReportsInProgressWidget from '../components/widgets/ReportsInProgressWidget';
import MaturingInvestmentsWidget from '../components/widgets/MaturingInvestmentsWidget';
import NegotiatedRatesTable from '../components/table/NegotiatedRatesTable';
import NotesTable from '../components/table/NotesTable';
import { checkPermissions } from '../utils/functions';
import permissions from '../utils/permissions';

const DashboardBase = ({
  appUser,
  userClients,
  negotiatedRates,
  isUserClientsFetching,
  fetchClientsForUser,
  fetchReportsInProgress,
  reportsInProgress,
  fetchNegotiatedRates,
  isReportsInProgressFetching,
  isFetchingNegotiatedRates,
  fetchAllNotes,
  isFetchingAllNotes,
  allNotes,
  fetchMaturingInvestments,
  maturingInvestments,
  isMaturingInvestmentsFetching,
  fetchMaturityLimit,
  maturityLimit,
  fetchAllClients,
  clients,
  ...props
}) => {
  useEffect(() => {
    fetchClientsForUser(appUser.userId);
    fetchReportsInProgress(appUser.userId);
    fetchMaturingInvestments(appUser.userId);
    fetchNegotiatedRates();
    fetchMaturityLimit();
    fetchAllNotes();
    fetchAllClients();
  }, [
    appUser,
    fetchClientsForUser,
    fetchReportsInProgress,
    fetchNegotiatedRates,
    fetchAllNotes,
    fetchMaturingInvestments,
    fetchMaturityLimit,
    fetchAllClients,
  ]);

  return (
    <>
      <DashBoardHeader header={`Welcome,`} appUser={appUser} {...props} />
      <ComponentBody>
        {isUserClientsFetching || isReportsInProgressFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '16rem',
            }}
          >
            <LoadSpinner size={200} />
          </div>
        ) : (
          <>
            <WidgetContainer>
              <ClientsWidget clients={userClients} />
              <MaturingInvestmentsWidget
                investments={maturingInvestments.investmentsByClient}
                maturityLimit={maturityLimit[0]}
              />
              <ReportsInProgressWidget reports={reportsInProgress} />
            </WidgetContainer>

            {checkPermissions(appUser.permList, permissions.VIEW_NOTES) && (
              <div style={{ margin: '0rem 3rem', padding: '1.5rem 0' }}>
                <NotesTable
                  appUser={appUser}
                  isFetching={isFetchingAllNotes}
                  notes={allNotes}
                  isDashboard={true}
                  {...props}
                />
              </div>
            )}
            {checkPermissions(
              appUser.permList,
              permissions.VIEW_NEGOTIATED_RATES
            ) && (
              <div style={{ margin: '0rem 3rem', padding: '1.5rem 0' }}>
                <NegotiatedRatesTable
                  appUser={appUser}
                  negotiatedRates={negotiatedRates}
                  isFetching={isFetchingNegotiatedRates}
                  fetchNegotiatedRates={fetchNegotiatedRates}
                  {...props}
                />
              </div>
            )}
          </>
        )}
      </ComponentBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  userClients: state.dashboard.userClients,
  isUserClientsFetching: state.dashboard.isUserClientsFetching,
  reportsInProgress: state.dashboard.reportsInProgress,
  negotiatedRates: state.dashboard.negotiatedRates,
  isFetchingNegotiatedRates: state.dashboard.isFetchingNegotiatedRates,
  isReportsInProgressFetching: state.dashboard.isReportsInProgressFetching,
  allNotes: state.notes.allNotes,
  isFetchingAllNotes: state.notes.isFetchingAllNotes,
  maturingInvestments: state.dashboard.maturingInvestments,
  isMaturingInvestmentsFetching: state.dashboard.isMaturingInvestmentsFetching,
  maturityLimit: state.administration.maturityLimit,
  clients: state.clients.allClients,
});

const Dashboard = connect(mapStateToProps, {
  fetchClientsForUser,
  fetchReportsInProgress,
  fetchNegotiatedRates,
  fetchAllNotes,
  fetchMaturingInvestments,
  fetchMaturityLimit,
  fetchAllClients,
})(DashboardBase);

export default Dashboard;
