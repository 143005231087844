import React, { useMemo, useState } from 'react';
import {
  NameLink,
  ActionIconButton,
  StyleColumnCell,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import { localDateFormatter, checkPermissions } from '../../utils/functions';
import {
  Icon,
  MenuItem,
  Menu,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import permissions from '../../utils/permissions';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import AccountGroupDeleteDialogBox from '../dialog/account-profile-dialog/AccountGroupDeleteDialogBox';
import AccountGroupDialogBox from '../dialog/account-profile-dialog/AccountGroupDialogBox';
import { TableContainer } from './components/table-styled-components';

const AccountGroupsTable = ({
  appUser,
  accountGroups,
  isFetching,
  clientId,
  ...props
}) => {
  const [
    isDeleteAccountGroupDialogOpen,
    setIsDeleteAccountGroupDialogOpen,
  ] = useState(false);
  const [selectedAccountGroups, setSelectedAccountGroups] = useState([]);
  const [isAccountGroupDialogOpen, setIsAccountGroupDialogOpen] = useState(
    false
  );
  const [accountGroup, setAccountGroup] = useState({});

  const handleDeleteDialogOpen = (accountGroups) => {
    accountGroups.length
      ? setSelectedAccountGroups(
          accountGroups.filter(
            (account) => account.groupName !== 'All Accounts'
          )
        )
      : setAccountGroup(accountGroups);
    setIsDeleteAccountGroupDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setAccountGroup({});
    setSelectedAccountGroups([]);
    setIsDeleteAccountGroupDialogOpen(false);
  };

  const handleEditAccountGroupDialogOpen = (accountGroup) => {
    setIsAccountGroupDialogOpen(true);
    setAccountGroup(accountGroup);
  };

  const handleEditAccountGroupDialogClose = () => {
    setIsAccountGroupDialogOpen(false);
    setAccountGroup({});
  };

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) =>
          row.original.groupName !== 'All Accounts' && (
            <RowSelector
              {...row.getToggleRowSelectedProps()}
              style={{ height: '15px', width: '15px' }}
            />
          ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Group Name' />
        ),
        accessor: 'groupName',
        width: '40%',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        Cell: ({ cell: { value }, row }) => {
          return (
            <NameLink
              to={`/client-management/${clientId}/account-group/${row.original.groupID}`}
            >
              {value}
            </NameLink>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Accounts' />
        ),
        accessor: 'numberOfAccounts',
        width: '10%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Updated At' />
        ),
        accessor: 'updatedAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        Cell: ({ cell: { value } }) => localDateFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Meets Short-term Validation'
          />
        ),
        accessor: 'validationStatus',
        width: '20%',
        isVisible: true,
        Cell: ({ cell: { value } }) => (
          <StyleColumnCell
            color={value === 'YES' ? colors.primary : colors.red}
          >
            {value}
          </StyleColumnCell>
        ),
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        sticky: 'right',
        Cell: ({ row }) => {
          return (
            <>
              {row.original.groupName !== 'All Accounts' ? (
                <Popover
                  className='table-action-menu'
                  interactionKind='click'
                  position={Position.BOTTOM_RIGHT}
                  minimal={true}
                  content={
                    <Menu>
                      {checkPermissions(appUser.permList, [
                        permissions.EDIT_ACCOUNT_GROUPS,
                      ]) && (
                        <MenuItem
                          text='Edit'
                          style={{
                            color: `${colors.purpleText}`,
                            fontWeight: '700',
                          }}
                          onClick={() =>
                            handleEditAccountGroupDialogOpen(row.original)
                          }
                          disabled={row.original.groupName === 'All Accounts'}
                        />
                      )}
                      {checkPermissions(appUser.permList, [
                        permissions.DELETE_ACCOUNT_GROUPS,
                      ]) && (
                        <>
                          <Menu.Divider />
                          <MenuItem
                            text='Delete'
                            disabled={row.original.groupName === 'All Accounts'}
                            style={{
                              color: `${colors.red}`,
                              fontWeight: '700',
                              paddingTop: ' 5px',
                              paddingBottom: ' 5px',
                              marginBottom: '10px',
                            }}
                            onClick={() => handleDeleteDialogOpen(row.original)}
                          />
                        </>
                      )}
                    </Menu>
                  }
                >
                  <ActionIconButton>
                    <Icon
                      icon={iconValue.menu}
                      iconSize={16}
                      color={colors.primary}
                    />
                  </ActionIconButton>
                </Popover>
              ) : (
                <Tooltip
                  content={
                    'You can\'t edit the name or delete "All Accounts" group.'
                  }
                  style={{ width: '25rem' }}
                >
                  <ActionIconButton>
                    <Icon
                      icon={iconValue.info}
                      iconSize={16}
                      color={colors.primary}
                    />
                  </ActionIconButton>
                </Tooltip>
              )}
            </>
          );
        },
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_ACCOUNT_GROUPS,
          permissions.DELETE_ACCOUNT_GROUPS,
          permissions.EDIT_ACCOUNT_GROUPS,
        ]),
      },
    ],
    [appUser, clientId]
  );

  return (
    <TableContainer padding='0rem'>
      <MakeTable
        appUser={appUser}
        columns={columns}
        data={accountGroups}
        isFetching={isFetching}
        tableTitle='Account Groups'
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        {...props}
      />
      <AccountGroupDeleteDialogBox
        isOpen={isDeleteAccountGroupDialogOpen}
        title={
          selectedAccountGroups.length
            ? `Delete Selected Account Groups (${selectedAccountGroups.length})`
            : 'Delete Account Group'
        }
        selectedAccountGroups={selectedAccountGroups}
        accountGroup={accountGroup}
        clientId={clientId}
        handleClose={handleDeleteDialogClose}
      />
      <AccountGroupDialogBox
        isOpen={isAccountGroupDialogOpen}
        title='Edit Group'
        clientId={clientId}
        accountGroup={accountGroup}
        handleClose={handleEditAccountGroupDialogClose}
        isClientProfile={true}
      />
    </TableContainer>
  );
};

export default AccountGroupsTable;
