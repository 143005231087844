import React, { useEffect } from 'react';
import { ComponentBody } from '../../../../components/styled-components';
import { connect } from 'react-redux';
import AccountGroupsTable from '../../../../components/table/AccountGroupsTable';
import { fetchAccountGroups } from '../../../../actions/accounts';

const ClientAccountGroupsBase = ({
  singleClient,
  appUser,
  accountGroups,
  isFetchingAccountGroups,
  fetchAccountGroups,
  ...props
}) => {
  const clientId = props.match.params.id;
  useEffect(() => {
    fetchAccountGroups(clientId);
  }, [clientId, fetchAccountGroups]);

  return (
    <ComponentBody padding='14rem 2rem 4rem'>
      <AccountGroupsTable
        clientId={clientId}
        appUser={appUser}
        accountGroups={accountGroups}
        isFetching={isFetchingAccountGroups}
        {...props}
      />
    </ComponentBody>
  );
};

const mapStateToProps = (state) => ({
  accountGroups: state.accounts.accountGroups,
  isFetchingAccountGroups: state.accounts.isFetchingAccountGroups,
});

const ClientAccountGroups = connect(mapStateToProps, { fetchAccountGroups })(
  ClientAccountGroupsBase
);

export default ClientAccountGroups;
