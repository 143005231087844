import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import InvestmentTemplateForm from '../../forms/template-forms/InvestmentTemplateForm';
import WarningTemplateDialog from './WarningTemplateDialog';
import { deleteTemplate } from '../../../api/templates';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../Toast';

const InvestmentTemplateDialog = ({
  isInvestmentTemplateOpen,
  closeDialog,
  handleSubmitTemplate,
  templateId,
  template,
  appUser,
}) => {
  //Template fields
  const [accountNumber, setAccountNumber] = useState('');
  const [investmentId, setInvestmentId] = useState('');
  const [maturityDate, setMaturityDate] = useState('');
  const [settlementAmount, setSettlementAmount] = useState('');
  const [couponRate, setCouponRate] = useState('');
  const [settlementDate, setSettlementDate] = useState('');
  const [investmentType, setInvestmentType] = useState('');
  const [headerStart, setHeaderStart] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [fund, setFund] = useState('');
  const [callDate, setCallDate] = useState('');
  const [faceValue, setFaceValue] = useState('');

  //For template
  const [isTemplateWarningOpen, setIsTemplateWarningOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (template) {
      setAccountNumber(template.accountNumber || '');
      setInvestmentId(template.investmentId || '');
      setMaturityDate(template.maturityDate || '');
      setSettlementAmount(template.settlementAmount || '');
      setCouponRate(template.couponRate || '');
      setSettlementDate(template.settlementDate || '');
      setInvestmentType(template.investmentType || '');
      setHeaderStart(template.headerStart || '');
      setInterestRate(template.interestRate || '');
      setFund(template.fund || '');
      setCallDate(template.callDate || '');
      setFaceValue(template.faceValue || '');
    }
  }, [template]);

  //Functions for the delete confirmation modal
  const handleWarningTemplateOpen = () => {
    setIsTemplateWarningOpen(true);
  };

  const handleWarningTemplateClose = () => {
    setIsTemplateWarningOpen(false);
  };

  const handleDeleteTemplate = () => {
    setIsDeleting(true);
    deleteTemplate(templateId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete template.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        closeDialog();
        handleWarningTemplateClose();
      });
  };
  return (
    <>
      {isTemplateWarningOpen && (
        <WarningTemplateDialog
          isOpen={isTemplateWarningOpen}
          handleWarningTemplateClose={handleWarningTemplateClose}
          handleDeleteTemplate={handleDeleteTemplate}
          isDeleting={isDeleting}
        />
      )}
      <Dialog
        isOpen={isInvestmentTemplateOpen}
        onClose={closeDialog}
        title='Manage Investment Statement Import Template'
        isCloseButtonShown={false}
      >
        <InvestmentTemplateForm
          investmentId={investmentId}
          accountNumber={accountNumber}
          settlementDate={settlementDate}
          maturityDate={maturityDate}
          couponRate={couponRate}
          settlementAmount={settlementAmount}
          interestRate={interestRate}
          investmentType={investmentType}
          headerStart={headerStart}
          closeDialog={closeDialog}
          handleSubmitTemplate={handleSubmitTemplate}
          handleWarningTemplateOpen={handleWarningTemplateOpen}
          appUser={appUser}
          template={template}
          fund={fund}
          faceValue={faceValue}
          callDate={callDate}
        />
      </Dialog>
    </>
  );
};

export default InvestmentTemplateDialog;
