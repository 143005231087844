import { stringToDate, currencyRemoveFormat, formattedMonthsList } from './functions';
import moment from 'moment';

export const allowedDataGatheringFrequenciesList = formattedMonthsList([1, 2, 3, 4, 6, 12]);

export const statusList = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

export const closedList = [
  { label: 'No', value: 0 },
  { label: 'Yes', value: 1 },
];

export const collectionTypes = {
  SentIn: 'SENT IN',
  Multiple: 'MULTIPLE',
  DirectAccess: 'DIRECT ACCESS'
};

export const collectionTypeList = [
  { label: 'Direct Access', value: 0 },
  { label: 'Sent In', value: 1 }
];


//Return Array of years from recent years to 1900, and make key,
//value pair for itemRender and filterList
let year = new Date().getFullYear();
export const yearsList = Array.from(
  { length: year + 1 - 1900 },
  (v, k) => `${k + 1900}`
)
  .reverse()
  .map((value) => ({ value: value, label: value }));

export const stateList = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DC', label: 'DC' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];

//Adds this sort methods to the default sorting methods
export const sortTypes = {
  caseInsensitiveSort: (rowA, rowB, columnId) => {
    const valueA =
      rowA.values[columnId] == null
        ? rowA.values[columnId]
        : rowA.values[columnId].toLowerCase();
    const valueB =
      rowB.values[columnId] == null
        ? rowB.values[columnId]
        : rowB.values[columnId].toLowerCase();
    return valueA === valueB ? 0 : valueB > valueA ? -1 : 1;
  },
  customDateTimeSort: (rowA, rowB, columnId) => {
    let dateOne;
    let dateTwo;
    // if no date value exists in a column, need to still allow for filtering
    if (
      rowA.values[columnId] === null ||
      rowA.values[columnId] === undefined ||
      rowB.values[columnId] === null ||
      rowB.values[columnId] === undefined
    ) {
      dateOne = moment().format('MM/DD/YYYY');
      dateTwo = moment().format('MM/DD/YYYY');
    } else {
      // convert format to use / for all browser compatibility
      dateOne = rowA.values[columnId].replace(/-/g, '/');
      dateTwo = rowB.values[columnId].replace(/-/g, '/');
    }
    const valueA = new Date(dateOne).getTime();
    const valueB = new Date(dateTwo).getTime();
    return valueA === valueB ? 0 : valueB > valueA ? -1 : 1;
  },
  debitCreditSort: (rowA, rowB, columnId) => {
    const valueA = rowA.values[columnId];
    const valueB = rowB.values[columnId];
    return valueA === valueB ? 0 : valueB > valueA ? -1 : 1;
  },
};

//Adds this filter methods to the default filtering methods
export const filterTypes = {
  // Case insensitive text sort method
  // it overrides the default text filter method
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
  //Date range filter, Filter date columns by date ranges
  dateRange: (rows, ids, filterValue) => {
    const [min, max] = filterValue || [];
    min && min.setHours(0, 0, 0, 0);
    max && max.setHours(0, 0, 0, 0);
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = stringToDate(row.values[id]);
        const maxFlag = max ? rowValue <= max : true;
        const minFlag = min ? rowValue >= min : true;
        return minFlag && maxFlag;
      });
    });
  },
  //Number range filter, filter number column by ranges
  numberRange: (rows, ids, filterValue) => {
    let [min, max] = filterValue || [];
    min = Number(currencyRemoveFormat(min));
    max = Number(currencyRemoveFormat(max));
    min = min || 0;
    max = max || Number.MAX_VALUE;
    //Swap values if the flags are in reverse
    if (min > max) {
      const temp = min;
      min = max;
      max = temp;
    }
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id];
        return rowValue >= min && rowValue <= max;
      });
    });
  },
  //Filter a column with multiple values
  multipleValues: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id];
        return rowValue && rowValue.length && filterValue.includes(rowValue);
      });
    });
  },
  //Toggles debit credit filter
  debitCredit: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id];
        if (filterValue === 'Debit') {
          return rowValue > 0;
        } else if (filterValue === 'Credit') {
          return rowValue < 0;
        } else {
          return rowValue;
        }
      });
    });
  },
  eitherOr: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id];
        if (filterValue === 'Yes') {
          return rowValue === 1;
        } else {
          return rowValue === 0;
        }
      });
    });
  },
  yesOrNo: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id];
        return (
          rowValue === filterValue || rowValue === filterValue.toUpperCase()
        );
      });
    });
  },
  userNotes: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id].flat();
        const allIdsAsInts = rowValue
          .filter((id) => id !== null)
          .map((id) => parseInt(id));
        return allIdsAsInts.includes(filterValue);
      });
    });
  },
  tags: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        return filterValue.some((value) => {
          return row.values[id] !== null && row.values[id].includes(value);
        });
      });
    });
  },
  usersInvestments: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id];
        return filterValue.includes(rowValue);
      });
    });
  },
};
