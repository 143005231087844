import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import TransactionTemplateForm from '../../forms/template-forms/TransactionTemplateForm';
import WarningTemplateDialog from '../../dialog/template-dialog/WarningTemplateDialog';
import LoadSpinner from '../../../components/LoadSpinner';

const TransactionTemplateDialog = ({
  isOpen,
  closeDialog,
  handleSubmitTemplate,
  template,
  appUser,
  isSingleTemplateFetching,
  isAddFirstCellTemplate,
  handleDeleteTemplate,
  isTemplateWarningOpen,
  isDeleting,
  templateId,
  handleWarningTemplateOpen,
  handleWarningTemplateClose,
  showCancel,
  isHeaderRowExtraction,
  headerRowData,
}) => {
  const [accountNumber, setAccountNumber] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [amountCredit, setAmountCredit] = useState('');
  const [amountDebit, setAmountDebit] = useState('');
  const [creditDebit, setCreditDebit] = useState('');
  const [amount, setAmount] = useState('');
  const [headerStart, setHeaderStart] = useState('');
  const [accountNumberHeader, setAccountNumberHeader] = useState('');
  const [fileNameIncluded, setFileNameIncluded] = useState(false);
  const [specialCase, setSpecialCase] = useState(false);
  const [cellHeaderData, setCellHeaderData] = useState('');
  const [footerRows, setFooterRows] = useState('');

  const { jsonTransform } = template;

  useEffect(() => {
    if (Object.keys(template).length > 0) {
      setAccountNumber(jsonTransform ? jsonTransform[0].accountNumber : '');
      setDescription(jsonTransform ? jsonTransform[0].description : '');
      setDate(jsonTransform ? jsonTransform[0].date : '');
      setAmountCredit(jsonTransform ? jsonTransform[0].amountCredit : '');
      setAmountDebit(jsonTransform ? jsonTransform[0].amountDebit : '');
      setCreditDebit(jsonTransform ? jsonTransform[0].creditDebit : '');
      setAmount(jsonTransform ? jsonTransform[0].amount : '');
      setHeaderStart(jsonTransform ? jsonTransform[0].headerStart : '');
      setAccountNumberHeader(
        jsonTransform ? jsonTransform[0].accountNumberHeader : ''
      );
      setFileNameIncluded(
        jsonTransform && jsonTransform[0].fileNameIncluded ? true : false
      );
      setSpecialCase(template.specialCase ? true : false);
      setCellHeaderData(template.templateType ? template.cellHeaderData : '');
      setFooterRows(jsonTransform ? jsonTransform[0].footerRows : '');
    }
    if (Object.keys(template).length === 0) {
      setAccountNumber('');
      setDescription('');
      setDate('');
      setAmountCredit('');
      setAmountDebit('');
      setCreditDebit('');
      setAmount('');
      setHeaderStart('');
      setAccountNumberHeader('');
      setFileNameIncluded(false);
      setSpecialCase(false);
      setCellHeaderData(headerRowData ? headerRowData : '');
      setFooterRows('');
    }
  }, [
    template,
    headerRowData,
    isHeaderRowExtraction,
    jsonTransform,
  ]);

  return (
    <>
      {isTemplateWarningOpen && (
        <WarningTemplateDialog
          isOpen={isTemplateWarningOpen}
          handleWarningTemplateClose={handleWarningTemplateClose}
          handleDeleteTemplate={handleDeleteTemplate}
          isDeleting={isDeleting}
          showCancel={showCancel}
        />
      )}

      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title='Manage Transactions Import Template'
        isCloseButtonShown={false}
      >
        {isSingleTemplateFetching ? (
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <LoadSpinner size={100} />
          </div>
        ) : (
          <TransactionTemplateForm
            closeDialog={closeDialog}
            handleSubmitTemplate={handleSubmitTemplate}
            handleWarningTemplateOpen={handleWarningTemplateOpen}
            accountNumber={accountNumber}
            description={description}
            date={date}
            amountCredit={amountCredit}
            amountDebit={amountDebit}
            creditDebit={creditDebit}
            amount={amount}
            headerStart={headerStart}
            accountNumberHeader={accountNumberHeader}
            appUser={appUser}
            template={template}
            fileNameIncluded={fileNameIncluded}
            specialCase={specialCase}
            cellHeaderData={cellHeaderData}
            isAddFirstCellTemplate={isAddFirstCellTemplate}
            templateId={templateId}
            isHeaderRowExtraction={isHeaderRowExtraction}
            footerRows={footerRows}
          />
        )}
      </Dialog>
    </>
  );
};

export default TransactionTemplateDialog;
