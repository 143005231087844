import React from 'react';
import { LogoBox, RegularButton } from '../components/styled-components';
import styled from 'styled-components';
import logo from '../assets/three-plus-one-logo-black-text.png';
import colors from '../utils/colors';

const NotFound = (props) => {
  return (
    <NotFoundContainer>
      <NotFoundWrapper>
        <LogoContainer>
          <LogoBox
            margin='20px 0 0 0'
            cursor='pointer'
            alt='company logo'
            src={logo}
            onClick={() => props.history.push('/dashboard')}
          />
        </LogoContainer>
        <ContentContainer>
          <MessageContainer>
            <MessageWrapper>
              <p>
                <b>Page not found.</b> The link you clicked may be broken or the
                page may have been removed.
              </p>
              <RegularButton
                margin='30px 0'
                width='271px'
                onClick={() => props.history.push('/dashboard')}
              >
                Back to Home
              </RegularButton>
            </MessageWrapper>
          </MessageContainer>
          <NumberContainer>
            <NumberWrapper>404</NumberWrapper>
          </NumberContainer>
        </ContentContainer>
      </NotFoundWrapper>
    </NotFoundContainer>
  );
};

const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.containerBackground};
  margin-left: -15rem;
  position: absolute;
  z-index: 100;
`;

const NotFoundWrapper = styled.div`
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 15rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 35rem;
  display: flex;
  position: relative;
`;

const MessageContainer = styled.div`
  width: 67%;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
`;

const MessageWrapper = styled.div`
  width: 100%;

  p {
    font-size: 2.5rem;
    color: ${colors.normalText};
    line-height: 50px;

    b {
      color: ${colors.boldText};
      font-weight: 700;
    }
  }
`;

const NumberContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 47rem;
`;

const NumberWrapper = styled.div`
  width: 100%;
  font-size: 25rem;
  font-weight: 700;
  color: ${colors.grey};
`;

export default NotFound;
