import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NotesTable from '../../../../../../components/table/NotesTable';
import { fetchAccountNotes } from '../../../../../../actions/notes';

const AccountNotesBase = ({
  appUser,
  accountNotes,
  fetchAccountNotes,
  isFetchingAccountNotes,
  accountId,
  ...props
}) => {
  useEffect(() => {
    fetchAccountNotes(accountId);
  }, [fetchAccountNotes, accountId]);

  return (
    <NotesTable
      appUser={appUser}
      notes={accountNotes}
      isFetching={isFetchingAccountNotes}
      accountId={accountId}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  accountNotes: state.notes.accountNotes,
  isFetchingAccountNotes: state.notes.isFetchingAccountNotes,
});

const AccountNotes = connect(mapStateToProps, { fetchAccountNotes })(
  AccountNotesBase
);

export default AccountNotes;
