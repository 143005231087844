import React from 'react';
import { Dialog } from '@blueprintjs/core';
import colors from '../../utils/colors';
import {
  AlignCenter,
  MessageText,
  RegularButton,
  RedText,
} from '../../components/styled-components';

// warning dialog for same name bank and client
const DownloadDialog = ({
  handleDownloadDialogClose,
  isOpen,
  downloadAmount,
  downloadError,
  downloadErrorAmount,
}) => {
  return (
    <>
      <Dialog
        className='small-dialog'
        isOpen={isOpen}
        title='Download Summary'
        onClose={handleDownloadDialogClose}
        isCloseButtonShown={false}
      >
        <div
          style={{
            padding: '30px 50px 20px',
          }}
        >
          <MessageText color={colors.green}>
            {downloadAmount} files downloaded successfully!
          </MessageText>
          {downloadError && (
            <RedText>{downloadErrorAmount} files failed to download.</RedText>
          )}
        </div>
        <AlignCenter
          padding='10px 0 10px 0'
          borderTop={`1px solid ${colors.lightText}`}
          margin='50px 0 0 0'
        >
          <RegularButton
            width='13rem'
            padding='10px 30px'
            onClick={handleDownloadDialogClose}
          >
            OK
          </RegularButton>
        </AlignCenter>
      </Dialog>
    </>
  );
};

export default DownloadDialog;
