import React, { useEffect, useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import GenerateReportForm from '../../forms/client-profile-forms/GenerateReportForm';
import { generateReportPdf } from '../../../api/clients';
import AppToast from '../../Toast';

const GenerateReportDialog = ({
  isOpen,
  clientReportStatus,
  singleClient,
  clientAccounts,
  setIsGenerateReportDialogOpen,
  clientAccountGroups,
  setIsReportGenerated,
  ...props
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [liquidityLevelAmounts, setLiquidityLevelAmounts] = useState('0');
  const [stmNetGraph, setStmNetGraph] = useState('0');
  const [stmPrevQuarterResults, setStmPrevQuarterResults] = useState('0');
  const [stmMinTable, setStmMinTable] = useState('0');
  const [zbaAccountStructure, setZbaAccountStructure] = useState('0');
  const [liquidTargetAccount, setLiquidTargetAccount] = useState(false);
  const [highYieldTargetAccountId, setHighYieldTargetAccountId] = useState(null);
  const [electronicTransacTemplate, setElectronicTransacTemplate] = useState('0');
  const [highYieldLiquidBenchmarkOverride, setHighYieldLiquidBenchmarkOverride] = useState(null);
  const [allAccountsGraph, setAllAccountsGraph] = useState([]);
  const [stressTestResult, setStressTestResult] = useState([]);
  const [stackedAreaAndStats, setStackedAreaAndStats] = useState([]);
  const [includeAllGraphs, setIncludeAllGraphs] = useState([]);
  const [minTransacForClosureGroupId, setMinTransacForClosureGroupId] = useState(null);

  useEffect(() => {
    if (clientReportStatus) {
      setStartDate(clientReportStatus.reportStartDate || null);
      setEndDate(clientReportStatus.reportEndDate || null);
    }
  }, [clientReportStatus]);

  const handleLiquidityLevelAmountChange = (event) => {
    setLiquidityLevelAmounts(event.target.value);
  };

  const handleNetGraphChange = (event) => {
    setStmNetGraph(event.target.value);
  };

  const handlePrevQuarterResultsChange = (event) => {
    setStmPrevQuarterResults(event.target.value);
  };

  const handleMinTableChange = (event) => {
    setStmMinTable(event.target.value);
  };

  const handleZbaAccountChange = (event) => {
    setZbaAccountStructure(event.target.value);
  };

  const handleElectronicTransactionChange = (event) => {
    setElectronicTransacTemplate(event.target.value);
  };

  const handleGenerateReportDialogClose = () => {
    setIsGenerateReportDialogOpen(false);
    setLiquidityLevelAmounts('0');
    setStmNetGraph('0');
    setStmPrevQuarterResults('0');
    setStmMinTable('0');
    setZbaAccountStructure('0');
    setLiquidTargetAccount(false);
    setHighYieldTargetAccountId(null);
    setElectronicTransacTemplate('0');
    setHighYieldLiquidBenchmarkOverride(null);
    setAllAccountsGraph([]);
    setStressTestResult([]);
    setStackedAreaAndStats([]);
    setIncludeAllGraphs([]);
    setMinTransacForClosureGroupId(null);
  };

  const handleGeneratePdf = (data) => {
    if (data.highYieldTargetAccountId) {
      data.highYieldTargetAccountId = clientAccounts.find((item) => item.label === data.highYieldTargetAccountId).value;
    }
    if (data.minTransacForClosureGroupId) {
      data.minTransacForClosureGroupId = clientAccountGroups.find((item) => item.label === data.minTransacForClosureGroupId).value;
    }

    const clientId = singleClient.clientId;
    const reports = { clientId, ...data };
    generateReportPdf(reports)
      .then((res) => {
        setIsReportGenerated(true);
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch(() => {
        AppToast.show({
          message: 'Failed to generate report pdf.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleGenerateReportDialogClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleGenerateReportDialogClose}
      title={'Generate Cashvest Report- Set Parameters'}
      isCloseButtonShown={false}
    >
      <GenerateReportForm
        startDate={startDate}
        endDate={endDate}
        liquidityLevelAmounts={liquidityLevelAmounts}
        stmNetGraph={stmNetGraph}
        stmPrevQuarterResults={stmPrevQuarterResults}
        stmMinTable={stmMinTable}
        zbaAccountStructure={zbaAccountStructure}
        liquidTargetAccount={liquidTargetAccount}
        highYieldTargetAccountId={highYieldTargetAccountId}
        electronicTransacTemplate={electronicTransacTemplate}
        highYieldLiquidBenchmarkOverride={highYieldLiquidBenchmarkOverride}
        allAccountsGraph={allAccountsGraph}
        stressTestResult={stressTestResult}
        stackedAreaAndStats={stackedAreaAndStats}
        includeAllGraphs={includeAllGraphs}
        minTransacForClosureGroupId={minTransacForClosureGroupId}
        singleClient={singleClient}
        clientAccounts={clientAccounts}
        clientAccountGroups={clientAccountGroups}
        handleLiquidityLevelAmountChange={handleLiquidityLevelAmountChange}
        handleNetGraphChange={handleNetGraphChange}
        handlePrevQuarterResultsChange={handlePrevQuarterResultsChange}
        handleMinTableChange={handleMinTableChange}
        handleZbaAccountChange={handleZbaAccountChange}
        handleElectronicTransactionChange={handleElectronicTransactionChange}
        handleGenerateReportDialogClose={handleGenerateReportDialogClose}
        handleGeneratePdf={handleGeneratePdf}
      />
    </Dialog>
  );
};

export default GenerateReportDialog
