import React from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  Label,
  WidgetHeader,
  WidgetBody,
  BoldText,
  StarIcon,
  MessageText,
  CardDiv,
} from '../../styled-components';
import colors from '../../../utils/colors';

const ScoreBreakdownWidget = ({
  cashVestSubScores,
  widgetTitle,
  scoreName,
  starsName,
  commentary,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ flexBasis: '335px', flexGrow: '2' }}
    >
      <WidgetHeader>
        <Label display={'flex'} alignItems={'center'}>
          {widgetTitle}
        </Label>
      </WidgetHeader>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '10px 20px' }}>
          <div style={{ display: 'flex', paddingTop: '10px' }}>
            <BoldText fontSize='1.25rem'>
              {cashVestSubScores &&
                cashVestSubScores.length &&
                cashVestSubScores[0][scoreName]}
            </BoldText>
            <StarIcon
              starPercentage={
                cashVestSubScores &&
                cashVestSubScores.length &&
                `${cashVestSubScores[0][starsName]}%`
              }
              style={{ paddingLeft: '10px' }}
            />
          </div>
          <MessageText margin='5px 0'>Subcategory Score</MessageText>
        </div>
      </div>

      <WidgetBody
        backgroundColor='white'
        borderTop={` 1px solid ${colors.lightText}`}
        padding={'30px 0 0 20px'}
      >
        <>
          <BoldText color={colors.greyPlaceholderText}>Commentary</BoldText>
          <MessageText>
            {cashVestSubScores &&
            cashVestSubScores.length &&
            cashVestSubScores[0][commentary]
              ? `- ${
                  cashVestSubScores &&
                  cashVestSubScores.length &&
                  cashVestSubScores[0][commentary]
                }`
              : ''}
          </MessageText>
        </>
      </WidgetBody>
    </CardDiv>
  );
};

export default ScoreBreakdownWidget;
