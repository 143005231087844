import React from 'react';
import { Dialog } from '@blueprintjs/core';
import BankForm from '../forms/BankForm';

const BanksDialogBox = ({
  isOpen,
  title,
  closeDialog,
  bankName,
  assetSize,
  status,
  defaultDataGatheringFrequency,
  dataAvailabilityDate,
  allowedDataGatheringFrequencies,
  allowedDataAvailabilityDates,
  handleCreateBank,
  handleEditBank,
  isTryingToSubmit,
  appUser,
  bankId,
}) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title}
        isCloseButtonShown={false}
      >
        <BankForm
          title={title}
          bankName={bankName}
          assetSize={assetSize}
          status={status}
          defaultDataGatheringFrequency = {defaultDataGatheringFrequency}
          dataAvailabilityDate = {dataAvailabilityDate}
          allowedDataGatheringFrequencies = {allowedDataGatheringFrequencies}
          allowedDataAvailabilityDates = {allowedDataAvailabilityDates}
          isTryingToSubmit={isTryingToSubmit}
          handleCreateBank={handleCreateBank}
          handleEditBank={handleEditBank}
          closeDialog={closeDialog}
          appUser={appUser}
          bankId={bankId}
        />
      </Dialog>
    </>
  );
};

export default BanksDialogBox;
