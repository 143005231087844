import React from 'react';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
  FlexColumn,
} from '../../styled-components';
import { Formik, Form } from 'formik';
import MyAccountTextField from '../../formik-text-fields/MyAccountTextField';
import LoadSpinner from '../../LoadSpinner';
import * as Yup from 'yup';

const userSchema = Yup.object().shape({
  firstName: Yup.string().required('Required!'),
  lastName: Yup.string().required('Required!'),
  email: Yup.string().email('Must be valid email.').required('Required!'),
});

const UserDetailsForm = ({
  firstName,
  lastName,
  email,
  handleChangeUserDetails,
  handleResetForm,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column'>
        <Formik
          initialValues={{
            firstName,
            lastName,
            email,
          }}
          validationSchema={userSchema}
          onSubmit={(values, actions) => {
            handleChangeUserDetails(values, actions);
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, resetForm }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter justifyContent='space-between' padding='25px 0'>
                <FlexColumn style={{ width: '45%' }}>
                  <MyAccountTextField
                    type='text'
                    name='firstName'
                    label='First Name'
                    value={values.firstName || ''}
                    autoComplete='off'
                  />
                </FlexColumn>
                <FlexColumn style={{ width: '45%' }}>
                  <MyAccountTextField
                    type='text'
                    name='lastName'
                    label='Last Name'
                    value={values.lastName || ''}
                    autoComplete='off'
                  />
                </FlexColumn>
              </AlignCenter>

              <AlignCenter justifyContent='flex-start'>
                <FlexColumn style={{ width: '100%' }}>
                  <MyAccountTextField
                    type='text'
                    name='email'
                    label='Email'
                    value={values.email || ''}
                    autoComplete='off'
                  />
                </FlexColumn>
              </AlignCenter>

              <AlignCenter justifyContent='flex-end'>
                <WhiteButton
                  type='button'
                  margin='30px 20px 0 0'
                  onClick={() => handleResetForm(resetForm)}
                >
                  Cancel
                </WhiteButton>
                <RegularButton
                  type='submit'
                  display='flex'
                  width='13rem'
                  margin='30px 0 0 0'
                  disabled={isSubmitting}
                >
                  Save Details
                  {isSubmitting && (
                    <span style={{ marginLeft: '10px' }}>
                      <LoadSpinner size={20} />
                    </span>
                  )}
                </RegularButton>
              </AlignCenter>
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default UserDetailsForm;
