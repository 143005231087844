import React, { useState, useEffect } from 'react';
import {
  MessageText,
  BorderComponentContainer,
  FlexColumn,
  RegularButton,
  GlobalSettingsDescription,
  WhiteButton,
  AlignCenter,
  FooterContainer,
  ResponsiveCenter,
} from '../styled-components';
import TextField from '../text-fields/TextField';
import LoadSpinner from '../../components/LoadSpinner';
import { updateMaturityLimit } from '../../api/administration';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';

const MaturingDateRange = ({
  maturityLimit,
  isFetching,
  fetchMaturityLimit,
}) => {
  const [daysNumber, setDaysNumber] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (maturityLimit) {
      setDaysNumber(maturityLimit.globalSettingValue);
    }
  }, [maturityLimit]);

  const resetValue = () => {
    setDaysNumber(maturityLimit.globalSettingValue);
  };

  const handleUpdate = () => {
    setIsUpdating(true);
    const globalSetting = { value: daysNumber };
    updateMaturityLimit(maturityLimit.globalSettingId, globalSetting)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchMaturityLimit();
      })
      .catch((err) => {
        console.error(err);
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to update Maturity Investment Date Range.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <ResponsiveCenter className={'global-setting'} minWidth={'654px'}>
      <GlobalSettingsDescription>
        <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
          Maturing Investment Date Range
        </MessageText>
        <MessageText>
          Set the number of days for Maturing Investments in the user dashboard.
        </MessageText>
      </GlobalSettingsDescription>

      <BorderComponentContainer
        padding={'0px'}
        width={'100%'}
        minWidth={'654px'}
      >
        <FlexColumn padding={'2rem'}>
          {isFetching ? (
            <LoadSpinner />
          ) : (
            <TextField
              type='number'
              name='days'
              placeholder='Number of Days'
              label='Number of Days'
              value={daysNumber || 0}
              onChange={(event) => {
                setDaysNumber(event.target.value);
              }}
              autoComplete='off'
              min={0}
            />
          )}
        </FlexColumn>

        <FooterContainer>
          <AlignCenter justifyContent='flex-end'>
            <WhiteButton type='button' onClick={resetValue}>
              CANCEL
            </WhiteButton>
            <RegularButton
              width='13rem'
              display='flex'
              onClick={handleUpdate}
              disabled={isFetching || isUpdating}
            >
              SAVE SETTINGS
            </RegularButton>
          </AlignCenter>
        </FooterContainer>
      </BorderComponentContainer>
    </ResponsiveCenter>
  );
};

export default MaturingDateRange;
