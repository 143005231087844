import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import {
  deleteStagingBalance,
  deleteStagingFee,
} from '../../../api/treasuryServices';
import AppToast from '../../Toast';
import { MessageText, RedText } from '../../styled-components';
import DialogDeleteActions from '../DialogDeleteActions';

const TreasuryServicesDeleteDialog = ({
  isOpen,
  type,
  title,
  batchId,
  handleClose,
  balance,
  fee,
  fetchStagingFees,
  fetchStagingBalances,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    if (type === 'Balance') {
      const stagingBalanceIds = balance.amounts.map((item) => item[0]);
      balance = { stagingBalanceIds };
      deleteStagingBalance(balance)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to delete balance',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          setIsDeleting(false);
          fetchStagingBalances(batchId);
          handleClose();
        });
    } else if (type === 'Fees') {
      const stagingFeesIds = fee.volumes.map((item) => item[0]).filter(item => { return item != null; });
      const fees = { stagingFeesIds };
      deleteStagingFee(fees)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to delete fee',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          setIsDeleting(false);
          fetchStagingFees(batchId);
          handleClose();
        });
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>Are you sure you want to delete this {type}?</MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Dialog>
  );
};

export default TreasuryServicesDeleteDialog;
