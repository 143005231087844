import { combineReducers } from 'redux';

// logout case is to null the quandl values when user logs out
const quandlData = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_QUANDL_DATA_FULFILLED':
      return action.payload.data;
    case 'FETCH_QUANDL_DATA_REJECTED':
    case 'FETCH_QUANDL_DATA_PENDING':
    case 'LOGOUT':
      return null;
    default:
      return state;
  }
};

const quandl = combineReducers({
  quandlData,
});

export default quandl;
