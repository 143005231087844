import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  Icon,
  Popover,
  Position,
  Menu,
  MenuItem,
  Button,
} from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import { LogoBox, AlignCenter } from '../styled-components';
import logo from '../../assets/three-plus-one-logo-white-text.png';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';
import { Auth } from 'aws-amplify';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';

const NavbarLink = (props) => (
  <LinkWrapper to={props.link}>
    <NavIcon icon={props.icon} />
    <LinkText>{props.text}</LinkText>
  </LinkWrapper>
);

const Sidebar = ({ toggle, open, isShowing, ...props }) => {
  let history = useHistory();
  const logoutUser = async () => {
    try {
      await Auth.signOut();
      props.logoutAppUser();
      history.push('/');
    } catch (err) {
      AppToast.show({ message: 'Logout Fail!', intent: Intent.DANGER });
    }
  };

  const { appUser } = props;
  return (
    <>
      <Click className={open} onClick={toggle}>
        {isShowing && (
          <Icon
            icon={iconValue.chevronLeft}
            iconSize={20}
            color={colors.boldText}
          />
        )}
        {!isShowing && (
          <Icon
            icon={iconValue.chevronRight}
            iconSize={20}
            color={colors.boldText}
          />
        )}
      </Click>

      <SidebarContainer className={open}>
        <LogoBox
          cursor='pointer'
          margin='3rem auto 60px'
          alt='company logo'
          src={logo}
          onClick={() => history.push('/dashboard')}
        />
        <AlignCenter flexDirection='column' justifyContent='space-between'>
          <div style={{ width: '100%' }}>
            <NavbarLink link='/dashboard' text='Home' icon={iconValue.home} />
            {appUser &&
              checkPermissions(appUser.permList, [permissions.VIEW_USERS]) && (
                <NavbarLink
                  link='/user-management'
                  text='Users'
                  icon={iconValue.newPerson}
                />
              )}
            {appUser &&
              checkPermissions(appUser.permList, [
                permissions.VIEW_CLIENTS,
              ]) && (
                <NavbarLink
                  link='/client-management'
                  text='Clients'
                  icon={iconValue.clients}
                />
              )}
            {appUser &&
              checkPermissions(appUser.permList, [permissions.VIEW_BANKS]) && (
                <NavbarLink
                  link='/bank-management'
                  text='Banks'
                  icon={iconValue.banks}
                />
              )}
            {appUser &&
              checkPermissions(appUser.permList, [
                permissions.VIEW_OPEN_REPORTS,
              ]) && (
                <NavbarLink
                  link='/data-ingestion'
                  text='Data Ingestion'
                  icon={iconValue.download}
                />
              )}
            {appUser &&
              checkPermissions(appUser.permList, [
                permissions.VIEW_INVESTMENTS,
              ]) && (
                <NavbarLink
                  link='/investment-management'
                  text='Investments'
                  icon={iconValue.investments}
                />
              )}
            {appUser &&
              checkPermissions(appUser.permList, [
                permissions.VIEW_ANALYSIS,
              ]) && (
                <NavbarLink
                  link='/analysis'
                  text='Analysis'
                  icon={iconValue.pieChart}
                />
              )}
            {appUser &&
              checkPermissions(appUser.permList, [
                permissions.VIEW_GLOBAL_SETTINGS,
              ]) && (
                <NavbarLink
                  link='/global-settings'
                  text='Global Settings'
                  icon={iconValue.settings}
                />
              )}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0 10px 3rem',
            }}
          >
            <Popover
              className='sidebar-popover'
              interactionKind='hover'
              position={Position.RIGHT}
              content={
                <Menu>
                  <MenuItem
                    text='My Account'
                    onClick={() => history.push('/my-account')}
                  />
                  <MenuItem text='Logout' onClick={logoutUser} />
                </Menu>
              }
            >
              <NameButton>
                {appUser ? `${appUser.firstName} ${appUser.lastName}` : null}
                <div style={{ marginLeft: '20px', width: '16px' }}>
                  <Icon
                    icon={iconValue.settings}
                    iconSize={16}
                    color={colors.boldText}
                  />
                </div>
              </NameButton>
            </Popover>
          </div>
        </AlignCenter>
      </SidebarContainer>
    </>
  );
};

const Click = styled.button`
  &.open {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 15;
    border: none;
    border-radius: 15px 0 0 15px;
    background: ${colors.grey};
    width: 30px;
    height: 30px;
    cursor: pointer;
    outline: none;
    left: 210px;
    top: 8rem;
    transition: 0.3s;

    & svg:hover {
      fill: ${colors.purpleText};
      transition: 0.3s;
    }
  }

  &.close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 15;
    border: none;
    border-radius: 0 15px 15px 0;
    background: ${colors.lightText};
    width: 30px;
    height: 30px;
    cursor: pointer;
    outline: none;
    left: 0;
    top: 8rem;
    transition: 0.3s;

    & svg:hover {
      fill: ${colors.containerBackground};
      transition: 0.3s;
    }
  }
`;

const SidebarContainer = styled.div`
  &.open {
    height: 100vh;
    width: 15rem;
    background: ${colors.lightText};
    z-index: 10;
    display: flex;
    flex-direction: column;
    position: fixed;
    transition: 0.3s;
    left: 0;
    top: 0;
  }

  &.close {
    height: 100vh;
    width: 15rem;
    left: -15rem;
    top: 0;
    transition: 0.3s;
    position: fixed;
  }
`;

const LinkWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 15px 30px;
  color: ${colors.boldText};
  outline: none;

  &:hover {
    background: white;
    text-decoration: none;
    color: ${colors.boldText};
    transition: 0.3s;
  }

  &.active {
    background: ${colors.grey};
    border-left: 8px solid ${colors.purpleText};
  }
`;

const NavIcon = styled(Icon)`
  margin-right: 20px;
`;

const LinkText = styled.p`
  font-size: 1.25rem;
`;

// need !important to override blueprint styles
const NameButton = styled(Button)`
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  min-height: 0;
  width: 100%;
  background-color: ${colors.lightText} !important;
  background-image: none !important;
  box-shadow: none !important;
  color: ${colors.boldText} !important;
  font-size: 1.125rem;

  span {
    display: flex;
    align-items: center;
    word-break: break-word;
  }
`;

export default Sidebar;
