import React from 'react';
import {
  AlignCenter,
  Label,
  ErrorMessageDiv,
  FlexColumn,
  InputWrapper,
  Input,
} from '../styled-components';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon, Tooltip } from '@blueprintjs/core';
import { useField } from 'formik';

const ModalTextField = ({
  toolTipText,
  width,
  isDisabled,
  isCurrency,
  isPercentage,
  isBalanceAdjustment,
  handleValueChange,
  setValue,
  isStepTwoShortTermModel,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FlexColumn>
        <Label
          color={
            isDisabled && isStepTwoShortTermModel
              ? colors.lightText
              : colors.boldText
          }
        >
          {props.label}
          {toolTipText && (
            <div
              style={{
                display: 'inline-block',
                padding: '0px 0px 5px 5px',
                verticalAlign: 'middle',
              }}
            >
              <Tooltip content={toolTipText}>
                <Icon
                  icon={iconValue.info}
                  iconSize={16}
                  color={colors.primary}
                />
              </Tooltip>
            </div>
          )}
        </Label>

        <InputWrapper
          background={isBalanceAdjustment ? 'white' : 'transparent'}
          width={width}
          style={
            isDisabled && isStepTwoShortTermModel
              ? { background: 'white', border: `1px solid ${colors.lightText}` }
              : isDisabled
              ? { background: colors.greyDisabled }
              : { background: 'white' }
          }
          className={
            isCurrency
              ? 'currency-input'
              : isPercentage
              ? 'percentage-input'
              : ''
          }
        >
          {isDisabled && isStepTwoShortTermModel ? (
            <Input
              style={{
                color: colors.lightText,
                background: 'white',
                cursor: 'not-allowed',
              }}
              {...field}
              {...props}
              disabled
            />
          ) : isDisabled ? (
            <Input {...field} {...props} disabled />
          ) : (
            <Input
              {...field}
              {...props}
              maxLength={
                props.maxLength
                  ? props.maxLength
                  : props.type === 'text'
                  ? 120
                  : 15
              }
            />
          )}
        </InputWrapper>

        <div style={{ width: width }}>
          {meta.touched && meta.error ? (
            <AlignCenter justifyContent='space-between'>
              <ErrorMessageDiv fontSize='1rem'>{meta.error}</ErrorMessageDiv>
              <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
            </AlignCenter>
          ) : null}
        </div>
      </FlexColumn>
    </>
  );
};

export default ModalTextField;
