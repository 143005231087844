import React from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  WidgetHeader,
  Label,
  CardDiv,
  MessageText,
  BoldText,
} from '../../styled-components';
import LoadSpinner from '../../LoadSpinner';
import colors from '../../../utils/colors';
import NonIdealState from '../../Non-Ideal-State';
import iconValue from '../../../utils/icons';

const InterestRateWidget = ({ isFetching, interestRate }) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget-summary'}
    >
      <WidgetHeader>
        <Label>Interest Rate</Label>
      </WidgetHeader>
      {isFetching ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      ) : !interestRate.length ? (
        <NonIdealState
          icon={iconValue.warning}
          title={'No data.'}
          description={'There are no results to be displayed.'}
        />
      ) : (
        <div
          style={{
            padding: '20px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              borderBottom: `1px solid ${colors.lightText}`,
            }}
          >
            <MessageText fontSize='1rem' margin='0 0 10px 0'>
              Last Month (Most Recent)
            </MessageText>
            <div style={{ margin: '0 0 40px 0' }}>
              <BoldText color={colors.boldText} fontSize='1.5rem'>
                {interestRate[0]}%
              </BoldText>
              <MessageText>Interest Rate</MessageText>
            </div>
          </div>
          <div>
            <MessageText fontSize='1rem' margin='20px 0 10px 0'>
              Last Quarter
            </MessageText>
            <div style={{ margin: '0 0 40px 0' }}>
              <BoldText color={colors.boldText} fontSize='1.5rem'>
                {interestRate[1]}%
              </BoldText>
              <MessageText>Interest Rate</MessageText>
            </div>
          </div>
        </div>
      )}
    </CardDiv>
  );
};

export default InterestRateWidget;
