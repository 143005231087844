import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import {
  AlignCenter,
  WhiteButton,
  RegularButton,
} from '../../styled-components';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import { stringToDate, dateToString, defaultAccountDataGatheringFrequency, formattedMonthsList } from '../../../utils/functions';
import { allowedDataGatheringFrequenciesList } from '../../../utils/constantsList';
import colors from '../../../utils/colors';
import { collectionTypeList } from '../../../utils/constantsList';
import * as Yup from 'yup';
import DateSelectField from '../../select-fields/DateSelectField';
import LoadSpinner from '../../LoadSpinner';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import { find } from 'lodash';

//Yup Validation schema for Accounts
//Account closed date is only required if the account is closed (value =1)
const accountSchema = Yup.object().shape({
  accountName: Yup.string().required('Required'),
  accountType: Yup.string().required('Required'),
  accountBalanceDate: Yup.date().required('Required').nullable(),
  accountNumber: Yup.string().required('Required'),
  accountClosed: Yup.string().required('Required'),
  accountAlias: Yup.string().required('Required'),
  accountBalance: Yup.string()
    .required('Required')
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  accountDateClosed: Yup.date()
    .nullable()
    .test('isAccountClosed', 'Required', function (value) {
      const { accountClosed } = this.parent;
      if (accountClosed === 'Yes' && !value) return false;
      else return true;
    }),
  accountCategory: Yup.string().required('Required'),
  bankId: Yup.number().required('Required'),
  dataGatheringFrequency: Yup.number().nullable()
});

//The add-account endpoint requires different variable names than edit account
const onAddTransform = (values) => {
  const addAccountName = values.accountName;
  delete values.accountName;
  values = { ...values, addAccountName };

  const addAccountNumber = values.accountNumber;
  delete values.accountNumber;
  values = { ...values, addAccountNumber };

  const addAccountAlias = values.accountAlias;
  delete values.accountAlias;
  values = { ...values, addAccountAlias };

  const addAccountBalance = values.accountBalance;
  delete values.accountBalance;
  values = { ...values, addAccountBalance };

  return values;
};

//Transform values before sending them to the api
const transformValues = (values, isAdd, inheritedCollectionType) => {
  let copiedValues = Object.assign({}, values);

  copiedValues.inheritedCollectionType = inheritedCollectionType;
  //Transform date objects to Strings
  if (copiedValues.accountBalanceDate)
    copiedValues.accountBalanceDate = dateToString(
      copiedValues.accountBalanceDate
    );

  if (copiedValues.accountDateClosed)
    copiedValues.accountDateClosed = dateToString(
      copiedValues.accountDateClosed
    );

  if (isAdd) copiedValues = onAddTransform(copiedValues);
  copiedValues.accountClosed = copiedValues.accountClosed === 'Yes' ? 1 : 0;
  return copiedValues;
};

const closedList = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const AccountForm = ({
  title,
  accountName,
  accountType,
  accountNumber,
  types,
  closeDialog,
  accountClosed,
  accountAlias,
  accountBalance,
  accountCategory,
  categories,
  collectionType,
  inheritedCollectionType,
  hasClientBankRelation,
  bankId,
  bankNames,
  bankDefaultDataGatheringFrequencies,
  dataGatheringFrequency,
  clientDataGatheringFrequency,
  accountBalanceDate,
  accountDateClosed,
  clientBanks,
  handleCreateAccount,
  handleEditAccount,
}) => {
  const [addMore, setAddMore] = useState(false);
  const isAdd = title === 'Add New Account';

  const allowedFrequencies = allowedDataGatheringFrequenciesList.filter(n => n);

  const getDefaultAccountDataGatheringFrequency = (values) => {
    const bankDefaultFrequency = bankDefaultDataGatheringFrequencies[values.bankId];
    return defaultAccountDataGatheringFrequency(bankDefaultFrequency, clientDataGatheringFrequency)
  };

  const formatDataFrequency = (frequency) => {
    if (frequency) {
      var formattedList = formattedMonthsList([frequency])
      return find(formattedList, ['value', frequency]).label;
    }
    else {
      return 'Not set'
    };
  };

  return (
    <>
      <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
        <Formik
          initialValues={{
            accountName,
            accountType,
            accountBalanceDate: stringToDate(accountBalanceDate),
            accountNumber,
            accountClosed,
            accountAlias,
            accountBalance,
            accountDateClosed: stringToDate(accountDateClosed),
            accountCategory,
            bankId,
            collectionType,
            inheritedCollectionType: isAdd ? true : inheritedCollectionType,
            hasClientBankRelation,
            dataGatheringFrequency
          }}
          onSubmit={(values, props) => {
            if (isAdd)
              handleCreateAccount(
                transformValues(values, true, inheritedCollectionType),
                addMore,
                props.resetForm
              );
            else handleEditAccount(transformValues(values, false, inheritedCollectionType));
          }}
          validationSchema={accountSchema}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            const defaultFromBankClient = getDefaultAccountDataGatheringFrequency(values);
            const handleCollectionTypeChange = (values) => {
              setFieldValue('collectionType', values.value);
              inheritedCollectionType = false;
            }
            const handleResetCollectionType = () => {
              setFieldValue('collectionType', clientBanks.find(cb => cb.bankId === values.bankId)?.collectionType);
              inheritedCollectionType = true;
            }
            const handleBankChange = (values) => {
              const bankId = values.value;
              setFieldValue('bankId', bankId);
              const correspondingClientBank = clientBanks.find(cb => cb.bankId === bankId);
              hasClientBankRelation = !!correspondingClientBank;
              inheritedCollectionType = isAdd ? true : !!correspondingClientBank;
              setFieldValue('collectionType', correspondingClientBank?.collectionType || 0);
            }
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                  <ModalTextField
                    type='text'
                    name='accountName'
                    placeholder='Account Name'
                    label='Account Name'
                    value={values.accountName || ''}
                    autoComplete='off'
                  />
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='accountType'
                    value={values.accountType}
                    items={types}
                    labelName='Account Type'
                  />
                  <DateSelectField
                    name={'accountBalanceDate'}
                    setFieldValue={setFieldValue}
                    value={values.accountBalanceDate}
                    labelName={'Balance Date'}
                  />
                </AlignCenter>
                <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                  <ModalTextField
                    type='text'
                    name='accountNumber'
                    placeholder='Account Number'
                    label='Account Number'
                    value={values.accountNumber || ''}
                    autoComplete='off'
                  />
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='accountCategory'
                    value={values.accountCategory}
                    items={categories}
                    labelName='Account Category'
                  />
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='accountClosed'
                    value={values.accountClosed}
                    items={closedList}
                    labelName='Account Closed?'
                  />
                </AlignCenter>
                <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                  <ModalTextField
                    type='text'
                    name='accountAlias'
                    placeholder='Account Alias'
                    label='Account Alias'
                    value={values.accountAlias || ''}
                    autoComplete='off'
                  />
                  <ModalTextField
                    type='text'
                    name='accountBalance'
                    placeholder='Balance'
                    label='Balance'
                    value={values.accountBalance || ''}
                    autoComplete='off'
                  />
                  <DateSelectField
                    name={'accountDateClosed'}
                    setFieldValue={setFieldValue}
                    value={
                      values.accountClosed === 'Yes'
                        ? values.accountDateClosed
                        : null
                    }
                    labelName={'Account Closed Date'}
                    isDateDisabled={values.accountClosed === 'No'}
                    isLightLabel={!values.accountClosed}
                  />
                </AlignCenter>
                <AlignCenter justifyContent='space-evenly' padding='20px 0' alignItems='top'>
                  <SelectFieldFormik
                    name='bankId'
                    value={
                      values.bankId === ''
                        ? ''
                        : find(bankNames, ['value', Number(values.bankId)]).label
                    }
                    items={bankNames}
                    labelName='Bank'
                    hasHandleFunction={true}
                    handleSelectChange={handleBankChange}
                  />

                  <div>
                    <SelectFieldFormik
                      setFieldValue={setFieldValue}
                      name='dataGatheringFrequency'
                      value={
                        (
                          allowedFrequencies.map(item => item.value)).includes(values.dataGatheringFrequency)
                          ? find(allowedFrequencies, ['value', values.dataGatheringFrequency]).label
                          : values.dataGatheringFrequency === undefined ? formatDataFrequency(defaultFromBankClient)
                            : String(values.dataGatheringFrequency).concat(' months')}
                      items={allowedFrequencies}
                      labelName='Data Gathering Frequency'
                    />
                    {values.dataGatheringFrequency !== undefined ?
                      // Only show the reset button and warning if isCustomDataGatheringFrequency is set
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', margin: '0px', padding: '0px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', margin: '0px', padding: '0px' }}>
                          <div style={{ marginRight: '10px' }}>
                            <Icon
                              icon={iconValue.warning}
                              iconSize={16}
                              color={colors.yellow}
                            />
                          </div>
                          <div style={{ fontSize: '0.9375rem' }}>
                            Custom frequency
                          </div>
                        </div>
                        <WhiteButton type='button' onClick={() => setFieldValue('dataGatheringFrequency', undefined)}
                          disabled={values.dataGatheringFrequency === undefined}
                          fontSize='0.9375rem'
                          margin='0'
                          padding='0'
                        >
                          Reset
                        </WhiteButton>
                      </div>
                      :
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', margin: '0px', padding: '0px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', margin: '0px', padding: '0px' }}>
                          <div style={{ marginRight: '10px' }}>
                            <Icon
                              icon={iconValue.warning}
                              iconSize={16}
                              color={colors.yellow}
                            />
                          </div>
                          <div style={{ fontSize: '0.9375rem' }}>
                            Default from client/bank settings
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div style={{ width: '271px' }} className='radio-group'>
                    <SelectFieldFormik
                      name='collectionType'
                      value={find(collectionTypeList, ['value', values.collectionType])?.label}
                      items={collectionTypeList}
                      labelName='Collection Type'
                      hasHandleFunction={true}
                      handleSelectChange={handleCollectionTypeChange}
                    />
                    {hasClientBankRelation && inheritedCollectionType && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                          icon={iconValue.warning}
                          iconSize={16}
                          color={colors.yellow}
                        />
                        <span style={{ fontSize: '0.9375rem', whiteSpace: 'nowrap', marginRight: '20%', marginLeft: '4%' }}>Default from client/bank settings</span>
                      </div>)}

                    {hasClientBankRelation && !inheritedCollectionType && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                          icon={iconValue.warning}
                          iconSize={16}
                          color={colors.yellow}
                        />
                        <span style={{ fontSize: '0.9375rem', whiteSpace: 'nowrap', marginRight: '20%', marginLeft: '4%' }}>Custom</span>
                        <WhiteButton type='button' fontSize='0.9375rem' margin='0' padding='0' onClick={() => handleResetCollectionType()}>Reset</WhiteButton>
                      </div>)}

                  </div>
                </AlignCenter>

                <AlignCenter
                  justifyContent='flex-end'
                  padding='10px 20px 0 0'
                  borderTop={`1px solid ${colors.lightText}`}
                  margin='50px 0 0 0'
                >
                  <WhiteButton type='button' onClick={closeDialog}>
                    CLOSE
                  </WhiteButton>
                  <RegularButton
                    padding='10px 30px'
                    type='submit'
                    display='flex'
                    disabled={isSubmitting}
                    onClick={() => setAddMore(false)}
                  >
                    SAVE
                    {isSubmitting && (
                      <span style={{ marginLeft: '10px' }}>
                        <LoadSpinner size={20} />
                      </span>
                    )}
                  </RegularButton>
                  {isAdd && (
                    <RegularButton
                      padding='10px 30px'
                      type='submit'
                      disabled={isSubmitting}
                      onClick={() => setAddMore(true)}
                    >
                      SAVE AND ADD NEW
                    </RegularButton>
                  )}
                </AlignCenter>
              </Form>
            )
          }}
        </Formik>
      </AlignCenter >
    </>
  );
};

export default AccountForm;
