import React from 'react';
import { AlignCenter } from './styled-components';
import { Spinner } from '@blueprintjs/core';

//size small = 20px
//size medium = 50px
//size large = 100px
//can pass number in as size through props

const LoadSpinner = ({ size, value, intent }) => (
  <AlignCenter>
    <Spinner size={size} value={value} intent={intent} />
  </AlignCenter>
);

export default LoadSpinner;
