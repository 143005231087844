import React, { useState } from 'react';
import {
  MessageText,
  GlobalSettingsDescription,
  ResponsiveCenter,
} from '../styled-components';
import NoteCategoriesTable from '../table/NoteCategoriesTable';
import NoteCategoriesDeactivateDialogBox from '../dialog/global-settings-dialog/NoteCategoriesDeactivateDialogBox';
import NoteCategoryDialogBox from '../dialog/global-settings-dialog/NoteCategoryDialogBox';

const ManageNoteCategories = ({
  appUser,
  noteCategories,
  isFetching,
  fetchAllNotes,
  fetchSingleNoteCategory,
  ...props
}) => {
  const [title, setTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [noteCategoryObj, setNoteCategoryObj] = useState({});

  const [isDeactivate, setIsDeactivate] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);

  const openAdd = () => {
    setTitle('Add Note Category');
    setIsOpen(true);
  };

  const openEdit = (selectedRow) => {
    setNoteCategoryObj(selectedRow);
    setTitle('Edit Note Category');
    setIsOpen(true);
  };

  const handleClose = () => {
    setTitle('');
    setIsOpen(false);
    setNoteCategoryObj({});
  };

  const closeDeactivateDialog = () => {
    setCategoryIds([]);
    setIsDeactivate(false);
  };

  const openDeleteDialog = (selectedRows) => {
    if (selectedRows instanceof Array) {
      setCategoryIds(selectedRows.map((row) => row.noteCategoryId));
    } else {
      setCategoryIds([selectedRows.noteCategoryId]);
    }
    setIsDeactivate(true);
  };

  return (
    <>
      <NoteCategoriesDeactivateDialogBox
        isOpen={isDeactivate}
        handleClose={closeDeactivateDialog}
        title={
          categoryIds.length > 1
            ? `Deactivate selected Note Categories (${categoryIds.length})`
            : 'Deactivate Note Category'
        }
        categoryIds={categoryIds}
        fetchAllNotes={fetchAllNotes}
      />
      <NoteCategoryDialogBox
        handleDialogClose={handleClose}
        isOpen={isOpen}
        title={title}
        noteCategoryObj={noteCategoryObj}
        fetchAllNotes={fetchAllNotes}
        fetchSingleNoteCategory={fetchSingleNoteCategory}
      />
      <ResponsiveCenter className={'global-setting'} minWidth={'765px'}>
        <GlobalSettingsDescription>
          <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
            Manage Note Categories
          </MessageText>
          <MessageText>
            Create and manage note categories for use in the application.
          </MessageText>
        </GlobalSettingsDescription>
        <NoteCategoriesTable
          appUser={appUser}
          noteCategories={noteCategories}
          isFetching={isFetching}
          openAdd={openAdd}
          openEdit={openEdit}
          openDeleteDialog={openDeleteDialog}
          {...props}
        />
      </ResponsiveCenter>
    </>
  );
};

export default ManageNoteCategories;
