// reusable colors

const colors = {
  // primary purple
  primary: '#470D82',
  // purple text
  purpleText: '#7636B5',
  // light purple
  lightPurple: '#E6E6FA',
  // bold text
  boldText: '#4F5467',
  // normal text
  normalText: '#9D9BA3',
  // light text/box shadow
  lightText: '#CACED6',
  // error text
  red: '#B92D00',
  // green text
  green: '#009688',
  // yellow warnings
  yellow:'#E6B344',
  // purple button hover
  purpleHover: '#885BB5',
  // grey used on table header and as grey hover
  grey: '#EDF0F5',
  // background for containers
  containerBackground: '#F8F9FB',
  white: '#FFF',
  black: '#000',
  // default placeholder text color for empty form fields
  greyPlaceholderText: '#7b7b7b',
  //Disabled fields
  greyDisabled: '#f7f7f9',
  //Active Status color table
  activeColor: '#470d82',
  inActiveColor: '#f4c65b',
  //User role color
  adminColor: '#ff514d',
  userColor: '#1dc7ce',
  redHover: '#FF3732',
  // box shadow for widgets that we are not using <CardDiv> for
  boxShadowWidget: '#DBDDE0',
};

export default colors;

// colors for some pie and bar charts
// medium orchid, medium purple, thistle, purple, indigo
export const colorList = [
  '#BA55D3',
  '#9370DB',
  '#D8BFD8',
  '#800080',
  '#4B0082',
  '#E6E6FA',
  '#8A2BE2',
];
