import React, { useEffect, useState } from 'react';
import DataIngestionHeader from '../../components/headers/DataIngestionHeader';
import { ComponentBody } from '../../components/styled-components';
import { fetchOpenReports } from '../../actions/data-ingestion';
import { connect } from 'react-redux';
import OpenReportsTable from '../../components/table/OpenReportsTable';
import OpenReportDeleteDialog from '../../components/dialog/data-ingestion/OpenReportDeleteDialog';
import { deleteOpenReport } from '../../api/data-ingestion';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';

const DataIngestionBase = ({
  appUser,
  fetchOpenReports,
  openReports,
  isOpenReportsFetching,
  ...props
}) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [openReport, setOpenReport] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchOpenReports();
  }, [fetchOpenReports]);

  const handleDeleteDialogOpen = (openReport) => {
    setIsOpenDeleteDialog(true);
    setOpenReport(openReport);
  };

  const handleDeleteDialogClose = () => {
    setIsOpenDeleteDialog(false);
    setOpenReport({});
  };

  const handleDelete = () => {
    setIsDeleting(true);
    deleteOpenReport(openReport)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchOpenReports();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete open reports',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        setOpenReport({});
        setIsOpenDeleteDialog(false);
      });
  };

  return (
    <>
      <DataIngestionHeader header='Data Ingestion: Open Batches' {...props} />
      <ComponentBody padding='0'>
        <OpenReportsTable
          openReports={openReports}
          appUser={appUser}
          isFetching={isOpenReportsFetching}
          handleDeleteDialogOpen={handleDeleteDialogOpen}
          {...props}
        />
        <OpenReportDeleteDialog
          isOpen={isOpenDeleteDialog}
          title='Delete Batch'
          handleClose={handleDeleteDialogClose}
          handleDelete={handleDelete}
          openReport={openReport}
          isDeleting={isDeleting}
        />
      </ComponentBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  openReports: state.dataIngestion.allOpenReports,
  isOpenReportsFetching: state.dataIngestion.isOpenReportsFetching,
});

const DataIngestion = connect(mapStateToProps, { fetchOpenReports })(
  DataIngestionBase
);

export default DataIngestion;
