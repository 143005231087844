import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import DialogDeleteActions from '../DialogDeleteActions';
import { connect } from 'react-redux';
import { RedText, MessageText } from '../../styled-components';
import { removeAccountGroupAssociation } from '../../../api/accounts';
import AppToast from '../../Toast';
import { fetchAccountsByGroup } from '../../../actions/accounts';

const AccountDeleteDialogBoxBase = ({
  isOpen,
  title,
  handleClose,
  selectedAccounts,
  account,
  fetchAccountsByGroup,
  groupId,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = () => {
    setIsDeleting(true);
    let accountGroupXrefs;
    if (selectedAccounts.length > 0) {
      accountGroupXrefs = selectedAccounts.map(
        (account) => account.accountXrefId
      );
    } else {
      accountGroupXrefs = [account.accountXrefId];
    }
    account = {
      accountGroupXrefs,
    };
    removeAccountGroupAssociation(account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete note(s)',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        fetchAccountsByGroup(groupId);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete{' '}
          {selectedAccounts.length ? 'these accounts' : 'this account'}?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const AccountDeleteDialogBox = connect(mapStateToProps, {
  fetchAccountsByGroup,
})(AccountDeleteDialogBoxBase);

export default AccountDeleteDialogBox;
