import axios from 'axios';
import { setHeader } from './index';

const ANALYTICS_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/analytics`;

export const fetchOpenReports = async () => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/open-reports`);
};

export const fetchMonteCarloHistory = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/monte-carlo/${clientId}`);
};

export const runAnalysis = async (monteCarlo) => {
  const request = await setHeader(axios);
  return request.post(`${ANALYTICS_API}/monte-carlo/run`, {
    monteCarlo,
  });
};

export const runStepOneAnalysis = async (shortTerm) => {
  const request = await setHeader(axios);
  return request.post(`${ANALYTICS_API}/short-term/step-1/run`, {
    shortTerm,
  });
};

export const runStepTwoAnalysis = async (shortTerm, shortTermModelId) => {
  const request = await setHeader(axios);
  return request.put(
    `${ANALYTICS_API}/short-term/step-2/run/${shortTermModelId}`,
    {
      shortTerm,
    }
  );
};

export const fetchStepOneShortTermAnalysis = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/short-term/step-1/${clientId}`);
};

export const downloadFile = async (clientId, fileName) => {
  const request = await setHeader(axios);
  return request.get(
    `${ANALYTICS_API}/short-term/download/${clientId}/${fileName}`
  );
};

export const fetchStepTwoShortTermAnalysis = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/short-term/step-2/${clientId}`);
};

export const fetchMonteResults = async (monteCarloId) => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/monte-carlo/results/${monteCarloId}`);
};

export const fetchTableCushionBalances = async (monteCarloId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ANALYTICS_API}/monte-carlo/total-balance/${monteCarloId}`
  );
};

export const fetchDefaultLiquidityLevels = async (monteCarloId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ANALYTICS_API}/monte-carlo/liquidity-levels/get/default/${monteCarloId}`
  );
};

export const fetchCurrentLiquidityLevels = async (monteCarloId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ANALYTICS_API}/monte-carlo/liquidity-levels/get/current/${monteCarloId}`
  );
};

export const updateLiquidityLevels = async (accountGroupId, monteCarlo) => {
  const request = await setHeader(axios);
  return request.put(
    `${ANALYTICS_API}/monte-carlo/liquidity-levels/${accountGroupId}`,
    { monteCarlo }
  );
};

export const deleteShortTermStepOne = async (shortTermModelId) => {
  const request = await setHeader(axios);
  return request.delete(
    `${ANALYTICS_API}/short-term/step-1/remove/${shortTermModelId}`
  );
};

export const deleteShortTermStepTwo = async (shortTermModelId) => {
  const request = await setHeader(axios);
  return request.delete(
    `${ANALYTICS_API}/short-term/step-2/remove/${shortTermModelId}`
  );
};

export const deleteMonteCarlo = async (monteCarloId) => {
  const request = await setHeader(axios);
  return request.delete(`${ANALYTICS_API}/monte-carlo/remove/${monteCarloId}`);
};

export const fetchShortTermForecast = async (accountGroupId) => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/short-term/forecast/${accountGroupId}`);
};

export const fetchDurationGraphData = async (monteCarloId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ANALYTICS_API}/monte-carlo/duration-capability-cushion-graph/${monteCarloId} `
  );
};

export const fetchCashVestScores = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/cash-vest/${clientId}`);
};

export const fetchDayToDayVarianceShortTermModel = async (accountGroupId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ANALYTICS_API}/short-term/day-to-day-variance/${accountGroupId}`
  );
};

export const fetchShortTermModelList = async () => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/short-term/list`);
};

export const fetchCashVestSubScores = async (cashVestId) => {
  const request = await setHeader(axios);
  return request.get(`${ANALYTICS_API}/cash-vest/sub-scores/${cashVestId}`);
};

export const updateCashVestScores = async (cashVestId, cashVest) => {
  const request = await setHeader(axios);
  return request.put(`${ANALYTICS_API}/cash-vest/update/${cashVestId}`, {
    cashVest,
  });
};

export const completeAnalysis = async (clientId) => {
  const request = await setHeader(axios);
  return request.put(`${ANALYTICS_API}/complete-analysis/${clientId}`);
};

export const fetchShortTermCashPosition = async (clientId, accountGroupId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ANALYTICS_API}/short-term/cash-position-forecast/${clientId}/${accountGroupId}`
  );
};
