import React, { useMemo, useState } from 'react';
import { ActionIconButton, TextBox, BoldText } from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import {
  localDateFormatter,
  checkPermissions,
  getTwoWeeksRange,
} from '../../utils/functions';
import { Popover, Position, Icon, MenuItem, Menu } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import permissions from '../../utils/permissions';
import RowSelector from './components/RowSelector';
import styled from 'styled-components';
import ViewNoteDialog from '../dialog/notes/ViewNoteDialog';
import NoteDialog from '../dialog/notes/NoteDialog';
import shortId from 'shortid';
import NoteResolveDialogBox from '../dialog/notes/NoteResolveDialogBox';
import NoteDeleteDialogBox from '../dialog/notes/NoteDeleteDialogBox';
import { TableContainer } from './components/table-styled-components';

const NotesTable = ({
  notes,
  appUser,
  clientId,
  isFetching,
  tableTitle,
  isDashboard,
  accountId,
  batchId,
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isViewNoteDialogOpen, setIsViewNoteDialogOpen] = useState(false);
  const [isResolveDialogOpen, setIsResolveDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isResolvedNote, setIsResolvedNote] = useState(false);
  const [resolveDialogTitle, setResolveDialogTitle] = useState('');
  const [note, setNote] = useState({});
  const [selectedNotes, setSelectedNotes] = useState([]);

  // This state is needed for tables that will have filter on initial load.
  // We need to setup this state to false when opening any action items.
  // and after action is completed, the original filter will be persisted.
  // NotesTable -> MakeTable -> GlobalFilter -> NotesFilterDialogBox
  const [resetInitialFilter, setResetInitialFilter] = useState(true);

  const canResolved = (authorId, appUser) =>
    checkPermissions(appUser.permList, [permissions.RESOLVE_NOTES]) ||
    authorId === appUser.userId;

  const handleDialogOpen = () => {
    setResetInitialFilter(false);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOpenViewDialog = (noteData, tableTitle) => {
    setResetInitialFilter(false);
    setIsViewNoteDialogOpen(true);
    setNote(noteData);
    if (tableTitle) setIsResolvedNote(true);
  };

  const handleCloseViewDialog = () => {
    setIsViewNoteDialogOpen(false);
    setNote({});
    setIsResolvedNote(false);
  };

  const handleResolveDialogOpen = (notes) => {
    setResetInitialFilter(false);
    notes.length ? setSelectedNotes(notes) : setNote(notes);
    setResolveDialogTitle(
      notes.length ? `Resolve Selected Notes (${notes.length})` : 'Resolve Note'
    );
    setIsResolveDialogOpen(true);
  };

  const handleRestoreDialogOpen = (notes) => {
    setResetInitialFilter(false);
    notes.length ? setSelectedNotes(notes) : setNote(notes);
    setResolveDialogTitle(
      notes.length ? `Restore Selected Notes (${notes.length})` : 'Restore Note'
    );
    setIsResolveDialogOpen(true);
  };

  const handleResolveDialogClose = () => {
    setNote({});
    setSelectedNotes([]);
    setResolveDialogTitle('');
    setIsResolveDialogOpen(false);
  };

  const handleDeleteDialogOpen = (notes) => {
    setResetInitialFilter(false);
    notes.length ? setSelectedNotes(notes) : setNote(notes);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setNote({});
    setSelectedNotes([]);
    setIsDeleteDialogOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps, rows }) => {
          const taggedUsersList = rows.map((row) => row.original.taggedUserIds);
          // checks if the app user is in every tagged user list
          const isInTaggedUsersList = taggedUsersList.every(
            (row) => row && row.includes(String(appUser.userId))
          );

          //Check any of those three condition is true and set disable false if they are
          const disabled = !(
            checkPermissions(appUser.permList, [permissions.RESOLVE_NOTES]) ||
            rows
              .map((row) => row.original.authorId)
              .every((id) => id === appUser.userId) ||
            isInTaggedUsersList
          );

          return (
            <RowSelector
              {...getToggleAllRowsSelectedProps()}
              style={{ height: '18px', width: '18px' }}
              rows={rows}
              disabled={disabled}
            />
          );
        },
        Cell: ({ row }) => {
          const taggedUserIds = row.original.taggedUserIds;
          const isInTaggedUserList =
            taggedUserIds && taggedUserIds.includes(String(appUser.userId));

          const disabled = !(
            row.original.authorId === appUser.userId ||
            isInTaggedUserList ||
            checkPermissions(appUser.permList, [permissions.RESOLVE_NOTES])
          );
          return (
            <RowSelector
              {...row.getToggleRowSelectedProps()}
              style={{ height: '15px', width: '15px' }}
              disabled={disabled}
            />
          );
        },
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Author' />
        ),
        accessor: 'author',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        width: '15%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Preview' />
        ),
        id: 'Preview',
        accessor: (row) => [
          row.noteTitle,
          row.noteDescription,
          row.commentCount,
        ],
        disableSortBy: true,
        filter: 'multipleValues',
        isVisible: true,
        width: '20%',
        Cell: ({ cell: { row } }) => {
          const noteDescription = row.original.noteDescription;
          const formattedNoteDescription =
            noteDescription && noteDescription.length <= 128
              ? noteDescription
              : `${noteDescription && noteDescription.slice(0, 128)}...`;
          return (
            <>
              <BoldText>{row.original.noteTitle}</BoldText>
              <Comment>{formattedNoteDescription}</Comment>
              <br />
              <div style={{ display: 'flex' }}>
                <Icon icon={iconValue.comment} iconSize={16} />
                <CommentCount>{row.original.commentCount}</CommentCount>
              </div>
            </>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'createdAt',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{value && localDateFormatter(value)}</span>;
        },
        width: '15%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Categories' />
        ),
        accessor: 'noteCategoryNames',
        filter: 'tags',
        disableSortBy: true,
        isVisible: true,
        width: '24%',
        Cell: ({ cell: { value } }) => {
          return (
            <div style={{ display: 'flex' }}>
              {value &&
                value.slice(0, 2).map((category) => (
                  <TextBox
                    key={shortId.generate()}
                    background={colors.purpleText}
                    margin='2px 3px'
                    padding='10px'
                  >
                    {category}
                  </TextBox>
                ))}
            </div>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='User Tags' />
        ),
        accessor: 'taggedUsers',
        filter: 'tags',
        sortType: 'basic',
        disableSortBy: true,
        isVisible: true,
        width: '24%',
        Cell: ({ cell: { value } }) => {
          return (
            <div style={{ display: 'flex' }}>
              {value &&
                value.slice(0, 2).map((user) => (
                  <TextBox
                    key={shortId.generate()}
                    background={colors.green}
                    margin='2px 3px'
                    padding='10px'
                  >
                    {user}
                  </TextBox>
                ))}
            </div>
          );
        },
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '1%',
        Cell: ({ cell: { row } }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.VIEW_NOTES,
                  ]) && (
                    <MenuItem
                      text='View/Reply'
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                      onClick={() =>
                        handleOpenViewDialog(row.original, tableTitle)
                      }
                    />
                  )}
                  {canResolved(row.original.authorId, appUser) && (
                    <>
                      <MenuItem
                        text={
                          tableTitle === 'Resolved Notes'
                            ? 'Restore Note'
                            : 'Resolve Note'
                        }
                        style={{
                          color: `${colors.purpleText}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                        }}
                        onClick={() => {
                          tableTitle === 'Resolved Notes'
                            ? handleRestoreDialogOpen(row.original)
                            : handleResolveDialogOpen(row.original);
                        }}
                      />
                    </>
                  )}
                  {tableTitle === 'Resolved Notes' &&
                    checkPermissions(appUser.permList, [
                      permissions.DELETE_NOTES,
                    ]) && (
                      <>
                        <Menu.Divider />
                        <MenuItem
                          text='Delete'
                          onClick={() => handleDeleteDialogOpen(row.original)}
                          style={{
                            color: `${colors.red}`,
                            fontWeight: '700',
                            paddingTop: ' 5px',
                            paddingBottom: ' 5px',
                            marginBottom: '10px',
                          }}
                        />
                      </>
                    )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_NOTES,
          permissions.RESOLVE_NOTES,
          permissions.DELETE_NOTES,
        ]),
      },
      {
        accessor: (row) => [row.authorId, row.taggedUserIds],
        id: 'userReference',
        // filter value linked to constant list method
        filter: 'userNotes',
        isVisible: false,
      },
    ],
    [appUser, tableTitle]
  );

  const CommentCount = styled.div`
    font-size: 0.8125rem;
    margin-left: 5px;
    padding-top: 4px;
  `;

  const Comment = styled.div`
    margin-bottom: -12px;
    font-size: 0.8125rem;
  `;

  return (
    <TableContainer padding='0px' width='100%'>
      <MakeTable
        appUser={appUser}
        columns={columns}
        data={notes}
        resetInitialFilter={resetInitialFilter}
        isFetching={isFetching}
        tableTitle={tableTitle ? tableTitle : 'Notes'}
        handleAddDialogOpen={handleDialogOpen}
        isDashboard={isDashboard}
        rangeFilter={getTwoWeeksRange()}
        rangeFilterName={'Created At'}
        handleResolveDialogOpen={handleResolveDialogOpen}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        handleRestoreDialogOpen={handleRestoreDialogOpen}
        {...props}
      />
      <NoteDialog
        title={'Add Note'}
        appUser={appUser}
        clientId={clientId}
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
        accountId={accountId}
        batchId={batchId}
        setResetInitialFilter={setResetInitialFilter}
        isDashboard={isDashboard}
        isResolvedNote={isResolvedNote}
      />
      <ViewNoteDialog
        appUser={appUser}
        title='View Note'
        isViewNoteDialogOpen={isViewNoteDialogOpen}
        handleCloseViewDialog={handleCloseViewDialog}
        noteId={note.noteId}
        isResolvedNote={isResolvedNote}
        isDashboard={isDashboard}
        clientId={clientId}
        accountId={accountId}
        batchId={batchId}
        setResetInitialFilter={setResetInitialFilter}
      />
      <NoteResolveDialogBox
        appUser={appUser}
        clientId={clientId}
        isOpen={isResolveDialogOpen}
        title={resolveDialogTitle}
        handleClose={handleResolveDialogClose}
        note={note}
        selectedNotes={selectedNotes}
        accountId={accountId}
        batchId={batchId}
        setResetInitialFilter={setResetInitialFilter}
        isDashboard={isDashboard}
      />
      <NoteDeleteDialogBox
        isOpen={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        title={
          selectedNotes.length
            ? `Delete Selected Resolve Notes (${selectedNotes.length})`
            : 'Delete Resolve Note'
        }
        note={note}
        selectedNotes={selectedNotes}
        setResetInitialFilter={setResetInitialFilter}
      />
    </TableContainer>
  );
};

export default NotesTable;
