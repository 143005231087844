import React from 'react';
import { Dialog } from '@blueprintjs/core';
import colors from '../../../utils/colors';
import {
  AlignCenter,
  MessageText,
  RegularButton,
  WhiteButton,
} from '../../../components/styled-components';
import LoadSpinner from '../../LoadSpinner';

// warning dialog for same name bank and client
const FinalizeDialog = ({
  handleCloseFinalizeDialog,
  isFinalizeDialogOpen,
  isFinalizing,
  handleToggleFinalize,
  selectedReport,
}) => {
  return (
    <>
      <Dialog
        className='small-dialog'
        isOpen={isFinalizeDialogOpen}
        title={
          selectedReport.finalized
            ? 'Unfinalize Report'
            : 'Finalize Report'
        }
        onClose={handleCloseFinalizeDialog}
        isCloseButtonShown={false}
      >
        <div
          style={{
            padding: '30px 50px 20px',
          }}
        >
          <MessageText>
            Are you sure you want to{' '}
            {selectedReport.finalized ? 'unfinalize' : 'finalize'} this
            report?
          </MessageText>
        </div>
        <AlignCenter
          padding='10px 0 10px 0'
          borderTop={`1px solid ${colors.lightText}`}
          margin='50px 0 0 0'
        >
          <WhiteButton type='button' onClick={handleCloseFinalizeDialog}>
            CANCEL
          </WhiteButton>
          <RegularButton
            width='13rem'
            padding='10px 30px'
            display='flex'
            onClick={handleToggleFinalize}
            disabled={isFinalizing}
          >
            OK
            {isFinalizing && (
              <span style={{ marginLeft: '10px' }}>
                <LoadSpinner size={20} />
              </span>
            )}
          </RegularButton>
        </AlignCenter>
      </Dialog>
    </>
  );
};

export default FinalizeDialog;
