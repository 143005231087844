import React from 'react';
import { Formik, Form } from 'formik';
import { AlignCenter } from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import FormActions from '../FormActions';
import * as Yup from 'yup';

const statusList = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const investmentTypesSchema = Yup.object().shape({
  investmentTypeName: Yup.string().required('Required'),
  status: Yup.string().required('Required!'),
});

const transformValues = (values, title) => {
  // On add do not send status as it defaults to active
  if (title === 'Add Investment Types') {
    delete values.status;
  } else {
    values.status = Number(values.status.toLowerCase() === 'active');
  }
  return values;
};

const InvestmentTypesForm = ({
  title,
  investmentType,
  onClose,
  handleEdit,
  handleCreate,
}) => {
  return (
    <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
      <Formik
        initialValues={{
          investmentTypeName: investmentType
            ? investmentType.investmentTypeName
            : '',
          status: investmentType ? investmentType.status : 'active',
        }}
        validationSchema={investmentTypesSchema}
        onSubmit={(values) => {
          if (title === 'Add Investment Types')
            handleCreate(transformValues(values, title));
          else
            handleEdit(
              transformValues(values, title),
              investmentType.investmentTypeId
            );
        }}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
              <ModalTextField
                type='text'
                name='investmentTypeName'
                placeholder='Investment Type Name'
                label='Investment Type Name'
                value={values.investmentTypeName || ''}
                autoComplete='off'
              />
              {title === 'Edit Investment Types' ? (
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  value={values.status || ''}
                  labelName={'Status'}
                  items={statusList}
                  name='status'
                  useLabel={true}
                />
              ) : (
                <div style={{ minWidth: '271px' }}></div>
              )}
            </AlignCenter>
            <FormActions isSubmitting={isSubmitting} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default InvestmentTypesForm;
