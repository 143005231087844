import React, { useState } from 'react';
import {
  ComponentBody,
  ProfileHeadingText,
} from '../../../../components/styled-components';
import UserDetails from '../../../../components/user-profile/UserDetails';
import AppToast from '../../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import { updateUserProfile, resetMfa } from '../../../../api/users';
import { fetchCognitoUser } from '../../../../api/auth';
import ResetMfaDialogBox from '../../../../components/dialog/user-profile-dialog/ResetMfaDialogBox';
import { Auth } from 'aws-amplify';

const AccountDetails = (props) => {
  const [firstName] = useState(props.appUser.firstName);
  const [lastName] = useState(props.appUser.lastName);
  const [email] = useState(props.appUser.email);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPassword] = useState('');
  const [newPassword] = useState('');
  const [confirmPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [verifyPasswordError, setVerifyPasswordError] = useState(false);
  const [cognitoError, setCognitoError] = useState('');
  const [showCognitoError, setShowCognitoError] = useState(false);
  const [samePasswordError] = useState(
    'New password cannot match current password.'
  );
  const [showSamePasswordError, setShowSamePasswordError] = useState(false);
  const [mfaDisable, setMfaDisable] = useState(false);

  // destructure from props
  const { fetchAppUser } = props;

  const toggleUserPasswordFormErrors = (input) => {
    input.currentPassword === ''
      ? setCurrentPasswordError(true)
      : setCurrentPasswordError(false);

    input.newPassword === ''
      ? setNewPasswordError(true)
      : setNewPasswordError(false);

    input.confirmPassword === ''
      ? setConfirmPasswordError(true)
      : setConfirmPasswordError(false);

    // if confirm password is not empty and doesn't match new password field,
    // display error for needing to match passwords
    if (
      input.confirmPassword !== '' &&
      input.newPassword !== input.confirmPassword
    ) {
      setConfirmPasswordError(true);
      setVerifyPasswordError(true);
    } else {
      // setConfirmPasswordError(false);
      setVerifyPasswordError(false);
    }

    // if new password field is not empty and new password field matches confirm field,
    // show error for not allowing same passwords
    if (
      input.newPassword !== '' &&
      input.confirmPassword === input.newPassword
    ) {
      setNewPasswordError(true);
      setShowSamePasswordError(true);
    } else {
      setShowSamePasswordError(false);
    }

    // set the cognito error for the current password back to false
    // so it goes away on each run of the handle update function
    setShowCognitoError(false);
  };

  const handleChangeUserDetails = async (values, actions) => {
    const oldEmail = props.appUser.email;
    const id = props.appUser.userId;
    const user = {
      ...values,
      oldEmail,
      id,
    };

    updateUserProfile(id, user)
      .then(async (res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        const cognitoUser = await fetchCognitoUser(true);
        if (cognitoUser) await fetchAppUser();
      })
      .catch((err) => {
        console.error(err);
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit user.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        // set submitting to false to make button normal state
        actions.setSubmitting(false);
      });
  };

  const handleResetForm = (resetForm) => {
    setCurrentPasswordError(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setVerifyPasswordError(false);
    setShowCognitoError(false);
    resetForm();
  };

  const openResetDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleResetMfa = async () => {
    const { appUser, logoutAppUser, history } = props;
    setMfaDisable(true);
    resetMfa(appUser.email)
      .then(async (res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        // logout cognito user and appUser (cognito and database)
        await Auth.signOut();
        logoutAppUser();
        history.push('/');
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to reset mfa.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsOpen(false);
        setMfaDisable(false);
      });
  };

  const handleChangePassword = async (
    { currentPassword, newPassword, confirmPassword },
    actions
  ) => {
    // const { appUser } = props;
    const input = {
      currentPassword,
      newPassword,
      confirmPassword,
    };
    // if form is not valid or any field is empty go into toggle error function and exit this function
    if (
      input.currentPassword === '' ||
      input.newPassword === '' ||
      input.confirmPassword === '' ||
      input.newPassword !== input.confirmPassword ||
      input.currentPassword === input.newPassword
    ) {
      await toggleUserPasswordFormErrors(input);
      actions.setSubmitting(false);
      return;
    } else {
      try {
        // get user
        await Auth.currentAuthenticatedUser()
          .then(async (user) => {
            await Auth.changePassword(user, currentPassword, newPassword);
          })
          // if change password succeeds reset form and if cognito error is showing
          .then(() => {
            AppToast.show({
              message: 'Password updated successfully!',
              intent: Intent.SUCCESS,
            });
            actions.resetForm();
            setShowCognitoError(false);
          })
          // if fail, show cognito error if it needs to and/or fail toast
          .catch((err) => {
            AppToast.show({
              message: 'Password update failed.',
              intent: Intent.DANGER,
            });
            // check error codes from cognito, show certain errors based on codes
            if (
              err.code === 'InvalidParameterException' ||
              err.code === 'InvalidPasswordException'
            ) {
              setNewPasswordError(true);
              setShowCognitoError(true);
              setCognitoError('Password must meet requirements.');
            } else if (err.code === 'NotAuthorizedException') {
              setNewPasswordError(true);
              setShowCognitoError(true);
              setCognitoError('Invalid Password.');
            }
          });
      } catch (err) {
        console.err(err);
      } finally {
        // reset all values of errors
        actions.setSubmitting(false);
        setCurrentPasswordError(false);
        setNewPasswordError(false);
        setConfirmPasswordError(false);
        setVerifyPasswordError(false);
      }
    }
  };

  return (
    <ComponentBody padding='14rem 8rem 4rem'>
      <ProfileHeadingText margin='0 0 30px 0'>
        Account Details
      </ProfileHeadingText>
      <UserDetails
        firstName={firstName}
        lastName={lastName}
        email={email}
        currentPassword={currentPassword}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        currentPasswordError={currentPasswordError}
        newPasswordError={newPasswordError}
        confirmPasswordError={confirmPasswordError}
        verifyPasswordError={verifyPasswordError}
        cognitoError={cognitoError}
        showCognitoError={showCognitoError}
        samePasswordError={samePasswordError}
        showSamePasswordError={showSamePasswordError}
        handleChangeUserDetails={handleChangeUserDetails}
        handleResetForm={handleResetForm}
        openResetDialog={openResetDialog}
        handleChangePassword={handleChangePassword}
        {...props}
      />
      <ResetMfaDialogBox
        isOpen={isOpen}
        title={'Reset MFA'}
        closeDialog={closeDialog}
        handleResetMfa={handleResetMfa}
        mfaDisable={mfaDisable}
      />
    </ComponentBody>
  );
};

export default AccountDetails;
