import React from 'react';
import {
  Label,
  FlexColumn,
  InputWrapper,
  Input,
  AlignCenter,
  ErrorMessageDiv,
} from '../styled-components';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon } from '@blueprintjs/core';
import { currencyFormat } from '../../utils/functions';

const NumberRangeField = ({
  labelName,
  selectLow,
  selectHigh,
  low,
  high,
  isCurrency,
  isPercentage,
}) => {
  low = isCurrency ? currencyFormat(low) : low;
  high = isCurrency ? currencyFormat(high) : high;
  //Checks if the format of the input is appropriate
  const lowError = isCurrency
    ? !/^(\d*|\d{1,3}(,\d{3})*)(\.\d{1,2})?$/.test(low)
    : !/^(?:\d*\.?\d+)?$/.test(low);
  const highError = isCurrency
    ? !/^(\d*|\d{1,3}(,\d{3})*)(\.\d{1,2})?$/.test(high)
    : !/^(?:\d*\.?\d+)?$/.test(high);

  return (
    <div style={{ display: 'inline-flex' }}>
      <FlexColumn>
        <Label>{labelName}</Label>
        <InputWrapper
          className={
            isCurrency
              ? 'currency-input'
              : isPercentage
              ? 'percentage-input'
              : ''
          }
        >
          <Input
            type='text'
            placeholder='Low Value'
            onChange={(event) => {
              selectLow(event.target.value);
            }}
            min='0'
            value={low}
          />
        </InputWrapper>
        <div>
          {lowError && (
            <AlignCenter justifyContent='space-between'>
              <ErrorMessageDiv fontSize='1rem'>
                {isCurrency
                  ? 'Field must be monetary value.'
                  : 'Field must be a number.'}
              </ErrorMessageDiv>
              <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
            </AlignCenter>
          )}
        </div>
      </FlexColumn>
      <FlexColumn>
        <div style={{ padding: '18px 0px 0px 50px' }}>
          <InputWrapper
            className={
              isCurrency
                ? 'currency-input'
                : isPercentage
                ? 'percentage-input'
                : ''
            }
          >
            <Input
              type='text'
              placeholder='High Value'
              onChange={(event) => {
                selectHigh(event.target.value);
              }}
              min='0'
              value={high}
            />
          </InputWrapper>
        </div>
        <div style={{ paddingLeft: '50px' }}>
          {highError && (
            <AlignCenter justifyContent='space-between'>
              <ErrorMessageDiv fontSize='1rem'>
                {isCurrency
                  ? 'Field must be monetary value.'
                  : 'Field must be a number.'}
              </ErrorMessageDiv>
              <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
            </AlignCenter>
          )}
        </div>
      </FlexColumn>
    </div>
  );
};

export default NumberRangeField;
