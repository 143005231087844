import axios from 'axios';
import { setHeader } from './index';

const TRANSACTION_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/transactions`;

export const fetchAllTransactions = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${TRANSACTION_API}/${accountId}`);
};

export const fetchPaginatedData = async (
  accountId,
  pageSize,
  pageIndex,
  filters,
  searchTerm,
  sortOrder
) => {
  const request = await setHeader(axios);
  return request.post(`${TRANSACTION_API}/${accountId}`, {
    pageSize,
    pageIndex,
    filters,
    searchTerm,
    sortOrder,
  });
};

export const fetchTransactionKeywords = async () => {
  const request = await setHeader(axios);
  return request.get(`${TRANSACTION_API}/keyword-categories`);
};

export const deleteTransaction = async (transaction) => {
  const request = await setHeader(axios);
  return request.delete(`${TRANSACTION_API}/remove`, { data: { transaction } });
};

export const updateTransaction = async (transaction) => {
  const request = await setHeader(axios);
  return request.put(`${TRANSACTION_API}/update/${transaction.transactionId}`, {
    transaction,
  });
};

export const bulkUpdateTransactions = async (transaction) => {
  const request = await setHeader(axios);
  return request.put(`${TRANSACTION_API}/update`, { transaction });
};

export const fetchStagingTransactions = async (batchAccountId) => {
  const request = await setHeader(axios);
  return request.get(`${TRANSACTION_API}/staging/${batchAccountId}`);
};

export const fetchStagingPaginatedData = async (
  batchAccountIds,
  pageSize,
  pageIndex,
  filters,
  searchTerm,
  sortOrder
  ) => {
    const request = await setHeader(axios);
    return request.post(`${TRANSACTION_API}/staging/inspect`, {
      batchAccountIds,
      pageSize,
      pageIndex,
      filters,
      searchTerm,
      sortOrder,
    });
  };

export const fetchSingleStagingTransaction = async (stagingTransactionId) => {
  const request = await setHeader(axios);
  return request.get(
    `${TRANSACTION_API}/staging/single/${stagingTransactionId}`
  );
};

export const updateStagingTransaction = async (transaction) => {
  const request = await setHeader(axios);
  return request.put(
    `${TRANSACTION_API}/staging/update/${transaction.stagingTransactionId}`,
    { transaction }
  );
};

export const bulkUpdateStagingTransactions = async (transaction) => {
  const request = await setHeader(axios);
  return request.put(`${TRANSACTION_API}/staging/update`, {
    transaction,
  });
};

export const validateStagingTransaction = async (transaction) => {
  const request = await setHeader(axios);
  return request.put(`${TRANSACTION_API}/staging/validate`, {
    transaction,
  });
};

export const invalidateStagingTransaction = async (transaction) => {
  const request = await setHeader(axios);
  return request.put(`${TRANSACTION_API}/staging/invalidate`, {
    transaction,
  });
};

export const deleteStagingTransaction = async (transaction) => {
  const request = await setHeader(axios);
  return request.delete(`${TRANSACTION_API}/staging`, {
    data: { transaction },
  });
};
