import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import axios from 'axios';
import {
  downloadFile,
  deleteShortTermStepOne,
  deleteShortTermStepTwo,
} from '../../api/analysis';
import AppToast from '../../components/Toast';
import { downloadFiles, stringToDate } from '../../utils/functions';
import {
  fetchStepOneShortTermAnalysis,
  fetchStepTwoShortTermAnalysis,
} from '../../actions/analysis';
import LoadSpinner from '../../components/LoadSpinner';
import StepOneAnalysisTable from '../../components/table/StepOneAnalysisTable';
import StepTwoAnalysisTable from '../../components/table/StepTwoAnalysisTable';
import ShortTermDeleteDialogBox from '../../components/dialog/analysis/ShortTermDeleteDialogBox';
import { getOneYearRange } from '../../utils/functions';
import RunShortTermAnalysisDialog from '../../components/dialog/analysis/RunShortTermAnalysisDialog';

const ShortTermModelBase = ({
  clientId,
  appUser,
  stepOneAnalysis,
  stepTwoAnalysis,
  isStepTwoAnalysisFetching,
  isStepOneAnalysisFetching,
  fetchStepOneShortTermAnalysis,
  fetchStepTwoShortTermAnalysis,
  ...props
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedAnalysisGroup, setSelectedAnalysisGroup] = useState([]);
  const [selectedTable, setSelectedTable] = useState(1);
  const [isRunAnalysisDialogOpen, setIsRunAnalysisDialogOpen] = useState(false);
  const [accountGroupId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [addAnalysisName, setAddAnalysisName] = useState('');
  const [balanceAdjustments, setBalanceAdjustments] = useState([
    { date: null, value: '' },
  ]);
  const [stepToRun, setStepToRun] = useState(1);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    fetchStepOneShortTermAnalysis(clientId);
    fetchStepTwoShortTermAnalysis(clientId);
  }, [fetchStepOneShortTermAnalysis, fetchStepTwoShortTermAnalysis, clientId]);

  const handleSetStepToRun = (number) => setStepToRun(number);

  const handleDownload = async (clientId, fileName) => {
    setIsDownloading(true);
    const presignedURL = await downloadFile(clientId, fileName)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Download PDF',
          intent: Intent.DANGER,
        });
        setIsDownloading(false);
      });

    if (presignedURL) {
      const titleBeginning = 8;
      const name = presignedURL.substring(
        titleBeginning,
        presignedURL.indexOf('?')
      );

      delete axios.defaults.headers.common['Authorization'];
      await axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => downloadFiles(res, name))
        .catch((err) => console.error(err))
        .finally(() => setIsDownloading(false));
    }
  };

  const handleDeleteDialogOpen = (analysisGroup, selectedTable) => {
    setSelectedTable(selectedTable);
    setSelectedAnalysisGroup(analysisGroup);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setSelectedTable(1);
    setSelectedAnalysisGroup([]);
    setIsDeleteDialogOpen(false);
  };

  const handleEditAndRerun = (row) => {
    setSelectedRow(row);
    setGroupName(row.groupName);
    setAddAnalysisName(row.shortTermModelName);
    // set date range
    setStartDate(row.startDateRange);
    setEndDate(row.endDateRange);
    // set balance adjustments
    if (row.balanceAdjustments.length >= 1) {
      const formattedBalanceAdjustments = row.balanceAdjustments.map(
        (balanceAdjustment) => {
          // format date from 'YYYY-MM-DD' to 'MM-DD-YYYY'
          // then change the string into a date object so it can be
          // set in the date field of balance adjustments
          let date;
          if (balanceAdjustment.date !== null) {
            const month = balanceAdjustment.date.slice(5, 7);
            const year = balanceAdjustment.date.slice(0, 4);
            const day = balanceAdjustment.date.slice(8);
            date = stringToDate(`${month}-${day}-${year}`);
          }
          // format value into string with two decimal places
          const value = Number(balanceAdjustment.amount).toFixed(2).toString();

          return { date, value };
        }
      );
      setBalanceAdjustments(formattedBalanceAdjustments);
    }
    handleOpenRunAnalysisDialog(true);
  };

  const handleOpenRunAnalysisDialog = (isEditAndRerun) => {
    if (!isEditAndRerun) {
      setGroupName('');
      setAddAnalysisName('');
      setStartDate(null);
      setEndDate(null);
      setBalanceAdjustments([{ date: null, value: '' }]);
    }
    setIsRunAnalysisDialogOpen(true);
  };

  const handleCloseRunAnalysisDialog = () => {
    setSelectedRow({});
    setIsRunAnalysisDialogOpen(false);
  };

  const handleDelete = () => {
    setIsDeleting(true);

    const shortTermModelId = selectedAnalysisGroup.shortTermModelId;

    let deleteFunction = deleteShortTermStepOne;
    let fetchTableFunction = fetchStepOneShortTermAnalysis;
    let errorTable = 'step 1';

    if (selectedTable === 2) {
      deleteFunction = deleteShortTermStepTwo;
      fetchTableFunction = fetchStepTwoShortTermAnalysis;
      errorTable = 'step 2';
    }

    deleteFunction(shortTermModelId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : `Failed to delete analysis group from ${errorTable} table`,
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchTableFunction(clientId);
        setIsDeleting(false);
        handleDeleteDialogClose();
      });
  };

  return (
    <>
      {isStepOneAnalysisFetching ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      ) : (
        <>
          <StepOneAnalysisTable
            appUser={appUser}
            stepOneAnalysis={stepOneAnalysis}
            isFetching={isStepOneAnalysisFetching}
            handleDownload={handleDownload}
            handleOpenRunAnalysisDialog={handleOpenRunAnalysisDialog}
            clientId={clientId}
            isDownloading={isDownloading}
            handleDeleteDialogOpen={handleDeleteDialogOpen}
            handleEditAndRerun={handleEditAndRerun}
            handleSetStepToRun={handleSetStepToRun}
            {...props}
          />
          <StepTwoAnalysisTable
            appUser={appUser}
            stepTwoAnalysis={stepTwoAnalysis}
            isFetching={isStepTwoAnalysisFetching}
            rangeFilter={getOneYearRange()}
            handleDeleteDialogOpen={handleDeleteDialogOpen}
            clientId={clientId}
            {...props}
          />
          <RunShortTermAnalysisDialog
            isOpen={isRunAnalysisDialogOpen}
            handleDialogClose={handleCloseRunAnalysisDialog}
            clientId={clientId}
            accountGroupId={accountGroupId}
            addAnalysisName={addAnalysisName}
            startDate={startDate}
            endDate={endDate}
            balanceAdjustments={balanceAdjustments}
            groupName={groupName}
            stepToRun={stepToRun}
            selectedRow={selectedRow}
            {...props}
          />
          <ShortTermDeleteDialogBox
            title={'Delete Analysis Group'}
            isOpen={isDeleteDialogOpen}
            isDeleting={isDeleting}
            handleClose={handleDeleteDialogClose}
            handleDelete={handleDelete}
            clientId={clientId}
            {...props}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  stepOneAnalysis: state.analysis.stepOneAnalysis,
  stepTwoAnalysis: state.analysis.stepTwoAnalysis,
  isStepTwoAnalysisFetching: state.analysis.isStepTwoAnalysisFetching,
  isStepOneAnalysisFetching: state.analysis.isStepOneAnalysisFetching,
});

const ShortTermModel = connect(mapStateToProps, {
  fetchStepOneShortTermAnalysis,
  fetchStepTwoShortTermAnalysis,
})(ShortTermModelBase);

export default ShortTermModel;
