import * as api from '../api/treasuryServices';

export const fetchStagingFees = (batchId) => ({
  type: 'FETCH_STAGING_FEES',
  payload: api.fetchStagingFees(batchId),
});

export const fetchStagingBalances = (batchId) => ({
  type: 'FETCH_STAGING_BALANCES',
  payload: api.fetchStagingBalances(batchId),
});

//This action will fired up when user closed the StagingTreasuryServicesDialog
//This is to reset the value on the reducer
export const closeStagingDialogs = () => ({
  type: 'CLOSE_DIALOG',
});

export const fetchStandardizedDescriptions = () => ({
  type: 'FETCH_STANDARDIZED_DESCRIPTIONS',
  payload: api.fetchStandardizedDescriptions(),
});

export const fetchBankLevelExceptionList = () => ({
  type: 'FETCH_BANK_LEVEL_EXCEPTION_LIST',
  payload: api.fetchBankLevelExceptionList,
});

export const fetchClientLevelExceptionList = () => ({
  type: 'FETCH_CLIENT_LEVEL_EXCEPTION_LIST',
  payload: api.fetchClientLevelExceptionList,
});

export const fetchFeeCategoryList = () => ({
  type: 'FETCH_FEE_CATEGORY_LIST',
  payload: api.fetchFeeCategoryList(),
});

export const fetchStagingAccountBalances = (batchId) => ({
  type: 'FETCH_STAGING_ACCOUNT_BALANCES',
  payload: api.fetchStagingAccountBalances(batchId),
});
