import { Form, Formik } from 'formik';
import React from 'react';
import ModalTextArea from '../../formik-text-fields/ModalTextArea';
import RadioPagination from '../../RadioPagination';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import { AlignCenter } from '../../styled-components';
import FormActions from '../FormActions';
import * as Yup from 'yup';

// This function will return item list of given range
// for the value select field
const getValueList = (range) => {
  return Array.from(
    { length: range[1] + 1 - range[0] },
    (v, k) => k + range[0]
  ).map((item) => ({ value: String(item), label: String(item) }));
};

const schema = Yup.object().shape({
  valueScore: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  liquidityProficiency: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  warnickRate: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  cashflow: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  investment: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  valueScoreComment: Yup.string(),
  liquidityProficiencyComment: Yup.string(),
  warnickRateComment: Yup.string(),
  cashflowComment: Yup.string(),
  investmentComment: Yup.string(),
});

const transformValues = (values) => {
  values.valueScoreComments = values.valueScoreComment;
  values.liquidityProfComments = values.liquidityProficiencyComment;
  values.liquidityProf = values.liquidityProficiency;
  values.warnickRateComments = values.warnickRateComment;
  values.cashflowComments = values.cashflowComment;
  values.investmentComments = values.investmentComment;
  delete values.valueScoreComment;
  delete values.liquidityProficiencyComment;
  delete values.warnickRateComment;
  delete values.cashflowComment;
  delete values.investmentComment;
  delete values.liquidityProficiency;
  return values;
};

const EditCashVestForm = ({
  cashVestSubScores,
  subScores,
  handleEdit,
  closeDialog,
  pageNumber,
  setPageNumber,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column'>
        <Formik
          initialValues={{
            valueScore: cashVestSubScores.valueScore || '',
            valueScoreComment: cashVestSubScores.valueScoreComment || '',
            liquidityProficiency: cashVestSubScores.liquidityProficiency || '',
            liquidityProficiencyComment:
              cashVestSubScores.liquidityProficiencyComment || '',
            warnickRate: cashVestSubScores.warnickRate || '',
            warnickRateComment: cashVestSubScores.warnickRateComment || '',
            cashflow: cashVestSubScores.cashflow || '',
            cashflowComment: cashVestSubScores.cashflowComment || '',
            investment: cashVestSubScores.investment || '',
            investmentComment: cashVestSubScores.investmentComment || '',
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            handleEdit(transformValues(values));
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter justifyContent='flex-start' padding='20px 50px'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  value={
                    pageNumber === 0
                      ? values.valueScore
                      : pageNumber === 1
                      ? values.liquidityProficiency
                      : pageNumber === 2
                      ? values.warnickRate
                      : pageNumber === 3
                      ? values.cashflow
                      : values.investment
                  }
                  labelName={subScores[pageNumber].label}
                  items={getValueList(subScores[pageNumber].valueRange)}
                  name={subScores[pageNumber].valueName}
                  useLabel
                />
              </AlignCenter>
              <AlignCenter justifyContent='flex-start' padding='20px 50px'>
                <ModalTextArea
                  label='Commentary'
                  name={subScores[pageNumber].commentName}
                  value={
                    pageNumber === 0
                      ? values.valueScoreComment
                      : pageNumber === 1
                      ? values.liquidityProficiencyComment
                      : pageNumber === 2
                      ? values.warnickRateComment
                      : pageNumber === 3
                      ? values.cashflowComment
                      : values.investmentComment
                  }
                />
              </AlignCenter>
              <div style={{ marginTop: '30px' }}>
                <RadioPagination
                  pages={5}
                  onClickSetPage={setPageNumber}
                  page={pageNumber}
                />
              </div>
              <FormActions isSubmitting={isSubmitting} onClose={closeDialog} />
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default EditCashVestForm;
