import React from 'react';
import { Label, FlexColumn, BlueprintInputWrapper } from '../styled-components';
import { Button, NonIdealState, MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';

const MultiSelectField = ({
  selectedItems,
  items,
  setSelectedItems,
  label,
  wide,
  fill,
}) => {
  items = items.map((item) => item.label);
  //Renders input tag
  const tagRenderer = (item) => {
    return item;
  };

  //Removes the item from selected list
  const deselectItem = (item) => {
    let index = selectedItems.indexOf(item);
    selectedItems.splice(index, 1);
    setSelectedItems(selectedItems);
  };

  //Adds item to the selected list
  const selectItem = (item) => {
    selectedItems.push(item);
  };

  //Handle selecting items
  const handleItemSelect = (item) => {
    if (!isItemSelected(item)) {
      selectItem(item);
    } else {
      deselectItem(item);
    }
  };

  //Handle clicking the large right cross button
  const handleClear = () => {
    setSelectedItems([]);
  };

  //Jxs for right element on inputProps
  const clearButton = (
    <Button icon='cross' minimal={true} onClick={handleClear} />
  );

  //Removes selected element on clicking items cross
  const handleTagRemove = (item) => {
    deselectItem(item);
  };

  //Returns true if item is selected
  const isItemSelected = (item) => {
    return selectedItems.length && selectedItems.indexOf(item) !== -1;
  };

  // this for the select fields with filters
  const filterList = (query, items) => {
    return items.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  //Renders items menu on popover
  const multiSelectRenderItems = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        icon={isItemSelected(item) ? 'tick' : 'blank'}
        key={item}
        onClick={handleClick}
        text={
          label === 'Assign Clients'
            ? item.substr(0, item.lastIndexOf('-'))
            : item
        }
        label={
          label === 'Assign Clients'
            ? item.substr(item.lastIndexOf('-') + 1)
            : null
        }
        shouldDismissPopover={false}
      />
    );
  };

  return (
    <FlexColumn style={wide ? { width: '100%' } : {}}>
      <Label>{label}</Label>
      <BlueprintInputWrapper>
        <MultiSelect
          items={items}
          itemRenderer={multiSelectRenderItems}
          itemPredicate={filterList}
          onItemSelect={handleItemSelect}
          tagRenderer={tagRenderer}
          scrollToActiveItem={true}
          placeholder='Select Multiple Items'
          noResults={<NonIdealState title='No Results Found...' />}
          popoverProps={{ minimal: true }}
          resetOnSelect={true}
          tagInputProps={{
            onRemove: handleTagRemove,
            rightElement: clearButton,
          }}
          selectedItems={selectedItems}
          fill={fill}
        ></MultiSelect>
      </BlueprintInputWrapper>
    </FlexColumn>
  );
};

export default MultiSelectField;
