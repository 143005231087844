import React, { useMemo } from 'react';
import { NameLink, ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import {
  checkPermissions,
  localDateFormatter,
  amountFormatter,
} from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const InvestmentGroupsTable = ({
  appUser,
  investmentGroups,
  isFetching,
  handleDeleteDialogOpen,
  handleEditDialogOpen,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Group ID' />
        ),
        accessor: 'groupId',
        width: '8%',
        sortType: 'basic',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Group Name' />
        ),
        accessor: 'groupName',
        width: '40%',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        Cell: ({ cell: { value }, row }) => {
          return (
            <NameLink
              to={`/investment-management/investment-group/${row.original.groupId}`}
            >
              {value}
            </NameLink>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Investments' />
        ),
        accessor: 'countInvestments',
        width: '10%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Settlement Total' />
        ),
        accessor: 'settlementAmountSum',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        width: '15%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Updated At' />
        ),
        accessor: 'updatedAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        Cell: ({ cell: { value } }) => localDateFormatter(value),
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        sticky: 'right',
        Cell: ({ row }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.EDIT_INVESTMENT_GROUPS,
                  ]) && (
                    <MenuItem
                      text='Edit'
                      onClick={() => handleEditDialogOpen(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.DELETE_INVESTMENT_GROUPS,
                  ]) && (
                    <>
                      <Menu.Divider />
                      <MenuItem
                        text='Delete'
                        onClick={() => handleDeleteDialogOpen(row.original)}
                        style={{
                          color: `${colors.red}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                          paddingBottom: ' 5px',
                          marginBottom: '10px',
                        }}
                      />
                    </>
                  )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_INVESTMENT_GROUPS,
          permissions.DELETE_INVESTMENT_GROUPS,
          permissions.EDIT_INVESTMENT_GROUPS,
        ]),
      },
    ],
    [appUser, handleDeleteDialogOpen, handleEditDialogOpen]
  );

  return (
    <TableContainer padding='0rem'>
      <MakeTable
        data={investmentGroups}
        appUser={appUser}
        columns={columns}
        tableTitle='Investment Groups'
        isFetching={isFetching}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        {...props}
      />
    </TableContainer>
  );
};

export default InvestmentGroupsTable;
