import React, { useState } from 'react';
import SignIn from '../components/auth/SignIn';
import {
  HeadingText,
  LoginContainer,
  LoginLogoContainer,
  AlignCenter,
  LoginFormContainer,
  LogoBox,
  LoginResponsiveContainer,
} from '../components/styled-components';
import ForgotPassword from '../components/auth/ForgotPassword';
import EmailConfirm from '../components/auth/EmailConfirm';
import { Auth } from 'aws-amplify';
import { fetchAppUser } from '../actions/auth';
import { fetchCognitoUser } from '../api/auth';
import { connect } from 'react-redux';
import logo from '../assets/three-plus-one-logo-white-text.png';

const LoginBase = (props) => {
  const [username] = useState('');
  const [password] = useState('');
  const [email] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [showSetupMfa, setShowSetupMfa] = useState(false);
  const [userObject, setUserObject] = useState('');
  const [mfaToken] = useState('');
  const [showEmailConfirm, setShowEmailConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [cognitoError] = useState('Invalid username or password.');
  const [mfaTokenError] = useState('Invalid MFA token.');
  const [qrStr, setQrStr] = useState('');

  const handleSignIn = async ({ username, password }) => {
    try {
      const user = await Auth.signIn(username, password);
      // if MFA hasn't been setup
      // checks if this does not exist because
      // setting mfa to optional in cognito will not give you a challengeName
      if (!user.challengeName) {
        const code = await Auth.setupTOTP(user);
        let env =
          process.env.NODE_ENV === 'production'
            ? ''
            : `${process.env.NODE_ENV}`;

        // uppercase first letter of environment
        if (env) env = '-' + env.charAt(0).toUpperCase() + env.slice(1);

        // creates the URL to generate the QR code
        setQrStr(
          `otpauth://totp/cashMax${env}:${username}?secret=${code}&issuer=cashMax${env}`
        );

        // passes the user object to the MFA auth page
        setUserObject(user);

        // show the page to set up MFA authenticator
        setShowSetupMfa(true);
      } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // passes the user object to the MFA auth page
        setUserObject(user);

        // show the page to enter mfa token
        setShowMfa(true);
      }
      setShowError(false);
    } catch (error) {
      setShowError(true);
    }
  };

  const handleMfaAuth = async ({ userObject, mfaToken }) => {
    const { fetchAppUser } = props;

    try {
      // when MFA is being setup the first time
      // checks if this does not exist because
      // setting mfa to optional in cognito will not give you a challengeName
      if (!userObject.challengeName) {
        await Auth.verifyTotpToken(userObject, mfaToken);
        await Auth.setPreferredMFA(userObject, 'TOTP');
        const cognitoUser = await fetchCognitoUser().catch((err) => {
          console.error(err);
        });

        if (cognitoUser) {
          const appUser = await fetchAppUser()
            .then((res) => res.value.data)
            .catch((err) => console.error(err));

          if (cognitoUser && appUser) {
            props.history.push('/dashboard');
          }
        }
      }
      // when the user enters the MFA token
      else if (userObject.challengeName === 'SOFTWARE_TOKEN_MFA') {
        await Auth.confirmSignIn(userObject, mfaToken, 'SOFTWARE_TOKEN_MFA');

        const cognitoUser = await fetchCognitoUser().catch((err) => {
          console.error(err);
        });

        if (cognitoUser) {
          const appUser = await fetchAppUser()
            .then((res) => res.value.data)
            .catch((err) => console.error(err));
          if (cognitoUser && appUser) {
            props.history.push('/dashboard');
          }
        }
      }
    } catch (error) {
      // Token is not verified
      setShowError(true);
    }
  };

  const handleForgotPassword = async ({ email }) => {
    try {
      await Auth.forgotPassword(email, {domain: 'cashmax.us'});
      setForgotPassword(false);
      setShowEmailConfirm(true);
    } catch (error) {
      setForgotPassword(false);
      setShowEmailConfirm(true);
    }
  };

  const showForgotPasswordModal = () => {
    setForgotPassword(true);
  };

  const backToLogin = () => {
    setShowEmailConfirm(false);
    setForgotPassword(false);
    setShowMfa(false);
    setShowSetupMfa(false);
  };

  return (
    <LoginContainer>
      <LoginLogoContainer>
        <LogoBox alt='company logo' src={logo} />
      </LoginLogoContainer>
      <AlignCenter width='67%' flexDirection='column'>
        <LoginResponsiveContainer>
          {!showEmailConfirm && (
            <div>
              <HeadingText>
                {!forgotPassword ? 'Welcome Back' : 'Password Reset'}
              </HeadingText>
            </div>
          )}
          {!showEmailConfirm && (
            <LoginFormContainer flexDirection='column'>
              {!forgotPassword && !showEmailConfirm && (
                <SignIn
                  username={username}
                  password={password}
                  handleSignIn={handleSignIn}
                  handleMfaAuth={handleMfaAuth}
                  showForgotPasswordModal={showForgotPasswordModal}
                  showMfa={showMfa}
                  showSetupMfa={showSetupMfa}
                  userObject={userObject}
                  qrStr={qrStr}
                  mfaToken={mfaToken}
                  backToLogin={backToLogin}
                  showError={showError}
                  cognitoError={cognitoError}
                  mfaTokenError={mfaTokenError}
                />
              )}

              {forgotPassword && (
                <ForgotPassword
                  email={email}
                  backToLogin={backToLogin}
                  handleForgotPassword={handleForgotPassword}
                />
              )}
            </LoginFormContainer>
          )}
          {showEmailConfirm && <EmailConfirm backToLogin={backToLogin} />}
        </LoginResponsiveContainer>
      </AlignCenter>
    </LoginContainer>
  );
};

const mapStateToProps = (state) => ({
  appUser: state.auth.appUser,
});

const Login = connect(mapStateToProps, { fetchAppUser })(LoginBase);

export default Login;
