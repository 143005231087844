import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import InvestmentForm from '../../forms/investment-forms/InvestmentForm';
import { fetchSingleInvestmentGroups } from '../../../api/investments';

const InvestmentDialog = ({
  isOpen,
  title,
  closeDialog,
  handleEditInvestment,
  investment,
  investmentTypes,
  allGroups,
  isStagingInvestment,
}) => {
  const [clientAccountRelationship, setClientAccountRelationship] = useState(
    ''
  );
  const [faceValue, setFaceValue] = useState(0);
  const [maturityDate, setMaturityDate] = useState(null);
  const [investmentTypeName, setInvestmentTypeName] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [liquidityLevel, setLiquidityLevel] = useState('');
  const [daysUntilMaturity, setDaysUntilMaturity] = useState(0);
  const [investmentId, setInvestmentId] = useState('');
  const [callDate, setCallDate] = useState(null);
  const [settlementDate, setSettlementDate] = useState(null);
  const [settlementAmount, setSettlementAmount] = useState(0);
  const [interestAtMaturity, setInterestAtMaturity] = useState(0);
  const [premiumDiscount, setPremiumDiscount] = useState('');
  const [bank, setBank] = useState('');
  const [investmentGroupIdList, setInvestmentGroupIdList] = useState([]);
  const [fund, setFund] = useState('');
  const [couponRate, setCouponRate] = useState(0);

  useEffect(() => {
    if (investment) {
      setClientAccountRelationship(investment.clientAccountRelationship || '');
      setFaceValue(investment.faceValue || 0);
      setMaturityDate(investment.maturityDate || null);
      setInvestmentTypeName(investment.investmentTypeName || '');
      setInterestRate(investment.interestRate || '');
      setLiquidityLevel(investment.liquidityLevel || '');
      setDaysUntilMaturity(investment.daysUntilMaturity || 0);
      setInvestmentId(investment.investmentId || '');
      setCallDate(investment.callDate || null);
      setSettlementDate(investment.settlementDate || null);
      setSettlementAmount(investment.settlementAmount || undefined);
      setInterestAtMaturity(investment.interestAtMaturity || undefined);
      setPremiumDiscount(investment.premiumDiscount || undefined);
      setBank(investment.bank || '');
      setCouponRate(investment.couponRate || undefined);
      investment.id &&
        fetchSingleInvestmentGroups(investment.id).then((res) => {
          setInvestmentGroupIdList(res.data);
        });
      setFund(investment.fund || '');
    }
  }, [investment]);

  const closeReset = () => {
    closeDialog();
    setClientAccountRelationship('');
    setFaceValue(0);
    setMaturityDate(null);
    setInvestmentTypeName('');
    setInterestRate('');
    setLiquidityLevel('');
    setDaysUntilMaturity('');
    setInvestmentId('');
    setCallDate(null);
    setSettlementDate(null);
    setSettlementAmount(undefined);
    setInterestAtMaturity(undefined);
    setCouponRate(undefined);
    setPremiumDiscount('');
    setBank('');
    setInvestmentGroupIdList([]);
    setFund('');
  };

  return (
    <Dialog
      className='medium-dialog'
      isOpen={isOpen}
      onClose={closeReset}
      title={title}
      isCloseButtonShown={false}
      style={{ minWidth: '1200px' }}
    >
      <InvestmentForm
        closeDialog={closeReset}
        clientAccountRelationship={clientAccountRelationship}
        faceValue={faceValue}
        maturityDate={maturityDate}
        investmentTypeName={investmentTypeName}
        investmentTypes={investmentTypes}
        interestRate={interestRate}
        liquidityLevel={liquidityLevel}
        daysUntilMaturity={daysUntilMaturity}
        investmentId={investmentId}
        callDate={callDate}
        settlementDate={settlementDate}
        settlementAmount={settlementAmount}
        interestAtMaturity={interestAtMaturity}
        premiumDiscount={premiumDiscount}
        bank={bank}
        handleEditInvestment={handleEditInvestment}
        investmentGroupIdList={investmentGroupIdList}
        setInvestmentGroupIdList={setInvestmentGroupIdList}
        allGroups={allGroups}
        fund={fund}
        couponRate={couponRate}
        isStagingInvestment={isStagingInvestment}
      />
    </Dialog>
  );
};

export default InvestmentDialog;
