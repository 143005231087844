import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Intent } from '@blueprintjs/core';
import InvestmentTypesForm from '../../forms/global-settings-forms/InvestmentTypesForm';
import {
  createInvestmentType,
  updateInvestmentType,
} from '../../../api/administration';
import AppToast from '../../Toast';
import {
  fetchInvestmentTypes,
  fetchSingleInvestmentType,
} from '../../../actions/administration';

const InvestmentTypesDialogBase = ({
  isOpen,
  title,
  handleClose,
  investmentType,
  fetchInvestmentTypes,
  fetchSingleInvestmentType,
}) => {
  const handleCreate = (investmentType) => {
    createInvestmentType(investmentType)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchInvestmentTypes();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create Investment Type.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleClose());
  };

  const handleEdit = (investmentType, investmentTypeId) => {
    updateInvestmentType(investmentType, investmentTypeId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit Investment Type.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchSingleInvestmentType(investmentTypeId);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <InvestmentTypesForm
        title={title}
        investmentType={investmentType}
        onClose={handleClose}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
      />
    </Dialog>
  );
};

const mapStateToProps = () => ({});

const InvestmentTypesDialog = connect(mapStateToProps, {
  fetchInvestmentTypes,
  fetchSingleInvestmentType,
})(InvestmentTypesDialogBase);

export default InvestmentTypesDialog;
