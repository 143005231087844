import React, { useState, useEffect, useRef } from 'react';
import {
  AlignCenter,
  FlexColumn,
  Label,
  Input,
  InputWrapper,
} from '../../styled-components';
import { connect } from 'react-redux';
import { Dialog } from '@blueprintjs/core';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import { fetchTransactionKeywords } from '../../../actions/transactions';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import SelectField from '../../../components/select-fields/SelectField';
import {
  dateToFilterText,
  textToFilterText,
  multiSelectToFilterText,
} from '../../../utils/functions';
import TableFilterActions from './TableFilterActions';

const TransactionFilterDialogBoxBase = ({
  isOpen,
  title,
  handleClose,
  setAllFilters,
  transactionsKeywords,
  fetchTransactionKeywords,
  setFilterSummary,
  updatePaginate,
  pageSize,
  pageIndex,
  searchTerm,
  setGetFilters,
  sortOrder,
  stopFunc,
  prevSearchTerm,
  prevSort,
  checkSearchValue,
  moreNextPrev,
  setMoreNextPrev,
  runFilterPaginate,
}) => {
  const [dateRange, setDateRange] = useState([]);
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [amountFilter, setAmountFilter] = useState('');
  const [keywords, setKeywords] = useState([]);

  // ref for filters to keep values from being erased.
  // set to empty array on clear and before each new apply so it doesn't stack.
  const keepFilters = useRef([]);
  const oldSortValue = useRef(sortOrder);

  useEffect(() => {
    if (
      stopFunc === false &&
      runFilterPaginate === false &&
      oldSortValue.current === sortOrder &&
      moreNextPrev === false
    ) {
      return;
    }
    if (stopFunc === true) {
      checkSearchValue(prevSearchTerm);
    }
    // run pagination if user clicks page change before doing filter, sort or search
    // if user types into search first, without sorting, changing page/rows, or filtering call from global filter file
    // if user types then sorts or filters or changes page/rows, run pagination here
    // if user sorts first, stopFunc will be false and prevSearchTerm will equal current searchTerm
    // if user sorts first then changes page/rows, run pagination here
    // initial page load will have stopFunc be false, prevSearch and searchTerm be equal, and sort values with 0 length, so run pagination here
    if (
      (moreNextPrev || (stopFunc === false && prevSearchTerm === searchTerm)) &&
      (oldSortValue.current !== sortOrder ||
        (moreNextPrev && oldSortValue.current === sortOrder) ||
        (oldSortValue.current.length === 0 && sortOrder.length === 0))
    ) {
      updatePaginate(
        { pageSize, pageIndex },
        keepFilters.current,
        searchTerm,
        sortOrder
      );
      oldSortValue.current = sortOrder;
      setMoreNextPrev(false);
    }
  }, [
    updatePaginate,
    pageIndex,
    pageSize,
    searchTerm,
    sortOrder,
    stopFunc,
    prevSearchTerm,
    prevSort,
    checkSearchValue,
    moreNextPrev,
    setMoreNextPrev,
    runFilterPaginate,
  ]);

  useEffect(() => {
    fetchTransactionKeywords();
  }, [fetchTransactionKeywords]);

  const amountFilterTypes = [
    { value: 'Any', label: 'Any' },
    { value: 'Debit', label: 'Debit' },
    { value: 'Credit', label: 'Credit' },
  ];

  const getFilterSummary = () => {
    const filters = [];
    dateRange.length && filters.push(dateToFilterText(dateRange, 'Date'));
    amountFilter &&
      filters.push(textToFilterText(amountFilter, 'Transaction Type'));
    keywords.length &&
      filters.push(multiSelectToFilterText(keywords, 'Keywords'));
    descriptionFilter &&
      filters.push(textToFilterText(descriptionFilter, 'Description'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    keepFilters.current = [];
    // for server side pagination. send backend the filter data as well
    dateRange.length &&
      keepFilters.current.push(dateToFilterText(dateRange, 'transactionDate'));
    amountFilter &&
      keepFilters.current.push(textToFilterText(amountFilter, 'amount'));
    keywords.length &&
      keepFilters.current.push(multiSelectToFilterText(keywords, 'keyword'));
    descriptionFilter &&
      keepFilters.current.push(
        textToFilterText(descriptionFilter, 'transactionDescription')
      );
    setGetFilters(keepFilters.current);
    updatePaginate(
      { pageSize, pageIndex },
      keepFilters.current,
      searchTerm,
      sortOrder
    );
    handleClose();
  };

  const handleClearFilters = () => {
    setDateRange([]);
    setAmountFilter('');
    setKeywords([]);
    setDescriptionFilter('');
    handleClose();
    setFilterSummary([]);
    setAllFilters([]);
    setGetFilters([]);
    // if user clears filters, need to send just size and index to refetch correct data
    keepFilters.current = [];
    updatePaginate(
      { pageSize, pageIndex },
      keepFilters.current,
      searchTerm,
      sortOrder
    );
  };

  const handleDescriptionChange = (event) => {
    setDescriptionFilter(event.target.value);
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={title}
        onClose={handleClose}
        isCloseButtonShown={false}
      >
        <div style={{ padding: '30px 0 20px 0' }}>
          <AlignCenter justifyContent='space-evenly' padding='10px 0'>
            <DateSelectPlainField
              labelName='Date'
              dateRange={dateRange}
              handleDateChange={setDateRange}
            />
          </AlignCenter>
          <AlignCenter justifyContent='space-evenly' padding='10px 0'>
            <FlexColumn>
              <Label>Filter Description</Label>
              <InputWrapper>
                <Input
                  type='text'
                  placeholder='Descriptions'
                  onChange={(event) => handleDescriptionChange(event)}
                  value={descriptionFilter || ''}
                />
              </InputWrapper>
            </FlexColumn>
            <SelectField
              selectValueFromList={setAmountFilter}
              value={amountFilter}
              labelName={'Filter Transactions'}
              items={amountFilterTypes}
            />
          </AlignCenter>
          <div style={{ padding: '0 3rem' }}>
            <MultiSelectField
              setSelectedItems={setKeywords}
              items={transactionsKeywords}
              selectedItems={keywords}
              label='Filter Keywords'
            />
          </div>
        </div>
        <TableFilterActions
          handleClose={handleClose}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  transactionsKeywords: state.transactions.allTransactionKeywords,
});

const TransactionFilterDialogBox = connect(mapStateToProps, {
  fetchTransactionKeywords,
})(TransactionFilterDialogBoxBase);

export default TransactionFilterDialogBox;
