import React from 'react';
import {
  ProfileHeadingText,
  BoldText,
  RegularButton,
  TextValue,
} from '../../styled-components';
import { amountFormatter } from '../../../utils/functions';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';
import styled from 'styled-components';
import ClientAccountBalanceGraph from '../../../components/widgets/ClientAccountBalanceGraph';

const AccountInfoSection = ({
  appUser,
  account,
  openEditAccountDialog,
  accountGraph,
  isAccountGraphFetching,
}) => {
  return (
    <>
      <ProfileHeadingText>Account: {account.accountName}</ProfileHeadingText>
      <MainDiv>
        <InfoContainer>
          <InfoWrapper>
            <InfoColumn>
              <BoldText>
                Number: <TextValue>{account.accountNumber}</TextValue>
              </BoldText>
              <BoldText>
                Alias: <TextValue>{account.accountAlias}</TextValue>
              </BoldText>
              <BoldText>
                Type: <TextValue>{account.accountType}</TextValue>
              </BoldText>
              <BoldText>
                Bank: <TextValue>{account.bankName}</TextValue>
              </BoldText>
              <BoldText>
                Start Balance:{' '}
                <TextValue>{amountFormatter(account.accountBalance)}</TextValue>
              </BoldText>
              <BoldText>
                Last Transaction Date:{' '}
                <TextValue>
                  {account.lastTransactionDate
                    ? account.lastTransactionDate
                    : 'N/A'}
                </TextValue>
              </BoldText>
              <BoldText>
                Balance Date:{' '}
                <TextValue>
                  {account.accountBalanceDate
                    ? account.accountBalanceDate
                    : 'N/A'}
                </TextValue>
              </BoldText>
            </InfoColumn>
            <InfoColumn>
              <BoldText>
                Account Closed: <TextValue>{account.accountClosed}</TextValue>
              </BoldText>
              <BoldText>
                Account Closed Date:{' '}
                <TextValue>
                  {account.accountDateClosed
                    ? account.accountDateClosed
                    : 'N/A'}
                </TextValue>
              </BoldText>
              <BoldText>
                Data Gathering Frequency: <TextValue>{account.dataGatheringFrequency}</TextValue>
              </BoldText>
              <BoldText>
                Collection Type: <TextValue>{account.collectionType === true ? 'Sent In' : 'Direct Access'}</TextValue>
              </BoldText>
            </InfoColumn>
          </InfoWrapper>
          <ElementWrapper>
            <InfoColumn>
              {appUser
                ? checkPermissions(appUser.permList, [
                  permissions.EDIT_ACCOUNTS,
                ]) && (
                  <RegularButton
                    margin='30px 0'
                    width='95%'
                    fontSize='1.125rem'
                    onClick={() => openEditAccountDialog(account)}
                  >
                    Edit Account Information
                  </RegularButton>
                )
                : null}
            </InfoColumn>
          </ElementWrapper>
        </InfoContainer>

        <GraphContainer>
          <GraphWrapper>
            <ClientAccountBalanceGraph
              isAccountGraphFetching={isAccountGraphFetching}
              accountGraph={accountGraph}
            />
          </GraphWrapper>
        </GraphContainer>
      </MainDiv>
    </>
  );
};

const MainDiv = styled.div`
  display: flex;
  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    flex-direction: column;
    margin-bottom: 15px;
  }
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .queue-button {
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    flex-direction: row;
  }
`;

const GraphContainer = styled.div`
  width: 100%;
`;

const GraphWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    width: 800px;
  }

  @media screen and (min-width: 1501px) and (max-width: 1600px) {
    width: 100%;
  }

  @media screen and (min-width: 1601px) {
    width: 700px;
  }
`;

const InfoColumn = styled.div`
  width: 300px;

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    width: 400px;

    .start-button {
      margin: 0 0 30px 0;
    }

    .edit-client-button {
      margin: 0 0 30px 0;
    }
  }
`;

export default AccountInfoSection;
