import React, { useState } from 'react';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
  MessageText,
} from '../../styled-components';
import { Formik, Form } from 'formik';
import colors from '../../../utils/colors';
import LoadSpinner from '../../LoadSpinner';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import { find } from 'lodash';
import * as Yup from 'yup';

const accountLinkSchema = Yup.object().shape({
  linkedToAccountId: Yup.string().required('Required!'),
});

const AccountLinkForm = ({
  title,
  setTitle,
  closeDialog,
  linkedToAccountId,
  clientAccounts,
  handleCreateAccountLink,
  handleEditAccountLink,
  handleDeleteAccountLink,
  appUser,
  isDeleting,
}) => {
  const [isDelete, setIsDelete] = useState(false);

  const handleSetDelete = () => {
    setTitle('Delete Account Link');
    setIsDelete(true);
  };

  const handleReturnToEdit = () => {
    setTitle('Edit Account Link');
    setIsDelete(false);
  };
  return (
    <>
      {!isDelete && (
        <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
          <Formik
            initialValues={{
              linkedToAccountId,
            }}
            onSubmit={(account) => {
              title === 'Add Account Link'
                ? handleCreateAccountLink(account)
                : handleEditAccountLink(account);
            }}
            validationSchema={accountLinkSchema}
            enableReinitialize={true}
            validateOnBlur={false}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AlignCenter
                  justifyContent='flex-start'
                  padding='0 0 30px 50px'
                >
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='linkedToAccountId'
                    value={
                      values.linkedToAccountId === ''
                        ? 'Account'
                        : find(clientAccounts, [
                            'value',
                            values.linkedToAccountId,
                          ]).label
                    }
                    items={clientAccounts}
                    labelName='Account'
                  />
                </AlignCenter>
                <AlignCenter
                  justifyContent={
                    title === 'Add Account Link' ? 'flex-end' : 'space-between'
                  }
                  padding='10px 20px 0 0'
                  borderTop={`1px solid ${colors.lightText}`}
                >
                  {title === 'Edit Account Link' &&
                    checkPermissions(appUser.permList, [
                      permissions.EDIT_ACCOUNT_LINKS,
                    ]) && (
                      <WhiteButton
                        justifyContent='flex-start'
                        margin='10px 10px 10px 20px'
                        type='button'
                        color={colors.red}
                        hoverColor={colors.redHover}
                        onClick={handleSetDelete}
                      >
                        DELETE ACCOUNT LINK
                      </WhiteButton>
                    )}
                  <AlignCenter
                    justifyContent='space-between'
                    width='fit-content'
                  >
                    <WhiteButton
                      justifyContent={
                        title === 'Add Account Link' ? 'flex-end' : ''
                      }
                      type='button'
                      onClick={closeDialog}
                    >
                      CLOSE
                    </WhiteButton>
                    <RegularButton
                      width='7rem'
                      display='flex'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      SAVE
                      {isSubmitting && (
                        <span style={{ marginLeft: '10px' }}>
                          <LoadSpinner size={20} />
                        </span>
                      )}
                    </RegularButton>
                  </AlignCenter>
                </AlignCenter>
              </Form>
            )}
          </Formik>
        </AlignCenter>
      )}

      {isDelete && (
        <>
          <MessageText style={{ padding: '30px 50px 20px' }}>
            Are you sure you want to delete the Account Link?
          </MessageText>
          <AlignCenter
            justifyContent='flex-end'
            padding='10px 20px 10px 0'
            borderTop={`1px solid ${colors.lightText}`}
            margin='50px 0 0 0'
          >
            <WhiteButton
              type='button'
              onClick={handleReturnToEdit}
              color={colors.red}
              hoverColor={colors.redHover}
            >
              RETURN TO EDIT
            </WhiteButton>
            <RegularButton
              padding='10px 30px'
              background={colors.red}
              backgroundHover={colors.redHover}
              onClick={handleDeleteAccountLink}
              disabled={isDeleting}
              display='flex'
            >
              DELETE
              {isDeleting && (
                <span style={{ marginLeft: '10px' }}>
                  <LoadSpinner size={20} />
                </span>
              )}
            </RegularButton>
          </AlignCenter>
        </>
      )}
    </>
  );
};

export default AccountLinkForm;
