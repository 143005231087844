import React, { useState, useEffect } from 'react';
import {
  MessageText,
  BorderComponentContainer,
  FlexColumn,
  RegularButton,
  WhiteButton,
  AlignCenter,
  FooterContainer,
  ResponsiveCenter,
  GlobalSettingsDescription,
} from '../styled-components';
import SelectField from '../../components/select-fields/SelectField';
import { getNumberWithOrdinal } from '../../utils/functions';
import LoadSpinner from '../../components/LoadSpinner';
import { updateUploadDate } from '../../api/administration';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';

const getDateItems = () => {
  const items = [];
  for (let i = 0; i < 24; i++) {
    const num = getNumberWithOrdinal(i + 1);
    const item = {
      value: num,
      label: num,
    };
    items.push(item);
  }
  return items;
};

const IngestionUploadDate = ({ dateLimit, isFetching, fetchUploadDate }) => {
  const [uploadDate, setUploadDate] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (dateLimit) {
      setUploadDate(dateLimit.globalSettingValue);
    }
  }, [dateLimit]);

  const resetDate = () => {
    setUploadDate(dateLimit.globalSettingValue);
  };

  const handleUpdate = () => {
    setIsUpdating(true);
    const globalSetting = { value: uploadDate };
    updateUploadDate(dateLimit.globalSettingId, globalSetting)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchUploadDate();
      })
      .catch((err) => {
        console.error(err);
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to update Data Ingestion upload date.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <ResponsiveCenter className={'global-setting'} minWidth={'654px'}>
      <GlobalSettingsDescription>
        <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
          Data Ingestion Upload Date
        </MessageText>
        <MessageText>
          Set the optimal date for files to be uploaded by.
        </MessageText>
      </GlobalSettingsDescription>

      <BorderComponentContainer
        padding={'0px'}
        width={'100%'}
        minWidth={'654px'}
      >
        <FlexColumn padding={'2rem'}>
          {isFetching ? (
            <LoadSpinner />
          ) : (
            <SelectField
              selectValueFromList={setUploadDate}
              value={uploadDate}
              labelName={'Upload Date'}
              items={getDateItems()}
            />
          )}
        </FlexColumn>

        <FooterContainer>
          <AlignCenter justifyContent='flex-end'>
            <WhiteButton type='button' onClick={resetDate}>
              CANCEL
            </WhiteButton>
            <RegularButton
              width='13rem'
              display='flex'
              onClick={handleUpdate}
              disabled={isFetching || isUpdating}
            >
              SAVE SETTINGS
            </RegularButton>
          </AlignCenter>
        </FooterContainer>
      </BorderComponentContainer>
    </ResponsiveCenter>
  );
};

export default IngestionUploadDate;
