import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import DialogDeleteActions from '../DialogDeleteActions';
import { connect } from 'react-redux';
import { RedText, MessageText } from '../../styled-components';
import { resolveNotes, restoreNote } from '../../../api/notes';
import AppToast from '../../Toast';
import {
  fetchAllNotes,
  fetchClientNotes,
  fetchResolveNotes,
  fetchAccountNotes,
  fetchBatchNotes,
} from '../../../actions/notes';

const NoteResolveDialogBoxBase = ({
  isOpen,
  handleClose,
  title,
  note,
  clientId,
  // If no selected notes it will default to an empty array.
  // So we can check the selectedNotes length
  selectedNotes = [],
  fetchAllNotes,
  fetchClientNotes,
  fetchResolveNotes,
  isViewNoteDialogOpen,
  handleCloseViewDialog,
  accountId,
  fetchAccountNotes,
  batchId,
  fetchBatchNotes,
  setResetInitialFilter,
  isDashboard,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const descriptionText = title.startsWith('Resolve')
    ? `Are you sure you want to resolve the selected
    ${selectedNotes.length ? ' notes' : ' note'}? Once the
    ${selectedNotes.length ? ' notes' : ' note'} ${
        selectedNotes.length > 1 ? ' have' : ' has'
      } been resolved, if you
    would like to access to it again, please contact an administrator.`
    : `Are you sure you want to restore ${
        selectedNotes.length > 1 ? ' these' : ' this'
      } ${selectedNotes.length ? ' notes' : ' note'}?`;

  const getNoteObject = () => {
    let ids;

    if (selectedNotes && selectedNotes.length > 0) {
      ids = selectedNotes.map((note) => note.noteId);
    } else {
      ids = [note.noteId];
    }

    // note coming from view note dialog
    if (note & !selectedNotes) ids = note.noteId;

    // not the prop note passed from view note dialog
    note = {
      ids,
    };
    return note;
  };

  const handleResolving = () => {
    setIsSubmitting(true);
    note = getNoteObject();
    resolveNotes(note)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to resolve note(s)',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        clientId && fetchClientNotes(clientId);
        accountId && fetchAccountNotes(accountId);
        batchId && fetchBatchNotes(batchId);
        isDashboard && fetchAllNotes();
        setIsSubmitting(false);
        handleClose();
        setResetInitialFilter(true);
        isViewNoteDialogOpen && handleCloseViewDialog();
      });
  };

  const handleRestore = () => {
    setIsSubmitting(true);
    note = getNoteObject();
    restoreNote(note)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to restore note(s)',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        fetchResolveNotes();
        setResetInitialFilter(true);
        handleClose();
        isViewNoteDialogOpen && handleCloseViewDialog();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        {title.startsWith('Resolve') && (
          <RedText>WARNING: This action can only be undone by an administrator.</RedText>
        )}
        <MessageText>{descriptionText}</MessageText>
      </div>
      <DialogDeleteActions
        deleteText={
          title.startsWith('Resolve') ? 'RESOLVE NOTE' : 'RESTORE NOTE'
        }
        isDeleting={isSubmitting}
        handleDelete={
          title.startsWith('Resolve') ? handleResolving : handleRestore
        }
        handleClose={handleClose}
        isPrimary={title.startsWith('Resolve') ? false : true}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const NoteResolveDialogBox = connect(mapStateToProps, {
  fetchAllNotes,
  fetchClientNotes,
  fetchResolveNotes,
  fetchAccountNotes,
  fetchBatchNotes,
})(NoteResolveDialogBoxBase);

export default NoteResolveDialogBox;
