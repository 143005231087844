import React from 'react';
import {
  LoginFormContainer,
  HeadingText,
  MessageText,
  ForgotText,
} from '../styled-components';
import { Intent, Icon } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';

const EmailConfirm = ({ backToLogin }) => {
  return (
    <>
      <HeadingText>Request Sent</HeadingText>
      <LoginFormContainer flexDirection='column' padding='5rem 4rem 4rem'>
        <Icon
          icon={iconValue.check}
          iconSize={100}
          intent={Intent.SUCCESS}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
        <MessageText>
          Password reset request has been sent. Please check your email for
          further instructions.
        </MessageText>
        <ForgotText
          padding='2rem 0'
          color={colors.purpleText}
          onClick={backToLogin}
        >
          Take me back to login
        </ForgotText>
      </LoginFormContainer>
    </>
  );
};

export default EmailConfirm;
