import axios from 'axios';
import { setHeader } from './index';

const CLIENT_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/clients`;
const REPORTS_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/reports`;

export const fetchSingleClient = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/${clientId}`);
};

export const fetchAllClients = async () => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/all`);
};

export const fetchAllCategories = async () => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/categories`);
};

export const fetchAllTypes = async () => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/types`);
};

export const fetchAllowedReportFrequencies = async () => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/allowed-report-frequencies`)
};

export const createClient = async (client) => {
  const request = await setHeader(axios);
  return request.post(`${CLIENT_API}/add`, { client });
};

export const updateClient = async (id, client) => {
  const request = await setHeader(axios);
  return request.put(`${CLIENT_API}/${id}`, { client });
};

export const fetchClientBanks = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/banks/all/${clientId}`);
};

export const updateClientBank = async (bankClientRelId, bankClientRel) => {
  const request = await setHeader(axios);
  return request.put(`${CLIENT_API}/banks/${bankClientRelId}`, {
    bankClientRel,
  });
};

export const initiateStartReport = async (clientId, client) => {
  const request = await setHeader(axios);
  return request.post(`${CLIENT_API}/start-report/${clientId}`, { client });
};

export const fetchClientList = async () => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/list`);
};

export const fetchClientNegotiatedRates = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/negotiated-rates/${clientId}`);
};

export const fetchAccountBalanceData = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/account-balance/${accountId}`);
};

export const fetchClientReportStatus = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/report-status/${clientId}`);
};

export const fetchMaturingInvestments = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/maturing-investments/${clientId}`);
};

export const fetchDayToDayVariance = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/day-to-day-variance/${clientId}`);
};

export const fetchCashVestScores = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/cash-vest/widget/${clientId}`);
};

export const fetchPresignedURLForReports = async (fileName, reportId) => {
  const request = await setHeader(axios);
  return request.get(`${REPORTS_API}/upload/${reportId}/${fileName}`);
};

export const updateReportUploadInfo = async (reports, reportId) => {
  const request = await setHeader(axios);
  return request.put(`${REPORTS_API}/${reportId}`, {
    reports,
  });
};

export const downloadReport = async (analysisReportId, reportType) => {
  const request = await setHeader(axios);
  return request.get(
    `${REPORTS_API}/download/${analysisReportId}/${reportType}`
  );
};

export const fetchAssetWidgetData = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/assets-widget/${clientId}`);
};

export const fetchClientShortTermForecast = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/short-term-forecast/${clientId}`);
};

export const fetchShortTermModelForecast = async (clientId, numberOfWeeks) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/short-term/${clientId}/${numberOfWeeks}`);
};

export const fetchReports = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${REPORTS_API}/all/${clientId}`);
};

export const fetchClientInterestRate = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${CLIENT_API}/interest-rate-widget/${clientId}`);
};

export const fetchInterestRateComparisons = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(
    `${CLIENT_API}/interest-rate-comparison-widget/${clientId}`
  );
};

export const updateDailyMonthlyBalance = async (clientId) => {
  const request = await setHeader(axios);
  return request.put(`${CLIENT_API}/update-balance/${clientId}`);
};

export const generateReportPdf = async (reports) => {
  const request = await setHeader(axios);
  return request.post(`${REPORTS_API}/generate`, { reports });
};

export const updateReportComment = async (reportId, report) => {
  const request = await setHeader(axios);
  return request.put(`${REPORTS_API}/comments/${reportId}`, { report });
};

export const fetchReportParameters = async (reportId) => {
  const request = await setHeader(axios);
  return request.get(`${REPORTS_API}/${reportId}`);
};

export const finalizeReport = async (reportId, finalizeStatus) => {
  const request = await setHeader(axios);
  return request.put(`${REPORTS_API}/finalize-status/${reportId}`, {
    report: { finalizeStatus },
  });
};

export const deleteReport = async (reportId) => {
  const request = await setHeader(axios);
  return request.delete(`${REPORTS_API}/${reportId}`);
}
