import React, { useState, useEffect } from 'react';
import UsersDialogBox from '../../components/dialog/UsersDialogBox';
import UserTable from '../../components/table/UserTable';
import { Intent } from '@blueprintjs/core';
import {
  createUser,
  updateUser,
  resetMfa,
  fetchUserClientAssociation,
  resendCode,
} from '../../api/users';
import AppToast from '../../components/Toast';
import { connect } from 'react-redux';
import { fetchRoles } from '../../actions/roles';
import { fetchClientList } from '../../actions/clients';
import { fetchSingleUser, fetchAllUsers } from '../../actions/users';
import { ComponentBody } from '../../components/styled-components';
import UserHeader from '../../components/headers/UserHeader';
import { fetchCognitoUser } from '../../api/auth';

const UserManagementBase = ({
  appUser,
  roles,
  users,
  isAllUsersFetching,
  clientList,
  isFetchingAllClientsList,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [status, setStatus] = useState(1);
  const [role, setRole] = useState('');
  const [resetMfaMessage, setResetMfaMessage] = useState(false);
  const [showGreenMessage, setShowGreenMessage] = useState(false);
  const [oldEmail, setOldEmail] = useState('');
  const [userId, setUserId] = useState(undefined);
  const [isResetting, setIsResetting] = useState(false);
  const [clientAssociation, setClientAssociation] = useState([]);
  const [isResendingCode, setIsResendingCode] = useState(false);

  const {
    fetchRoles,
    fetchAllUsers,
    fetchSingleUser,
    fetchClientList,
    fetchAppUser,
  } = props;

  useEffect(() => {
    fetchRoles();
    fetchAllUsers();
    fetchClientList();
  }, [fetchRoles, fetchAllUsers, fetchClientList]);

  const openAddNewUserDialog = () => {
    setTitle('Add New User');
    setIsOpen(true);
  };

  const openEditUserDialog = async (attributes) => {
    // fetch all the clients associated with the selected user
    await fetchUserClientAssociation(attributes.userId)
      .then((res) => {
        setClientAssociation(res.data.map((val) => val.clientName));
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to get client associations for the user.',
          intent: Intent.DANGER,
        });
        closeDialog();
      });

    attributes.status === 'active' ? setStatus(1) : setStatus(0);
    setTitle('Edit User');
    setRole(attributes.roleId);
    setOldEmail(attributes.email);
    setIsOpen(true);
    setUserId(attributes.userId);
    setFirstName(attributes.firstName);
    setLastName(attributes.lastName);
    setEmail(attributes.email);
    setPhoneNumber(attributes.phoneNumber)
  };

  const closeDialog = () => {
    setIsOpen(false);
    setTitle('');
    setStatus(1);
    setRole('');
    setResetMfaMessage(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setUserId(undefined);
    setClientAssociation([]);
  };

  const handleCreateUser = async (values) => {
    const user = {
      ...values,
    };
    createUser(user)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllUsers();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create user.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  const handleEditUser = (values) => {
    const user = {
      ...values,
      oldEmail,
    };
    updateUser(userId, user)
      .then(async (res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        // need to bypass the cache and fetch most recent cognito user so lambda has correct
        // email to check for user permissions when it does call to get user
        const cognitoUser = await fetchCognitoUser(true);
        if (cognitoUser) await fetchAppUser();
        fetchSingleUser(userId);
        props.history.push('/dashboard');
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit user.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  const resetUserMfa = () => {
    setIsResetting(true);
    resetMfa(oldEmail)
      .then(() => {
        setResetMfaMessage(true);
        setShowGreenMessage(true);
      })
      .catch(() => {
        setResetMfaMessage(true);
        setShowGreenMessage(false);
      })
      .finally(() => setIsResetting(false));
  };

  const resendConfirmationCode = async () => {
    setIsResendingCode(true);
    resendCode(email)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to send code.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => setIsResendingCode(false));
  };

  return (
    <>
      <UserHeader
        appUser={appUser}
        header={'Manage Users'}
        hasButton={true}
        buttonText={'Add New User'}
        action={openAddNewUserDialog}
        {...props}
      />
      <ComponentBody>
        <UserTable
          appUser={appUser}
          users={users}
          isFetching={isAllUsersFetching}
          openEditUserDialog={openEditUserDialog}
          {...props}
        />

        <UsersDialogBox
          isOpen={isOpen}
          title={title}
          closeDialog={closeDialog}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          status={status}
          role={role}
          roles={roles}
          resetMfaMessage={resetMfaMessage}
          showGreenMessage={showGreenMessage}
          setClientAssociation={setClientAssociation}
          clientList={clientList}
          clientAssociation={clientAssociation}
          handleCreateUser={handleCreateUser}
          handleEditUser={handleEditUser}
          resetUserMfa={resetUserMfa}
          appUser={appUser}
          userId={userId}
          isResetting={isResetting}
          resendConfirmationCode={resendConfirmationCode}
          isResendingCode={isResendingCode}
        />
      </ComponentBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: state.roles.appRoles,
  users: state.users.allUsers,
  isAllUsersFetching: state.users.isAllUsersFetching,
  clientList: state.clients.allClientList,
  isFetchingAllClientsList: state.clients.isFetchingAllClientsList,
});

const UserManagement = connect(mapStateToProps, {
  fetchRoles,
  fetchAllUsers,
  fetchSingleUser,
  fetchClientList,
})(UserManagementBase);

export default UserManagement;
