import React from 'react';
import {
  RegularButton,
  ForgotText,
  MessageText,
  AlignCenter,
  Label,
} from '../styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadSpinner from '../LoadSpinner';
import IconTextField from '../formik-text-fields/IconTextField';
import colors from '../../utils/colors';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Required!'),
});

const ForgotPassword = ({ email, backToLogin, handleForgotPassword }) => {
  return (
    <>
      <AlignCenter flexDirection='column'>
        <MessageText fontSize='1.25rem' margin='0 0 30px 0'>
          To reset your password, please provide your Email Account on record.
          You will be sent an email with steps to help you create a new
          password.
        </MessageText>
        <Formik
          initialValues={{ email }}
          validationSchema={validationSchema}
          onSubmit={handleForgotPassword}
          validateOnBlur={false}
        >
          {({ isSubmitting, values }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {/* custom field using formik hooks */}
              <Label> Email </Label>
              <IconTextField
                type='email'
                name='email'
                value={values.email || ''}
                autoComplete='off'
              />
              <RegularButton
                margin='30px 0'
                width='100%'
                padding='1rem'
                type='submit'
                disabled={isSubmitting}
                display='flex'
              >
                Send Link
                {isSubmitting && (
                  <span style={{ marginLeft: '10px' }}>
                    <LoadSpinner size={20} />
                  </span>
                )}
              </RegularButton>
              <ForgotText color={colors.purpleText} onClick={backToLogin}>
                Take me back to login
              </ForgotText>
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default ForgotPassword;
