import React, { useMemo } from 'react';
import { StyleColumnCell, ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import colors from '../../utils/colors';
import { amountFormatter, checkPermissions } from '../../utils/functions';
import { Popover, Position, Icon, Menu, MenuItem } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const StagingTransactionsInspectTable = ({
  stagingTransactions,
  handleOpenEditDialog,
  handleBulkEditDialogOpen,
  isFetching,
  appUser,
  updatePaginate,
  pageCount,
  resultCount,
  isServerSidePagination,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account' />
        ),
        accessor: 'accountNumber',
        width: '20%',
        isVisible: true,
        filter: 'text',
        sortType: 'caseInsensitiveSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'transactionDate',
        id: 'transactionDate',
        width: '12%',
        isVisible: true,
        filter: 'dateRange',
        sortType: 'customDateTimeSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Description' />
        ),
        accessor: 'transactionDescription',
        id: 'transactionDescription',
        width: '30%',
        isVisible: true,
        filter: 'text',
        sortType: 'caseInsensitiveSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Keywords' />
        ),
        accessor: 'keyword',
        width: '10%',
        isVisible: true,
        filter: 'multipleValues',
        sortType: 'caseInsensitiveSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Debit/Credit' />
        ),
        accessor: 'amount',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value > 0 ? colors.green : colors.red}
              fontWeight='400'
            >
              {typeof(value) === 'number' ? amountFormatter(value) : '---'}
            </StyleColumnCell>
          );
        },
        filter: 'debitCredit',
        width: '5%',
        isVisible: true,
        sortType: 'debitCreditSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Validated?' />
        ),
        accessor: 'validationStatus',
        width: '5%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'No' ? colors.activeColor : colors.inActiveColor}
            >
              {value && value.toUpperCase()}
            </StyleColumnCell>
          );
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.EDIT_STAGING_TRANSACTIONS,
                  ]) && (
                    <MenuItem
                      text='Edit'
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                      onClick={() => handleOpenEditDialog(row.original)}
                    />
                  )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.EDIT_STAGING_TRANSACTIONS,
        ]),
      },
    ],
    [
      handleOpenEditDialog,
      appUser,
    ]
  );

  return (
    <>
      <TableContainer width='100%' padding='0rem 2rem 1rem'>
        <MakeTable
          tableTitle='Inspect Staging Transactions'
          data={stagingTransactions}
          isFetching={isFetching}
          columns={columns}
          customPageOptions={[50, 100, 200, 300, 500]}
          openBulkEditDialog={handleBulkEditDialogOpen}
          appUser={appUser}
          updatePaginate={updatePaginate}
          pageCount={pageCount}
          resultCount={resultCount}
          isServerSidePagination={isServerSidePagination}
          {...props}
        />
      </TableContainer>
    </>
  );
};

export default StagingTransactionsInspectTable;
