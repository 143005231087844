import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { AlignCenter } from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import FormActions from '../FormActions';
import ModalTextArea from '../../formik-text-fields/ModalTextArea';
import { Intent } from '@blueprintjs/core';
import FormikMultiSelectField from '../../multi-select-fields/FormikMultiSelectField';
import { onKeyDown } from '../../../utils/functions';

const transformValues = (values) => {
  values.taggedUsersList = values.taggedUsersList.map((user) => user.value);
  values.categoriesList = values.categoriesList.map(
    (category) => category.value
  );
  return values;
};

const noteSchema = Yup.object().shape({
  noteTitle: Yup.string().required('Required!'),
  noteDescription: Yup.string().required('Required!'),
  taggedUsersList: Yup.array(),
  categoriesList: Yup.array(),
});

const NoteForm = ({
  note,
  title,
  userList,
  handleCreate,
  handleEdit,
  handleClose,
  noteCategories,
}) => {
  const [selectedNoteCategories, setSelectedNoteCategories] = useState([]);
  const [selectedTaggedUsers, setSelectedTaggedUsers] = useState([]);
  useEffect(() => {
    if (note) {
      const categories = noteCategories.filter((category) => {
        return (
          note.noteCategoryIds &&
          note.noteCategoryIds.includes(String(category.value))
        );
      });
      const taggedUsers = userList.filter((user) => {
        return (
          note.taggedUserIds && note.taggedUserIds.includes(String(user.value))
        );
      });
      setSelectedNoteCategories(categories);
      setSelectedTaggedUsers(taggedUsers);
    }
  }, [note, userList, noteCategories]);
  return (
    <AlignCenter flexDirection='column' padding='10px 0'>
      <Formik
        initialValues={{
          noteTitle: note ? note.noteTitle : '',
          noteDescription: note ? note.noteDescription : '',
          taggedUsersList: note ? selectedTaggedUsers : [],
          categoriesList: note ? selectedNoteCategories : [],
        }}
        onSubmit={(values) => {
          title === 'Add Note'
            ? handleCreate(transformValues(values))
            : handleEdit(transformValues(values), note.noteId);
        }}
        validationSchema={noteSchema}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            onKeyDown={onKeyDown}
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <AlignCenter
              justifyContent='space-evenly'
              padding='20px 0'
              alignItems='flex-start'
            >
              <ModalTextField
                type='text'
                name='noteTitle'
                placeholder='Note Title'
                label='Note Title'
                value={values.noteTitle || ''}
                autoComplete='off'
                width='500px'
              />
              <div style={{ width: '500px' }}>
                <FormikMultiSelectField
                  setSelectedItems={setFieldValue}
                  items={noteCategories}
                  selectedItems={values.categoriesList}
                  label='Categories'
                  name='categoriesList'
                  fill={true}
                  minWidth='500px'
                />
              </div>
            </AlignCenter>
            <AlignCenter
              justifyContent='space-evenly'
              padding='20px 0'
              alignItems='flex-start'
            >
              <ModalTextArea
                label='Comments'
                name='noteDescription'
                placeholder='Note Descriptions'
                value={values.noteDescription || ''}
                height='150px'
                width='500px'
              />
              <div style={{ width: '500px' }}>
                <FormikMultiSelectField
                  setSelectedItems={setFieldValue}
                  items={userList}
                  selectedItems={values.taggedUsersList}
                  label='Tag Users'
                  fill={true}
                  name='taggedUsersList'
                  intent={Intent.SUCCESS}
                  minWidth='500px'
                />
              </div>
            </AlignCenter>
            {/* {Form Footer} */}
            <FormActions isSubmitting={isSubmitting} onClose={handleClose} />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default NoteForm;
