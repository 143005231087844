import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './store';
import Amplify from 'aws-amplify';
import amplifyConfig from './amplifyConfig';
import { PersistGate } from 'redux-persist/integration/react';
//For IE11 Support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

//JS6 Syntax support for IE
//This does not include all of the features to make a more lightweight bundle
// if more js6 methods are added to the app then they must be added here as well
// to be supported on ie
import 'core-js/features/promise';
import 'core-js/features/array/from';
import 'core-js/features/object/assign';
import 'core-js/features/typed-array/from';

//Persist gate will delay the rendering until the state is retrieved from the storage - Used on a page refresh

// this function will run each app load to create the store
const { store, persistor } = configureStore();

// initialize AWS Amplify
Amplify.configure(amplifyConfig);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// hi
