import React from 'react';
import { SubNavWrapper, NavItem } from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

// the function being passed will send value back to state of client profile page
// also set the is active state to the item in the sub nav that is clicked
const ClientSubNavigation = ({
  handleCurrentComponentChange,
  currentComponent,
  appUser,
}) => {
  return (
    <>
      <SubNavWrapper>
        {checkPermissions(appUser.permList, [permissions.VIEW_CLIENTS]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(0)}
            className={currentComponent === 0 && 'active'}
          >
            Summary
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_ACCOUNTS]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(1)}
            className={currentComponent === 1 && 'active'}
          >
            Accounts
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_ACCOUNT_GROUPS,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(2)}
            className={currentComponent === 2 && 'active'}
          >
            Client Account Groups
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_INVESTMENTS]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(3)}
            className={currentComponent === 3 && 'active'}
          >
            Investments
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_REPORTS]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(4)}
            className={currentComponent === 4 && 'active'}
          >
            Report List
          </NavItem>
        )}
      </SubNavWrapper>
    </>
  );
};

export default ClientSubNavigation;
