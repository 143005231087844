import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchClientNegotiatedRates } from '../../../../../actions/clients';
import NegotiatedRatesTable from '../../../../../components/table/NegotiatedRatesTable';

const NegotiatedRatesBase = ({
  singleClient,
  appUser,
  fetchClientNegotiatedRates,
  clientNegotiatedRates,
  isFetchingClientNegotiatedRates,
  ...props
}) => {
  useEffect(() => {
    fetchClientNegotiatedRates(singleClient.clientId);
  }, [fetchClientNegotiatedRates, singleClient]);
  return (
    <NegotiatedRatesTable
      negotiatedRates={clientNegotiatedRates}
      appUser={appUser}
      isFetching={isFetchingClientNegotiatedRates}
      singleClient={singleClient}
      hasClient={true}
      fetchClientNegotiatedRates={fetchClientNegotiatedRates}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  clientNegotiatedRates: state.clients.clientNegotiatedRates,
  isFetchingClientNegotiatedRates:
    state.clients.isFetchingClientNegotiatedRates,
});

const NegotiatedRates = connect(mapStateToProps, {
  fetchClientNegotiatedRates,
})(NegotiatedRatesBase);

export default NegotiatedRates;
