import axios from 'axios';
import { setHeader } from './index';

const BANK_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/banks`;

export const fetchAllBanks = async () => {
  const request = await setHeader(axios);
  return request.get(`${BANK_API}/all`);
};

export const fetchSingleBank = async (bankId) => {
  const request = await setHeader(axios);
  return request.get(`${BANK_API}/${bankId}`);
};

export const createBank = async (bank) => {
  const request = await setHeader(axios);
  return request.post(`${BANK_API}/add`, { bank });
};

export const updateBank = async (id, bank) => {
  const request = await setHeader(axios);
  return request.put(`${BANK_API}/${id}`, { bank });
};

export const fetchBankNames = async () => {
  const request = await setHeader(axios);
  return request.get(`${BANK_API}/list`);
};

export const fetchAllowedDataGatheringValues = async () => {
    const request = await setHeader(axios);
    return request.get(`${BANK_API}/allowed-data-gathering-values`);
};

export const fetchBankDefaultDataGatheringFrequencies = async () => {
    const request = await setHeader(axios);
    return request.get(`${BANK_API}/default-data-gathering-frequencies`);
};