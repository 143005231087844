import React from 'react';
import { SubNavWrapper, NavItem } from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

const AccountSubNavigation = ({
  handleAccountComponentChange,
  accountComponent,
  appUser,
}) => {
  return (
    <>
      <SubNavWrapper>
        {checkPermissions(appUser.permList, [
          permissions.VIEW_ACCOUNT_LINKS,
        ]) && (
          <NavItem
            onClick={() => handleAccountComponentChange(0)}
            className={accountComponent === 0 && 'active'}
          >
            Account Link
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_NOTES]) && (
          <NavItem
            onClick={() => handleAccountComponentChange(1)}
            className={accountComponent === 1 && 'active'}
          >
            Notes
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_ACCOUNT_GROUPS,
        ]) && (
          <NavItem
            onClick={() => handleAccountComponentChange(2)}
            className={accountComponent === 2 && 'active'}
          >
            Account Groups
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_TRANSACTIONS,
        ]) && (
          <NavItem
            onClick={() => handleAccountComponentChange(3)}
            className={accountComponent === 3 && 'active'}
          >
            Transactions
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_INVESTMENTS]) && (
          <NavItem
            onClick={() => handleAccountComponentChange(4)}
            className={accountComponent === 4 && 'active'}
          >
            Investments
          </NavItem>
        )}
      </SubNavWrapper>
    </>
  );
};

export default AccountSubNavigation;
