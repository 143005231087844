import React from 'react';
import { Form, Formik } from 'formik';
import DateSelectField from '../../select-fields/DateSelectField';
import { AlignCenter, ErrorMessageDiv, Label } from '../../styled-components';
import FormActions from '../FormActions';
import * as Yup from 'yup';
import { dateToString, stringToDate } from '../../../utils/functions';
import { Checkbox, Icon } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import colors from '../../../utils/colors';

const schema = Yup.object().shape({
  startDate: Yup.string().required('Required!').nullable(),
  endDate: Yup.string().required('Required!').nullable(),
  accountTypes: Yup.object().test('atLeastOneSelected', (values, testContext) => {
    return Object.values(values).some(accountType => accountType === true) 
    ? true 
    : testContext.createError({
        message: 'At least one must be selected!',
        path: 'accountTypes'
      })
  })
});

//This function transform date values to string date for backend
const transformValues = (values) => {
  let batch = Object.assign({}, values);
  batch.startDate = dateToString(batch.startDate);
  batch.endDate = dateToString(batch.endDate);
  return batch;
};

const StartIngestionForm = ({
  handleClose,
  handleStartIngestion,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column' padding='10px 0'>
        <Formik
          initialValues={{
            startDate: stringToDate(''),
            endDate: stringToDate(''),
            accountTypes: {
              transactions: true,
              investments: true,
              hybridAccount: true,
              treasuryServices: true,
            }
          }}
          onSubmit={(values) => {
            handleStartIngestion(transformValues(values));
          }}
          validationSchema={schema}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({ isSubmitting, handleChange, values, errors, setFieldValue }) => {
            return (
              <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
              >
                <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                  <DateSelectField
                    name={'startDate'}
                    setFieldValue={setFieldValue}
                    value={values.startDate}
                    labelName={'Start Date'}
                    />
                  <DateSelectField
                    name={'endDate'}
                    setFieldValue={setFieldValue}
                    value={values.endDate}
                    labelName={'End Date'}
                    minDate={values.startDate}
                    />
                </AlignCenter>
                <AlignCenter>
                  <Label>
                    Account Types
                  </Label>
                </AlignCenter>
                <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                  <Checkbox
                    label={"Transactions"}
                    onChange={handleChange}
                    value={values.accountTypes.transactions}
                    checked={values.accountTypes.transactions}
                    name={"accountTypes.transactions"}
                    large={true}
                  />
                  <Checkbox 
                    checked={values.accountTypes.investments}
                    label={"Investments"}
                    onChange={handleChange}
                    value={values.accountTypes.investments}
                    name={"accountTypes.investments"}
                    large={true}
                  />
                  <Checkbox
                    label={"Hybrid Account"}
                    onChange={handleChange}
                    value={values.accountTypes.hybridAccount}
                    checked={values.accountTypes.hybridAccount}
                    name={"accountTypes.hybridAccount"}
                    large={true}
                  />
                  <Checkbox
                    label={"Treasury Services"}
                    onChange={handleChange}
                    value={values.accountTypes.treasuryServices}
                    checked={values.accountTypes.treasuryServices}
                    name={"accountTypes.treasuryServices"}
                    large={true}
                  />
                </AlignCenter>
                {errors.accountTypes ? (
                  <AlignCenter justifyContent='center'>
                    <ErrorMessageDiv fontSize='1rem'>{errors.accountTypes}</ErrorMessageDiv>
                    <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
                  </AlignCenter>
                  ) : <div></div>}
                <FormActions
                  isSubmitting={isSubmitting}
                  onClose={handleClose}
                />
              </Form>
            );
          }}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default StartIngestionForm;
