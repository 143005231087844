import React from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
  MessageText,
  RedText,
} from '../../styled-components';
import colors from '../../../utils/colors';
import LoadSpinner from '../../LoadSpinner';

const TransactionDeleteDialogBox = ({
  isOpen,
  title,
  transaction,
  handleClose,
  handleDelete,
  isDeleting,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete this transaction?
        </MessageText>
      </div>
      <AlignCenter
        justifyContent='flex-end'
        padding='10px 20px 10px 0'
        borderTop={`1px solid ${colors.lightText}`}
        margin='50px 0 0 0'
      >
        <WhiteButton
          type='button'
          color={colors.red}
          onClick={handleClose}
          hoverColor={colors.redHover}
        >
          CLOSE
        </WhiteButton>
        <RegularButton
          display='flex'
          padding='10px 30px'
          onClick={() => handleDelete(transaction)}
          background={colors.red}
          backgroundHover={colors.redHover}
          disabled={isDeleting}
        >
          DELETE
          {isDeleting && (
            <span style={{ marginLeft: '10px' }}>
              <LoadSpinner size={20} />
            </span>
          )}
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};

export default TransactionDeleteDialogBox;
