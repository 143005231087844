import React from 'react';
import { Label, FlexColumn, InputWrapper, Input } from '../styled-components';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon, Tooltip } from '@blueprintjs/core';

const TextField = ({ toolTipText, isDisabled, ...props }) => {
  return (
    <>
      <FlexColumn>
        <Label>
          {props.label}
          {toolTipText && (
            <div
              style={{
                display: 'inline-block',
                padding: '0px 0px 5px 5px',
                verticalAlign: 'middle',
              }}
            >
              <Tooltip content={toolTipText}>
                <Icon
                  icon={iconValue.info}
                  iconSize={16}
                  color={colors.primary}
                />
              </Tooltip>
            </div>
          )}
        </Label>

        <InputWrapper style={isDisabled && { background: colors.greyDisabled }}>
          {isDisabled ? <Input {...props} disabled /> : <Input {...props} />}
        </InputWrapper>
      </FlexColumn>
    </>
  );
};

export default TextField;
