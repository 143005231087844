import React from 'react';
import { FieldArray, Form, Formik } from 'formik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import { AlignCenter } from '../../styled-components';
import FormActions from '../FormActions';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  amounts: Yup.array().of(
    Yup.object().shape({
      1: Yup.number()
        .required('Required!')
        .min(0, 'ECR can not be less than 0%.')
        .nullable(),
    })
  ),
});

const transformValues = (values, isECR) => {
  values.accountBalanceAmounts = isECR
    ? values.amounts.map((item) => [item[0], item[1] / 100])
    : values.amounts;
  return values;
};

const EditAccountBalanceForm = ({
  closeDialog,
  amounts,
  dynamicHeaders,
  isECR,
  handleEditAccountBalance,
  indexes,
}) => {
  const headerArray = indexes.map((i) => dynamicHeaders[i]);

  return (
    <>
      <AlignCenter flexDirection='column'>
        <Formik
          initialValues={{ amounts }}
          validationSchema={schema}
          onSubmit={(values) => {
            handleEditAccountBalance(transformValues(values, isECR));
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  maxHeight: '700px',
                  overflowY: 'auto',
                  padding: '30px 0 20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    padding: '0px 50px',
                    marginBottom: '10px',
                  }}
                >
                  <FieldArray
                    name='amounts'
                    render={(arrayHelpers) =>
                      headerArray.map((header, index) => (
                        <div
                          style={{
                            flexBasis: '271px',
                            flexGrow: 1,
                          }}
                          key={index}
                        >
                          <ModalTextField
                            width='271px'
                            type='text'
                            name={`amounts[${index}][1]`}
                            placeholder='Amount'
                            label={header}
                            isCurrency={!isECR}
                            isPercentage={isECR}
                            autoComplete='off'
                          />
                        </div>
                      ))
                    }
                  />
                </div>
              </div>
              <FormActions
                isSubmitting={isSubmitting}
                onClose={closeDialog}
                margin='0'
              />
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default EditAccountBalanceForm;
