import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { WidgetHeader, Label, TextValue, CardDiv } from '../styled-components';
import LoadSpinner from '../LoadSpinner';
import { ResponsiveLine } from '@nivo/line';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { amountFormatter } from '../../utils/functions';
import NonIdealStateComp from '../Non-Ideal-State';
import moment from 'moment';

const AssetsWidget = ({ assetWidgetData, isAssetWidgetDataFetching }) => {
  const color = [colors.primary, colors.green];
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ width: '500px', height: '100%' }}
    >
      {isAssetWidgetDataFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {!isAssetWidgetDataFetching &&
        assetWidgetData &&
        assetWidgetData[2] &&
        assetWidgetData[2].length === 0 && (
          <>
            <WidgetHeader display='block'>
              <Label>Assets</Label>
              <TextValue as='p' padding='5px 0 0 0'>
                As of {moment().format('MM-DD-YYYY')}
              </TextValue>
            </WidgetHeader>
            <WidgetHeader padding='10px 20px' display='block'>
              <Label fontSize='1.5rem'>
                {assetWidgetData && assetWidgetData[1]['Number Of Accounts']}
              </Label>
              <TextValue as='p' padding='5px 0 20px'>
                Number of Accounts
              </TextValue>
              <Label fontSize='1.5rem'>
                {assetWidgetData &&
                  amountFormatter(assetWidgetData[1]['Strategic Liquidity'], 2)}
              </Label>
              <TextValue as='p' padding='5px 0 20px'>
                Strategic Liquidity
              </TextValue>
              <Label fontSize='1.5rem'>
                {assetWidgetData &&
                  amountFormatter(assetWidgetData[1]['Amount Invested'], 2)}
              </Label>
              <TextValue as='p' padding='5px 0'>
                Total cash balances
              </TextValue>
            </WidgetHeader>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        )}

      {!isAssetWidgetDataFetching &&
        assetWidgetData &&
        assetWidgetData[2] &&
        assetWidgetData[2].length > 0 && (
          <>
            <WidgetHeader display='block'>
              <Label>Assets</Label>
              <TextValue as='p' padding='5px 0 0 0'>
                As of {moment().format('MM-DD-YYYY')}
              </TextValue>
            </WidgetHeader>
            <WidgetHeader padding='10px 20px' display='block'>
              <Label fontSize='1.5rem'>
                {assetWidgetData && assetWidgetData[1]['Number Of Accounts']}
              </Label>
              <TextValue as='p' padding='5px 0 20px'>
                Number of Accounts
              </TextValue>
              <Label fontSize='1.5rem'>
                {assetWidgetData &&
                  amountFormatter(assetWidgetData[1]['Strategic Liquidity'], 2)}
              </Label>
              <TextValue as='p' padding='5px 0 20px'>
                Strategic Liquidity
              </TextValue>
              <Label fontSize='1.5rem'>
                {assetWidgetData &&
                  amountFormatter(assetWidgetData[1]['Amount Invested'], 2)}
              </Label>
              <TextValue as='p' padding='5px 0'>
                Total cash balances
              </TextValue>
            </WidgetHeader>

            <div
              style={{
                height: '15.8rem',
                display: 'flex',
                paddingTop: '1rem',
                background: `${colors.containerBackground}`,
              }}
            >
              <ResponsiveLine
                data={assetWidgetData[2]}
                margin={{ top: 40, right: 50, bottom: 50, left: 100 }}
                colors={color}
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                enableGridY={false}
                enablePoints={false}
                areaOpacity={1}
                xScale={{ type: 'time', format: '%m-%d-%Y', useUTC: false }}
                xFormat='time:%m-%d-%Y'
                yScale={{
                  type: 'linear',
                  min: assetWidgetData[0]['min'],
                  max: assetWidgetData[0]['max'],
                }}
                yFormat={(v) => amountFormatter(v, 2)}
                axisBottom={{
                  format: '%b-%Y',
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 10,
                  tickValues: 'every month',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 10,
                  format: (v) => amountFormatter(v, 0),
                }}
                // needed for interactivity
                useMesh={true}
                legends={[
                  {
                    dataForm: 'keys',
                    anchor: 'top',
                    direction: 'row',
                    translateX: 0,
                    translateY: -40,
                    itemsSpacing: 2,
                    itemWidth: 120,
                    itemHeight: 30,
                    itemDirection: 'left-to-right',
                    symbolSize: 10,
                    symbolShape: 'square',
                  },
                ]}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: '#a9acbe',
                        strokeWidth: 1,
                      },
                    },
                  },
                  tooltip: {
                    container: { color: colors.boldText, fontWeight: 700 },
                  },
                }}
              />
            </div>
          </>
        )}
    </CardDiv>
  );
};

export default AssetsWidget;
