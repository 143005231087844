import React from 'react';
import { NavItem, SubNavWrapper } from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

const GlobalSettingsSubNavigation = ({
  handleCurrentComponentChange,
  currentComponent,
  appUser,
}) => {
  return (
    <>
      <SubNavWrapper>
        {checkPermissions(appUser.permList, [
          permissions.VIEW_OPEN_REPORTS,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(0)}
            className={currentComponent === 0 && 'active'}
          >
            Data Ingestion
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_NOTES]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(1)}
            className={currentComponent === 1 && 'active'}
          >
            Notes
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_INVESTMENTS]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(2)}
            className={currentComponent === 2 && 'active'}
          >
            Investments
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_NEGOTIATED_RATES,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(3)}
            className={currentComponent === 3 && 'active'}
          >
            Negotiated Rates
          </NavItem>
        )}
        {/* TODO change permission for viewing templates */}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_NEGOTIATED_RATES,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(4)}
            className={currentComponent === 4 && 'active'}
          >
            Templates
          </NavItem>
        )}
      </SubNavWrapper>
    </>
  );
};

export default GlobalSettingsSubNavigation;
