import React, { useState, useEffect } from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  NameLink,
  Label,
  WidgetHeader,
  WidgetBody,
  WidgetBodyHeader,
  WidgetRow,
  CardDiv,
} from '../styled-components';
import WidgetPagination from './WidgetPagination';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';
import { paginate } from '../../utils/functions';

const pageLimit = 5;

const ClientsWidget = ({ clients }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedClients, setPaginatedClients] = useState([]);

  useEffect(() => {
    setPaginatedClients(paginate(clients, pageLimit, pageNumber));
  }, [clients, pageNumber]);

  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ flexBasis: '250px', flexGrow: 1 }}
    >
      <WidgetHeader>
        <Label>My Clients</Label>
        <NameLink
          to={{
            pathname: `/client-management/`,
          }}
        >
          View All
        </NameLink>
      </WidgetHeader>

      <WidgetBody>
        <WidgetBodyHeader>
          <Label>Client</Label>
          <Label>cashVest</Label>
        </WidgetBodyHeader>
        {paginatedClients.length ? (
          paginatedClients.map((client) => (
            <WidgetRow key={client.clientId}>
              <NameLink
                title={client.clientName}
                to={{
                  pathname: `/client-management/${client.clientId}`,
                }}
              >
                {client.clientName.length > 21
                  ? client.clientName.substring(0, 18) + '...'
                  : client.clientName}
              </NameLink>
              {client.cashVest}
            </WidgetRow>
          ))
        ) : (
          <NonIdealStateComp
            icon={iconValue.warning}
            title={'No Results.'}
            description={'There are no results to be displayed.'}
          />
        )}
      </WidgetBody>

      <WidgetPagination
        currentIndex={pageNumber}
        recordsLength={clients.length}
        setPageNumber={setPageNumber}
        pageLimit={pageLimit}
      />
    </CardDiv>
  );
};

export default ClientsWidget;
