import React, { useEffect, useState } from 'react';
import { Dialog, Radio, RadioGroup } from '@blueprintjs/core';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import {
  dateRangeChecker,
  dateToFilterText,
  multiSelectToFilterText,
  textToFilterText,
} from '../../../utils/functions';
import TableFilterActions from './TableFilterActions';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';

const StepOneAnalysisFilterDialogBox = ({
  isOpen,
  handleClose,
  rangeFilter,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
  resetInitialFilter,
}) => {
  const [dateRange, setDateRange] = useState(rangeFilter);
  const [groupNames, setGroupNames] = useState([]);
  const [groupNameItems, setGroupNameItems] = useState([]);
  const [modelNames, setModelNames] = useState([]);
  const [modelNameItems, setModelNameItems] = useState([]);
  const [dataHasChanged, setDataHasChanged] = useState('');
  const [stepOneReady, setStepOneReady] = useState('');

  useEffect(() => {
    if (resetInitialFilter) {
      setFilter('createdAt', rangeFilter);
    }
    if (initialData.length) {
      setGroupNameItems(
        initialData
          .map((item) => item.groupName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setModelNameItems(
        initialData
          .map((item) => item.shortTermModelName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData, resetInitialFilter, setFilter, rangeFilter]);

  const getFilterSummary = () => {
    const filters = [];
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Created At'));
    groupNames.length &&
      filters.push(multiSelectToFilterText(groupNames, 'Account Group'));
    modelNames.length &&
      filters.push(multiSelectToFilterText(modelNames, 'Name'));
    dataHasChanged &&
      filters.push(textToFilterText(dataHasChanged, 'Data Has Changed'));
    stepOneReady &&
      filters.push(textToFilterText(stepOneReady, 'Step One Ready'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('createdAt', dateRangeChecker(dateRange) ? dateRange : undefined);
    setFilter('groupName', groupNames.length ? groupNames : undefined);
    setFilter('shortTermModelName', modelNames.length ? modelNames : undefined);
    setFilter('dataHasChanged', dataHasChanged || undefined);
    setFilter('stepStatus', stepOneReady || undefined);
    handleClose();
  };

  const handleClearFilters = () => {
    setAllFilters([]);
    setDateRange([]);
    setFilterSummary([]);
    setDataHasChanged('');
    setStepOneReady('');
    setGroupNames([]);
    setModelNames([]);
    handleClose();
  };

  const handleDataHasChangedChange = (event) => {
    setDataHasChanged(event.target.value);
  };

  const handleStepOneReadyChange = (event) => {
    setStepOneReady(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title='Set Filters'
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0', marginBottom: '10px' }}>
        <>
          <div style={{ marginBottom: '20px' }}>
            <DateSelectPlainField
              labelName='Created Date Range'
              dateRange={dateRange}
              handleDateChange={setDateRange}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <MultiSelectField
              setSelectedItems={setGroupNames}
              items={groupNameItems}
              selectedItems={groupNames}
              label='Account Groups'
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <MultiSelectField
              setSelectedItems={setModelNames}
              items={modelNameItems}
              selectedItems={modelNames}
              label='Name'
            />
          </div>
          <div
            style={{ marginBottom: '20px', width: '271px' }}
            className='radio-group'
          >
            <RadioGroup
              label={'Step One Ready?'}
              onChange={handleStepOneReadyChange}
              selectedValue={stepOneReady}
              inline={true}
            >
              <Radio label='Yes' value='Yes' style={{ marginRight: '20px' }} />
              <Radio label='No' value='No' style={{ marginRight: '20px' }} />
              <Radio label='None' value='' />
            </RadioGroup>
          </div>
          <div
            style={{ marginBottom: '20px', width: '271px' }}
            className='radio-group'
          >
            <RadioGroup
              label={'Data Has Changed?'}
              onChange={handleDataHasChangedChange}
              selectedValue={dataHasChanged}
              inline={true}
            >
              <Radio label='Yes' value='Yes' style={{ marginRight: '20px' }} />
              <Radio label='No' value='No' style={{ marginRight: '20px' }} />
              <Radio label='None' value='' />
            </RadioGroup>
          </div>
        </>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default StepOneAnalysisFilterDialogBox;
