import React, { useEffect, useState } from 'react';
import { ComponentBody } from '../../../components/styled-components';
import ValidationHistoryTable from '../../../components/table/ValidationHistoryTable';
import { connect } from 'react-redux';
import { fetchValidationHistory } from '../../../actions/data-ingestion';
import ValidationHistoryDownloadDialog from '../../../components/dialog/data-ingestion/ValidationHistoryDownloadDialog';

const ValidationHistoryBase = ({
  fetchValidationHistory,
  validationHistory,
  isValidationHistoryFetching,
  clientId,
  ...props
}) => {
  const [isValidationHistoryDialogOpen, setIsValidationHistoryDialogOpen] = useState(false)
  const [rowData, setRowData] = useState(undefined)
  // This state is needed for tables that will have filter on initial load.
  // We need to setup this state to false when opening any action items.
  // and after action is completed, the original filter will be persisted.
  // ValidationHistoryTable -> MakeTable -> GlobalFilter -> NotesFilterDialogBox
  const [resetInitialFilter, setResetInitialFilter] = useState(false);



  useEffect(() => {
    fetchValidationHistory(clientId);
  }, [fetchValidationHistory, clientId]);

  const handleOpenValidationHistoryDialog = (row) => {
    // set row data to be the correct format for that specific row
    setRowData(row.files)
    setIsValidationHistoryDialogOpen(true)
  }

  const closeDialog = () => {
    setIsValidationHistoryDialogOpen(false)
  }

  return (
    <>
      <ComponentBody padding='5rem 3rem 0'>
        <ValidationHistoryTable
          validationHistory={validationHistory}
          isFetching={isValidationHistoryFetching}
          resetInitialFilter={resetInitialFilter}
          setResetInitialFilter={setResetInitialFilter}
          handleOpenValidationHistoryDialog={handleOpenValidationHistoryDialog}
          {...props}
        />
      </ComponentBody>
      <ValidationHistoryDownloadDialog 
        isOpen={isValidationHistoryDialogOpen}
        closeDialog={closeDialog}
        data={rowData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  validationHistory: state.dataIngestion.clientValidationHistory,
  isValidationHistoryFetching: state.dataIngestion.isValidationHistoryFetching,
});

const ValidationHistory = connect(mapStateToProps, { fetchValidationHistory })(
  ValidationHistoryBase
);

export default ValidationHistory;
