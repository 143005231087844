import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { MessageText } from '../../styled-components';
import { deactivateInvestmentTypes } from '../../../api/administration';
import AppToast from '../../Toast';
import DialogDeleteActions from '../DialogDeleteActions';

const InvestmentTypesDeactivateDialogBox = ({
  isOpen,
  handleCloseDialog,
  title,
  investmentTypeIds,
  fetchInvestmentTypes,
}) => {
  const [isDeactivating, setIsDeactivating] = useState(false);

  const handleDeactivateInvestmentTypes = () => {
    setIsDeactivating(true);
    deactivateInvestmentTypes(investmentTypeIds)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchInvestmentTypes();
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to deactivate investment type.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeactivating(false);
        handleCloseDialog();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <MessageText>
          {'Are you sure you want to deactivate' +
            (investmentTypeIds.length > 1
              ? ' these investment types?'
              : ' this investment type?')}
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeactivating}
        deleteText='DEACTIVATE'
        handleClose={handleCloseDialog}
        handleDelete={handleDeactivateInvestmentTypes}
      />
    </Dialog>
  );
};

export default InvestmentTypesDeactivateDialogBox;
