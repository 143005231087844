import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import ResetPassword from '../components/auth/ResetPassword';
import { fetchAppUser } from '../actions/auth';
import { connect } from 'react-redux';
import { MainContainer } from '../components/styled-components';
import UserManagement from './Users/UserManagement';
import ClientManagement from './Clients/ClientManagement';
import BankManagement from './Banks/BankManagement';
import InvestmentManagement from './Investments/InvestmentManagement';
import ClientProfile from './Clients/ClientProfile/ClientProfile';
import AccountProfile from './Clients/ClientProfile/Accounts/AccountProfile/AccountProfile';
import UserProfile from './Users/UserProfile/UserProfile';
import NotFound from './NotFound';
import DataIngestion from './DataIngestion/DataIngestion';
import DataIngestionProfile from './DataIngestion/DataIngestionProfile/DataIngestionProfile';
import PrivateRoute from '../utils/PrivateRoute';
import PublicRoute from '../utils/PublicRoute';
import GlobalSettings from './GlobalSettings/GlobalSettings';
import AnalysisManagement from './Analysis/AnalysisManagement';
import InvestmentGroupProfile from './Investments/InvestmentGroupProfile/InvestmentGroupProfile';
import AccountGroupProfile from './Clients/ClientProfile/ClientAccountGroups/AccountGroupProfile/AccountGroupProfile';
import { checkPermissions } from '../utils/functions';
import permissions from '../utils/permissions';

class App extends Component {
  render() {
    const { appUser } = this.props;
    return (
      // basename is default route. if basename was "/home" and you wanted to go to about
      // page you would navigate to "/about" internally but looks like "/home/about" in the url.

      // MainContainer is rendered in a fragment because we don't it showing up when app first
      // loads. if a user does not exist, it will render one of the first three routes and not
      // the main container. if a user exists, it will render what is inside the fragment.
      <Router basename='/'>
        <Switch>
          <PublicRoute exact path='/' component={Login} />
          <PublicRoute
            exact
            path='/reset-password/:username/*'
            component={ResetPassword}
          />
          <PublicRoute
            exact
            path='/forgot-password/:username/:code'
            component={ResetPassword}
          />
          <>
            <MainContainer className='MainContainer'>
              <Switch>
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                <PrivateRoute
                  exact
                  path='/user-management'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [permissions.VIEW_USERS])
                      ? UserManagement
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/client-management'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_CLIENTS,
                    ])
                      ? ClientManagement
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/client-management/:id'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_CLIENTS,
                    ])
                      ? ClientProfile
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/client-management/:clientId/:accountId'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_CLIENTS,
                    ])
                      ? AccountProfile
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/bank-management'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [permissions.VIEW_BANKS])
                      ? BankManagement
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/my-account'
                  component={UserProfile}
                />
                <PrivateRoute
                  exact
                  path='/data-ingestion'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_OPEN_REPORTS,
                    ])
                      ? DataIngestion
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/data-ingestion/:clientId'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_OPEN_REPORTS,
                    ])
                      ? DataIngestionProfile
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/investment-management'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_INVESTMENTS,
                    ])
                      ? InvestmentManagement
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/analysis'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_ANALYSIS,
                    ])
                      ? AnalysisManagement
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/global-settings'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_GLOBAL_SETTINGS,
                    ])
                      ? GlobalSettings
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/investment-management/investment-group/:groupId'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_INVESTMENTS,
                    ])
                      ? InvestmentGroupProfile
                      : NotFound
                  }
                />
                <PrivateRoute
                  exact
                  path='/client-management/:clientId/account-group/:groupId'
                  component={
                    appUser &&
                    checkPermissions(appUser.permList, [
                      permissions.VIEW_ACCOUNT_GROUPS,
                    ])
                      ? AccountGroupProfile
                      : NotFound
                  }
                />
                {/* 404 must be last route in list */}
                <PrivateRoute exact path='*' component={NotFound} />
              </Switch>
            </MainContainer>
          </>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  appUser: state.auth.appUser,
});

App = connect(mapStateToProps, {
  fetchAppUser,
})(App);

export default App;
