import React from 'react';
import { Label, FlexColumn, BlueprintInputWrapper } from '../styled-components';
import {
  dateFormatter,
  getDateTwentyYearsInPast,
  getDateTwentyYearsInFuture,
} from '../../utils/functions';
import { Position } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';

const DateSelectPlainField = ({
  labelName,
  dateRange,
  handleDateChange,
  isDateDisabled,
  minDate,
  maxDate,
}) => {
  minDate = minDate || getDateTwentyYearsInPast();
  maxDate = maxDate || getDateTwentyYearsInFuture();
  return (
    <FlexColumn>
      <Label>{labelName}</Label>
      <BlueprintInputWrapper>
        <DateRangeInput
          {...dateFormatter()}
          shortcuts={true}
          popoverProps={{ position: Position.BOTTOM }}
          onChange={(value) => handleDateChange(value)}
          value={dateRange}
          className={'custom-date-picker'}
          disabled={isDateDisabled}
          minDate={minDate}
          maxDate={maxDate}
        />
      </BlueprintInputWrapper>
    </FlexColumn>
  );
};

export default DateSelectPlainField;
