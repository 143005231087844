import React from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import FileUpload from '../../data-ingestion/FileUpload';
import UploadFileTable from '../../table/UploadFileTable';
import LoadSpinner from '../../LoadSpinner';
import colors from '../../../utils/colors';
import { WhiteButton, RegularButton } from '../../styled-components';

const AccountUploadDialogBox = ({
  isOpen,
  handleClose,
  handleDrop,
  handleUpload,
  uploadList,
  removeSingleFile,
  removeMultipleFiles,
  isUploading,
  source,
  progressPercentage,
  appUser,
  ...props
}) => {
  return (
    <>
      <Dialog
        className='large-dialog border-less-header'
        isOpen={isOpen}
        onClose={handleClose}
        title='Upload Accounts'
        isCloseButtonShown={false}
      >
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <FileUpload
            handleDrop={handleDrop}
            acceptedText='Accepted File Type is CSV.'
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <UploadFileTable
              uploadList={uploadList}
              appUser={appUser}
              removeSingleFile={removeSingleFile}
              removeMultipleFiles={removeMultipleFiles}
              {...props}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <WhiteButton
                margin='10px 40px 10px 0'
                type='button'
                color={colors.red}
                hoverColor={colors.redHover}
                onClick={() => {
                  source.cancel('Operation canceled by the user.');
                  handleClose();
                }}
              >
                CANCEL UPLOAD
              </WhiteButton>
              <RegularButton
                width='13rem'
                display='flex'
                onClick={handleUpload}
                disabled={isUploading || uploadList.length < 1}
              >
                UPLOAD FILES
                {isUploading && (
                  <span style={{ marginLeft: '10px' }}>
                    <LoadSpinner
                      size={20}
                      value={progressPercentage * 0.01}
                      intent={Intent.SUCCESS}
                    />
                  </span>
                )}
              </RegularButton>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AccountUploadDialogBox;
