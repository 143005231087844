import React from 'react';
import { Dialog } from '@blueprintjs/core';
import EditTransactionForm from '../../forms/transaction-forms/EditTransactionForm';

const TransactionEditDialogBox = ({
  transaction,
  handleClose,
  handleEditTransaction,
  selectKeywordFromList,
  keywords,
  keyword,
  isOpen,
  title,
}) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={title}
        isCloseButtonShown={false}
      >
        <EditTransactionForm
          transaction={transaction}
          handleClose={handleClose}
          handleEditTransaction={handleEditTransaction}
          selectKeywordFromList={selectKeywordFromList}
          keywords={keywords}
          keyword={keyword}
        />
      </Dialog>
    </>
  );
};

export default TransactionEditDialogBox;
