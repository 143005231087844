import React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { AlignCenter } from './styled-components';

// shows when there is no data to be displayed
const NonIdealStateComp = ({ icon, title, description }) => (
  <AlignCenter className={'non-ideal-state'}>
    <NonIdealState icon={icon} title={title} description={description} />
  </AlignCenter>
);

export default NonIdealStateComp;
