import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  createKeywordCategory,
  updateKeywordCategory,
} from '../../../api/administration';
import AppToast from '../../Toast';
import { Intent } from '@blueprintjs/core';
import KeywordCategoriesForm from '../../forms/global-settings-forms/KeywordCategoriesForm';

const KeywordCategoryDialogBox = ({
  isOpen,
  title,
  handleDialogClose,
  categoryObj,
  fetchKeywordCategories,
  fetchSingleKeywordCategory,
}) => {
  const [categoryName, setCategoryName] = useState('');
  const [rank, setRank] = useState('');
  const [status, setStatus] = useState('');
  const [categoryId, setCategoryId] = useState(undefined);

  useEffect(() => {
    if (categoryObj) {
      setCategoryName(categoryObj.categoryName || '');
      setRank(categoryObj.rank || '');
      setStatus(categoryObj.status || '');
      setCategoryId(categoryObj.categoryId || undefined);
    }
  }, [categoryObj]);

  const handleEdit = (keywordCategories) => {
    updateKeywordCategory(categoryId, keywordCategories)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchSingleKeywordCategory(categoryId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit keyword Category.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleDialogClose());
  };

  const handleCreate = (keywordCategories) => {
    createKeywordCategory(keywordCategories)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchKeywordCategories();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create keyword Category.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleDialogClose());
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleDialogClose}
      isCloseButtonShown={false}
    >
      <KeywordCategoriesForm
        title={title}
        onClose={handleDialogClose}
        categoryName={categoryName}
        rank={rank}
        status={status}
        handleEdit={handleEdit}
        handleCreate={handleCreate}
      />
    </Dialog>
  );
};

export default KeywordCategoryDialogBox;
