import { combineReducers } from 'redux';

const allTemplates = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_TEMPLATES_FULFILLED':
      return action.payload.data;
    case 'FETCH_TEMPLATES_REJECTED':
    case 'FETCH_ALL_BANKS_PENDING':
      return [];
    case 'FETCH_SINGLE_TEMPLATE_FULFILLED':
      return state.map((template) => {
        if (template.templateId === action.payload.data.templateId) {
          return action.payload.data;
        }
        return template;
      });
    case 'FETCH_SINGLE_TEMPLATE_REJECTED':
    case 'FETCH_SINGLE_TEMPLATE_PENDING':
      return state;
    default:
      return state;
  }
};

const isTemplatesFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_TEMPLATES_PENDING':
      return true;
    case 'FETCH_TEMPLATES_REJECTED':
    case 'FETCH_TEMPLATES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const singleTemplate = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_SINGLE_TEMPLATE_FULFILLED':
      return action.payload.data;
    case 'FETCH_SINGLE_TEMPLATE_REJECTED':
    case 'FETCH_SINGLE_TEMPLATE_PENDING':
      return state;
    default:
      return state;
  }
};

const isSingleTemplateFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_SINGLE_TEMPLATE_PENDING':
      return true;
    case 'FETCH_SINGLE_TEMPLATE_REJECTED':
    case 'FETCH_SINGLE_TEMPLATE_FULFILLED':
      return false;
    default:
      return state;
  }
};

const templates = combineReducers({
  allTemplates,
  isTemplatesFetching,
  isSingleTemplateFetching,
  singleTemplate,
});

export default templates;
