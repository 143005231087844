import React from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  WidgetHeader,
  Label,
  WidgetBody,
  CardDiv,
} from '../../styled-components';
import colors from '../../../utils/colors';
import {
  amountFormatter,
  getMonthDifference,
  monthYearOnly,
} from '../../../utils/functions';
import LoadSpinner from '../../LoadSpinner';
import NonIdealStateComp from '../../Non-Ideal-State';
import iconValue from '../../../utils/icons';
import {
  Table,
  TableData,
  TableHeader,
  TableRow,
} from '../../table/components/table-styled-components';
import shortid from 'shortid';

const MonteResultWidget = ({ monteResults, isFetching, selectedRow }) => {
  const tableHeaders = [
    'Result Type',
    'Year 1',
    'Year 2',
    'Year 3',
    'Year 4',
    'Year 5',
  ];
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ flexBasis: '800px', flexGrow: 1, height: '100%' }}
    >
      <>
        <WidgetHeader>
          <Label>
            Monté Result -{' '}
            {`${getMonthDifference(
              selectedRow.startDateRange,
              selectedRow.endDateRange
            )} Months ${monthYearOnly(
              selectedRow.startDateRange
            )} - ${monthYearOnly(selectedRow.endDateRange)}`}
          </Label>
        </WidgetHeader>
        {isFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px 0',
            }}
          >
            <LoadSpinner size={100} />
          </div>
        ) : !isFetching && !monteResults.length ? (
          <NonIdealStateComp
            icon={iconValue.warning}
            title={'No Results.'}
            description={'There are no results to be displayed.'}
          />
        ) : (
          <>
            <WidgetBody backgroundColor={colors.white}>
              <Table>
                <tbody>
                  <TableRow>
                    {tableHeaders.map((header, i) => (
                      <TableHeader key={shortid.generate()}>
                        {header}
                      </TableHeader>
                    ))}
                  </TableRow>
                  {monteResults.map((result, i) => (
                    <TableRow key={shortid.generate()}>
                      <TableHeader>
                        {i === 0 ? 'Raw Output' : 'From Min Bal'}
                      </TableHeader>
                      <TableData>{amountFormatter(result.year1)}</TableData>
                      <TableData>{amountFormatter(result.year2)}</TableData>
                      <TableData>{amountFormatter(result.year3)}</TableData>
                      <TableData>{amountFormatter(result.year4)}</TableData>
                      <TableData>{amountFormatter(result.year5)}</TableData>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </WidgetBody>
          </>
        )}
      </>
    </CardDiv>
  );
};

export default MonteResultWidget;
