import React, { useState, useEffect } from 'react';
import { Elevation, Tooltip, Icon } from '@blueprintjs/core';
import { connect } from 'react-redux';
import shortid from 'shortid';
import {
  Label,
  WidgetHeader,
  WidgetBody,
  WidgetBodyHeader,
  WidgetRow,
  BoldText,
  NameLink,
  CardDiv,
} from '../styled-components';
import colors from '../../utils/colors';
import {
  paginate,
  amountFormatter,
  getXDaysFromNow,
  dateToFilterText,
} from '../../utils/functions';
import WidgetPagination from './WidgetPagination';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';
import { fetchMaturingInvestments } from '../../actions/clients';
import LoadSpinner from '../../components/LoadSpinner';

const ClientsMaturingInvestmentsWidgetBase = ({
  singleClient,
  maturityLimit,
  handleCurrentComponentChange,
  setFromMaturingInvestmentsWidget,
  maturingInvestments,
  isMaturingInvestmentsFetching,
  ...props
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedInvestments, setPaginatedInvestments] = useState([]);

  const { fetchMaturingInvestments } = props;
  const pageLimit = 4;

  useEffect(() => {
    if (singleClient && singleClient.clientId) {
      fetchMaturingInvestments(singleClient.clientId);
    }
  }, [fetchMaturingInvestments, singleClient]);

  useEffect(() => {
    maturingInvestments.maturingInvestments &&
    maturingInvestments.maturingInvestments.length
      ? setPaginatedInvestments(
          paginate(
            [maturingInvestments.maturingInvestments],
            pageLimit,
            pageNumber
          )
        )
      : setPaginatedInvestments(paginate([], pageLimit, pageNumber));
  }, [maturingInvestments, pageNumber]);

  let dateRange;
  if (
    maturityLimit &&
    maturityLimit.length &&
    maturityLimit[0].globalSettingValue
  ) {
    dateRange = getXDaysFromNow(maturityLimit[0].globalSettingValue);
  }

  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget maturing-widget'}
      style={{ flexBasis: '420px', flexGrow: '2' }}
    >
      {isMaturingInvestmentsFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}
      {!isMaturingInvestmentsFetching && (
        <>
          <WidgetHeader>
            <Label display={'flex'} alignItems={'center'}>
              Maturing Investments
            </Label>
            <NameLink
              as='p'
              onClick={() => {
                setFromMaturingInvestmentsWidget(true);
                handleCurrentComponentChange(3);
              }}
            >
              View All
            </NameLink>
          </WidgetHeader>
          <div style={{ display: 'flex' }}>
            <div style={{ padding: '10px 20px' }}>
              <BoldText fontSize={'1.25rem'} color={colors.boldText}>
                {maturingInvestments.totalMaturingInvestments
                  ? maturingInvestments.totalMaturingInvestments
                  : '0'}
              </BoldText>
              <div style={{ display: 'flex', paddingTop: '10px' }}>
                Over the Next
                {maturityLimit &&
                maturityLimit.length &&
                maturityLimit[0].globalSettingValue
                  ? ` ${maturityLimit[0].globalSettingValue} `
                  : ' 0 '}
                Days
                <Tooltip
                  content={
                    dateRange ? dateToFilterText(dateRange).value : undefined
                  }
                >
                  <Icon
                    icon={iconValue.info}
                    iconSize={16}
                    color={colors.primary}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '5px',
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <div style={{ padding: '10px 20px' }}>
              <BoldText fontSize={'1.25rem'} color={colors.boldText}>
                {maturingInvestments.totalAmountMaturingInvestments
                  ? amountFormatter(
                      maturingInvestments.totalAmountMaturingInvestments
                    )
                  : '$0.00'}
              </BoldText>
              <div style={{ paddingTop: '10px' }}>Total Amount</div>
            </div>
          </div>
          <WidgetBody borderTop={` 1px solid ${colors.lightText}`}>
            <WidgetBodyHeader padding='20px 20px 10px'>
              <Label
                fontSize='1rem'
                width={maturingInvestments ? '25%' : '30%'}
              >
                Investment
              </Label>
              <Label
                fontSize='1rem'
                width={maturingInvestments ? '30%' : '15%'}
              >
                Type
              </Label>
              <Label
                fontSize='1rem'
                width={maturingInvestments ? '20%' : '22%'}
              >
                Amount
              </Label>
              <Label fontSize='1rem' width={'25%'}>
                Maturity Date
              </Label>
            </WidgetBodyHeader>
            {paginatedInvestments.length ? (
              paginatedInvestments[0].map((investment) => (
                <WidgetRow key={shortid.generate()}>
                  <div style={{ width: '25%' }}>{investment.investmentId}</div>
                  <div style={{ width: '30%' }}>
                    {investment.investmentType}
                  </div>
                  <div style={{ width: '20%' }}>
                    {amountFormatter(investment.amount)}
                  </div>
                  <div style={{ width: '25%' }}>{investment.maturityDate}</div>
                </WidgetRow>
              ))
            ) : (
              <NonIdealStateComp
                icon={iconValue.warning}
                title={'No Results.'}
                description={'There are no results to be displayed.'}
              />
            )}
          </WidgetBody>
          <WidgetPagination
            currentIndex={pageNumber}
            recordsLength={
              maturingInvestments.maturingInvestments &&
              maturingInvestments.maturingInvestments.length
                ? maturingInvestments.maturingInvestments.length
                : 0
            }
            setPageNumber={setPageNumber}
            pageLimit={pageLimit}
          />
        </>
      )}
    </CardDiv>
  );
};

const mapStateToProps = (state) => ({
  maturingInvestments: state.clients.maturingInvestments,
  isMaturingInvestmentsFetching: state.clients.isMaturingInvestmentsFetching,
});

const ClientsMaturingInvestmentsWidget = connect(mapStateToProps, {
  fetchMaturingInvestments,
})(ClientsMaturingInvestmentsWidgetBase);

export default ClientsMaturingInvestmentsWidget;
