import React, { useState, useEffect } from 'react';
import AccountTable from '../../../../components/table/AccountTable';
import {
  fetchCompleteAccounts,
  fetchAccountCategories,
  fetchAccountTypes,
  fetchAccount,
  fetchReviewAccounts,
} from '../../../../actions/accounts';
import { fetchBankNames, fetchBankDefaultDataGatheringFrequencies } from '../../../../actions/banks';
import {
  createAccount,
  updateAccount,
  bulkUploadAccounts,
} from '../../../../api/accounts';
import { fetchClientBanks } from '../../../../actions/clients';
import { connect } from 'react-redux';
import AccountDialogBox from '../../../../components/dialog/client-profile-dialog/AccountDialogBox';
import { ComponentBody } from '../../../../components/styled-components';
import AppToast from '../../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import find from 'lodash/find';
import ClientHeader from '../../../../components/headers/ClientHeader';
import axios from 'axios';
import AccountUploadDialogBox from '../../../../components/dialog/account-profile-dialog/AccountUploadDialogBox';
import { filterExtension } from '../../../../utils/functions';
import {
  uploadFile,
  deleteCanceledUploads,
} from '../../../../api/data-ingestion';
import UploadConfirmDialog from '../../../../components/dialog/data-ingestion/UploadConfirmDialog';
import AccountActions from './AccountActions';
import StagingAccountDeleteDialogBox from '../../../../components/dialog/account-profile-dialog/StagingAccountDeleteDialogBox';

//To cancel promises initiated by this source (cancel upload button)
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const AccountsBase = ({
  appUser,
  completeAccounts,
  isCompleteAccountsFetching,
  categories,
  bankNames,
  clientBanks,
  bankDefaultDataGatheringFrequencies,
  types,
  singleClient,
  reviewAccounts,
  isFetchingReviewAccounts,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [accountAttributes, setAccountAttributes] = useState({});

  //For accounts upload
  const [isAccountUploadDialogOpen, setIsAccountUploadDialogOpen] = useState(
    false
  );
  const [uploadList, setUploadList] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadErrorList, setUploadErrorList] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);
  const [uploadErrorCount, setUploadErrorCount] = useState(0);
  const [isUploadConfirmDialogOpen, setIsUploadConfirmDialogOpen] = useState(
    false
  );
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(
    false
  );
  const [selectedStagingAccounts, setSelectedStagingAccounts] = useState([]);

  const clientId = props.match.params.id;
  const {
    fetchCompleteAccounts,
    fetchAccountCategories,
    fetchBankNames,
    fetchBankDefaultDataGatheringFrequencies,
    fetchAccountTypes,
    fetchAccount,
    fetchReviewAccounts,
    fetchClientBanks,
  } = props;

  useEffect(() => {
    fetchAccountCategories();
    fetchBankNames();
    fetchBankDefaultDataGatheringFrequencies();
    fetchAccountTypes();
    fetchCompleteAccounts(clientId);
    fetchReviewAccounts(clientId);
    fetchClientBanks(clientId);
  }, [
    fetchAccountCategories,
    fetchBankNames,
    fetchBankDefaultDataGatheringFrequencies,
    fetchAccountTypes,
    fetchCompleteAccounts,
    fetchReviewAccounts,
    fetchClientBanks,
    clientId,
  ]);

  const openAddAccountDialog = () => {
    setTitle('Add New Account');
    setIsOpen(true);
  };

  const openEditAccountDialog = (attributes) => {
    setTitle('Edit Account');
    attributes.bankId = find(bankNames, ['label', attributes.bankName]).value;
    setAccountAttributes(attributes);
    setIsOpen(true);
  };

  const resetDialogState = () => {
    setAccountAttributes({});
  };

  const closeDialog = () => {
    setIsOpen(false);
    setTitle('');
    resetDialogState();
  };

  /**
   *  Creates an account
   * @param {obj} values  - The field values of the account
   * @param {bool} addMore - Flag to denote if the user wants to add another account
   * @param {func} resetForm - Resets formik form values
   */
  const handleCreateAccount = async (values, addMore, resetForm) => {
    const account = {
      clientId,
      ...values,
    };

    createAccount(account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchCompleteAccounts(clientId);
        if (!clientBanks.find(cb => cb.bankId === values.bankId)) {
          fetchClientBanks(clientId);
        }
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create account.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        if (addMore) {
          resetDialogState();
          resetForm();
        } else closeDialog();
      });
  };

  /**
   * Edits an account
   * @param {obj} values - account values
   */
  const handleEditAccount = async (values) => {
    const accountId = accountAttributes.accountId;
    const account = {
      accountId,
      clientId,
      ...values,
    };
    updateAccount(accountId, account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAccount(clientId, accountId);
        fetchCompleteAccounts(clientId);
        fetchReviewAccounts(clientId);
        if (!clientBanks.find(cb => cb.bankId === values.bankId)) {
          fetchClientBanks(clientId);
        }
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit account.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  const handleOpenUploadDialog = () => {
    setIsAccountUploadDialogOpen(true);
  };

  const handleCloseUploadDialog = () => {
    setUploadList([]);
    setIsUploadConfirmDialogOpen(false);
    setIsAccountUploadDialogOpen(false);
    setUploadErrorList([]);
    setUploadCount(0);
    setUploadErrorCount(0);
    source = CancelToken.source();
  };

  const handleDrop = (acceptedFiles) => {
    acceptedFiles = acceptedFiles.filter((file) =>
      filterExtension(file, ['csv'])
    );

    if (acceptedFiles.length > 0) {
      setUploadList([...uploadList, ...acceptedFiles]);
    } else {
      AppToast.show({
        message: 'File type not accepted.',
        intent: Intent.DANGER,
      });
    }
  };

  const removeSingleFile = (row) => {
    const newList = [...uploadList];
    newList.splice(row.id, 1);
    setUploadList(newList);
  };

  const removeMultipleFiles = (selectedRows) => {
    // get file name of selected rows
    const pickedList = selectedRows.map((item) => item.name);
    // filter upload list based on file name and return new array of items that don't include that file name
    const array = uploadList.filter((item) => !pickedList.includes(item.name));
    setUploadList(array);
  };

  const handleUpload = () => {
    setIsUploading(true);
    let counter = 0;
    let counterError = 0;
    let successfulFileList = [];

    const addFileToList = (listItem) => {
      successfulFileList.push(listItem);
    };
    const countSuccess = () => {
      counter++;
    };
    const countError = () => {
      counterError++;
    };
    let canceled = false;
    const sourceToken = source.token;
    uploadAllFiles({ sourceToken }, countSuccess, countError, addFileToList)
      .catch((err) => {
        if (axios.isCancel(err)) canceled = true;
      })
      .finally(() => {
        setIsUploading(false);
        setUploadCount(counter);
        setUploadErrorCount(counterError);
        if (!canceled) setIsUploadConfirmDialogOpen(true);
        else {
          if (successfulFileList.length) {
            deleteCanceledUploads(successfulFileList)
              .then(() => { })
              .catch((err) => {
                console.error(err);
              });
          }
          closeDialog();
        }
        setProgressPercentage(0);
        if (successfulFileList.length > 0 && !canceled) {
          const fileUpload = {
            clientId,
            successfulFileList,
          };
          bulkUploadAccounts(fileUpload)
            .then((res) => {
              AppToast.show({
                message: res.data.msg,
                intent: Intent.SUCCESS,
              });
            })
            .catch((err) => {
              AppToast.show({
                message: 'Failed to Extract Accounts from CSV file.',
                intent: Intent.DANGER,
              });
            })
            .finally(() => {
              fetchCompleteAccounts(clientId);
              fetchReviewAccounts(clientId);
              setIsUploading(false);
            });
        }
      });
  };

  /**
   * Returns a boolean list with promises
   * True indicates the promise is resolved
   * @param {*} sourceToken - source token so these requests can be aborted if the user choses to
   * @param {func} countSuccess - count stressful requests
   * @param {func} countError - counts failed requests
   */
  const uploadAllFiles = async (
    { sourceToken } = {},
    countSuccess,
    countError,
    addFileToList
  ) => {
    const bankId = 'None';
    const templateType = 'None';
    const totalSize = uploadList.reduce((sum, file) => sum + file.size, 0);
    const uploaded = {};
    const uploadedFiles = uploadList.map(async (file, index) => {
      const presignedURLResponse = await uploadFile(
        'client',
        clientId,
        bankId,
        templateType,
        file.name.split(' ').join(''),
        sourceToken
      ).catch((err) => {
        throw err; //<-- appears in the catch block of the parent
      });

      const presignedURL = presignedURLResponse.data;
      if (presignedURL) {
        delete axios.defaults.headers.common['Authorization'];
        // append file name to fields portion of presigned url response
        let formData = new FormData();
        Object.keys(presignedURL.fields).forEach((key) => {
          formData.append(key, presignedURL.fields[key]);
        });
        // Actual file has to be appended last.
        formData.append('file', file);

        await axios
          .post(presignedURL.url, formData, {
            cancelToken: sourceToken,
            onUploadProgress: calcProgress.bind(
              this,
              totalSize,
              uploaded,
              index
            ),
          })
          .then(() => {
            addFileToList(`${presignedURL.url}${presignedURL.fields.key}`);
            countSuccess();
          })
          .catch((err) => {
            setUploadErrorList((uploadErrorList) => [
              ...uploadErrorList,
              file.name,
            ]);
            countError();
            throw err; //<-- appears in the catch block of the parent
          });
      }
      return true;
    });
    return Promise.all(uploadedFiles); //<-- List holding promises for all of the requests. when the request is complete the list is going to be populated with true
  };

  /**
   * Helper Function that captures the total upload percentage
   * Works with onUploadProgress
   * @param {int} totalSize - total size of all files to be uploaded
   * @param {obj} uploaded - object that holds the total % uploaded for every file
   * @param {int} index - used to refer to a file
   * @param {obj} progressEvent - event that is being triggered by the axios post method
   */
  const calcProgress = (totalSize, uploaded, index, progressEvent) => {
    uploaded[index] = progressEvent.loaded;
    const totalDone = Object.values(uploaded).reduce(
      (acc, value) => acc + value,
      0
    );
    const totalProgress =
      Math.floor((totalDone * 100) / totalSize) > 100
        ? 100
        : Math.floor((totalDone * 100) / totalSize);
    setProgressPercentage(totalProgress);
  };

  const handleStagingAccountDialogOpen = (selectedRows) => {
    setSelectedStagingAccounts(selectedRows);
    setIsDeleteAccountDialogOpen(true);
  };

  const handleCloseDeleteAccountDialog = () => {
    setIsDeleteAccountDialogOpen(false);
    setSelectedStagingAccounts([]);
  };

  return (
    <ComponentBody padding='0'>
      <ClientHeader
        appUser={appUser}
        header={'Client'}
        clientName={singleClient && singleClient.clientName}
        hasButton={true}
        bulkAddButtonText='Bulk Upload Accounts'
        buttonText='Add New Account'
        downloadButtonText='Download Template'
        action={openAddAccountDialog}
        handleOpenUploadDialog={handleOpenUploadDialog}
        {...props}
      />
      <div style={{ padding: '14rem 2rem 4rem' }}>
        <AccountActions
          clientId={clientId}
          handleOpenUploadDialog={handleOpenUploadDialog}
        />
      </div>
      <AccountTable
        appUser={appUser}
        accounts={reviewAccounts}
        isFetching={isFetchingReviewAccounts}
        openEditAccountDialog={openEditAccountDialog}
        clientId={clientId}
        clientDataGatheringFrequency={singleClient && singleClient.dataGatheringFrequency}
        title={'These accounts require additional setup'}
        handleStagingAccountDialogOpen={handleStagingAccountDialogOpen}
        {...props}
      />
      <AccountTable
        appUser={appUser}
        accounts={completeAccounts}
        isFetching={isCompleteAccountsFetching}
        openEditAccountDialog={openEditAccountDialog}
        clientId={clientId}
        clientDataGatheringFrequency={singleClient && singleClient.dataGatheringFrequency}
        title={'All Accounts'}
        {...props}
      />
      <AccountDialogBox
        isOpen={isOpen}
        title={title}
        account={accountAttributes}
        categories={categories}
        types={types}
        bankNames={bankNames}
        clientBanks={clientBanks}
        bankDefaultDataGatheringFrequencies={bankDefaultDataGatheringFrequencies}
        clientDataGatheringFrequency={singleClient && singleClient.dataGatheringFrequency}
        closeDialog={closeDialog}
        handleCreateAccount={handleCreateAccount}
        handleEditAccount={handleEditAccount}
        progressPercentage={progressPercentage}
      />
      <AccountUploadDialogBox
        isOpen={isAccountUploadDialogOpen}
        handleClose={handleCloseUploadDialog}
        uploadList={uploadList}
        source={source}
        handleDrop={handleDrop}
        removeSingleFile={removeSingleFile}
        removeMultipleFiles={removeMultipleFiles}
        handleUpload={handleUpload}
        isUploading={isUploading}
        progressPercentage={progressPercentage}
        {...props}
      />
      <UploadConfirmDialog
        isOpen={isUploadConfirmDialogOpen}
        closeDialog={handleCloseUploadDialog}
        uploadAmount={uploadCount}
        uploadErrorAmount={uploadErrorCount}
        uploadErrorList={uploadErrorList}
      />
      <StagingAccountDeleteDialogBox
        isOpen={isDeleteAccountDialogOpen}
        clientId={clientId}
        fetchReviewAccounts={fetchReviewAccounts}
        reviewAccounts={selectedStagingAccounts}
        handleClose={handleCloseDeleteAccountDialog}
      />
    </ComponentBody>
  );
};

const mapStateToProps = (state) => ({
  completeAccounts: state.accounts.completeAccounts,
  isCompleteAccountsFetching: state.accounts.isCompleteAccountsFetching,
  categories: state.accounts.accountCategories,
  bankNames: state.banks.bankNames,
  clientBanks: state.clients?.allClientBanks,
  bankDefaultDataGatheringFrequencies: state.banks.bankDefaultDataGatheringFrequencies,
  types: state.accounts.accountTypes,
  isFetchingReviewAccounts: state.accounts.isFetchingReviewAccounts,
  reviewAccounts: state.accounts.reviewAccounts,
});

const Accounts = connect(mapStateToProps, {
  fetchCompleteAccounts,
  fetchAccountCategories,
  fetchBankNames,
  fetchBankDefaultDataGatheringFrequencies,
  fetchAccountTypes,
  fetchAccount,
  fetchReviewAccounts,
  fetchClientBanks,
})(AccountsBase);

export default Accounts;
