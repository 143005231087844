import React from 'react';
import { Radio, RadioGroup } from '@blueprintjs/core';

const RadioPagination = ({ pages, onClickSetPage, page }) => {
  let pageIds = [];
  //create id for each element
  for (let i = 0; i < pages; i++) {
    pageIds.push(i);
  }
  return (
    <RadioGroup
      onChange={(event) => {
        onClickSetPage(Number(event.target.value));
      }}
      selectedValue={page}
      inline={true}
      className={'radio-pagination'}
    >
      {pageIds.map((pageId) => (
        <Radio value={pageId} key={pageId} />
      ))}
    </RadioGroup>
  );
};

export default RadioPagination;
