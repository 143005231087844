import { Form, Formik } from 'formik';
import React from 'react';
import DateSelectField from '../../select-fields/DateSelectField';
import { AlignCenter } from '../../styled-components';
import FormActions from '../FormActions';
import * as Yup from 'yup';
import { dateToString, stringToDate } from '../../../utils/functions';

const schema = Yup.object().shape({
  startDate: Yup.string().required('Required!').nullable(),
  endDate: Yup.string().required('Required!').nullable(),
  dueDate: Yup.string().nullable(),
});

//This function transform date values to string date for backend
const transformValues = (values) => {
  let client = Object.assign({}, values);
  client.startDate = dateToString(client.startDate);
  client.endDate = dateToString(client.endDate);
  client.dueDate = client.dueDate ? dateToString(client.dueDate) : null;
  client.analysisReportId = client.analysisReportId || null;
  return client;
};

const StartReportForm = ({
  startDate,
  endDate,
  dueDate,
  analysisReportId,
  handleClose,
  handleStartReport,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column' padding='10px 0'>
        <Formik
          initialValues={{
            startDate: stringToDate(startDate),
            endDate: stringToDate(endDate),
            dueDate: stringToDate(dueDate),
            analysisReportId,
          }}
          onSubmit={(values) => {
            handleStartReport(transformValues(values), handleClose);
          }}
          validationSchema={schema}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                  <DateSelectField
                    name={'startDate'}
                    setFieldValue={setFieldValue}
                    value={values.startDate}
                    labelName={'Start Date'}
                  />
                  <DateSelectField
                    name={'endDate'}
                    setFieldValue={setFieldValue}
                    value={values.endDate}
                    labelName={'End Date'}
                    minDate={values.startDate}
                  />
                </AlignCenter>
                <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                  <DateSelectField
                    name={'dueDate'}
                    setFieldValue={setFieldValue}
                    value={values.dueDate}
                    labelName={'Due Date'}
                  />
                  <div style={{ width: '271px' }}></div>
                </AlignCenter>
                <FormActions
                  isSubmitting={isSubmitting}
                  onClose={handleClose}
                />
              </Form>
            );
          }}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default StartReportForm;
