import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import TableFilterActions from './TableFilterActions';
import {
  numberRangeToFilterText,
  multiSelectToFilterText,
  dateRangeChecker,
  dateToFilterText,
} from '../../../utils/functions';
import NumberRangeField from '../../select-fields/NumberRangeField';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';

const AccountGroupsFilterDialogBox = ({
  isOpen,
  title,
  handleClose,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
}) => {
  const [accountLow, setAccountLow] = useState('');
  const [accountHigh, setAccountHigh] = useState('');
  const [groupNames, setGroupNames] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [groupsNameItems, setGroupsNameItems] = useState([]);

  useEffect(() => {
    if (initialData.length) {
      setGroupsNameItems(
        initialData
          .map((item) => item.groupName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData]);

  const getFilterSummary = () => {
    const filters = [];
    groupNames.length &&
      filters.push(multiSelectToFilterText(groupNames, 'Group Names'));
    (accountLow || accountHigh) &&
      filters.push(numberRangeToFilterText(accountLow, accountHigh, 'Account'));
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Updated At'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter(
      'numberOfAccounts',
      !accountLow && !accountHigh
        ? undefined
        : [accountLow || undefined, accountHigh || undefined]
    );
    setFilter('updatedAt', dateRangeChecker(dateRange) ? dateRange : undefined);
    setFilter('groupName', groupNames.length ? groupNames : undefined);
    handleClose();
  };

  const handleClearFilters = () => {
    setAccountHigh('');
    setAccountLow('');
    setDateRange([]);
    setGroupNames([]);
    setAllFilters([]);
    setFilterSummary([]);
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0' }}>
        <div style={{ marginBottom: '20px' }}>
          <NumberRangeField
            labelName='Account Count'
            selectLow={setAccountLow}
            selectHigh={setAccountHigh}
            high={accountHigh}
            low={accountLow}
            isCurrency={false}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <DateSelectPlainField
            labelName='Updated At'
            dateRange={dateRange}
            handleDateChange={setDateRange}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setGroupNames}
            items={groupsNameItems}
            selectedItems={groupNames}
            label='Group Names'
          />
        </div>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default AccountGroupsFilterDialogBox;
