import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AlignCenter } from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import FormActions from '../FormActions';
import { RadioGroup, Radio } from '@blueprintjs/core';

const liquiditySchema = Yup.object().shape({
  levelOne: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  levelTwo: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  levelThree: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  levelFour: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  levelFive: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  cushion: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  shortTermDollars: Yup.string().matches(
    /^[-]?\d*\.?\d*$/,
    'Field must be a number.'
  ),
});

const LiquidityLevelForm = ({
  closeDialog,
  handleDateRangeChange,
  dateRange,
  levelOne,
  levelTwo,
  levelThree,
  levelFour,
  levelFive,
  cushion,
  shortTermDollars,
  handleEditLiquidityLevels,
  handleResetDefaults,
  isResetting,
}) => {
  return (
    <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
      <Formik
        initialValues={{
          levelOne,
          levelTwo,
          levelThree,
          levelFour,
          levelFive,
          cushion,
          shortTermDollars,
        }}
        onSubmit={(values) => {
          handleEditLiquidityLevels(values, dateRange);
        }}
        validationSchema={liquiditySchema}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <div style={{ width: '271px' }} className='radio-group'>
                <RadioGroup
                  label={'Liquidity Level Duration Range'}
                  onChange={handleDateRangeChange}
                  selectedValue={dateRange}
                  inline={true}
                >
                  <Radio
                    label='6 Months'
                    value='6'
                    style={{ marginRight: '20px' }}
                  />
                  <Radio label='1 Year' value='12' />
                </RadioGroup>
              </div>
              <div style={{ width: '271px' }}></div>
            </AlignCenter>
            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <ModalTextField
                type='text'
                name='levelOne'
                label='Liquidity Level 1'
                value={values.levelOne}
                autoComplete='off'
                isCurrency={true}
              />
              <ModalTextField
                type='text'
                name='levelTwo'
                label='Liquidity Level 2'
                value={values.levelTwo}
                autoComplete='off'
                isCurrency={true}
              />
            </AlignCenter>
            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <ModalTextField
                type='text'
                name='levelThree'
                label='Liquidity Level 3'
                value={values.levelThree}
                autoComplete='off'
                isCurrency={true}
              />
              <ModalTextField
                type='text'
                name='levelFour'
                label='Liquidity Level 4'
                value={values.levelFour}
                autoComplete='off'
                isCurrency={true}
              />
            </AlignCenter>
            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <ModalTextField
                type='text'
                name='levelFive'
                label='Liquidity Level 5'
                value={values.levelFive}
                autoComplete='off'
                isCurrency={true}
              />
              <ModalTextField
                type='text'
                name='cushion'
                label='Cushion'
                value={values.cushion}
                autoComplete='off'
                isCurrency={true}
              />
            </AlignCenter>
            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <ModalTextField
                type='text'
                name='shortTermDollars'
                label='Short Term Dollars'
                value={values.shortTermDollars}
                autoComplete='off'
                isCurrency={true}
              />
              <div style={{ width: '271px' }}></div>
            </AlignCenter>
            {/* {Form Footer} */}
            <FormActions
              isSubmitting={isSubmitting}
              onClose={closeDialog}
              thirdButton={true}
              buttonText='RESTORE DEFAULTS'
              action={handleResetDefaults}
              isDisabled={isResetting}
            />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default LiquidityLevelForm;
