import React from 'react';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
  FlexColumn,
  MessageText,
} from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import colors from '../../../utils/colors';
import { Formik, Form } from 'formik';
import { transformValues, checkPermissions } from '../../../utils/functions';
import * as Yup from 'yup';
import permissions from '../../../utils/permissions';
import LabelIcon from '../../../components/LabelIcon';
import LoadSpinner from '../../LoadSpinner';
import { useState } from 'react';
import RadioPagination from '../../RadioPagination';

/**
 * Regex: ^\d+$ -> one ore more numbers ex 123
 * Regex: ^ *\d+ *(?:, *\d+ *)*$ -> one or more numbers followed by zero or more (coma with one or more numbers) ex 123,2,33,4
 */
const investmentTemplateSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required('Required')
    .matches(/^\d*$/, 'Field must be a number.'),
  investmentId: Yup.string().matches(/^\d+$/, 'Field must be a number.'),
  settlementDate: Yup.string()
    .required('Required!')
    .matches(/^\d+$/, 'Field must be a number.'),
  maturityDate: Yup.string()
    .required('Required!')
    .matches(/^\d+$/, 'Field must be a number.'),
  couponRate: Yup.string().matches(/^\d+$/, 'Field must be a number.'),
  interestRate: Yup.string()
    .required('Required!')
    .matches(/^\d+$/, 'Field must be a number.'),
  settlementAmount: Yup.string()
    .required('Required!')
    .matches(/^\d+$/, 'Field must be a number.'),
  investmentType: Yup.string()
    .required('Required!')
    .matches(/^\d+$/, 'Field must be a number.'),
  faceValue: Yup.string()
    .required('Required!')
    .matches(/^\d+$/, 'Field must be a number.'),
  callDate: Yup.string().matches(/^\d+$/, 'Field must be a number.'),
  fund: Yup.string().matches(/^\d+$/, 'Field must be a number.'),
  headerStart: Yup.string()
    .matches(/^[-]?\d*$/, 'Field must be a number.')
    .required('Required'),
});

const InvestmentTemplateForm = ({
  closeDialog,
  handleSubmitTemplate,
  handleWarningTemplateOpen,
  accountNumber,
  investmentId,
  settlementDate,
  maturityDate,
  couponRate,
  settlementAmount,
  investmentType,
  interestRate,
  headerStart,
  appUser,
  template,
  fund,
  callDate,
  faceValue,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  return (
    <>
      <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
        <Formik
          initialValues={{
            accountNumber,
            investmentId,
            settlementDate,
            maturityDate,
            couponRate,
            settlementAmount,
            investmentType,
            headerStart,
            interestRate,
            fund,
            faceValue,
            callDate,
          }}
          onSubmit={(account) => {
            handleSubmitTemplate(
              transformValues(account),
              'Investment'
            );
          }}
          validationSchema={investmentTemplateSchema}
          validateOnBlur={false}
        >
          {({ isSubmitting, values }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter padding='0 50px 10px 50px'>
                <FlexColumn>
                  <MessageText>
                    Enter the column header numbers from your sample CSV into
                    the appropriate fields below.
                  </MessageText>
                  <MessageText>
                    Note: Not all fields are required. Specific fields are
                    intended to accommodate different bank templates. ( Page{' '}
                    {pageNumber + 1} of 2 )
                  </MessageText>
                </FlexColumn>
              </AlignCenter>
              {pageNumber === 0 && (
                <>
                  <AlignCenter
                    justifyContent='space-evenly'
                    margin='0 0 10px 0'
                  >
                    <ModalTextField
                      type='text'
                      name='accountNumber'
                      placeholder='Account Number'
                      label='Account Number'
                      value={values.accountNumber || ''}
                      autoComplete='off'
                    />

                    <ModalTextField
                      type='text'
                      name='investmentId'
                      placeholder='Investment ID'
                      label='Investment ID'
                      value={values.investmentId || ''}
                      autoComplete='off'
                    />
                  </AlignCenter>

                  <AlignCenter
                    justifyContent='space-evenly'
                    margin='0 0 10px 0'
                  >
                    <ModalTextField
                      type='text'
                      name='settlementDate'
                      placeholder='Settlement Date'
                      label='Settlement Date'
                      value={values.settlementDate || ''}
                      autoComplete='off'
                    />
                    <ModalTextField
                      type='text'
                      name='maturityDate'
                      placeholder='Maturity Date'
                      label='Maturity Date'
                      value={values.maturityDate || ''}
                      autoComplete='off'
                    />
                  </AlignCenter>

                  <AlignCenter
                    justifyContent='space-evenly'
                    margin='0 0 10px 0'
                  >
                    <ModalTextField
                      type='text'
                      name='settlementAmount'
                      placeholder='Settlement Amount'
                      label='Settlement Amount'
                      value={values.settlementAmount || ''}
                      autoComplete='off'
                    />
                    <ModalTextField
                      type='text'
                      name='couponRate'
                      placeholder='Coupon Rate'
                      label='Coupon Rate'
                      value={values.couponRate || ''}
                      autoComplete='off'
                    />
                  </AlignCenter>

                  <AlignCenter
                    justifyContent='space-evenly'
                    margin='0 0 10px 0'
                  >
                    <ModalTextField
                      type='text'
                      name='headerStart'
                      placeholder='Header Start'
                      label={
                        <LabelIcon
                          labelName={'Header Start'}
                          text={
                            'If there are no headers, set the Header Start field to -1.'
                          }
                        />
                      }
                      autoComplete='off'
                      value={values.headerStart || ''}
                    />
                    <ModalTextField
                      type='text'
                      name='investmentType'
                      placeholder='Investment Type'
                      label='Investment Type'
                      value={values.investmentType || ''}
                      autoComplete='off'
                    />
                  </AlignCenter>
                </>
              )}

              {pageNumber === 1 && (
                <>
                  <AlignCenter
                    justifyContent='space-evenly'
                    margin='0 0 10px 0'
                  >
                    <ModalTextField
                      type='text'
                      name='faceValue'
                      placeholder='Face Value'
                      label='Face Value'
                      value={values.faceValue || ''}
                      autoComplete='off'
                    />
                    <ModalTextField
                      type='text'
                      name='callDate'
                      placeholder='Call Date'
                      label='Call Date'
                      value={values.callDate || ''}
                      autoComplete='off'
                    />
                  </AlignCenter>
                  <AlignCenter
                    justifyContent='space-evenly'
                    margin='0 0 10px 0'
                  >
                    <ModalTextField
                      type='text'
                      name='interestRate'
                      placeholder='Interest Rate'
                      label='Interest Rate'
                      value={values.interestRate || ''}
                      autoComplete='off'
                    />
                    <ModalTextField
                      type='text'
                      name='fund'
                      placeholder='Fund'
                      label='Fund'
                      value={values.fund || ''}
                      autoComplete='off'
                    />
                  </AlignCenter>
                </>
              )}
              <RadioPagination
                pages={2}
                onClickSetPage={setPageNumber}
                page={pageNumber}
              />
              <AlignCenter
                justifyContent={
                  template && Object.keys(template).length === 0
                    ? 'flex-end'
                    : 'space-between'
                }
                padding='10px 20px 0 0'
                borderTop={`1px solid ${colors.lightText}`}
              >
                {checkPermissions(appUser.permList, [
                  permissions.DELETE_TEMPLATES,
                ]) && (
                    <WhiteButton
                      className={
                        template && Object.keys(template).length === 0
                          ? 'hide-button'
                          : ''
                      }
                      justifyContent='flex-start'
                      margin='10px 10px 10px 20px'
                      type='button'
                      color={colors.red}
                      hoverColor={colors.redHover}
                      onClick={handleWarningTemplateOpen}
                    >
                      DELETE
                    </WhiteButton>
                  )}
                <AlignCenter justifyContent='space-between' width='fit-content'>
                  <WhiteButton type='button' onClick={closeDialog}>
                    CLOSE
                  </WhiteButton>
                  <RegularButton
                    width='7rem'
                    display='flex'
                    type='submit'
                    disabled={isSubmitting}
                  >
                    SAVE
                    {isSubmitting && (
                      <span style={{ marginLeft: '10px' }}>
                        <LoadSpinner size={20} />
                      </span>
                    )}
                  </RegularButton>
                </AlignCenter>
              </AlignCenter>
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default InvestmentTemplateForm;
