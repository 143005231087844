import React, { useState } from 'react';
import { connect } from 'react-redux';
import { fetchAppUser, logoutAppUser } from '../actions/auth';
import { Route, Redirect } from 'react-router-dom';
import { ComponentContainer } from '../components/styled-components';
import Sidebar from '../components/navigation/Sidebar';
import { Auth } from 'aws-amplify';

const hasNoMFA = () => {
  return checkMFA === 'NOMFA';
};
const checkMFA = async () => {
  const mfa = await Auth.currentAuthenticatedUser().then(
    (res) => res.preferredMFA
  );
  return mfa;
};

function PrivateRouteBase({ component: Component, ...props }) {
  const [open, setOpen] = useState('open');
  const [isShowing, setIsShowing] = useState(true);

  const toggle = () => {
    if (isShowing) {
      setIsShowing(false);
      setOpen('close');
    } else {
      setIsShowing(true);
      setOpen('open');
    }
  };

  const { appUser } = props;
  let authenticated = appUser ? true : false;

  if (appUser && hasNoMFA()) authenticated = false;

  return (
    <Route
      {...props}
      render={(rest) => {
        const allProps = { ...props, ...rest };
        return authenticated ? (
          <>
            <Sidebar
              toggle={toggle}
              open={open}
              isShowing={isShowing}
              {...allProps}
            />
            <ComponentContainer className={open}>
              <Component
                toggle={toggle}
                open={open}
                isShowing={isShowing}
                {...allProps}
              />
            </ComponentContainer>
          </>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  isAppUserFetching: state.auth.isAppUserFetching,
  appUser: state.auth.appUser,
});

const PrivateRoute = connect(mapStateToProps, {
  fetchAppUser,
  logoutAppUser,
})(PrivateRouteBase);

export default PrivateRoute;
