import React from 'react';
import {
  StyleColumnCell,
  ActionIconButton,
} from '../../components/styled-components';
import MakeTable from '../../components/table/components/MakeTable';
import SortableHeader from './components/SortableHeader';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import {
  checkPermissions,
  getCollectionTypeColor,
  getCollectionTypeValue,
  localDateFormatter,
  amountFormatter,
} from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const BankClientRelationshipTable = ({
  appUser,
  banks,
  isFetching,
  handleEditBankDialogOpen,
  handleOpenTemplate,
  clientReportStatus,
  handleStartIngestionForBank,
  handleStartIngestionDialogOpen,
  ...props
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Bank Name' />;
        },
        accessor: 'bankName',
        width: '10%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='# of Accounts' />;
        },
        accessor: 'numOfAccounts',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => {
          return (
            <SortableHeader
              column={column}
              header='Average Balance'
              toolTipText='Average balance over twelve months.'
            />
          );
        },
        Cell: ({ cell: { value } }) => {
          return <span>{amountFormatter(value, 0)}</span>;
        },
        accessor: 'avgBalance12Months',
        width: '10%',
        sortType: 'basic',
        isVisible: true,
      },
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Creation Date' />;
        },
        accessor: 'createdAt',
        sortType: 'customDateTimeSort',
        width: '5%',
        isVisible: true,
        // Checks value to see if its admin and assign to user others
        Cell: ({ cell: { value } }) => {
          return <span>{localDateFormatter(value)}</span>;
        },
      },
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Last Updated' />;
        },
        accessor: 'updatedAt',
        width: '10%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{localDateFormatter(value)}</span>;
        },
      },
      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Active Status' />;
        },
        accessor: 'status',
        width: '10%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={
                value === 'active' ? colors.activeColor : colors.inActiveColor
              }
            >
              {value.toUpperCase()}
            </StyleColumnCell>
          );
        },
      },

      {
        Header: ({ column }) => {
          return <SortableHeader column={column} header='Collection Type' />;
        },
        accessor: 'collectionType',
        width: '10%',
        sortType: 'basic',
        filter: 'text',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={getCollectionTypeColor(value)}
            >
              {getCollectionTypeValue(value)}
            </StyleColumnCell >
          );
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: '1%',
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.EDIT_BANK_CLIENT_RELATIONSHIPS,
          permissions.MANAGE_TEMPLATES,
        ]),
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.EDIT_BANK_CLIENT_RELATIONSHIPS,
                ]) && (
                    <MenuItem
                      text='Edit'
                      onClick={() => handleEditBankDialogOpen(row.original)}
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.MANAGE_TEMPLATES,
                ]) && (
                    <MenuItem
                      text='Manage Investments Import Template'
                      onClick={() =>
                        handleOpenTemplate(row.original, 'Investment')
                      }
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.START_REPORT,
                ]) && (
                    <MenuItem
                      text='Start Ingestion'
                      onClick={() => handleStartIngestionForBank(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
      },
    ],
    [
      handleEditBankDialogOpen,
      handleOpenTemplate,
      appUser,
      handleStartIngestionForBank,
    ]
  );
  return (
    <TableContainer padding='0'>
      {/*This call invokes MakeTable components with given columns and data*/}
      <MakeTable
        columns={columns}
        data={banks}
        isFetching={isFetching}
        tableTitle='Banks'
        handleStartIngestionDialogOpen={handleStartIngestionDialogOpen}
        {...props}
      />
    </TableContainer>
  );
};

export default BankClientRelationshipTable;
