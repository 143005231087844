import axios from 'axios';
import { setHeader } from './index';

const TEMPLATES_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/templates`;

// fetch template from client summary secondary sub nav
// for investment and treasury services
export const fetchTemplate = async (bankClientRelId, templateType) => {
  const request = await setHeader(axios);
  return request.get(`${TEMPLATES_API}/all/${templateType}/${bankClientRelId}`);
};

// fetch all templates for global settings table
export const fetchAllTemplates = async () => {
  const request = await setHeader(axios);
  return request.get(`${TEMPLATES_API}/transaction`);
};

// fetch transaction template from global settings template table and for edit action
export const fetchSingleTemplate = async (templateId) => {
  const request = await setHeader(axios);
  return request.get(`${TEMPLATES_API}/${templateId}`);
};

export const updateTemplate = async (template) => {
  const request = await setHeader(axios);
  return request.post(`${TEMPLATES_API}/manage`, { template });
};

export const deleteTemplate = async (templateId) => {
  const request = await setHeader(axios);
  return request.delete(`${TEMPLATES_API}/${templateId}`);
};
