import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchCashVestScores } from '../../actions/analysis';
import EditCashVestScoreDialog from '../../components/dialog/analysis/EditCashVestScoreDialog';
import LoadSpinner from '../../components/LoadSpinner';
import CashVestHistoryTable from '../../components/table/CashVestHistoryTable';

const CashVestScoresBase = ({
  appUser,
  clientId,
  cashVestScores,
  isCashVestScoresFetching,
  fetchCashVestScores,
  ...props
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedCashVest, setSelectedCashVest] = useState({});

  useEffect(() => {
    fetchCashVestScores(clientId);
  }, [clientId, fetchCashVestScores]);

  const handleOpenEditDialog = (selectedRow) => {
    setIsEditDialogOpen(true);
    setSelectedCashVest(selectedRow);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedCashVest({});
  };

  return (
    <>
      {isCashVestScoresFetching ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      ) : (
        <>
          <CashVestHistoryTable
            cashVestScores={cashVestScores}
            isFetching={isCashVestScoresFetching}
            appUser={appUser}
            handleOpenEditDialog={handleOpenEditDialog}
            {...props}
          />
          <EditCashVestScoreDialog
            isOpen={isEditDialogOpen}
            handleClose={handleCloseEditDialog}
            selectedCashVest={selectedCashVest}
            clientId={clientId}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cashVestScores: state.analysis.cashVestScores,
  isCashVestScoresFetching: state.analysis.isCashVestScoresFetching,
});

const CashVestScores = connect(mapStateToProps, { fetchCashVestScores })(
  CashVestScoresBase
);

export default CashVestScores;
