import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { WidgetHeader, Label, TextValue, CardDiv } from '../styled-components';
import LoadSpinner from '../LoadSpinner';
import { ResponsiveBar } from '@nivo/bar';
import colors, { colorList } from '../../utils/colors';
import iconValue from '../../utils/icons';
import NonIdealStateComp from '../Non-Ideal-State';

const CashVestWidget = ({ cashVestScores, isCashVestScoreFetching }) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ width: '600px' }}
    >
      {isCashVestScoreFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {!isCashVestScoreFetching && cashVestScores.length === 0 && (
        <>
          <WidgetHeader>
            <Label>cashVest Score</Label>
          </WidgetHeader>
          <NonIdealStateComp
            icon={iconValue.warning}
            title={'No Results.'}
            description={'There are no results to be displayed.'}
          />
        </>
      )}

      {!isCashVestScoreFetching && cashVestScores && cashVestScores.length > 0 && (
        <>
          <WidgetHeader>
            <Label>cashVest Score</Label>
          </WidgetHeader>
          <WidgetHeader padding='10px 20px' display='block'>
            <Label fontSize='1.5rem'>
              {cashVestScores && cashVestScores[0]}
            </Label>
            <TextValue as='p' padding='0'>
              current score
            </TextValue>
          </WidgetHeader>

          <div
            style={{
              height: '15.8rem',
              display: 'flex',
              background: `${colors.containerBackground}`,
            }}
          >
            <ResponsiveBar
              data={cashVestScores[1]}
              keys={[
                'Cash Flow',
                'Investment',
                'Liquidity Proficiency',
                'Value Score',
                'Warnick Rate',
              ]}
              indexBy='monthYear'
              colors={colorList}
              margin={{ top: 50, right: 120, bottom: 50, left: 60 }}
              padding={0.75}
              enableGridY={false}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 10,
              }}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 10,
              }}
              enableLabel={false}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: '#a9acbe',
                      strokeWidth: 1,
                    },
                  },
                },
                tooltip: {
                  container: { color: colors.boldText, fontWeight: 700 },
                },
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  translateX: 20,
                  translateY: 0,
                  itemsSpacing: 1,
                  itemWidth: 20,
                  itemHeight: 25,
                  itemDirection: 'left-to-right',
                  symbolSize: 10,
                  symbolShape: 'square',
                },
              ]}
            />
          </div>
        </>
      )}
    </CardDiv>
  );
};

export default CashVestWidget;
