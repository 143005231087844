import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import ClientForm from '../forms/ClientForm';
import SameNameWarning from '../../components/dialog/SameNameWarning';

const ClientsDialogBox = ({
  isOpen,
  appUser,
  title,
  closeDialog,
  handleWarningDialogClose,
  categories,
  types,
  selectedClient,
  handleCreateClient,
  handleEditClient,
  isWarningDialogOpen,
  warningName,
  isTryingToSubmit,
  isOverwriting,
  allowedReportFrequencies
}) => {
  const [clientName, setClientName] = useState('');
  const [budget, setBudget] = useState('');
  const [state, setState] = useState('');
  const [clientType, setClientType] = useState('');
  const [clientCategory, setClientCategory] = useState('');
  const [status, setStatus] = useState(1);
  const [clientId, setClientId] = useState(undefined);
  const [allCategories, setAllCategories] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [initialInterest, setInitialInterest] = useState('');
  const [reportFrequency, setReportFrequency] = useState(undefined);
  const [reportCycle, setReportCycle] = useState(undefined);
  const [dataGatheringFrequency, setDataGatheringFrequency] = useState(undefined);

  useEffect(() => {
    if (selectedClient) {
      setClientName(selectedClient.clientName || '');
      setBudget(selectedClient.budget || '');
      setState(selectedClient.state || '');
      setClientType(selectedClient.clientType || '');
      setClientCategory(selectedClient.clientCategory || '');
      setStatus(selectedClient.status >= 0 ? selectedClient.status : 1);
      setClientId(selectedClient.clientId || undefined);
      setInitialInterest(selectedClient.initialInterest || '');
      setReportFrequency(selectedClient.reportFrequency || undefined);
      setReportCycle(selectedClient.reportCycle || undefined);
      setDataGatheringFrequency(selectedClient.dataGatheringFrequency || undefined);
    }
    setAllCategories(categories);
    setAllTypes(types);
  }, [selectedClient, categories, types]);

  return (
    <>
      {isWarningDialogOpen && (
        <SameNameWarning
          parentTitle={title}
          isOpen={isWarningDialogOpen}
          handleWarningDialogClose={handleWarningDialogClose}
          warningName={warningName}
          handleIgnoreSameName={
            title === 'Add New Client' ? handleCreateClient : handleEditClient
          }
          isOverwriting={isOverwriting}
        />
      )}
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title}
        isCloseButtonShown={false}
      >
        <ClientForm
          title={title}
          appUser={appUser}
          clientName={clientName}
          initialInterest={initialInterest}
          clientCategory={clientCategory}
          categories={allCategories}
          clientType={clientType}
          types={allTypes}
          budget={budget}
          status={status}
          state={state}
          clientId={clientId}
          dataGatheringFrequency={dataGatheringFrequency}
          reportFrequency={reportFrequency}
          reportCycle={reportCycle}
          allowedReportFrequencies={allowedReportFrequencies}
          handleCreateClient={handleCreateClient}
          handleEditClient={handleEditClient}
          isTryingToSubmit={isTryingToSubmit}
          closeDialog={closeDialog}
        />
      </Dialog>
    </>
  );
};

export default ClientsDialogBox;
