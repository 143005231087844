import axios from 'axios';
import { setHeader } from './index';

const DATA_INGESTION_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/data-ingestion`;

export const downloadFile = async (fileId) => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/download/${fileId}`);
};

// send files individually to s3 to retrieve presigned url for each file
export const uploadFile = async (
  type,
  clientId,
  bankId,
  templateType,
  fileName,
  sourceToken
) => {
  const request = await setHeader(axios);
  return request.get(
    `${DATA_INGESTION_API}/upload/${type}/${clientId}/${bankId}/${templateType}/${fileName}`,
    {
      cancelToken: sourceToken,
    }
  );
};

export const uploadFileListToDatabase = async (fileUpload) => {
  const request = await setHeader(axios);
  return request.post(`${DATA_INGESTION_API}/start-extraction`, { fileUpload });
};

export const fetchExtractionStatus = async (batchId) => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/extraction-status/${batchId}`);
};

export const fetchOpenReports = async () => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/open-reports`);
};

export const fetchValidationHistory = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/validation/${clientId}`);
};

export const fetchBatchesForClient = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/batches/all/${clientId}`);
};

export const fetchSingleClientBatch = async (batchId) => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/batches/${batchId}`);
};

export const deleteOpenReport = async (openReport) => {
  const request = await setHeader(axios);
  return request.delete(
    `${DATA_INGESTION_API}/open-reports/${openReport.clientId}`
  );
};

export const fetchBatchAccounts = async (batchId) => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/accounts/all/${batchId}`);
};

export const fetchSingleBatchAccount = async (batchAccountId) => {
  const request = await setHeader(axios);
  return request.get(`${DATA_INGESTION_API}/accounts/${batchAccountId}`);
};

export const deleteBatch = async (batchId) => {
  const request = await setHeader(axios);
  return request.delete(`${DATA_INGESTION_API}/batches/${batchId}`);
};

export const deleteBatchAccount = async (batchAccountId) => {
  const request = await setHeader(axios);
  return request.delete(`${DATA_INGESTION_API}/accounts/${batchAccountId}`);
};

export const validateBatchAccount = async (batchAccountId) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/accounts/validate`, {
    accounts: { batchAccountId: batchAccountId },
  });
};

export const validateBatch = async (batchId) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/validate`, {
    accounts: { batchId: batchId },
  });
};

export const ignoreBatchAccount = async (batchAccountId) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/accounts/ignore`, {
    accounts: { batchAccountId: batchAccountId },
  });
};

export const restoreBatchAccount = async (batchAccountId) => {
  const request = await setHeader(axios);
  return request.put(
    `${DATA_INGESTION_API}/accounts/restore/${batchAccountId}`
  );
};

export const deleteCanceledUploads = async (fileList) => {
  const request = await setHeader(axios);
  return request.delete(`${DATA_INGESTION_API}/files/`, {
    data: { fileUpload: { fileList } },
  });
};

export const fetchExtractionStatusForClient = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(
    `${DATA_INGESTION_API}/extraction-status/client/${clientId}`
  );
};

export const retryExtraction = async (auditId) => {
  const request = await setHeader(axios);
  return request.post(`${DATA_INGESTION_API}/retry-extraction/`, { auditId });
};

export const startIngestionTransactionsSingleBank = async (bcrId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/report/transaction/${bcrId}`, { batch });
};

export const startIngestionTransactionsClient = async (clientId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/transaction/${clientId}`, { batch });
};

export const startIngestionTreasuryServicesSingleBank = async (bcrId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/report/treasury/${bcrId}`, { batch });
};

export const startIngestionTreasuryServicesClient = async (clientId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/treasury/${clientId}`, { batch });
};

export const startIngestionInvestmentsSingleBank = async (bcrId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/report/investment/${bcrId}`, { batch });
};

export const startIngestionInvestmentsClient = async (clientId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/investment/${clientId}`, { batch });
};

export const startIngestionHybridAccountSingleBank = async (bcrId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/report/hybrid/${bcrId}`, { batch });
};

export const startIngestionHybridAccountClient = async (clientId, batch) => {
  const request = await setHeader(axios);
  return request.put(`${DATA_INGESTION_API}/batches/hybrid/${clientId}`, { batch });
};

export const downloadIngestionFile = async (fileAuditId, fileName,stageFolderName) => {
  const request = await setHeader(axios);
  return request.post(`${DATA_INGESTION_API}/download/${fileAuditId}`, {
    fileName: fileName,
    stageFolderName: stageFolderName,
  });
};
