import { combineReducers } from 'redux';

const allBanks = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_BANKS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_BANKS_REJECTED':
    case 'FETCH_ALL_BANKS_PENDING':
      return [];
    default:
      return state;
  }
};

const isAllBanksFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_BANKS_PENDING':
      return true;
    case 'FETCH_ALL_BANKS_REJECTED':
    case 'FETCH_ALL_BANKS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const bankNames = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_BANK_NAMES_FULFILLED':
      return action.payload.data;
    case 'FETCH_BANK_NAMES_REJECTED':
    case 'FETCH_BANK_NAMES_PENDING':
      return [];
    default:
      return state;
  }
};

const allowedDataGatheringValues = (state={}, action) => {
  switch (action.type) {
    case 'FETCH_ALLOWED_DATA_GATHERING_VALUES_FULFILLED':
      return action.payload.data[0];
    case 'FETCH_ALLOWED_DATA_GATHERING_VALUES_REJECTED':
    case 'FETCH_ALLOWED_DATA_GATHERING_VALUES_PENDING':
      return {};
    default:
      return state;
  }
};

const bankDefaultDataGatheringFrequencies = (state={}, action) => {
  switch (action.type) {
    case 'FETCH_BANK_DEFAULT_DATA_GATHERING_FREQUENCIES_FULFILLED':
      return action.payload.data;
    case 'FETCH_BANK_DEFAULT_DATA_GATHERING_FREQUENCIES_REJECTED':
    case 'FETCH_BANK_DEFAULT_DATA_GATHERING_FREQUENCIES_PENDING':
      return {};
    default:
      return state;
  }
};

const banks = combineReducers({
  allBanks,
  isAllBanksFetching,
  bankNames,
  allowedDataGatheringValues,
  bankDefaultDataGatheringFrequencies
});

export default banks;
