import React from 'react';
import { ComponentHeader, HeaderText, WhiteButton } from '../styled-components';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import QuandlData from './header-data/QuandlData';

const BankHeader = ({
  appUser,
  header,
  hasButton,
  buttonText,
  action,
  ...props
}) => {
  const { open } = props;
  return (
    <ComponentHeader className={open}>
      <QuandlData {...props} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <HeaderText>{header}</HeaderText>
        {hasButton &&
          checkPermissions(appUser.permList, [permissions.ADD_BANKS]) && (
            <WhiteButton width='18rem' margin='0 3rem 0 0' onClick={action}>
              {buttonText}
            </WhiteButton>
          )}
      </div>
    </ComponentHeader>
  );
};

export default BankHeader;
