import React from 'react';
import {
  ComponentHeader,
  HeaderText,
  MainSubNavContainer,
  WhiteButton,
} from '../styled-components';
import DataIngestionSubNav from '../navigation/SubNavigation/DataIngestionSubNav';
import QuandlData from './header-data/QuandlData';

const DataIngestionHeader = ({
  header,
  handleCurrentComponentChange,
  currentComponent,
  hasSubNav,
  hasButton,
  clientName,
  appUser,
  ...props
}) => {
  const { open } = props;
  return (
    <>
      <ComponentHeader className={open}>
        <QuandlData {...props} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <HeaderText>
            {header + (clientName ? ' - ' + clientName : '')}
          </HeaderText>
          {hasButton && (
            <WhiteButton
              width='18rem'
              margin='0 3rem 0 0'
              onClick={() => props.history.push('/data-ingestion')}
            >
              Back to Open Reports
            </WhiteButton>
          )}
        </div>
      </ComponentHeader>
      {hasSubNav && (
        <MainSubNavContainer className={open}>
          <DataIngestionSubNav
            handleCurrentComponentChange={handleCurrentComponentChange}
            currentComponent={currentComponent}
            appUser={appUser}
          />
        </MainSubNavContainer>
      )}
    </>
  );
};

export default DataIngestionHeader;
