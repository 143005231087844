import React from 'react';
import { ComponentBody } from '../../components/styled-components';
import InvestmentTable from '../../components/table/InvestmentTable';

const AllInvestments = ({
  appUser,
  investments,
  isAllInvestmentsFetching,
  showUsersClients,
  maturityLimit,
  updatePaginate,
  resultCount,
  pageCount,
  isServerSidePagination,
  ...props
}) => {
  return (
    <ComponentBody padding='5rem 2rem 3rem'>
      <InvestmentTable
        appUser={appUser}
        investments={investments}
        isAllInvestmentsFetching={isAllInvestmentsFetching}
        title={'All Investments'}
        isCurrentInvestments={true}
        showUsersClients={showUsersClients}
        maturityLimit={maturityLimit}
        updatePaginate={updatePaginate}
        resultCount={resultCount}
        pageCount={pageCount}
        isServerSidePagination={isServerSidePagination}
        {...props}
      />
    </ComponentBody>
  );
};

export default AllInvestments;
