import React, { useState } from 'react';
import {
  ComponentHeader,
  HeaderText,
  WhiteButton,
  MainSubNavContainer,
} from '../styled-components';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import ClientSubNavigation from '../../components/navigation/SubNavigation/ClientSubNavigation';
import ClientsDialogBox from '../../components/dialog/ClientsDialogBox';
import { checkSameName } from '../../utils/functions';
import { createClient } from '../../api/clients';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../components/Toast';
import QuandlData from './header-data/QuandlData';

const ClientHeader = ({
  appUser,
  header,
  clientName,
  hasButton,
  buttonText,
  action,
  hasClientSubNav,
  handleCurrentComponentChange,
  currentComponent,
  bulkAddButtonText,
  types,
  categories,
  clientNames,
  allowedReportFrequencies,
  fetchAllClients,
  isAccountGroupProfile,
  handleAddAccountGroupDialogOpen,
  downloadButtonText,
  handleOpenUploadDialog,
  ...props
}) => {
  //Client create modal state variables
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [warningClientName, setWarningClientName] = useState('');
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);
  const [clientState, setClientState] = useState({});
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [isOverwriting, setIsOverWriting] = useState(false);

  let clientId;
  if (isAccountGroupProfile) {
    clientId = props.match.params.clientId;
    handleCurrentComponentChange = (value) => {
      props.history.push({
        pathname: `/client-management/${clientId}`,
        state: value,
      });
    };
    currentComponent = 2;
  }

  //client create modal functions
  const openAddNewClientDialog = () => {
    setSelectedClient({});
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setIsWarningDialogOpen(false);
    setSelectedClient({});
    setClientState({});
    setWarningClientName('');
    setIsTryingToSubmit(false);
    setIsOverWriting(false);
  };

  /**
   * Submits the created client
   * If the name given already belongs to another client it throws an exception and enables a confirmation modal
   * When run with confirmation modal enabled (overwrite confirmed) it creates the client as well
   * @param {Obj} values  - fields of the submit, if none specified it defaults to what is saved in the ongoing client state (on confirm)
   */
  const handleCreateClient = (values = clientState) => {
    const client = {
      ...values,
    };
    setIsTryingToSubmit(true);

    const isNameOverwritten =
      checkSameName(client.addClientName, clientNames) && !isWarningDialogOpen;

    try {
      if (isNameOverwritten) throw new Error('NAME_OVERWRITE');
      setIsOverWriting(true);
      //No conflicts create client
      createClient(client)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
          fetchAllClients();
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to create client.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          closeDialog();
        });
    } catch (err) {
      if (err.message === 'NAME_OVERWRITE') {
        setIsWarningDialogOpen(true);
        setClientState(client);
        setWarningClientName(client.addClientName);
      }
    }
  };

  const handleWarningDialogClose = () => {
    setIsTryingToSubmit(false);
    setIsWarningDialogOpen(false);
  };

  //End of client create modal

  const { open } = props;

  return (
    <>
      <ClientsDialogBox
        isOpen={isOpen}
        appUser={appUser}
        title={'Add New Client'}
        closeDialog={closeDialog}
        categories={categories}
        types={types}
        selectedClient={selectedClient}
        handleCreateClient={handleCreateClient}
        isTryingToSubmit={isTryingToSubmit}
        isWarningDialogOpen={isWarningDialogOpen}
        warningName={warningClientName}
        handleWarningDialogClose={handleWarningDialogClose}
        isOverwriting={isOverwriting}
        allowedReportFrequencies={allowedReportFrequencies}
      />
      <ComponentHeader
        className={open}
        flexDirection='column'
        justifyContent='space-evenly'
      >
        <QuandlData {...props} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <HeaderText>
            {header} {clientName ? `- ${clientName}` : ''}
          </HeaderText>
          {hasButton &&
            checkPermissions(appUser.permList, [
              permissions.ADD_CLIENTS,
              permissions.ADD_ACCOUNTS,
            ]) && (
              <div style={{ display: 'flex' }}>
                {currentComponent === 2 && !isAccountGroupProfile && (
                  <WhiteButton
                    width='18rem'
                    margin='0 1rem 0 0'
                    onClick={handleAddAccountGroupDialogOpen}
                  >
                    Add Group
                  </WhiteButton>
                )}
                {isAccountGroupProfile && (
                  <WhiteButton
                    width='18rem'
                    margin='0 1rem 0 0'
                    onClick={() =>
                      props.history.push({
                        pathname: `/client-management/${clientId}`,
                        state: 2,
                      })
                    }
                  >
                    Back to Account Groups
                  </WhiteButton>
                )}
                <WhiteButton
                  width='18rem'
                  margin='0 1rem 0 0'
                  onClick={action || openAddNewClientDialog}
                >
                  {buttonText}
                </WhiteButton>
              </div>
            )}
        </div>
      </ComponentHeader>
      {hasClientSubNav && (
        <MainSubNavContainer className={open}>
          <ClientSubNavigation
            handleCurrentComponentChange={handleCurrentComponentChange}
            currentComponent={currentComponent}
            appUser={appUser}
          />
        </MainSubNavContainer>
      )}
    </>
  );
};

export default ClientHeader;
