import React, { useState } from 'react';
import { BorderComponentContainer } from '../../components/styled-components';
import colors from '../../utils/colors';
import AnalysisOptionsSubNav from '../../components/navigation/SubNavigation/AnalysisOptionsSubNav';
import MonteCarlo from './MonteCarlo';
import ShortTermModel from './ShortTermModel';
import CashVestScores from './CashVestScores';

const Analysis = ({ appUser, clientId, clientName, ...props }) => {
  const [currentComponent, setCurrentComponent] = useState(
    props.location ? Number(props.location.state) : 0
  );

  const handleCurrentComponentChange = (value) => {
    setCurrentComponent(value);
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        margin: '14rem 1rem 3rem',
        paddingBottom: '1rem',
      }}
    >
      <BorderComponentContainer
        borderRadius='5px 5px 0 0'
        marginBottom='2rem'
        padding='1rem 0 0 0'
        boxShadow={`0px 3px 6px ${colors.lightText}`}
        minWidth='765px'
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${colors.lightText}`,
          }}
        >
          <AnalysisOptionsSubNav
            currentComponent={currentComponent}
            handleCurrentComponentChange={handleCurrentComponentChange}
            appUser={appUser}
            clientId={clientId}
          />
        </div>
        <div style={{ padding: '1rem' }}>
          {currentComponent === 0 && (
            <MonteCarlo appUser={appUser} clientId={clientId} {...props} />
          )}
          {currentComponent === 1 && (
            <ShortTermModel appUser={appUser} clientId={clientId} {...props} />
          )}
          {currentComponent === 2 && (
            <CashVestScores clientId={clientId} appUser={appUser} {...props} />
          )}
        </div>
      </BorderComponentContainer>
    </div>
  );
};

export default Analysis;
