import { Dialog, Intent } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchCashVestScores,
  fetchCashVestSubScores,
} from '../../../actions/analysis';
import { updateCashVestScores } from '../../../api/analysis';
import EditCashVestForm from '../../forms/analytics/EditCashVestForm';
import LoadSpinner from '../../LoadSpinner';
import AppToast from '../../Toast';

const EditCashVestScoreDialogBase = ({
  isOpen,
  handleClose,
  selectedCashVest,
  cashVestSubScores,
  isFetching,
  fetchCashVestSubScores,
  clientId,
  fetchCashVestScores,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    isOpen && fetchCashVestSubScores(selectedCashVest.cashVestId);
  }, [fetchCashVestSubScores, selectedCashVest, isOpen]);

  const subScores = [
    {
      label: 'Value Score',
      valueName: 'valueScore',
      commentName: 'valueScoreComment',
      valueRange: [2, 20],
    },
    {
      label: 'Liquidity Proficiency',
      valueName: 'liquidityProficiency',
      commentName: 'liquidityProficiencyComment',
      valueRange: [3, 30],
    },
    {
      label: 'Warnick Rate Indicator',
      valueName: 'warnickRate',
      commentName: 'warnickRateComment',
      valueRange: [2, 20],
    },
    {
      label: 'Cashflow Optimization',
      valueName: 'cashflow',
      commentName: 'cashflowComment',
      valueRange: [2, 20],
    },
    {
      label: 'Investment Policy & Implementation',
      valueName: 'investment',
      commentName: 'investmentComment',
      valueRange: [1, 10],
    },
  ];

  const handleEdit = (cashVest) => {
    updateCashVestScores(selectedCashVest.cashVestId, cashVest)
      .then((res) => {
        AppToast.show({ message: res.data.msg, intent: Intent.SUCCESS });
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to edit cashVest scores.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchCashVestScores(clientId);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={`CashVest Score - ${subScores[pageNumber].label}`}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      {isFetching ? (
        <div style={{ padding: '20px' }}>
          <LoadSpinner size={100} />
        </div>
      ) : (
        <EditCashVestForm
          cashVestSubScores={cashVestSubScores}
          subScores={subScores}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          closeDialog={handleClose}
          handleEdit={handleEdit}
        />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  cashVestSubScores: state.analysis.cashVestSubScores,
  isFetching: state.analysis.isCashVestSubScoresFetching,
});

const EditCashVestScoreDialog = connect(mapStateToProps, {
  fetchCashVestSubScores,
  fetchCashVestScores,
})(EditCashVestScoreDialogBase);

export default EditCashVestScoreDialog;
