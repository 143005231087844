import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import LiquidityLevelForm from '../../forms/analytics/LiquidityLevelForm';
import {
  updateLiquidityLevels,
  fetchDefaultLiquidityLevels,
} from '../../../api/analysis';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../Toast';

const LiquidityLevelDialog = ({
  isOpen,
  title,
  liquidityLevels,
  setIsLiquidityLevelDialogOpen,
  selectedRow,
  // isChanged is for re-fetching the data balance table and graph
  setIsChanged,
}) => {
  const [levelOne, setLevelOne] = useState(null);
  const [levelTwo, setLevelTwo] = useState(null);
  const [levelThree, setLevelThree] = useState(null);
  const [levelFour, setLevelFour] = useState(null);
  const [levelFive, setLevelFive] = useState(null);
  const [cushion, setCushion] = useState(null);
  const [shortTermDollars, setShortTermDollars] = useState(null);
  const [dateRange, setDateRange] = useState('6');
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    if (liquidityLevels) {
      setDateRange(
        liquidityLevels.dateRange ? liquidityLevels.dateRange.toString() : '6'
      );
      setLevelOne(liquidityLevels.levelOne);
      setLevelTwo(liquidityLevels.levelTwo);
      setLevelThree(liquidityLevels.levelThree);
      setLevelFour(liquidityLevels.levelFour);
      setLevelFive(liquidityLevels.levelFive);
      setCushion(liquidityLevels.cushion);
      setShortTermDollars(liquidityLevels.shortTermDollars);
    }
  }, [liquidityLevels]);

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  const closeDialog = () => {
    setIsLiquidityLevelDialogOpen(false);
  };

  const handleEditLiquidityLevels = (values, dateRange) => {
    const { accountGroupId } = liquidityLevels;
    const levelsObject = { dateRange, ...values };
    updateLiquidityLevels(accountGroupId, levelsObject)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit liquidity levels.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsChanged(true);
        closeDialog();
      });
  };

  const handleResetDefaults = () => {
    setIsResetting(true);
    fetchDefaultLiquidityLevels(selectedRow.monteCarloId)
      .then((res) => {
        setDateRange(res.data[0].dateRange.toString());
        setLevelOne(res.data[0].levelOne);
        setLevelTwo(res.data[0].levelTwo);
        setLevelThree(res.data[0].levelThree);
        setLevelFour(res.data[0].levelFour);
        setLevelFive(res.data[0].levelFive);
        setCushion(res.data[0].cushion);
        setShortTermDollars(res.data[0].shortTermDollars);
      })
      .catch(() => {
        AppToast.show({
          message: 'Failed to reset liquidity levels.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsChanged(true);
        setIsResetting(false);
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={closeDialog}
      title={title}
      isCloseButtonShown={false}
    >
      <LiquidityLevelForm
        closeDialog={closeDialog}
        handleDateRangeChange={handleDateRangeChange}
        dateRange={dateRange}
        levelOne={levelOne}
        levelTwo={levelTwo}
        levelThree={levelThree}
        levelFour={levelFour}
        levelFive={levelFive}
        cushion={cushion}
        shortTermDollars={shortTermDollars}
        handleEditLiquidityLevels={handleEditLiquidityLevels}
        handleResetDefaults={handleResetDefaults}
        isResetting={isResetting}
      />
    </Dialog>
  );
};

export default LiquidityLevelDialog;
