import React from 'react';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from 'react-table';
import colors from '../../../utils/colors';
import { Button, InputGroup } from '@blueprintjs/core';
import {
  Label,
  AlignCenter,
  WhiteButton,
  RegularButton,
} from '../../styled-components';
import iconValue from '../../../utils/icons';
import { sortTypes } from '../../../utils/constantsList';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import LoadSpinner from '../../LoadSpinner';
import { TableFilterWrapper } from './table-styled-components';

const AddDialogTable = ({
  columns,
  data,
  isOpen,
  handleClose,
  title,
  isFetching,
  isAdding,
  handleAdd,
  addButtonText,
  searchName,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter,
    state,
    selectedFlatRows,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 }, sortTypes },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  const { globalFilter } = state;

  const selectedRows = selectedFlatRows.map((row) => row.original);

  //Checks if there is any data
  const haveData = rows.length > 0;

  const handleClear = () => {
    setGlobalFilter('');
  };

  const crossButton = (
    <>
      <Button icon={iconValue.cross} minimal={true} onClick={handleClear} />
    </>
  );

  return (
    <>
      <Dialog isOpen={isOpen} onClose={handleClose} className='large-dialog'>
        <div
          style={{
            padding: '20px 30px',
            borderBottom: `1px solid ${colors.lightText}`,
          }}
        >
          <Label>{title}</Label>
          <TableFilterWrapper style={{ margin: '15px 0' }}>
            <InputGroup
              style={{
                width: '100%',
                fontSize: '1.125rem',
                paddingLeft: '32px',
              }}
              leftIcon={iconValue.search}
              placeholder={`Search current ${searchName}`}
              rightElement={crossButton}
              type='text'
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value || undefined)}
            />
          </TableFilterWrapper>
        </div>
        <div
          style={{
            height: '650px',
            padding: '20px 30px',
            display: 'block',
            overflowY: 'auto',
          }}
        >
          {!globalFilter && (
            <AlignCenter padding='0 0 500px' style={{ fontSize: '1.25rem' }}>
              Search to find {searchName}.
            </AlignCenter>
          )}
          {globalFilter && !haveData && (
            <AlignCenter padding='0 0 500px' style={{ fontSize: '1.25rem' }}>
              No result found for {globalFilter}
            </AlignCenter>
          )}
          {globalFilter && haveData && (
            <DialogTableWrapper>
              <table {...getTableProps}>
                <thead>
                  {/* Loops through header element and renders with given width*/}
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getFooterGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          //Table headers props
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          width={column.width}
                        >
                          {/* Renders column header here */}
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {!isFetching &&
                    rows.length > 0 &&
                    rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </DialogTableWrapper>
          )}
        </div>
        <AlignCenter
          justifyContent='space-between'
          padding='0 30px'
          borderTop={`1px solid ${colors.lightText}`}
        >
          <div style={{ color: colors.purpleText, fontWeight: '700' }}>
            {selectedRows.length ? `${selectedRows.length} Selected` : ''}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <WhiteButton type='button' onClick={handleClose}>
              CLOSE
            </WhiteButton>
            <RegularButton
              padding='10px 30px'
              disabled={!selectedRows.length || isAdding}
              onClick={() => handleAdd(selectedRows)}
              display='flex'
            >
              {addButtonText}
              {isAdding && (
                <span style={{ marginLeft: '10px' }}>
                  <LoadSpinner size={20} />
                </span>
              )}
            </RegularButton>
          </div>
        </AlignCenter>
      </Dialog>
    </>
  );
};

const DialogTableWrapper = styled.div`
  display: inline-block;
  margin-right: 30px;
  box-shadow: 0 3px 10px ${colors.lightText};
  overflow-x: auto;
  box-shadow: ${(props) => props.boxShadow || '0'};
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid ${colors.lightText};
    background: #fff;
    box-shadow: 0 3px 10px ${colors.lightText};
  }

  table tr:last-child td {
    border-bottom: 0;
  }

  table thead {
    font-weight: 400;
    font-size: 1.125rem;
  }

  table tr:nth-child(1) th:first-child {
    padding: 16px 20px;
    height: 100%;
  }

  table thead tr:nth-child(1) {
    color: ${colors.boldText};
    background: ${colors.grey};
    text-align: left;
    padding: 12px 0;
    font-weight: 700;
  }

  table th,
  table td {
    padding: 8px 20px;
    border-bottom: 1px solid ${colors.lightText};
  }

  table th:last-child,
  table td:last-child {
    border-right: 0;
  }

  table tr:nth-child(n-1) {
    border-bottom: 1px solid ${colors.lightText};
  }

  tbody td {
    color: ${colors.normalText};
  }
`;

export default AddDialogTable;
