import React, { useMemo, useState } from 'react';
import { Intent } from '@blueprintjs/core';
import { NameLink } from '../../styled-components';
import SortableHeader from '../../table/components/SortableHeader';
import { amountFormatter } from '../../../utils/functions';
import RowSelector from '../../table/components/RowSelector';
import { addAccountsToAccountGroup } from '../../../api/accounts';
import AppToast from '../../Toast';
import AddDialogTable from '../../table/components/AddDialogTable';
import colors from '../../../utils/colors';
import { StyleColumnCell } from '../../styled-components';

const AddAccountsDialog = ({
  isOpen,
  handleClose,
  completeAccounts,
  groupAccounts,
  groupId,
  fetchAccountsByGroup,
  clientId,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const data = completeAccounts.filter(
    (account) =>
      !groupAccounts
        .map((account) => account.accountId)
        .includes(account.accountId)
  );

  const handleAddAccounts = (accounts) => {
    const accountIdList = accounts.map((account) => account.accountId);
    setIsAdding(true);
    addAccountsToAccountGroup(groupId, accountIdList)
      .then((res) => {
        AppToast.show({ message: res.data.msg, intent: Intent.SUCCESS });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to  add accounts.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchAccountsByGroup(groupId);
        handleClose();
        setIsAdding(false);
      });
  };
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account Name' />
        ),
        Cell: ({ cell: { value }, row }) => (
          <NameLink
            to={{
              pathname: `/client-management/${clientId}/${row.original.accountId}`,
            }}
          >
            {value}
          </NameLink>
        ),
        accessor: 'accountName',
        sortType: 'caseInsensitiveSort',
        width: '10%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Number' />
        ),
        accessor: 'accountNumber',
        width: '1%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Alias' />
        ),
        accessor: 'accountAlias',
        width: '1%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Type' />
        ),
        accessor: 'accountType',
        sortType: 'caseInsensitiveSort',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Type Category' />
        ),
        accessor: 'accountCategory',
        sortType: 'caseInsensitiveSort',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank' />
        ),
        accessor: 'bankName',
        sortType: 'caseInsensitiveSort',
        width: '3%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Balance' />
        ),
        Cell: ({ cell: { value } }) => {
          return <span>{amountFormatter(value)}</span>;
        },
        accessor: 'accountBalance',
        width: '2%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Last Transaction Date' />
        ),
        accessor: 'lastTransactionDate',
        sortType: 'customDateTimeSort',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Balance Date' />
        ),
        accessor: 'accountBalanceDate',
        sortType: 'customDateTimeSort',
        width: '4%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account Closed' />
        ),
        accessor: 'accountClosed',
        sortType: 'caseInsensitiveSort',
        width: '6%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'No' ? colors.activeColor : colors.inActiveColor}
            >
              {value && value.toUpperCase()}
            </StyleColumnCell>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Closed Date' />
        ),
        accessor: 'accountDateClosed',
        sortType: 'customDateTimeSort',
        width: '6%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{value === null ? '-' : value}</span>;
        },
      },
    ],
    [clientId]
  );

  return (
    <AddDialogTable
      isOpen={isOpen}
      columns={columns}
      data={data}
      title='Current Accounts'
      handleAdd={handleAddAccounts}
      isAdding={isAdding}
      handleClose={handleClose}
      addButtonText='Add Account(s)'
      searchName='Accounts'
    />
  );
};

export default AddAccountsDialog;
