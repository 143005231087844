import * as api from '../api/accounts';

// fetch all accounts for a client
export const fetchCompleteAccounts = (clientId) => ({
  type: 'FETCH_COMPLETE_ACCOUNTS',
  payload: api.fetchCompleteAccounts(clientId),
});

export const fetchReviewAccounts = (clientId) => ({
  type: 'FETCH_REVIEW_ACCOUNTS',
  payload: api.fetchReviewAccounts(clientId),
});

export const fetchAccountCategories = () => ({
  type: 'FETCH_ACCOUNT_CATEGORIES',
  payload: api.fetchAccountCategories(),
});

export const fetchAccountTypes = () => ({
  type: 'FETCH_ACCOUNT_TYPES',
  payload: api.fetchAccountTypes,
});

export const fetchClientAccounts = (clientId) => ({
  type: 'FETCH_CLIENT_ACCOUNTS',
  payload: api.fetchClientAccounts(clientId),
});

export const fetchAccountLinks = (accountId) => ({
  type: 'FETCH_ACCOUNT_LINKS',
  payload: api.fetchAccountLinks(accountId),
});

// fetch singe account
export const fetchAccount = (clientId, accountId) => ({
  type: 'FETCH_ACCOUNT',
  payload: api.fetchAccount(clientId, accountId),
});

export const fetchClientAccountGroups = (clientId) => ({
  type: 'FETCH_CLIENT_ACCOUNT_GROUPS',
  payload: api.fetchClientAccountGroups(clientId),
});

export const fetchAccountGroupAssociations = (accountId) => ({
  type: 'FETCH_ACCOUNT_GROUP_ASSOCIATIONS',
  payload: api.fetchAccountGroupAssociations(accountId),
});

export const fetchAccountGroups = (clientId) => ({
  type: 'FETCH_ACCOUNT_GROUPS',
  payload: api.fetchAccountGroups(clientId),
});

export const fetchAccountsByGroup = (accountGroupId) => ({
  type: 'FETCH_ACCOUNTS_BY_GROUP',
  payload: api.fetchAccountsByGroup(accountGroupId),
});
