import React, { useState, useEffect } from 'react';
import { Elevation, Tooltip, Icon } from '@blueprintjs/core';
import {
  NameLink,
  Label,
  WidgetHeader,
  WidgetBody,
  WidgetBodyHeader,
  WidgetRow,
  CardDiv,
} from '../styled-components';
import colors from '../../utils/colors';
import {
  paginate,
  amountFormatter,
  getXDaysFromNow,
  dateToFilterText,
} from '../../utils/functions';
import WidgetPagination from './WidgetPagination';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';
import styled from 'styled-components';

const pageLimit = 4;

const MaturingInvestmentsWidget = ({ investments, maturityLimit }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedInvestments, setPaginatedInvestments] = useState([]);
  const [maturityDateLimit, setMaturityDateLimit] = useState(0);

  useEffect(() => {
    investments === undefined
      ? setPaginatedInvestments(paginate([], pageLimit, pageNumber))
      : setPaginatedInvestments(paginate(investments, pageLimit, pageNumber));
    if (maturityLimit) {
      setMaturityDateLimit(Number(maturityLimit.globalSettingValue));
    }
  }, [investments, pageNumber, maturityLimit]);

  const totalMaturingInvestments =
    investments === undefined
      ? 0
      : investments.reduce(
          (acc, currentValue) => acc + currentValue.investmentCount,
          0
        );

  const totalMaturingAmount =
    investments === undefined
      ? 0
      : amountFormatter(
          investments.reduce(
            (acc, currentValue) => acc + currentValue.totalAmount,
            0
          )
        );

  const dateRange = getXDaysFromNow(maturityDateLimit);

  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget maturing-widget'}
      style={{ flexBasis: '420px', flexGrow: '2' }}
    >
      <WidgetHeader>
        <Label display={'flex'} alignItems={'center'}>
          Maturing Investments
        </Label>
        <div>
          <NameLink
            to={{
              pathname: `/investment-management`,
              state: 3,
            }}
          >
            View All
          </NameLink>
        </div>
      </WidgetHeader>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '10px 20px' }}>
          <InvestmentAmount>{totalMaturingInvestments}</InvestmentAmount>
          <div style={{ display: 'flex', paddingTop: '10px' }}>
            Over the Next {maturityDateLimit} Days
            <Tooltip content={dateToFilterText(dateRange).value}>
              <Icon
                icon={iconValue.info}
                iconSize={16}
                color={colors.primary}
                style={{
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div style={{ padding: '10px 20px' }}>
          <InvestmentAmount>
            {totalMaturingAmount ? totalMaturingAmount : '$0.00'}
          </InvestmentAmount>
          <div style={{ paddingTop: '10px' }}>Total Amount</div>
        </div>
      </div>

      <WidgetBody borderTop={` 1px solid ${colors.lightText}`}>
        <WidgetBodyHeader>
          <Label width={'30%'}>Client</Label>
          <Label width={'10%'}>#</Label>
          <Label width={'30%'}>Total Amount</Label>
          <Label width={'25%'}>Date</Label>
        </WidgetBodyHeader>
        {paginatedInvestments.length ? (
          paginatedInvestments.map((investment) => (
            <WidgetRow key={investment.clientId}>
              <NameLink
                title={investment.clientName}
                to={{
                  pathname: `/client-management/${investment.clientId}`,
                  state: 3,
                }}
                width={'30%'}
              >
                {investment.clientName.length > 15
                  ? investment.clientName.substring(0, 12) + '...'
                  : investment.clientName}
              </NameLink>
              <div style={{ width: '10%' }}>{investment.investmentCount}</div>
              <div style={{ width: '30%' }}>
                {amountFormatter(investment.totalAmount)}
              </div>
              <div style={{ width: '25%' }}>
                {investment.earliestMaturityDate}
              </div>
            </WidgetRow>
          ))
        ) : (
          <NonIdealStateComp
            icon={iconValue.warning}
            title={'No Results.'}
            description={'There are no results to be displayed.'}
          />
        )}
      </WidgetBody>

      <WidgetPagination
        currentIndex={pageNumber}
        recordsLength={investments ? investments.length : 0}
        setPageNumber={setPageNumber}
        pageLimit={pageLimit}
      />
    </CardDiv>
  );
};

const InvestmentAmount = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${colors.boldText};
`;

export default MaturingInvestmentsWidget;
