import { Auth } from 'aws-amplify';
import axios from 'axios';
import { setHeader } from './index';

const USER_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/users`;

// bypassCache is what cognito uses to call the user.
// if true, it will ignore the cache and get the most
// current user. this is used for when the user updates
// their email. cognito will always use the old email
// so we bypass the caching element to pull the new
// email from cognito when the user updates it
export const fetchCognitoUser = async (flag = false) => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: flag,
  });
  return user;
};

export const fetchAppUser = async () => {
  const request = await setHeader(axios);
  return request.get(`${USER_API}/logged-user`);
};
