import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { fetchTransactionKeywords } from '../../../actions/transactions';
import { connect } from 'react-redux';
import { createKeyword, updateKeyword } from '../../../api/administration';
import AppToast from '../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import KeywordsForm from '../../forms/global-settings-forms/KeywordsForm';

const KeywordsDialogBoxBase = ({
  handleDialogClose,
  isOpen,
  title,
  fetchTransactionKeywords,
  allTransactionKeywords,
  isAllTransactionsFetching,
  keywordObj,
  fetchAllKeywords,
  fetchSingleKeyword,
  ...props
}) => {
  const [keywordName, setKeywordName] = useState('');
  const [keywordCategory, setKeywordCategory] = useState('');
  const [keywordId, setKeywordId] = useState(null);

  useEffect(() => {
    fetchTransactionKeywords();
    if (keywordObj) {
      setKeywordName(keywordObj.keywordName || '');
      setKeywordCategory(keywordObj.keywordCategory || '');
      setKeywordId(keywordObj.keywordId || null);
    }
  }, [fetchTransactionKeywords, keywordObj]);

  const handleEdit = (keywords) => {
    updateKeyword(keywordId, keywords)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchSingleKeyword(keywordId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to update keyword.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleDialogClose());
  };

  const handleCreate = (keywords) => {
    createKeyword(keywords)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllKeywords();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create keyword.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleDialogClose());
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={title}
        onClose={handleDialogClose}
        isCloseButtonShown={false}
      >
        <KeywordsForm
          title={title}
          keywordName={keywordName}
          keywordCategory={keywordCategory}
          onClose={handleDialogClose}
          keywordCategories={allTransactionKeywords}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  allTransactionKeywords: state.transactions.allTransactionKeywords,
  isAllTransactionsFetching: state.transactions.isAllTransactionsFetching,
});

const KeywordsDialogBox = connect(mapStateToProps, {
  fetchTransactionKeywords,
})(KeywordsDialogBoxBase);

export default KeywordsDialogBox;
