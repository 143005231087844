import React from 'react';
import {
  MessageText,
  BorderComponentContainer,
  InfoWidthContainer,
  FormWidthContainer,
} from '../../styled-components';
import UserDetailsForm from '../../forms/user-profile-forms/UserDetailsForm';

const DetailsComponent = ({
  firstName,
  lastName,
  email,
  handleChangeUserDetails,
  handleResetForm,
  ...props
}) => {
  return (
    <>
      <InfoWidthContainer>
        <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
          Details
        </MessageText>
        <MessageText>Edit your account information, here.</MessageText>
      </InfoWidthContainer>
      <FormWidthContainer>
        <BorderComponentContainer padding='2rem'>
          <UserDetailsForm
            firstName={firstName}
            lastName={lastName}
            email={email}
            handleChangeUserDetails={handleChangeUserDetails}
            handleResetForm={handleResetForm}
            {...props}
          />
        </BorderComponentContainer>
      </FormWidthContainer>
    </>
  );
};

export default DetailsComponent;
