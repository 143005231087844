import React from 'react';
import { AlignCenter } from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormActions from '../FormActions';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';

const accountGroupSchema = Yup.object().shape({
  accountGroupName: Yup.string().required('Required!'),
});

const transformValues = (values) => {
  values.addAccountGroupName = values.accountGroupName;
  delete values.accountGroupName;
  return values;
};

const AccountGroupForm = ({
  title,
  closeDialog,
  clientAccountGroups,
  groupAssociations,
  setGroupAssociations,
  handleCreateGroup,
  handleEditGroupAssociation,
  handleEditGroup,
  accountGroup,
}) => {
  return (
    <>
      {(title === 'Create Group' ||
        title === 'Add Group' ||
        title === 'Edit Group') && (
        <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
          <Formik
            initialValues={{
              accountGroupName: accountGroup ? accountGroup.groupName : '',
            }}
            onSubmit={(values) => {
              (title === 'Add Group' || title === 'Create Group') &&
                handleCreateGroup(transformValues(values));
              title === 'Edit Group' && handleEditGroup(values);
              title === 'Assign/Edit Groups' && handleEditGroupAssociation();
            }}
            validationSchema={accountGroupSchema}
            enableReinitialize={true}
            validateOnBlur={false}
          >
            {({ isSubmitting, values }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AlignCenter
                  justifyContent='flex-start'
                  padding='0 50px 30px 50px'
                >
                  <ModalTextField
                    type='text'
                    name='accountGroupName'
                    placeholder='Account Group Name'
                    label='Account Group Name'
                    value={values.accountGroupName || ''}
                    autoComplete='off'
                  />
                </AlignCenter>
                <FormActions
                  isSubmitting={isSubmitting}
                  onClose={closeDialog}
                />
              </Form>
            )}
          </Formik>
        </AlignCenter>
      )}
      {title === 'Assign/Edit Groups' && (
        <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
          <Formik
            initialValues={{
              groupAssociations,
            }}
            onSubmit={(values) => {
              handleEditGroupAssociation(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AlignCenter
                  justifyContent='flex-start'
                  padding='0px 50px 20px 50px'
                >
                  <MultiSelectField
                    setSelectedItems={setGroupAssociations}
                    items={clientAccountGroups}
                    selectedItems={groupAssociations}
                    label='Assign Groups'
                  />
                </AlignCenter>
                <FormActions
                  isSubmitting={isSubmitting}
                  onClose={closeDialog}
                />
              </Form>
            )}
          </Formik>
        </AlignCenter>
      )}
    </>
  );
};

export default AccountGroupForm;
