import React, { useState, useEffect } from 'react';
import { Dialog, RadioGroup, Radio } from '@blueprintjs/core';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import {
  dateRangeChecker,
  dateToFilterText,
  textToFilterText,
  multiSelectToFilterText,
} from '../../../utils/functions';

import TableFilterActions from './TableFilterActions';

const MonteCarloFilterDialogBox = ({
  isOpen,
  title,
  handleClose,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
  rangeFilter,
  resetInitialFilter,
}) => {
  const [dateRange, setDateRange] = useState([]);
  const [dataHasChanged, setDataHasChanged] = useState('');
  const [analysisGroupNames, setAnalysisGroupNames] = useState([]);
  const [analysisGroupNameItems, setAnalysisGroupNameItems] = useState([]);
  const [names, setNames] = useState([]);
  const [nameItems, setNameItems] = useState([]);

  useEffect(() => {
    if (resetInitialFilter) {
      setFilter('createdAt', rangeFilter);
      setDateRange(rangeFilter);
    }
    // Setting Data for the filter multiselects
    if (initialData.length) {
      // gets all group names from monte carlo history,
      // stores them as an array of objects with a label and a value
      // these group names are passed into a "MultiSelectField" in the jsx return,
      // and become the options for the "Analysis Groups" multiselect in the table filter.
      setAnalysisGroupNameItems(
        initialData
          .map((item) => item.groupName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      // gets all monte carlo analysis names from monte carlo history,
      // stores them as an array of objects with a label and a value
      // these group names are passed into a "MultiSelectField",
      // and become the options for the "Name" multiselect in the table filter.
      setNameItems(
        initialData
          .map((item) => item.monteCarloName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData, setFilter, rangeFilter, setDateRange, resetInitialFilter]);

  const getFilterSummary = () => {
    const filters = [];
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Date'));
    dataHasChanged &&
      filters.push(textToFilterText(dataHasChanged, 'Data Has Changed?'));
    analysisGroupNames.length &&
      filters.push(
        multiSelectToFilterText(analysisGroupNames, 'Analysis Group')
      );
    names.length && filters.push(multiSelectToFilterText(names, 'Name'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('createdAt', dateRangeChecker(dateRange) ? dateRange : undefined);
    setFilter('dataHasChanged', dataHasChanged || undefined);
    setFilter('monteCarloName', names.length ? names : undefined);
    setFilter(
      'groupName',
      analysisGroupNames.length ? analysisGroupNames : undefined
    );
    handleClose();
  };

  const handleClearFilters = () => {
    setAllFilters([]);
    setFilterSummary([]);
    setDataHasChanged('');
    setAnalysisGroupNames([]);
    setDateRange([]);
    setNames([]);
    handleClose();
  };

  const handleDataHasChangedChange = (event) => {
    setDataHasChanged(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div
        style={{
          padding: '2rem 3rem 0',
          marginBottom: '10px',
        }}
      >
        <>
          <div style={{ marginBottom: '20px' }}>
            <DateSelectPlainField
              labelName={'Created Date Range'}
              dateRange={dateRange}
              handleDateChange={setDateRange}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <MultiSelectField
              setSelectedItems={setAnalysisGroupNames}
              items={analysisGroupNameItems}
              selectedItems={analysisGroupNames}
              label='Analysis Groups'
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <MultiSelectField
              setSelectedItems={setNames}
              items={nameItems}
              selectedItems={names}
              label='Name'
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <div style={{ width: '271px' }} className='radio-group'>
              <RadioGroup
                label={'Data Has Changed?'}
                onChange={handleDataHasChangedChange}
                selectedValue={dataHasChanged}
                inline={true}
              >
                <Radio
                  label='Yes'
                  value='Yes'
                  style={{ marginRight: '20px' }}
                />
                <Radio label='No' value='No' />
              </RadioGroup>
            </div>
          </div>
        </>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default MonteCarloFilterDialogBox;
