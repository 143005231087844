import React, { useEffect, useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import EditBalanceForm from '../../forms/treasury-services/EditBalanceForm';
import { updateStagingBalance } from '../../../api/treasuryServices';
import AppToast from '../../Toast';

const EditBalanceDialog = ({
  isOpen,
  handleClose,
  balance,
  dynamicHeaders,
  standardizedDescriptionList,
  batchId,
  fetchStagingBalances,
}) => {
  const [standardizedDescription, setStandardizedDescription] = useState('');
  const [amounts, setAmounts] = useState([]);
  const [indexes, setIndexes] = useState([]);

  useEffect(() => {
    if (balance) {
      setStandardizedDescription(balance.standardizedDescription || '');
      if (balance.description === 'ecr') {
        setAmounts(
          balance.amounts
            .filter((x) => x[0] !== null)
            .map((item) => [item[0], item[1] * 100])
        );
        setIndexes(
          balance.amounts.reduce((x, v, i) => {
            if (v[0] !== null) x.push(i);
            return x;
          }, [])
        );
      } else {
        setAmounts(balance.amounts.filter((x) => x[0] !== null) || []);
        setIndexes(
          balance.amounts.reduce((x, v, i) => {
            if (v[0] !== null) x.push(i);
            return x;
          }, [])
        );
      }
    }
  }, [balance]);

  const handleEditBalance = (balance) => {
    updateStagingBalance(balance)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit Staging Balance.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingBalances(batchId);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title='Edit Staging Balance'
      onClose={handleClose}
      isCloseButtonShown={false}
      className={amounts.length > 6 ? 'medium-dialog' : ''}
    >
      <EditBalanceForm
        amounts={amounts}
        closeDialog={handleClose}
        dynamicHeaders={dynamicHeaders}
        standardizedDescription={standardizedDescription}
        standardizedDescriptionList={standardizedDescriptionList}
        isECR={balance.description === 'ecr'}
        handleEditBalance={handleEditBalance}
        indexes={indexes}
      />
    </Dialog>
  );
};

export default EditBalanceDialog;
