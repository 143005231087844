import * as api from '../api/transactions';

export const fetchAllTransactions = (accountId) => ({
  type: 'FETCH_ALL_TRANSACTIONS',
  payload: api.fetchAllTransactions(accountId),
});

// export const fetchPaginatedData = (accountId, pageInfo) => ({
//   type: 'FETCH_PAGINATED_TRANSACTIONS',
//   payload: api.fetchPaginatedData(accountId, pageInfo),
// });

export const fetchTransactionKeywords = () => ({
  type: 'FETCH_TRANSACTION_KEYWORDS',
  payload: api.fetchTransactionKeywords(),
});

export const fetchStagingTransactions = (batchAccountId) => ({
  type: 'FETCH_STAGING_TRANSACTIONS',
  payload: api.fetchStagingTransactions(batchAccountId),
});

export const fetchSingleStagingTransaction = (stagingTransactionId) => ({
  type: 'FETCH_SINGLE_STAGING_TRANSACTION',
  payload: api.fetchSingleStagingTransaction(stagingTransactionId),
});
