import { combineReducers } from 'redux';

const allClients = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_CLIENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_CLIENTS_REJECTED':
    case 'FETCH_ALL_CLIENTS_PENDING':
      return [];
    case 'FETCH_SINGLE_CLIENT_FULFILLED':
      return state.map((client) => {
        if (client.clientId === action.payload.data[0].clientId) {
          return action.payload.data[0];
        }
        return client;
      });
    case 'FETCH_SINGLE_CLIENT_REJECTED':
    case 'FETCH_SINGLE_CLIENT_PENDING':
      return state;
    default:
      return state;
  }
};

const isAllClientsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_CLIENTS_PENDING':
      return true;
    case 'FETCH_ALL_CLIENTS_REJECTED':
    case 'FETCH_ALL_CLIENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const allClientCategories = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_CLIENTS_CATEGORIES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_CLIENTS_CATEGORIES_REJECTED':
    case 'FETCH_ALL_CLIENTS_CATEGORIES_PENDING':
      return [];
    default:
      return state;
  }
};

const allClientTypes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_CLIENTS_TYPES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_CLIENTS_TYPES_REJECTED':
    case 'FETCH_ALL_CLIENTS_TYPES_PENDING':
      return [];
    default:
      return state;
  }
};

const allowedReportFrequencies = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALLOWED_REPORT_FREQUENCIES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALLOWED_REPORT_FREQUENCIES_REJECTED':
    case 'FETCH_ALLOWED_REPORT_FREQUENCIES_PENDING':
      return [];
    default:
      return state;
  }
};

const allClientBanks = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_CLIENT_BANKS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_CLIENT_BANKS_REJECTED':
    case 'FETCH_ALL_CLIENT_BANKS_PENDING':
      return [];
    default:
      return state;
  }
};

const isFetchingClientBanks = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_CLIENT_BANKS_PENDING':
      return true;
    case 'FETCH_ALL_CLIENT_BANKS_REJECTED':
    case 'FETCH_ALL_CLIENT_BANKS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const allClientList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_LIST_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_LIST_REJECTED':
    case 'FETCH_CLIENT_LIST_PENDING':
      return [];
    default:
      return state;
  }
};

const isFetchingAllClientsList = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_LIST_PENDING':
      return true;
    case 'FETCH_CLIENT_LIST_REJECTED':
    case 'FETCH_CLIENT_LIST_FULFILLED':
      return false;
    default:
      return state;
  }
};

const clientNegotiatedRates = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_NEGOTIATED_RATES_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_NEGOTIATED_RATES_PENDING':
    case 'FETCH_CLIENT_NEGOTIATED_RATES_REJECTED':
      return [];
    case 'FETCH_SINGLE_NEGOTIATED_RATE_FULFILLED':
      return state.map((rate) => {
        if (rate.negotiatedRateId === action.payload.data[0].negotiatedRateId) {
          return action.payload.data[0];
        }
        return rate;
      });
    case 'FETCH_SINGLE_NEGOTIATED_RATE_PENDING':
    case 'FETCH_SINGLE_NEGOTIATED_RATE_REJECTED':
      return state;
    default:
      return state;
  }
};

const isFetchingClientNegotiatedRates = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_NEGOTIATED_RATES_PENDING':
      return true;
    case 'FETCH_CLIENT_NEGOTIATED_RATES_FULFILLED':
    case 'FETCH_CLIENT_NEGOTIATED_RATES_REJECTED':
      return false;
    default:
      return state;
  }
};

const clientReportStatus = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_REPORT_STATUS_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_REPORT_STATUS_REJECTED':
    case 'FETCH_CLIENT_REPORT_STATUS_PENDING':
      return [];
    default:
      return state;
  }
};

const maturingInvestments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_MATURING_INVESTMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_MATURING_INVESTMENTS_REJECTED':
    case 'FETCH_MATURING_INVESTMENTS_PENDING':
      return [];
    default:
      return state;
  }
};

const summary = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_SINGLE_CLIENT_FULFILLED':
      return action.payload.data[0];
    case 'FETCH_SINGLE_CLIENT_REJECTED':
    case 'FETCH_SINGLE_CLIENT_PENDING':
      return state;
    default:
      return state;
  }
};

const isFetchingClientReportStatus = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_REPORT_STATUS_PENDING':
      return true;
    case 'FETCH_CLIENT_REPORT_STATUS_REJECTED':
    case 'FETCH_CLIENT_REPORT_STATUS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const isMaturingInvestmentsFetching = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_MATURING_INVESTMENTS_PENDING':
      return true;
    case 'FETCH_MATURING_INVESTMENTS_REJECTED':
    case 'FETCH_MATURING_INVESTMENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const isSummaryFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_SINGLE_CLIENT_PENDING':
      return true;
    case 'FETCH_SINGLE_CLIENT_REJECTED':
    case 'FETCH_SINGLE_CLIENT_FULFILLED':
      return false;
    default:
      return state;
  }
};

const accountGraph = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_BALANCE_DATA_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_BALANCE_DATA_REJECTED':
    case 'FETCH_ACCOUNT_BALANCE_DATA_PENDING':
      return state;
    default:
      return state;
  }
};

const isAccountGraphFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_BALANCE_DATA_PENDING':
      return true;
    case 'FETCH_ACCOUNT_BALANCE_DATA_REJECTED':
    case 'FETCH_ACCOUNT_BALANCE_DATA_FULFILLED':
      return false;
    default:
      return state;
  }
};

const dayToDayVariance = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_DAY_TO_DAY_VARIANCE_FULFILLED':
      return action.payload.data;
    case 'FETCH_DAY_TO_DAY_VARIANCE_REJECTED':
    case 'FETCH_DAY_TO_DAY_VARIANCE_PENDING':
      return state;
    default:
      return state;
  }
};

const isDayToDayVarianceFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_DAY_TO_DAY_VARIANCE_PENDING':
      return true;
    case 'FETCH_DAY_TO_DAY_VARIANCE_REJECTED':
    case 'FETCH_DAY_TO_DAY_VARIANCE_FULFILLED':
      return false;
    default:
      return state;
  }
};

const cashVestScores = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CASH_VEST_SCORES_FULFILLED':
      return action.payload.data;
    case 'FETCH_CASH_VEST_SCORES_REJECTED':
    case 'FETCH_CASH_VEST_SCORES_PENDING':
      return state;
    default:
      return state;
  }
};

const isCashVestScoreFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CASH_VEST_SCORES_PENDING':
      return true;
    case 'FETCH_CASH_VEST_SCORES_REJECTED':
    case 'FETCH_CASH_VEST_SCORES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const shortTermModelForecast = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_SHORT_TERM_MODEL_FORECAST_FULFILLED':
      return action.payload.data;
    case 'FETCH_SHORT_TERM_MODEL_FORECAST_REJECTED':
    case 'FETCH_SHORT_TERM_MODEL_FORECAST_PENDING':
      return [];
    default:
      return state;
  }
};

const isShortTermModelForecastFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_SHORT_TERM_MODEL_FORECAST_PENDING':
      return true;
    case 'FETCH_SHORT_TERM_MODEL_FORECAST_REJECTED':
    case 'FETCH_SHORT_TERM_MODEL_FORECAST_FULFILLED':
      return false;
    default:
      return state;
  }
};

const reports = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_REPORTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_REPORTS_PENDING':
    case 'FETCH_REPORTS_REJECTED':
      return [];
    default:
      return state;
  }
};

const isReportsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_REPORTS_PENDING':
      return true;
    case 'FETCH_REPORTS_FULFILLED':
    case 'FETCH_REPORTS_REJECTED':
      return false;
    default:
      return state;
  }
};

const assetWidgetData = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ASSET_WIDGET_DATA_FULFILLED':
      return action.payload.data;
    case 'FETCH_ASSET_WIDGET_DATA_REJECTED':
    case 'FETCH_ASSET_WIDGET_DATA_PENDING':
      return state;
    default:
      return state;
  }
};

const isAssetWidgetDataFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ASSET_WIDGET_DATA_PENDING':
      return true;
    case 'FETCH_ASSET_WIDGET_DATA_REJECTED':
    case 'FETCH_ASSET_WIDGET_DATA_FULFILLED':
      return false;
    default:
      return state;
  }
};

const shortTermForecast = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_SHORT_TERM_FORECAST_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_SHORT_TERM_FORECAST_REJECTED':
    case 'FETCH_CLIENT_SHORT_TERM_FORECAST_PENDING':
      return state;
    default:
      return state;
  }
};

const isShortTermForecastFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_SHORT_TERM_FORECAST_PENDING':
      return true;
    case 'FETCH_CLIENT_SHORT_TERM_FORECAST_REJECTED':
    case 'FETCH_CLIENT_SHORT_TERM_FORECAST_FULFILLED':
      return false;
    default:
      return state;
  }
};

const interestRate = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_INTEREST_RATE_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_INTEREST_RATE_PENDING':
    case 'FETCH_CLIENT_INTEREST_RATE_REJECTED':
      return [];
    default:
      return state;
  }
};

const isInterestRateFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_INTEREST_RATE_PENDING':
      return true;
    case 'FETCH_CLIENT_INTEREST_RATE_FULFILLED':
    case 'FETCH_CLIENT_INTEREST_RATE_REJECTED':
      return false;
    default:
      return state;
  }
};

const interestRateComparisons = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_INTEREST_RATE_COMPARISONS_FULFILLED':
      return action.payload.data;
    case 'FETCH_INTEREST_RATE_COMPARISONS_PENDING':
    case 'FETCH_INTEREST_RATE_COMPARISONS_REJECTED':
      return [];
    default:
      return state;
  }
};

const isFetchingInterestRateComparisons = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_INTEREST_RATE_COMPARISONS_PENDING':
      return true;
    case 'FETCH_INTEREST_RATE_COMPARISONS_FULFILLED':
    case 'FETCH_INTEREST_RATE_COMPARISONS_REJECTED':
      return false;
    default:
      return state;
  }
};

const viewParameters = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_VIEW_REPORT_PARAMETERS_FULFILLED':
      return action.payload.data[0];
    case 'FETCH_VIEW_REPORT_PARAMETERS_PENDING':
    case 'FETCH_VIEW_REPORT_PARAMETERS_REJECTED':
      return {};
    default:
      return state;
  }
};

const isFetchingViewParameters = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_VIEW_REPORT_PARAMETERS_PENDING':
      return true;
    case 'FETCH_VIEW_REPORT_PARAMETERS_FULFILLED':
    case 'FETCH_VIEW_REPORT_PARAMETERS_REJECTED':
      return false;
    default:
      return state;
  }
};

const clients = combineReducers({
  allClients,
  isAllClientsFetching,
  allowedReportFrequencies,
  allClientCategories,
  allClientTypes,
  allClientBanks,
  isFetchingClientBanks,
  allClientList,
  isFetchingAllClientsList,
  clientNegotiatedRates,
  isFetchingClientNegotiatedRates,
  clientReportStatus,
  isFetchingClientReportStatus,
  maturingInvestments,
  isMaturingInvestmentsFetching,
  summary,
  isSummaryFetching,
  accountGraph,
  isAccountGraphFetching,
  dayToDayVariance,
  isDayToDayVarianceFetching,
  cashVestScores,
  isCashVestScoreFetching,
  assetWidgetData,
  isAssetWidgetDataFetching,
  shortTermForecast,
  isShortTermForecastFetching,
  shortTermModelForecast,
  isShortTermModelForecastFetching,
  reports,
  isReportsFetching,
  interestRate,
  isInterestRateFetching,
  interestRateComparisons,
  isFetchingInterestRateComparisons,
  viewParameters,
  isFetchingViewParameters,
});

export default clients;
