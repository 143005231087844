import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import IconTextField from '../formik-text-fields/IconTextField';
import {
  RegularButton,
  LoginContainer,
  LoginLogoContainer,
  LoginFormContainer,
  AlignCenter,
  HeadingText,
  MessageText,
  Label,
  LogoBox,
  LoginResponsiveContainer,
} from '../styled-components';
import LoadSpinner from '../LoadSpinner';
import { Auth } from 'aws-amplify';
import { fetchCognitoUser } from '../../api/auth';
import { connect } from 'react-redux';
import colors from '../../utils/colors';
import logo from '../../assets/three-plus-one-logo-white-text.png';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(12, 'Must be 12 characters or more.')
    .required('Required!'),
  confirmPassword: Yup.string()
    .min(12, 'Must be 12 characters or more.')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match!')
    .required('Required!'),
});

const ResetPasswordBase = (props) => {
  const [newPassword] = useState('');
  const [confirmPassword] = useState('');
  const [showResetError, setShowResetError] = useState(false);
  const [resetError, setResetError] = useState('');

  // submit form, on first time login, user will get an email to reset temporary password
  // they will complete new password challenge and be logged in.
  // if user forgets password they will be brought to this page to complete the forgot password challenge
  const submit = async ({ newPassword, confirmPassword }) => {
    // splits the url on every '/' starting after the second occurrence and grabs the username
    const username = props.match.url.split('/').slice(2)[0];

    // splits the url on every '/' starting after the third occurrence and grabs the code
    // and joins it back in a case where the code has one or more '/'
    const code = props.match.url.split('/').slice(3).join('/');

    if (newPassword === confirmPassword) {
      try {
        if (props.location.pathname.includes('/reset-password')) {
          const user = await Auth.signIn(username, code);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            await Auth.completeNewPassword(user, newPassword);
            props.history.push('/');
          }
        } else {
          await Auth.forgotPasswordSubmit(username, code, newPassword);
          props.history.push('/');
        }
      } catch (error) {
        setShowResetError(true);
        setResetError(error.message);
      }
    }
  };

  return (
    <LoginContainer>
      <LoginLogoContainer>
        <LogoBox alt='company logo' src={logo} />
      </LoginLogoContainer>
      <AlignCenter width='67%' flexDirection='column'>
        <LoginResponsiveContainer>
          <div>
            <HeadingText>Set New Password</HeadingText>
          </div>
          <LoginFormContainer flexDirection='column'>
            <MessageText margin='0 0 30px 0'>
              Enter your new password and you'll be able to log into Three+One.
            </MessageText>
            <Formik
              initialValues={{ newPassword, confirmPassword }}
              validationSchema={validationSchema}
              validateOnBlur={false}
              // submit will login when user resets password
              onSubmit={submit}
            >
              {({ isSubmitting, values }) => (
                <Form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  {/* custom field using formik hooks */}
                  <Label>New Password</Label>
                  <IconTextField
                    type='password'
                    name='newPassword'
                    value={values.newPassword || ''}
                    autoComplete='off'
                  />
                  <Label margin='20px 0 0 0'>Confirm Password</Label>
                  <IconTextField
                    type='password'
                    name='confirmPassword'
                    value={values.confirmPassword || ''}
                    autoComplete='off'
                  />
                  {showResetError && (
                    <MessageText color={colors.red}>{resetError}</MessageText>
                  )}
                  <MessageText>At least 12 characters long.</MessageText>
                  <MessageText>
                    Contains at least one of each of the following: lower case
                    letter, upper case letter, number and symbol.
                  </MessageText>
                  <RegularButton
                    margin='30px 0'
                    width='100%'
                    padding='1rem'
                    type='submit'
                    disabled={isSubmitting}
                    display='flex'
                  >
                    Reset Password
                    {isSubmitting && (
                      <span style={{ marginLeft: '10px' }}>
                        <LoadSpinner size={20} />
                      </span>
                    )}
                  </RegularButton>
                </Form>
              )}
            </Formik>
          </LoginFormContainer>
        </LoginResponsiveContainer>
      </AlignCenter>
    </LoginContainer>
  );
};

const ResetPassword = connect(null, { fetchCognitoUser })(ResetPasswordBase);

export default ResetPassword;
