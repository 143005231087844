import React, { useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import { fetchClientAccountGroups } from '../../../actions/accounts';
import {
  fetchStepOneShortTermAnalysis,
  fetchStepTwoShortTermAnalysis,
  fetchShortTermModelList,
} from '../../../actions/analysis';
import { connect } from 'react-redux';
import AppToast from '../../Toast';
import { Intent } from '@blueprintjs/core';
import ShortTermModelForm from '../../forms/analytics/ShortTermModelForm';
import { runStepOneAnalysis, runStepTwoAnalysis } from '../../../api/analysis';

const RunShortTermAnalysisDialogBase = ({
  handleDialogClose,
  isOpen,
  clientAccountGroups,
  clientId,
  startDate,
  endDate,
  accountGroupId,
  addAnalysisName,
  stepOneAnalysis,
  stepTwoAnalysis,
  balanceAdjustments,
  groupName,
  shortTermModelList,
  stepToRun,
  selectedRow,
  ...props
}) => {
  const {
    fetchClientAccountGroups,
    fetchStepOneShortTermAnalysis,
    fetchStepTwoShortTermAnalysis,
    fetchShortTermModelList,
  } = props;
  useEffect(() => {
    fetchClientAccountGroups(clientId);
    fetchShortTermModelList();
  }, [fetchClientAccountGroups, fetchShortTermModelList, clientId]);

  const handleRunAnalysis = (settings) => {
    const [values, stepToRun] = settings;
    if (stepToRun === 1) {
      runStepOneAnalysis(values)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to Run Step One Analysis',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          fetchStepOneShortTermAnalysis(clientId);
          handleDialogClose();
        });
    }

    if (stepToRun === 2) {
      const shortTermModelId = values.shortTermModelId;
      delete values.shortTermModelId;

      runStepTwoAnalysis(values, shortTermModelId)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to Run Step Two Analysis',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          fetchStepTwoShortTermAnalysis(clientId);
          handleDialogClose();
        });
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={'Settings'}
        onClose={handleDialogClose}
        isCloseButtonShown={false}
      >
        <ShortTermModelForm
          title={'Settings'}
          handleRunAnalysis={handleRunAnalysis}
          onClose={handleDialogClose}
          startDate={startDate}
          endDate={endDate}
          accountGroupId={accountGroupId}
          addAnalysisName={addAnalysisName}
          clientAccountGroups={clientAccountGroups}
          clientId={clientId}
          balanceAdjustments={balanceAdjustments}
          groupName={groupName}
          shortTermModelList={shortTermModelList}
          stepToRun={stepToRun}
          stepOneAnalysis={stepOneAnalysis}
          selectedRow={selectedRow}
          {...props}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  clientAccountGroups: state.accounts.clientAccountGroups,
  stepOneAnalysis: state.analysis.stepOneAnalysis,
  stepTwoAnalysis: state.analysis.stepTwoAnalysis,
  shortTermModelList: state.analysis.shortTermModelList,
});

const RunShortTermAnalysisDialog = connect(mapStateToProps, {
  fetchClientAccountGroups,
  fetchStepOneShortTermAnalysis,
  fetchStepTwoShortTermAnalysis,
  fetchShortTermModelList,
})(RunShortTermAnalysisDialogBase);

export default RunShortTermAnalysisDialog;
