import React from 'react';
import { Form, Formik } from 'formik';
import { AlignCenter } from '../../styled-components';
import FormActions from '../FormActions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  unitPrice: Yup.string().matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  bankLevelException: Yup.string(),
  clientLevelException: Yup.string(),
  category: Yup.object().shape({
    label: Yup.string().required('Required!'),
  }),
  subCategory: Yup.object().shape({
    label: Yup.string().required('Required!'),
  }),
});

const transformValues = (values) => {
  values.categoryId = values.subCategory.value;
  delete values.category;
  delete values.subCategory;
  return values;
};

const EditFeeForm = ({
  category,
  unitPrice,
  closeDialog,
  subCategory,
  categoryList,
  handleEditFee,
  subCategoryList,
  bankLevelException,
  clientLevelException,
  bankLevelExceptionList,
  clientLevelExceptionList,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column'>
        <Formik
          initialValues={{
            category,
            unitPrice,
            subCategory,
            bankLevelException,
            clientLevelException,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            handleEditFee(transformValues(values));
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            const handleCategoryChange = (item) => {
              if (item.value !== values.category.value) {
                setFieldValue('subCategory', {});
                setFieldValue('category', item);
              } else {
                setFieldValue('category', item);
              }
            };
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AlignCenter
                  justifyContent='space-evenly'
                  padding='20px 0'
                  alignItems='flex-start'
                >
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    value={values.category.label || ''}
                    labelName='Category'
                    items={categoryList}
                    name='category'
                    hasHandleFunction={true}
                    handleSelectChange={handleCategoryChange}
                  />
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    value={values.subCategory.label || ''}
                    labelName='Sub Category'
                    items={
                      values.category.value || values.category.value === 0
                        ? subCategoryList[values.category.value]
                        : []
                    }
                    name='subCategory'
                    useItem
                  />
                </AlignCenter>
                <AlignCenter
                  justifyContent='space-evenly'
                  padding='20px 0'
                  alignItems='flex-start'
                >
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    value={values.bankLevelException || ''}
                    labelName='Bank Level Exception'
                    items={bankLevelExceptionList}
                    name='bankLevelException'
                    useLabel
                  />
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    value={values.clientLevelException || ''}
                    labelName='Client Level Exception'
                    items={clientLevelExceptionList}
                    name='clientLevelException'
                    useLabel
                  />
                </AlignCenter>
                <AlignCenter
                  justifyContent='space-evenly'
                  padding='20px 0'
                  alignItems='flex-start'
                >
                  <ModalTextField
                    width='271px'
                    type='text'
                    name='unitPrice'
                    placeholder='Unit Price'
                    label='Unit Price'
                    autoComplete='off'
                  />
                  <div style={{ width: '271px' }}></div>
                </AlignCenter>
                <FormActions
                  isSubmitting={isSubmitting}
                  onClose={closeDialog}
                  margin='0'
                />
              </Form>
            );
          }}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default EditFeeForm;
