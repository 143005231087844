import React, { useEffect, useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import EditAccountBalanceForm from '../../forms/treasury-services/EditAccountBalanceForm';
import { updateStagingAccountBalance } from '../../../api/treasuryServices';
import AppToast from '../../Toast';

const EditAccountBalanceDialog = ({
  isOpen,
  handleClose,
  accountBalance,
  dynamicHeaders,
  batchId,
  fetchStagingAccountBalances,
}) => {
  const [amounts, setAmounts] = useState([]);
  const [indexes, setIndexes] = useState([]);

  useEffect(() => {
    if (accountBalance) {
      if (accountBalance.description === 'ecr') {
        setAmounts(
          accountBalance.amounts
            .filter((x) => x[0] !== null)
            .map((item) => [item[0], item[1] * 100]) || []
        );
        setIndexes(
          accountBalance.amounts.reduce((x, v, i) => {
            if (v[0] !== null) x.push(i);
            return x;
          }, [])
        );
      } else {
        setAmounts(accountBalance.amounts.filter((x) => x[0] !== null) || []);
        setIndexes(
          accountBalance.amounts.reduce((x, v, i) => {
            if (v[0] !== null) x.push(i);
            return x;
          }, [])
        );
      }
    }
  }, [accountBalance]);

  const handleEditAccountBalance = (balance) => {
    updateStagingAccountBalance(balance)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit Staging Account Balance.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingAccountBalances(batchId);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title='Edit Staging Account Balance'
      onClose={handleClose}
      isCloseButtonShown={false}
      className={amounts.length > 6 ? 'medium-dialog' : ''}
    >
      <EditAccountBalanceForm
        amounts={amounts}
        closeDialog={handleClose}
        dynamicHeaders={dynamicHeaders}
        isECR={accountBalance.description === 'ecr'}
        handleEditAccountBalance={handleEditAccountBalance}
        indexes={indexes}
      />
    </Dialog>
  );
};

export default EditAccountBalanceDialog;
