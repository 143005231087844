import React from 'react';
import {
  MessageText,
  AlignCenter,
  WhiteButton,
  RegularButton,
} from '../../styled-components';
import colors from '../../../utils/colors';
import { Dialog } from '@blueprintjs/core';
import LoadSpinner from '../../LoadSpinner';

const ExtractionWarningDialog = ({
  isOpen,
  handleCloseExtractionWarningDialog,
  handleRetryExtraction,
  isRetryingExtraction,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleCloseExtractionWarningDialog}
      title='Confirm'
      isCloseButtonShown={false}
    >
      <MessageText style={{ padding: '30px 50px 20px' }}>
        Are you sure you want to retry the extraction?
      </MessageText>
      <AlignCenter
        justifyContent='space-between'
        padding='10px 20px 10px 0'
        borderTop={`1px solid ${colors.lightText}`}
        margin='50px 0 0 0'
      >
        <WhiteButton
          type='button'
          margin='0 0 0 40px'
          onClick={handleCloseExtractionWarningDialog}
          color={colors.primary}
          hoverColor={colors.purpleHover}
        >
          CANCEL
        </WhiteButton>

        <RegularButton
          padding='10px 30px'
          display='flex'
          background={colors.primary}
          backgroundHover={colors.purpleHover}
          onClick={handleRetryExtraction}
          disabled={isRetryingExtraction}
        >
          RETRY
          {isRetryingExtraction && (
            <span style={{ marginLeft: '10px' }}>
              <LoadSpinner size={20} />
            </span>
          )}
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};

export default ExtractionWarningDialog;
