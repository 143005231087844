import React, { useEffect, useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { find } from 'lodash';
import EditFeeForm from '../../forms/treasury-services/EditFeeForm';
import { updateStagingFees } from '../../../api/treasuryServices';
import AppToast from '../../Toast';

const EditFeeDialog = ({
  isOpen,
  handleClose,
  fee,
  batchId,
  fetchStagingFees,
  bankLevelExceptionList,
  clientLevelExceptionList,
  feeCategoryList,
}) => {
  const getCategoryList = () =>
    Object.keys(feeCategoryList[0]).map((item, index) => ({
      label: item,
      value: index,
    }));
  const getSubCategoryList = () =>
    Object.values(feeCategoryList[0]).map((items) =>
      items.map((item) => ({ label: item[1], value: item[0] }))
    );

  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [clientLevelException, setClientLevelException] = useState('');
  const [bankLevelException, setBankLevelException] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [categoryList] = useState(getCategoryList());
  const [subCategoryList] = useState(getSubCategoryList());

  useEffect(() => {
    if (fee) {
      const tempCategory = find(categoryList, ['label', fee.category]);
      setCategory(tempCategory || {});
      setSubCategory(
        (tempCategory &&
          find(subCategoryList[tempCategory.value || 0], [
            'label',
            fee.subCategory,
          ])) ||
          {}
      );
      setUnitPrice(fee.unitPrice);
      setBankLevelException(fee.bankLevelException || '');
      setClientLevelException(fee.clientLevelException || '');
    }
  }, [fee, categoryList, subCategoryList]);

  const handleEditFee = (values) => {
    const feeIds = fee.volumes.map((item) => item[0]);
    const fees = { ...values, feeIds };
    updateStagingFees(fees)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit Staging Fees.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingFees(batchId);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title='Edit Staging Fee'
      isCloseButtonShown={false}
    >
      <EditFeeForm
        closeDialog={handleClose}
        category={category}
        unitPrice={unitPrice}
        subCategory={subCategory}
        handleEditFee={handleEditFee}
        bankLevelException={bankLevelException}
        clientLevelException={clientLevelException}
        categoryList={categoryList}
        subCategoryList={subCategoryList}
        bankLevelExceptionList={bankLevelExceptionList}
        clientLevelExceptionList={clientLevelExceptionList}
      />
    </Dialog>
  );
};

export default EditFeeDialog;
