import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import {
  BorderComponentContainer,
  BoldText,
  FlexColumn,
} from '../styled-components';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon } from '@blueprintjs/core';

const FileUpload = ({ handleDrop, acceptedText, isReportWidget, styles }) => {
  return (
    <DropzoneContainer style={styles}>
      <BorderComponentContainer padding='0' height='21.5rem'>
        <FlexColumn>
          <div
            style={{
              borderBottom: `1px solid ${colors.lightText}`,
              padding: '25px 0 25px 50px',
            }}
          >
            <BoldText>{isReportWidget ? 'Add File' : 'Add Files'}</BoldText>
          </div>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <DropzoneWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                <ContentWrapper>
                  <Icon
                    icon={iconValue.folder}
                    size={16}
                    color={colors.lightText}
                  />
                  <BrowseText>
                    {isReportWidget
                      ? 'Drop file here or browse.'
                      : 'Drop files here or browse.'}
                  </BrowseText>
                  <DefaultText>
                    {acceptedText
                      ? acceptedText
                      : 'Accepted file types are .csv, .txt, .pdf, .docx, .xls, and .xlsx.'}
                  </DefaultText>
                </ContentWrapper>
              </DropzoneWrapper>
            )}
          </Dropzone>
        </FlexColumn>
      </BorderComponentContainer>
    </DropzoneContainer>
  );
};

const DropzoneContainer = styled.div`
  width: 45%;
  margin: 2rem 0;
`;

const DropzoneWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  span {
    svg {
      width: 150px;
      height: 150px;
    }
  }
`;

const BrowseText = styled.span`
  color: ${colors.purpleText};
  border: 1px solid ${colors.primary};
  padding: 10px 5px;
  margin: 15px 0;

  :hover {
    cursor: pointer;
  }
`;

const DefaultText = styled.p`
  color: ${colors.normalText};
  margin-bottom: 15px;
`;

export default FileUpload;
