import React from 'react';
import { AlignCenter } from '../../../components/styled-components';
import { Formik, Form } from 'formik';
import ModalTextField from '../../../components/formik-text-fields/ModalTextField';
import SelectFieldFormik from '../../../components/select-fields/SelectFieldFormik';
import * as Yup from 'yup';
import TextField from '../../text-fields/TextField';
import FormActions from '../FormActions';

const statusList = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const keywordCategorySchema = Yup.object().shape({
  keywordCategoryName: Yup.string().required('Required!'),
  keywordCategoryRank: Yup.number().required('Required!'),
  status: Yup.string().required('Required!'),
});

const transformValues = (values, title) => {
  values.keywordCategoryRank = Number(values.keywordCategoryRank);
  // On add do not send status as it defaults to active
  if (title === 'Add Keyword Category') {
    delete values.status;
  } else {
    values.status = Number(values.status.toLowerCase() === 'active');
  }
  return values;
};

const KeywordCategoriesForm = ({
  title,
  categoryName,
  rank,
  status,
  onClose,
  handleEdit,
  handleCreate,
}) => {
  return (
    <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
      <Formik
        initialValues={{
          keywordCategoryName: categoryName,
          keywordCategoryRank: rank,
          status: status ? status : 'Active',
        }}
        validationSchema={keywordCategorySchema}
        onSubmit={(values) => {
          if (title === 'Add Keyword Category')
            handleCreate(transformValues(values, title));
          else handleEdit(transformValues(values, title));
        }}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
              <ModalTextField
                type='text'
                name='keywordCategoryName'
                placeholder='Category Name'
                label='Category Name'
                value={values.keywordCategoryName || ''}
                autoComplete='off'
              />

              <TextField
                type='number'
                name='keywordCategoryRank'
                placeholder='Rank'
                label='Rank'
                value={values.keywordCategoryRank || ''}
                onChange={(event) => {
                  setFieldValue('keywordCategoryRank', event.target.value);
                }}
                autoComplete='off'
                min={0}
              />
            </AlignCenter>
            {title === 'Edit Keyword Category' && (
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  value={values.status || ''}
                  labelName={'Status'}
                  items={statusList}
                  name='status'
                  useLabel={true}
                />
                <div style={{ minWidth: '271px' }}></div>
              </AlignCenter>
            )}
            <FormActions isSubmitting={isSubmitting} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default KeywordCategoriesForm;
