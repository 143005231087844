import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import AccountForm from '../../forms/client-profile-forms/AccountForm';


const AccountDialogBox = ({
  isOpen,
  title,
  closeDialog,
  categories,
  bankNames,
  clientBanks,
  bankDefaultDataGatheringFrequencies,
  clientDataGatheringFrequency,
  types,
  account,
  handleCreateAccount,
  handleEditAccount,
}) => {
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountAlias, setAccountAlias] = useState('');
  const [accountBalance, setAccountBalance] = useState('');
  const [accountCategory, setAccountCategory] = useState('');
  const [accountClosed, setAccountClosed] = useState('No');
  const [bankId, setBankId] = useState('');
  const [accountBalanceDate, setAccountBalanceDate] = useState('');
  const [accountDateClosed, setAccountDateClosed] = useState('');
  const [collectionType, setCollectionType] = useState(undefined);
  const [inheritedCollectionType, setInheritedCollectionType] = useState(false);
  const [hasClientBankRelation, setHasClientBankRelation] = useState(false);
  const [dataGatheringFrequency, setDataGatheringFrequency] = useState(undefined);

  useEffect(() => {
    if (account) {
      setAccountName(account.accountName || '');
      setAccountType(account.accountType || '');
      setAccountNumber(account.accountNumber || '');
      setAccountAlias(account.accountAlias || '');
      setAccountBalance(account.accountBalance || '');
      setAccountCategory(account.accountCategory || '');
      setAccountClosed(account.accountClosed || 'No');
      setBankId(account.bankId || '');
      setAccountBalanceDate(account.accountBalanceDate || '');
      setAccountDateClosed(account.accountDateClosed || '');
      setCollectionType(account.collectionType || 0);
      setDataGatheringFrequency(account.dataGatheringFrequency || undefined);
      setInheritedCollectionType(account.inheritedCollectionType || false);
      setHasClientBankRelation(clientBanks?.find(cb => cb.bankId === account.bankId) ? true : false);
    }
  }, [account, clientBanks]);

  return (
    <>
      <Dialog
        className='medium-dialog'
        isOpen={isOpen}
        onClose={closeDialog}
        title={title}
        isCloseButtonShown={false}
      >
        <AccountForm
          title={title}
          closeDialog={closeDialog}
          categories={categories}
          bankNames={bankNames}
          bankDefaultDataGatheringFrequencies={bankDefaultDataGatheringFrequencies}
          types={types}
          accountCategory={accountCategory}
          accountName={accountName}
          accountNumber={accountNumber}
          bankId={bankId}
          accountAlias={accountAlias}
          accountBalance={accountBalance}
          accountType={accountType}
          accountClosed={accountClosed}
          accountDateClosed={accountDateClosed}
          accountBalanceDate={accountBalanceDate}
          collectionType={collectionType}
          inheritedCollectionType={inheritedCollectionType}
          hasClientBankRelation={hasClientBankRelation}
          dataGatheringFrequency={dataGatheringFrequency}
          clientDataGatheringFrequency={clientDataGatheringFrequency}
          clientBanks={clientBanks}
          handleCreateAccount={handleCreateAccount}
          handleEditAccount={handleEditAccount}
        />
      </Dialog>
    </>
  );
};

export default AccountDialogBox;
