import axios from 'axios';
import { setHeader } from './index';

const QUANDL_API = process.env.REACT_APP_GATEWAY_ENDPOINT;

// TODO: move quandl api to dashboard for api/ reducer/ actions and fix usages

export const fetchQuandlData = async () => {
  const request = await setHeader(axios);
  return request.get(`${QUANDL_API}/dashboard/quandl`);
};
