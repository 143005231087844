import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { updateStagingFees } from '../../../api/treasuryServices';
import AppToast from '../../Toast';
import BulkEditFeeForm from '../../forms/treasury-services/BulkEditFeeForm';

const BulkEditFeeDialog = ({
  isOpen,
  handleClose,
  feeIds,
  batchId,
  fetchStagingFees,
  feeCategoryList,
}) => {
  const getCategoryList = () =>
    Object.keys(feeCategoryList[0]).map((item, index) => ({
      label: item,
      value: index,
    }));
  const getSubCategoryList = () =>
    Object.values(feeCategoryList[0]).map((items) =>
      items.map((item) => ({ label: item[1], value: item[0] }))
    );

  const [categoryList] = useState(getCategoryList());
  const [subCategoryList] = useState(getSubCategoryList());

  const handleBulkEditFee = (values) => {
    const fees = {...values, feeIds}
    updateStagingFees(fees)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS
        });
      })
      .catch(err => {
        AppToast.show({
          message: 
            err.response && err.response.data.msg 
              ? err.response.data.msg 
              : 'Failed to edit Staging Fees.',
          intent: Intent.DANGER
        })
      })
      .finally(()=>{
        fetchStagingFees(batchId)
        handleClose()
      })
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title='Bulk Edit Staging Fee category'
      isCloseButtonShown={false}
    >
      <BulkEditFeeForm
        closeDialog={handleClose}
        handleBulkEditFee={handleBulkEditFee}
        categoryList={categoryList}
        subCategoryList={subCategoryList}
      />
    </Dialog>
  );
};

export default BulkEditFeeDialog;
