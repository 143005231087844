import React, { useState, useEffect } from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  NameLink,
  Label,
  WidgetHeader,
  WidgetBody,
  WidgetBodyHeader,
  WidgetRow,
  CardDiv,
} from '../styled-components';
import colors from '../../utils/colors';
import { paginate } from '../../utils/functions';
import WidgetPagination from './WidgetPagination';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';

const pageLimit = 5;

const ReportsInProgressWidget = ({ reports }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedReports, setPaginatedReports] = useState([]);

  useEffect(() => {
    setPaginatedReports(paginate(reports, pageLimit, pageNumber));
  }, [reports, pageNumber]);

  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ flexBasis: '750px', flexGrow: '3' }}
    >
      <WidgetHeader>
        <Label>Reports In Progress</Label>
        <NameLink
          to={{
            pathname: `/data-ingestion`,
          }}
        >
          View All
        </NameLink>
      </WidgetHeader>
      <WidgetBody>
        <WidgetBodyHeader>
          <Label width={'20%'}>Client</Label>
          <Label width={'15%'}>Date Started</Label>
          <Label width={'15%'}>Report Due</Label>
          <Label width={'10%'}>Open Batches</Label>
          <Label width={'18%'}>Analysis Status</Label>
        </WidgetBodyHeader>
        {paginatedReports.length ? (
          paginatedReports.map((report) => (
            <WidgetRow key={report.clientId}>
              <NameLink
                title={report.clientName}
                to={{
                  pathname: `/data-ingestion/${report.clientId}`,
                }}
                width={'20%'}
              >
                {report.clientName.length > 16
                  ? report.clientName.substring(0, 13) + '...'
                  : report.clientName}
              </NameLink>
              <div style={{ width: '15%' }}>{report.startDate}</div>
              <div style={{ width: '15%' }}>{report.dueDate}</div>
              <div style={{ width: '10%' }}>{report.numberOfOpenBatches}</div>
              <div
                style={
                  report.status === 'READY'
                    ? {
                      width: '18%',
                      fontWeight: '700',
                      color: colors.purpleText,
                    }
                    : report.status === 'IN PROGRESS'
                      ? { width: '18%', fontWeight: '700', color: colors.green }
                      : {
                        width: '18%',
                        fontWeight: '700',
                        color: colors.greyPlaceholderText,
                      }
                }
              >
                {report.status}
              </div>
            </WidgetRow>
          ))
        ) : (
          <NonIdealStateComp
            icon={iconValue.warning}
            title={'No Results.'}
            description={'There are no results to be displayed.'}
          />
        )}
      </WidgetBody>

      <WidgetPagination
        currentIndex={pageNumber}
        recordsLength={reports.length}
        setPageNumber={setPageNumber}
        pageLimit={pageLimit}
      />
    </CardDiv>
  );
};

export default ReportsInProgressWidget;
