import React, { useMemo, useState } from 'react';
import { Icon, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import colors from '../../utils/colors';
import { checkPermissions } from '../../utils/functions';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';
import { ActionIconButton, StyleColumnCell } from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import { TableContainer } from './components/table-styled-components';

const StagingFeesTable = ({
  stagingFees,
  appUser,
  isFetching,
  setDynamicHeaders,
  handleOpenEditDialog,
  handleValidateFee,
  handleInvalidateFee,
  handleBulkEditFee,
  handleOpenDeleteDialog,
  ...props
}) => {
  const [dynamicColumns, setDynamicColumns] = useState([]);
  if (!isFetching && stagingFees.length > 0 && !dynamicColumns.length) {
    setDynamicHeaders(stagingFees[0].headers);
    stagingFees[0].headers.forEach((item, i) => {
      dynamicColumns.push({
        Header: ({ column }) => (
          <SortableHeader column={column} header={item} sizeFont={'0.75'} />
        ),
        accessor: (row) => row.volumes[i][1],
        Cell: ({ cell: { value } }) => value || '-',
        id: item,
        filter: 'numberRange',
        width: '3%',
        isVisible: true,
      });
    });
    setDynamicColumns([...dynamicColumns]);
    stagingFees.splice(0, 1);
  }

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Bank Category'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'bankCategory',
        filter: 'multipleValues',
        width: '8%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Description'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'description',
        filter: 'multipleValues',
        width: '8%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Bank Code'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'bankCode',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='AFP Code' sizeFont={'0.75'} />
        ),
        accessor: 'afpCode',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Category' sizeFont={'0.75'} />
        ),
        Cell: ({ cell: { value } }) => value || '-',
        accessor: 'category',
        filter: 'multipleValues',
        width: '8%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Sub Category'
            sizeFont={'0.75'}
          />
        ),
        Cell: ({ cell: { value } }) => value || '-',
        accessor: 'subCategory',
        filter: 'multipleValues',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Bank Level Exception'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'bankLevelException',
        filter: 'multipleValues',
        Cell: ({ cell: { value } }) => value || '-',
        width: '3%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Client Level Exception'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'clientLevelException',
        filter: 'multipleValues',
        Cell: ({ cell: { value } }) => value || '-',
        width: '3%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Unit Price'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'unitPrice',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => (
          <StyleColumnCell
            color={value > 0 ? colors.red : value < 0 ? colors.green : ''}
            fontWeight={400}
          >
            {value}
          </StyleColumnCell>
        ),
        width: '3%',
        isVisible: true,
      },
      ...dynamicColumns,
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Validated?' />
        ),
        accessor: 'validationStatus',
        width: '1%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'No' ? colors.lightText : colors.primary}
            >
              {value && value.toUpperCase()}
            </StyleColumnCell>
          );
        },
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.EDIT_STAGING_FEES,
                  ]) && (
                    <MenuItem
                      text='Edit'
                      onClick={() => handleOpenEditDialog(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.VALIDATE_STAGING_FEES,
                    permissions.INVALIDATE_STAGING_FEES,
                  ]) && (
                    <MenuItem
                      text={
                        row.original.validationStatus === 'No'
                          ? 'Validate'
                          : 'Invalidate'
                      }
                      onClick={() =>
                        row.original.validationStatus === 'No'
                          ? handleValidateFee(row.original)
                          : handleInvalidateFee(row.original)
                      }
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.DELETE_STAGING_FEES,
                  ]) && (
                    <>
                      <Menu.Divider />
                      <MenuItem
                        text={'Delete'}
                        onClick={() => {
                          handleOpenDeleteDialog(row.original);
                        }}
                        style={{
                          color: `${colors.red}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                          paddingBottom: ' 5px',
                          marginBottom: '10px',
                        }}
                      />
                    </>
                  )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_STAGING_FEES,
          permissions.EDIT_STAGING_FEES,
          permissions.DELETE_STAGING_FEES,
          permissions.VALIDATE_STAGING_FEES,
          permissions.INVALIDATE_STAGING_FEES,
        ]),
      },
    ],
    [
      appUser,
      dynamicColumns,
      handleValidateFee,
      handleInvalidateFee,
      handleOpenEditDialog,
      handleOpenDeleteDialog,
    ]
  );
  return (
    <>
      <TableContainer width='100%' padding='0rem 2rem 1rem'>
        <MakeTable
          appUser={appUser}
          tableTitle='Fees'
          data={stagingFees}
          isFetching={isFetching}
          columns={columns}
          customPageOptions={[10, 25, 50, 75, 100]}
          isTreasuryServices={true}
          handleValidateStagingTable={handleValidateFee}
          handleInvalidateStagingTable={handleInvalidateFee}
          handleBulkEditStagingTable={handleBulkEditFee}
          {...props}
        />
      </TableContainer>
    </>
  );
};

export default StagingFeesTable;
