import React, { useMemo } from 'react';
import { Icon } from '@blueprintjs/core';
import SortableHeader from './components/SortableHeader';
import {
  AlignCenter,
  StyleColumnCell,
  ActionIconButton,
} from '../styled-components';
import RowSelector from './components/RowSelector';
import MakeTable from './components/MakeTable';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import { checkPermissions, amountFormatter } from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const TransactionTable = ({
  appUser,
  transactions,
  isFetching,
  handleDeleteDialogOpen,
  handleEditDialogOpen,
  handleBulkEditDialogOpen,
  handleDownloadStatement,
  handleBulkDownload,
  isServerSidePagination,
  resultCount,
  pageCount,
  updatePaginate,
  ...props
}) => {
  const customPageOptions = [50, 100, 200, 300, 500];

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'transactionDate',
        width: '8%',
        isVisible: true,
        filter: 'dateRange',
        sortType: 'customDateTimeSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Description' />
        ),
        accessor: 'transactionDescription',
        width: '30%',
        isVisible: true,
        filter: 'text',
        sortType: 'caseInsensitiveSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Keywords' />
        ),
        accessor: 'keyword',
        width: '10%',
        isVisible: true,
        filter: 'multipleValues',
        sortType: 'caseInsensitiveSort',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Debit/Credit' />
        ),
        accessor: 'amount',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value > 0 ? colors.green : colors.red}
              fontWeight='400'
            >
              {amountFormatter(value)}
            </StyleColumnCell>
          );
        },
        filter: 'debitCredit',
        width: '5%',
        isVisible: true,
        sortType: 'debitCreditSort',
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '5%',
        Cell: ({ row }) => (
          <AlignCenter justifyContent='start'>
            {checkPermissions(appUser.permList, [
              permissions.EDIT_TRANSACTIONS,
            ]) && (
              <ActionIconButton
                onClick={() => handleEditDialogOpen(row.original)}
              >
                <Icon
                  icon={iconValue.pen}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            )}
            {checkPermissions(appUser.permList, [
              permissions.DELETE_TRANSACTIONS,
            ]) && (
              <ActionIconButton
                margin='0 0 0 5px'
                onClick={() => handleDeleteDialogOpen(row.original)}
              >
                <Icon
                  icon={iconValue.trash}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            )}
            {checkPermissions(appUser.permList, [
              permissions.DOWNLOAD_FILES,
            ]) && (
              <ActionIconButton
                margin='0 0 0 5px'
                onClick={() => handleDownloadStatement(row.original)}
                disabled={row.original.fileName === '' ? true : false}
              >
                <Icon
                  icon={iconValue.download}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            )}
          </AlignCenter>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.EDIT_TRANSACTIONS,
        ]),
      },
    ],
    [
      appUser,
      handleDeleteDialogOpen,
      handleEditDialogOpen,
      handleDownloadStatement,
    ]
  );

  return (
    <TableContainer padding='0'>
      <MakeTable
        appUser={appUser}
        data={transactions}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Transactions'
        customPageOptions={customPageOptions}
        openBulkEditDialog={handleBulkEditDialogOpen}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        handleBulkDownload={handleBulkDownload}
        isServerSidePagination={isServerSidePagination}
        updatePaginate={updatePaginate}
        resultCount={resultCount}
        pageCount={pageCount}
        {...props}
      />
    </TableContainer>
  );
};

export default TransactionTable;
