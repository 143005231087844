import React from 'react';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
  FlexColumn,
  ErrorMessageDiv,
  MessageText,
} from '../../styled-components';
import { Formik, Form } from 'formik';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import colors from '../../../utils/colors';
import LoadSpinner from '../../LoadSpinner';
import MyAccountTextField from '../../formik-text-fields/MyAccountTextField';

const PasswordForm = ({
  currentPassword,
  newPassword,
  confirmPassword,
  currentPasswordError,
  newPasswordError,
  confirmPasswordError,
  verifyPasswordError,
  cognitoError,
  showCognitoError,
  samePasswordError,
  showSamePasswordError,
  handleChangePassword,
  handleResetForm,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column'>
        <Formik
          initialValues={{
            currentPassword,
            newPassword,
            confirmPassword,
          }}
          onSubmit={handleChangePassword}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, resetForm }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter justifyContent='flex-start' padding='25px 0 0 0'>
                <FlexColumn style={{ width: '100%' }}>
                  <MyAccountTextField
                    type='password'
                    name='currentPassword'
                    placeholder=''
                    label='Current Password'
                    value={values.currentPassword || ''}
                    autoComplete='off'
                  />
                  {currentPasswordError && !showCognitoError && (
                    <AlignCenter justifyContent='space-between'>
                      <ErrorMessageDiv fontSize='1rem'>
                        Required!
                      </ErrorMessageDiv>
                      <Icon
                        icon={iconValue.warning}
                        iconSize={16}
                        color={colors.red}
                      />
                    </AlignCenter>
                  )}
                  {(currentPasswordError || !currentPasswordError) &&
                    showCognitoError &&
                    cognitoError === 'Invalid Password.' && (
                      <AlignCenter justifyContent='space-between'>
                        <ErrorMessageDiv fontSize='1rem'>
                          {cognitoError}
                        </ErrorMessageDiv>
                        <Icon
                          icon={iconValue.warning}
                          iconSize={16}
                          color={colors.red}
                        />
                      </AlignCenter>
                    )}
                </FlexColumn>
              </AlignCenter>

              <AlignCenter justifyContent='flex-start' padding='25px 0 0 0'>
                <FlexColumn style={{ width: '100%' }}>
                  <MyAccountTextField
                    type='password'
                    name='newPassword'
                    placeholder=''
                    label='New Password'
                    value={values.newPassword || ''}
                    autoComplete='off'
                  />
                  {newPasswordError && !showSamePasswordError && (
                    <AlignCenter justifyContent='space-between'>
                      <ErrorMessageDiv fontSize='1rem'>
                        Required!
                      </ErrorMessageDiv>
                      <Icon
                        icon={iconValue.warning}
                        iconSize={16}
                        color={colors.red}
                      />
                    </AlignCenter>
                  )}

                  {newPasswordError && showSamePasswordError && (
                    <AlignCenter justifyContent='space-between'>
                      <ErrorMessageDiv fontSize='1rem'>
                        {samePasswordError}
                      </ErrorMessageDiv>
                      <Icon
                        icon={iconValue.warning}
                        iconSize={16}
                        color={colors.red}
                      />
                    </AlignCenter>
                  )}

                  {(newPasswordError || !newPasswordError) &&
                    showCognitoError &&
                    cognitoError === 'Password must meet requirements.' && (
                      <AlignCenter justifyContent='space-between'>
                        <ErrorMessageDiv fontSize='1rem'>
                          {cognitoError}
                        </ErrorMessageDiv>
                        <Icon
                          icon={iconValue.warning}
                          iconSize={16}
                          color={colors.red}
                        />
                      </AlignCenter>
                    )}
                </FlexColumn>
              </AlignCenter>

              <AlignCenter justifyContent='flex-start' padding='25px 0 0 0'>
                <FlexColumn style={{ width: '100%' }}>
                  <MyAccountTextField
                    type='password'
                    name='confirmPassword'
                    placeholder=''
                    label='Verify New Password'
                    value={values.confirmPassword || ''}
                    autoComplete='off'
                  />
                  {confirmPasswordError && (
                    <AlignCenter justifyContent='space-between'>
                      {!verifyPasswordError && (
                        <ErrorMessageDiv fontSize='1rem'>
                          Required!
                        </ErrorMessageDiv>
                      )}
                      {verifyPasswordError && (
                        <ErrorMessageDiv fontSize='1rem'>
                          Passwords must match!
                        </ErrorMessageDiv>
                      )}
                      <Icon
                        icon={iconValue.warning}
                        iconSize={16}
                        color={colors.red}
                      />
                    </AlignCenter>
                  )}
                </FlexColumn>
              </AlignCenter>

              <MessageText>At least 12 characters long.</MessageText>
              <MessageText>
                Contains at least one of each of the following: lower case
                letter, upper case letter, number and symbol.
              </MessageText>

              <AlignCenter justifyContent='flex-end'>
                <WhiteButton
                  type='button'
                  margin='30px 20px 0 0'
                  onClick={() => handleResetForm(resetForm)}
                >
                  Cancel
                </WhiteButton>
                <RegularButton
                  type='submit'
                  display='flex'
                  width='13rem'
                  margin='30px 0 0 0'
                  disabled={isSubmitting}
                >
                  Save Password
                  {isSubmitting && (
                    <span style={{ marginLeft: '10px' }}>
                      <LoadSpinner size={20} />
                    </span>
                  )}
                </RegularButton>
              </AlignCenter>
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default PasswordForm;
