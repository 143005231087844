import React from 'react';
import {
  Label,
  FlexColumn,
  AlignCenter,
  ErrorMessageDiv,
} from '../styled-components';
import { Select } from '@blueprintjs/select';
import { showListItems, filterList } from '../../utils/functions';
import { Button, Icon, Tooltip } from '@blueprintjs/core';
import {
  SelectAccountOverflowStyle,
  SelectDefaultInputStyle,
  SelectDisabledInputStyle,
  SelectStepTwoDisabledInputStyle,
} from '../../utils/fieldStyles';
import iconValue from '../../utils/icons';
import { useField } from 'formik';
import colors from '../../utils/colors';

const SelectFieldFormik = ({
  setFieldValue,
  value,
  labelName,
  items,
  isDisabled,
  toolTipText,
  useLabel,
  isStepTwoShortTermModel,
  useItem,
  hasHandleFunction,
  handleSelectChange,
  placeHolder,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <FlexColumn>
      <Label
        color={
          isDisabled && isStepTwoShortTermModel
            ? colors.lightText
            : colors.boldText
        }
      >
        {labelName}
        {toolTipText && (
          <div
            style={{
              display: 'inline-block',
              padding: '0px 0px 5px 5px',
              verticalAlign: 'middle',
            }}
          >
            <Tooltip content={toolTipText}>
              <Icon
                icon={iconValue.info}
                iconSize={16}
                color={colors.primary}
              />
            </Tooltip>
          </div>
        )}
      </Label>

      <Select
        name={field.name}
        items={items}
        itemRenderer={showListItems}
        itemPredicate={filterList}
        onItemSelect={(item) => {
          hasHandleFunction
            ? handleSelectChange(item)
            : setFieldValue(
                field.name,
                useLabel ? item.label : useItem ? item : item.value
              );
        }}
        filterable={true}
        popoverProps={{ minimal: true }}
        disabled={items.length === 0 || isDisabled}
      >
        <Button
          text={value ? value : placeHolder ? placeHolder : labelName}
          style={
            isDisabled && isStepTwoShortTermModel
              ? SelectStepTwoDisabledInputStyle
              : items.length === 0 || isDisabled
              ? SelectDisabledInputStyle
              : value
              ? SelectAccountOverflowStyle
              : SelectDefaultInputStyle
          }
          disabled={items.length === 0 || isDisabled}
          rightIcon={
            items.length === 0 || isDisabled ? null : (
              <Icon
                icon={iconValue.caretDown}
                iconSize={20}
                style={{ marginRight: '10px' }}
              />
            )
          }
        />
      </Select>
      <div>
        {meta.touched && meta.error && Object.entries(meta.error).length > 0 ? (
          <AlignCenter justifyContent='space-between'>
            <ErrorMessageDiv fontSize='1rem'>{meta.error}</ErrorMessageDiv>
            <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
          </AlignCenter>
        ) : null}
      </div>
    </FlexColumn>
  );
};

export default SelectFieldFormik;
