import React from 'react';
import { Dialog } from '@blueprintjs/core';
import {
  MessageText,
  AlignCenter,
  WhiteButton,
  RegularButton,
} from '../../styled-components';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import LoadSpinner from '../../LoadSpinner';

const ResetMfaDialogBox = ({
  isOpen,
  title,
  closeDialog,
  handleResetMfa,
  mfaDisable,
}) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title}
        isCloseButtonShown={false}
      >
        <div style={{ padding: '1rem 3rem 0' }}>
          <MessageText>
            To reset your MFA click <TextValue>Confirm and Log Out</TextValue>{' '}
            below and you will be logged out and prompted to set up your new MFA
            the next time you log in.
          </MessageText>
        </div>

        <AlignCenter
          justifyContent='flex-end'
          padding='10px 20px 10px 0'
          borderTop={`1px solid ${colors.lightText}`}
          margin='50px 0 0 0'
        >
          <WhiteButton type='button' onClick={closeDialog}>
            CANCEL
          </WhiteButton>
          <RegularButton
            width='20rem'
            type='button'
            display='flex'
            onClick={handleResetMfa}
            disabled={mfaDisable}
          >
            CONFIRM AND LOG OUT
            {mfaDisable && (
              <span style={{ marginLeft: '10px' }}>
                <LoadSpinner size={20} />
              </span>
            )}
          </RegularButton>
        </AlignCenter>
      </Dialog>
    </>
  );
};

const TextValue = styled.span`
  font-weight: 700;
`;

export default ResetMfaDialogBox;
