import React, { useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import { fetchClientAccountGroups } from '../../../actions/accounts';
import { fetchMonteCarloHistory } from '../../../actions/analysis';
import { connect } from 'react-redux';
import AppToast from '../../Toast';
import { Intent } from '@blueprintjs/core';
import MonteCarloForm from '../../forms/analytics/MonteCarloForm';
import { runAnalysis } from '../../../api/analysis';

const RunMonteCarloAnalysisDialogBase = ({
  handleDialogClose,
  isOpen,
  monteCarloHistory,
  clientAccountGroups,
  clientId,
  endRange,
  accountGroup,
  accountGroupId,
  name,
  numberOfYears,
  ...props
}) => {
  const { fetchClientAccountGroups, fetchMonteCarloHistory } = props;

  useEffect(() => {
    fetchClientAccountGroups(clientId);
  }, [fetchClientAccountGroups, clientId]);

  const handleRunAnalysis = (settings) => {
    runAnalysis(settings)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Run Analysis',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchMonteCarloHistory(clientId);
        handleDialogClose();
      });
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={'Settings'}
        onClose={handleDialogClose}
        isCloseButtonShown={false}
      >
        <MonteCarloForm
          title={'Settings'}
          handleRunAnalysis={handleRunAnalysis}
          onClose={handleDialogClose}
          endRange={endRange}
          accountGroup={accountGroup}
          accountGroupId={accountGroupId}
          name={name}
          clientAccountGroups={clientAccountGroups}
          clientId={clientId}
          numberOfYears={numberOfYears}
          {...props}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  clientAccountGroups: state.accounts.clientAccountGroups,
  monteCarloHistory: state.analysis.monteCarloHistory,
});

const RunMonteCarloAnalysisDialog = connect(mapStateToProps, {
  fetchClientAccountGroups,
  fetchMonteCarloHistory,
})(RunMonteCarloAnalysisDialogBase);

export default RunMonteCarloAnalysisDialog;
