import React from 'react';
import {
  ComponentHeader,
  HeaderText,
  WhiteButton,
  MainSubNavContainer,
} from '../styled-components';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import UserProfileSubNavigation from '../navigation/SubNavigation/UserProfileSubNavigation';
import QuandlData from './header-data/QuandlData';

const UserHeader = ({
  appUser,
  header,
  hasButton,
  buttonText,
  action,
  hasUserProfileSubNav,
  handleUserProfileComponentChange,
  userProfileComponent,
  ...props
}) => {
  const { open } = props;
  return (
    <>
      <ComponentHeader className={open}>
        <QuandlData {...props} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <HeaderText>{header}</HeaderText>
          {hasButton &&
            checkPermissions(appUser.permList, [permissions.ADD_USERS]) && (
              <WhiteButton width='18rem' margin='0 3rem 0 0' onClick={action}>
                {buttonText}
              </WhiteButton>
            )}
        </div>
      </ComponentHeader>
      {hasUserProfileSubNav && (
        <MainSubNavContainer className={open}>
          <UserProfileSubNavigation
            handleUserProfileComponentChange={handleUserProfileComponentChange}
            userProfileComponent={userProfileComponent}
          />
        </MainSubNavContainer>
      )}
    </>
  );
};

export default UserHeader;
