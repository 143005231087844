import React, { useMemo } from 'react';
import SortableHeader from './components/SortableHeader';
import MakeTable from './components/MakeTable';
import styled from 'styled-components';
import { getMonthRange, localDateFormatter } from '../../utils/functions';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import { ActionIconButton } from '../styled-components';


const ValidationHistoryTable = ({
  validationHistory,
  resetInitialFilter,
  isFetching,
  handleOpenValidationHistoryDialog,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank' />
        ),
        accessor: 'bankName',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account' />
        ),
        accessor: 'accountName',
        width: '10%',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Type' small={true} />
        ),
        accessor: 'templateType',
        sortType: 'caseInsensitiveSort',
        width: '7%',
        isVisible: true,
        filter: 'text',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Created Date' />
        ),
        accessor: 'createdAt',
        width: '10%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
        Cell: ({ cell: { value } }) => localDateFormatter(value),
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='# of Files' />
        ),
        accessor: 'totalFiles',
        width: '15%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Completed Date' />
        ),
        accessor: 'completedAt',
        width: '10%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
      },
      {
        Header: () => <span style={{ fontSize: '1rem' }}>Actions</span>,
        id: 'actions',
        width: '2%',
        sticky: 'right',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                <MenuItem
                  text='Download file'
                  onClick={() => handleOpenValidationHistoryDialog(row.original)}
                  style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                />
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        isVisible: true,
        // isVisible: checkPermissions(appUser.permList, [
        //   permissions.DOWNLOAD_FILES,
        // ]),
      },
    ],
    [handleOpenValidationHistoryDialog]
  );

  return (
    <TableContainer>
      <MakeTable
        data={validationHistory}
        columns={columns}
        tableTitle='Ingested Batches'
        isFetching={isFetching}
        rangeFilter={getMonthRange(3)}
        rangeFilterName='Completed Date'
        resetInitialFilter={resetInitialFilter}
        {...props}
      />
    </TableContainer>
  );
};

// this is for the table rows spacing
// this table doesn't have the actions button creating the spacing
const TableContainer = styled.div`
  div {
    table {
      tbody {
        tr {
          td {
            padding: 15px 20px;
          }
        }
      }
    }
  }
`;

export default ValidationHistoryTable;
