import React, { useMemo } from 'react';
import { ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import { Icon } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import RowSelector from './components/RowSelector';
import { TableContainer } from './components/table-styled-components';

const UploadFileTable = ({
  uploadList,
  isFetching,
  removeSingleFile,
  removeMultipleFiles,
  isReportWidget,
  toggleAllowOverwrite,
  isHybridBatch=false,
  ...props
}) => {
  const customPageOptions = [10];

  const columns = useMemo(
    () => {
      let headers = [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <RowSelector
              {...getToggleAllRowsSelectedProps()}
              style={{ height: '18px', width: '18px' }}
            />
          ),
          Cell: ({ row }) => (
            <RowSelector
              {...row.getToggleRowSelectedProps()}
              style={{ height: '15px', width: '15px' }}
            />
          ),
          isVisible: true,
          width: '1%',
          sticky: 'left',
        },
        {
          Header: ({ column }) => (
            <SortableHeader column={column} header='File Name' />
          ),
          id: 'File Name',
          accessor: (row) => `${row.file ? row.file.name : row.name}`,
          sortType: 'caseInsensitiveSort',
          width: '10%',
          isVisible: true,
        },
        {
          Header: () => 'Actions',
          id: 'actions',
          width: '1%',
          isVisible: true,
          sticky: 'right',
          Cell: ({ row }) => (
            <ActionIconButton onClick={() => removeSingleFile(row)}>
              <Icon icon={iconValue.trash} iconSize={16} color={colors.primary} />
            </ActionIconButton>
          ),
        },
      ]

      if (isHybridBatch)
        headers = [
          ...headers.slice(0, 2),
          {
            Header: ({ column }) => (
              <SortableHeader column={column} header='Template Type' />
            ),
            id: 'Template Type',
            accessor: (row) => `${row.templateType}`,
            sortType: 'caseInsensitiveSort',
            width: '10%',
            isVisible: true,
          },
          headers[2],
        ];

      return headers
    },
    [isHybridBatch, removeSingleFile]
  );

  return (
    <TableContainer padding='2rem 0' width={isHybridBatch? '550px' : '500px'}>
      <MakeTable
        data={uploadList}
        columns={columns}
        isFetching={isFetching}
        tableTitle={isReportWidget ? 'File to Upload' : 'Files to Upload'}
        customPageOptions={customPageOptions}
        boxShadow={`0 3px 10px ${colors.lightText}`}
        removeMultipleFiles={removeMultipleFiles}
        toggleAllowOverwrite={toggleAllowOverwrite}
        {...props}
      />
    </TableContainer>
  );
};

export default UploadFileTable;
