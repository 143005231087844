import React from 'react';
import { Form, Formik } from 'formik';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
} from '../../styled-components';
import * as Yup from 'yup';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import colors from '../../../utils/colors';
import { checkPermissions } from '../../../utils/functions';
import permissions from '../../../utils/permissions';
import LoadSpinner from '../../LoadSpinner';

const treasurySchema = Yup.object().shape({
  accountGroupName: Yup.string().required('Required!'),
});

const TreasuryTemplateForm = ({
  appUser,
  closeDialog,
  handleSubmitTemplate,
  handleWarningTemplateOpen,
  template,
  accountGroupName,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
        <Formik
          initialValues={{ accountGroupName }}
          onSubmit={(values) => {
            handleSubmitTemplate(values, 0, 'Treasury');
          }}
          validationSchema={treasurySchema}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({ isSubmitting, values }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter justifyContent='space-evenly' margin='0 0 10px 0'>
                <ModalTextField
                  type='text'
                  name='accountGroupName'
                  placeholder='Account Group Name'
                  label='Account Group'
                  value={values.accountGroupName || ''}
                  autoComplete='off'
                />
                <div style={{ width: '271px' }}></div>
              </AlignCenter>
              <AlignCenter
                justifyContent={
                  template && Object.keys(template).length === 0
                    ? 'flex-end'
                    : 'space-between'
                }
                padding='10px 20px 0 0'
                borderTop={`1px solid ${colors.lightText}`}
              >
                {checkPermissions(appUser.permList, [
                  permissions.DELETE_TEMPLATES,
                ]) && (
                  <WhiteButton
                    className={
                      template && Object.keys(template).length === 0
                        ? 'hide-button'
                        : ''
                    }
                    justifyContent='flex-start'
                    margin='10px 10px 10px 20px'
                    type='button'
                    color={colors.red}
                    hoverColor={colors.redHover}
                    onClick={handleWarningTemplateOpen}
                  >
                    DELETE
                  </WhiteButton>
                )}
                <AlignCenter justifyContent='space-between' width='fit-content'>
                  <WhiteButton type='button' onClick={closeDialog}>
                    CLOSE
                  </WhiteButton>
                  <RegularButton
                    width='7rem'
                    display='flex'
                    type='submit'
                    disabled={isSubmitting}
                  >
                    SAVE
                    {isSubmitting && (
                      <span style={{ marginLeft: '10px' }}>
                        <LoadSpinner size={20} />
                      </span>
                    )}
                  </RegularButton>
                </AlignCenter>
              </AlignCenter>
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default TreasuryTemplateForm;
