import axios from 'axios';
import { setHeader } from './index';

const ADMINISTRATION_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/administration`;

//CRUD FOR KEYWORDS
export const fetchAllKeywords = async () => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/keywords`);
};

export const fetchSingleKeyword = async (keywordId) => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/keywords/${keywordId}`);
};

export const createKeyword = async (keywords) => {
  const request = await setHeader(axios);
  return request.post(`${ADMINISTRATION_API}/keywords`, {
    keywords,
  });
};

export const deleteKeyword = async (keywordIds) => {
  const request = await setHeader(axios);
  return request.delete(`${ADMINISTRATION_API}/keywords`, {
    data: {
      keywords: { ids: keywordIds },
    },
  });
};

export const updateKeyword = async (keywordId, keywords) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/keywords/${keywordId} `, {
    keywords,
  });
};
// END OF KEYWORDS METHODS

export const fetchUploadDate = async () => {
  const uploadDateOption = 1;
  const request = await setHeader(axios);
  return request.get(
    `${ADMINISTRATION_API}/global-setting/${uploadDateOption}`
  );
};

export const updateUploadDate = async (id, globalSetting) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/global-setting/${id}`, {
    globalSetting,
  });
};

export const fetchMaturityLimit = async () => {
  const maturityLimitOption = 2;
  const request = await setHeader(axios);
  return request.get(
    `${ADMINISTRATION_API}/global-setting/${maturityLimitOption}`
  );
};

export const updateMaturityLimit = async (id, globalSetting) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/global-setting/${id}`, {
    globalSetting,
  });
};

//CRUD FOR KEYWORD CATEGORIES
export const fetchKeywordCategories = async () => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/keyword-category`);
};

export const fetchSingleKeywordCategory = async (keywordCategoryId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ADMINISTRATION_API}/keyword-category/${keywordCategoryId}`
  );
};

export const createKeywordCategory = async (keywordCategories) => {
  const request = await setHeader(axios);
  return request.post(`${ADMINISTRATION_API}/keyword-category`, {
    keywordCategories,
  });
};

export const updateKeywordCategory = async (
  keywordCategoryId,
  keywordCategories
) => {
  const request = await setHeader(axios);
  return request.put(
    `${ADMINISTRATION_API}/keyword-category/${keywordCategoryId} `,
    {
      keywordCategories,
    }
  );
};

export const fetchAllQuoteTypes = async () => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/quote-types`);
};

export const fetchSingleQuoteType = async (quoteTypeId) => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/quote-types/${quoteTypeId}`);
};

export const createQuoteType = async (quoteType) => {
  const request = await setHeader(axios);
  return request.post(`${ADMINISTRATION_API}/quote-types`, {
    quoteType,
  });
};

export const updateQuoteType = async (quoteTypeId, quoteType) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/quote-types/${quoteTypeId} `, {
    quoteType,
  });
};

export const deactivateQuoteTypes = async (ids) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/quote-types`, {
    quoteType: { ids: ids },
  });
};

export const deactivateKeywordCategories = async (ids) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/keyword-category`, {
    keywords: { ids: ids },
  });
};
//END OF KEYWORD CATEGORIES METHODS

export const fetchQuoteTypesList = async () => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/quote-types/list`);
};

// THIS IS FETCHING THE NOTE-CATEGORY DATA
export const fetchAllNotes = async () => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/note-category`);
};
//
export const fetchSingleNoteCategory = async (noteCategoryId) => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/note-category/${noteCategoryId}`);
};

export const createNoteCategory = async (notes) => {
  const request = await setHeader(axios);
  return request.post(`${ADMINISTRATION_API}/note-category`, {
    notes,
  });
};

export const updateNoteCategory = async (noteCategoryId, notes) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/note-category/${noteCategoryId} `, {
    notes,
  });
};

export const deactivateNoteCategories = async (ids) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/note-category`, {
    notes: { categoryIds: ids },
  });
};

export const fetchInvestmentTypes = async () => {
  const request = await setHeader(axios);
  return request.get(`${ADMINISTRATION_API}/investment-types`);
};

export const fetchSingleInvestmentType = async (investmentTypeId) => {
  const request = await setHeader(axios);
  return request.get(
    `${ADMINISTRATION_API}/investment-types/${investmentTypeId}`
  );
};

export const deactivateInvestmentTypes = async (ids) => {
  const request = await setHeader(axios);
  return request.put(`${ADMINISTRATION_API}/investment-types`, {
    investmentType: { ids: ids },
  });
};
export const createInvestmentType = async (investmentType) => {
  const request = await setHeader(axios);
  return request.post(`${ADMINISTRATION_API}/investment-types`, {
    investmentType,
  });
};

export const updateInvestmentType = async (
  investmentType,
  investmentTypeId
) => {
  const request = await setHeader(axios);
  return request.put(
    `${ADMINISTRATION_API}/investment-types/${investmentTypeId}`,
    { investmentType }
  );
};
