import React from 'react';
import {
  AlignCenter,
  FlexColumn,
  Label,
  BlueprintInputWrapper,
  ErrorMessageDiv,
} from '../../styled-components';
import colors from '../../../utils/colors';
import { Formik, Form } from 'formik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import {
  dateFormatter,
  stringToDate,
  dateToString,
} from '../../../utils/functions';
import { DateInput } from '@blueprintjs/datetime';
import { Position } from '@blueprintjs/core';
import * as Yup from 'yup';
import iconValue from '../../../utils/icons';
import { Icon } from '@blueprintjs/core';
import FormActions from '../FormActions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import { find } from 'lodash';

const transactionSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  transactionDescription: Yup.string().required('Required!'),
  keyword: Yup.string().required('Required!'),
  transactionDate: Yup.date().required('Required!').nullable(),
});

const EditTransactionForm = ({
  transaction,
  handleClose,
  handleEditTransaction,
  keywords,
  keyword,
}) => {
  const { transactionId, transactionDescription, amount } = transaction;
  let { transactionDate } = transaction;

  transactionDate =
    transactionDate instanceof Date
      ? transactionDate
      : stringToDate(transactionDate);

  return (
    <>
      <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
        <Formik
          initialValues={{
            transactionId,
            transactionDate,
            transactionDescription,
            amount,
            keyword,
          }}
          validationSchema={transactionSchema}
          onSubmit={(transaction, actions) => {
            //transform date object to string and send back to api
            transaction.transactionDate = dateToString(
              transaction.transactionDate
            );
            handleEditTransaction(transaction, actions);
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <FlexColumn>
                  <Label>Transaction Date</Label>
                  <BlueprintInputWrapper>
                    <DateInput
                      {...dateFormatter()}
                      popoverProps={{ position: Position.BOTTOM }}
                      onChange={(value) => {
                        setFieldValue('transactionDate', value);
                      }}
                      value={values.transactionDate}
                      name='transactionDate'
                      type='date'
                    />
                    {errors.transactionDate ? (
                      <AlignCenter justifyContent='space-between'>
                        <ErrorMessageDiv fontSize='1rem'>
                          {errors.transactionDate}
                        </ErrorMessageDiv>
                        <Icon
                          icon={iconValue.warning}
                          iconSize={16}
                          color={colors.red}
                        />
                      </AlignCenter>
                    ) : null}
                  </BlueprintInputWrapper>
                </FlexColumn>
                <ModalTextField
                  type='text'
                  name='transactionDescription'
                  placeholder='Transaction Description'
                  label='Transaction Description'
                  value={values.transactionDescription || ''}
                  autoComplete='off'
                />
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  name='keyword'
                  value={
                    values.keyword !== ''
                      ? find(keywords, ['value', values.keyword]).label
                      : ''
                  }
                  items={keywords}
                  labelName='Transaction Keyword'
                />
                <ModalTextField
                  type='text'
                  name='amount'
                  placeholder='Amount'
                  label='Amount'
                  value={values.amount}
                  autoComplete='off'
                />
              </AlignCenter>
              <FormActions isSubmitting={isSubmitting} onClose={handleClose} />
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default EditTransactionForm;
