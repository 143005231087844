import * as api from '../api/administration';

export const fetchAllKeywords = () => ({
  type: 'FETCH_ALL_KEYWORDS',
  payload: api.fetchAllKeywords(),
});

export const fetchSingleKeyword = (keywordId) => ({
  type: 'FETCH_SINGLE_KEYWORD',
  payload: api.fetchSingleKeyword(keywordId),
});

export const fetchUploadDate = () => ({
  type: 'FETCH_UPLOAD_DATE',
  payload: api.fetchUploadDate(),
});

export const fetchMaturityLimit = () => ({
  type: 'FETCH_MATURITY_LIMIT',
  payload: api.fetchMaturityLimit(),
});

export const fetchKeywordCategories = () => ({
  type: 'FETCH_ALL_KEYWORD_CATEGORIES',
  payload: api.fetchKeywordCategories(),
});

export const fetchAllNotes = () => ({
  type: 'FETCH_ALL_NOTES',
  payload: api.fetchAllNotes(),
});

export const fetchSingleKeywordCategory = (keywordCategoryId) => ({
  type: 'FETCH_SINGLE_KEYWORD_CATEGORY',
  payload: api.fetchSingleKeywordCategory(keywordCategoryId),
});

export const fetchQuoteTypesList = () => ({
  type: 'FETCH_QUOTE_TYPES_LIST',
  payload: api.fetchQuoteTypesList(),
});

export const fetchSingleNoteCategory = (noteCategoryId) => ({
  type: 'FETCH_SINGLE_NOTE_CATEGORY',
  payload: api.fetchSingleNoteCategory(noteCategoryId),
});

export const fetchAllQuoteTypes = () => ({
  type: 'FETCH_QUOTE_TYPES',
  payload: api.fetchAllQuoteTypes(),
});

export const fetchSingleQuoteType = (quoteTypeId) => ({
  type: 'FETCH_SINGLE_QUOTE_TYPE',
  payload: api.fetchSingleQuoteType(quoteTypeId),
});

export const fetchInvestmentTypes = () => ({
  type: 'FETCH_INVESTMENT_TYPES',
  payload: api.fetchInvestmentTypes(),
});

export const fetchSingleInvestmentType = (investmentTypeId) => ({
  type: 'FETCH_SINGLE_INVESTMENT_TYPE',
  payload: api.fetchSingleInvestmentType(investmentTypeId),
});
