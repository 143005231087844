import React from 'react';
import {
  MessageText,
  AlignCenter,
  WhiteButton,
  RegularButton,
} from '../../styled-components';
import colors from '../../../utils/colors';
import { Dialog } from '@blueprintjs/core';
import LoadSpinner from '../../LoadSpinner';

const WarningTemplateDialog = ({
  isOpen,
  handleWarningTemplateClose,
  handleDeleteTemplate,
  isDeleting,
  showCancel,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleWarningTemplateClose}
      title='Warning'
      isCloseButtonShown={false}
    >
      <MessageText style={{ padding: '30px 50px 20px' }}>
        Are you sure you want to delete this template?
      </MessageText>
      <AlignCenter
        justifyContent='flex-end'
        padding='10px 20px 10px 0'
        borderTop={`1px solid ${colors.lightText}`}
        margin='50px 0 0 0'
      >
        {!showCancel ? (
          <WhiteButton
            type='button'
            onClick={handleWarningTemplateClose}
            color={colors.red}
            hoverColor={colors.redHover}
          >
            RETURN TO EDIT
          </WhiteButton>
        ) : (
          <WhiteButton
            type='button'
            onClick={handleWarningTemplateClose}
            color={colors.red}
            hoverColor={colors.redHover}
          >
            CANCEL
          </WhiteButton>
        )}

        <RegularButton
          padding='10px 30px'
          display='flex'
          background={colors.red}
          backgroundHover={colors.redHover}
          onClick={handleDeleteTemplate}
          disabled={isDeleting}
        >
          DELETE
          {isDeleting && (
            <span style={{ marginLeft: '10px' }}>
              <LoadSpinner size={20} />
            </span>
          )}
        </RegularButton>
      </AlignCenter>
    </Dialog>
  );
};

export default WarningTemplateDialog;
