import React from 'react';
import {
  AlignCenter,
  Label,
  ErrorMessageDiv,
  FlexColumn,
  InputWrapper,
  Input,
} from '../styled-components';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon } from '@blueprintjs/core';
import { useField } from 'formik';

// needed to make this component to use on my account page for inputs
// Firefox renders inputs differently and needs the width prop set to 100%

const MyAccountTextField = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FlexColumn>
        <Label>{props.label}</Label>
        <InputWrapper width='100%'>
          <Input {...field} {...props} />
        </InputWrapper>
        <div>
          {meta.touched && meta.error ? (
            <AlignCenter justifyContent='space-between'>
              <ErrorMessageDiv fontSize='1rem'>{meta.error}</ErrorMessageDiv>
              <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
            </AlignCenter>
          ) : null}
        </div>
      </FlexColumn>
    </>
  );
};

export default MyAccountTextField;
