import React, { useMemo, useState } from 'react';
import { ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import {
  amountFormatter,
  localDateFormatter,
  checkPermissions,
} from '../../utils/functions';
import { Popover, Position, Icon, MenuItem, Menu } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import permissions from '../../utils/permissions';
import NegotiatedRateDialog from '../dialog/dashboard/NegotiatedRateDialog';
import NegotiatedRatesDeleteDialogBox from '../dialog/NegotiatedRatesDeleteDialogBox';
import { TableContainer } from './components/table-styled-components';

const NegotiatedRatesTable = ({
  negotiatedRates,
  appUser,
  isFetching,
  singleClient,
  hasClient,
  fetchNegotiatedRates,
  fetchClientNegotiatedRates,
  ...props
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [negotiatedRateId, setNegotiatedRateId] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedNegotiatedRate, setSelectedNegotiatedRate] = useState(
    undefined
  );

  const handleAddRateDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleEditDialogOpen = (negotiatedRate) => {
    setSelectedNegotiatedRate(negotiatedRate);
    setIsDialogOpen(true);
  };

  const handleRateDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedNegotiatedRate(undefined);
  };

  const closeDeleteDialog = () => {
    setNegotiatedRateId([]);
    setIsDeleteOpen(false);
  };

  const openDeleteDialog = (selectedRow) => {
    setNegotiatedRateId(selectedRow.negotiatedRateId);
    setIsDeleteOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Client' />
        ),
        accessor: 'clientName',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        width: '3%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank Name' />
        ),
        accessor: 'bankName',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        width: '3%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='State' />
        ),
        accessor: 'state',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        width: '2%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Amount' />
        ),
        accessor: 'amount',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        filter: 'numberRange',
        isVisible: true,
        width: '2%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Term' />
        ),
        accessor: 'term',
        Cell: ({ cell: { value } }) => <>{value} days</>,
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
        width: '2%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Rate' />
        ),
        sortType: 'basic',
        accessor: 'rate',
        Cell: ({ cell: { value } }) => <>{value}%</>,
        filter: 'numberRange',
        isVisible: true,
        width: '2%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Quote Type' />
        ),
        sortType: 'caseInsensitiveSort',
        accessor: 'quoteType',
        filter: 'multipleValues',
        isVisible: true,
        width: '5%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Realized?' />
        ),
        accessor: 'realized',
        Cell: ({ cell: { value } }) => <>{value === 1 ? 'YES' : 'No'}</>,
        sortType: 'basic',
        filter: 'eitherOr',
        isVisible: true,
        width: '2%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Who Captured the Rate?' />
        ),
        accessor: 'userName',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
        width: '5%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date Captured' />
        ),
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => localDateFormatter(value),
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
        width: '4%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Comments' />
        ),
        accessor: 'comments',
        Cell: ({ cell: { value } }) => (
          <span>
            {value && value.length > 100
              ? value.substring(0, 100) + '...'
              : value
              ? value
              : '---'}
          </span>
        ),
        sortType: 'caseInsensitiveSort',
        isVisible: true,
        width: '15%',
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '1%',
        Cell: ({ row }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.EDIT_NEGOTIATED_RATES,
                  ]) && (
                    <MenuItem
                      text='Edit'
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                      onClick={() => handleEditDialogOpen(row.original)}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.DELETE_NEGOTIATED_RATES,
                  ]) && (
                    <>
                      <Menu.Divider />
                      <MenuItem
                        text='Delete'
                        onClick={() => openDeleteDialog(row.original)}
                        style={{
                          color: `${colors.red}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                          paddingBottom: ' 5px',
                          marginBottom: '10px',
                        }}
                      />
                    </>
                  )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.EDIT_NEGOTIATED_RATES,
          permissions.DELETE_NEGOTIATED_RATES,
        ]),
      },
    ],
    [appUser]
  );

  return (
    <TableContainer padding='0px' width='100%'>
      <MakeTable
        appUser={appUser}
        columns={columns}
        data={negotiatedRates}
        isFetching={isFetching}
        tableTitle='Negotiated Rates'
        handleAddDialogOpen={handleAddRateDialogOpen}
        {...props}
      />
      <NegotiatedRateDialog
        appUser={appUser}
        isOpen={isDialogOpen}
        title={
          selectedNegotiatedRate
            ? 'Edit Negotiated Rate'
            : 'Add Negotiated Rate'
        }
        negotiatedRate={selectedNegotiatedRate}
        handleClose={handleRateDialogClose}
        handleDeleteDialogOpen={openDeleteDialog}
        singleClient={singleClient}
        hasClient={hasClient}
      />
      <NegotiatedRatesDeleteDialogBox
        isOpen={isDeleteOpen}
        handleClose={closeDeleteDialog}
        title='Delete Negotiated Rate'
        negotiatedRateId={negotiatedRateId}
        fetchNegotiatedRates={fetchNegotiatedRates}
        singleClient={singleClient}
        hasClient={hasClient}
        fetchClientNegotiatedRates={fetchClientNegotiatedRates}
      />
    </TableContainer>
  );
};

export default NegotiatedRatesTable;
