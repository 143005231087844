import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { MessageText, RedText } from '../../styled-components';
import DialogDeleteActions from '../DialogDeleteActions';
import { deleteAccounts } from '../../../api/accounts';
import AppToast from '../../Toast';

const StagingAccountDeleteDialogBox = ({
  isOpen,
  clientId,
  handleClose,
  reviewAccounts,
  fetchReviewAccounts,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    let accountId = reviewAccounts.map((account) => account.accountId);
    let account = { accountId };
    deleteAccounts(account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete account(s)',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchReviewAccounts(clientId);
        setIsDeleting(false);
        handleClose();
      });
  };
  return (
    <Dialog
      isOpen={isOpen}
      title={`Delete Staging Accounts (${reviewAccounts.length})`}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete these accounts?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Dialog>
  );
};

export default StagingAccountDeleteDialogBox;
