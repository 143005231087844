import React from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import AccountGroupFrom from '../../forms/account-profile-forms/AccountGroupForm';
import AppToast from '../../Toast';
import { createAccountGroup, updateAccountGroup } from '../../../api/accounts';
import { connect } from 'react-redux';
import {
  fetchClientAccountGroups,
  fetchAccountGroups,
} from '../../../actions/accounts';

const AccountGroupDialogBoxBase = ({
  isOpen,
  title,
  handleClose,
  clientAccountGroups,
  groupAssociations,
  setGroupAssociations,
  clientId,
  fetchClientAccountGroups,
  isAccountProfile,
  isClientProfile,
  handleEditGroupAssociation,
  fetchAccountGroups,
  accountGroup,
}) => {
  const handleCreateGroup = (values) => {
    const account = {
      clientId,
      ...values,
    };
    createAccountGroup(account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        isAccountProfile && fetchClientAccountGroups(clientId);
        isClientProfile && fetchAccountGroups(clientId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create group.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleClose());
  };

  const handleEditGroup = (values) => {
    const account = {
      ...values,
    };
    updateAccountGroup(account, accountGroup.groupID)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        isClientProfile && fetchAccountGroups(clientId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to update group.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleClose());
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={title}
        onClose={handleClose}
        isCloseButtonShown={false}
        className='small-dialog'
      >
        <AccountGroupFrom
          isOpen={isOpen}
          title={title}
          closeDialog={handleClose}
          clientAccountGroups={clientAccountGroups}
          groupAssociations={groupAssociations}
          setGroupAssociations={setGroupAssociations}
          handleCreateGroup={handleCreateGroup}
          handleEditGroupAssociation={handleEditGroupAssociation}
          handleEditGroup={handleEditGroup}
          accountGroup={accountGroup}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({});

const AccountGroupDialogBox = connect(mapStateToProps, {
  fetchClientAccountGroups,
  fetchAccountGroups,
})(AccountGroupDialogBoxBase);

export default AccountGroupDialogBox;
