import React from 'react';
import { Dialog } from '@blueprintjs/core';
import BulkEditTransactionForm from '../../forms/transaction-forms/BulkEditTransactionForm';

const TransactionBulkEditDialogBox = ({
  isOpen,
  title,
  keywords,
  handleClose,
  handleBulkEditTransaction,
}) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={title}
        isCloseButtonShown={false}
      >
        <BulkEditTransactionForm
          keywords={keywords}
          handleBulkEditTransaction={handleBulkEditTransaction}
          handleClose={handleClose}
        />
      </Dialog>
    </>
  );
};

export default TransactionBulkEditDialogBox;
