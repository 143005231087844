import React from 'react';
import { SubNavWrapper, NavItem } from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

// the function being passed will send value back to state of client profile page
// also set the is active state to the item in the sub nav that is clicked
const ClientSecondarySubNavigation = ({
  handleComponentChange,
  currentComponent,
  appUser,
}) => {
  return (
    <>
      <SubNavWrapper>
        {checkPermissions(appUser.permList, [
          permissions.VIEW_BANK_CLIENT_RELATIONSHIPS,
        ]) && (
          <NavItem
            onClick={() => handleComponentChange(0)}
            className={currentComponent === 0 && 'active'}
          >
            Banks
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_NEGOTIATED_RATES,
        ]) && (
          <NavItem
            onClick={() => handleComponentChange(1)}
            className={currentComponent === 1 && 'active'}
          >
            Negotiated Rates
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [permissions.VIEW_NOTES]) && (
          <NavItem
            onClick={() => handleComponentChange(2)}
            className={currentComponent === 2 && 'active'}
          >
            Notes
          </NavItem>
        )}
      </SubNavWrapper>
    </>
  );
};

export default ClientSecondarySubNavigation;
