import React, { useEffect, useState } from 'react';
import colors from '../../utils/colors';
import {
  BorderComponentContainer,
  BoldText,
  TransparentButton,
} from '../../components/styled-components';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import MonteResultWidget from '../../components/widgets/monte-carlo-widgets/MonteResultWidget';
import TotalBalanceTableWidget from '../../components/widgets/monte-carlo-widgets/TotalBalanceTableWidget';
import TotalBalanceGraphWidget from '../../components/widgets/monte-carlo-widgets/TotalBalanceGraphWidget';
import LiquidityLevelDialog from '../../components/dialog/monte-carlo/LiquidityLevelDialog';
import {
  fetchMonteResults,
  fetchTableCushionBalances,
  fetchCurrentLiquidityLevels,
  fetchDurationGraphData,
} from '../../api/analysis';
import LoadSpinner from '../../components/LoadSpinner';

const MonteCarloResults = ({ selectedRow, handleDeleteContainer }) => {
  const [showBox, setShowBox] = useState(false);
  const [monteResults, setMonteResults] = useState([]);
  const [isFetchingMonteResults, setIsFetchingMonteResults] = useState(false);
  const [tableCushionBalances, setTableCushionBalances] = useState([]);
  const [
    isFetchingTableCushionBalances,
    setIsFetchingTableCushionBalances,
  ] = useState(false);
  const [isLiquidityLevelDialogOpen, setIsLiquidityLevelDialogOpen] = useState(
    false
  );
  const [liquidityLevels, setLiquidityLevels] = useState({});
  const [isFetchingLevels, setIsFetchingLevels] = useState(undefined);
  // isChanged is for if the user edits the liquidity levels from the dialog
  const [isChanged, setIsChanged] = useState(true);
  const [monteGraphData, setMonteGraphData] = useState([]);
  const [isMonteGraphDataFetching, setIsMonteGraphDataFetching] = useState(
    false
  );

  useEffect(() => {
    if (showBox) {
      setIsFetchingMonteResults(true);
      fetchMonteResults(selectedRow.monteCarloId)
        .then((res) => setMonteResults(res.data))
        .catch(() => setMonteResults([]))
        .finally(() => {
          setIsFetchingMonteResults(false);
        });
    }
  }, [showBox, selectedRow]);

  useEffect(() => {
    if (showBox && isChanged) {
      setIsFetchingTableCushionBalances(true);
      setIsMonteGraphDataFetching(true);
      fetchTableCushionBalances(selectedRow.monteCarloId)
        .then((res) => {
          setTableCushionBalances(res.data);
        })
        .catch(() => {
          setTableCushionBalances([]);
        })
        .finally(() => {
          setIsFetchingTableCushionBalances(false);
          setIsChanged(false);
        });
      fetchDurationGraphData(selectedRow.monteCarloId)
        .then((res) => {
          setMonteGraphData(res.data);
        })
        .catch(() => setMonteGraphData([]))
        .finally(() => {
          setIsMonteGraphDataFetching(false);
          setIsChanged(false);
        });
    }
  }, [showBox, isChanged, selectedRow]);

  const handleShowBox = () => {
    setShowBox(!showBox);
  };

  const handleOpenLiquidityDialog = () => {
    setIsFetchingLevels(true);
    fetchCurrentLiquidityLevels(selectedRow.monteCarloId)
      .then((res) => {
        setLiquidityLevels(res.data[0]);
      })
      .catch(() => setLiquidityLevels({}))
      .finally(() => {
        setIsFetchingLevels(false);
        setIsLiquidityLevelDialogOpen(true);
      });
  };

  return (
    <BorderComponentContainer
      borderRadius='5px 5px 0 0'
      borderBottom='0'
      padding='1rem 0'
      marginBottom={'2rem'}
      marginTop='25px'
      boxShadow={`0px 3px 6px ${colors.lightText}`}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: showBox ? '1rem' : '0',
          borderBottom: showBox ? `1px solid ${colors.lightText}` : '',
        }}
      >
        <Icon
          icon={showBox ? iconValue.caretUp : iconValue.caretDown}
          iconSize={20}
          color={colors.primary}
          style={{ cursor: 'pointer', marginLeft: '1rem' }}
          onClick={handleShowBox}
        />
        <BoldText margin={'0 0 0 1rem'}>
          {selectedRow.monteCarloName + ' - Results'}
        </BoldText>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <TransparentButton
            margin='0 10px 0 0'
            onClick={() => handleDeleteContainer(selectedRow)}
          >
            Hide Analysis
          </TransparentButton>
          <TransparentButton
            margin='0 10px 0 0'
            display='flex'
            disabled={!showBox || isFetchingLevels}
            onClick={handleOpenLiquidityDialog}
          >
            Set Liquidity Levels
            {isFetchingLevels && (
              <span style={{ marginLeft: '10px' }}>
                <LoadSpinner size={20} />
              </span>
            )}
          </TransparentButton>
        </div>
      </div>
      {showBox && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '1rem 1rem 0',
          }}
        >
          <MonteResultWidget
            selectedRow={selectedRow}
            monteResults={monteResults}
            isFetching={isFetchingMonteResults}
          />
          <TotalBalanceGraphWidget
            monteGraphData={monteGraphData}
            isMonteGraphDataFetching={isMonteGraphDataFetching}
          />
          <TotalBalanceTableWidget
            selectedRow={selectedRow}
            isFetching={isFetchingTableCushionBalances}
            balances={tableCushionBalances}
          />
        </div>
      )}
      <LiquidityLevelDialog
        title={`Set Liquidity Levels - ${selectedRow.monteCarloName}`}
        isOpen={isLiquidityLevelDialogOpen}
        selectedRow={selectedRow}
        setIsLiquidityLevelDialogOpen={setIsLiquidityLevelDialogOpen}
        liquidityLevels={liquidityLevels}
        setIsChanged={setIsChanged}
      />
    </BorderComponentContainer>
  );
};

export default MonteCarloResults;
