import React, { useEffect, useState } from 'react';
import { Elevation } from '@blueprintjs/core';
import { connect } from 'react-redux';
import {
  Label,
  WidgetHeader,
  WidgetBody,
  WidgetRow,
  TransparentButton,
  BoldText,
  MessageText,
  CardDiv,
} from '../styled-components';
import { fetchClientReportStatus } from '../../actions/clients';
import colors from '../../utils/colors';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';
import LoadSpinner from '../LoadSpinner';
import StartReportDialog from '../dialog/client-profile-dialog/StartReportDialog';

const ClientsReportStatusWidgetBase = ({
  clientReportStatus,
  handleStartReport,
  singleClient,
  isFetchingClientReportStatus,
  handleGenerateReportDialogOpen,
  ...props
}) => {
  const { fetchClientReportStatus } = props;
  const [isStartReportDialogOpen, setIsStartReportDialogOpen] = useState(false);

  useEffect(() => {
    if (singleClient && singleClient.clientId) {
      fetchClientReportStatus(singleClient.clientId);
    }
  }, [fetchClientReportStatus, singleClient]);

  const handleOpenStartReportDialog = () => {
    setIsStartReportDialogOpen(true);
  };

  const handleCloseStartReportDialog = () => {
    setIsStartReportDialogOpen(false);
  };

  const isStartReportButtonDisabled =
    (clientReportStatus && !clientReportStatus.length) ||
    (clientReportStatus &&
      clientReportStatus[0].reportStatus === 'Reported');

  const isGenerateReportButtonDisabled =
    clientReportStatus &&
    clientReportStatus[0] &&
    clientReportStatus[0].reportStatus !== 'Reported' &&
    clientReportStatus[0].reportStatus !== 'Reporting';

  return (
    <>
      <CardDiv
        interactive={false}
        elevation={Elevation.TWO}
        className={'widget maturing-widget'}
        style={{ flexBasis: '420px', flexGrow: '2' }}
      >
        {isFetchingClientReportStatus && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <LoadSpinner size={100} />
          </div>
        )}
        {!isFetchingClientReportStatus && (
          <>
            <WidgetHeader display={'block'}>
              <Label
                display={'flex'}
                alignItems={'center'}
                paddingBottom={'10px'}
              >
                Report Status:
                <span style={{ fontSize: '1rem', paddingLeft: '5px' }}>
                  {clientReportStatus && clientReportStatus.length
                    ? clientReportStatus[0].reportStatus.toUpperCase()
                    : 'N/A'}
                </span>
              </Label>
              <MessageText margin={'0 0 -10px 0'}>
                Last Report Completed on{' '}
                {clientReportStatus &&
                  clientReportStatus.length &&
                  clientReportStatus[0].lastReportDate
                  ? clientReportStatus[0].lastReportDate
                  : 'N/A'}
              </MessageText>
            </WidgetHeader>
            <div
              style={{
                padding: '15px 20px',
                display: 'flex',
              }}
            >
              <TransparentButton
                margin={'0 15px 0 0'}
                onClick={handleOpenStartReportDialog}
                disabled={isStartReportButtonDisabled}
              >
                Start Report
              </TransparentButton>
              <TransparentButton
                margin={'0 15px 0 0'}
                onClick={handleGenerateReportDialogOpen}
                disabled={isGenerateReportButtonDisabled}
              >
                Generate Cashvest Report
              </TransparentButton>
            </div>
            <WidgetBody
              borderTop={` 1px solid ${colors.lightText}`}
              padding={'30px 0 0 0'}
            >
              {clientReportStatus && clientReportStatus.length ? (
                <>
                  <WidgetRow>
                    <div>
                      <BoldText>
                        {clientReportStatus[0].openBatchCount}
                      </BoldText>
                      <MessageText margin={'5px 0'}>Open Batches</MessageText>
                    </div>
                    <div>
                      <BoldText>
                        {clientReportStatus[0].reportStartDate
                          ? clientReportStatus[0].reportStartDate
                          : 'N/A'}
                      </BoldText>
                      <MessageText margin={'5px 0'}>Date Started</MessageText>
                    </div>
                    <div>
                      <BoldText>
                        {clientReportStatus[0].reportDueDate
                          ? clientReportStatus[0].reportDueDate
                          : 'N/A'}
                      </BoldText>
                      <MessageText margin={'5px 0'}>
                        Report Due Date
                      </MessageText>
                    </div>
                  </WidgetRow>
                  <WidgetRow>
                    <div>
                      <BoldText
                        color={
                          clientReportStatus[0].ingestionStatus ===
                            'Not Started'
                            ? colors.red
                            : clientReportStatus[0].ingestionStatus ===
                              'In Progress'
                              ? colors.inActiveColor
                              : colors.green
                        }
                      >
                        {clientReportStatus[0].ingestionStatus}
                      </BoldText>
                      <MessageText margin={'5px 0'}>
                        Ingestion Status
                      </MessageText>
                    </div>
                    <div>
                      <BoldText></BoldText>
                      <MessageText margin={'5px 0'}></MessageText>
                    </div>
                  </WidgetRow>
                </>
              ) : (
                <NonIdealStateComp
                  icon={iconValue.warning}
                  title={'No Results.'}
                  description={'There are no results to be displayed.'}
                />
              )}
            </WidgetBody>
          </>
        )}
      </CardDiv>
      <StartReportDialog
        isOpen={isStartReportDialogOpen}
        handleStartReport={handleStartReport}
        clientReportStatus={clientReportStatus && clientReportStatus[0]}
        handleClose={handleCloseStartReportDialog}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  clientReportStatus: state.clients.clientReportStatus,
  isFetchingClientReportStatus: state.clients.isFetchingClientReportStatus,
});

const ClientReportStatusWidget = connect(mapStateToProps, {
  fetchClientReportStatus,
})(ClientsReportStatusWidgetBase);

export default ClientReportStatusWidget;
