import React from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  WidgetHeader,
  Label,
  MessageText,
  WidgetBody,
  CardDiv,
} from '../../styled-components';
import LoadSpinner from '../../LoadSpinner';
import NonIdealStateComp from '../../Non-Ideal-State';
import iconValue from '../../../utils/icons';
import colors from '../../../utils/colors';
import {
  Table,
  TableData,
  TableHeader,
  TableRow,
} from '../../table/components/table-styled-components';
import moment from 'moment';
import shortid from 'shortid';
import { amountFormatter } from '../../../utils/functions';

const TotalBalanceTableWidget = ({ balances, isFetching }) => {
  const tableHeaders = [
    '',
    'Amount',
    'Duration',
    'Indicative Rate',
    'Benchmark Interest',
  ];
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ flexBasis: '420px', flexGrow: 2, height: '100%' }}
    >
      <WidgetHeader display='block'>
        <Label>
          Duration Capability and Cushion vs. Total Balance as of{' '}
          {moment().format('MM/DD/YYYY')}
        </Label>
        <MessageText margin='10px 0 0 0'>Table</MessageText>
      </WidgetHeader>
      {isFetching ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      ) : !isFetching && !balances.length ? (
        <NonIdealStateComp
          icon={iconValue.warning}
          title={'No Results.'}
          description={'There are no results to be displayed.'}
        />
      ) : (
        <>
          <WidgetBody backgroundColor={colors.white}>
            <Table>
              <tbody>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableHeader key={shortid.generate()}>{header}</TableHeader>
                  ))}
                </TableRow>
                {balances.map((balance) => {
                  const values = Object.values(balance)[0];
                  return (
                    <TableRow key={shortid.generate()}>
                      <TableHeader>{Object.keys(balance)[0]}</TableHeader>
                      {values.map((value, i) => (
                        <TableData key={shortid.generate()}>
                          {i === 0
                            ? amountFormatter(value)
                            : i === 2
                            ? isNaN(value)
                              ? value
                              : `${value}%`
                            : i === 3
                            ? isNaN(value)
                              ? value
                              : amountFormatter(value)
                            : value}
                        </TableData>
                      ))}
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </WidgetBody>
        </>
      )}
    </CardDiv>
  );
};

export default TotalBalanceTableWidget;
