import React from 'react';
import { Dialog } from '@blueprintjs/core';
import FileUpload from '../../data-ingestion/FileUpload';
import {
  RegularButton,
  WhiteButton,
  BoldText,
  TextAreaWrapper,
  MessageText,
} from '../../styled-components';
import colors from '../../../utils/colors';
import UploadFileTable from '../../table/UploadFileTable';
import LoadSpinner from '../../../components/LoadSpinner';

const ReportUploadDialog = ({
  isOpen,
  closeDialog,
  handleDrop,
  handleUpload,
  uploadList,
  removeFile,
  removeMultipleFiles,
  isUploading,
  accountName,
  bankName,
  clientName,
  source,
  progressPercentage,
  handleSetComment,
  comment,
  ...props
}) => {
  const { appUser } = props;

  const maxCharsAllowed = 200;
  return (
    <>
      <Dialog
        className='large-dialog '
        isOpen={isOpen}
        onClose={closeDialog}
        title={'Upload & Complete Report'}
        isCloseButtonShown={false}
      >
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <FileUpload
            uploadList={uploadList}
            handleUpload={handleUpload}
            handleDrop={handleDrop}
            isReportWidget={true}
          />
          <UploadFileTable
            uploadList={uploadList}
            appUser={appUser}
            removeSingleFile={removeFile}
            removeMultipleFiles={removeMultipleFiles}
            isReportWidget={true}
            {...props}
          />
        </div>
        <BoldText margin='0 0 0 45px'>Comment</BoldText>
        <TextAreaWrapper
          margin='5px 0 0 45px'
          width='100%'
          padding='0 45px 0 0'
        >
          <textarea
            style={{ padding: '5px 0 0 5px' }}
            onChange={(e) => handleSetComment(e.target.value)}
            maxLength='200'
          ></textarea>
        </TextAreaWrapper>
        <MessageText margin='10px 0 0 45px'>{`Characters Left: ${
          maxCharsAllowed - comment.length
        }`}</MessageText>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '30px',
          }}
        >
          <WhiteButton
            margin='10px 40px 10px 0'
            type='button'
            color={colors.red}
            hoverColor={colors.redHover}
            onClick={() => {
              // source.cancel('Operation canceled by the user.');
              closeDialog();
              handleSetComment('');
            }}
          >
            CANCEL UPLOAD
          </WhiteButton>
          <RegularButton
            width='20rem'
            display='flex'
            onClick={handleUpload}
            disabled={isUploading || uploadList.length !== 1}
          >
            UPLOAD AND COMPLETE
            {isUploading && (
              <span style={{ marginLeft: '10px' }}>
                <LoadSpinner size={20} />
              </span>
            )}
          </RegularButton>
        </div>
      </Dialog>
    </>
  );
};

export default ReportUploadDialog;
