import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { WidgetHeader, Label, CardDiv } from '../../styled-components';
import LoadSpinner from '../../../components/LoadSpinner';
import { ResponsivePie } from '@nivo/pie';
import colors, { colorList } from '../../../utils/colors';
import { amountFormatter } from '../../../utils/functions';
import iconValue from '../../../utils/icons';
import NonIdealStateComp from '../../Non-Ideal-State';

const LiquidityLevelWidget = ({
  amountsInvested,
  isAmountsInvestedFetching,
  amountsInvestedAccounts,
  isAmountsInvestedAccountsFetching,
  currentComponent,
  accountComponent,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget-summary'}
    >
      <>
        {isAmountsInvestedFetching ||
          (isAmountsInvestedAccountsFetching && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <LoadSpinner size={100} />
            </div>
          ))}

        {/* if currentComponent exists, show client level chart (investments page) */}
        {/* client level investment liquidity level chart */}
        {currentComponent && (
          <>
            {!isAmountsInvestedFetching &&
              amountsInvested &&
              amountsInvested.length === 0 && (
                <>
                  <WidgetHeader>
                    <Label>Current Amounts Invested by Liquidity Level</Label>
                  </WidgetHeader>
                  <NonIdealStateComp
                    icon={iconValue.warning}
                    title={'No Results.'}
                    description={'There are no results to be displayed.'}
                  />
                </>
              )}
            {!isAmountsInvestedFetching &&
              amountsInvested &&
              amountsInvested.length > 0 && (
                <>
                  <WidgetHeader>
                    <Label>Current Amounts Invested by Liquidity Level</Label>
                  </WidgetHeader>
                  <div
                    style={{
                      height: '19.7rem',
                      display: 'flex',
                      background: `${colors.containerBackground}`,
                    }}
                  >
                    <ResponsivePie
                      data={amountsInvested}
                      margin={{ top: 30, right: 80, bottom: 30, left: -60 }}
                      innerRadius={0.5}
                      colors={colorList}
                      enableRadialLabels={false}
                      enableSlicesLabels={false}
                      legends={[
                        {
                          anchor: 'right',
                          direction: 'column',
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 18,
                          itemsSpacing: 10,
                          symbolSize: 10,
                          symbolShape: 'circle',
                        },
                      ]}
                      theme={{
                        tooltip: {
                          container: {
                            color: colors.boldText,
                            fontWeight: 700,
                          },
                        },
                      }}
                      tooltipFormat={(value) => amountFormatter(value, 2)}
                    />
                  </div>
                </>
              )}
          </>
        )}

        {/* account level investment liquidity level chart */}
        {/* if accountComponent exists, show account level chart */}
        {accountComponent && (
          <>
            {!isAmountsInvestedAccountsFetching &&
              amountsInvestedAccounts &&
              amountsInvestedAccounts.length === 0 && (
                <>
                  <WidgetHeader>
                    <Label>Current Amounts Invested by Liquidity Level</Label>
                  </WidgetHeader>
                  <NonIdealStateComp
                    icon={iconValue.warning}
                    title={'No Results.'}
                    description={'There are no results to be displayed.'}
                  />
                </>
              )}
            {!isAmountsInvestedAccountsFetching &&
              amountsInvestedAccounts &&
              amountsInvestedAccounts.length > 0 && (
                <>
                  <WidgetHeader>
                    <Label>Current Amounts Invested by Liquidity Level</Label>
                  </WidgetHeader>
                  <div
                    style={{
                      height: '19.7rem',
                      display: 'flex',
                      background: `${colors.containerBackground}`,
                    }}
                  >
                    <ResponsivePie
                      data={amountsInvestedAccounts}
                      margin={{ top: 30, right: 80, bottom: 30, left: -60 }}
                      innerRadius={0.5}
                      colors={colorList}
                      enableRadialLabels={false}
                      enableSlicesLabels={false}
                      legends={[
                        {
                          anchor: 'right',
                          direction: 'column',
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 18,
                          itemsSpacing: 10,
                          symbolSize: 10,
                          symbolShape: 'circle',
                        },
                      ]}
                      theme={{
                        tooltip: {
                          container: {
                            color: colors.boldText,
                            fontWeight: 700,
                          },
                        },
                      }}
                      tooltipFormat={(value) => amountFormatter(value, 2)}
                    />
                  </div>
                </>
              )}
          </>
        )}
      </>
    </CardDiv>
  );
};

export default LiquidityLevelWidget;
