import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NotesTable from '../../../../../components/table/NotesTable';
import { fetchClientNotes } from '../../../../../actions/notes';

const NotesBase = ({
  appUser,
  clientNotes,
  fetchClientNotes,
  isFetchingClientNotes,
  singleClient,
  ...props
}) => {
  useEffect(() => {
    singleClient && fetchClientNotes(singleClient.clientId);
  }, [fetchClientNotes, singleClient]);

  return (
    <NotesTable
      clientId={singleClient.clientId}
      appUser={appUser}
      notes={clientNotes}
      isFetching={isFetchingClientNotes}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  clientNotes: state.notes.clientNotes,
  isFetchingClientNotes: state.notes.isFetchingClientNotes,
});

const Notes = connect(mapStateToProps, { fetchClientNotes })(NotesBase);

export default Notes;
