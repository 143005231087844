// styles for Formik Field inputs

import colors from './colors';

export const SelectDefaultInputStyle = {
  border: '1px solid grey',
  margin: '5px 0 1rem 0',
  fontSize: '1.2rem',
  minWidth: '271px',
  padding: '10px 10px 10px 20px',
  outline: 'none',
  borderRadius: '10px',
  width: '100%',
  height: '54px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'transparent',
  boxShadow: 'none',
  color: colors.greyPlaceholderText,
  maxWidth: '271px',
};

export const SelectDisabledInputStyle = {
  border: '1px solid grey',
  margin: '5px 0 1rem 0',
  fontSize: '1.2rem',
  minWidth: '271px',
  padding: '10px 10px 10px 20px',
  outline: 'none',
  borderRadius: '10px',
  width: '100%',
  height: '54px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: colors.greyDisabled,
  boxShadow: 'none',
  color: colors.greyPlaceholderText,
  maxWidth: '271px',
};

export const SelectStepTwoDisabledInputStyle = {
  border: `1px solid ${colors.lightText}`,
  margin: '5px 0 1rem 0',
  fontSize: '1.2rem',
  minWidth: '271px',
  padding: '10px 10px 10px 20px',
  outline: 'none',
  borderRadius: '10px',
  width: '100%',
  height: '54px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: colors.white,
  boxShadow: 'none',
  color: colors.lightText,
  maxWidth: '271px',
};

export const SelectInputStyle = {
  border: '1px solid grey',
  margin: '5px 0 1rem 0',
  fontSize: '1.2rem',
  minWidth: '271px',
  padding: '10px 10px 10px 20px',
  outline: 'none',
  borderRadius: '10px',
  width: '100%',
  height: '54px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'transparent',
  boxShadow: 'none',
  color: '#000',
  maxWidth: '271px',
};

export const SelectAccountOverflowStyle = {
  border: '1px solid grey',
  margin: '5px 0 1rem 0',
  fontSize: '1rem',
  minWidth: '271px',
  padding: '10px 10px 10px 20px',
  outline: 'none',
  borderRadius: '10px',
  width: '100%',
  height: '54px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'transparent',
  boxShadow: 'none',
  color: '#000',
  maxWidth: '271px',
};
