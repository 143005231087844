import React from 'react';
import { useField } from 'formik';
import {
  ErrorMessageDiv,
  AlignCenter,
  LoginWrapperStyle,
  Input,
} from '../styled-components';
import { Icon } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';

const IconTextField = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <LoginWrapperStyle>
        <AlignCenter>
          {field.name === 'username' || field.name === 'email' ? (
            <Icon
              icon={iconValue.person}
              iconSize={20}
              color={colors.lightText}
            />
          ) : (
            <Icon
              icon={iconValue.lock}
              iconSize={20}
              color={colors.lightText}
            />
          )}
          <Input {...field} {...props} />
        </AlignCenter>
      </LoginWrapperStyle>
      <div>
        {meta.touched && meta.error ? (
          <AlignCenter justifyContent='space-between'>
            <ErrorMessageDiv marginBottom='12px'>{meta.error}</ErrorMessageDiv>
            <Icon icon={iconValue.warning} iconSize={20} color={colors.red} />
          </AlignCenter>
        ) : null}
      </div>
    </>
  );
};

export default IconTextField;
