import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { RedText, MessageText } from '../../styled-components';
import AppToast from '../../Toast';
import { deleteInvestments } from '../../../api/investments';
import {
  fetchGroupInvestments,
  fetchStagingInvestments,
} from '../../../actions/investments';
import { connect } from 'react-redux';
import DialogDeleteActions from '../DialogDeleteActions';

const InvestmentDeleteDialogBoxBase = ({
  isOpen,
  title,
  singleClient,
  accountId,
  groupId,
  investment,
  handleClose,
  currentInvestment,
  selectedInvestments,
  fetchGroupInvestments,
  setResetInitialFilter,
  isStagingInvestment,
  updatePaginate,
  pageSize,
  pageIndex,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    let ids;
    let investmentGroupXrefs;
    let stagingInvestmentId;
    if (groupId) {
      if (selectedInvestments.length > 0) {
        investmentGroupXrefs = selectedInvestments.map(
          (investment) => investment.investmentGroupXref
        );
      } else {
        investmentGroupXrefs = [investment.investmentGroupXref];
      }
      investment = {
        investmentGroupXrefs,
      };
    } else if (isStagingInvestment) {
      if (selectedInvestments.length > 0) {
        stagingInvestmentId = selectedInvestments.map(
          (investment) => investment.id
        );
      } else {
        stagingInvestmentId = [investment.id];
      }
      investment = {
        stagingInvestmentId,
      };
    } else {
      if (selectedInvestments.length > 0) {
        ids = selectedInvestments.map((investment) => investment.id);
      } else {
        ids = [investment.id];
      }
      investment = {
        ids,
      };
    }
    deleteInvestments(investment, groupId, isStagingInvestment)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete investments.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        if (singleClient) updatePaginate({ pageSize, pageIndex });
        else if (accountId) updatePaginate({ pageSize, pageIndex });
        else if (currentInvestment) updatePaginate({ pageSize, pageIndex });
        else if (groupId) fetchGroupInvestments(groupId);
        else if (isStagingInvestment) updatePaginate({ pageSize, pageIndex });
        setResetInitialFilter(true);
        setIsDeleting(false);
        handleClose();
      });
  };
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete these investments?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

const mapStateToProps = () => ({});

const InvestmentDeleteDialogBox = connect(mapStateToProps, {
  fetchGroupInvestments,
  fetchStagingInvestments,
})(InvestmentDeleteDialogBoxBase);

export default InvestmentDeleteDialogBox;
