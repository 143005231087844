import React, { useMemo, useState } from 'react';
import { Icon, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import colors from '../../utils/colors';
import { amountFormatter, checkPermissions, rateFormatter } from '../../utils/functions';
import iconValue from '../../utils/icons';
import { ActionIconButton, StyleColumnCell } from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const StagingInvestmentTable = ({
  stagingInvestments,
  appUser,
  isFetching,
  handleOpenEditDialog,
  handleDeleteDialogOpen,
  handleValidateInvestment,
  handleInvalidateInvestment,
  updatePaginate,
  resultCount,
  pageCount,
  isServerSidePagination,
  ...props
}) => {
  const [isShow, setIsShow] = useState(false);

  const hiddenColumns =
    'Columns: Fund, Settlement Date, Call Date, Settlement Amount, Premium / Discount, Coupon Rate';

  const handleChangeShow = () => {
    setIsShow(!isShow);
  };

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Face Value'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'faceValue',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Maturity Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'maturityDate',
        width: '8%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Investment Type'
            sizeFont={'0.85rem'}
          />
        ),
        accessor: 'investmentTypeName',
        Cell: ({ cell: { value } }) => (value ? value : '-'),
        width: '8%',
        sortType: 'caseInsensitiveSort',
        filter: 'text',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Fund' sizeFont={'0.85rem'} />
        ),
        accessor: 'fund',
        width: '8%',
        sortType: 'caseInsensitiveSort',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Interest Rate'
            sizeFont={'0.85rem'}
          />
        ),
        accessor: 'interestRate',
        Cell: ({ cell: { value } }) => (rateFormatter(value, 4)),
        width: '5%',
        sortType: 'caseInsensitiveSort',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Liquidity Level'
            sizeFont={'0.75rem'}
            toolTipText={'This field is determined by Analytics.'}
          />
        ),
        accessor: 'liquidityLevel',
        width: '5%',
        sortType: 'basic',
        filter: 'text',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Days Until Maturity'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'daysUntilMaturity',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <>
            <SortableHeader
              column={column}
              header='ID'
              toolTipText={
                'Market Identifier for the Security (Examples: CUSIP, SEDOL, ISIN, etc.).'
              }
            />
          </>
        ),
        accessor: 'investmentId',
        width: '5%',
        sortType: 'basic',
        isVisible: true,
      },

      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Coupon Rate'
            sizeFont={'0.85rem'}
          />
        ),
        Cell: ({ cell: { value } }) => (rateFormatter(value, 4)),
        accessor: 'couponRate',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },

      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Call Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'callDate',
        width: '8%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Settlement Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'settlementDate',
        width: '5%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Settlement Amount'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'settlementAmount',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Interest at Maturity'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'interestAtMaturity',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Premium or Discount'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'premiumDiscount',
        width: '8%',
        sortType: 'basic',
        filter: 'text',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Validated?' />
        ),
        accessor: 'validationStatus',
        width: '5%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'No' ? colors.activeColor : colors.inActiveColor}
            >
              {value && value.toUpperCase()}
            </StyleColumnCell>
          );
        },
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.EDIT_STAGING_INVESTMENTS,
                  ]) && (
                    <MenuItem
                      text='Edit Investment'
                      onClick={() => handleOpenEditDialog(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.VALIDATE_STAGING_INVESTMENTS,
                    permissions.INVALIDATE_STAGING_INVESTMENTS,
                  ]) && (
                    <MenuItem
                      text={
                        row.original.validationStatus === 'No'
                          ? 'Validate Investment'
                          : 'Invalidate Investment'
                      }
                      onClick={() =>
                        row.original.validationStatus === 'No'
                          ? handleValidateInvestment(row.original)
                          : handleInvalidateInvestment(row.original)
                      }
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.DELETE_STAGING_INVESTMENTS,
                  ]) && (
                    <>
                      <Menu.Divider />
                      <MenuItem
                        text={'Delete Investment'}
                        onClick={() => {
                          handleDeleteDialogOpen(row.original);
                        }}
                        style={{
                          color: `${colors.red}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                          paddingBottom: ' 5px',
                          marginBottom: '10px',
                        }}
                      />
                    </>
                  )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_STAGING_INVESTMENTS,
          permissions.EDIT_STAGING_INVESTMENTS,
          permissions.DELETE_STAGING_INVESTMENTS,
        ]),
      },
    ],
    [
      appUser,
      isShow,
      handleOpenEditDialog,
      handleDeleteDialogOpen,
      handleValidateInvestment,
      handleInvalidateInvestment,
    ]
  );
  return (
    <>
      <TableContainer width='100%' padding='0rem 2rem 1rem'>
        <MakeTable
          appUser={appUser}
          tableTitle='Staging Investments'
          data={stagingInvestments}
          isFetching={isFetching}
          isStagingInvestment={true}
          columns={columns}
          isShow={isShow}
          hiddenColumns={hiddenColumns}
          customPageOptions={[50, 100, 200, 300, 500]}
          handleChangeShow={handleChangeShow}
          handleValidateInvestment={handleValidateInvestment}
          handleInvalidateInvestment={handleInvalidateInvestment}
          handleDeleteDialogOpen={handleDeleteDialogOpen}
          updatePaginate={updatePaginate}
          resultCount={resultCount}
          pageCount={pageCount}
          isServerSidePagination={isServerSidePagination}
          {...props}
        />
      </TableContainer>
    </>
  );
};

export default StagingInvestmentTable;
