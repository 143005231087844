import React, { useState, useEffect } from 'react';
import { Dialog, RadioGroup, Radio } from '@blueprintjs/core';
import NumberRangeField from '../../select-fields/NumberRangeField';
import RadioPagination from '../../RadioPagination';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import {
  dateRangeChecker,
  dateToFilterText,
  numberRangeToFilterText,
  textToFilterText,
  multiSelectToFilterText,
} from '../../../utils/functions';
import { stateList } from '../../../utils/constantsList';
import TableFilterActions from './TableFilterActions';

const NegotiatedRateFilterDialogBox = ({
  isOpen,
  title,
  handleClose,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
}) => {
  const [amountLow, setAmountLow] = useState('');
  const [amountHigh, setAmountHigh] = useState('');
  const [termLow, setTermLow] = useState('');
  const [termHigh, setTermHigh] = useState('');
  const [rateLow, setRateLow] = useState('');
  const [rateHigh, setRateHigh] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [realized, setRealized] = useState('');
  const [clientNames, setClientNames] = useState([]);
  const [clientNameItems, setClientNameItems] = useState([]);
  const [bankNames, setBankNames] = useState([]);
  const [bankNamesItems, setBankNamesItems] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [userNameItems, setUserNameItems] = useState([]);
  const [quoteTypes, setQuoteTypes] = useState([]);
  const [quoteTypeItems, setQuoteTypeItems] = useState([]);
  const [states, setStates] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    if (initialData.length) {
      setClientNameItems(
        initialData
          .map((item) => item.clientName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setBankNamesItems(
        initialData
          .map((item) => item.bankName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setUserNameItems(
        initialData
          .map((item) => item.userName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setQuoteTypeItems(
        initialData
          .map((item) => item.quoteType)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData]);

  const getFilterSummary = () => {
    const filters = [];
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Created At'));
    (amountLow || amountHigh) &&
      filters.push(numberRangeToFilterText(amountLow, amountHigh, 'Amount'));
    (termHigh || termHigh) &&
      filters.push(numberRangeToFilterText(termLow, termHigh, 'Term'));
    (rateLow || rateHigh) &&
      filters.push(numberRangeToFilterText(rateLow, rateHigh, 'Rate'));
    realized && filters.push(textToFilterText(realized, 'Realized'));
    clientNames.length &&
      filters.push(multiSelectToFilterText(clientNames, 'Clients'));
    bankNames.length &&
      filters.push(multiSelectToFilterText(bankNames, 'Bank Names'));
    userNames.length &&
      filters.push(multiSelectToFilterText(userNames, 'Who Captured the Rate'));
    quoteTypes.length &&
      filters.push(multiSelectToFilterText(quoteTypes, 'Quote Types'));
    states.length && filters.push(multiSelectToFilterText(states, 'States'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('createdAt', dateRangeChecker(dateRange) ? dateRange : undefined);
    setFilter(
      'amount',
      !amountLow && !amountHigh
        ? undefined
        : [amountLow || undefined, amountHigh || undefined]
    );
    setFilter(
      'rate',
      !rateLow && !rateHigh
        ? undefined
        : [rateLow || undefined, rateHigh || undefined]
    );
    setFilter(
      'term',
      !termLow && !termHigh
        ? undefined
        : [termLow || undefined, termHigh || undefined]
    );
    setFilter('realized', realized || undefined);
    setFilter('clientName', clientNames.length ? clientNames : undefined);
    setFilter('bankName', bankNames.length ? bankNames : undefined);
    setFilter('userName', userNames.length ? userNames : undefined);
    setFilter('quoteType', quoteTypes.length ? quoteTypes : undefined);
    setFilter('state', states.length ? states : undefined);

    handleClose();
  };

  const handleClearFilters = () => {
    setAllFilters([]);
    setFilterSummary([]);
    setAmountLow('');
    setAmountHigh('');
    setTermHigh('');
    setTermLow('');
    setRateLow('');
    setRateHigh('');
    setRealized('');
    setClientNameItems([]);
    setClientNames([]);
    setBankNames([]);
    setBankNamesItems([]);
    setUserNames([]);
    setUserNameItems([]);
    setQuoteTypeItems([]);
    setQuoteTypes([]);
    setStates([]);
    setPageNumber(0);
    handleClose();
  };

  const handleRealizedChange = (event) => {
    setRealized(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div
        style={{
          padding: '2rem 3rem 0',
          marginBottom: '10px',
        }}
      >
        {pageNumber === 0 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <DateSelectPlainField
                labelName={'Created Date Range'}
                dateRange={dateRange}
                handleDateChange={setDateRange}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <NumberRangeField
                labelName='Amount'
                selectLow={setAmountLow}
                selectHigh={setAmountHigh}
                low={amountLow}
                high={amountHigh}
                isCurrency={true}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <NumberRangeField
                labelName='Term'
                selectLow={setTermLow}
                selectHigh={setTermHigh}
                low={termLow}
                high={termHigh}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <NumberRangeField
                labelName='Rate'
                selectLow={setRateLow}
                selectHigh={setRateHigh}
                low={rateLow}
                high={rateHigh}
                isPercentage={true}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <div style={{ width: '271px' }} className='radio-group'>
                <RadioGroup
                  label={'Realized?'}
                  onChange={handleRealizedChange}
                  selectedValue={realized}
                  inline={true}
                >
                  <Radio
                    label='Yes'
                    value='Yes'
                    style={{ marginRight: '20px' }}
                  />
                  <Radio label='No' value='No' />
                </RadioGroup>
              </div>
            </div>
          </>
        )}
        {pageNumber === 1 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setQuoteTypes}
                items={quoteTypeItems}
                selectedItems={quoteTypes}
                label='Quote Types'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setClientNames}
                items={clientNameItems}
                selectedItems={clientNames}
                label='Clients'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setBankNames}
                items={bankNamesItems}
                selectedItems={bankNames}
                label='Bank Names'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setUserNames}
                items={userNameItems}
                selectedItems={userNames}
                label='Who Captured the Rate?'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setStates}
                items={stateList}
                selectedItems={states}
                label='State'
              />
            </div>
          </>
        )}
      </div>
      <RadioPagination
        pages={2}
        onClickSetPage={setPageNumber}
        page={pageNumber}
      />
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default NegotiatedRateFilterDialogBox;
