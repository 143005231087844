import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchAccountGroups,
  fetchAccountsByGroup,
  fetchCompleteAccounts,
} from '../../../../../actions/accounts';
import AppToast from '../../../../../components/Toast';
import { Icon, Intent, Tooltip } from '@blueprintjs/core';
import AccountTable from '../../../../../components/table/AccountTable';
import ClientHeader from '../../../../../components/headers/ClientHeader';
import { fetchAllClients } from '../../../../../actions/clients';
import {
  Label,
  AlignCenter,
  WhiteButton,
  ComponentBody,
  RegularButton,
} from '../../../../../components/styled-components';
import colors from '../../../../../utils/colors';
import AddAccountsDialog from '../../../../../components/dialog/account-profile-dialog/AddAccountsDialog';
import LoadSpinner from '../../../../../components/LoadSpinner';
import AccountGroupDialogBox from '../../../../../components/dialog/account-profile-dialog/AccountGroupDialogBox';
import iconValue from '../../../../../utils/icons';

const AccountGroupProfileBase = ({
  appUser,
  fetchAccountGroups,
  fetchAllClients,
  accountGroups,
  isFetching,
  clients,
  fetchAccountsByGroup,
  accountsByGroup,
  isFetchingAccountsByGroup,
  completeAccounts,
  isCompleteAccountsFetching,
  fetchCompleteAccounts,
  ...props
}) => {
  const groupId = props.match.params.groupId;
  const clientId = props.match.params.clientId;
  const [isOpen, setIsOpen] = useState(false);
  const [accountGroup, setAccountGroup] = useState({});

  const [isAccountGroupDialogOpen, setIsAccountGroupDialogOpen] = useState(
    false
  );

  useEffect(() => {
    fetchAccountGroups(clientId);
    fetchAllClients();
    fetchAccountsByGroup(groupId);
    fetchCompleteAccounts(clientId);
  }, [
    clientId,
    fetchAccountGroups,
    fetchAllClients,
    fetchAccountsByGroup,
    groupId,
    fetchCompleteAccounts,
  ]);

  useEffect(() => {
    if (accountGroups.length) {
      setAccountGroup(
        accountGroups.find(
          (group) => group.groupID.toString() === groupId.toString()
        )
      );
    }
  }, [accountGroups, groupId]);

  // get client name
  const client = clients.find((client) => {
    return client.clientId.toString() === clientId.toString();
  });

  useEffect(() => {
    if (!isFetching && accountGroups.length && accountGroup === undefined) {
      props.history.push({
        pathname: `/client-management/${clientId}`,
        state: 2,
      });
      AppToast.show({
        message: 'Account group does not exist.',
        intent: Intent.DANGER,
      });
    }
  });

  const handleBackToClientList = () => {
    props.history.push('/client-management');
  };

  const handleOpenAddDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleEditAccountGroupDialogClose = () => {
    setIsAccountGroupDialogOpen(false);
  };

  const handleEditAccountGroupDialogOpen = () => {
    setIsAccountGroupDialogOpen(true);
  };

  return (
    <>
      {isFetching ? (
        <div>
          <LoadSpinner size={200} />
        </div>
      ) : (
        <>
          <ClientHeader
            appUser={appUser}
            header={'Client'}
            clientName={client ? client.clientName : null}
            hasButton={true}
            action={handleBackToClientList}
            buttonText={'Back to Clients Table'}
            hasClientSubNav={true}
            isAccountGroupProfile={true}
            {...props}
          />
          <ComponentBody padding='14rem 2rem 0'>
            <AlignCenter justifyContent='space-between'>
              <Label width='20rem' style={{ fontSize: '1.3rem' }}>
                {accountGroup && accountGroup.groupName} Group
              </Label>
              <AlignCenter justifyContent='flex-end'>
                <WhiteButton
                  width='12rem'
                  border={`1px solid ${colors.primary}`}
                  onClick={() => handleEditAccountGroupDialogOpen()}
                  disabled={
                    accountGroup && accountGroup.groupName === 'All Accounts'
                  }
                  display='flex'
                >
                  Edit Group
                  {accountGroup.groupName === 'All Accounts' && (
                    <Tooltip
                      content={
                        'You can\'t edit the name for "All Accounts" group.'
                      }
                      style={{ width: '25rem' }}
                    >
                      <Icon
                        icon={iconValue.info}
                        iconSize={16}
                        color={colors.primary}
                        style={{ marginLeft: '5px', display: 'flex' }}
                      />
                    </Tooltip>
                  )}
                </WhiteButton>
                <RegularButton onClick={handleOpenAddDialog} width='12rem'>
                  Add Accounts
                </RegularButton>
              </AlignCenter>
            </AlignCenter>
            <AccountTable
              isOpen={isOpen}
              appUser={appUser}
              accounts={accountsByGroup}
              title={'Current Accounts'}
              clientId={clientId}
              isFetching={isFetchingAccountsByGroup}
              handleCloseDialog={handleCloseDialog}
              groupId={groupId}
              {...props}
            />
            <AccountGroupDialogBox
              isOpen={isAccountGroupDialogOpen}
              title='Edit Group'
              clientId={clientId}
              accountGroup={accountGroup}
              handleClose={handleEditAccountGroupDialogClose}
              isClientProfile={true}
            />
            <AddAccountsDialog
              isOpen={isOpen}
              handleClose={handleCloseDialog}
              completeAccounts={completeAccounts}
              groupAccounts={accountsByGroup}
              clientId={clientId}
              groupId={groupId}
              fetchAccountsByGroup={fetchAccountsByGroup}
            />
          </ComponentBody>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  accountGroups: state.accounts.accountGroups,
  isFetching: state.accounts.isFetchingAccountGroups,
  clients: state.clients.allClients,
  isFetchingClients: state.clients.isAllClientsFetching,
  accountsByGroup: state.accounts.accountsByGroup,
  isFetchingAccountsByGroup: state.accounts.isFetchingAccountsByGroup,
  completeAccounts: state.accounts.completeAccounts,
  isCompleteAccountsFetching: state.accounts.isCompleteAccountsFetching,
});

const AccountGroupProfile = connect(mapStateToProps, {
  fetchAccountGroups,
  fetchAllClients,
  fetchAccountsByGroup,
  fetchCompleteAccounts,
})(AccountGroupProfileBase);

export default AccountGroupProfile;
