import React from 'react';
import { Header, Text, Data, Number } from './styled-components';

const EffectiveFederalFunds = ({ quandlData }) => {
  return (
    <div style={{ flexBasis: '200px', flexGrow: 1, marginRight: '10px' }}>
      <div style={{ borderBottom: '1px solid white', marginBottom: '5px' }}>
        <Header>Effective Federal Funds</Header>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Data>
          {quandlData && quandlData.length !== 0 && (
            <Number>{quandlData[0].effectiveFund[0]}</Number>
          )}
          <Text>30-Day</Text>
        </Data>
      </div>
    </div>
  );
};

export default EffectiveFederalFunds;
