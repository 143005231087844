import { combineReducers } from 'redux';

const openReports = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_OPEN_REPORTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_OPEN_REPORTS_REJECTED':
    case 'FETCH_OPEN_REPORTS_PENDING':
      return [];
    default:
      return state;
  }
};

const isFetchingOpenReports = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_OPEN_REPORTS_PENDING':
      return true;
    case 'FETCH_OPEN_REPORTS_REJECTED':
    case 'FETCH_OPEN_REPORTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const monteCarloHistory = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_MONTE_CARLO_HISTORY_FULFILLED':
      return action.payload.data;
    case 'FETCH_MONTE_CARLO_HISTORY_REJECTED':
    case 'FETCH_MONTE_CARLO_HISTORY_PENDING':
      return [];
    case 'FETCH_SINGLE_MONTE_CARLO_HISTORY_FULFILLED':
      return state.map((user) => {
        if (user.userId === action.payload.data[0].userId) {
          return action.payload.data[0];
        }
        return user;
      });
    case 'FETCH_SINGLE_MONTE_CARLO_HISTORY_REJECTED':
    case 'FETCH_SINGLE_MONTE_CARLO_HISTORY_PENDING':
      return state;
    default:
      return state;
  }
};

const isFetchingMonteCarloHistory = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_MONTE_CARLO_HISTORY_PENDING':
      return true;
    case 'FETCH_MONTE_CARLO_HISTORY_REJECTED':
    case 'FETCH_MONTE_CARLO_HISTORY_FULFILLED':
      return false;
    default:
      return state;
  }
};

const stepOneAnalysis = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STEP_ONE_SHORT_TERM_ANALYSIS_FULFILLED':
      return action.payload.data;
    case 'FETCH_STEP_ONE_SHORT_TERM_ANALYSIS_PENDING':
    case 'FETCH_STEP_ONE_SHORT_TERM_ANALYSIS_REJECTED':
      return [];
    default:
      return state;
  }
};

const isStepOneAnalysisFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_STEP_ONE_SHORT_TERM_ANALYSIS_PENDING':
      return true;
    case 'FETCH_STEP_ONE_SHORT_TERM_ANALYSIS_REJECTED':
    case 'FETCH_STEP_ONE_SHORT_TERM_ANALYSIS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const stepTwoAnalysis = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STEP_TWO_SHORT_TERM_ANALYSIS_FULFILLED':
      return action.payload.data;
    case 'FETCH_STEP_TWO_SHORT_TERM_ANALYSIS_PENDING':
    case 'FETCH_STEP_TWO_SHORT_TERM_ANALYSIS_REJECTED':
      return [];
    default:
      return state;
  }
};

const isStepTwoAnalysisFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_STEP_TWO_SHORT_TERM_ANALYSIS_PENDING':
      return true;
    case 'FETCH_STEP_TWO_SHORT_TERM_ANALYSIS_FULFILLED':
    case 'FETCH_STEP_TWO_SHORT_TERM_ANALYSIS_REJECTED':
      return false;
    default:
      return state;
  }
};

const cashVestScores = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CASH_VEST_SCORES_FULFILLED':
      return action.payload.data;
    case 'FETCH_CASH_VEST_SCORES_PENDING':
    case 'FETCH_CASH_VEST_SCORES_REJECTED':
      return [];
    default:
      return state;
  }
};

const isCashVestScoresFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CASH_VEST_SCORES_PENDING':
      return true;
    case 'FETCH_CASH_VEST_SCORES_REJECTED':
    case 'FETCH_CASH_VEST_SCORES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const shortTermModelList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_SHORT_TERM_MODEL_LIST_FULFILLED':
      return action.payload.data;
    case 'FETCH_SHORT_TERM_MODEL_LIST_REJECTED':
    case 'FETCH_SHORT_TERM_MODEL_LIST_PENDING':
      return [];
    default:
      return state;
  }
};

const isFetchingShortTermModelList = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_SHORT_TERM_MODEL_LIST_PENDING':
      return true;
    case 'FETCH_SHORT_TERM_MODEL_LIST_REJECTED':
    case 'FETCH_SHORT_TERM_MODEL_LIST_FULFILLED':
      return false;
    default:
      return state;
  }
};

const cashVestSubScores = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CASH_VEST_SUB_SCORES_FULFILLED':
      return action.payload.data[0];
    case 'FETCH_CASH_VEST_SUB_SCORES_PENDING':
    case 'FETCH_CASH_VEST_SUB_SCORES_REJECTED':
      return {};
    default:
      return state;
  }
};

const isCashVestSubScoresFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CASH_VEST_SUB_SCORES_PENDING':
      return true;
    case 'FETCH_CASH_VEST_SUB_SCORES_FULFILLED':
    case 'FETCH_CASH_VEST_SUB_SCORES_REJECTED':
      return false;
    default:
      return state;
  }
};

const analysis = combineReducers({
  monteCarloHistory,
  isFetchingMonteCarloHistory,
  openReports,
  isFetchingOpenReports,
  stepOneAnalysis,
  stepTwoAnalysis,
  cashVestScores,
  isStepOneAnalysisFetching,
  isStepTwoAnalysisFetching,
  isCashVestScoresFetching,
  shortTermModelList,
  isFetchingShortTermModelList,
  cashVestSubScores,
  isCashVestSubScoresFetching,
});

export default analysis;
