import React, { useState, useEffect } from 'react';
import { Dialog, RadioGroup, Radio } from '@blueprintjs/core';
import TableFilterActions from './TableFilterActions';
import {
  multiSelectToFilterText,
  numberRangeToFilterText,
  dateRangeChecker,
  dateToFilterText,
} from '../../../utils/functions';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import NumberRangeField from '../../select-fields/NumberRangeField';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import RadioPagination from '../../RadioPagination';

const AccountFilterDialogBox = ({
  isOpen,
  title,
  setFilter,
  initialData,
  handleClose,
  setAllFilters,
  setFilterSummary,
}) => {
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [banks, setBanks] = useState([]);
  const [lastTransactionDateRange, setLastTransactionDateRange] = useState([]);
  const [closedDateRange, setClosedDateRange] = useState([]);
  const [balanceDateRange, setBalanceDateRange] = useState([]);
  const [isClose, setIsClosed] = useState('None');
  const [bankList, setBankList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [balanceLow, setBalanceLow] = useState('');
  const [balanceHigh, setBalanceHigh] = useState('');

  const [pageNumber, setPageNumber] = useState(0);

  const handleIsCloseChange = (event) => {
    setIsClosed(event.target.value);
  };

  useEffect(() => {
    if (initialData.length) {
      setTypeList(
        initialData
          .map((item) => item.accountType)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setCategoryList(
        initialData
          .map((item) => item.accountCategory)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setBankList(
        initialData
          .map((item) => item.bankName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData]);

  const getFilterSummary = () => {
    const filters = [];
    types.length && filters.push(multiSelectToFilterText(types, 'Types'));
    categories.length &&
      filters.push(multiSelectToFilterText(categories, 'Category Types'));
    banks.length && filters.push(multiSelectToFilterText(banks, 'Banks'));
    (balanceHigh || balanceLow) &&
      filters.push(numberRangeToFilterText(balanceLow, balanceHigh, 'Balance'));
    dateRangeChecker(lastTransactionDateRange) &&
      filters.push(
        dateToFilterText(lastTransactionDateRange, 'Last Transaction Date')
      );
    dateRangeChecker(balanceDateRange) &&
      filters.push(dateToFilterText(balanceDateRange, 'Balance Date'));
    isClose !== 'None' &&
      filters.push({ value: isClose, name: 'Account Closed' });
    dateRangeChecker(closedDateRange) &&
      filters.push(dateToFilterText(closedDateRange, 'Closed Date'));
    return filters;
  };
  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('accountType', types.length ? types : undefined);
    setFilter('accountCategory', categories.length ? categories : undefined);
    setFilter('bankName', banks.length ? banks : undefined);
    setFilter(
      'accountBalance',
      !balanceLow && !balanceHigh
        ? undefined
        : [balanceLow || undefined, balanceHigh || undefined]
    );
    setFilter(
      'lastTransactionDate',
      dateRangeChecker(lastTransactionDateRange)
        ? lastTransactionDateRange
        : undefined
    );
    setFilter(
      'accountBalanceDate',
      dateRangeChecker(balanceDateRange) ? balanceDateRange : undefined
    );
    setFilter('accountClosed', isClose !== 'None' ? isClose : undefined);
    setFilter(
      'accountDateClosed',
      dateRangeChecker(closedDateRange) ? closedDateRange : undefined
    );
    handleClose();
  };

  const handleClearFilters = () => {
    setFilterSummary([]);
    setAllFilters([]);
    setTypes([]);
    setCategories([]);
    setBanks([]);
    setLastTransactionDateRange([]);
    setClosedDateRange([]);
    setBalanceDateRange([]);
    setIsClosed('None');
    setBankList([]);
    setCategoryList([]);
    setTypeList([]);
    setBalanceHigh('');
    setBalanceLow('');
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0', marginBottom: '10px' }}>
        {pageNumber === 0 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <NumberRangeField
                labelName='Balance'
                selectLow={setBalanceLow}
                selectHigh={setBalanceHigh}
                high={balanceHigh}
                low={balanceLow}
                isCurrency={true}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <DateSelectPlainField
                labelName='Last Transaction Date'
                dateRange={lastTransactionDateRange}
                handleDateChange={setLastTransactionDateRange}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <DateSelectPlainField
                labelName='Balance Date'
                dateRange={balanceDateRange}
                handleDateChange={setBalanceDateRange}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <div style={{ width: '271px' }} className='radio-group'>
                <RadioGroup
                  label={'Realized?'}
                  onChange={handleIsCloseChange}
                  selectedValue={isClose}
                  inline={true}
                >
                  <Radio
                    label='Yes'
                    value='Yes'
                    style={{ marginRight: '20px' }}
                  />
                  <Radio label='No' value='No' />
                  <Radio label='None' value='None' />
                </RadioGroup>
              </div>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <DateSelectPlainField
                labelName='Closed Date'
                dateRange={closedDateRange}
                handleDateChange={setClosedDateRange}
                isDateDisabled={isClose !== 'Yes'}
              />
            </div>
          </>
        )}
        {pageNumber === 1 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setTypes}
                items={typeList}
                selectedItems={types}
                label='Types'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setCategories}
                items={categoryList}
                selectedItems={categories}
                label='Type Category'
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <MultiSelectField
                setSelectedItems={setBanks}
                items={bankList}
                selectedItems={banks}
                label='Banks'
              />
            </div>
          </>
        )}
      </div>
      <RadioPagination
        pages={2}
        onClickSetPage={setPageNumber}
        page={pageNumber}
      />
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default AccountFilterDialogBox;
