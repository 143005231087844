import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const hasNoMFA = () => {
  return checkMFA === 'NOMFA';
};

const checkMFA = async () => {
  const mfa = await Auth.currentAuthenticatedUser().then(
    (res) => res.preferredMFA
  );
  return mfa;
};

function PublicRouteBase({ component: Component, ...props }) {
  const { appUser } = props;
  let authenticated = appUser ? true : false;

  if (appUser && hasNoMFA()) authenticated = false;

  return (
    <Route
      {...props}
      render={(rest) => {
        const allProps = { ...props, ...rest };
        return authenticated ? (
          <Redirect
            to={{ pathname: '/dashboard', state: { from: props.location } }}
          />
        ) : (
          <Component {...allProps} />
        );
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  appUser: state.auth.appUser,
});

const PublicRoute = connect(mapStateToProps)(PublicRouteBase);

export default PublicRoute;
