import React from 'react';
import { useField } from 'formik';
import {
  FlexColumn,
  Label,
  ErrorMessageDiv,
  AlignCenter,
  TextAreaWrapper,
} from '../styled-components';
import { TextArea, Tooltip, Icon } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';

const ModalTextArea = ({
  toolTipText,
  width,
  height,
  isDisabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  width = width ? width : '100%';
  return (
    <>
      <FlexColumn style={{ width: width }}>
        <Label>
          {props.label}
          {toolTipText && (
            <div
              style={{
                display: 'inline-block',
                padding: '0px 0px 5px 5px',
                verticalAlign: 'middle',
              }}
            >
              <Tooltip content={toolTipText}>
                <Icon
                  icon={iconValue.info}
                  iconSize={16}
                  color={colors.primary}
                />
              </Tooltip>
            </div>
          )}
        </Label>
        <TextAreaWrapper height={height}>
          <TextArea
            growVertically={true}
            large={true}
            {...field}
            {...props}
            disabled={isDisabled}
          />
        </TextAreaWrapper>
        <div>
          {meta.touched && meta.error ? (
            <AlignCenter justifyContent='space-between'>
              <ErrorMessageDiv fontSize='1rem'>{meta.error}</ErrorMessageDiv>
              <Icon icon={iconValue.warning} iconSize={16} color={colors.red} />
            </AlignCenter>
          ) : null}
        </div>
      </FlexColumn>
    </>
  );
};

export default ModalTextArea;
