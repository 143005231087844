import { combineReducers } from 'redux';

const allInvestments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_INVESTMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_INVESTMENTS_PENDING':
    case 'FETCH_ALL_INVESTMENTS_REJECTED':
      return [];
    case 'FETCH_SINGLE_INVESTMENT_FULFILLED':
      return state.map((investment) => {
        if (investment.id === action.payload.data[0].id) {
          return action.payload.data[0];
        }
        return investment;
      });
    case 'FETCH_SINGLE_INVESTMENT_REJECTED':
    case 'FETCH_SINGLE_INVESTMENT_PENDING':
      return state;
    default:
      return state;
  }
};

const isAllInvestmentsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_INVESTMENTS_PENDING':
      return true;
    case 'FETCH_ALL_INVESTMENTS_REJECTED':
    case 'FETCH_ALL_INVESTMENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const allAccountInvestments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_ACCOUNT_INVESTMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_ACCOUNT_INVESTMENTS_PENDING':
    case 'FETCH_ALL_ACCOUNT_INVESTMENTS_REJECTED':
      return [];
    case 'FETCH_SINGLE_INVESTMENT_FULFILLED':
      return state.map((investment) => {
        if (investment.id === action.payload.data[0].id) {
          return action.payload.data[0];
        }
        return investment;
      });
    case 'FETCH_SINGLE_INVESTMENT_REJECTED':
    case 'FETCH_SINGLE_INVESTMENT_PENDING':
      return state;
    default:
      return state;
  }
};

const clientInvestments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_INVESTMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_INVESTMENTS_PENDING':
    case 'FETCH_ALL_INVESTMENTS_REJECTED':
      return [];
    case 'FETCH_SINGLE_INVESTMENT_FULFILLED':
      return state.map((investment) => {
        if (investment.id === action.payload.data[0].id) {
          return action.payload.data[0];
        }
        return investment;
      });
    case 'FETCH_SINGLE_INVESTMENT_REJECTED':
    case 'FETCH_SINGLE_INVESTMENT_PENDING':
      return state;
    default:
      return state;
  }
};

const isAllAccountInvestmentsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_ACCOUNT_INVESTMENTS_PENDING':
      return true;
    case 'FETCH_ALL_ACCOUNT_INVESTMENTS_REJECTED':
    case 'FETCH_ALL_ACCOUNT_INVESTMENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const isClientInvestmentsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_INVESTMENTS_PENDING':
      return true;
    case 'FETCH_CLIENT_INVESTMENTS_FULFILLED':
    case 'FETCH_CLIENT_INVESTMENTS_REJECTED':
      return false;
    default:
      return state;
  }
};

const investmentGroups = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_GROUPS_FULFILLED':
      return action.payload.data;
    case 'FETCH_INVESTMENT_GROUPS_PENDING':
    case 'FETCH_INVESTMENT_GROUPS_REJECTED':
      return [];
    case 'FETCH_SINGLE_INVESTMENT_GROUP_FULFILLED':
      return state.map((investmentGroup) => {
        if (investmentGroup.groupId === action.payload.data[0].groupId) {
          return action.payload.data[0];
        }
        return investmentGroup;
      });
    case 'FETCH_SINGLE_INVESTMENT_GROUP_REJECTED':
    case 'FETCH_SINGLE_INVESTMENT_GROUP_PENDING':
      return state;
    default:
      return state;
  }
};

const isInvestmentGroupsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_GROUPS_PENDING':
      return true;
    case 'FETCH_INVESTMENT_GROUPS_FULFILLED':
    case 'FETCH_INVESTMENT_GROUPS_REJECTED':
      return false;
    default:
      return state;
  }
};

const groupInvestments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_GROUP_INVESTMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_GROUP_INVESTMENTS_PENDING':
    case 'FETCH_GROUP_INVESTMENTS_REJECTED':
      return [];
    case 'FETCH_SINGLE_INVESTMENT_FULFILLED':
      return state.map((investment) => {
        if (investment.id === action.payload.data[0].id) {
          return action.payload.data[0];
        }
        return investment;
      });
    case 'FETCH_SINGLE_INVESTMENT_REJECTED':
    case 'FETCH_SINGLE_INVESTMENT_PENDING':
      return state;
    default:
      return state;
  }
};

const isGroupInvestmentFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_GROUP_INVESTMENTS_PENDING':
      return true;
    case 'FETCH_GROUP_INVESTMENTS_REJECTED':
    case 'FETCH_GROUP_INVESTMENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const clientUnrealizedInterest = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_UNREALIZED_INTEREST_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_UNREALIZED_INTEREST_PENDING':
    case 'FETCH_CLIENT_UNREALIZED_INTEREST_REJECTED':
      return {};
    default:
      return state;
  }
};

const accountUnrealizedInterest = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_UNREALIZED_INTEREST_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_UNREALIZED_INTEREST_PENDING':
    case 'FETCH_ACCOUNT_UNREALIZED_INTEREST_REJECTED':
      return {};
    default:
      return state;
  }
};

const isUnrealizedInterestFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_UNREALIZED_INTEREST_PENDING':
    case 'FETCH_ACCOUNT_UNREALIZED_INTEREST_PENDING':
      return true;
    case 'FETCH_CLIENT_UNREALIZED_INTEREST_FULFILLED':
    case 'FETCH_ACCOUNT_UNREALIZED_INTEREST_FULFILLED':
    case 'FETCH_CLIENT_UNREALIZED_INTEREST_REJECTED':
    case 'FETCH_ACCOUNT_UNREALIZED_INTEREST_REJECTED':
      return false;
    default:
      return state;
  }
};

const investmentAccountBalance = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_ACCOUNT_BALANCE_FULFILLED':
      return action.payload.data;
    case 'FETCH_INVESTMENT_ACCOUNT_BALANCE_REJECTED':
    case 'FETCH_INVESTMENT_ACCOUNT_BALANCE_PENDING':
      return [];
    default:
      return state;
  }
};

const isInvestmentAccountGraphFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_ACCOUNT_BALANCE_PENDING':
      return true;
    case 'FETCH_INVESTMENT_ACCOUNT_BALANCE_REJECTED':
    case 'FETCH_INVESTMENT_ACCOUNT_BALANCE_FULFILLED':
      return false;
    default:
      return state;
  }
};

const investmentClientBalance = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_CLIENT_BALANCE_FULFILLED':
      return action.payload.data;
    case 'FETCH_INVESTMENT_CLIENT_BALANCE_REJECTED':
    case 'FETCH_INVESTMENT_CLIENT_BALANCE_PENDING':
      return state;
    default:
      return state;
  }
};

const isInvestmentClientGraphFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_CLIENT_BALANCE_PENDING':
      return true;
    case 'FETCH_INVESTMENT_CLIENT_BALANCE_REJECTED':
    case 'FETCH_INVESTMENT_CLIENT_BALANCE_FULFILLED':
      return false;
    default:
      return state;
  }
};

const stagingInvestments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STAGING_INVESTMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_STAGING_INVESTMENTS_PENDING':
    case 'FETCH_STAGING_INVESTMENTS_REJECTED':
      return [];
    case 'FETCH_SINGLE_STAGING_INVESTMENT_FULFILLED':
      return state.map((investment) => {
        if (investment.id === action.payload.data[0].id) {
          return action.payload.data[0];
        }
        return investment;
      });
    case 'FETCH_SINGLE_STAGING_INVESTMENT_REJECTED':
    case 'FETCH_SINGLE_STAGING_INVESTMENT_PENDING':
      return state;
    default:
      return state;
  }
};

const isFetchingStagingInvestments = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_STAGING_INVESTMENTS_PENDING':
      return true;
    case 'FETCH_STAGING_INVESTMENTS_REJECTED':
    case 'FETCH_STAGING_INVESTMENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const amountsInvested = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_AMOUNTS_INVESTED_FULFILLED':
      return action.payload.data;
    case 'FETCH_AMOUNTS_INVESTED_REJECTED':
    case 'FETCH_AMOUNTS_INVESTED_PENDING':
      return state;
    default:
      return state;
  }
};

const isAmountsInvestedFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_AMOUNTS_INVESTED_PENDING':
      return true;
    case 'FETCH_AMOUNTS_INVESTED_REJECTED':
    case 'FETCH_AMOUNTS_INVESTED_FULFILLED':
      return false;
    default:
      return state;
  }
};

const amountsInvestedAccounts = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_AMOUNTS_INVESTED_ACCOUNTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_AMOUNTS_INVESTED_ACCOUNTS_REJECTED':
    case 'FETCH_AMOUNTS_INVESTED_ACCOUNTS_PENDING':
      return state;
    default:
      return state;
  }
};

const isAmountsInvestedFetchingAccounts = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_AMOUNTS_INVESTED_ACCOUNTS_PENDING':
      return true;
    case 'FETCH_AMOUNTS_INVESTED_ACCOUNTS_REJECTED':
    case 'FETCH_AMOUNTS_INVESTED_ACCOUNTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const interestRate = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_INTEREST_RATES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_INTEREST_RATES_PENDING':
    case 'FETCH_ACCOUNT_INTEREST_RATES_REJECTED':
      return [];
    default:
      return state;
  }
};

const isInterestRateFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_INTEREST_RATES_PENDING':
      return true;
    case 'FETCH_ACCOUNT_INTEREST_RATES_FULFILLED':
    case 'FETCH_ACCOUNT_INTEREST_RATES_REJECTED':
      return false;
    default:
      return state;
  }
};

const investments = combineReducers({
  allInvestments,
  interestRate,
  groupInvestments,
  investmentGroups,
  clientInvestments,
  stagingInvestments,
  allAccountInvestments,
  clientUnrealizedInterest,
  accountUnrealizedInterest,
  isAllInvestmentsFetching,
  isGroupInvestmentFetching,
  isInvestmentGroupsFetching,
  isClientInvestmentsFetching,
  isAllAccountInvestmentsFetching,
  isUnrealizedInterestFetching,
  investmentAccountBalance,
  isInvestmentAccountGraphFetching,
  investmentClientBalance,
  isInvestmentClientGraphFetching,
  isFetchingStagingInvestments,
  amountsInvested,
  isAmountsInvestedFetching,
  amountsInvestedAccounts,
  isAmountsInvestedFetchingAccounts,
  isInterestRateFetching,
});

export default investments;
