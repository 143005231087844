import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { AlignCenter, WhiteButton } from '../styled-components';
import StagingTransactionsTable from '../table/StagingTransactionsTable';
import { connect } from 'react-redux';
import {
  fetchTransactionKeywords,
  fetchSingleStagingTransaction,
} from '../../actions/transactions';
import TransactionEditDialogBox from './transactions/TransactionEditDialogBox';
import TransactionBulkEditDialogBox from './transactions/TransactionBulkEditDialogBox';
import { find } from 'lodash';
import {
  updateStagingTransaction,
  bulkUpdateStagingTransactions,
  validateStagingTransaction,
  invalidateStagingTransaction,
  deleteStagingTransaction,
  fetchStagingPaginatedData,
} from '../../api/transactions';
import AppToast from '../Toast';
import TransactionDeleteDialogBox from './transactions/TransactionDeleteDialogBox';
import { fetchSingleBatchAccount } from '../../actions/data-ingestion';

const StagingTransactionDialogBase = ({
  isOpen,
  clientName,
  bankName,
  batchAccount,
  accountType,
  handleCloseValidateTransaction,
  handleBulkInspectDialogOpen,
  isFetching,
  fetchSingleStagingTransaction,
  fetchSingleBatchAccount,
  fetchTransactionKeywords,
  appUser,
  keywords,
  isServerSidePagination,
  ...props
}) => {
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenBulkEditDialog, setIsOpenBulkEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [stagingTransaction, setStagingTransaction] = useState({});
  const [keyword, setKeyword] = useState('');
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  // pagination info
  // pageSize and pageIndex used to refetch data after edit/delete
  const [pageSize] = useState(50);
  const [pageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(undefined);
  const [resultCount, setResultCount] = useState(undefined);
  const [stagingTransactions, setStagingTransactions] = useState([]);
  const [isPaginatedDataFetching, setIsPaginatedDataFetching] = useState(false);

  useEffect(() => {
    fetchTransactionKeywords();
  }, [fetchTransactionKeywords]);

  // for server side pagination. only call this function if needed so use hook useCallback
  const updatePaginate = useCallback(
    (
      { pageSize, pageIndex },
      filters = [],
      searchTerm = '',
      sortOrder = {}
    ) => {
      setIsPaginatedDataFetching(true);
      fetchStagingPaginatedData(
        [batchAccount.batchAccountId],
        pageSize,
        pageIndex,
        filters,
        searchTerm,
        sortOrder
      )
        .then((res) => {
          setStagingTransactions(res.data.results);
          setPageCount(res.data.pageCount);
          setResultCount(res.data.resultCount);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsPaginatedDataFetching(false));
    },
    [batchAccount.batchAccountId]
  );

  const handleOpenEditDialog = (stagingTransaction) => {
    setStagingTransaction(stagingTransaction);
    setKeyword(
      keywords.length
        ? find(keywords, ['label', stagingTransaction.keyword]).value
        : ''
    );
    setIsOpenEditDialog(true);
  };

  const handleBulkEditDialogOpen = (transactions) => {
    setSelectedTransactions(transactions);
    setIsOpenBulkEditDialog(true);
  };

  const handleDeleteDialogOpen = (transaction) => {
    transaction instanceof Array
      ? setSelectedTransactions(transaction)
      : setStagingTransaction(transaction);
    setIsOpenDeleteDialog(true);
  };


  const handleEditDialogClose = () => {
    setStagingTransaction({});
    setIsOpenEditDialog(false);
  };

  const handleBulkEditDialogClose = () => {
    setKeyword('');
    setIsOpenBulkEditDialog(false);
  };

  const handleDeleteDialogClose = () => {
    setStagingTransaction({});
    setSelectedTransactions([]);
    setIsOpenDeleteDialog(false);
  };

  const handleBulkEditTransaction = ({
    amount,
    keyword,
    transactionDescription,
    transactionDate,
  }) => {
    const stagingTransactionIds = selectedTransactions.map(
      (transaction) => transaction.transactionId
    );
    const transaction = {
      amount,
      keyword,
      date: transactionDate === 'Invalid date' ? '' : transactionDate,
      description: transactionDescription,
      stagingTransactionIds,
    };
    bulkUpdateStagingTransactions(transaction)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to edit transaction.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleBulkEditDialogClose();
      });
  };

  const handleEditTransaction = async ({
    transactionId,
    amount,
    transactionDescription,
    transactionDate,
    keyword,
  }) => {
    const transaction = {
      stagingTransactionId: transactionId,
      amount,
      description: transactionDescription,
      keyword,
      date: transactionDate,
    };
    updateStagingTransaction(transaction)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit transaction',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        updatePaginate({ pageSize, pageIndex });
        handleEditDialogClose();
      });
  };

  const handleDelete = (transaction) => {
    setIsDeleting(true);
    let stagingTransactionId;
    if (selectedTransactions.length > 0) {
      stagingTransactionId = selectedTransactions.map(
        (transaction) => transaction.transactionId
      );
    } else {
      stagingTransactionId = [Number(transaction.transactionId)];
    }
    transaction = {
      stagingTransactionId,
    };
    deleteStagingTransaction(transaction)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        updatePaginate({ pageSize, pageIndex });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete transaction',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        handleDeleteDialogClose();
        fetchSingleBatchAccount(batchAccount.batchAccountId);
      });
  };

  const handleValidateTransaction = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    let stagingTransactionIds;
    let batchAccountId = batchAccount.batchAccountId;

    if (isArray)
      stagingTransactionIds = selectedRows
        .filter((row) => row.validationStatus === 'No')
        .map((row) => Number(row.transactionId));

    const transaction = {
      batchAccountId,
      stagingTransactionIds: isArray
        ? stagingTransactionIds
        : [Number(selectedRows.transactionId)],
    };
    validateStagingTransaction(transaction)
      .then((res) => {
        AppToast.show({
          message: 'Successfully validated transactions.',
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to validate transactions.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        updatePaginate({ pageSize, pageIndex });
        fetchSingleBatchAccount(batchAccountId);
      });
  };

  const handleInvalidateTransaction = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    let stagingTransactionIds;
    let batchAccountId = batchAccount.batchAccountId;

    if (isArray)
      stagingTransactionIds = selectedRows
        .filter((row) => row.validationStatus === 'Yes')
        .map((row) => Number(row.transactionId));

    const transaction = {
      batchAccountId,
      stagingTransactionIds: isArray
        ? stagingTransactionIds
        : [Number(selectedRows.transactionId)],
    };
    invalidateStagingTransaction(transaction)
      .then((res) => {
        AppToast.show({
          message: 'Successfully invalidated Transactions',
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Invalidate Transactions',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        updatePaginate({ pageSize, pageIndex });
        fetchSingleBatchAccount(batchAccountId);
      });
  };

  const Title = () => {
    const status = stagingTransactions.filter(
      (transaction) => transaction.validationStatus === 'No'
    ).length
      ? 'Needs Validation'
      : 'Validated';
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {`Validate/Inspect/Edit: ${clientName} / ${bankName} / ${accountType} - ${status}`}
      </div>
    );
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleCloseValidateTransaction}
        title={<Title />}
        className={'custom-dialog-header large-dialog'}
        isCloseButtonShown={false}
      >
        <AlignCenter justifyContent='space-between'>
          <StagingTransactionsTable
            stagingTransactions={stagingTransactions}
            batchAccountId={batchAccount.batchAccountId}
            handleOpenEditDialog={handleOpenEditDialog}
            handleBulkEditDialogOpen={handleBulkEditDialogOpen}
            handleDeleteDialogOpen={handleDeleteDialogOpen}
            handleValidateTransaction={handleValidateTransaction}
            handleInvalidateTransaction={handleInvalidateTransaction}
            handleBulkInspectDialogOpen={handleBulkInspectDialogOpen}
            isFetching={isPaginatedDataFetching}
            appUser={appUser}
            updatePaginate={updatePaginate}
            pageCount={pageCount}
            resultCount={resultCount}
            isServerSidePagination={isServerSidePagination}
            {...props}
          />
        </AlignCenter>

        <AlignCenter justifyContent='flex-end' padding='0px 20px 0px 0px'>
          <WhiteButton type='button' onClick={handleCloseValidateTransaction}>
            CLOSE
          </WhiteButton>
        </AlignCenter>
      </Dialog>
      <TransactionEditDialogBox
        isOpen={isOpenEditDialog}
        title='Edit Transaction Information'
        handleClose={handleEditDialogClose}
        transaction={stagingTransaction}
        keywords={keywords}
        keyword={keyword}
        handleEditTransaction={handleEditTransaction}
      />
      <TransactionBulkEditDialogBox
        title={`Edit Selected Transactions (${selectedTransactions.length})`}
        isOpen={isOpenBulkEditDialog}
        handleClose={handleBulkEditDialogClose}
        keywords={keywords}
        handleBulkEditTransaction={handleBulkEditTransaction}
      />
      <TransactionDeleteDialogBox
        isOpen={isOpenDeleteDialog}
        title={
          // if transaction is not an object use bulk title
          selectedTransactions.length
            ? `Delete Selected Transactions (${selectedTransactions.length})`
            : 'Delete Transaction'
        }
        handleClose={handleDeleteDialogClose}
        handleDelete={handleDelete}
        transaction={stagingTransaction}
        isDeleting={isDeleting}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  keywords: state.transactions.allTransactionKeywords,
  appUser: state.auth.appUser,
});

const StagingTransactionDialog = connect(mapStateToProps, {
  fetchSingleStagingTransaction,
  fetchTransactionKeywords,
  fetchSingleBatchAccount,
})(StagingTransactionDialogBase);

export default StagingTransactionDialog;
