import React, { useState, useEffect, useCallback } from 'react';
import InvestmentHeader from '../../components/headers/InvestmentHeader';
import { ComponentBody } from '../../components/styled-components';
import AllInvestments from '../../containers/Investments/AllInvestments';
import { connect } from 'react-redux';
import {
  fetchSingleInvestment,
  fetchInvestmentGroups,
} from '../../actions/investments';
import { fetchPaginatedData } from '../../api/investments';
import InvestmentGroups from './InvestmentGroups';
import InvestmentGroupDialog from '../../components/dialog/investments/InvestmentGroupDialog';
import { fetchMaturityLimit } from '../../api/administration';

const InvestmentManagementBase = ({
  appUser,
  fetchSingleInvestment,
  fetchInvestmentGroups,
  allInvestmentGroups,
  ...props
}) => {
  const [isAddGroupDialogOpen, setIsAddGroupDialogOpen] = useState(false);
  const [maturityLimit, setMaturityLimit] = useState({});
  // pagination info
  // pageSize and pageIndex used to refetch data after edit/delete
  const [pageSize] = useState(50);
  const [pageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(undefined);
  const [resultCount, setResultCount] = useState(undefined);
  const [investments, setInvestments] = useState([]);
  const [isPaginatedDataFetching, setIsPaginatedDataFetching] = useState(false);
  const [isServerSidePagination] = useState(true);

  useEffect(() => {
    fetchInvestmentGroups();
    fetchMaturityLimit()
      .then((res) => setMaturityLimit(res.data[0]))
      .catch((err) => console.error(err));
  }, [fetchInvestmentGroups]);

  // for server side pagination. only call this function if needed so use hook useCallback
  const updatePaginate = useCallback(
    (
      { pageSize, pageIndex },
      filters = [],
      searchTerm = '',
      sortOrder = {}
    ) => {
      setIsPaginatedDataFetching(true);
      fetchPaginatedData(pageSize, pageIndex, filters, searchTerm, sortOrder)
        .then((res) => {
          setInvestments(res.data.results);
          setPageCount(res.data.pageCount);
          setResultCount(res.data.resultCount);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsPaginatedDataFetching(false));
    },
    []
  );

  const state =
    Number(props.location.state) === 3 ? 0 : Number(props.location.state);
  const [showUsersClients] = useState(Number(props.location.state) === 3);
  const [currentComponent, setCurrentComponent] = useState(state || 0);
  const handleCurrentComponentChange = (value) => {
    setCurrentComponent(value);
  };

  const openAddInvestmentGroupDialog = () => {
    setIsAddGroupDialogOpen(true);
  };
  const closeAddInvestmentGroupDialog = () => {
    setIsAddGroupDialogOpen(false);
  };

  props.location &&
    Number(props.location.state) !== 0 &&
    props.history.replace(props.history.location.state, 0);

  return (
    <>
      <InvestmentHeader
        appUser={appUser}
        header='Investments'
        handleCurrentComponentChange={handleCurrentComponentChange}
        currentComponent={currentComponent}
        hasSubNav={true}
        openAddInvestmentGroupDialog={openAddInvestmentGroupDialog}
        {...props}
      />
      <ComponentBody>
        {currentComponent === 0 && (
          <AllInvestments
            appUser={appUser}
            investments={investments}
            isAllInvestmentsFetching={isPaginatedDataFetching}
            showUsersClients={showUsersClients}
            maturityLimit={maturityLimit}
            updatePaginate={updatePaginate}
            resultCount={resultCount}
            pageCount={pageCount}
            pageSize={pageSize}
            pageIndex={pageIndex}
            isServerSidePagination={isServerSidePagination}
            {...props}
          />
        )}
        {currentComponent === 1 && (
          <InvestmentGroups appUser={appUser} {...props} />
        )}
        <InvestmentGroupDialog
          isOpen={isAddGroupDialogOpen}
          title={'Add Investment Group'}
          handleClose={closeAddInvestmentGroupDialog}
        />
      </ComponentBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  allInvestmentGroups: state.investments.investmentGroups,
});

const InvestmentManagement = connect(mapStateToProps, {
  fetchSingleInvestment,
  fetchInvestmentGroups,
})(InvestmentManagementBase);

export default InvestmentManagement;
