import React, { useState } from 'react';
import {
  ResponsiveCenter,
  GlobalSettingsDescription,
  MessageText,
} from '../styled-components';
import InvestmentTypesTable from '../table/InvestmentTypesTable';
import InvestmentTypesDialog from '../../components/dialog/global-settings-dialog/InvestmentTypesDialog';
import InvestmentTypesDeactivateDialogBox from '../dialog/global-settings-dialog/InvestmentTypesDeactivateDialogBox';

const ManageInvestmentTypes = ({
  appUser,
  investmentTypes,
  isFetching,
  fetchInvestmentTypes,
  ...props
}) => {
  const [investmentType, setInvestmentType] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [investmentTypeIds, setInvestmentTypeIds] = useState([]);

  const openDeactivateDialog = (selectedRows) => {
    if (selectedRows instanceof Array) {
      setInvestmentTypeIds(selectedRows.map((row) => row.investmentTypeId));
    } else {
      setInvestmentTypeIds([selectedRows.investmentTypeId]);
    }
    setIsDeactivate(true);
  };

  const closeDeactivateDialog = () => {
    setInvestmentTypeIds([]);
    setIsDeactivate(false);
  };

  const handleAddDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleEditDialogOpen = (investmentType) => {
    setInvestmentType(investmentType);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setInvestmentType(undefined);
    setIsDialogOpen(false);
  };

  return (
    <>
      <InvestmentTypesDeactivateDialogBox
        isOpen={isDeactivate}
        handleCloseDialog={closeDeactivateDialog}
        title={
          investmentTypeIds.length > 1
            ? `Deactivate selected Investment Types (${investmentTypeIds.length})`
            : 'Deactivate Investment Type'
        }
        investmentTypeIds={investmentTypeIds}
        fetchInvestmentTypes={fetchInvestmentTypes}
      />
      <ResponsiveCenter className='global-setting' minWidth='765px'>
        <GlobalSettingsDescription>
          <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
            Investments - Manage Investment Types
          </MessageText>
          <MessageText>
            Create and manage Investment types for use in the application.
          </MessageText>
        </GlobalSettingsDescription>
        <InvestmentTypesTable
          appUser={appUser}
          investmentTypes={investmentTypes}
          isFetching={isFetching}
          openDeactivateDialog={openDeactivateDialog}
          handleAddDialogOpen={handleAddDialogOpen}
          handleEditDialogOpen={handleEditDialogOpen}
          {...props}
        />
      </ResponsiveCenter>
      <InvestmentTypesDialog
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
        investmentType={investmentType}
        title={
          investmentType ? 'Edit Investment Types' : 'Add Investment Types'
        }
      />
    </>
  );
};

export default ManageInvestmentTypes;
