import axios from 'axios';
import { setHeader } from './index';

const INVESTMENTS_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/investments`;

export const fetchAllInvestments = async () => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/all`);
};

export const fetchPaginatedData = async (
  pageSize,
  pageIndex,
  filters,
  searchTerm,
  sortOrder
) => {
  const request = await setHeader(axios);
  return request.post(`${INVESTMENTS_API}/all/`, {
    pageSize,
    pageIndex,
    filters,
    searchTerm,
    sortOrder,
  });
};

export const fetchAllAccountInvestments = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/account/${accountId}`);
};

export const fetchAccountInvestmentPaginatedData = async (
  accountId,
  pageSize,
  pageIndex,
  filters,
  searchTerm,
  sortOrder
) => {
  const request = await setHeader(axios);
  return request.post(`${INVESTMENTS_API}/account/${accountId}`, {
    pageSize,
    pageIndex,
    filters,
    searchTerm,
    sortOrder,
  });
};

export const fetchClientInvestments = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/client/${clientId}`);
};

export const fetchClientInvestmentPaginatedData = async (
  clientId,
  pageSize,
  pageIndex,
  filters,
  searchTerm,
  sortOrder
) => {
  const request = await setHeader(axios);
  return request.post(`${INVESTMENTS_API}/client/${clientId}`, {
    pageSize,
    pageIndex,
    filters,
    searchTerm,
    sortOrder,
  });
};

export const updateSingleInvestment = async (investmentId, investment) => {
  const request = await setHeader(axios);
  return request.put(`${INVESTMENTS_API}/${investmentId}`, {
    investment,
  });
};

export const getInvestmentTypes = async () => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/types/list`);
};

export const fetchSingleInvestment = async (investmentId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/${investmentId}`);
};

export const fetchAllInvestmentGroups = async () => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/groups/list`);
};

export const fetchSingleInvestmentGroups = async (investmentId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/groups/${investmentId}`);
};

export const deleteInvestments = async (
  investment,
  groupId,
  isStagingTransaction
) => {
  const request = await setHeader(axios);
  if (groupId)
    return request.delete(`${INVESTMENTS_API}/group-association`, {
      data: { investment },
    });
  else if (isStagingTransaction) {
    return request.delete(`${INVESTMENTS_API}/staging`, {
      data: { investment },
    });
  } else
    return request.delete(`${INVESTMENTS_API}/remove`, {
      data: { investment },
    });
};

export const fetchInvestmentGroups = async () => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/groups`);
};

export const createInvestmentGroup = async (groupName) => {
  const request = await setHeader(axios);
  return request.post(`${INVESTMENTS_API}/groups`, {
    investment: { groupName },
  });
};

export const deleteInvestmentGroups = async (investment) => {
  const request = await setHeader(axios);
  return request.delete(`${INVESTMENTS_API}/groups/`, {
    data: { investment },
  });
};

export const fetchGroupInvestments = async (groupId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/group-association/${groupId}`);
};

export const addInvestmentsToGroup = async (groupId, investmentIdList) => {
  const request = await setHeader(axios);
  return request.post(`${INVESTMENTS_API}/group-association/${groupId}`, {
    investment: { investmentIdList },
  });
};

export const updateInvestmentGroup = async (groupName, groupId) => {
  const request = await setHeader(axios);
  return request.put(`${INVESTMENTS_API}/groups/update/${groupId}`, {
    investment: { groupName },
  });
};

export const fetchSingleInvestmentGroup = async (groupId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/groups/single/${groupId}`);
};

export const fetchClientUnrealizedInterest = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(
    `${INVESTMENTS_API}/unrealized-interest/clients/${clientId}`
  );
};

export const fetchAccountUnrealizedInterest = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(
    `${INVESTMENTS_API}/unrealized-interest/accounts/${accountId}`
  );
};

export const fetchAccountInvestmentBalance = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/balance-widget/accounts/${accountId}`);
};

export const fetchClientInvestmentBalance = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/balance-widget/clients/${clientId}`);
};

export const fetchStagingInvestments = async (batchAccountId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/staging/all/${batchAccountId}`);
};

export const fetchStagingPaginatedData = async (
  batchAccountId,
  pageSize,
  pageIndex,
  filters,
  searchTerm,
  sortOrder
) => {
  const request = await setHeader(axios);
  return request.post(`${INVESTMENTS_API}/staging/all/${batchAccountId}`, {
    pageSize,
    pageIndex,
    filters,
    searchTerm,
    sortOrder,
  });
};

export const fetchSingleStagingInvestment = async (stagingInvestmentId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/staging/${stagingInvestmentId}`);
};

export const updateStagingInvestment = async (
  investment,
  stagingInvestmentId
) => {
  const request = await setHeader(axios);
  return request.put(`${INVESTMENTS_API}/staging/${stagingInvestmentId}`, {
    investment,
  });
};

export const validateStagingInvestment = async (investment) => {
  const request = await setHeader(axios);
  return request.put(`${INVESTMENTS_API}/staging/validate`, { investment });
};

export const invalidateStagingInvestment = async (investment) => {
  const request = await setHeader(axios);
  return request.put(`${INVESTMENTS_API}/staging/invalidate`, { investment });
};

// id can either be account or client id
// backend using same endpoint and either uses client or account in endpoint
export const fetchAmountsInvested = async (id, type) => {
  const request = await setHeader(axios);
  return request.get(
    `${INVESTMENTS_API}/liquidity-levels-widget/${type}/${id}`
  );
};

export const fetchAccountInterestRates = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${INVESTMENTS_API}/interest-rate-widget/${accountId}`);
};
