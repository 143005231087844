import React, { useEffect } from 'react';
import { ComponentBody } from '../../components/styled-components';
import { fetchOpenReports } from '../../actions/analysis';
import { connect } from 'react-redux';
import AnalysisOpenReportsTable from '../../components/table/AnalysisOpenReportsTable';

const OpenReportsBase = ({
  appUser,
  fetchOpenReports,
  openReports,
  isFetchingOpenReports,
  handleCurrentComponentChange,
  handleSetClientAttr,
  ...props
}) => {
  useEffect(() => {
    fetchOpenReports();
  }, [fetchOpenReports]);

  return (
    <ComponentBody padding='0'>
      <AnalysisOpenReportsTable
        appUser={appUser}
        openReports={openReports}
        isFetching={isFetchingOpenReports}
        handleCurrentComponentChange={handleCurrentComponentChange}
        handleSetClientAttr={handleSetClientAttr}
        {...props}
      />
    </ComponentBody>
  );
};

const mapStateToProps = (state) => ({
  openReports: state.analysis.openReports,
  isFetchingOpenReports: state.analysis.isFetchingOpenReports,
});

const OpenReports = connect(mapStateToProps, { fetchOpenReports })(
  OpenReportsBase
);

export default OpenReports;
