import React from 'react';
import {
  AlignCenter,
  Label,
  TransparentButton,
  FlexColumn,
  MessageText,
} from '../../styled-components';
import { Formik, Form, FieldArray } from 'formik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import * as Yup from 'yup';
import FormActions from '../FormActions';
import {
  dateToString,
  checkForDisable,
  stringToDate,
} from '../../../utils/functions';
import DateSelectField from '../../select-fields/DateSelectField';
import colors from '../../../utils/colors';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import moment from 'moment';

const stepOneSchema = Yup.object().shape({
  addAnalysisName: Yup.string().required('Required!'),
  accountGroup: Yup.string().required('Required!'),
  startDate: Yup.date().required('Required!').nullable(),
  endDate: Yup.date().required('Required!').nullable(),
  balanceAdjustments: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required('Required!').nullable(),
      value: Yup.string()
        .required('Required!')
        .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
    })
  ),
});

const stepTwoSchema = Yup.object().shape({
  modelName: Yup.string().required('Required!'),
});

const transformValues = (values, accountGroups, stepToRun) => {
  // set values for step 1
  if (stepToRun === 1) {
    // Format Balance Adjustments from [{date: date, value: value}] into [[date, value]]
    values.balanceAdjustments = values.balanceAdjustments.map(
      (balanceAdjustment) => {
        return [
          dateToString(balanceAdjustment.date),
          Number(balanceAdjustment.value),
        ];
      }
    );

    // get account group id
    if (values.accountGroup) {
      const accountGroupId = accountGroups.find(
        (x) => x.label === values.accountGroup
      ).value;
      values.accountGroupId = accountGroupId;
    }

    // change date range objects to strings
    values.startRange = dateToString(values.startDate);
    values.endRange = dateToString(values.endDate);
    delete values.shortTermModel;
    delete values.shortTermModelId;
    delete values.modelName;
    delete values.startDate;
    delete values.endDate;
  }

  // set values for step 2
  if (stepToRun === 2) {
    delete values.balanceAdjustments;
    delete values.startRange;
    delete values.endRange;
    delete values.addAnalysisName;
    delete values.clientId;
    delete values.accountGroup;
  }

  // delete these values regardless of step
  delete values.accountGroup;
  delete values.dateRange;

  return [values, stepToRun];
};

const ShortTermModelForm = ({
  title,
  onClose,
  handleRunAnalysis,
  startDate,
  endDate,
  clientAccountGroups,
  accountGroupId,
  addAnalysisName,
  clientId,
  balanceAdjustments,
  groupName,
  shortTermModelList,
  stepToRun,
  selectedRow,
  ...props
}) => {
  const isDisabled = stepToRun === 2;
  return (
    <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
      <Formik
        initialValues={{
          startDate: stringToDate(startDate),
          endDate: stringToDate(endDate),
          addAnalysisName,
          accountGroup: groupName,
          clientId,
          balanceAdjustments,
          modelName: '',
          shortTermModelId: selectedRow.shortTermModelId,
        }}
        validationSchema={stepToRun === 1 ? stepOneSchema : stepTwoSchema}
        onSubmit={(values) => {
          if (title === 'Settings')
            handleRunAnalysis(
              transformValues(values, clientAccountGroups, stepToRun)
            );
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          // checks if the dates from start to end range is at least 24 months of data
          const isDateRangeValid =
            values.startDate &&
            values.endDate &&
            moment(values.endDate).diff(values.startDate, 'months', true) >= 24;
          const isSaveDisabled =
            // checks if the end date is at least 24 months after the minDate
            // the minDate is the first date in the account that has data.
            checkForDisable(
              values.endDate,
              values.accountGroup,
              clientAccountGroups,
              24
            ) || !isDateRangeValid;

          const item =
            clientAccountGroups &&
            values.accountGroup &&
            clientAccountGroups.filter(
              (x) => x.label === values.accountGroup
            )[0].minDate;

          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter
                padding='0 50px 10px 50px'
                justifyContent='flex-start'
              >
                <FlexColumn>
                  <MessageText>
                    Note: Account Group must be selected before Start Date.
                  </MessageText>
                  <MessageText>
                    Note: End Date must be at least 24 months past the Start
                    Date.
                  </MessageText>
                </FlexColumn>
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  value={values.accountGroup || ''}
                  labelName={'Account Group'}
                  items={clientAccountGroups}
                  name='accountGroup'
                  useLabel={true}
                  isDisabled={isDisabled}
                  isStepTwoShortTermModel={true}
                />
                <ModalTextField
                  type='text'
                  name='addAnalysisName'
                  placeholder='Name'
                  label='Name'
                  value={values.addAnalysisName || ''}
                  autoComplete='off'
                  isDisabled={isDisabled}
                  isStepTwoShortTermModel={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <DateSelectField
                  name={'startDate'}
                  setFieldValue={setFieldValue}
                  value={values.startDate}
                  labelName={'Start Date'}
                  minDate={stringToDate(item)}
                  // disable date field if no value is selected for account group
                  isDateDisabled={!values.accountGroup}
                />
                <DateSelectField
                  name={'endDate'}
                  setFieldValue={setFieldValue}
                  value={values.endDate}
                  labelName={'End Date'}
                />
              </AlignCenter>
              <FieldArray
                name='balanceAdjustments'
                render={(arrayHelpers) => {
                  return (
                    <>
                      {values.balanceAdjustments &&
                        values.balanceAdjustments.length >= 1 &&
                        values.balanceAdjustments.map(
                          (balanceAdjustment, index) => {
                            return (
                              <div key={index}>
                                {index === 0 && (
                                  <AlignCenter
                                    style={{
                                      marginLeft: '50px',
                                      marginBottom: '3px',
                                    }}
                                    justifyContent='start'
                                  >
                                    <Label
                                      color={
                                        isDisabled
                                          ? colors.lightText
                                          : colors.boldText
                                      }
                                    >
                                      Balance Adjustments
                                    </Label>
                                  </AlignCenter>
                                )}
                                <AlignCenter
                                  justifyContent='space-evenly'
                                  padding='0'
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      background: isDisabled
                                        ? colors.grey
                                        : colors.lightPurple,
                                      borderRadius: '10px',
                                      marginBottom: '10px',
                                      padding: '10px 20px 0',
                                    }}
                                  >
                                    <DateSelectField
                                      name={`balanceAdjustments.${index}.date`}
                                      setFieldValue={setFieldValue}
                                      placeholder='MM/DD/YYYY'
                                      value={balanceAdjustment.date || null}
                                      isBalanceAdjustment={true}
                                      isDateDisabled={isDisabled}
                                    />
                                    <div
                                      style={{
                                        marginLeft: '10px',
                                      }}
                                    >
                                      <ModalTextField
                                        type='text'
                                        name={`balanceAdjustments.${index}.value`}
                                        setFieldValue={setFieldValue}
                                        placeholder='Value'
                                        autoComplete='off'
                                        isCurrency={true}
                                        value={balanceAdjustment.value || ''}
                                        isDisabled={isDisabled}
                                        isStepTwoShortTermModel={true}
                                        {...props}
                                      />
                                    </div>

                                    {stepToRun === 1 && (
                                      <Icon
                                        style={{
                                          marginTop: '25px',
                                          marginLeft: '30px',
                                        }}
                                        icon={iconValue.cross}
                                        iconSize={16}
                                        color={colors.lightText}
                                        onClick={() => {
                                          if (stepToRun === 1)
                                            arrayHelpers.remove(index);
                                        }}
                                      />
                                    )}
                                  </div>
                                </AlignCenter>
                              </div>
                            );
                          }
                        )}
                      {stepToRun === 1 && (
                        <AlignCenter
                          justifyContent='start'
                          padding='20px 0'
                          style={{ marginLeft: '50px' }}
                        >
                          <TransparentButton
                            margin='0 10px 0 0'
                            type='button'
                            onClick={() => {
                              arrayHelpers.push({
                                date: null,
                                value: '',
                              });
                            }}
                          >
                            Add Another Balance Adjustment
                          </TransparentButton>
                        </AlignCenter>
                      )}
                    </>
                  );
                }}
              />
              {stepToRun === 2 && (
                <>
                  <div
                    style={{ borderTop: `1px solid ${colors.lightText}` }}
                  ></div>
                  <AlignCenter
                    justifyContent='start'
                    padding='40px 0 0 0'
                    style={{ marginLeft: '50px' }}
                  >
                    <SelectFieldFormik
                      setFieldValue={setFieldValue}
                      value={values.modelName || ''}
                      labelName={'Choose Model'}
                      items={shortTermModelList}
                      name='modelName'
                      useLabel={true}
                    />
                  </AlignCenter>
                </>
              )}
              <FormActions
                isSaveDisabled={isSaveDisabled && stepToRun === 1}
                saveText={'RUN WITH SETTINGS'}
                isSubmitting={isSubmitting}
                onClose={onClose}
              />
            </Form>
          );
        }}
      </Formik>
    </AlignCenter>
  );
};

export default ShortTermModelForm;
