import React, { useMemo } from 'react';
import {
  StyleColumnCell,
  TransparentButton,
  AlignCenter,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import colors from '../../utils/colors';
import { checkPermissions, localDateFormatter, computeCollectionType, getCollectionTypeColor } from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const AnalysisOpenReportsTable = ({
  openReports,
  isFetching,
  appUser,
  handleCurrentComponentChange,
  handleSetClientAttr,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Client' />
        ),
        accessor: 'clientName',
        width: '15%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Created Date' />
        ),
        accessor: 'createdAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => localDateFormatter(value),
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Collection Type' />
        ),
        accessor: (row) => `${computeCollectionType(row.accountsCount, row.accountsSentIn)}`,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell color={getCollectionTypeColor(value)}>
              {value}
            </StyleColumnCell>
          );
        },
        id: 'collectionType',
        width: '15%',
        sortType: 'basic',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Last Analysis Date' />
        ),
        accessor: 'startDate',
        width: '15%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{value !== null ? value : '-'}</span>;
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Analysis Status' />
        ),
        accessor: 'status',
        width: '20%',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={
                value === 'Data Ingestion'
                  ? colors.greyPlaceholderText
                  : value === 'New Files Added'
                    ? colors.inActiveColor
                    : value === 'Ready For Analysis'
                      ? colors.purpleText
                      : value === 'In Progress'
                        ? colors.green
                        : value === 'Analysis Stopped'
                          ? colors.red
                          : colors.primary
              }
            >
              {value && value.toUpperCase()}
            </StyleColumnCell>
          );
        },
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Actions' />
        ),
        id: 'actions',
        width: '10%',
        Cell: ({ row }) => {
          return (
            <AlignCenter justifyContent='start'>
              {checkPermissions(appUser.permList, [
                permissions.VIEW_ANALYSIS_OPEN_REPORTS,
              ]) && (
                  <TransparentButton
                    padding='7px'
                    width='150px'
                    onClick={() => {
                      handleSetClientAttr(row.original);
                      handleCurrentComponentChange(1);
                    }}
                  >
                    Go to Analysis
                  </TransparentButton>
                )}
            </AlignCenter>
          );
        },
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_ANALYSIS_OPEN_REPORTS,
        ]),
      },
    ],
    [appUser, handleCurrentComponentChange, handleSetClientAttr]
  );
  return (
    <TableContainer padding='14rem 2rem 4rem'>
      <MakeTable
        data={openReports}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Clients with Open Reports'
        {...props}
      />
    </TableContainer>
  );
};

export default AnalysisOpenReportsTable;
