import React, { useMemo } from 'react';
import { ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const TemplateTable = ({
  appUser,
  templates,
  isFetching,
  handleEditTemplate,
  handleOpenTemplate,
  handleWarningTemplateOpen,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Header Row/First Cell' />
        ),
        accessor: 'cellHeaderData',
        Cell: ({ cell: { value } }) => (
          <span>
            {value && value.length > 100
              ? value.substring(0, 100) + '...'
              : value
              ? value
              : '---'}
          </span>
        ),
        sortType: 'basic',
        width: '10%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Type' />
        ),
        accessor: 'templateType',
        sortType: 'caseInsensitiveSort',
        width: '8%',
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.MANAGE_TEMPLATES,
                ]) && (
                  <MenuItem
                    text='View/Edit Template'
                    onClick={() => handleEditTemplate(row.original)}
                    style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                  />
                )}
                {checkPermissions(appUser.permList, [
                  permissions.MANAGE_TEMPLATES,
                ]) && (
                  <MenuItem
                    text='Delete Template'
                    onClick={() =>
                      handleWarningTemplateOpen(row.original, 'table')
                    }
                    style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                  />
                )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.MANAGE_TEMPLATES,
        ]),
      },
    ],
    [appUser, handleEditTemplate, handleWarningTemplateOpen]
  );
  return (
    <TableContainer>
      <MakeTable
        data={templates}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Templates'
        handleOpenTemplate={handleOpenTemplate}
        appUser={appUser}
        {...props}
      />
    </TableContainer>
  );
};

export default TemplateTable;
