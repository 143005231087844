import * as api from '../api/clients';

export const fetchSingleClient = (clientId) => ({
  type: 'FETCH_SINGLE_CLIENT',
  payload: api.fetchSingleClient(clientId),
});

export const fetchAllClients = () => ({
  type: 'FETCH_ALL_CLIENTS',
  payload: api.fetchAllClients(),
});

export const fetchAllCategories = () => ({
  type: 'FETCH_ALL_CLIENTS_CATEGORIES',
  payload: api.fetchAllCategories(),
});

export const fetchAllTypes = () => ({
  type: 'FETCH_ALL_CLIENTS_TYPES',
  payload: api.fetchAllTypes(),
});

export const fetchAllowedReportFrequencies = () =>({
    type: 'FETCH_ALLOWED_REPORT_FREQUENCIES',
    payload: api.fetchAllowedReportFrequencies(),
});

export const fetchClientBanks = (id) => ({
  type: 'FETCH_ALL_CLIENT_BANKS',
  payload: api.fetchClientBanks(id),
});

export const fetchClientList = () => ({
  type: 'FETCH_CLIENT_LIST',
  payload: api.fetchClientList(),
});

export const fetchClientNegotiatedRates = (clientId) => ({
  type: 'FETCH_CLIENT_NEGOTIATED_RATES',
  payload: api.fetchClientNegotiatedRates(clientId),
});

export const fetchAccountBalanceData = (clientId) => ({
  type: 'FETCH_ACCOUNT_BALANCE_DATA',
  payload: api.fetchAccountBalanceData(clientId),
});

export const fetchClientReportStatus = (clientId) => ({
  type: 'FETCH_CLIENT_REPORT_STATUS',
  payload: api.fetchClientReportStatus(clientId),
});

export const fetchMaturingInvestments = (clientId) => ({
  type: 'FETCH_MATURING_INVESTMENTS',
  payload: api.fetchMaturingInvestments(clientId),
});

export const fetchDayToDayVariance = (clientId) => ({
  type: 'FETCH_DAY_TO_DAY_VARIANCE',
  payload: api.fetchDayToDayVariance(clientId),
});

export const fetchCashVestScores = (clientId) => ({
  type: 'FETCH_CASH_VEST_SCORES',
  payload: api.fetchCashVestScores(clientId),
});

export const fetchAssetWidgetData = (clientId) => ({
  type: 'FETCH_ASSET_WIDGET_DATA',
  payload: api.fetchAssetWidgetData(clientId),
});

export const fetchClientShortTermForecast = (clientId) => ({
  type: 'FETCH_CLIENT_SHORT_TERM_FORECAST',
  payload: api.fetchClientShortTermForecast(clientId),
});

export const fetchShortTermModelForecast = (clientId, numberOfWeeks) => ({
  type: 'FETCH_SHORT_TERM_MODEL_FORECAST',
  payload: api.fetchShortTermModelForecast(clientId, numberOfWeeks),
});

export const fetchReports = (clientId) => ({
  type: 'FETCH_REPORTS',
  payload: api.fetchReports(clientId),
});

export const fetchClientInterestRate = (clientId) => ({
  type: 'FETCH_CLIENT_INTEREST_RATE',
  payload: api.fetchClientInterestRate(clientId),
});

export const fetchInterestRateComparisons = (clientId) => ({
  type: 'FETCH_INTEREST_RATE_COMPARISONS',
  payload: api.fetchInterestRateComparisons(clientId),
});

export const fetchReportParameters = (reportId) => ({
  type: 'FETCH_VIEW_REPORT_PARAMETERS',
  payload: api.fetchReportParameters(reportId),
});
