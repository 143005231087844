import React, { useMemo } from 'react';
import {
  Icon,
  Menu,
  MenuItem,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import colors from '../../utils/colors';
import {
  checkPermissions,
  localDateFormatterWithTime,
} from '../../utils/functions';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';
import {
  ActionIconButton,
  StyleColumnCell,
  TransparentButton,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import { TableContainer } from './components/table-styled-components';

const ReportsTable = ({
  appUser,
  reports,
  isFetching,
  handleOpenUploadDialog,
  handleDownloadReport,
  handleDeleteReport,
  handleOpenEditCommentDialog,
  clientReportStatus,
  handleGenerateReportDialogOpen,
  handleViewParametersDialogOpen,
  handleOpenFinalizeDialog,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date Generated' />
        ),
        accessor: 'systemReportGeneratedAt',
        Cell: ({ cell: { value } }) =>
          localDateFormatterWithTime(value) !== 'Invalid date'
            ? localDateFormatterWithTime(value)
            : '---',
        id: 'systemReportGeneratedAt',
        isVisible: true,
        width: '10%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Report Period' />
        ),
        accessor: (row) => `${row.reportStartDate} - ${row.reportEndDate}`,
        id: 'reportPeriod',
        isVisible: true,
        width: '10%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Report Name' />
        ),
        Cell: ({ cell: { value } }) =>
          value ? value.replace('.pdf', '').split('-').join(' ') : '---',
        accessor: 'systemReport',
        id: 'reportName',
        isVisible: true,
        width: '5%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Presentation Date' />
        ),
        accessor: 'presentationDate',
        Cell: ({ cell: { value } }) => <span>{value || '---'}</span>,
        isVisible: true,
        width: '5%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Finalized?' />
        ),
        accessor: 'finalized',
        Cell: ({ cell: { value } }) => (
          <StyleColumnCell
          color={value ? colors.primary : colors.normalText}
        >
          {value ? 'YES' : 'NO'}
        </StyleColumnCell>
        ),
        isVisible: true,
        width: '5%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Comment' />
        ),
        accessor: 'comment',
        Cell: ({ cell: { value } }) => (
          <span>
            {value && value.length > 100
              ? value.substring(0, 100) + '...'
              : value
              ? value
              : '---'}
          </span>
        ),
        isVisible: true,
        width: '20%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Published' />
        ),
        accessor: 'published',
        Cell: ({ cell: { value } }) => (
          <StyleColumnCell
          color={value? colors.primary : colors.normalText}
        >
          {value ? 'YES' : 'NO'}
        </StyleColumnCell>
        ),
        isVisible: true,
        width: '20%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='System Gen. PDF' />
        ),
        accessor: 'systemReport',
        Cell: ({ cell: { value, row, column } }) => {
          return (
            <TransparentButton
              margin='0 10px 0 0'
              onClick={() => handleDownloadReport(row.original, 'system')}
              disabled={row.original.systemReport === null}
            >
              {value !== null ? (
                <Tooltip content={row.original.systemReport}>
                  <Icon
                    icon={iconValue.download}
                    iconSize={16}
                    color={colors.primary}
                  />
                </Tooltip>
              ) : (
                <Icon
                  icon={iconValue.cross}
                  iconSize={16}
                  color={colors.lightText}
                />
              )}
            </TransparentButton>
          );
        },
        isVisible: checkPermissions(appUser.permList, [
          permissions.DOWNLOAD_REPORTS,
        ]),
        width: '10%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='User Edited PDF' />
        ),
        accessor: 'userReport',
        Cell: ({ cell: { value, row } }) => {
          return (
            <TransparentButton
              margin='0 10px 0 0'
              onClick={() => handleDownloadReport(row.original, 'user')}
              disabled={row.original.userReport === null}
            >
              {value !== null ? (
                <Tooltip content={row.original.userReport}>
                  <Icon
                    icon={iconValue.download}
                    iconSize={16}
                    color={colors.primary}
                  />
                </Tooltip>
              ) : (
                <Icon
                  icon={iconValue.cross}
                  iconSize={16}
                  color={colors.lightText}
                />
              )}
            </TransparentButton>
          );
        },
        isVisible: checkPermissions(appUser.permList, [
          permissions.DOWNLOAD_REPORTS,
        ]),
        width: '10%',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Uploaded By' />
        ),
        accessor: 'uploadedBy',
        Cell: ({ cell: { value } }) => <span>{value || '---'}</span>,
        isVisible: true,
        width: '5%',
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '1%',
        Cell: ({ cell: { row } }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.EDIT_REPORT_COMMENTS,
                    permissions.VIEW_REPORTS,
                  ]) && (
                    <MenuItem
                      text='View/Edit Comment'
                      onClick={() => handleOpenEditCommentDialog(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.VIEW_REPORTS,
                  ]) && (
                    <MenuItem
                      text='View Parameters'
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                      onClick={() =>
                        handleViewParametersDialogOpen(row.original)
                      }
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.UPLOAD_REPORTS,
                  ]) && (
                    <MenuItem
                      text='Upload Edited PDF'
                      onClick={() => {
                        handleOpenUploadDialog(row.original);
                      }}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.FINALIZE_REPORTS,
                  ]) && (
                    <MenuItem
                      text={
                        row.original.finalized
                          ? 'Unfinalize'
                          : 'Finalize'

                      }
                      onClick={() => handleOpenFinalizeDialog(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.DELETE_OPEN_REPORTS,
                  ]) && (!row.original.published)  && (
                    <MenuItem
                      text='Delete Report'
                      onClick={() => {
                        handleDeleteReport(row.original);
                      }}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_REPORTS,
          permissions.UPLOAD_REPORTS,
          permissions.EDIT_REPORT,
        ]),
      },
    ],
    [
      appUser.permList,
      handleOpenEditCommentDialog,
      handleOpenUploadDialog,
      handleDownloadReport,
      handleViewParametersDialogOpen,
      handleOpenFinalizeDialog,
      handleDeleteReport
    ]
  );
  return (
    <TableContainer padding='0px' width='100%'>
      <MakeTable
        data={reports}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Reports List'
        appUser={appUser}
        handleGenerateReportDialogOpen={handleGenerateReportDialogOpen}
        clientReportStatus={clientReportStatus}
        {...props}
      />
    </TableContainer>
  );
};

export default ReportsTable;
