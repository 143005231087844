import React from 'react';
import {
  BoldText,
  AlignCenter,
  TransparentButton,
  PurpleBox,
} from '../../styled-components';
import shortid from 'shortid';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

const AccountGroupSection = ({
  openAddAccountGroupDialog,
  openAssignEditGroupDialog,
  accountGroupAssociations,
  appUser,
}) => {
  return (
    <>
      <AlignCenter justifyContent='space-between'>
        <BoldText>Account Groups</BoldText>
        <AlignCenter justifyContent='flex-end'>
          {checkPermissions(appUser.permList, [
            permissions.ADD_ACCOUNT_GROUPS,
          ]) && (
            <TransparentButton
              margin='0 10px'
              onClick={openAddAccountGroupDialog}
            >
              Create Group
            </TransparentButton>
          )}

          {checkPermissions(appUser.permList, [
            permissions.EDIT_ACCOUNT_GROUPS,
          ]) && (
            <TransparentButton
              margin='0 10px'
              onClick={openAssignEditGroupDialog}
            >
              Assign/Edit Groups
            </TransparentButton>
          )}
        </AlignCenter>
      </AlignCenter>
      {accountGroupAssociations.length > 0 && (
        <AlignCenter
          justifyContent='flex-start'
          style={{ flexWrap: 'wrap' }}
          margin='10px 0 0'
        >
          {accountGroupAssociations.map(function (value, index) {
            return (
              <PurpleBox key={shortid.generate()}>{value.label}</PurpleBox>
            );
          })}
        </AlignCenter>
      )}
    </>
  );
};

export default AccountGroupSection;
