import React, { useMemo, useState } from 'react';
import { Icon, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import colors from '../../utils/colors';
import { amountFormatter, checkPermissions } from '../../utils/functions';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';
import { ActionIconButton, StyleColumnCell } from '../styled-components';
import MakeTable from './components/MakeTable';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import { TableContainer } from './components/table-styled-components';

const StagingAccountBalanceTable = ({
  stagingAccountBalances,
  isFetching,
  appUser,
  setDynamicHeaders,
  handleOpenEditDialog,
  handleValidateAccountBalance,
  handleInvalidateAccountBalance,
  ...props
}) => {
  const [dynamicColumns, setDynamicColumns] = useState([]);

  if (
    !isFetching &&
    stagingAccountBalances.length > 0 &&
    !dynamicColumns.length
  ) {
    setDynamicHeaders(stagingAccountBalances[0].headers);
    stagingAccountBalances &&
      stagingAccountBalances[0].headers.forEach((item, i) => {
        dynamicColumns.push({
          Header: ({ column }) => (
            <SortableHeader column={column} header={item} sizeFont={'0.75'} />
          ),
          accessor: (row) => row.amounts[i][1],
          Cell: ({ cell: { value }, row }) =>
            value !== null
              ? row.original.description === 'ecr' ||
                row.original.description.split(' ').includes('Rate') ||
                row.original.description.split(' ').includes('rate')
                ? `${value * 100}%`
                : amountFormatter(value)
              : '-',
          id: item,
          filter: 'numberRange',
          width: '5%',
          isVisible: true,
        });
      });
    setDynamicColumns([...dynamicColumns]);
    stagingAccountBalances.splice(0, 1);
  }

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Account Number'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'accountNumber',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='AFP Code' sizeFont={'0.75'} />
        ),
        Cell: ({ cell: { value } }) => value || '-',
        accessor: 'afpCode',
        filter: 'multipleValues',
        width: '5%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Description'
            sizeFont={'0.75'}
          />
        ),
        accessor: 'description',
        width: '10%',
        sortType: 'caseInsensitiveSort',
        filter: 'multipleValues',
        isVisible: true,
      },
      ...dynamicColumns,
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Validated?' />
        ),
        accessor: 'validationStatus',
        sortType: 'caseInsensitiveSort',
        width: '5%',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={value === 'No' ? colors.activeColor : colors.inActiveColor}
            >
              {value && value.toUpperCase()}
            </StyleColumnCell>
          );
        },
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.EDIT_STAGING_ACCOUNT_BALANCES,
                ]) && (
                  <MenuItem
                    text='Edit'
                    onClick={() => handleOpenEditDialog(row.original)}
                    style={{
                      color: `${colors.purpleText}`,
                      fontWeight: '700',
                    }}
                  />
                )}
                {checkPermissions(appUser.permList, [
                  permissions.VALIDATE_STAGING_ACCOUNT_BALANCES,
                  permissions.INVALIDATE_STAGING_ACCOUNT_BALANCES,
                ]) && (
                  <MenuItem
                    text={
                      row.original.validationStatus === 'No'
                        ? 'Validate'
                        : 'Invalidate'
                    }
                    onClick={() =>
                      row.original.validationStatus === 'No'
                        ? handleValidateAccountBalance(row.original)
                        : handleInvalidateAccountBalance(row.original)
                    }
                    style={{
                      color: `${colors.purpleText}`,
                      fontWeight: '700',
                    }}
                  />
                )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),

        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_STAGING_ACCOUNT_BALANCES,
          permissions.EDIT_STAGING_ACCOUNT_BALANCES,
          permissions.VALIDATE_STAGING_ACCOUNT_BALANCES,
          permissions.INVALIDATE_STAGING_ACCOUNT_BALANCES,
        ]),
      },
    ],
    [
      appUser,
      dynamicColumns,
      handleOpenEditDialog,
      handleValidateAccountBalance,
      handleInvalidateAccountBalance,
    ]
  );
  return (
    <>
      <TableContainer width='100%' padding='0rem 2rem 1rem'>
        <MakeTable
          appUser={appUser}
          tableTitle='Account Balance'
          isFetching={isFetching}
          data={stagingAccountBalances}
          columns={columns}
          customPageOptions={[10, 25, 50, 75, 100]}
          isTreasuryServices={true}
          handleValidateStagingTable={handleValidateAccountBalance}
          handleInvalidateStagingTable={handleInvalidateAccountBalance}
          {...props}
        />
      </TableContainer>
    </>
  );
};

export default StagingAccountBalanceTable;
