import { combineReducers } from 'redux';
import users from './users';
import roles from './roles';
import clients from './clients';
import banks from './banks';
import accounts from './accounts';
import transactions from './transactions';
import dataIngestion from './data-ingestion';
import auth from './auth';
import investments from './investments';
import dashboard from './dashboard';
import notes from './notes';
import administration from './administration';
import quandl from './quandl';
import analysis from './analysis';
import treasuryServices from './treasuryServices';
import templates from './templates';

const rootReducer = combineReducers({
  auth,
  users,
  roles,
  clients,
  banks,
  accounts,
  transactions,
  dataIngestion,
  investments,
  dashboard,
  notes,
  administration,
  quandl,
  analysis,
  treasuryServices,
  templates,
});

export default rootReducer;
