import React, { useState } from 'react';
import {
  NavItem,
  SubNavWrapper,
  TransparentButton,
} from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';
import { updateDailyMonthlyBalance } from '../../../api/clients';
import AppToast from '../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import LoadSpinner from '../../LoadSpinner';

const AnalysisOptionsSubNav = ({
  handleCurrentComponentChange,
  currentComponent,
  appUser,
  clientId,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleUpdateDailyMonthlyBalance = () => {
    setIsFetching(true);
    updateDailyMonthlyBalance(clientId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to run calculations.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <>
      <SubNavWrapper
        backgroundColor='white'
        margin='0 0 -15px 0'
        padding='0 0 0 20px'
      >
        {checkPermissions(appUser.permList, [
          permissions.VIEW_MONTE_CARLO_ANALYSIS,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(0)}
            className={currentComponent === 0 && 'active'}
          >
            Monté Carlo
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_SHORT_TERM_MODEL,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(1)}
            className={currentComponent === 1 && 'active'}
          >
            Short Term Model
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_CASH_VEST_SCORES,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(2)}
            className={currentComponent === 2 && 'active'}
          >
            cashVest
          </NavItem>
        )}
        <TransparentButton
          margin='0 0 10px 10px'
          onClick={handleUpdateDailyMonthlyBalance}
          display='flex'
        >
          Calculate Daily and Monthly Tables
          {isFetching && (
            <span style={{ marginLeft: '10px' }}>
              <LoadSpinner size={20} />
            </span>
          )}
        </TransparentButton>
      </SubNavWrapper>
    </>
  );
};

export default AnalysisOptionsSubNav;
