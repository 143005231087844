import React, { useMemo } from 'react';
import MakeTable from './components/MakeTable';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import { ActionIconButton } from '../styled-components';
import RowSelector from './components/RowSelector';
import SortableHeader from './components/SortableHeader';
import colors from '../../utils/colors';
import permissions from '../../utils/permissions';
import { checkPermissions, localDateFormatter } from '../../utils/functions';
import iconValue from '../../utils/icons';
import { TableContainer } from './components/table-styled-components';

const KeywordsTable = ({
  appUser,
  isFetching,
  keywords,
  openEdit,
  openAdd,
  openDeleteDialog,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Keyword' />
        ),
        accessor: 'keywordName',
        sortType: 'caseInsensitiveSort',
        width: '30%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Category' />
        ),
        accessor: 'keywordCategory',
        sortType: 'caseInsensitiveSort',
        width: '30%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Creation Date' />
        ),
        accessor: 'createdAt',
        width: '20%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => {
          return <span>{value && localDateFormatter(value)}</span>;
        },
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        sticky: 'right',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.EDIT_KEYWORDS,
                ]) && (
                  <MenuItem
                    text='Edit'
                    onClick={() => {
                      openEdit(row.original);
                    }}
                    style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                  />
                )}
                {checkPermissions(appUser.permList, [
                  permissions.DELETE_KEYWORDS,
                ]) && (
                  <>
                    <Menu.Divider />
                    <MenuItem
                      text='Delete'
                      onClick={() => {
                        openDeleteDialog(row.original);
                      }}
                      style={{ color: `${colors.red}`, fontWeight: '700' }}
                    />
                  </>
                )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),

        isVisible: checkPermissions(appUser.permList, [
          permissions.EDIT_KEYWORDS,
          permissions.DELETE_KEYWORDS,
        ]),
      },
    ],
    [appUser, openEdit, openDeleteDialog]
  );

  return (
    <TableContainer padding={'0px'} width={'100%'}>
      <MakeTable
        appUser={appUser}
        data={keywords}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Manage Keywords'
        openAddKeywords={openAdd}
        handleDeleteDialogOpen={openDeleteDialog}
        {...props}
      />
    </TableContainer>
  );
};

export default KeywordsTable;
