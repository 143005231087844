import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { RedText, MessageText } from '../../styled-components';
import DialogDeleteActions from '../DialogDeleteActions';

const InvestmentGroupDeleteDialogBox = ({
  title,
  isOpen,
  isDeleting,
  handleClose,
  handleDelete,
  investmentGroup,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete{' '}
          {!Object.keys(investmentGroup).length
            ? 'these investment groups'
            : 'this investment group'}
          ?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default InvestmentGroupDeleteDialogBox;
