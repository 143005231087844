import React from 'react';
import { Header, Text, Data, Number } from './styled-components';

const A2P2CommercialPaper = ({ quandlData }) => {
  return (
    <div style={{ flexBasis: '250px', flexGrow: 1, marginRight: '10px' }}>
      <div style={{ borderBottom: '1px solid white', marginBottom: '5px' }}>
        <Header>A2/P2 Commercial Paper</Header>
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <Data>
          {quandlData && quandlData.length !== 0 && (
            <Number>{quandlData[0].a2p2NonFinancialCommercialPaper[0]}</Number>
          )}
          <Text>30-Day</Text>
        </Data>
        <Data>
          {quandlData && quandlData.length !== 0 && (
            <Number>{quandlData[0].a2p2NonFinancialCommercialPaper[1]}</Number>
          )}
          <Text>Overnight</Text>
        </Data>
      </div>
    </div>
  );
};

export default A2P2CommercialPaper;
