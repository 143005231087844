import { combineReducers } from 'redux';

const stagingFees = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STAGING_FEES_FULFILLED':
      return action.payload.data;
    case 'FETCH_STAGING_FEES_REJECTED':
    case 'FETCH_STAGING_FEES_PENDING':
      return [];
    case 'CLOSE_DIALOG':
      return [];
    default:
      return state;
  }
};

const stagingBalances = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STAGING_BALANCES_FULFILLED':
      return action.payload.data;
    case 'FETCH_STAGING_BALANCES_REJECTED':
    case 'FETCH_STAGING_BALANCES_PENDING':
      return [];
    case 'CLOSE_DIALOG':
      return [];
    default:
      return state;
  }
};

const stagingAccountBalances = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STAGING_ACCOUNT_BALANCES_FULFILLED':
      return action.payload.data;
    case 'FETCH_STAGING_ACCOUNT_BALANCES_REJECTED':
    case 'FETCH_STAGING_ACCOUNT_BALANCES_PENDING':
      return [];
    case 'CLOSE_DIALOG':
      return [];
    default:
      return state;
  }
};

const isFetchingStagingBalances = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_STAGING_BALANCES_PENDING':
      return true;
    case 'FETCH_STAGING_BALANCES_REJECTED':
    case 'FETCH_STAGING_BALANCES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const isFetchingStagingAccountBalances = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_STAGING_ACCOUNT_BALANCES_PENDING':
      return true;
    case 'FETCH_STAGING_ACCOUNT_BALANCES_REJECTED':
    case 'FETCH_STAGING_ACCOUNT_BALANCES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const isFetchingStagingFees = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_STAGING_FEES_PENDING':
      return true;
    case 'FETCH_STAGING_FEES_REJECTED':
    case 'FETCH_STAGING_FEES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const standardizedDescriptionList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STANDARDIZED_DESCRIPTIONS_FULFILLED':
      return action.payload.data;
    case 'FETCH_STANDARDIZED_DESCRIPTIONS_PENDING':
    case 'FETCH_STANDARDIZED_DESCRIPTIONS_REJECTED':
      return [];
    default:
      return state;
  }
};

const bankLevelExceptionList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_BANK_LEVEL_EXCEPTION_LIST_FULFILLED':
      return action.payload.data;
    case 'FETCH_BANK_LEVEL_EXCEPTION_LIST_PENDING':
    case 'FETCH_BANK_LEVEL_EXCEPTION_LIST_REJECTED':
      return [];
    default:
      return state;
  }
};

const clientLevelExceptionList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_LEVEL_EXCEPTION_LIST_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_LEVEL_EXCEPTION_LIST_PENDING':
    case 'FETCH_CLIENT_LEVEL_EXCEPTION_LIST_REJECTED':
      return [];
    default:
      return state;
  }
};

const feeCategoryList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_FEE_CATEGORY_LIST_FULFILLED':
      return action.payload.data;
    case 'FETCH_FEE_CATEGORY_LIST_PENDING':
    case 'FETCH_FEE_CATEGORY_LIST_REJECTED':
      return [];
    default:
      return state;
  }
};

const treasuryServices = combineReducers({
  stagingFees,
  stagingBalances,
  isFetchingStagingBalances,
  standardizedDescriptionList,
  isFetchingStagingFees,
  bankLevelExceptionList,
  clientLevelExceptionList,
  feeCategoryList,
  stagingAccountBalances,
  isFetchingStagingAccountBalances,
});

export default treasuryServices;
