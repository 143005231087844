import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { fetchTransactionKeywords } from '../../../actions/transactions';
import { connect } from 'react-redux';
import { createQuoteType, updateQuoteType } from '../../../api/administration';
import AppToast from '../../Toast';
import { Intent } from '@blueprintjs/core';
import QuoteTypesForm from '../../forms/global-settings-forms/QuoteTypesForm';

const QuoteTypesDialogBoxBase = ({
  handleCloseDialog,
  isOpen,
  title,
  fetchTransactionKeywords,
  allTransactionKeywords,
  quoteTypeObj,
  fetchAllQuoteTypes,
  fetchSingleQuoteType,
}) => {
  const [quoteTypeName, setQuoteTypeName] = useState('');
  const [status, setStatus] = useState('');
  const [quoteTypeId, setQuoteTypeId] = useState(null);

  useEffect(() => {
    fetchTransactionKeywords();
    if (quoteTypeObj) {
      setQuoteTypeName(quoteTypeObj.quoteTypeName || '');
      setStatus(quoteTypeObj.status || '');
      setQuoteTypeId(quoteTypeObj.quoteTypeId || null);
    }
  }, [fetchTransactionKeywords, quoteTypeObj]);

  const handleCreate = (quoteTypes) => {
    createQuoteType(quoteTypes)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllQuoteTypes();
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create keyword.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleCloseDialog());
  };

  const handleEdit = (quoteTypes) => {
    updateQuoteType(quoteTypeId, quoteTypes)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchSingleQuoteType(quoteTypeId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit Quote Type.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => handleCloseDialog());
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={title}
        onClose={handleCloseDialog}
        isCloseButtonShown={false}
      >
        <QuoteTypesForm
          title={title}
          onClose={handleCloseDialog}
          quoteTypeCategories={allTransactionKeywords}
          quoteTypeName={quoteTypeName}
          status={status}
          handleEdit={handleEdit}
          handleCreate={handleCreate}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  allTransactionKeywords: state.transactions.allTransactionKeywords,
  isAllTransactionsFetching: state.transactions.isAllTransactionsFetching,
});

const QuoteTypesDialogBox = connect(mapStateToProps, {
  fetchTransactionKeywords,
})(QuoteTypesDialogBoxBase);

export default QuoteTypesDialogBox;
