import React, { useState, useEffect } from 'react';
import { Dialog, RadioGroup, Radio } from '@blueprintjs/core';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import {
  dateRangeChecker,
  dateToFilterText,
  multiSelectToFilterText,
  getTwoWeeksRange,
} from '../../../utils/functions';
import TableFilterActions from './TableFilterActions';

const NotesFilterDialogBox = ({
  isOpen,
  title,
  handleClose,
  setFilter,
  initialData,
  setFilterSummary,
  userId,
  isDashboard,
  rangeFilter,
  resetInitialFilter,
}) => {
  const [dateRange, setDateRange] = useState(rangeFilter);
  const [authors, setAuthors] = useState([]);
  const [authorsNames, setAuthorsNames] = useState([]);
  const [noteCategories, setNoteCategories] = useState([]);
  const [noteCategoriesNames, setNoteCategoriesNames] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [taggedUsersNames, setTaggedUsersNames] = useState([]);
  const [eitherOr, setEitherOr] = useState('Yes');

  useEffect(() => {
    if (initialData.length) {
      setAuthorsNames(
        initialData
          .map((item) => item.author)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setNoteCategoriesNames(
        initialData
          .filter((note) => note.noteCategoryNames)
          .reduce(
            (acc, currentVal) => acc.concat(currentVal.noteCategoryNames),
            []
          )
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      setTaggedUsersNames(
        initialData
          .filter((note) => note.taggedUsers)
          .reduce((acc, currentVal) => acc.concat(currentVal.taggedUsers), [])
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
      if (resetInitialFilter) {
        // initial table values to show only notes for last two weeks.
        setFilter('createdAt', rangeFilter);
        setDateRange(rangeFilter);
        // preset calendar picker with most recent two weeks.
        if (isDashboard) {
          // 'userNotes' maps to id / accessor in notes table.
          setEitherOr('No');
          setFilter('userReference', userId);
        }
      }
    }
  }, [
    initialData,
    setFilter,
    userId,
    isDashboard,
    rangeFilter,
    resetInitialFilter,
  ]);

  const getFilterSummary = () => {
    const filters = [];
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Created At'));
    isDashboard &&
      filters.push({
        value:
          eitherOr === 'Yes' ? 'Yes' : 'No, only show me notes relevant to me',
        name: 'Show All Notes',
      });
    authors.length && filters.push(multiSelectToFilterText(authors, 'Author'));
    noteCategories.length &&
      filters.push(multiSelectToFilterText(noteCategories, 'Categories'));
    taggedUsers.length &&
      filters.push(multiSelectToFilterText(taggedUsers, 'User Tags'));
    return filters;
  };

  const handleApplyFilters = () => {
    if (!isDashboard) {
      setEitherOr('Yes');
    }
    setFilterSummary(getFilterSummary());
    setFilter('createdAt', dateRangeChecker(dateRange) ? dateRange : undefined);
    setFilter('author', authors.length ? authors : undefined);
    setFilter(
      'noteCategoryNames',
      noteCategories.length ? noteCategories : undefined
    );
    setFilter('taggedUsers', taggedUsers.length ? taggedUsers : undefined);
    if (eitherOr === 'Yes') {
      setFilter('userReference', undefined);
    } else {
      isDashboard && setFilter('userReference', userId);
    }
    handleClose();
  };

  const handleRestoreDefaultFilters = () => {
    setEitherOr('Yes');
    setFilterSummary(getFilterSummary());
    setFilter('createdAt', getTwoWeeksRange());
    setFilter('author', undefined);
    setFilter('noteCategoryNames', undefined);
    setFilter('taggedUsers', undefined);
    setDateRange(getTwoWeeksRange());
    setAuthors([]);
    setNoteCategories([]);
    setTaggedUsers([]);
    if (isDashboard) {
      setFilter('userReference', userId);
      setEitherOr('No');
    }
    handleClose();
  };

  const handleShowAllNotes = (event) => {
    setEitherOr(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div
        style={{
          padding: '2rem 3rem 0',
          marginBottom: '10px',
        }}
      >
        <div style={{ marginBottom: '20px' }}>
          <DateSelectPlainField
            labelName={'Created Date Range'}
            dateRange={dateRange}
            handleDateChange={setDateRange}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setAuthors}
            items={authorsNames}
            selectedItems={authors}
            label='Authors'
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setNoteCategories}
            items={noteCategoriesNames}
            selectedItems={noteCategories}
            label='Note Categories'
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setTaggedUsers}
            items={taggedUsersNames}
            selectedItems={taggedUsers}
            label='Tagged Users'
          />
        </div>
        {isDashboard && (
          <div style={{ marginBottom: '20px' }}>
            <div style={{ width: '271px' }} className='radio-group'>
              <RadioGroup
                label={'Show All Notes?'}
                onChange={handleShowAllNotes}
                selectedValue={eitherOr}
                inline={true}
              >
                <div style={{ width: '271px' }} className='radio-group'></div>
                <Radio
                  label='Yes, show me all notes.'
                  value='Yes'
                  style={{ marginRight: '20px' }}
                />
                <Radio
                  label='No, only show me notes relevant to me.'
                  value='No'
                />
              </RadioGroup>
            </div>
          </div>
        )}
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleRestoreDefaultFilters={handleRestoreDefaultFilters}
        filterType='Notes'
      />
    </Dialog>
  );
};

export default NotesFilterDialogBox;
