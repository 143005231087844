import React, { useMemo } from 'react';
import colors from '../../../utils/colors';
import { amountFormatter, localDateFormatter } from '../../../utils/functions';
import MakeTable from '../../table/components/MakeTable';
import SortableHeader from '../../table/components/SortableHeader';

const ModelingForecastWidget = ({
  appUser,
  isFetching,
  shortTermForecasts,
  selectedRow,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'date',
        width: '20%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Single Day' />
        ),
        accessor: 'singleVariance',
        width: '10%',
        isVisible: true,
        sortType: 'basic',
        Cell: ({ cell: { value } }) => {
          return (
            <span style={{ color: value > 0 ? colors.green : colors.red }}>
              {amountFormatter(value)}
            </span>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='7 Day' />
        ),
        accessor: 'sevenDayVariance',
        width: '10%',
        isVisible: true,
        sortType: 'basic',
        Cell: ({ cell: { value } }) => {
          return (
            <span style={{ color: value > 0 ? colors.green : colors.red }}>
              {amountFormatter(value)}
            </span>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='14 Day' />
        ),
        accessor: 'fourteenDayVariance',
        width: '10%',
        isVisible: true,
        sortType: 'basic',
        Cell: ({ cell: { value } }) => {
          return (
            <span style={{ color: value > 0 ? colors.green : colors.red }}>
              {amountFormatter(value)}
            </span>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='30 Day' />
        ),
        accessor: 'monthlyVariance',
        width: '10%',
        isVisible: true,
        sortType: 'basic',
        Cell: ({ cell: { value } }) => {
          return (
            <span style={{ color: value > 0 ? colors.green : colors.red }}>
              {amountFormatter(value)}
            </span>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className='widget'
      style={{
        flexBasis: '1150px',
        flexGrow: 2,
        height: '100%',
        boxShadow: `${colors.boxShadowWidget} 0px 1px 5px 1px`,
      }}
    >
      <MakeTable
        appUser={appUser}
        data={shortTermForecasts}
        columns={columns}
        isFetching={isFetching}
        tableTitle={`Short Term Modeling Forecast as of ${localDateFormatter(
          selectedRow.createdAt
        )
          .split('-')
          .join('/')}`}
        isModelingForecastWidget={true}
      />
    </div>
  );
};

export default ModelingForecastWidget;
