import React from 'react';
import { NavItem, SubNavWrapper } from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

const DataIngestionSubNav = ({
  handleCurrentComponentChange,
  currentComponent,
  appUser,
}) => {
  return (
    <>
      <SubNavWrapper>
        {checkPermissions(appUser.permList, [permissions.VIEW_BATCHES]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(0)}
            className={currentComponent === 0 && 'active'}
          >
            Upload & Validation
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_VALIDATION_HISTORY,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(1)}
            className={currentComponent === 1 && 'active'}
          >
            Validation History
          </NavItem>
        )}
      </SubNavWrapper>
    </>
  );
};

export default DataIngestionSubNav;
