import React, { useState } from 'react';
import AnalysisHeader from '../../components/headers/AnalysisHeader';
import OpenReports from './OpenReports';
import Analysis from './Analysis';

const AnalysisManagement = ({ appUser, ...props }) => {
  const [currentComponent, setCurrentComponent] = useState(0);

  const [clientName, setClientName] = useState('');
  const [clientId, setClientId] = useState(0);
  const [status, setStatus] = useState('');

  const handleCurrentComponentChange = (value) => {
    setCurrentComponent(value);
  };

  const handleSetClientAttr = (row) => {
    setClientName(row.clientName);
    setClientId(row.clientId);
    setStatus(row.status);
  };


  return (
    <>
      <AnalysisHeader
        appUser={appUser}
        header={
          currentComponent === 0
            ? 'Analysis: Open Reports'
            : `Analysis: ${clientName}`
        }
        hasSubNav={true}
        currentComponent={currentComponent}
        handleCurrentComponentChange={handleCurrentComponentChange}
        clientId={clientId}
        status={status}
        {...props}
      />

      {currentComponent === 0 && (
        <OpenReports
          appUser={appUser}
          handleCurrentComponentChange={handleCurrentComponentChange}
          handleSetClientAttr={handleSetClientAttr}
          {...props}
        />
      )}
      {currentComponent === 1 && (
        <Analysis appUser={appUser} clientId={clientId} {...props} />
      )}
    </>
  );
};

export default AnalysisManagement;
