import React from 'react';
import { WhiteButton, AlignCenter, RegularButton } from '../styled-components';
import LoadSpinner from '../LoadSpinner';
import colors from '../../utils/colors';

const DialogDeleteActions = ({
  isDeleting,
  handleClose,
  deleteText,
  handleDelete,
  isDisabled,
  isPrimary,
}) => {
  return (
    <AlignCenter
      justifyContent='flex-end'
      padding='10px 20px 10px 0'
      borderTop={`1px solid ${colors.lightText}`}
      margin='50px 0 0 0'
    >
      <WhiteButton
        type='button'
        color={isPrimary ? colors.primary : colors.red}
        onClick={handleClose}
        hoverColor={isPrimary ? colors.purpleHover : colors.redHover}
      >
        CANCEL
      </WhiteButton>
      <RegularButton
        padding='10px 30px'
        display='flex'
        onClick={handleDelete}
        background={!isPrimary && colors.red}
        backgroundHover={!isPrimary && colors.redHover}
        disabled={isDeleting || isDisabled}
      >
        {deleteText || 'DELETE'}
        {isDeleting && (
          <span style={{ marginLeft: '10px' }}>
            <LoadSpinner size={20} />
          </span>
        )}
      </RegularButton>
    </AlignCenter>
  );
};

export default DialogDeleteActions;
