import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@blueprintjs/core';
import colors from '../../utils/colors';
import { AlignCenter, RegularButton, WhiteButton } from '../styled-components';
import permissions from '../../utils/permissions';
import {
  checkPermissions,
  checkBatchValidateButton,
  downloadFiles,
} from '../../utils/functions';
import ValidateBatchTable from '../table/ValidateBatchTable';
import LoadSpinner from '../../components/LoadSpinner';
import {
  downloadFile,
  validateBatchAccount,
  deleteBatchAccount,
  ignoreBatchAccount,
  restoreBatchAccount,
} from '../../api/data-ingestion';
import axios from 'axios';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';
import { validateTreasuryServicesBatch } from '../../api/treasuryServices';

const ValidateBatchDialogBox = ({
  appUser,
  clientName,
  bankName,
  accountType,
  isValidateDialogOpen,
  handleCloseValidationDialog,
  handleValidateBatch,
  isValidatingBatch,
  batchAccounts,
  isFetchingBatchAccounts,
  handleEnableConfirmation,
  templateType,
  fetchBatchAccounts,
  selectedBatchId,
  isInvestment,
  fetchSingleBatchAccount,
  isTreasuryServices,
  isHybridAccount,
  batchId,
  ...props
}) => {
  //state variables
  const [disableButton, setDisableButton] = useState(
    checkBatchValidateButton(batchAccounts)
  );
  const [
    isBatchAccountDeleteDialogEnabled,
    setIsBatchAccountDeleteDialogEnabled,
  ] = useState(false);
  const [selectedBatchAccount, setSelectedBatchAccount] = useState({});
  const [isDeletingBatchAccount, setIsDeletingBatchAccount] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountHasFiles, setAccountHasFiles] = useState([]);
  const [isIgnoreOpen, setIsIgnoreOpen] = useState(false);
  const [isIgnoring, setIsIgnoring] = useState(false);

  //Set submit batch button disable flag
  useEffect(() => {
    setDisableButton(checkBatchValidateButton(batchAccounts));
  }, [batchAccounts]);

  //Opens up the delete batch account confirmation dialog
  const handleEnableAccountConfirmation = useCallback((selectedRow) => {
    setSelectedBatchAccount(selectedRow);
    setIsBatchAccountDeleteDialogEnabled(true);
  }, []);

  //Closes the delete batch account confirmation dialog
  const handleCloseAccountConfirmation = () => {
    setIsBatchAccountDeleteDialogEnabled(false);
    setSelectedBatchAccount({});
  };

  //Deletes a batch account
  const handleDeleteBatchAccount = () => {
    setIsDeletingBatchAccount(true);
    deleteBatchAccount(selectedBatchAccount.batchAccountId)
      .then((res) => {
        if (selectedBatchAccount.fileIdList)
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Delete Batch Account.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchSingleBatchAccount(selectedBatchAccount.batchAccountId);
        setIsDeletingBatchAccount(false);
        handleCloseAccountConfirmation();
      });
  };

  //Downloads sources for a batch account
  const downloadSource = (selectedRow) => {
    const fileIds = selectedRow.fileIdList;
    fileIds.forEach(async (fileId) => {
      const presignedURL = await downloadFile(fileId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
        });

      const name = presignedURL.substring(
        presignedURL.indexOf(`${templateType}/`) + (templateType.length + 1),
        presignedURL.indexOf('?')
      );
      delete axios.defaults.headers.common['Authorization'];
      // Initiating the GET request to download file
      await axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => {
          // call download from utils function
          downloadFiles(res, name);
        })
        .catch((err) => {});
    });
  };

  //closes confirmation dialog when ignoring accounts with files
  const handleCloseIgnoreConfirmation = () => {
    setIsIgnoreOpen(false);
    setSelectedBatchAccount({});
    setSelectedAccounts([])
  };

  //checks if account to be ignored has associated files. if yes shows confirmation dialog
  const checkIgnore = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    const SelectedRowsList = isArray ? selectedRows : [selectedRows]
    setSelectedAccounts(SelectedRowsList)

    const accountWithFiles = SelectedRowsList
      .filter(row => row.status === 'Needs Validation' || row.status === 'Needs Files')
      .filter(row => row.fileIdList?.length > 0)
      .map(row => row.account)

    if (accountWithFiles.length) {
      setAccountHasFiles(accountWithFiles)
      setIsIgnoreOpen(true);
    } else {
      handleIgnoreBatchAccount(SelectedRowsList);
    }
  };

  //ignores a batch account
  const handleIgnoreBatchAccount = (selection) => {
    const selectedRows = Array.isArray(selection) ? selection : selectedAccounts
    const batchAccountsIds = selectedRows
      .filter(row => row.status === 'Needs Validation' || row.status === 'Needs Files')
      .map(row => Number(row.batchAccountId));
    setIsIgnoring(true);
    ignoreBatchAccount(batchAccountsIds)
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Ignore Batch Account(s).',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        batchAccountsIds.forEach(accountId => fetchSingleBatchAccount(accountId))
        setIsIgnoring(false);
        handleCloseIgnoreConfirmation();
      });
  };

  //restores a batch account
  const handleRestoreBatchAccount = (selectedRow) => {
    restoreBatchAccount(selectedRow.batchAccountId)
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Restore Batch Account.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchSingleBatchAccount(selectedRow.batchAccountId);
      });
  };

  /**
   * validates selected rows
   * @param {*} selectedRows
   */
  const handleValidateBatchAccount = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    let batchAccountsIds;

    if (isTreasuryServices) {
      let treasuryServices;
      if (isArray)
        treasuryServices = { batchTypes: selectedRows.map((row) => row.type) };
      else treasuryServices = { batchTypes: [selectedRows.type] };
      treasuryServices.batchId = batchId;
      validateTreasuryServicesBatch(treasuryServices)
        .then((res) => {
          AppToast.show({
            message: res.data.msg || 'Batches Validated Successfully',
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to Validate Batches.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          fetchBatchAccounts(selectedBatchId);
        });
    } else {
      if (isArray)
        batchAccountsIds = selectedRows
          .filter((row) => row.status === 'Needs Validation')
          .map((row) => Number(row.batchAccountId));
      validateBatchAccount(
        isArray ? batchAccountsIds : [Number(selectedRows.batchAccountId)]
      )
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to Validate Batch Account.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          if (isArray) fetchBatchAccounts(selectedBatchId);
          else fetchSingleBatchAccount(selectedRows.batchAccountId);
        });
    }
  };

  return (
    <Dialog
      isOpen={isValidateDialogOpen}
      onClose={handleCloseValidationDialog}
      title={`Validate Batch: ${clientName} / ${bankName} / ${accountType}${
        isInvestment
          ? ' Investments'
          : isTreasuryServices
          ? ' / Analysis Statements'
          : ''
      }`}
      isCloseButtonShown={false}
      className={'custom-dialog-header large-dialog'}
    >
      <AlignCenter justifyContent='space-between'>
        {isFetchingBatchAccounts ? (
          <div style={{ width: '100%' }}>
            <LoadSpinner size={100} />
          </div>
        ) : (
          <ValidateBatchTable
            appUser={appUser}
            batchId={batchId}
            batchAccounts={batchAccounts}
            accountHasFiles={accountHasFiles}
            handleValidateBatchAccount={handleValidateBatchAccount}
            checkIgnore={checkIgnore}
            downloadSource={downloadSource}
            selectedBatchAccount={selectedBatchAccount}
            handleEnableAccountConfirmation={handleEnableAccountConfirmation}
            isBatchAccountDeleteDialogEnabled={
              isBatchAccountDeleteDialogEnabled
            }
            handleCloseAccountConfirmation={handleCloseAccountConfirmation}
            handleDeleteBatchAccount={handleDeleteBatchAccount}
            isDeletingBatchAccount={isDeletingBatchAccount}
            isIgnoreOpen={isIgnoreOpen}
            handleCloseIgnoreConfirmation={handleCloseIgnoreConfirmation}
            handleIgnoreBatchAccount={handleIgnoreBatchAccount}
            isIgnoring={isIgnoring}
            handleRestoreBatchAccount={handleRestoreBatchAccount}
            clientName={clientName}
            bankName={bankName}
            accountType={accountType}
            isInvestment={isInvestment}
            isTreasuryServices={isTreasuryServices}
            isHybridAccount={isHybridAccount}
            {...props}
          />
        )}
      </AlignCenter>

      <AlignCenter justifyContent='space-between' padding='0px 20px 0px 0px'>
        {checkPermissions(appUser.permList, [permissions.DELETE_BATCHES]) && (
          <WhiteButton
            justifyContent='flex-start'
            margin='10px 10px 10px 20px'
            padding='10px 10px 10px 15px'
            fontSize='1rem'
            type='button'
            color={colors.red}
            hoverColor={colors.redHover}
            onClick={handleEnableConfirmation}
          >
            DELETE BATCH
          </WhiteButton>
        )}
        <AlignCenter justifyContent='space-between' width='fit-content'>
          <WhiteButton
            type='button'
            fontSize='1rem'
            onClick={handleCloseValidationDialog}
          >
            CLOSE
          </WhiteButton>
          {checkPermissions(appUser.permList, [
            permissions.VALIDATE_BATCHES,
          ]) && (
            <RegularButton
              type='submit'
              display='flex'
              fontSize='1rem'
              padding='12px 25px'
              disabled={disableButton || isValidatingBatch}
              onClick={handleValidateBatch}
            >
              VALIDATE THIS BATCH
              {isValidatingBatch && (
                <span style={{ marginLeft: '10px' }}>
                  <LoadSpinner size={20} />
                </span>
              )}
            </RegularButton>
          )}
        </AlignCenter>
      </AlignCenter>
    </Dialog>
  );
};

export default ValidateBatchDialogBox;
