import { combineReducers } from 'redux';

const keywords = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_KEYWORDS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_KEYWORDS_REJECTED':
    case 'FETCH_ALL_KEYWORDS_PENDING':
      return [];
    case 'FETCH_SINGLE_KEYWORD_FULFILLED':
      return state.map((keyword) => {
        if (keyword.keywordId === action.payload.data[0].keywordId) {
          return action.payload.data[0];
        }
        return keyword;
      });
    case 'FETCH_SINGLE_KEYWORD_REJECTED':
      return state;
    case 'FETCH_SINGLE_KEYWORD_PENDING':
      return state;
    default:
      return state;
  }
};

const isKeywordsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_KEYWORDS_PENDING':
      return true;
    case 'FETCH_ALL_KEYWORDS_REJECTED':
    case 'FETCH_ALL_KEYWORDS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const uploadDate = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_UPLOAD_DATE_FULFILLED':
      return action.payload.data;
    case 'FETCH_UPLOAD_DATE_REJECTED':
    case 'FETCH_UPLOAD_DATE_PENDING':
      return [];
    default:
      return state;
  }
};

const isUploadDateFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_UPLOAD_DATE_PENDING':
      return true;
    case 'FETCH_UPLOAD_DATE_REJECTED':
    case 'FETCH_UPLOAD_DATE_FULFILLED':
      return false;
    default:
      return state;
  }
};

const maturityLimit = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_MATURITY_LIMIT_FULFILLED':
      return action.payload.data;
    case 'FETCH_MATURITY_LIMIT_REJECTED':
    case 'FETCH_MATURITY_LIMIT_PENDING':
      return [];
    default:
      return state;
  }
};

const isMaturityLimitFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_MATURITY_LIMIT_PENDING':
      return true;
    case 'FETCH_MATURITY_LIMIT_REJECTED':
    case 'FETCH_MATURITY_LIMIT_FULFILLED':
      return false;
    default:
      return state;
  }
};

const keywordCategories = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_KEYWORD_CATEGORIES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_KEYWORD_CATEGORIES_REJECTED':
    case 'FETCH_ALL_KEYWORD_CATEGORIES_PENDING':
      return [];
    case 'FETCH_SINGLE_KEYWORD_CATEGORY_FULFILLED':
      return state.map((category) => {
        if (category.categoryId === action.payload.data[0].categoryId) {
          return action.payload.data[0];
        }
        return category;
      });
    case 'FETCH_SINGLE_KEYWORD_CATEGORY_REJECTED':
      return state;
    case 'FETCH_SINGLE_KEYWORD_CATEGORY_PENDING':
      return state;
    default:
      return state;
  }
};

const isKeywordCategoriesFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_KEYWORD_CATEGORIES_PENDING':
      return true;
    case 'FETCH_ALL_KEYWORD_CATEGORIES_REJECTED':
    case 'FETCH_ALL_KEYWORD_CATEGORIES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const noteCategories = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_NOTES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_NOTES_REJECTED':
    case 'FETCH_ALL_NOTES_PENDING':
      return [];
    case 'FETCH_SINGLE_NOTE_CATEGORY_FULFILLED':
      return state.map((note) => {
        if (note.noteCategoryId === action.payload.data[0].noteCategoryId) {
          return action.payload.data[0];
        }
        return note;
      });
    case 'FETCH_SINGLE_NOTE_CATEGORY_REJECTED':
      return state;
    case 'FETCH_SINGLE_NOTE_CATEGORY_PENDING':
      return state;
    default:
      return state;
  }
};

const isNoteCategoriesFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_NOTES_PENDING':
      return true;
    case 'FETCH_ALL_NOTES_REJECTED':
    case 'FETCH_ALL_NOTES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const quoteTypes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_QUOTE_TYPES_FULFILLED':
      return action.payload.data;
    case 'FETCH_QUOTE_TYPES_REJECTED':
    case 'FETCH_QUOTE_TYPES_PENDING':
      return [];
    case 'FETCH_SINGLE_QUOTE_TYPE_FULFILLED':
      return state.map((quoteType) => {
        if (quoteType.quoteTypeId === action.payload.data[0].quoteTypeId) {
          return action.payload.data[0];
        }
        return quoteType;
      });
    case 'FETCH_SINGLE_QUOTE_TYPE_REJECTED':
      return state;
    case 'FETCH_SINGLE_QUOTE_TYPE_PENDING':
      return state;
    default:
      return state;
  }
};

const isQuoteTypesFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_QUOTE_TYPES_PENDING':
      return true;
    case 'FETCH_QUOTE_TYPES_REJECTED':
    case 'FETCH_QUOTE_TYPES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const quoteTypeList = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_QUOTE_TYPES_LIST_FULFILLED':
      return action.payload.data;
    case 'FETCH_QUOTE_TYPES_LIST_PENDING':
    case 'FETCH_QUOTE_TYPES_LIST_REJECTED':
      return [];
    default:
      return state;
  }
};

const investmentTypes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_TYPES_FULFILLED':
      return action.payload.data;
    case 'FETCH_INVESTMENT_TYPES_PENDING':
    case 'FETCH_INVESTMENT_TYPES_REJECTED':
      return [];
    case 'FETCH_SINGLE_INVESTMENT_TYPE_FULFILLED':
      return state.map((investmentType) => {
        if (
          investmentType.investmentTypeId ===
          action.payload.data[0].investmentTypeId
        ) {
          return action.payload.data[0];
        }
        return investmentType;
      });
    case 'FETCH_SINGLE_INVESTMENT_TYPE_REJECTED':
    case 'FETCH_SINGLE_INVESTMENT_TYPE_PENDING':
      return state;
    default:
      return state;
  }
};

const isInvestmentTypesFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_INVESTMENT_TYPES_PENDING':
      return true;
    case 'FETCH_INVESTMENT_TYPES_FULFILLED':
    case 'FETCH_INVESTMENT_TYPES_REJECTED':
      return false;
    default:
      return state;
  }
};

const administration = combineReducers({
  keywords,
  isKeywordsFetching,
  uploadDate,
  isUploadDateFetching,
  maturityLimit,
  isMaturityLimitFetching,
  keywordCategories,
  isKeywordCategoriesFetching,
  noteCategories,
  quoteTypeList,
  isNoteCategoriesFetching,
  quoteTypes,
  isQuoteTypesFetching,
  investmentTypes,
  isInvestmentTypesFetching,
});

export default administration;
