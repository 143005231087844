import React, { useEffect, useState } from 'react';
import colors from '../../utils/colors';
import {
  BorderComponentContainer,
  BoldText,
  TransparentButton,
} from '../../components/styled-components';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import ModelingForecastWidget from '../../components/widgets/short-term-model-widgets/ModelingForecastWidget';
import DayToDayVarianceWidget from '../../components/widgets/short-term-model-widgets/DayToDayVarianceWidget';
import CashPositionForecastWidget from '../../components/widgets/short-term-model-widgets/CashPositionForecastWidget';
import {
  fetchShortTermForecast,
  fetchDayToDayVarianceShortTermModel,
  fetchShortTermCashPosition,
} from '../../api/analysis';

const ShortTermModelResults = ({
  appUser,
  selectedRow,
  handleDeleteContainer,
  clientId,
}) => {
  const [showBox, setShowBox] = useState(false);
  const [shortTermForecasts, setShortTermForecasts] = useState([]);
  const [
    isFetchingShortTermForecasts,
    setIsFetchingShortTermForecasts,
  ] = useState(false);
  const [
    dayToDayVarianceShortTermModel,
    setDayToDayVarianceShortTermModel,
  ] = useState([]);
  const [
    isDayToDayVarianceShortTermModelFetching,
    setIsDayToDayVarianceShortTermModelFetching,
  ] = useState(false);
  const [shortTermCashPosition, setShortTermCashPosition] = useState([]);
  const [
    isShortTermCashPositionFetching,
    setIsShortTermCashPositionFetching,
  ] = useState(false);

  useEffect(() => {
    if (showBox) {
      setIsFetchingShortTermForecasts(true);
      fetchShortTermForecast(selectedRow.groupId)
        .then((res) => setShortTermForecasts(res.data))
        .catch(() => setShortTermForecasts([]))
        .finally(() => setIsFetchingShortTermForecasts(false));
      setIsDayToDayVarianceShortTermModelFetching(true);
      fetchDayToDayVarianceShortTermModel(selectedRow.groupId)
        .then((res) => setDayToDayVarianceShortTermModel(res.data))
        .catch(() => setDayToDayVarianceShortTermModel([]))
        .finally(() => setIsDayToDayVarianceShortTermModelFetching(false));
      setIsShortTermCashPositionFetching(true);
      fetchShortTermCashPosition(clientId, selectedRow.groupId)
        .then((res) => setShortTermCashPosition(res.data))
        .catch(() => setShortTermCashPosition([]))
        .finally(() => setIsShortTermCashPositionFetching(false));
    }
  }, [showBox, selectedRow, clientId]);

  const handleShowBox = () => {
    setShowBox(!showBox);
  };

  return (
    <BorderComponentContainer
      borderRadius='5px 5px 0 0'
      borderBottom='0'
      padding='1rem 0'
      marginBottom={'2rem'}
      marginTop='25px'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: showBox ? '1rem' : '0',
          borderBottom: showBox ? `1px solid ${colors.lightText}` : '',
        }}
      >
        <Icon
          icon={showBox ? iconValue.caretUp : iconValue.caretDown}
          iconSize={20}
          color={colors.primary}
          style={{ cursor: 'pointer', marginLeft: '1rem' }}
          onClick={handleShowBox}
        />
        <BoldText margin={'0 0 0 1rem'}>
          {selectedRow.shortTermModelName + ' - Results'}
        </BoldText>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <TransparentButton
            margin='0 10px 0 0'
            onClick={() => handleDeleteContainer(selectedRow)}
          >
            Hide Analysis
          </TransparentButton>
        </div>
      </div>
      {showBox && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            padding: '1rem 1rem 0',
          }}
        >
          <ModelingForecastWidget
            appUser={appUser}
            selectedRow={selectedRow}
            isFetching={isFetchingShortTermForecasts}
            shortTermForecasts={shortTermForecasts}
          />
          <DayToDayVarianceWidget
            appUser={appUser}
            selectedRow={selectedRow}
            isDayToDayVarianceFetching={
              isDayToDayVarianceShortTermModelFetching
            }
            dayToDayVarianceShortTermModel={dayToDayVarianceShortTermModel}
          />
          <CashPositionForecastWidget
            shortTermCashPosition={shortTermCashPosition}
            isShortTermCashPositionFetching={isShortTermCashPositionFetching}
          />
        </div>
      )}
    </BorderComponentContainer>
  );
};

export default ShortTermModelResults;
