import * as api from '../api/banks';

export const fetchAllBanks = () => ({
  type: 'FETCH_ALL_BANKS',
  payload: api.fetchAllBanks(),
});

export const fetchBankNames = () => ({
  type: 'FETCH_BANK_NAMES',
  payload: api.fetchBankNames(),
});

export const fetchAllowedDataGatheringValues = () => ({
    type: 'FETCH_ALLOWED_DATA_GATHERING_VALUES',
    payload: api.fetchAllowedDataGatheringValues(),
});

export const fetchBankDefaultDataGatheringFrequencies =  () => ({
    type: 'FETCH_BANK_DEFAULT_DATA_GATHERING_FREQUENCIES',
    payload: api.fetchBankDefaultDataGatheringFrequencies(),
});