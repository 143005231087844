import React from 'react';
import { Header, Text, Data, Number } from './styled-components';

const CorporateBond = ({ quandlData }) => {
  return (
    <div style={{ flexBasis: '120px', flexGrow: 1, marginRight: '10px' }}>
      <div style={{ borderBottom: '1px solid white', marginBottom: '5px' }}>
        <Header>Corporate Bond</Header>
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <Data>
          {quandlData && quandlData.length !== 0 && (
            <Number>{quandlData[0].corporateBond[0]}</Number>
          )}
          <Text>1-Year</Text>
        </Data>
        <Data>
          {quandlData && quandlData.length !== 0 && (
            <Number>{quandlData[0].corporateBond[1]}</Number>
          )}
          <Text>2-Year</Text>
        </Data>
      </div>
    </div>
  );
};

export default CorporateBond;
