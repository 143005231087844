import React, { useMemo } from 'react';
import { ActionIconButton } from '../styled-components';
import MakeTable from './components/MakeTable';
import { TableContainer } from './components/table-styled-components';
import SortableHeader from './components/SortableHeader';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import { Icon } from '@blueprintjs/core';

const ValidationHistoryDownloadTable = ({
  handleDownloadFile,
  tableData,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='File Name' />
        ),
        accessor: 'uploadedFileName',
        sortType: 'caseInsensitiveSort',
        width: '6%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Status' />
        ),
        accessor: 'dataExtractionMsg',
        sortType: 'caseInsensitiveSort',
        width: '4%',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Date' />
        ),
        accessor: 'updatedAt',
        sortType: 'caseInsensitiveSort',
        width: '3%',
        isVisible: true,
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '1%',
        Cell: ({ row }) => (
          <ActionIconButton
            margin='0 0 0 8px'
            style={{ padding: '5px' }}
            onClick={() => handleDownloadFile(row.original)}
          >
            <Icon
              icon={iconValue.download}
              iconSize={16}
              color={colors.primary}
            />
          </ActionIconButton>
        ),
        isVisible: true,
      },
    ],
    [handleDownloadFile]
  );

  return (
    <TableContainer width='100%' padding='0 0 1rem'>
      <MakeTable
        data={tableData}
        columns={columns}
        tableTitle='Validated Files'
        {...props}
      />
    </TableContainer>
  );
};

export default ValidationHistoryDownloadTable;
