import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { WidgetHeader, Label, CardDiv } from '../../styled-components';
import { ResponsiveLine } from '@nivo/line';
import colors, { colorList } from '../../../utils/colors';
import { amountFormatter } from '../../../utils/functions';
import LoadSpinner from '../../LoadSpinner';
import moment from 'moment';
import NonIdealStateComp from '../../Non-Ideal-State';
import iconValue from '../../../utils/icons';

const TotalBalanceGraphWidget = ({
  monteGraphData,
  isMonteGraphDataFetching,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget-monte'}
    >
      <WidgetHeader>
        <Label>
          Duration Capability and Cushion vs. Total Balance as of{' '}
          {moment().format('MM-DD-YYYY')}
        </Label>
      </WidgetHeader>

      {isMonteGraphDataFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '20px 0',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {!isMonteGraphDataFetching &&
        monteGraphData &&
        monteGraphData.length === 0 && (
          <>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        )}

      {!isMonteGraphDataFetching &&
        monteGraphData &&
        monteGraphData.length > 0 && (
          <div
            style={{
              height: '19.7rem',
              width: '100%',
              display: 'flex',
              background: `${colors.containerBackground}`,
            }}
          >
            <ResponsiveLine
              data={monteGraphData}
              margin={{ top: 40, right: 200, bottom: 50, left: 100 }}
              colors={colorList}
              axisTop={null}
              axisRight={null}
              enablePoints={false}
              enableGridX={false}
              enableGridY={false}
              enableArea={true}
              areaOpacity={1}
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
              }}
              xFormat='time:%b-%Y'
              yFormat={(v) => amountFormatter(v, 2)}
              yScale={{
                type: 'linear',
                stacked: true,
              }}
              axisBottom={{
                format: '%b-%Y',
                tickValues: 'every 3 months',
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 10,
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 10,
                format: (v) => amountFormatter(v, 0),
              }}
              // needed for interactivity
              useMesh={true}
              legends={[
                {
                  dataForm: 'keys',
                  anchor: 'right',
                  direction: 'column',
                  translateX: 120,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 30,
                  itemDirection: 'left-to-right',
                  symbolSize: 10,
                  symbolShape: 'circle',
                },
              ]}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: '#a9acbe',
                      strokeWidth: 1,
                    },
                  },
                },
                tooltip: {
                  container: { color: colors.boldText, fontWeight: 700 },
                },
              }}
            />
          </div>
        )}
    </CardDiv>
  );
};

export default TotalBalanceGraphWidget;
