import React, { useState, useEffect } from 'react';
import { Dialog, Icon, Intent } from '@blueprintjs/core';
import styled from 'styled-components';
import {
  TransparentButton,
  BoldText,
  MessageText,
  TextBox,
  Input,
  InputWrapper,
  NameLink,
} from '../../styled-components';
import colors from '../../../utils/colors';
import shortid from 'shortid';
import { connect } from 'react-redux';
import {
  fetchNoteComments,
  fetchSingleNote,
  fetchClientNotes,
  fetchBatchNotes,
  fetchAccountNotes,
  fetchAllNotes,
} from '../../../actions/notes';
import { addComment, editComment } from '../../../api/notes';
import LoadSpinner from '../../../components/LoadSpinner';
import { militaryToStandard, checkPermissions } from '../../../utils/functions';
import iconValue from '../../../utils/icons';
import permissions from '../../../utils/permissions';
import AppToast from '../../../components/Toast';
import NoteDialog from '../../../components/dialog/notes/NoteDialog';
import NonIdealStateComp from '../../Non-Ideal-State';
import DeleteCommentDialog from './DeleteCommentDialog';
import NoteResolveDialogBox from './NoteResolveDialogBox';
import NoteDeleteDialogBox from './NoteDeleteDialogBox';

const TopSection = ({
  appUser,
  note,
  handleCloseViewDialog,
  handleDialogOpen,
  isViewNoteDialogOpen,
  isResolvedNote,
  clientId,
  batchId,
  accountId,
  isDashboard,
  setResetInitialFilter,
}) => {
  const [isResolving, setIsResolving] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [title, setTitle] = useState('');

  const { permList } = appUser;
  const { author } = note;

  const handleOpenResolveDialog = (title) => {
    setTitle(title);
    setIsResolving(true);
  };

  const handleCloseResolveDialog = () => {
    setTitle('');
    setIsResolving(false);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Initials>
            {author && author.split(' ')[0].charAt(0).toUpperCase()}
            {author && author.split(' ')[1].charAt(0).toUpperCase()}
          </Initials>
          <Name>{author}</Name>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {checkPermissions(permList, [permissions.EDIT_NOTES]) &&
            !isResolvedNote && (
              <TransparentButton
                margin='0 10px 0 0'
                onClick={handleDialogOpen}
                disabled={isResolvedNote}
              >
                Edit
              </TransparentButton>
            )}
          {checkPermissions(permList, [permissions.RESOLVE_NOTES]) &&
            !isResolvedNote && (
              <TransparentButton
                margin='0 10px 0 0'
                onClick={() => {
                  handleOpenResolveDialog('Resolve Note');
                }}
                disabled={isResolvedNote}
              >
                Resolve
              </TransparentButton>
            )}
          {isResolvedNote && (
            <>
              <TransparentButton
                margin='0 10px 0 0'
                onClick={() => {
                  handleOpenResolveDialog('Restore Note');
                }}
              >
                Restore
              </TransparentButton>
              <TransparentButton
                margin='0 10px 0 0'
                onClick={() => {
                  setIsDeleteDialogOpen(true);
                }}
              >
                Delete
              </TransparentButton>
            </>
          )}
          <TransparentButton
            margin='0 10px 0 0'
            onClick={handleCloseViewDialog}
          >
            Close
          </TransparentButton>
        </div>
        <NoteResolveDialogBox
          isOpen={isResolving}
          title={title}
          note={note}
          handleClose={handleCloseResolveDialog}
          handleCloseViewDialog={handleCloseViewDialog}
          appUser={appUser}
          isViewNoteDialogOpen={isViewNoteDialogOpen}
          clientId={clientId}
          accountId={accountId}
          batchId={batchId}
          isDashboard={isDashboard}
          setResetInitialFilter={setResetInitialFilter}
        />
        <NoteDeleteDialogBox
          isOpen={isDeleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          title={'Delete Resolve Note'}
          note={note}
          selectedNotes={[]}
          handleCloseViewDialog={handleCloseViewDialog}
          isViewNoteDialogOpen={isViewNoteDialogOpen}
          setResetInitialFilter={setResetInitialFilter}
        />
      </div>
    </>
  );
};

const MiddleSection = ({ note, noteComments }) => {
  const {
    noteTitle,
    noteDescription,
    noteCategoryNames,
    taggedUsers,
    createdAt,
  } = note;
  return (
    <div>
      <BoldText
        margin='20px 0 10px'
        fontSize='1.75rem'
        color={`${colors.boldText}`}
      >
        {noteTitle}
      </BoldText>
      <BoldText margin='10px 0' color={`${colors.boldText}`}>
        {militaryToStandard(createdAt)}
      </BoldText>
      <NameLink
        to={{
          pathname: noteComments.breadcrumbLink,
        }}
      >
        {noteComments.breadcrumb}
      </NameLink>
      <MessageText>{noteDescription}</MessageText>
      {noteCategoryNames && noteCategoryNames.length && (
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {noteCategoryNames.map((category) => (
            <TextBox
              key={shortid.generate()}
              background={colors.purpleText}
              margin='2px 3px'
              padding='10px'
            >
              {category}
            </TextBox>
          ))}
        </div>
      )}
      {taggedUsers && taggedUsers.length && (
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {taggedUsers.map((user) => (
            <TextBox
              key={shortid.generate()}
              background={colors.green}
              margin='2px 3px'
              padding='10px'
            >
              {user}
            </TextBox>
          ))}
        </div>
      )}
    </div>
  );
};

const CommentSection = ({
  appUser,
  noteComments,
  note,
  isFetchingNoteComments,
  fetchNoteComments,
  fetchSingleNote,
  isResolvedNote,
  clientId,
  batchId,
  accountId,
  isDashboard,
  fetchAllNotes,
  fetchBatchNotes,
  fetchAccountNotes,
  fetchClientNotes,
  setResetInitialFilter,
}) => {
  const [comment, setComment] = useState('');
  const [editNoteComment, setEditNoteComment] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submitEdit, setSubmitEdit] = useState(false);
  const [commentNumber, setCommentNumber] = useState(undefined);
  const [commentToDelete, setCommentToDelete] = useState(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { firstName, lastName, userId, permList } = appUser;
  const { comments } = noteComments;
  const { noteId } = note;

  const handleAddComment = (e) => {
    // this is needed to prevent page refresh on submit in Firefox
    e.preventDefault();
    const commentObj = { comment, noteId };
    setIsAdding(true);
    addComment(commentObj)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to add comment.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsAdding(false);
        setComment('');
        setResetInitialFilter(true);
        clientId && fetchClientNotes(clientId);
        batchId && fetchBatchNotes(batchId);
        accountId && fetchAccountNotes(accountId);
        isDashboard && fetchAllNotes();
        fetchSingleNote(noteId);
        fetchNoteComments(noteId);
      });
  };

  const handleShowInput = (comment) => {
    setIsEdit(true);
    setCommentNumber(comment.commentId);
    setEditNoteComment(comment.comment);
  };

  // cancel editing comment
  const handleCancel = () => {
    setIsEdit(false);
    setCommentNumber(undefined);
    setCommentToDelete(undefined);
  };

  const handleEditComment = (e, commentId) => {
    // this is needed to prevent page refresh on submit in Firefox
    e.preventDefault();
    const commentObj = { comment: editNoteComment };
    setSubmitEdit(true);
    editComment(commentObj, commentId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to update comment.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchNoteComments(noteId);
        setCommentNumber(undefined);
        setIsEdit(false);
        setSubmitEdit(false);
        setEditNoteComment('');
      });
  };

  const handleOpenDeleteDialog = (comment) => {
    setIsDeleteDialogOpen(true);
    setCommentToDelete(comment);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <>
        <Name>{note.commentCount} Comments</Name>
        <div
          style={{
            margin: '10px 0 20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Initials
            width='50px'
            height='50px'
            fontSize='1.125rem'
            margin='0 10px 0 0'
          >
            {firstName.charAt(0).toUpperCase()}
            {lastName.charAt(0).toUpperCase()}
          </Initials>
          <form
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <InputWrapper margin='0 10px 0 0' width='100%'>
              <Input
                type='text'
                placeholder='Add Comment'
                value={comment || ''}
                onChange={(event) => setComment(event.target.value)}
                autoComplete='off'
                disabledBackground='transparent'
                disabled={isResolvedNote}
              />
            </InputWrapper>
            <TransparentButton
              margin='0 10px'
              display='flex'
              type='submit'
              onClick={(e) => handleAddComment(e)}
              disabled={comment === '' || isAdding}
            >
              Submit
              {isAdding && (
                <span style={{ marginLeft: '10px' }}>
                  <LoadSpinner size={20} />
                </span>
              )}
            </TransparentButton>
          </form>
        </div>
      </>

      {/* if is fetching note comments is true, show load spinner */}
      {isFetchingNoteComments && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {/* if show fetching is false and there are no comments show non ideal state */}
      {!isFetchingNoteComments && comments && comments.length === 0 && (
        <NonIdealStateComp
          title={'No comments found.'}
          description={'Add a new comment above.'}
        />
      )}

      {/* if show fetching is false and there are comments, show comments section */}
      {!isFetchingNoteComments && comments && comments.length > 0 && (
        <div style={{ marginTop: '15px', height: '330px', overflowY: 'auto' }}>
          {comments &&
            comments.map((comment) => (
              <div
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                }}
                // can't use short id here because it will render for each character typed, must be same id
                key={comment.commentId}
              >
                <div>
                  <Initials
                    width='50px'
                    height='50px'
                    fontSize='1.125rem'
                    margin='0 10px 0 0'
                  >
                    {comment.author.split(' ')[0].charAt(0).toUpperCase()}
                    {comment.author.split(' ')[1].charAt(0).toUpperCase()}
                  </Initials>
                </div>

                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex' }}>
                    <Name>{comment.author}</Name>
                    <BoldText
                      margin='0 0 0 10px'
                      fontSize='.85rem'
                      fontWeight='400'
                      color={`${colors.boldText}`}
                    >
                      {militaryToStandard(comment.updatedAt)}
                    </BoldText>
                  </div>

                  {/* if isEdit is false just show comment */}
                  {comment.commentId !== commentNumber && (
                    <MessageText margin='5px 10px'>
                      {comment.comment}
                    </MessageText>
                  )}

                  {/* if isEdit is true show input with comment and submit button */}
                  {isEdit && comment.commentId === commentNumber && (
                    <form
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <InputWrapper margin='0 10px 0 0' width='100%'>
                        <Input
                          type='text'
                          value={editNoteComment}
                          onChange={(event) =>
                            setEditNoteComment(event.target.value)
                          }
                          autoComplete='off'
                        />
                      </InputWrapper>
                      <TransparentButton
                        margin='0 10px'
                        display='flex'
                        type='submit'
                        onClick={(e) => handleEditComment(e, comment.commentId)}
                        disabled={submitEdit}
                      >
                        Submit
                        {submitEdit && (
                          <span style={{ marginLeft: '10px' }}>
                            <LoadSpinner size={20} />
                          </span>
                        )}
                      </TransparentButton>
                    </form>
                  )}

                  {!isResolvedNote && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* check if comment is made by user, or if user has correct permissions */}
                      {(userId === comment.authorId ||
                        checkPermissions(permList, [
                          permissions.EDIT_COMMENTS,
                        ])) && (
                        <ActionText onClick={() => handleShowInput(comment)}>
                          Edit
                        </ActionText>
                      )}
                      <Icon
                        icon={iconValue.dot}
                        size={16}
                        color={colors.primary}
                      />
                      {/* check if comment is made by user, or if user has correct permissions */}
                      {(userId === comment.authorId ||
                        checkPermissions(permList, [
                          permissions.DELETE_COMMENTS,
                        ])) && (
                        <ActionText
                          margin='5px'
                          onClick={() => handleOpenDeleteDialog(comment)}
                        >
                          Delete
                        </ActionText>
                      )}

                      {/* if edit is true, show cancel button for corresponding comment  */}
                      {isEdit && comment.commentId === commentNumber && (
                        <>
                          <Icon
                            icon={iconValue.dot}
                            size={16}
                            color={colors.primary}
                          />
                          <ActionText
                            margin='5px 0 5px 5px'
                            onClick={handleCancel}
                          >
                            Cancel
                          </ActionText>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          <DeleteCommentDialog
            isDeleteDialogOpen={isDeleteDialogOpen}
            isDeleting={isDeleting}
            handleCloseDeleteDialog={handleCloseDeleteDialog}
            commentToDelete={commentToDelete}
            setIsDeleting={setIsDeleting}
            fetchNoteComments={fetchNoteComments}
            fetchSingleNote={fetchSingleNote}
            noteId={noteId}
          />
        </div>
      )}
    </>
  );
};

const ViewNoteDialogBase = ({
  isViewNoteDialogOpen,
  title,
  handleCloseViewDialog,
  appUser,
  noteId,
  fetchNoteComments,
  isFetchingNoteComments,
  noteComments,
  fetchSingleNote,
  isFetchingSingleNote,
  note,
  isDashboard,
  clientId,
  accountId,
  batchId,
  isResolvedNote,
  fetchAccountNotes,
  fetchClientNotes,
  fetchBatchNotes,
  fetchAllNotes,
  setResetInitialFilter,
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  useEffect(() => {
    if (isViewNoteDialogOpen) fetchSingleNote(noteId);
    if (isViewNoteDialogOpen) fetchNoteComments(noteId);
  }, [noteId, isViewNoteDialogOpen, fetchNoteComments, fetchSingleNote]);

  return (
    <Dialog
      className='medium-dialog'
      isOpen={isViewNoteDialogOpen}
      title={title}
      onClose={handleCloseViewDialog}
      isCloseButtonShown={false}
    >
      <NoteWrapper>
        {isFetchingSingleNote && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '1rem',
            }}
          >
            <LoadSpinner size={100} />
          </div>
        )}
        {!isFetchingSingleNote && (
          <>
            <TopSection
              appUser={appUser}
              note={note}
              handleCloseViewDialog={handleCloseViewDialog}
              handleDialogOpen={handleDialogOpen}
              // needs to be passed to top section to then be passed to resolve dialog
              // it will be used to check if handleCloseViewDialog function needs to run
              isViewNoteDialogOpen={isViewNoteDialogOpen}
              isResolvedNote={isResolvedNote}
              clientId={clientId}
              accountId={accountId}
              batchId={batchId}
              isDashboard={isDashboard}
              setResetInitialFilter={setResetInitialFilter}
            />
            <MiddleSection
              note={note}
              noteComments={noteComments}
              isResolvedNote={isResolvedNote}
            />
            <CommentSection
              noteComments={noteComments}
              isFetchingNoteComments={isFetchingNoteComments}
              note={note}
              appUser={appUser}
              fetchNoteComments={fetchNoteComments}
              isResolvedNote={isResolvedNote}
              clientId={clientId}
              batchId={batchId}
              accountId={accountId}
              isDashboard={isDashboard}
              fetchAccountNotes={fetchAccountNotes}
              fetchAllNotes={fetchAllNotes}
              fetchClientNotes={fetchClientNotes}
              fetchBatchNotes={fetchBatchNotes}
              fetchSingleNote={fetchSingleNote}
              setResetInitialFilter={setResetInitialFilter}
            />
          </>
        )}
      </NoteWrapper>
      <NoteDialog
        title='Edit Note'
        isOpen={isEditDialogOpen}
        handleClose={handleEditDialogClose}
        note={note}
        clientId={clientId}
        accountId={accountId}
        batchId={batchId}
        isDashboard={isDashboard}
        setResetInitialFilter={setResetInitialFilter}
      />
    </Dialog>
  );
};

const NoteWrapper = styled.div`
  padding: 1.5rem;
`;

const Name = styled.p`
  font-size: 1.125rem;
  color: ${colors.boldText};
  margin-left: 8px;
  font-weight: 700;
  line-height: 24px;
`;

const Initials = styled.div`
  width: ${(props) => props.width || '60px'};
  height: ${(props) => props.height || '60px'};
  border-radius: 50%;
  background: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.fontSize || '1.75rem'};
  color: ${colors.white};
  margin: ${(props) => props.margin};
`;

const ActionText = styled.p`
  color: ${colors.primary};
  fontsize: 1.125rem;
  margin: ${(props) => props.margin || '5px 5px 5px 10px'};
  cursor: pointer;

  &:hover {
    color: ${colors.purpleHover};
    transition: 0.3s;
  }
  &:disabled {
    color: ${colors.greyDisabled};
    cursor: not-allowed;
  }
`;

const mapStateToProps = (state) => ({
  noteComments: state.notes.noteComments,
  isFetchingNoteComments: state.notes.isFetchingNoteComments,
  note: state.notes.singleNote,
  isFetchingSingleNote: state.notes.isFetchingSingleNote,
});

const ViewNoteDialog = connect(mapStateToProps, {
  fetchNoteComments,
  fetchSingleNote,
  fetchAllNotes,
  fetchClientNotes,
  fetchAccountNotes,
  fetchBatchNotes,
})(ViewNoteDialogBase);

export default ViewNoteDialog;
