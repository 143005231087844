import axios from 'axios';
import { setHeader } from './index';

const DASHBOARD_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/dashboard`;

export const fetchClientsForUser = async (userId) => {
  const request = await setHeader(axios);
  return request.get(`${DASHBOARD_API}/users/clients/${userId}`);
};

export const fetchReportsInProgress = async (userId) => {
  const request = await setHeader(axios);
  return request.get(`${DASHBOARD_API}/users/client-reports/${userId}`);
};

export const fetchNegotiatedRates = async () => {
  const request = await setHeader(axios);
  return request.get(`${DASHBOARD_API}/negotiated-rates`);
};

export const fetchSingleNegotiatedRate = async (rateId) => {
  const request = await setHeader(axios);
  return request.get(`${DASHBOARD_API}/negotiated-rates/${rateId}`);
};

export const createNegotiatedRate = async (negotiatedRates) => {
  const request = await setHeader(axios);
  return request.post(`${DASHBOARD_API}/negotiated-rates/`, {
    negotiatedRates,
  });
};

export const updateNegotiatedRate = async (negotiatedRates, rateId) => {
  const request = await setHeader(axios);
  return request.put(`${DASHBOARD_API}/negotiated-rates/${rateId}`, {
    negotiatedRates,
  });
};

export const deleteNegotiatedRate = async (rateId) => {
  const request = await setHeader(axios);
  return request.delete(`${DASHBOARD_API}/negotiated-rates/${rateId}`);
};

export const fetchMaturingInvestments = async (userId) => {
  const request = await setHeader(axios);
  return request.get(`${DASHBOARD_API}/users/maturing-investments/${userId}`);
};
