import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { MessageText } from '../../styled-components';
import { deactivateNoteCategories } from '../../../api/administration';
import AppToast from '../../Toast';
import DialogDeleteActions from '../DialogDeleteActions';

const NoteCategoriesDeactivateDialogBox = ({
  isOpen,
  handleClose,
  title,
  categoryIds,
  fetchAllNotes,
}) => {
  const [isDeactivating, setIsDeactivating] = useState(false);

  const handleDeactivateCategories = () => {
    setIsDeactivating(true);
    deactivateNoteCategories(categoryIds)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllNotes();
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to deactivate note category.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeactivating(false);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <MessageText>
          {'Are you sure you want to deactivate' +
            (categoryIds.length > 1
              ? ' these Note categories?'
              : ' this Note category?')}
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeactivating}
        deleteText='DEACTIVATE'
        handleClose={handleClose}
        handleDelete={handleDeactivateCategories}
      />
    </Dialog>
  );
};

export default NoteCategoriesDeactivateDialogBox;
