import React, { useState, useEffect } from 'react';
import {
  ComponentProfileBody,
  SecondarySubNavContainer,
  ComponentBody,
} from '../../../../../components/styled-components';
import { connect } from 'react-redux';
import {
  fetchAccount,
  fetchAccountCategories,
  fetchAccountTypes,
  fetchClientAccounts,
} from '../../../../../actions/accounts';
import {
  fetchAllClients,
  fetchAccountBalanceData,
  fetchClientBanks,
} from '../../../../../actions/clients';
import { updateAccount } from '../../../../../api/accounts';
import { fetchBankNames, fetchBankDefaultDataGatheringFrequencies } from '../../../../../actions/banks';
import find from 'lodash/find';
import AppToast from '../../../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import ClientHeader from '../../../../../components/headers/ClientHeader';
import AccountLink from './AccountLink/AccountLink';
import AccountNotes from './AccountNotes/AccountNotes';
import AccountInvestments from './AccountInvestments/AccountInvestments';
import AccountTransactions from './AccountTransactions/AccountTransactions';
import AccountGroups from './AccountGroups/AccountGroups';
import AccountDialogBox from '../../../../../components/dialog/client-profile-dialog/AccountDialogBox';
import AccountSubNavigation from '../../../../../components/navigation/SubNavigation/AccountSubNavigation';
import AccountInfoSection from '../../../../../components/accounts/account-profile/AccountInfoSection';
import LoadSpinner from '../../../../../components/LoadSpinner';
import { fetchTransactionKeywords } from '../../../../../actions/transactions';

const AccountProfileBase = ({
  appUser,
  account,
  categories,
  bankNames,
  types,
  clients,
  isFetching,
  isClientAccountsFetching,
  clientAccounts,
  accountGraph,
  isAccountGraphFetching,
  bankDefaultDataGatheringFrequencies,
  ...props
}) => {
  const [accountComponent, setAccountComponent] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [accountAttributes, setAccountAttributes] = useState({});
  const [isServerSidePagination, setIsServerSidePagination] = useState(false);

  const clientId = props.match.params.clientId;
  const accountId = props.match.params.accountId;

  const {
    fetchAccount,
    fetchAccountCategories,
    fetchBankNames,
    fetchAccountTypes,
    fetchAllClients,
    fetchTransactionKeywords,
    fetchClientAccounts,
    fetchAccountBalanceData,
    fetchBankDefaultDataGatheringFrequencies,
    fetchClientBanks,
  } = props;

  useEffect(() => {
    fetchTransactionKeywords();
    fetchAccountCategories();
    fetchBankNames();
    fetchAccountTypes();
    fetchAllClients();
    fetchClientAccounts(clientId);
    fetchAccountBalanceData(accountId);
    fetchBankDefaultDataGatheringFrequencies();
    fetchClientBanks(clientId);

    const handleAccountCheck = async () => {
      await fetchAccount(clientId, accountId).catch((err) => {
        props.history.push(`/client-management/${clientId}`);
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Account not found.',
          intent: Intent.DANGER,
        });
      });
    };

    handleAccountCheck();
  }, [
    accountId,
    clientId,
    fetchAccount,
    fetchAccountCategories,
    fetchAccountTypes,
    fetchAllClients,
    fetchBankNames,
    fetchClientAccounts,
    fetchTransactionKeywords,
    fetchAccountBalanceData,
    fetchBankDefaultDataGatheringFrequencies,
    fetchClientBanks,
    props.history,
  ]);

  const handleAccountComponentChange = (value) => {
    setAccountComponent(value);
    if (value === 3 || value === 4) setIsServerSidePagination(true);
    else setIsServerSidePagination(false);
  };

  const openEditAccountDialog = (attributes) => {
    setTitle('Edit Account');
    attributes.bankId = find(bankNames, ['label', attributes.bankName]).value;
    attributes.accountClosed = attributes.accountClosed === 'Yes' ? 1 : 0;
    setAccountAttributes(attributes);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setAccountAttributes({});
  };

  /**
   * Edits an account
   * @param {obj} values - account values
   */
  const handleEditAccount = async (values) => {
    const accountId = accountAttributes.accountId;
    const account = {
      accountId,
      clientId,
      ...values,
    };
    updateAccount(accountId, account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAccount(clientId, accountId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit account.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  const handleBackToAccountTable = () => {
    props.history.push({
      pathname: `/client-management/${clientId}`,
      state: 1,
    });
  };

  // get client name
  const client = clients.find((client) => {
    return client.clientId.toString() === clientId.toString();
  });

  const { open } = props;

  // in order to not flash blank account profile page while the fetch for the account occurs,
  // we need to show the spinner and either show the page or redirect (from useEffect)
  return (
    <>
      {isFetching && (
        <div>
          <LoadSpinner size={200} />
        </div>
      )}
      {!isFetching && (
        <ComponentBody padding='0'>
          <ClientHeader
            appUser={appUser}
            header={'Client'}
            clientName={client ? client.clientName : null}
            hasButton={true}
            buttonText={'Back to Accounts Table'}
            action={handleBackToAccountTable}
            {...props}
          />
          <ComponentProfileBody padding='11rem 2rem 0'>
            <AccountInfoSection
              appUser={appUser}
              account={account}
              openEditAccountDialog={openEditAccountDialog}
              clientId={clientId}
              fetchAccount={fetchAccount}
              accountGraph={accountGraph}
              isAccountGraphFetching={isAccountGraphFetching}
            />
          </ComponentProfileBody>
          <SecondarySubNavContainer className={open}>
            <AccountSubNavigation
              accountComponent={accountComponent}
              handleAccountComponentChange={handleAccountComponentChange}
              appUser={appUser}
            />
          </SecondarySubNavContainer>
          <ComponentProfileBody>
            {accountComponent === 0 && (
              <AccountLink
                clientId={clientId}
                accountId={accountId}
                appUser={appUser}
              />
            )}
            {accountComponent === 1 && (
              <AccountNotes
                appUser={appUser}
                accountId={accountId}
                {...props}
              />
            )}
            {accountComponent === 2 && (
              <AccountGroups
                clientId={clientId}
                accountId={accountId}
                appUser={appUser}
                {...props}
              />
            )}
            {accountComponent === 3 && (
              <AccountTransactions
                accountId={accountId}
                isServerSidePagination={isServerSidePagination}
                {...props}
              />
            )}
            {accountComponent === 4 && (
              <AccountInvestments
                appUser={appUser}
                accountId={accountId}
                accountComponent={accountComponent}
                isServerSidePagination={isServerSidePagination}
                {...props}
              />
            )}
          </ComponentProfileBody>
          <AccountDialogBox
            isOpen={isOpen}
            title={title}
            categories={categories}
            types={types}
            bankNames={bankNames}
            closeDialog={closeDialog}
            handleEditAccount={handleEditAccount}
            account={accountAttributes}
            bankDefaultDataGatheringFrequencies={bankDefaultDataGatheringFrequencies}
          />
        </ComponentBody>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients.allClients,
  categories: state.accounts.accountCategories,
  bankNames: state.banks.bankNames,
  types: state.accounts.accountTypes,
  account: state.accounts.account,
  isFetching: state.accounts.isAccountFetching,
  clientAccounts: state.accounts.clientAccounts,
  isClientAccountsFetching: state.accounts.isClientAccountsFetching,
  accountGraph: state.clients.accountGraph,
  isAccountGraphFetching: state.clients.isAccountGraphFetching,
  bankDefaultDataGatheringFrequencies: state.banks.bankDefaultDataGatheringFrequencies,
  clientBanks: state.clients.allClientBanks
});

const AccountProfile = connect(mapStateToProps, {
  fetchAccountCategories,
  fetchBankNames,
  fetchAccountTypes,
  fetchAccount,
  fetchAllClients,
  fetchTransactionKeywords,
  fetchClientAccounts,
  fetchAccountBalanceData,
  fetchBankDefaultDataGatheringFrequencies,
  fetchClientBanks
})(AccountProfileBase);

export default AccountProfile;
