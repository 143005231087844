import * as api from '../api/data-ingestion';

export const fetchOpenReports = () => ({
  type: 'FETCH_OPEN_REPORTS',
  payload: api.fetchOpenReports(),
});

export const fetchValidationHistory = (clientId) => ({
  type: 'FETCH_VALIDATION_HISTORY',
  payload: api.fetchValidationHistory(clientId),
});

export const fetchClientBatches = (clientId) => ({
  type: 'FETCH_CLIENT_BATCHES',
  payload: api.fetchBatchesForClient(clientId),
});

export const fetchBatchAccounts = (batchId) => ({
  type: 'FETCH_BATCH_ACCOUNTS',
  payload: api.fetchBatchAccounts(batchId),
});

export const fetchSingleBatchAccount = (batchAccountId) => ({
  type: 'FETCH_SINGLE_BATCH_ACCOUNT',
  payload: api.fetchSingleBatchAccount(batchAccountId),
});

export const fetchSingleClientBatch = (batchId) => ({
  type: 'FETCH_SINGLE_BATCH',
  payload: api.fetchSingleClientBatch(batchId),
});
