import React, { useMemo, useState, useCallback } from 'react';
import MakeTable from './components/MakeTable';
import { StyleColumnCell, ActionIconButton } from '../styled-components';
import SortableHeader from './components/SortableHeader';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import colors from '../../utils/colors';
import { checkPermissions, localDateFormatter } from '../../utils/functions';
import permissions from '../../utils/permissions';
import ValidateBatchDialogBox from '../../components/dialog/ValidateBatchDialogBox';
import {
  deleteBatch,
  validateBatch,
  fetchExtractionStatusForClient,
} from '../../api/data-ingestion';
import { connect } from 'react-redux';
import {
  fetchSingleBatchAccount,
  fetchBatchAccounts,
} from '../../actions/data-ingestion';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';
import BatchesDeleteDialog from '../../components/dialog/data-ingestion/BatchesDeleteDialog';
import useInterval from '../../utils/useInterval';
import NoteDialog from '../dialog/notes/NoteDialog';
import ViewNotesDialog from '../dialog/data-ingestion/ViewNotesDialog';
import { TableContainer } from './components/table-styled-components';

const BatchesTableBase = ({
  appUser,
  clientBatches,
  isClientBatchesFetching,
  clientName,
  fetchClientBatches,
  fetchSingleClientBatch,
  clientId,
  fetchBatchAccounts,
  isBatchAccountsFetching,
  batchAccounts,
  fetchSingleBatchAccount,
  handleOpenUploadDialog,
  handleOpenExtractionDialog,
  extractionFinished,
  extractionInProgress,
  extracting,
  ...props
}) => {
  //For validation dialog
  const [isValidateDialogOpen, setValidateDialogOpen] = useState(false);
  const [selectedBatchBank, setSelectedBatchBank] = useState('');
  const [selectedBatchGroup, setSelectedBatchGroup] = useState('');
  const [isDeletingBatch, setIsDeletingBatch] = useState(false);
  const [isBatchDeleteDialogEnabled, setIsBatchDeleteDialogEnabled] = useState(
    false
  );

  const [isAddNoteDialogOpen, setIsAddNoteDialogOpen] = useState(false);
  const [isViewNotesDialogOpen, setIsViewNotesDialogOpen] = useState(false);
  const [batch, setBatch] = useState({});

  const [selectedBatchId, setSelectedBatchId] = useState(undefined);
  const [templateType, setTemplateType] = useState('');
  const [isValidatingBatch, setIsValidatingBatch] = useState(false);
  const [isInvestment, setIsInvestment] = useState(false);
  const [isTreasuryServices, setIsTreasuryServices] = useState(false);

  const [previousBatches, setPreviousBatches] = useState([]);
  const pollingTime = 4000; //in ms

  //Custom hook which sets an interval, which runs every given amount of time (pollingTime)
  //Extracting flag indicates if something is currently being extracted
  useInterval(() => {
    if (extracting && !isValidateDialogOpen) {
      fetchExtractionStatusForClient(clientId).then((res) => {
        //how many files are being extracted
        const isExtractingCounter = res.data.reduce(
          (acc, batch) => acc + batch.inProgressCount,
          0
        );
        if (isExtractingCounter > 0) extractionInProgress();
        else extractionFinished();

        //we are tracking any changes on batches
        if (previousBatches.length > 0) {
          const updatedIds = [];
          //see what changed from the previous interval
          previousBatches.forEach((batch) => {
            const found = res.data.find(
              (resBatch) => resBatch.batchId === batch.batchId
            );
            //the id is not in the "in progress" batch anymore. It just finished extracting!
            if (!found) {
              updatedIds.push(batch.batchId);
              return;
            }
            //edge case
            const less = batch.inProgressCount > found.inProgressCount;
            if (less) updatedIds.push(batch.batchId);
          });

          // Refetch and update info when data is extracted
          if (updatedIds.length > 0) {
            updatedIds.forEach((batchId) => {
              fetchSingleClientBatch(batchId);
            });
          }
        }
        setPreviousBatches(res.data);
      });
    }
  }, pollingTime);

  /**
   * Fetches batch data and opens the validation dialog
   */
  const handleOpenValidationDialog = useCallback(
    (selectedBatch) => {
      setTemplateType(selectedBatch.templateType);
      setSelectedBatchId(selectedBatch.batchId);
      fetchBatchAccounts(selectedBatch.batchId);
      //Open the dialog while fetching - shows a spinner while the table data is being fetched
      setSelectedBatchBank(selectedBatch.bankName);
      setSelectedBatchGroup(selectedBatch.accountName);
      setValidateDialogOpen(true);
      selectedBatch.templateType === 'Investment'
        ? setIsInvestment(true)
        : setIsInvestment(false);
      selectedBatch.templateType === 'Treasury'
        ? setIsTreasuryServices(true)
        : setIsTreasuryServices(false);
    },
    [fetchBatchAccounts]
  );

  //Closes the validation dialog
  const handleCloseValidationDialog = () => {
    setValidateDialogOpen(false);
    setSelectedBatchBank('');
    setSelectedBatchGroup('');
    setSelectedBatchId(undefined);
    setTemplateType('');
  };

  //Deletes a batch
  const handleDeleteBatch = () => {
    setIsDeletingBatch(true);
    deleteBatch(selectedBatchId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Delete Batch.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleCloseConfirmation();
        handleCloseValidationDialog();
        setIsDeletingBatch(false);
        fetchClientBatches(clientId);
      });
  };

  //For delete batch confirmation dialog
  const handleEnableConfirmation = () => {
    setIsBatchDeleteDialogEnabled(true);
  };

  const handleCloseConfirmation = () => {
    setIsBatchDeleteDialogEnabled(false);
  };

  //Validates a batch
  const handleValidateBatch = () => {
    setIsValidatingBatch(true);
    validateBatch(selectedBatchId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to Validate the Batch.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleCloseValidationDialog();
        setIsValidatingBatch(false);
        fetchClientBatches(clientId);
      });
  };

  const handleAddNoteDialogOpen = (batch) => {
    setBatch(batch);
    setIsAddNoteDialogOpen(true);
  };

  const handleAddNoteDialogClose = () => {
    setBatch({});
    setIsAddNoteDialogOpen(false);
  };

  const handleViewNotesDialogOpen = (batch) => {
    setBatch(batch);
    setIsViewNotesDialogOpen(true);
  };

  const handleViewNotesDialogClose = () => {
    setBatch({});
    setIsViewNotesDialogOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank' small={true} />
        ),
        accessor: 'bankName',
        Cell: ({ cell: { value }, row }) => (
          <div style={{ display: 'flex' }}>
            {value}
            {row.original.hasNotes && (
              <Icon
                icon={iconValue.comment}
                iconSize={16}
                style={{ paddingLeft: '8px' }}
              />
            )}
          </div>
        ),
        sortType: 'caseInsensitiveSort',
        width: '5%',
        isVisible: true,
        filter: 'text',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Account/Group' small={true} />
        ),
        accessor: 'accountName',
        width: '7%',
        isVisible: true,
        filter: 'text',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Type' small={true} />
        ),
        accessor: 'templateType',
        sortType: 'caseInsensitiveSort',
        width: '7%',
        isVisible: true,
        filter: 'text',
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Created Date' small={true} />
        ),
        accessor: 'createdAt',
        width: '8%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        filter: 'dateRange',
        Cell: ({ cell: { value } }) => {
          return <span>{value && localDateFormatter(value)}</span>;
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='# of Files' small={true} />
        ),
        accessor: 'sourcesRequired',
        width: '5%',
        isVisible: true,
        filter: 'text',
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Validation Status'
            small={true}
          />
        ),
        accessor: 'status',
        width: '7%',
        filter: 'text',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={
                value === 'IN PROGRESS'
                  ? colors.green
                  : colors.greyPlaceholderText
              }
            >
              {value}
            </StyleColumnCell>
          );
        },
        isVisible: true,
      },
      {
        Header: () => <span style={{ fontSize: '1rem' }}>Actions</span>,
        id: 'actions',
        width: '2%',
        sticky: 'right',
        Cell: ({ row }) => (
          <Popover
            className='table-action-menu'
            interactionKind='click'
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            content={
              <Menu>
                {checkPermissions(appUser.permList, [
                  permissions.UPLOAD_FILES,
                ]) && (
                    <MenuItem
                      text='Upload Files'
                      onClick={() => handleOpenUploadDialog(row.original)}
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.VIEW_ACCOUNT_BATCHES,
                ]) && (
                    <MenuItem
                      text='Validate Uploads'
                      onClick={() =>
                        handleOpenValidationDialog(row.original, clientName)
                      }
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.VIEW_DATA_EXTRACTION_STATUS,
                ]) && (
                    <MenuItem
                      text='Extraction Progress'
                      onClick={() => handleOpenExtractionDialog(row.original)}
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {checkPermissions(appUser.permList, [
                  permissions.ADD_NOTES,
                ]) && (
                    <MenuItem
                      text='Add Note'
                      onClick={() => handleAddNoteDialogOpen(row.original)}
                      style={{ color: `${colors.purpleText}`, fontWeight: '700' }}
                    />
                  )}
                {row.original.hasNotes &&
                  checkPermissions(appUser.permList, [
                    permissions.VIEW_NOTES,
                  ]) && (
                    <MenuItem
                      text='View Notes'
                      onClick={() => handleViewNotesDialogOpen(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
              </Menu>
            }
          >
            <ActionIconButton>
              <Icon
                icon={iconValue.menu}
                iconSize={16}
                color={colors.primary}
              />
            </ActionIconButton>
          </Popover>
        ),

        isVisible: checkPermissions(appUser.permList, [
          permissions.UPLOAD_FILES,
          permissions.VALIDATE_BATCHES,
          permissions.VIEW_ACCOUNT_BATCHES,
          permissions.VIEW_DATA_EXTRACTION_STATUS,
          permissions.ADD_NOTES,
          permissions.VIEW_NOTES,
        ]),
      },
    ],
    [
      appUser,
      clientName,
      handleOpenValidationDialog,
      handleOpenUploadDialog,
      handleOpenExtractionDialog,
    ]
  );

  return (
    <>
      <BatchesDeleteDialog
        isOpen={isBatchDeleteDialogEnabled}
        title={'Delete Batch'}
        clientName={clientName}
        selectedBatchBank={selectedBatchBank}
        selectedBatchGroup={selectedBatchGroup}
        handleClose={handleCloseConfirmation}
        handleDelete={handleDeleteBatch}
        isDeleting={isDeletingBatch}
      />
      <ValidateBatchDialogBox
        batchId={selectedBatchId}
        clientName={clientName}
        bankName={selectedBatchBank}
        accountType={selectedBatchGroup}
        handleCloseValidationDialog={handleCloseValidationDialog}
        isValidateDialogOpen={isValidateDialogOpen}
        appUser={appUser}
        batchAccounts={batchAccounts}
        isFetchingBatchAccounts={isBatchAccountsFetching}
        handleEnableConfirmation={handleEnableConfirmation}
        templateType={templateType}
        fetchBatchAccounts={fetchBatchAccounts}
        selectedBatchId={selectedBatchId}
        handleValidateBatch={handleValidateBatch}
        isValidatingBatch={isValidatingBatch}
        fetchSingleBatchAccount={fetchSingleBatchAccount}
        isInvestment={isInvestment}
        isTreasuryServices={isTreasuryServices}
        isHybridAccount={templateType === 'Hybrid'}
        {...props}
      />
      <NoteDialog
        isOpen={isAddNoteDialogOpen}
        title={'Add Note'}
        appUser={appUser}
        templateId={batch.templateId}
        batchId={batch.batchId}
        handleClose={handleAddNoteDialogClose}
        setResetInitialFilter={() => { }}
      />
      <ViewNotesDialog
        isOpen={isViewNotesDialogOpen}
        batch={batch}
        appUser={appUser}
        title={`Validate Batch: ${batch.clientName} / ${batch.bankName} / ${batch.accountName}`}
        handleClose={handleViewNotesDialogClose}
      />
      <TableContainer>
        <MakeTable
          appUser={appUser}
          data={clientBatches}
          columns={columns}
          isFetching={isClientBatchesFetching}
          tableTitle='Batches Ready for Files'
          {...props}
        />
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  batchAccounts: state.dataIngestion.batchAccounts,
  isBatchAccountsFetching: state.dataIngestion.isBatchAccountsFetching,
});

const BatchesTable = connect(mapStateToProps, {
  fetchBatchAccounts,
  fetchSingleBatchAccount,
})(BatchesTableBase);
export default BatchesTable;
