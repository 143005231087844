import React from 'react';
import { Dialog } from '@blueprintjs/core';
import FileUpload from '../../data-ingestion/FileUpload';
import { RegularButton, WhiteButton } from '../../styled-components';
import colors from '../../../utils/colors';
import UploadFileTable from '../../table/UploadFileTable';
import LoadSpinner from '../../../components/LoadSpinner';
import { Intent } from '@blueprintjs/core';
import { RadioGroup, Radio } from '@blueprintjs/core';

const DataIngestionDialog = ({
  isOpen,
  closeDialog,
  handleDrop,
  handleUpload,
  uploadList,
  removeSingleFile,
  removeMultipleFiles,
  toggleAllowOverwrite,
  isUploading,
  accountName,
  bankName,
  clientName,
  source,
  progressPercentage,
  isHybridBatch,
  currentFileTemplateOption,
  handleChangeCurrentFileTemplateOption,
  ...props
}) => {
  const { appUser } = props;

  return (
    <>
      <Dialog
        className='large-dialog border-less-header'
        isOpen={isOpen}
        onClose={closeDialog}
        title={`Upload Batch: ${clientName} / ${bankName} / ${accountName}`}
        isCloseButtonShown={false}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '0 0 2rem 0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '45%',
            }}
          >
            <FileUpload
              uploadList={uploadList}
              handleUpload={handleUpload}
              handleDrop={handleDrop}
              styles={{ width: '100%', margin: '2rem 0' }}
            />
            {isHybridBatch && (
              <div style={{ width: '271px' }} className='radio-group'>
                <RadioGroup
                  label='Template Type:'
                  onChange={handleChangeCurrentFileTemplateOption}
                  selectedValue={currentFileTemplateOption}
                  inline={true}
                >
                  <Radio
                    label='Transaction'
                    value='Transaction'
                    style={{ marginRight: '20px' }}
                  />
                  <Radio label='Investment' value='Investment' />
                </RadioGroup>
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <UploadFileTable
              uploadList={uploadList}
              appUser={appUser}
              removeSingleFile={removeSingleFile}
              removeMultipleFiles={removeMultipleFiles}
              toggleAllowOverwrite={toggleAllowOverwrite}
              isHybridBatch={isHybridBatch}
              {...props}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <WhiteButton
                margin='10px 40px 10px 0'
                type='button'
                color={colors.red}
                hoverColor={colors.redHover}
                onClick={() => {
                  source.cancel('Operation canceled by the user.');
                  closeDialog();
                }}
              >
                CANCEL UPLOAD
              </WhiteButton>
              <RegularButton
                width='13rem'
                display='flex'
                onClick={handleUpload}
                disabled={isUploading || uploadList.length < 1}
              >
                UPLOAD FILES
                {isUploading && (
                  <span style={{ marginLeft: '10px' }}>
                    <LoadSpinner
                      size={20}
                      value={progressPercentage * 0.01}
                      intent={Intent.SUCCESS}
                    />
                  </span>
                )}
              </RegularButton>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DataIngestionDialog;
