import React from 'react';
import { NavItem, SubNavWrapper } from '../../styled-components';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

const InvestmentSubNav = ({
  handleCurrentComponentChange,
  currentComponent,
  appUser,
}) => {
  return (
    <>
      <SubNavWrapper>
        {checkPermissions(appUser.permList, [permissions.VIEW_INVESTMENTS]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(0)}
            className={currentComponent === 0 && 'active'}
          >
            All Investments
          </NavItem>
        )}
        {checkPermissions(appUser.permList, [
          permissions.VIEW_INVESTMENT_GROUPS,
        ]) && (
          <NavItem
            onClick={() => handleCurrentComponentChange(1)}
            className={currentComponent === 1 && 'active'}
          >
            Investment Groups
          </NavItem>
        )}
      </SubNavWrapper>
    </>
  );
};

export default InvestmentSubNav;
