import React from 'react';
import {
  ComponentHeader,
  HeaderText,
  MainSubNavContainer,
  WhiteButton,
} from '../styled-components';
import InvestmentSubNav from '../navigation/SubNavigation/InvestmentSubNavigation';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import QuandlData from './header-data/QuandlData';

const InvestmentHeader = ({
  appUser,
  header,
  hasSubNav,
  currentComponent,
  isInvestmentProfile,
  handleCurrentComponentChange,
  handleAddInvestmentsOpen,
  openAddInvestmentGroupDialog,
  handleEditDialogOpen,
  ...props
}) => {
  if (isInvestmentProfile) {
    handleCurrentComponentChange = (value) => {
      props.history.push({ pathname: '/investment-management', state: value });
    };
    currentComponent = 1;
  }

  const { open } = props;

  return (
    <>
      <ComponentHeader
        className={open}
        flexDirection='column'
        justifyContent='space-evenly'
      >
        <QuandlData {...props} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <HeaderText>{header}</HeaderText>
          {checkPermissions(appUser.permList, [
            permissions.ADD_INVESTMENT_GROUPS,
          ]) &&
            currentComponent === 1 &&
            !isInvestmentProfile && (
              <WhiteButton
                width='18rem'
                margin='0 3rem 0 0'
                onClick={openAddInvestmentGroupDialog}
              >
                Add Group
              </WhiteButton>
            )}
          {isInvestmentProfile && (
            <div style={{ display: 'flex' }}>
              <WhiteButton
                width='18rem'
                margin='0 1rem 0 0'
                className='investment-header-button'
                onClick={() =>
                  props.history.push({
                    pathname: '/investment-management',
                    state: 1,
                  })
                }
              >
                Back to Investment Groups
              </WhiteButton>
              {checkPermissions(appUser.permList, [
                permissions.EDIT_INVESTMENT_GROUPS,
              ]) && (
                <WhiteButton
                  width='18rem'
                  margin='0 1rem 0 0'
                  className='investment-header-button'
                  onClick={handleEditDialogOpen}
                >
                  Edit Group
                </WhiteButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.ADD_INVESTMENT_GROUP_ASSOCIATIONS,
              ]) && (
                <WhiteButton
                  width='18rem'
                  margin='0 1rem 0 0'
                  className='investment-header-button'
                  onClick={handleAddInvestmentsOpen}
                >
                  Add Investments
                </WhiteButton>
              )}
            </div>
          )}
        </div>
      </ComponentHeader>
      {hasSubNav && (
        <MainSubNavContainer className={open}>
          <InvestmentSubNav
            handleCurrentComponentChange={handleCurrentComponentChange}
            currentComponent={currentComponent}
            appUser={appUser}
          />
        </MainSubNavContainer>
      )}
    </>
  );
};

export default InvestmentHeader;
