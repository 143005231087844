import styled from 'styled-components';
import colors from '../../../utils/colors';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  background: ${colors.lightText};
  color: ${colors.boldText};
  font-weight: 700;
  margin-bottom: 0.5rem;
  padding: 20px;
  text-align: left;
`;

export const TableRow = styled.tr`
  width: 100%;
  border-bottom: 1px solid ${colors.grey};
  border-left: none;
  border-right: none;
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    background: ${colors.lightText};
  }
`;

export const TableData = styled.td`
  color: grey;
  padding: 20px;

  @media screen and (max-width: 1350px) {
    padding: 20px 10px;
  }
`;

export const TableContainer = styled.div`
  padding: ${(props) => props.padding || '5rem 3rem 4rem'};
  width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.minWidth};
`;

export const TableWrapper = styled.div`
  box-shadow: ${(props) => props.boxShadow || '0'};
  overflow-x: auto;
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid ${colors.lightText};
    background: #fff;
    box-shadow: 0 3px 10px ${colors.lightText};
  }

  table thead {
    font-weight: 400;
    font-size: 1.125rem;
  }

  table tr:nth-child(1) th:first-child {
    padding: 16px 20px;
    height: 100%;
  }

  table thead:nth-child(1) tr:nth-child(1) {
    color: ${colors.boldText};
    background: ${colors.grey};
    text-align: left;
    padding: 12px 0;
    font-weight: 700;
  }

  table th,
  table td {
    padding: 8px 20px;
    border-bottom: 1px solid ${colors.lightText};
  }

  .pagination {
    border-bottom: 0;
  }

  table th:last-child,
  table td:last-child {
    border-right: 0;
  }

  // for sticky table columns
  table .sticky tr th:last-child,
  table .sticky tr th:first-child {
    background: ${colors.grey};
  }

  // for sticky table columns
  table td:last-child,
  table td:first-child {
    background: ${colors.white};
  }

  tbody td {
    color: ${colors.normalText};
  }
`;

export const TableFilterWrapper = styled.div`
  height: 45px;
  .bp3-input-group {
    height: 100%;
    .bp3-icon-search {
      height: 100%;
      margin: 0;
      padding-left: 8px;
      display: flex;
      align-items: center;
      color: ${colors.lightText};
    }

    .bp3-input {
      height: 100%;
      -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
        0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1.5px rgba(16, 22, 26, 0.15),
        inset 0 1.5px 1.5px rgba(16, 22, 26, 0.2);
      -moz-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
        0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1.5px rgba(16, 22, 26, 0.15),
        inset 0 1.5px 1.5px rgba(16, 22, 26, 0.2);
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1.5px rgba(16, 22, 26, 0.15),
        inset 0 1.5px 1.5px rgba(16, 22, 26, 0.2);
    }

    .bp3-input-action {
      height: 100%;
      display: flex;
      color: ${colors.greyPlaceholderText};

      button {
        span {
          color: ${colors.lightText} !important;
        }
      }
    }
  }
`;
