import React, { useState, useEffect } from 'react';
import { Dialog } from '@blueprintjs/core';
import MultiSelectField from '../../multi-select-fields/MultiSelectField';
import NumberRangeField from '../../select-fields/NumberRangeField';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import {
  dateToFilterText,
  numberRangeToFilterText,
  multiSelectToFilterText,
  dateRangeChecker,
  amountToFilterText,
} from '../../../utils/functions';
import TableFilterActions from './TableFilterActions';

const InvestmentGroupsFilterDialogBox = ({
  isOpen,
  title,
  handleClose,
  setFilter,
  setAllFilters,
  initialData,
  setFilterSummary,
}) => {
  const [groupNames, setGroupNames] = useState([]);
  const [countInvestmentLow, setCountInvestmentLow] = useState('');
  const [countInvestmentHigh, setCountInvestmentHigh] = useState('');
  const [settlementAmountLow, setSettlementAmountLow] = useState('');
  const [settlementAmountHigh, setSettlementAmountHigh] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [groupNameItems, setGroupsNameItems] = useState([]);

  useEffect(() => {
    if (initialData.length) {
      setGroupsNameItems(
        initialData
          .map((item) => item.groupName)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .map((item) => ({
            label: item,
            value: item,
          }))
      );
    }
  }, [initialData]);

  const getFilterSummary = () => {
    const filters = [];
    groupNames.length &&
      filters.push(multiSelectToFilterText(groupNames, 'Group Name'));
    (countInvestmentLow || countInvestmentHigh) &&
      filters.push(
        numberRangeToFilterText(
          countInvestmentLow,
          countInvestmentHigh,
          'Investment Count'
        )
      );
    (settlementAmountLow || settlementAmountHigh) &&
      filters.push(
        amountToFilterText(
          settlementAmountLow,
          settlementAmountHigh,
          'Settlement Amount'
        )
      );
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Updated At'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('groupName', groupNames.length ? groupNames : undefined);
    setFilter(
      'countInvestments',
      !countInvestmentLow && !countInvestmentHigh
        ? undefined
        : [countInvestmentLow || undefined, countInvestmentHigh || undefined]
    );
    setFilter(
      'settlementAmountSum',
      !settlementAmountLow && !settlementAmountHigh
        ? undefined
        : [settlementAmountLow || undefined, settlementAmountHigh || undefined]
    );
    setFilter('updatedAt', dateRangeChecker(dateRange) ? dateRange : undefined);
    handleClose();
  };

  const handleClearFilters = () => {
    setCountInvestmentHigh('');
    setCountInvestmentLow('');
    setSettlementAmountLow('');
    setSettlementAmountHigh('');
    setDateRange([]);
    setGroupNames([]);
    setAllFilters([]);
    setFilterSummary([]);
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '2rem 3rem 0' }}>
        <div style={{ marginBottom: '20px' }}>
          <NumberRangeField
            labelName='Investment Count'
            selectLow={setCountInvestmentLow}
            selectHigh={setCountInvestmentHigh}
            high={countInvestmentHigh}
            low={countInvestmentLow}
            isCurrency={false}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <NumberRangeField
            labelName='Settlement Amount'
            selectLow={setSettlementAmountLow}
            selectHigh={setSettlementAmountHigh}
            low={settlementAmountLow}
            high={settlementAmountHigh}
            isCurrency={true}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <DateSelectPlainField
            labelName='Updated At'
            dateRange={dateRange}
            handleDateChange={setDateRange}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <MultiSelectField
            setSelectedItems={setGroupNames}
            items={groupNameItems}
            selectedItems={groupNames}
            label='Group Names'
          />
        </div>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default InvestmentGroupsFilterDialogBox;
