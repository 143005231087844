import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  ComponentBody,
  ComponentProfileBody,
} from '../../../../components/styled-components';
import ClientHeader from '../../../../components/headers/ClientHeader';
import { fetchClientInvestmentBalance } from '../../../../actions/investments';
import { fetchClientInvestmentPaginatedData } from '../../../../api/investments';
import InvestmentTable from '../../../../components/table/InvestmentTable';
import InvestmentSummary from '../../../../components/investment-summary/InvestmentSummary';
import { fetchClientInterestRate } from '../../../../actions/clients';

const InvestmentsBase = ({
  appUser,
  singleClient,
  clientId,
  isFetching,
  maturityLimit,
  fromMaturingInvestmentsWidget,
  isClientInvestments,
  investmentClientBalance,
  isInvestmentClientGraphFetching,
  amountsInvested,
  isAmountsInvestedFetching,
  currentComponent,
  fetchClientInterestRate,
  interestRate,
  isFetchingInterestRate,
  isServerSidePagination,
  ...props
}) => {
  const { fetchClientInvestmentBalance } = props;

  // pagination info
  // pageSize and pageIndex used to refetch data after edit/delete
  const [pageSize] = useState(50);
  const [pageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(undefined);
  const [resultCount, setResultCount] = useState(undefined);
  const [investments, setInvestments] = useState([]);
  const [isPaginatedDataFetching, setIsPaginatedDataFetching] = useState(false);

  // for server side pagination. only call this function if needed so use hook useCallback
  const updatePaginate = useCallback(
    (
      { pageSize, pageIndex },
      filters = [],
      searchTerm = '',
      sortOrder = {}
    ) => {
      setIsPaginatedDataFetching(true);
      fetchClientInvestmentPaginatedData(
        singleClient.clientId,
        pageSize,
        pageIndex,
        filters,
        searchTerm,
        sortOrder
      )
        .then((res) => {
          setInvestments(res.data.results);
          setPageCount(res.data.pageCount);
          setResultCount(res.data.resultCount);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsPaginatedDataFetching(false));
    },
    [singleClient.clientId]
  );

  useEffect(() => {
    fetchClientInvestmentBalance(singleClient.clientId);
    fetchClientInterestRate(singleClient.clientId);
  }, [
    fetchClientInvestmentBalance,
    fetchClientInterestRate,
    singleClient.clientId,
  ]);

  return (
    <ComponentBody padding='0'>
      <ClientHeader
        appUser={appUser}
        header={'Client'}
        clientName={singleClient.clientName}
        {...props}
      />
      <ComponentProfileBody padding='14rem 2rem 4rem'>
        <InvestmentSummary
          appUser={appUser}
          clientId={clientId}
          investmentClientBalance={investmentClientBalance}
          isInvestmentClientGraphFetching={isInvestmentClientGraphFetching}
          amountsInvested={amountsInvested}
          isAmountsInvestedFetching={isAmountsInvestedFetching}
          currentComponent={currentComponent}
          interestRate={interestRate}
          isFetchingInterestRate={isFetchingInterestRate}
          {...props}
        />
        <InvestmentTable
          appUser={appUser}
          investments={investments}
          isAllInvestmentsFetching={isPaginatedDataFetching}
          title='Client Investments'
          client={singleClient}
          isCurrentInvestments={true}
          showUsersClients={fromMaturingInvestmentsWidget}
          fromMaturingInvestmentsWidget={fromMaturingInvestmentsWidget}
          maturityLimit={maturityLimit}
          singleClient={singleClient}
          isClientInvestments={isClientInvestments}
          updatePaginate={updatePaginate}
          pageCount={pageCount}
          resultCount={resultCount}
          pageSize={pageSize}
          pageIndex={pageIndex}
          isServerSidePagination={isServerSidePagination}
          {...props}
        />
      </ComponentProfileBody>
    </ComponentBody>
  );
};

const mapStateToProps = (state) => ({
  investmentClientBalance: state.investments.investmentClientBalance,
  isInvestmentClientGraphFetching:
    state.investments.isInvestmentClientGraphFetching,
  interestRate: state.clients.interestRate,
  isFetchingInterestRate: state.clients.isInterestRateFetching,
});

const Investments = connect(mapStateToProps, {
  fetchClientInvestmentBalance,
  fetchClientInterestRate,
})(InvestmentsBase);

export default Investments;
