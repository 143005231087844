import React from 'react';
import { Header, Text, Data, Number } from './styled-components';

const FederalFundsRange = ({ quandlData }) => {
  return (
    <div style={{ flexBasis: '160px', flexGrow: 1, marginRight: '10px' }}>
      <div style={{ borderBottom: '1px solid white', marginBottom: '5px' }}>
        <Header>Federal Funds Range</Header>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Data>
          {quandlData && quandlData.length !== 0 && (
            <Number>{quandlData[0].federalFund[0]}</Number>
          )}
          <Text>Daily-Lower Limit</Text>
        </Data>
        <Data>
          {quandlData && quandlData.length !== 0 && (
            <Number>{quandlData[0].federalFund[1]}</Number>
          )}
          <Text>Daily-Upper Limit</Text>
        </Data>
      </div>
    </div>
  );
};

export default FederalFundsRange;
