import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BankClientRelationshipTable from '../../../../../components/table/BankClientRelationshipTable';
import BankClientRelationshipDialog from '../../../../../components/dialog/client-profile-dialog/BankClientRelationshipDialog';
import { fetchClientBanks } from '../../../../../actions/clients';
import { updateClientBank } from '../../../../../api/clients';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../../../../components/Toast';
import { updateTemplate, fetchTemplate } from '../../../../../api/templates';
import TransactionTemplateDialog from '../../../../../components/dialog/template-dialog/TransactionTemplateDialog';
import InvestmentTemplateDialog from '../../../../../components/dialog/template-dialog/InvestmentTemplateDialog';
import TreasuryTemplateDialog from '../../../../../components/dialog/template-dialog/TreasuryTemplateDialog';
import StartIngestionDialog from '../../../../../components/dialog/client-profile-dialog/StartIngestionDialog';
import {
  handleIngestionTransactionsClient,
  handleIngestionTransactionsSingleBank,
  handleIngestionTreasuryServicesClient,
  handleIngestionTreasuryServicesSingleBank,
  handleIngestionInvestmentsClient,
  handleIngestionInvestmentsSingleBank,
  handleIngestionHybridAccountClient,
  handleIngestionHybridAccountSingleBank
} from './startIngestion'

const BanksBase = ({
  banks,
  fetchClientBanks,
  isFetchingClientBanks,
  clientReportStatus,
  singleClient,
  ...props
}) => {
  const [isEditBankDialogOpen, setIsEditBankDialogOpen] = useState(false);
  //Templates Enable dialogs
  const [isInvestmentTemplateOpen, setIsInvestmentTemplateOpen] = useState(
    false
  );
  const [isTransactionTemplateOpen, setIsTransactionTemplateOpen] = useState(
    false
  );
  const [isTreasuryTemplateOpen, setIsTreasuryTemplateOpen] = useState(false);
  const [status, setStatus] = useState(1);
  const [collectionType, setCollectionType] = useState(false);
  const [clientBankRelationshipId, setClientBankRelationshipId] = useState(
    undefined
  );
  const [template, setTemplate] = useState({});
  const [templateId, setTemplateId] = useState(undefined);
  const [isStartIngestionDialogOpen, setIsStartIngestionDialogOpen] = useState(false)

  const id = props.match.params.id;
  useEffect(() => {
    fetchClientBanks(id);
  }, [fetchClientBanks, id]);

  const handleEditBankDialogOpen = (clientBank) => {
    setClientBankRelationshipId(clientBank.bankClientRelId);
    setStatus(clientBank.status === 'active' ? 1 : 0);
    setCollectionType(clientBank.collectionType);
    setIsEditBankDialogOpen(true);
  };

  const closeDialog = () => {
    setIsEditBankDialogOpen(false);
    setTemplate({});
    setClientBankRelationshipId(undefined);
    setTemplateId(undefined);
    setIsTransactionTemplateOpen(false);
    setIsInvestmentTemplateOpen(false);
    setIsTreasuryTemplateOpen(false);
    setIsStartIngestionDialogOpen(false)
  };

  const handleEditClientBankRelationship = (newStatus, newCollectionType) => {
    const bankClientRel = {
      status: newStatus,
      collectionType: newCollectionType
    };
    updateClientBank(clientBankRelationshipId, bankClientRel)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchClientBanks(id);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit status.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsEditBankDialogOpen(false);
      });
  };

  // opens template with appropriate values if template already exists
  const handleOpenTemplate = (clientBank, type) => {
    fetchTemplate(clientBank.bankClientRelId, type)
      .then((res) => {
        setTemplate(res.data.length ? res.data[0].jsonTransform[0] : {});
        setTemplateId(res.data.length ? res.data[0].templateId : undefined);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to load template.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        if (type === 'Transaction') setIsTransactionTemplateOpen(true);
        else if (type === 'Investment') setIsInvestmentTemplateOpen(true);
        else if (type === 'Treasury') setIsTreasuryTemplateOpen(true);
        setClientBankRelationshipId(clientBank.bankClientRelId);
      });
  };

  /**
   * Submits a template info.
   * Depending on the template the selected values submitted are different
   * Runs on formik submit
   * @param {Obj} values - Object with the fields
   * @param {string} type - the type of the template to submit [Transaction, Analysis, Investment, Balance]
   */
  const handleSubmitTemplate = (values, type) => {
    let template = {};
    let copiedValues = Object.assign({}, values);

    // IF account number comes from file name, transform value to send to backend
    // need to change fileNameIncluded from true to 1
    // copy value, and set new value 1 and rename variable for backend to fileNameIncluded
    // ELSE, if account number is present in values delete fileNameIncluded and send rest of values.
    // same concept for special case variable
    if (values.fileNameIncluded === true && values.specialCase === true) {
      let fileNameVariable = copiedValues.fileNameIncluded;
      let specialCaseVariable = copiedValues.specialCase;
      fileNameVariable = 1;
      specialCaseVariable = 1;
      const fileNameIncluded = fileNameVariable;
      const specialCase = specialCaseVariable;
      copiedValues = { ...copiedValues, fileNameIncluded, specialCase };
      template = {
        bankClientRelId: clientBankRelationshipId,
        type: type,
        jsonTransform: [
          {
            ...copiedValues,
          },
        ],
      };
    } else if (values.fileNameIncluded === true) {
      let fileNameVariable = copiedValues.fileNameIncluded;
      fileNameVariable = 1;
      const fileNameIncluded = fileNameVariable;
      copiedValues = { ...copiedValues, fileNameIncluded };
      delete copiedValues.specialCase;
      template = {
        bankClientRelId: clientBankRelationshipId,
        type: type,
        jsonTransform: [
          {
            ...copiedValues,
          },
        ],
      };
    } else if (values.specialCase === true) {
      let specialCaseVariable = copiedValues.specialCase;
      specialCaseVariable = 1;
      const specialCase = specialCaseVariable;
      copiedValues = { ...copiedValues, specialCase };
      delete copiedValues.fileNameIncluded;
      template = {
        bankClientRelId: clientBankRelationshipId,
        type: type,
        jsonTransform: [
          {
            ...copiedValues,
          },
        ],
      };
    } else {
      delete copiedValues.fileNameIncluded;
      delete copiedValues.specialCase;
      template = {
        bankClientRelId: clientBankRelationshipId,
        type: type,
        jsonTransform: [
          {
            ...copiedValues,
          },
        ],
      };
    }
    updateTemplate(template)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit template.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  const handleStartIngestionForBank = (row) => {
    setClientBankRelationshipId(row.bankClientRelId)
    setIsStartIngestionDialogOpen(true)
  }


  const handleStartIngestion = (data) => {
    const singleBank = clientBankRelationshipId ? true : false
    const { startDate, endDate, accountTypes } = data
    const batch = { startDate, endDate }

    if (accountTypes.transactions) {
      singleBank
        ? handleIngestionTransactionsSingleBank(clientBankRelationshipId, batch)
        : handleIngestionTransactionsClient(singleClient.clientId, batch)
    }
    if (accountTypes.treasuryServices) {
      singleBank
        ? handleIngestionTreasuryServicesSingleBank(clientBankRelationshipId, batch)
        : handleIngestionTreasuryServicesClient(singleClient.clientId, batch)
    }
    if (accountTypes.investments) {
      singleBank
        ? handleIngestionInvestmentsSingleBank(clientBankRelationshipId, batch)
        : handleIngestionInvestmentsClient(singleClient.clientId, batch)
    }
    if (accountTypes.hybridAccount) {
      singleBank
        ? handleIngestionHybridAccountSingleBank(clientBankRelationshipId, batch)
        : handleIngestionHybridAccountClient(singleClient.clientId, batch)
    }
    setClientBankRelationshipId(undefined)
    closeDialog()
  }

  const { appUser } = props;

  return (
    <>
      {clientReportStatus && (
        <BankClientRelationshipTable
          banks={banks}
          isFetching={isFetchingClientBanks}
          handleEditBankDialogOpen={handleEditBankDialogOpen}
          handleOpenTemplate={handleOpenTemplate}
          clientReportStatus={clientReportStatus}
          handleStartIngestionForBank={handleStartIngestionForBank}
          handleStartIngestionDialogOpen={setIsStartIngestionDialogOpen}
          {...props}
        />
      )}
      <BankClientRelationshipDialog
        isOpen={isEditBankDialogOpen}
        status={status}
        collectionType={collectionType}
        closeDialog={closeDialog}
        handleEditClientBankRelationship={handleEditClientBankRelationship}
      />
      <InvestmentTemplateDialog
        isInvestmentTemplateOpen={isInvestmentTemplateOpen}
        closeDialog={closeDialog}
        handleSubmitTemplate={handleSubmitTemplate}
        template={template}
        templateId={templateId}
        appUser={appUser}
      />
      <TransactionTemplateDialog
        isOpen={isTransactionTemplateOpen}
        closeDialog={closeDialog}
        handleSubmitTemplate={handleSubmitTemplate}
        template={template}
        templateId={templateId}
        appUser={appUser}
      />
      <TreasuryTemplateDialog
        isOpen={isTreasuryTemplateOpen}
        closeDialog={closeDialog}
        handleSubmitTemplate={handleSubmitTemplate}
        template={template}
        templateId={templateId}
        appUser={appUser}
      />
      <StartIngestionDialog
        isOpen={isStartIngestionDialogOpen}
        closeDialog={closeDialog}
        handleStartIngestion={handleStartIngestion}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  banks: state.clients.allClientBanks,
  isFetchingClientBanks: state.clients.isFetchingClientBanks,
});

const Banks = connect(mapStateToProps, { fetchClientBanks })(BanksBase);

export default Banks;
