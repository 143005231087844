import React, { useEffect, useState } from 'react';
import GlobalSettingsHeader from '../../components/headers/GlobalSettingsHeader';
import {
  ComponentBody,
  ResponsiveCenter,
} from '../../components/styled-components';
import IngestionUploadDate from '../../components/global-settings/IngestionUploadDate';
import MaturingDateRange from '../../components/global-settings/MaturingDateRange';
import ManageKeywords from '../../components/global-settings/ManageKeywords';
import ManageKeywordCategories from '../../components/global-settings/ManageKeywordCategories';
import ManageNoteCategories from '../../components/global-settings/ManageNoteCategories';
import ManageQuoteTypes from '../../components/global-settings/ManageQuoteTypes';
import {
  fetchAllKeywords,
  fetchUploadDate,
  fetchMaturityLimit,
  fetchKeywordCategories,
  fetchSingleKeyword,
  fetchAllNotes,
  fetchSingleKeywordCategory,
  fetchSingleNoteCategory,
  fetchAllQuoteTypes,
  fetchSingleQuoteType,
  fetchInvestmentTypes,
} from '../../actions/administration';
import {
  fetchAllTemplates,
  fetchSingleTemplate,
} from '../../actions/templates';
import { updateTemplate, deleteTemplate } from '../../api/templates';
import { connect } from 'react-redux';
import ManageInvestmentTypes from '../../components/global-settings/ManageInvestmentTypes';
import NotesTable from '../../components/table/NotesTable';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import { fetchResolveNotes } from '../../actions/notes';
import TemplateTable from '../../components/table/TemplateTable';
import TransactionTemplateDialog from '../../components/dialog/template-dialog/TransactionTemplateDialog';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../components/Toast';

const GlobalSettingsBase = ({
  appUser,
  keywords,
  isKeywordsFetching,
  fetchAllKeywords,
  fetchUploadDate,
  fetchMaturityLimit,
  uploadDate,
  isUploadDateFetching,
  maturityLimit,
  isMaturityLimitFetching,
  fetchKeywordCategories,
  keywordCategories,
  isKeywordCategoriesFetching,
  fetchSingleKeyword,
  fetchAllNotes,
  fetchSingleNoteCategory,
  noteCategories,
  isNoteCategoriesFetching,
  fetchSingleKeywordCategory,
  fetchAllQuoteTypes,
  fetchSingleQuoteType,
  quoteTypes,
  isQuoteTypesFetching,
  fetchInvestmentTypes,
  investmentTypes,
  isInvestmentTypesFetching,
  resolveNotes,
  isFetchingResolveNotes,
  fetchResolveNotes,
  fetchAllTemplates,
  templates,
  isTemplatesFetching,
  fetchSingleTemplate,
  isSingleTemplateFetching,
  singleTemplate,
  ...props
}) => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const [template, setTemplate] = useState({});
  const [templateId, setTemplateId] = useState(undefined);
  const [isTransactionTemplateOpen, setIsTransactionTemplateOpen] = useState(
    false
  );
  const [isAddFirstCellTemplate, setIsAddFirstCellTemplate] = useState(false);
  const [isTemplateWarningOpen, setIsTemplateWarningOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    if (currentComponent === 0) {
      fetchAllKeywords();
      fetchKeywordCategories();
      fetchUploadDate();
    } else if (currentComponent === 1) {
      fetchAllNotes();
      fetchResolveNotes();
    } else if (currentComponent === 2) {
      fetchMaturityLimit();
      fetchInvestmentTypes();
    } else if (currentComponent === 3) {
      fetchAllQuoteTypes();
    } else if (currentComponent === 4) {
      fetchAllTemplates();
    }
  }, [
    fetchAllKeywords,
    fetchUploadDate,
    fetchMaturityLimit,
    fetchKeywordCategories,
    fetchAllNotes,
    fetchAllQuoteTypes,
    fetchInvestmentTypes,
    fetchResolveNotes,
    fetchAllTemplates,
    currentComponent,
  ]);

  const closeDialog = () => {
    setIsTemplateWarningOpen(false);
    setIsTransactionTemplateOpen(false);
    setTemplate({});
    setTemplateId(undefined);
    setIsAddFirstCellTemplate(false);
    setShowCancel(false);
  };

  // fetch single template info from global settings template table for edit/view
  const handleEditTemplate = (selectedRow) => {
    setIsTransactionTemplateOpen(true);
    fetchSingleTemplate(selectedRow.templateId)
      .then((res) => {
        setTemplate(res.value.data);
        setTemplateId(res.value.data.templateId);
      })
      .catch((err) => {
        setIsTransactionTemplateOpen(false);
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to load template.',
          intent: Intent.DANGER,
        });
      });
  };

  /**
   * Submits a template info.
   * Depending on the template the selected values submitted are different
   * Runs on formik submit
   * @param {Obj} values - Object with the fields
   * @param {string} type - the type of the template to submit [Header, Cell]
   */
  const handleSubmitTemplate = (values, type) => {
    let template = {};
    let copiedValues = Object.assign({}, values);

    // for user using the add first cell button in table
    // we know the type will always come into this function as undefined
    // set to cell
    if (type === undefined) {
      type = 'Cell';
    }

    // if type is Header remove cellHeaderData (first cell text variable)
    // this if check must be before defining cellHeaderData and delete copiedValues.cellHeaderData
    if (type === 'Header') {
      delete copiedValues.cellHeaderData;
    }

    // if the type is 'Cell' (can either be Header or Cell) create new variable to add to template (object sent to backend)
    const cellHeaderData = copiedValues.cellHeaderData;
    // and remove from jsonTransform
    delete copiedValues.cellHeaderData;

    // IF account number comes from file name, transform value to send to backend
    // need to change fileNameIncluded from true to 1
    // copy value, and set new value 1 and rename variable for backend to fileNameIncluded
    // ELSE, if account number is present in values delete fileNameIncluded and send rest of values.
    // same concept for special case variable
    if (values.fileNameIncluded === true && values.specialCase === true) {
      let fileNameVariable = copiedValues.fileNameIncluded;
      let specialCaseVariable = copiedValues.specialCase;
      fileNameVariable = 1;
      specialCaseVariable = 1;
      const fileNameIncluded = fileNameVariable;
      const specialCase = specialCaseVariable;
      copiedValues = { ...copiedValues, fileNameIncluded, specialCase };
      // if type is Header don't send cellHeaderData to backend
      if (type === 'Header') {
        template = {
          templateId,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          templateId,
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    } else if (values.fileNameIncluded === true) {
      let fileNameVariable = copiedValues.fileNameIncluded;
      fileNameVariable = 1;
      const fileNameIncluded = fileNameVariable;
      copiedValues = { ...copiedValues, fileNameIncluded };
      delete copiedValues.specialCase;
      if (type === 'Header') {
        template = {
          templateId,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          templateId,
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    } else if (values.specialCase === true) {
      let specialCaseVariable = copiedValues.specialCase;
      specialCaseVariable = 1;
      const specialCase = specialCaseVariable;
      copiedValues = { ...copiedValues, specialCase };
      delete copiedValues.fileNameIncluded;
      if (type === 'Header') {
        template = {
          templateId,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          templateId,
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    } else {
      delete copiedValues.fileNameIncluded;
      delete copiedValues.specialCase;
      if (type === 'Header') {
        template = {
          templateId,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
      if (type === 'Cell') {
        template = {
          templateId,
          cellHeaderData,
          type: type,
          jsonTransform: [
            {
              ...copiedValues,
            },
          ],
        };
      }
    }
    updateTemplate(template)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit template.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        // if first cell button is clicked user is adding a template so fetch all templates
        isAddFirstCellTemplate
          ? fetchAllTemplates()
          : fetchSingleTemplate(templateId);
        closeDialog();
      });
  };

  // for add first cell template button
  const handleOpenTemplate = () => {
    setIsTransactionTemplateOpen(true);
    setIsAddFirstCellTemplate(true);
  };

  // Functions for the delete confirmation modal
  // spot argument is passed from the template delete function  within the actual template when the user views/edits.
  // if delete happens from inside template, the template argument below will be the actual templateId
  // otherwise if user deletes template from table action, the template argument wil be from the row in the table and be template.templateId
  const handleWarningTemplateOpen = (template, spot) => {
    setIsTemplateWarningOpen(true);
    if (spot === 'fromTemplate') {
      setTemplate(template);
    } else setTemplateId(template.templateId);
    if (spot === 'table') {
      setShowCancel(true);
    }
  };

  const handleWarningTemplateClose = () => {
    setIsTemplateWarningOpen(false);
    setTemplateId(undefined);
    setShowCancel(false);
  };

  const handleDeleteTemplate = () => {
    setIsDeleting(true);
    deleteTemplate(templateId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete template.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        closeDialog();
        fetchAllTemplates();
      });
  };

  return (
    <>
      <GlobalSettingsHeader
        header='Global Settings'
        handleCurrentComponentChange={setCurrentComponent}
        currentComponent={currentComponent}
        appUser={appUser}
        {...props}
      />
      <ComponentBody padding='14rem 8rem 4rem'>
        {currentComponent === 0 && (
          <>
            <ManageKeywords
              appUser={appUser}
              keywords={keywords}
              isFetching={isKeywordsFetching}
              fetchAllKeywords={fetchAllKeywords}
              fetchSingleKeyword={fetchSingleKeyword}
              {...props}
            />
            <ManageKeywordCategories
              appUser={appUser}
              keywordCategories={keywordCategories}
              isFetching={isKeywordCategoriesFetching}
              fetchKeywordCategories={fetchKeywordCategories}
              fetchSingleKeywordCategory={fetchSingleKeywordCategory}
              {...props}
            />
            <IngestionUploadDate
              dateLimit={uploadDate[0]}
              isFetching={isUploadDateFetching}
              fetchUploadDate={fetchUploadDate}
              {...props}
            />
          </>
        )}
        {currentComponent === 1 && (
          <>
            <ManageNoteCategories
              appUser={appUser}
              noteCategories={noteCategories}
              isFetching={isNoteCategoriesFetching}
              fetchAllNotes={fetchAllNotes}
              fetchSingleNoteCategory={fetchSingleNoteCategory}
              {...props}
            />
            {checkPermissions(appUser.permList, [
              permissions.VIEW_RESOLVED_NOTES,
            ]) && (
                <ResponsiveCenter className='global-setting' minWidth='765px'>
                  <NotesTable
                    appUser={appUser}
                    notes={resolveNotes}
                    isFetching={isFetchingResolveNotes}
                    tableTitle='Resolved Notes'
                    {...props}
                  />
                </ResponsiveCenter>
              )}
          </>
        )}
        {currentComponent === 2 && (
          <>
            <ManageInvestmentTypes
              appUser={appUser}
              investmentTypes={investmentTypes}
              isFetching={isInvestmentTypesFetching}
              fetchInvestmentTypes={fetchInvestmentTypes}
              {...props}
            />
            <MaturingDateRange
              maturityLimit={maturityLimit[0]}
              isFetching={isMaturityLimitFetching}
              fetchMaturityLimit={fetchMaturityLimit}
              {...props}
            />
          </>
        )}
        {currentComponent === 3 && (
          <>
            <ManageQuoteTypes
              appUser={appUser}
              quoteTypes={quoteTypes}
              isFetching={isQuoteTypesFetching}
              fetchAllQuoteTypes={fetchAllQuoteTypes}
              fetchSingleQuoteType={fetchSingleQuoteType}
              {...props}
            />
          </>
        )}
        {currentComponent === 4 && (
          <>
            <TemplateTable
              appUser={appUser}
              templates={templates}
              isFetching={isTemplatesFetching}
              handleOpenTemplate={handleOpenTemplate}
              handleEditTemplate={handleEditTemplate}
              handleWarningTemplateOpen={handleWarningTemplateOpen}
              {...props}
            />
            <TransactionTemplateDialog
              isOpen={isTransactionTemplateOpen}
              closeDialog={closeDialog}
              handleSubmitTemplate={handleSubmitTemplate}
              template={template}
              templateId={templateId}
              appUser={appUser}
              isSingleTemplateFetching={isSingleTemplateFetching}
              isAddFirstCellTemplate={isAddFirstCellTemplate}
              isTemplateWarningOpen={isTemplateWarningOpen}
              isDeleting={isDeleting}
              handleDeleteTemplate={handleDeleteTemplate}
              handleWarningTemplateOpen={handleWarningTemplateOpen}
              handleWarningTemplateClose={handleWarningTemplateClose}
              showCancel={showCancel}
              {...props}
            />
          </>
        )}
      </ComponentBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  keywords: state.administration.keywords,
  isKeywordsFetching: state.administration.isKeywordsFetching,
  uploadDate: state.administration.uploadDate,
  isUploadDateFetching: state.administration.isUploadDateFetching,
  maturityLimit: state.administration.maturityLimit,
  isMaturityLimitFetching: state.administration.isMaturityLimitFetching,
  keywordCategories: state.administration.keywordCategories,
  isKeywordCategoriesFetching: state.administration.isKeywordCategoriesFetching,
  noteCategories: state.administration.noteCategories,
  isNoteCategoriesFetching: state.administration.isNoteCategoriesFetching,
  quoteTypes: state.administration.quoteTypes,
  isQuoteTypesFetching: state.administration.isQuoteTypesFetching,
  investmentTypes: state.administration.investmentTypes,
  isInvestmentTypesFetching: state.administration.isInvestmentTypesFetching,
  isFetchingResolveNotes: state.notes.isFetchingResolveNotes,
  resolveNotes: state.notes.resolveNotes,
  templates: state.templates.allTemplates,
  isTemplatesFetching: state.templates.isTemplatesFetching,
  isSingleTemplateFetching: state.templates.isSingleTemplateFetching,
  singleTemplate: state.templates.singleTemplate,
});

const GlobalSettings = connect(mapStateToProps, {
  fetchAllKeywords,
  fetchUploadDate,
  fetchMaturityLimit,
  fetchKeywordCategories,
  fetchSingleKeywordCategory,
  fetchSingleKeyword,
  fetchAllNotes,
  fetchSingleNoteCategory,
  fetchAllQuoteTypes,
  fetchSingleQuoteType,
  fetchInvestmentTypes,
  fetchResolveNotes,
  fetchAllTemplates,
  fetchSingleTemplate,
})(GlobalSettingsBase);

export default GlobalSettings;
