import React from 'react';
import {
  AlignCenter,
  RegularButton,
  WhiteButton,
  FlexColumn,
  MessageText,
  Label,
} from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import colors from '../../../utils/colors';
import { Formik, Form } from 'formik';
import {
  transformValues,
  restrictFieldColumns,
  checkPermissions,
} from '../../../utils/functions';
import * as Yup from 'yup';
import permissions from '../../../utils/permissions';
import LabelIcon from '../../../components/LabelIcon';
import LoadSpinner from '../../LoadSpinner';
import { Field } from 'formik';

//field names to restrict as unique
const formColumnFields = [
  'accountNumber',
  'description',
  'date',
  'amountCredit',
  'amountDebit',
  'creditDebit',
  'amount',
];

//Add custom method to yup
Yup.addMethod(Yup.string, 'uniqueColumn', restrictFieldColumns);

/**
 * Regex: ^\d+$ -> one ore more numbers ex 123
 * Regex: ^ *\d+ *(?:, *\d+ *)*$ -> one or more numbers followed by zero or more (coma with one or more numbers) ex 123,2, 33,4
 * Regex: ^[0-9]+[ ]*[,][ ]*[0-9]+[ ]*?$ -> one or more numbers followed by 1 comma (0+ spaces) and one or more numbers ex 321, 456
 */
const transactionTemplateSchema = Yup.object().shape({
  cellHeaderData: Yup.string().required('Required'),
  accountNumber: Yup.string()
    .matches(/^\d*$/, 'Field must be a number.')
    .uniqueColumn(formColumnFields, 'Column references must be unique.')
    .test('checkAfterSet', 'Required!', function (value) {
      if (
        this.parent.fileNameIncluded === false &&
        this.parent.specialCase === false &&
        this.parent.accountNumberHeader === undefined &&
        !value
      )
        return false;
      return true;
    })
    .test('checkAfterSet', 'Should be empty!', function (value) {
      if (
        (this.parent.specialCase === true ||
          this.parent.fileNameIncluded === true) &&
        value
      )
        return false;
      return true;
    }),
  description: Yup.string()
    .required('Required!')
    .matches(/^ *\d+ *(?:, *\d+ *)*$/, 'Field has an invalid format.')
    .uniqueColumn(formColumnFields, 'Column references must be unique.'),
  date: Yup.string()
    .required('Required!')
    .matches(/^\d+$/, 'Field must be a number.')
    .uniqueColumn(formColumnFields, 'Column references must be unique.'),
  amountCredit: Yup.string()
    .matches(/^\d+$/, 'Field must be a number.')
    .uniqueColumn(formColumnFields, 'Column references must be unique.')
    .test('debit-check', 'Required!', function (value) {
      const { amountDebit } = this.parent;
      if (amountDebit && !value) return false;
      else return true;
    }),
  amountDebit: Yup.string()
    .matches(/^\d+$/, 'Field must be a number.')
    .uniqueColumn(formColumnFields, 'Column references must be unique.')
    .test('credit-check', 'Required!', function (value) {
      const { amountCredit } = this.parent;
      if (amountCredit && !value) return false;
      else return true;
    }),
  amount: Yup.string()
    .matches(/^\d+$/, 'Field must be a number.')
    .uniqueColumn(formColumnFields, 'Column references must be unique.')
    .test('credit-debit-check', 'Required', function (value) {
      const { amountCredit, amountDebit } = this.parent;
      if (!amountCredit && !amountDebit && !value) return false;
      else return true;
    }),
  creditDebit: Yup.string()
    .matches(/^\d+$/, 'Field must be a number.')
    .uniqueColumn(formColumnFields, 'Column references must be unique.'),
  headerStart: Yup.string()
    .matches(/^\d+$/, 'Field must be a number.')
    .required('Required'),
  accountNumberHeader: Yup.string()
    .matches(/^[0-9]+[ ]*[,][ ]*[0-9]+[ ]*?$/, 'Field must be 2 values.')
    .test('checkAfterSet', 'Required!', function (value) {
      if (
        this.parent.fileNameIncluded === false &&
        this.parent.accountNumber === undefined &&
        !value
      )
        return false;
      return true;
    })
    .test('checkAfterSet', 'Should be empty!', function (value) {
      if (this.parent.fileNameIncluded === true && value) return false;
      return true;
    }),
  footerRows: Yup.string().matches(/^\d+$/, 'Field must be a number.'),
});

const TransactionTemplateForm = ({
  closeDialog,
  handleSubmitTemplate,
  handleWarningTemplateOpen,
  accountNumber,
  description,
  date,
  amountCredit,
  amountDebit,
  creditDebit,
  amount,
  headerStart,
  accountNumberHeader,
  appUser,
  template,
  fileNameIncluded,
  specialCase,
  cellHeaderData,
  isAddFirstCellTemplate,
  templateId,
  isHeaderRowExtraction,
  footerRows,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
        <Formik
          initialValues={{
            accountNumber,
            description,
            date,
            amountCredit,
            amountDebit,
            creditDebit,
            amount,
            headerStart,
            accountNumberHeader,
            fileNameIncluded,
            specialCase,
            cellHeaderData,
            footerRows,
          }}
          onSubmit={(account) => {
            handleSubmitTemplate(
              transformValues(account),
              template.templateType
            );
          }}
          validationSchema={transactionTemplateSchema}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ isSubmitting, values }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter padding='0 50px 10px 50px'>
                <FlexColumn>
                  <MessageText>
                    Enter the column header numbers from your sample CSV into
                    the appropriate fields below. The description field can take
                    multiple values(ex: 4,7,8).
                  </MessageText>
                  <MessageText>
                    Note: Not all fields are required. Specific fields are
                    intended to accommodate different bank templates.
                  </MessageText>
                </FlexColumn>
              </AlignCenter>

              {/* for templates at global settings page */}
              {template && template.templateType === 'Header' && (
                <FlexColumn>
                  <Label margin='0 0 10px 50px'>Header Row</Label>
                  <MessageText padding='0 50px' margin='0 0 15px 0'>
                    {cellHeaderData}
                  </MessageText>
                </FlexColumn>
              )}

              {/* for templates at extraction progress table */}
              {isHeaderRowExtraction && (
                <FlexColumn>
                  <Label margin='0 0 10px 50px'>Header Row</Label>
                  <MessageText padding='0 50px' margin='0 0 15px 0'>
                    {cellHeaderData}
                  </MessageText>
                </FlexColumn>
              )}

              {/* if template is type Cell, or is opened from 'add first cell button' show first cell input */}
              {((template && template.templateType === 'Cell') ||
                isAddFirstCellTemplate) && (
                  <AlignCenter justifyContent='space-evenly' margin='0 0 10px 0'>
                    <ModalTextField
                      type='text'
                      name='cellHeaderData'
                      placeholder='First Cell Text'
                      label='First Cell Text'
                      value={values.cellHeaderData || ''}
                      autoComplete='off'
                    />
                    <div style={{ width: '271px' }}></div>
                  </AlignCenter>
                )}

              <AlignCenter justifyContent='space-evenly' margin='0 0 15px 0'>
                <div style={{ width: '271px' }} className='radio-group'>
                  <FlexColumn>
                    <Label margin='0 0 10px 0'>Account Id in File Name?</Label>
                    <label
                      style={{
                        width: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Field
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '10px',
                        }}
                        type='checkbox'
                        name='fileNameIncluded'
                      />
                      Yes
                    </label>
                  </FlexColumn>
                </div>
                <div style={{ width: '271px' }} className='radio-group'>
                  <FlexColumn>
                    <Label margin='0 0 10px 0'>Special Case?</Label>
                    <label
                      style={{
                        width: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Field
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '10px',
                        }}
                        type='checkbox'
                        name='specialCase'
                      />
                      Yes
                    </label>
                  </FlexColumn>
                </div>
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' margin='0 0 10px 0'>
                <ModalTextField
                  type='text'
                  name='accountNumber'
                  placeholder='Account Number'
                  label='Account Number'
                  value={values.accountNumber || ''}
                  autoComplete='off'
                />
                <ModalTextField
                  type='text'
                  name='description'
                  placeholder='Description'
                  label='Description'
                  value={values.description || ''}
                  autoComplete='off'
                />
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' margin='0 0 10px 0'>
                <ModalTextField
                  type='text'
                  name='date'
                  placeholder='Date'
                  label='Date'
                  value={values.date || ''}
                  autoComplete='off'
                />
                <ModalTextField
                  type='text'
                  name='amountCredit'
                  placeholder='Credit Amount'
                  label='Credit Amount'
                  value={values.amountCredit || ''}
                  autoComplete='off'
                />
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' margin='0 0 10px 0'>
                <ModalTextField
                  type='text'
                  name='amountDebit'
                  placeholder='Debit Amount'
                  label='Debit Amount'
                  value={values.amountDebit || ''}
                  autoComplete='off'
                />
                <ModalTextField
                  type='text'
                  name='creditDebit'
                  placeholder='Credit/Debit'
                  label='Credit/Debit'
                  value={values.creditDebit || ''}
                  autoComplete='off'
                />
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' margin='0 0 10px 0'>
                <ModalTextField
                  type='text'
                  name='amount'
                  placeholder='Combined Amount'
                  label='Combined Amount'
                  value={values.amount || ''}
                  autoComplete='off'
                />
                <ModalTextField
                  type='text'
                  name='headerStart'
                  placeholder='Header Start'
                  label='Header Start'
                  value={values.headerStart || ''}
                  autoComplete='off'
                />
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' margin='0 0 10px 0'>
                <ModalTextField
                  type='text'
                  name='accountNumberHeader'
                  placeholder='Row, Column'
                  label={
                    <LabelIcon
                      labelName={'Account Number Header'}
                      text={
                        'Note: The account number header field is for the account number when it does not have its own column. It takes two values, the first for the row and the second for the column, separated by a comma.'
                      }
                    />
                  }
                  value={values.accountNumberHeader || ''}
                  autoComplete='off'
                />
                <ModalTextField
                  type='text'
                  name='footerRows'
                  placeholder='Footer Rows'
                  label='Footer Rows To Remove'
                  value={values.footerRows || ''}
                  autoComplete='off'
                />
              </AlignCenter>

              <AlignCenter
                justifyContent={
                  template && Object.keys(template).length === 0
                    ? 'flex-end'
                    : 'space-between'
                }
                padding='10px 20px 0 0'
                borderTop={`1px solid ${colors.lightText}`}
              >
                {checkPermissions(appUser.permList, [
                  permissions.DELETE_TEMPLATES,
                ]) && (
                    <WhiteButton
                      className={
                        template && Object.keys(template).length === 0
                          ? 'hide-button'
                          : ''
                      }
                      justifyContent='flex-start'
                      margin='10px 10px 10px 20px'
                      type='button'
                      color={colors.red}
                      hoverColor={colors.redHover}
                      // fromTemplate is passed to use as flag for delete action from inside template instead of from table action
                      onClick={() =>
                        handleWarningTemplateOpen(templateId, 'fromTemplate')
                      }
                    >
                      DELETE
                    </WhiteButton>
                  )}
                <AlignCenter justifyContent='space-between' width='fit-content'>
                  <WhiteButton type='button' onClick={closeDialog}>
                    CLOSE
                  </WhiteButton>
                  <RegularButton
                    width='7rem'
                    display='flex'
                    type='submit'
                    disabled={isSubmitting}
                  >
                    SAVE
                    {isSubmitting && (
                      <span style={{ marginLeft: '10px' }}>
                        <LoadSpinner size={20} />
                      </span>
                    )}
                  </RegularButton>
                </AlignCenter>
              </AlignCenter>
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default TransactionTemplateForm;
