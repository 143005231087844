import React, { useState, useEffect } from 'react';
import DataIngestionHeader from '../../../components/headers/DataIngestionHeader';
import { ComponentBody } from '../../../components/styled-components';
import DataIngestionDialog from '../../../components/dialog/data-ingestion/DataIngestionDialog';
import axios from 'axios';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../../components/Toast';
import {
  uploadFile,
  uploadFileListToDatabase,
  fetchExtractionStatus,
  deleteCanceledUploads,
} from '../../../api/data-ingestion';
import UploadConfirmDialog from '../../../components/dialog/data-ingestion/UploadConfirmDialog';
import { filterExtension } from '../../../utils/functions';
import {
  fetchClientBatches,
  fetchSingleClientBatch,
} from '../../../actions/data-ingestion';
import LoadSpinner from '../../../components/LoadSpinner';
import { connect } from 'react-redux';
import BatchesTable from '../../../components/table/BatchesTable';
import ExtractionConfirmDialog from '../../../components/dialog/data-ingestion/ExtractionConfirmDialog';
import ValidationHistory from '../../DataIngestion/DataIngestionProfile/ValidationHistory';

//To cancel promises initiated by this source (cancel upload button)
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const DataIngestionProfileBase = ({
  fetchClientBatches,
  fetchSingleClientBatch,
  clientBatches,
  isClientBatchesFetching,
  ...props
}) => {
  const clientId = props.match.params.clientId;
  const clientName =
    clientBatches.length > 0 ? clientBatches[0].clientName : '';

  const [header, setHeader] = useState(`Data Ingestion: Upload Dashboard`);
  const [currentComponent, setCurrentComponent] = useState(0);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [uploadList, setUploadList] = useState([]);
  const [uploadAmount, setUploadAmount] = useState(0);
  const [uploadErrorAmount, setUploadErrorAmount] = useState(0);
  const [isUploadConfirmDialogOpen, setIsUploadConfirmDialogOpen] =
    useState(false);
  const [allowOverwrite, setAllowOverwrite] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadErrorList, setUploadErrorList] = useState([]);
  const [isExtractionDialogOpen, setIsExtractionDialogOpen] = useState(false);
  const [bankName, setBankName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [batchId, setBatchId] = useState(undefined);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [templateType, setTemplateType] = useState(undefined);
  const [bankId, setBankId] = useState(undefined);
  // for the extraction dialog
  const [extractionList, setExtractionList] = useState([]);
  const [isExtractionListFetching, setIsExtractionListFetching] =
    useState(false);
  const [currentFileTemplateOption, setCurrentFileTemplateOption] = useState('Investment')
  //Setting to true it will initiate a check for the status extraction every time the component mounts
  const [extracting, setExtracting] = useState(true);

  const handleChangeCurrentFileTemplateOption = (e) => {
    setCurrentFileTemplateOption(e.target.value)
  }

  const extractionInProgress = () => {
    setExtracting(true);
  };

  const extractionFinished = () => {
    setExtracting(false);
  };

  useEffect(() => {
    fetchClientBatches(clientId);
  }, [fetchClientBatches, clientId]);

  const handleCurrentComponentChange = (value) => {
    setHeader(
      `Data Ingestion: ${value === 0 ? 'Upload Dashboard' : 'Dashboard'}`
    );
    setCurrentComponent(value);
  };

  const handleOpenUploadDialog = (row) => {
    setIsUploadDialogOpen(true);
    setBankName(row.bankName);
    setAccountName(row.accountName);
    setBatchId(row.batchId);
    setTemplateType(row.templateType);
    setBankId(clientBatches[0].bankId);
  };

  const handleOpenExtractionDialog = (row) => {
    setIsExtractionDialogOpen(true);
    setIsExtractionListFetching(true);
    setBatchId(row.batchId);
    fetchExtractionStatus(row.batchId)
      .then((res) => {
        // remove counts from end of array
        setExtractionList(res.data.slice(0, -3));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsExtractionListFetching(false);
      });
  };

  const closeDialog = () => {
    setIsUploadDialogOpen(false);
    setUploadList([]);
    setIsUploadConfirmDialogOpen(false);
    setIsUploading(false);
    setUploadErrorAmount(0);
    setUploadErrorList([]);
    setIsExtractionDialogOpen(false);
    setBankName('');
    setAccountName('');
    setBatchId(undefined);
    setTemplateType(undefined);
    setBankId(undefined);
    source = CancelToken.source(); //update the cancel token source in case the previous value is canceled
    setExtractionList([]);
    setIsExtractionListFetching(false);
    setAllowOverwrite(false)
  };

  const handleDrop = (acceptedFiles) => {
    const acceptedExtensions = ['csv', 'xls', 'xlsx', 'txt', 'pdf'];
    // run function to check file extension
    acceptedFiles = acceptedFiles.filter((file) =>
      filterExtension(file, acceptedExtensions)
    );

    acceptedFiles = acceptedFiles.map(file => {return {file, templateType: templateType === 'Hybrid' ? currentFileTemplateOption : templateType}})

    // if acceptedFiles comes back with item/items, set the upload list otherwise show toast
    if (acceptedFiles.length > 0) {
      setUploadList([...uploadList, ...acceptedFiles]);
    } else {
      AppToast.show({
        message: 'File type not accepted.',
        intent: Intent.DANGER,
      });
    }
  };

  const handleUpload = () => {
    setIsUploading(true);
    // need to get these counts for the amount of uploads succeeded or failed
    let counter = 0;
    let counterError = 0;
    let successfulFileList = [];

    const addFileToList = (listItem) => {
      successfulFileList.push(listItem);
    };
    const countSuccess = () => {
      counter++;
    };
    const countError = () => {
      counterError++;
    };
    let canceled = false;
    const sourceToken = source.token;
    uploadAllFiles({ sourceToken }, countSuccess, countError, addFileToList)
      .catch((err) => {
        if (axios.isCancel(err)) canceled = true;
      })
      .finally(() => {
        setIsUploading(false);
        setUploadAmount(counter);
        setUploadErrorAmount(counterError);
        if (!canceled) setIsUploadConfirmDialogOpen(true);
        else {
          if (successfulFileList.length) {
            deleteCanceledUploads(successfulFileList)
              .then(() => {})
              .catch((err) => {
                console.error(err);
              });
          }
          closeDialog();
        }
        setProgressPercentage(0);

        // after for of loop is done, and if list has at least 1 item, send list to database
        if (successfulFileList.length > 0 && !canceled) {
          const { appUser } = props;
          const userId = appUser.userId;
          const fileUpload = {
            userId,
            batchId,
            successfulFileList,
            allowOverwrite,
          };
          setExtracting(true);
          // upload list of files to database
          uploadFileListToDatabase(fileUpload)
            .then((res) => {
              AppToast.show({
                message: res.data.body.replaceAll('"', ''),
                intent: Intent.SUCCESS,
              });
            })
            .catch((err) => {
              AppToast.show({
                message:
                  err.response && err.response.data.msg
                    ? err.response.data.msg
                    : 'Failed to initiate the data extraction process',
                intent: Intent.DANGER,
              });
            });
        }
      });
  };

  /**
   * Returns a boolean list with promises
   * True indicates the promise is resolved
   * @param {*} sourceToken - source token so these requests can be aborted if the user choses to
   * @param {func} countSuccess - count stressful requests
   * @param {func} countError - counts failed requests
   */
  const uploadAllFiles = async (
    { sourceToken } = {},
    countSuccess,
    countError,
    addFileToList
  ) => {
    const totalSize = uploadList.reduce((sum, item) => sum + item.file.size, 0);
    const uploaded = {};
    const uploadedFiles = uploadList.map(async (item, index) => {
      const presignedURLResponse = await uploadFile(
        'batch',
        clientId,
        bankId,
        item.templateType,
        item.file.name.split(' ').join(''),
        sourceToken
      ).catch((err) => {
        throw err; //<-- appears in the catch block of the parent
      });

      const presignedURL = presignedURLResponse.data;
      if (presignedURL) {
        delete axios.defaults.headers.common['Authorization'];
        // append file name to fields portion of presigned url response
        let formData = new FormData();
        Object.keys(presignedURL.fields).forEach((key) => {
          formData.append(key, presignedURL.fields[key]);
        });
        // Actual file has to be appended last.
        formData.append('file', item.file);

        await axios
          .post(presignedURL.url, formData, {
            cancelToken: sourceToken,
            onUploadProgress: calcProgress.bind(
              this,
              totalSize,
              uploaded,
              index
            ),
          })
          .then(() => {
            addFileToList(`${presignedURL.url}${presignedURL.fields.key}`);
            countSuccess();
          })
          .catch((err) => {
            setUploadErrorList((uploadErrorList) => [
              ...uploadErrorList,
              item.file.name,
            ]);
            countError();
            throw err; //<-- appears in the catch block of the parent
          });
      }
      return true;
    });
    return Promise.all(uploadedFiles); //<-- List holding promises for all of the requests. when the request is complete the list is going to be populated with true
  };

  /**
   * Helper Function that captures the total upload percentage
   * Works with onUploadProgress
   * @param {int} totalSize - total size of all files to be uploaded
   * @param {obj} uploaded - object that holds the total % uploaded for every file
   * @param {int} index - used to refer to a file
   * @param {obj} progressEvent - event that is being triggered by the axios post method
   */
  const calcProgress = (totalSize, uploaded, index, progressEvent) => {
    uploaded[index] = progressEvent.loaded;
    const totalDone = Object.values(uploaded).reduce(
      (acc, value) => acc + value,
      0
    );
    const totalProgress =
      Math.floor((totalDone * 100) / totalSize) > 100
        ? 100
        : Math.floor((totalDone * 100) / totalSize);
    setProgressPercentage(totalProgress);
  };

  const removeSingleFile = (row) => {
    const newList = [...uploadList];
    newList.splice(row.id, 1);
    setUploadList(newList);
  };

  const removeMultipleFiles = (selectedRows) => {
    // get file name of selected rows
    const pickedList = selectedRows.map((item) => item.file.name);
    // filter upload list based on file name and return new array of items that don't include that file name
    const array = uploadList.filter((item) => !pickedList.includes(item.file.name));
    setUploadList(array);
  };

  const toggleAllowOverwrite = () => {
    setAllowOverwrite((prevallowOverwrite) => !prevallowOverwrite);
  };

  const { appUser } = props;
  return (
    <>
      {isClientBatchesFetching && (
        <div>
          <LoadSpinner size={200} />
        </div>
      )}

      {!isClientBatchesFetching && (
        <>
          <DataIngestionHeader
            header={header}
            handleCurrentComponentChange={handleCurrentComponentChange}
            currentComponent={currentComponent}
            hasSubNav={true}
            hasButton={true}
            clientName={clientName}
            appUser={appUser}
            {...props}
          />
          <ComponentBody>
            {currentComponent === 0 && (
              <BatchesTable
                appUser={appUser}
                clientBatches={clientBatches}
                isClientBatchesFetching={isClientBatchesFetching}
                clientName={clientName}
                fetchClientBatches={fetchClientBatches}
                fetchSingleClientBatch={fetchSingleClientBatch}
                clientId={clientId}
                handleOpenUploadDialog={handleOpenUploadDialog}
                handleOpenExtractionDialog={handleOpenExtractionDialog}
                extractionFinished={extractionFinished}
                extractionInProgress={extractionInProgress}
                extracting={extracting}
                {...props}
              />
            )}
            {currentComponent === 1 && (
              <ValidationHistory 
                clientId={clientId}
                {...props} 
              />
            )}
          </ComponentBody>
          <DataIngestionDialog
            isOpen={isUploadDialogOpen}
            closeDialog={closeDialog}
            handleUpload={handleUpload}
            handleDrop={handleDrop}
            uploadList={uploadList}
            removeMultipleFiles={removeMultipleFiles}
            removeSingleFile={removeSingleFile}
            isUploading={isUploading}
            progressPercentage={progressPercentage}
            accountName={accountName}
            bankName={bankName}
            clientName={clientName}
            source={source}
            toggleAllowOverwrite={toggleAllowOverwrite}
            isHybridBatch={templateType==='Hybrid'}
            currentFileTemplateOption={currentFileTemplateOption}
            handleChangeCurrentFileTemplateOption={handleChangeCurrentFileTemplateOption}
            {...props}
          />
          <UploadConfirmDialog
            isOpen={isUploadConfirmDialogOpen}
            closeDialog={closeDialog}
            uploadAmount={uploadAmount}
            uploadErrorAmount={uploadErrorAmount}
            uploadErrorList={uploadErrorList}
          />
          <ExtractionConfirmDialog
            isOpen={isExtractionDialogOpen}
            closeDialog={closeDialog}
            extractionList={extractionList}
            isFetching={isExtractionListFetching}
            appUser={appUser}
            setExtractionList={setExtractionList}
            batchId={batchId}
            setIsExtractionListFetching={setIsExtractionListFetching}
            {...props}
          />

        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clientBatches: state.dataIngestion.clientBatches,
  isClientBatchesFetching: state.dataIngestion.isClientBatchesFetching,
});

const DataIngestionProfile = connect(mapStateToProps, {
  fetchClientBatches,
  fetchSingleClientBatch,
})(DataIngestionProfileBase);

export default DataIngestionProfile;
