import React from 'react';
import {
  RegularButton,
  ForgotText,
  MessageText,
  Label,
  AlignCenter,
} from '../styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadSpinner from '../LoadSpinner';
import IconTextField from '../formik-text-fields/IconTextField';
import QRCode from 'qrcode.react';
import colors from '../../utils/colors';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../utils/icons';

const validationSchema = Yup.object().shape({
  username: Yup.string().email('Invalid Email').required('Required!'),
  password: Yup.string().required('Required!'),
});

const validationSchemaTwo = Yup.object().shape({
  mfaToken: Yup.string().required('Required!'),
});

const SignIn = ({
  username,
  password,
  handleSignIn,
  handleMfaAuth,
  showForgotPasswordModal,
  showMfa,
  showSetupMfa,
  userObject,
  qrStr,
  mfaToken,
  backToLogin,
  showError,
  cognitoError,
  mfaTokenError,
}) => {
  return (
    // formik takes initial values passed from login component.
    // they are keyed to the name property on the IconTextField tag.
    // handle change event and error msg is handled by formik.
    <>
      {/* show the login form with username and password  */}
      {!showMfa && !showSetupMfa && (
        <AlignCenter flexDirection='column'>
          <Formik
            initialValues={{ username, password }}
            validationSchema={validationSchema}
            onSubmit={handleSignIn}
            validateOnBlur={false}
          >
            {({ isSubmitting, values }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <MessageText margin='0 0 30px 0' fontSize='1.25rem'>
                  Log in to access the Three+One portal
                </MessageText>
                {/* custom field using formik hooks */}
                <Label> Email </Label>
                <IconTextField
                  type='email'
                  name='username'
                  value={values.username || ''}
                  autoComplete='off'
                />
                <Label margin='20px 0 0 0'> Password </Label>
                <IconTextField
                  type='password'
                  name='password'
                  value={values.password || ''}
                />
                {showError && (
                  <AlignCenter justifyContent='space-between'>
                    <MessageText color={colors.red}>{cognitoError}</MessageText>
                    <Icon
                      icon={iconValue.warning}
                      iconSize={20}
                      color={colors.red}
                    />
                  </AlignCenter>
                )}
                <RegularButton
                  margin='30px 0'
                  width='100%'
                  padding='1rem'
                  type='submit'
                  disabled={isSubmitting}
                  display='flex'
                >
                  Login
                  {isSubmitting && (
                    <span style={{ marginLeft: '10px' }}>
                      <LoadSpinner size={20} />
                    </span>
                  )}
                </RegularButton>
                <ForgotText
                  color={colors.purpleText}
                  onClick={showForgotPasswordModal}
                  hoverColor={colors.purpleHover}
                >
                  Forgot Password?
                </ForgotText>
              </Form>
            )}
          </Formik>
        </AlignCenter>
      )}

      {/* show mfa token form if username and password are correct */}
      {showMfa && (
        <AlignCenter flexDirection='column'>
          <Formik
            initialValues={{ userObject, mfaToken }}
            validationSchema={validationSchemaTwo}
            onSubmit={handleMfaAuth}
            validateOnBlur={false}
          >
            {({ isSubmitting, values }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <MessageText>
                  Enter the code from your authenticator app.
                </MessageText>
                {/* custom field using formik hooks */}
                <Label margin='20px 0 0 0'> MFA Token </Label>
                <IconTextField
                  type='text'
                  name='mfaToken'
                  value={values.mfaToken || ''}
                  autoComplete='off'
                />
                {showError && (
                  <AlignCenter justifyContent='space-between'>
                    <MessageText color={colors.red}>
                      {mfaTokenError}
                    </MessageText>
                    <Icon
                      icon={iconValue.warning}
                      iconSize={20}
                      color={colors.red}
                    />
                  </AlignCenter>
                )}
                <RegularButton
                  margin='30px 0'
                  width='100%'
                  display='flex'
                  padding='1rem'
                  type='submit'
                  disabled={isSubmitting}
                >
                  Submit
                  {isSubmitting && (
                    <span style={{ marginLeft: '10px' }}>
                      <LoadSpinner size={20} />
                    </span>
                  )}
                </RegularButton>
                <ForgotText
                  color={colors.purpleText}
                  onClick={backToLogin}
                  hoverColor={colors.purpleHover}
                >
                  Take me back to login
                </ForgotText>
              </Form>
            )}
          </Formik>
        </AlignCenter>
      )}

      {/* show mfa setup form if username and password are correct and mfa has not been setup */}
      {showSetupMfa && (
        <AlignCenter flexDirection='column'>
          <Formik
            initialValues={{ userObject, mfaToken }}
            validationSchema={validationSchemaTwo}
            onSubmit={handleMfaAuth}
            validateOnBlur={false}
          >
            {({ isSubmitting, values }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <MessageText>
                  Multi-Factor Authentication (MFA) is required to login to
                  Three+One.
                </MessageText>
                <MessageText>
                  Please install an authenticator app and use the QR code below
                  to enroll your account.
                </MessageText>
                <MessageText>
                  Then enter the MFA token (6-digit number) that is generated by
                  the authenticator app to login.
                </MessageText>

                <Label margin='30px 0 0 0' style={{ textAlign: 'center' }}>
                  <QRCode value={qrStr || ''} />
                </Label>

                {/* custom field using formik hooks */}
                <Label margin='30px 0 0 0'> MFA Token </Label>
                <IconTextField
                  type='text'
                  name='mfaToken'
                  value={values.mfaToken || ''}
                  autoComplete='off'
                />
                {showError && (
                  <AlignCenter justifyContent='space-between'>
                    <MessageText color={colors.red}>
                      {mfaTokenError}
                    </MessageText>
                    <Icon
                      icon={iconValue.warning}
                      iconSize={20}
                      color={colors.red}
                    />
                  </AlignCenter>
                )}

                <RegularButton
                  margin='30px 0'
                  width='100%'
                  display='flex'
                  padding='1rem'
                  type='submit'
                  disabled={isSubmitting}
                >
                  Submit
                  {isSubmitting && (
                    <span style={{ marginLeft: '10px' }}>
                      <LoadSpinner size={20} />
                    </span>
                  )}
                </RegularButton>
                <ForgotText
                  color={colors.purpleText}
                  onClick={backToLogin}
                  hoverColor={colors.purpleHover}
                >
                  Take me back to login
                </ForgotText>
              </Form>
            )}
          </Formik>
        </AlignCenter>
      )}
    </>
  );
};

export default SignIn;
