import React, { useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import colors from '../../../utils/colors';

// Ref forwarding is an opt-in feature that lets some components take a ref
// they receive, and pass it further down (in other words, “forward” it) to a child.
const RowSelector = forwardRef(
  ({ isSelected, isViewingColumn, indeterminate, disabled, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const Label = styled.label`
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 17px;
      cursor: pointer;
      user-select: none;

      input {
        position: absolute;
        margin-left: -30px;
        opacity: 0;
        height: 0;
        width: 0;
      }
    `;

    const Check = styled.svg`
      fill: none;
      stroke: #470d82;
      stroke-width: 2px;
    `;

    const Span = styled.span`
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: ${(props) => (props.isSelected ? 'white' : '#eee')};
      outline: ${(props) =>
        props.isSelected ? `1px solid ${colors.primary}` : ''};

      ${Check} {
        visibility: ${(props) => (props.isSelected ? 'visible' : 'hidden')};
      }
    `;
    return (
      <>
        {isViewingColumn && (
          <Label>
            <input
              checked={isSelected}
              type='checkbox'
              disabled={disabled}
              ref={resolvedRef}
              {...rest}
            />
            <Span isSelected={isSelected}>
              <Check viewBox='0 0 24 24'>
                <polyline points='20 6 9 17 4 12' />
              </Check>
            </Span>
          </Label>
        )}
        {!isViewingColumn && (
          <input
            type='checkbox'
            disabled={disabled}
            ref={resolvedRef}
            {...rest}
          />
        )}
      </>
    );
  }
);

export default RowSelector;
