import { Dialog } from '@blueprintjs/core';
import React, { useEffect, useState, useRef } from 'react';
import {
  amountToFilterText,
  dateRangeChecker,
  dateToFilterText,
  numberRangeToFilterText,
  percentageToFilterText,
  textToFilterText,
} from '../../../utils/functions';
import RadioPagination from '../../RadioPagination';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import NumberRangeField from '../../select-fields/NumberRangeField';
import SelectField from '../../select-fields/SelectField';
import { AlignCenter } from '../../styled-components';
import TableFilterActions from './TableFilterActions';

const StagingInvestmentsFilterDialogBox = ({
  isOpen,
  title,
  handleClose,
  investments,
  setFilterSummary,
  setAllFilters,
  updatePaginate,
  pageSize,
  pageIndex,
  searchTerm,
  setGetFilters,
  sortOrder,
  stopFunc,
  prevSearchTerm,
  prevSort,
  checkSearchValue,
  moreNextPrev,
  setMoreNextPrev,
  runFilterPaginate,
  setRunFilterPaginate,
  ...props
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [faceValueLow, setFaceValueLow] = useState('');
  const [faceValueHigh, setFaceValueHigh] = useState('');
  const [investmentTypeName, setInvestmentTypeName] = useState('');
  const [maturityDateRange, setMaturityDateRange] = useState([]);
  const [interestRateHigh, setInterestRateHigh] = useState('');
  const [interestRateLow, setInterestRateLow] = useState('');
  const [liquidityLevel, setLiquidityLevel] = useState('');
  const [daysActiveHigh, setDaysActiveHigh] = useState('');
  const [daysActiveLow, setDaysActiveLow] = useState('');
  const [id, setId] = useState('');
  const [callDateRange, setCallDateRange] = useState([]);
  const [settlementDate, setSettlementDate] = useState([]);
  const [settlementAmountHigh, setSettlementAmountHigh] = useState('');
  const [settlementAmountLow, setSettlementAmountLow] = useState('');
  const [interestAtMaturityHigh, setInterestAtMaturityHigh] = useState('');
  const [interestAtMaturityLow, setInterestAtMaturityLow] = useState('');
  const [premiumDiscount, setPremiumDiscount] = useState('');
  const [couponRateLow, setCouponRateLow] = useState('');
  const [couponRateHigh, setCouponRateHigh] = useState('');

  const [investmentTypeItems, setInvestmentTypeItems] = useState([]);
  const [liquidityLevelItems, setLiquidityLevelItems] = useState([]);
  const [idItems, setIdItems] = useState([]);

  const keepFilters = useRef([]);
  const oldSortValue = useRef(sortOrder);

  useEffect(() => {
    if (
      stopFunc === false &&
      runFilterPaginate === false &&
      oldSortValue.current === sortOrder &&
      moreNextPrev === false
    ) {
      return;
    }
    if (prevSort === sortOrder && stopFunc === true) {
      checkSearchValue(prevSearchTerm);
    }
    if (
      (moreNextPrev || (stopFunc === false && prevSearchTerm === searchTerm)) &&
      (oldSortValue.current !== sortOrder ||
        (moreNextPrev && oldSortValue.current === sortOrder) ||
        (oldSortValue.current.length === 0 && sortOrder.length === 0))
    ) {
      updatePaginate(
        { pageSize, pageIndex },
        keepFilters.current,
        searchTerm,
        sortOrder
      );
      oldSortValue.current = sortOrder;
      setMoreNextPrev(false);
    }
  }, [
    updatePaginate,
    pageIndex,
    pageSize,
    searchTerm,
    checkSearchValue,
    prevSearchTerm,
    prevSort,
    sortOrder,
    stopFunc,
    moreNextPrev,
    setMoreNextPrev,
    runFilterPaginate,
  ]);

  useEffect(() => {
    if (investments.length) {
      //This is setting up the item list to use for select field
      const investmentTypes = investments
        .map((investment) => investment.investmentTypeName)
        .filter(
          (value, index, self) => self.indexOf(value) === index && value != null
        )
        .map((name) => ({
          value: name,
          label: name,
        }));
      setInvestmentTypeItems(investmentTypes);

      const liquidityLevels = investments
        .map((investment) => investment.liquidityLevel)
        .filter(
          (value, index, self) => self.indexOf(value) === index && value != null
        )
        .map((name) => ({
          value: name,
          label: name,
        }));
      setLiquidityLevelItems(liquidityLevels);

      const ids = investments
        .map((investment) => investment.investmentId)
        .filter(
          (value, index, self) => self.indexOf(value) === index && value != null
        )
        .map((name) => ({
          value: name,
          label: name,
        }));
      setIdItems(ids);
    }
  }, [investments]);

  const getFilterSummary = () => {
    const filters = [];
    (faceValueHigh || faceValueLow) &&
      filters.push(
        amountToFilterText(faceValueLow, faceValueHigh, 'Face Value')
      );
    dateRangeChecker(maturityDateRange) &&
      filters.push(dateToFilterText(maturityDateRange, 'Maturity date'));
    (interestRateHigh || interestRateLow) &&
      filters.push(
        percentageToFilterText(
          interestRateLow,
          interestRateHigh,
          'Interest Rate'
        )
      );
    liquidityLevel &&
      filters.push(textToFilterText(liquidityLevel, 'Liquidity Level'));
    (daysActiveLow || daysActiveHigh) &&
      filters.push(
        numberRangeToFilterText(daysActiveLow, daysActiveHigh, 'Days Active')
      );
    id && filters.push(textToFilterText(id, 'ID'));
    dateRangeChecker(callDateRange) &&
      filters.push(dateToFilterText(callDateRange, 'Call date'));
    dateRangeChecker(settlementDate) &&
      filters.push(dateToFilterText(settlementDate, 'Purchase date'));
    (settlementAmountHigh || settlementAmountLow) &&
      filters.push(
        amountToFilterText(
          settlementAmountLow,
          settlementAmountHigh,
          'Settlement Amount'
        )
      );
    (interestAtMaturityHigh || interestAtMaturityLow) &&
      filters.push(
        amountToFilterText(
          interestAtMaturityLow,
          interestAtMaturityHigh,
          'Yield to Maturity'
        )
      );
    premiumDiscount &&
      filters.push(textToFilterText(premiumDiscount, 'Premium/Discount'));
    (couponRateLow || couponRateHigh) &&
      filters.push(
        amountToFilterText(couponRateLow, couponRateHigh, 'Coupon Rate')
      );
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    keepFilters.current = [];
    (faceValueHigh || faceValueLow) &&
      keepFilters.current.push(
        amountToFilterText(faceValueLow, faceValueHigh, 'faceValue')
      );
    dateRangeChecker(maturityDateRange) &&
      keepFilters.current.push(
        dateToFilterText(maturityDateRange, 'maturityDate')
      );
    (interestRateHigh || interestRateLow) &&
      keepFilters.current.push(
        percentageToFilterText(
          interestRateLow,
          interestRateHigh,
          'interestRate'
        )
      );
    liquidityLevel &&
      keepFilters.current.push(
        textToFilterText(liquidityLevel, 'liquidityLevel')
      );
    (daysActiveLow || daysActiveHigh) &&
      keepFilters.current.push(
        numberRangeToFilterText(
          daysActiveLow,
          daysActiveHigh,
          'daysUntilMaturity'
        )
      );
    id && keepFilters.current.push(textToFilterText(id, 'investmentId'));
    dateRangeChecker(callDateRange) &&
      keepFilters.current.push(dateToFilterText(callDateRange, 'callDate'));
    dateRangeChecker(settlementDate) &&
      keepFilters.current.push(
        dateToFilterText(settlementDate, 'settlementDate')
      );
    (settlementAmountHigh || settlementAmountLow) &&
      keepFilters.current.push(
        amountToFilterText(
          settlementAmountLow,
          settlementAmountHigh,
          'settlementAmount'
        )
      );
    (interestAtMaturityHigh || interestAtMaturityLow) &&
      keepFilters.current.push(
        amountToFilterText(
          interestAtMaturityLow,
          interestAtMaturityHigh,
          'interestAtMaturity'
        )
      );
    premiumDiscount &&
      keepFilters.current.push(
        textToFilterText(premiumDiscount, 'premiumDiscount')
      );
    (couponRateLow || couponRateHigh) &&
      keepFilters.current.push(
        amountToFilterText(couponRateLow, couponRateHigh, 'couponRate')
      );
    setGetFilters(keepFilters.current);
    updatePaginate(
      { pageSize, pageIndex },
      keepFilters.current,
      searchTerm,
      sortOrder
    );
    closeReset();
  };

  const handleClearFilters = () => {
    setFaceValueLow('');
    setFaceValueHigh('');
    setInvestmentTypeName('');
    setMaturityDateRange([]);
    setInterestRateHigh('');
    setInterestRateLow('');
    setLiquidityLevel('');
    setDaysActiveHigh('');
    setDaysActiveLow('');
    setId('');
    setCallDateRange([]);
    setSettlementDate([]);
    setSettlementAmountHigh('');
    setSettlementAmountLow('');
    setInterestAtMaturityHigh('');
    setInterestAtMaturityLow('');
    setAllFilters([]);
    setFilterSummary([]);
    setPremiumDiscount('');
    setCouponRateHigh('');
    setCouponRateLow('');
    setGetFilters([]);
    keepFilters.current = [];
    updatePaginate(
      { pageSize, pageIndex },
      keepFilters.current,
      searchTerm,
      sortOrder
    );
    closeReset();
  };

  const closeReset = () => {
    handleClose();
    setPageNumber(0);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={closeReset}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 0 20px 0' }}>
        <div style={{ marginBottom: '10px' }}>
          {pageNumber === 0 && (
            <>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Face Value'}
                  selectLow={setFaceValueLow}
                  selectHigh={setFaceValueHigh}
                  high={faceValueHigh}
                  low={faceValueLow}
                  isCurrency={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <DateSelectPlainField
                  labelName={'Maturity Date'}
                  dateRange={maturityDateRange}
                  handleDateChange={setMaturityDateRange}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Interest Rate'}
                  selectLow={setInterestRateLow}
                  selectHigh={setInterestRateHigh}
                  high={interestRateHigh}
                  low={interestRateLow}
                  isPercentage={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Days Until Maturity'}
                  selectLow={setDaysActiveLow}
                  selectHigh={setDaysActiveHigh}
                  high={daysActiveHigh}
                  low={daysActiveLow}
                />
              </AlignCenter>
            </>
          )}
          {pageNumber === 1 && (
            <>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <DateSelectPlainField
                  labelName={'Call Date'}
                  dateRange={callDateRange}
                  handleDateChange={setCallDateRange}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <SelectField
                  selectValueFromList={setId}
                  value={id}
                  labelName={'ID'}
                  items={idItems}
                />
                <SelectField
                  selectValueFromList={setLiquidityLevel}
                  value={liquidityLevel}
                  labelName={'Liquidity Level'}
                  items={liquidityLevelItems}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <DateSelectPlainField
                  labelName={'Settlement Date'}
                  dateRange={settlementDate}
                  handleDateChange={setSettlementDate}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Settlement Amount'}
                  selectLow={setSettlementAmountLow}
                  selectHigh={setSettlementAmountHigh}
                  high={settlementAmountHigh}
                  low={settlementAmountLow}
                  isCurrency={true}
                />
              </AlignCenter>
            </>
          )}
          {pageNumber === 2 && (
            <>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Interest at Maturity'}
                  selectLow={setInterestAtMaturityLow}
                  selectHigh={setInterestAtMaturityHigh}
                  high={interestAtMaturityHigh}
                  low={interestAtMaturityLow}
                  isCurrency={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Coupon Rate'}
                  selectLow={setCouponRateLow}
                  selectHigh={setCouponRateHigh}
                  high={couponRateHigh}
                  low={couponRateLow}
                  isPercentage={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <SelectField
                  labelName='Premium/Discount'
                  items={[
                    { label: 'Premium', value: 'Premium' },
                    { label: 'Discount', value: 'Discount' },
                  ]}
                  selectValueFromList={setPremiumDiscount}
                  value={premiumDiscount}
                />
                <SelectField
                  selectValueFromList={setInvestmentTypeName}
                  value={investmentTypeName}
                  labelName={'Investment Type'}
                  items={investmentTypeItems}
                />
              </AlignCenter>
            </>
          )}
        </div>
      </div>
      <RadioPagination
        pages={3}
        onClickSetPage={setPageNumber}
        page={pageNumber}
      />
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default StagingInvestmentsFilterDialogBox;
