import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../utils/icons';
import { BoldText, BorderComponentContainer } from '../styled-components';
import colors from '../../utils/colors';
import RadioPagination from '../RadioPagination';
import InterestRateWidget from './widgets/InterestRateWidget';
import InvestmentBalanceWidget from './widgets/InvestmentBalanceWidget';
import LiquidityLevelWidget from './widgets/LiquidityLevelWidget';
import UnrealizedInterestWidget from './widgets/UnrealizedInterestWidget';

const InvestmentSummary = ({
  appUser,
  accountId,
  clientId,
  investmentAccountBalance,
  isInvestmentAccountGraphFetching,
  investmentClientBalance,
  isInvestmentClientGraphFetching,
  amountsInvested,
  isAmountsInvestedFetching,
  amountsInvestedAccounts,
  isAmountsInvestedAccountsFetching,
  currentComponent,
  accountComponent,
  interestRate,
  isFetchingInterestRate,
  ...props
}) => {
  const [showBox, setShowBox] = useState(true);
  const [page, setPage] = useState(0);
  const handleSetShowBox = () => {
    setShowBox(!showBox);
  };

  return (
    <>
      <BorderComponentContainer
        borderRadius='5px 5px 0 0'
        borderBottom='0'
        padding='1rem 0'
        marginBottom={'2rem'}
        boxShadow={`0px 3px 6px ${colors.lightText}`}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: showBox ? '1rem' : '0',
            borderBottom: showBox ? `1px solid ${colors.lightText}` : '',
          }}
        >
          <BoldText margin='0 0 0 1rem'>Summary</BoldText>
          <Icon
            icon={showBox ? iconValue.caretUp : iconValue.caretDown}
            iconSize={20}
            color={colors.primary}
            style={{ cursor: 'pointer', marginRight: '1rem' }}
            onClick={handleSetShowBox}
          />
        </div>
        {showBox && (
          <>
            <div
              style={{
                margin: '1rem',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              {page === 0 && (
                <>
                  <InvestmentBalanceWidget
                    investmentAccountBalance={investmentAccountBalance}
                    isInvestmentAccountGraphFetching={
                      isInvestmentAccountGraphFetching
                    }
                    investmentClientBalance={investmentClientBalance}
                    isInvestmentClientGraphFetching={
                      isInvestmentClientGraphFetching
                    }
                  />
                  <LiquidityLevelWidget
                    amountsInvested={amountsInvested}
                    isAmountsInvestedFetching={isAmountsInvestedFetching}
                    amountsInvestedAccounts={amountsInvestedAccounts}
                    isAmountsInvestedAccountsFetching={
                      isAmountsInvestedAccountsFetching
                    }
                    currentComponent={currentComponent}
                    accountComponent={accountComponent}
                  />
                </>
              )}
              {page === 1 && (
                <>
                  <InterestRateWidget
                    accountId={accountId}
                    interestRate={interestRate}
                    isFetching={isFetchingInterestRate}
                  />
                  <UnrealizedInterestWidget
                    accountId={accountId}
                    clientId={clientId}
                    {...props}
                  />
                </>
              )}
            </div>
            <RadioPagination pages={2} page={page} onClickSetPage={setPage} />
          </>
        )}
      </BorderComponentContainer>
    </>
  );
};

export default InvestmentSummary;
