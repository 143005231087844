import { combineReducers } from 'redux';

const noteComments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_NOTE_COMMENTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_NOTE_COMMENTS_PENDING':
    case 'FETCH_NOTE_COMMENTS_REJECTED':
      return [];
    default:
      return state;
  }
};

const isFetchingNoteComments = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_NOTE_COMMENTS_PENDING':
      return true;
    case 'FETCH_NOTE_COMMENTS_REJECTED':
    case 'FETCH_NOTE_COMMENTS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const noteCategories = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_NOTE_CATEGORIES_FULFILLED':
      return action.payload.data;
    case 'FETCH_NOTE_CATEGORIES_PENDING':
    case 'FETCH_NOTE_CATEGORIES_REJECTED':
      return [];
    default:
      return state;
  }
};

const singleNote = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_SINGLE_NOTE_FULFILLED':
      return action.payload.data[0];
    case 'FETCH_SINGLE_NOTE_PENDING':
    case 'FETCH_SINGLE_NOTE_REJECTED':
      return {};
    default:
      return state;
  }
};

const isFetchingSingleNote = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_SINGLE_NOTE_PENDING':
      return true;
    case 'FETCH_SINGLE_NOTE_REJECTED':
    case 'FETCH_SINGLE_NOTE_FULFILLED':
      return false;
    default:
      return state;
  }
};

const clientNotes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_NOTES_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_NOTES_PENDING':
    case 'FETCH_CLIENT_NOTES_REJECTED':
      return [];
    default:
      return state;
  }
};

const isFetchingClientNotes = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_NOTES_PENDING':
      return true;
    case 'FETCH_CLIENT_NOTES_REJECTED':
    case 'FETCH_CLIENT_NOTES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const allNotes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_NOTES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_NOTES_REJECTED':
    case 'FETCH_ALL_NOTES_PENDING':
      return [];
    default:
      return state;
  }
};

const resolveNotes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_RESOLVE_NOTES_FULFILLED':
      return action.payload.data;
    case 'FETCH_RESOLVE_NOTES_REJECTED':
    case 'FETCH_RESOLVE_NOTES_PENDING':
      return [];
    default:
      return state;
  }
};

const isFetchingAllNotes = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_NOTES_PENDING':
      return true;
    case 'FETCH_ALL_NOTES_REJECTED':
    case 'FETCH_ALL_NOTES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const isFetchingResolveNotes = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_RESOLVE_NOTES_PENDING':
      return true;
    case 'FETCH_RESOLVE_NOTES_REJECTED':
    case 'FETCH_RESOLVE_NOTES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const accountNotes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_NOTES_FULFILLED':
      return action.payload.data;
    case 'FETCH_ACCOUNT_NOTES_REJECTED':
    case 'FETCH_ACCOUNT_NOTES_PENDING':
      return [];
    default:
      return state;
  }
};

const isFetchingAccountNotes = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_NOTES_PENDING':
      return true;
    case 'FETCH_ACCOUNT_NOTES_REJECTED':
    case 'FETCH_ACCOUNT_NOTES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const batchNotes = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_BATCH_NOTES_FULFILLED':
      return action.payload.data;
    case 'FETCH_BATCH_NOTES_PENDING':
    case 'FETCH_BATCH_NOTES_REJECTED':
      return [];
    default:
      return state;
  }
};

const isFetchingBatchNotes = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_BATCH_NOTES_PENDING':
      return true;
    case 'FETCH_BATCH_NOTES_REJECTED':
    case 'FETCH_BATCH_NOTES_FULFILLED':
      return false;
    default:
      return state;
  }
};

const notes = combineReducers({
  batchNotes,
  noteComments,
  clientNotes,
  isFetchingClientNotes,
  noteCategories,
  isFetchingNoteComments,
  singleNote,
  isFetchingSingleNote,
  allNotes,
  isFetchingAllNotes,
  resolveNotes,
  isFetchingResolveNotes,
  accountNotes,
  isFetchingAccountNotes,
  isFetchingBatchNotes,
});

export default notes;
