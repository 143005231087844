import React, { useState } from 'react';
import colors from '../utils/colors';
import {
  MessageText,
  ResponseMessage,
  BorderComponentContainer,
  BoldText,
} from './styled-components';
import { Icon } from '@blueprintjs/core';
import iconValue from '../utils/icons';

const FilterSummary = ({ filters, preFilteredLength, filteredLength }) => {
  const [showBox, setShowBox] = useState(false);
  const handleShowBox = () => {
    setShowBox(!showBox);
  };

  return (
    <>
      {filters.length ? (
        <BorderComponentContainer
          borderRadius='5px 5px 0 0'
          borderBottom='0'
          padding='1rem 0'
          marginBottom={'2rem'}
          boxShadow={`0px 3px 6px ${colors.lightText}`}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: showBox ? '1rem' : '0',
              borderBottom: showBox ? `1px solid ${colors.lightText}` : '',
            }}
          >
            <BoldText margin={'0 0 0 1rem'}>
              Filter Summary ({filteredLength} of {preFilteredLength} rows)
            </BoldText>
            <Icon
              icon={showBox ? iconValue.caretUp : iconValue.caretDown}
              iconSize={20}
              color={colors.primary}
              style={{ cursor: 'pointer', marginRight: '1rem' }}
              onClick={handleShowBox}
            />
          </div>
          {showBox && (
            <div style={{ padding: '1rem 1rem 0' }}>
              <MessageText
                color={colors.green}
                border={`1px solid ${colors.green}`}
                borderLeft={`10px solid ${colors.green}`}
                padding={' 0px 10px'}
                minWidth={'600px'}
              >
                <ResponseMessage marginTop={'0px'}>
                  {filters.map((filter, i) => (
                    <span
                      style={{ padding: '5px 0px', display: 'block' }}
                      key={i}
                    >
                      {filter.name + ': ' + filter.value}
                    </span>
                  ))}
                </ResponseMessage>
              </MessageText>
            </div>
          )}
        </BorderComponentContainer>
      ) : null}
    </>
  );
};

export default FilterSummary;
