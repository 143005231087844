import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchReports } from '../../../../actions/clients';
import EditReportCommentDialogBox from '../../../../components/dialog/client-profile-dialog/EditReportCommentDialogBox';
import ReportsTable from '../../../../components/table/ReportsTable';
import ReportUploadDialog from '../../../../components/dialog/client-profile-dialog/ReportUploadDialog';
import { ComponentProfileBody } from '../../../../components/styled-components';
import AppToast from '../../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import axios from 'axios';
import { downloadFiles, filterExtension } from '../../../../utils/functions';
import {
  fetchPresignedURLForReports,
  downloadReport,
  updateReportUploadInfo,
  finalizeReport,
  deleteReport
} from '../../../../api/clients';
import ViewParametersDialogBox from '../../../../components/dialog/client-profile-dialog/ViewParametersDialogBox';
import FinalizeDialog from '../../../../components/dialog/client-profile-dialog/FinalizeDialog';
import DeleteReportDialog from '../../../../components/dialog/client-profile-dialog/DeleteReportDialog';

const ReportsListBase = ({
  appUser,
  client,
  fetchReports,
  reports,
  isReportsFetching,
  clientReportStatus,
  handleGenerateReportDialogOpen,
  ...props
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [uploadList, setUploadList] = useState([]);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [comment, setComment] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isViewParametersDialogOpen, setIsViewParametersDialogOpen] = useState(
    false
  );
  const [isFinalizeDialogOpen, setIsFinalizeDialogOpen] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteReportDialogOpen, setIsDeleteReportDialogOpen] = useState(false);


  useEffect(() => {
    fetchReports(client.clientId);
  }, [fetchReports, client]);

  const handleOpenEditCommentDialog = (selectedReport) => {
    setSelectedReport(selectedReport);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedReport({});
  };

  const handleOpenFinalizeDialog = (selectedReport) => {
    setIsFinalizeDialogOpen(true);
    setSelectedReport(selectedReport);
  };

  const handleCloseFinalizeDialog = () => {
    setIsFinalizeDialogOpen(false);
    setSelectedReport({});
  };

  const handleSetComment = (text) => {
    setComment(text);
  };

  const handleDownloadReport = async (selectedReport, type) => {
    setIsDownloading(true);
    const presignedURL = await downloadReport(selectedReport.reportId, type)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        AppToast.show({
          message: 'No report available',
          intent: Intent.DANGER,
        });
        setIsDownloading(false);
      });

    if (presignedURL) {
      let name;
      if (type === 'system') {
        name = presignedURL
          .slice(0, presignedURL.indexOf('?'))
          .split('/')
          .slice(4)
          .join('/');
      } else if (type === 'user') {
        name = presignedURL
          .slice(0, presignedURL.indexOf('?'))
          .split('/')
          .slice(5)
          .join('/');
      }

      delete axios.defaults.headers.common['Authorization'];

      await axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => {
          downloadFiles(res, name);
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to Download Report.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          setIsDownloading(false);
          setSelectedReport({});
        });
    }
  };

  const handleOpenDeleteReportDialog = (selectedReport) => {
    setIsDeleteReportDialogOpen(true);
    setSelectedReport(selectedReport);
  };

  const handleCloseDeleteReportDialog = () => {
    setIsDeleteReportDialogOpen(false);
    setSelectedReport({});
  };


  const handleDeleteReport = async () => {
    setIsDeleting(true);
    deleteReport(selectedReport.reportId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch(() => {
        AppToast.show({
          message: 'Failed to delete the report.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleCloseDeleteReportDialog();
        setIsDeleting(false);
        fetchReports(client.clientId);
      });
    

  }

  const handleUploadReport = async () => {
    let successfulFileList = '';

    setIsUploading(true);

    const presignedURLResponse = await fetchPresignedURLForReports(
      uploadList[0].name.split(' ').join(''),
      selectedReport.reportId
    ).catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to fetch presigned url.',
        intent: Intent.DANGER,
      });
    });
    const presignedURL = presignedURLResponse.data;

    if (presignedURL) {
      delete axios.defaults.headers.common['Authorization'];
      // append file name to fields portion of presigned url response
      let formData = new FormData();
      Object.keys(presignedURL.fields).forEach((key) => {
        formData.append(key, presignedURL.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append('file', uploadList[0]);

      await axios
        .post(presignedURL.url, formData)
        .then(() => {
          successfulFileList = `${presignedURL.url}${presignedURL.fields.key}`;
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to upload report.',
            intent: Intent.DANGER,
          });
          throw err; //<-- appears in the catch block of the parent
        });
      if (successfulFileList.length > 0) {
        const reports = {
          fileName: presignedURL.fields.key,
          comment: comment,
          userId: appUser.userId,
        };

        await updateReportUploadInfo(reports, selectedReport.reportId)
          .then((res) => {
            AppToast.show({ message: res.data.msg, intent: Intent.SUCCESS });
          })
          .catch((err) => {
            AppToast.show({
              message:
                err.response && err.response.data.msg
                  ? err.response.data.msg
                  : 'Failed to update report.',
              intent: Intent.DANGER,
            });
          })
          .finally(() => {
            setIsUploading(false);
            setIsUploadDialogOpen(false);
            setComment('');
            fetchReports(client.clientId);
          });
      }
    }
  };

  const handleOpenUploadDialog = (selectedRow) => {
    setIsUploadDialogOpen(true);
    setSelectedReport(selectedRow);
  };

  const handleCloseUploadDialog = () => {
    setIsUploadDialogOpen(false);
    setUploadList([]);
    setSelectedReport({});
  };

  const handleDrop = (acceptedFiles) => {
    const acceptedExtensions = ['csv', 'xls', 'xlsx', 'txt', 'pdf'];
    // run function to check file extension
    acceptedFiles = acceptedFiles.filter((file) =>
      filterExtension(file, acceptedExtensions)
    );

    // if acceptedFiles comes back with item/items, set the upload list otherwise show toast
    if (acceptedFiles.length !== 1) {
      AppToast.show({
        message: 'Can only upload one report file at a time.',
        intent: Intent.DANGER,
      });
    } else if (acceptedFiles.length > 0) {
      setUploadList([...uploadList, ...acceptedFiles]);
    } else {
      AppToast.show({
        message: 'File type not accepted.',
        intent: Intent.DANGER,
      });
    }
  };

  const removeFile = () => {
    setUploadList([]);
  };

  const handleViewParametersDialogOpen = (report) => {
    setIsViewParametersDialogOpen(true);
    setSelectedReport(report);
  };

  const handleViewParametersDialogClose = () => {
    setSelectedReport({});
    setIsViewParametersDialogOpen(false);
  };

  const handleToggleFinalize = () => {
    setIsFinalizing(true);
    const report = selectedReport.finalized ? 0 : 1;
    finalizeReport(selectedReport.reportId, report)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch(() => {
        AppToast.show({
          message: 'Failed to finalize/unfinalize report.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        handleCloseFinalizeDialog();
        setIsFinalizing(false);
        fetchReports(client.clientId);
      });
  };

  return (
    <ComponentProfileBody padding='14rem 2rem 4rem'>
      <ReportsTable
        appUser={appUser}
        reports={reports}
        isFetching={isReportsFetching}
        handleOpenUploadDialog={handleOpenUploadDialog}
        handleDownloadReport={handleDownloadReport}
        handleDeleteReport={handleOpenDeleteReportDialog}
        isDownloading={isDownloading}
        handleOpenEditCommentDialog={handleOpenEditCommentDialog}
        clientReportStatus={clientReportStatus}
        handleViewParametersDialogOpen={handleViewParametersDialogOpen}
        handleGenerateReportDialogOpen={handleGenerateReportDialogOpen}
        handleOpenFinalizeDialog={handleOpenFinalizeDialog}
        {...props}
      />
      {/* for the edit action */}
      <EditReportCommentDialogBox
        isOpen={isEditDialogOpen}
        handleClose={handleCloseEditDialog}
        selectedReport={selectedReport}
        fetchReports={fetchReports}
        clientId={client.clientId}
      />
      {/* for the upload action */}
      <ReportUploadDialog
        isOpen={isUploadDialogOpen}
        closeDialog={handleCloseUploadDialog}
        uploadList={uploadList}
        handleDrop={handleDrop}
        removeFile={removeFile}
        handleUpload={handleUploadReport}
        handleSetComment={handleSetComment}
        comment={comment}
        isUploading={isUploading}
        {...props}
      />
      <ViewParametersDialogBox
        isOpen={isViewParametersDialogOpen}
        report={selectedReport}
        title={`Report Parameters ${selectedReport.systemReport ? '- ' : ''} ${
          selectedReport.systemReport
            ? selectedReport.systemReport.replace('.pdf', '').replace('-', ' ')
            : ''
        }`}
        handleClose={handleViewParametersDialogClose}
      />

      {/* for finalize action */}
      <FinalizeDialog
        isFinalizeDialogOpen={isFinalizeDialogOpen}
        handleCloseFinalizeDialog={handleCloseFinalizeDialog}
        reports={reports}
        handleToggleFinalize={handleToggleFinalize}
        isFinalizing={isFinalizing}
        selectedReport={selectedReport}
      />
        <DeleteReportDialog
        isDeleteReportDialogOpen={isDeleteReportDialogOpen}
        handleCloseDeleteReportDialog={handleCloseDeleteReportDialog}
        isDeleting={isDeleting}
        handleDeleteReport={handleDeleteReport}
        selectedReport={selectedReport}
      />
    </ComponentProfileBody>
  );
};

const mapStateToProps = (state) => ({
  reports: state.clients.reports,
  isReportsFetching: state.clients.isReportsFetching,
});

const ReportsList = connect(mapStateToProps, { fetchReports })(ReportsListBase);

export default ReportsList;
