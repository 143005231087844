import React from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  Label,
  WidgetHeader,
  WidgetBody,
  WidgetRow,
  BoldText,
  MessageText,
  StarIcon,
  CardDiv,
} from '../../styled-components';
import colors from '../../../utils/colors';
import { amountFormatter } from '../../../utils/functions';

const CashVestScoreWidget = ({ cashVestSubScores }) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ flexBasis: '335px', flexGrow: '2' }}
    >
      <WidgetHeader>
        <Label display={'flex'} alignItems={'center'}>
          cashVest Score
        </Label>
      </WidgetHeader>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '10px 20px' }}>
          <div style={{ display: 'flex', paddingTop: '10px' }}>
            <BoldText fontSize='1.25rem'>
              {cashVestSubScores &&
                cashVestSubScores.length &&
                cashVestSubScores[0].totalScore}
            </BoldText>
            <StarIcon
              starPercentage={
                cashVestSubScores &&
                cashVestSubScores.length &&
                `${cashVestSubScores[0].totalStar}%`
              }
              style={{ paddingLeft: '10px' }}
            />
          </div>
          <MessageText margin='5px 0'>Overall Score</MessageText>
        </div>
      </div>

      <WidgetBody
        backgroundColor='white'
        borderTop={` 1px solid ${colors.lightText}`}
        padding={'30px 0 0 0'}
      >
        <>
          <WidgetRow>
            <div>
              <BoldText fontSize='1.25rem'>
                {cashVestSubScores &&
                  cashVestSubScores.length &&
                  amountFormatter(cashVestSubScores[0].periodInterest)}
              </BoldText>
              <MessageText margin={'5px 0'}>
                Period Interest Earnings
              </MessageText>
            </div>
            <div>
              <BoldText fontSize='1.25rem'>
                {cashVestSubScores &&
                  cashVestSubScores.length &&
                  amountFormatter(cashVestSubScores[0].yearInterest)}
              </BoldText>
              <MessageText margin={'5px 0'}>12-Month Interest</MessageText>
            </div>
          </WidgetRow>
          <WidgetRow>
            <div>
              <BoldText fontSize='1.25rem'>
                {cashVestSubScores &&
                  cashVestSubScores.length &&
                  amountFormatter(cashVestSubScores[0].initialInterest)}
              </BoldText>
              <MessageText margin={'5px 0'}>Initial Interest</MessageText>
            </div>
          </WidgetRow>
        </>
      </WidgetBody>
    </CardDiv>
  );
};

export default CashVestScoreWidget;
