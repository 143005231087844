import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, Intent } from '@blueprintjs/core';
import NegotiatedRateForm from '../../forms/dashboard/NegotiatedRateForm';
import {
  fetchClientList,
  fetchClientNegotiatedRates,
} from '../../../actions/clients';
import { fetchBankNames } from '../../../actions/banks';
import { fetchQuoteTypesList } from '../../../actions/administration';
import {
  createNegotiatedRate,
  updateNegotiatedRate,
} from '../../../api/dashboard';
import AppToast from '../../Toast';
import {
  fetchNegotiatedRates,
  fetchSingleNegotiatedRate,
} from '../../../actions/dashboard';

const NegotiatedRateDialogBase = ({
  isOpen,
  title,
  appUser,
  handleClose,
  singleClient,
  hasClient,
  clientList,
  bankList,
  quoteTypeList,
  negotiatedRate,
  fetchClientList,
  fetchBankNames,
  fetchQuoteTypesList,
  fetchNegotiatedRates,
  fetchSingleNegotiatedRate,
  fetchClientNegotiatedRates,
}) => {
  useEffect(() => {
    fetchClientList();
    fetchBankNames();
    fetchQuoteTypesList();
  }, [fetchClientList, fetchBankNames, fetchQuoteTypesList]);

  const handleCreate = (negotiatedRates) => {
    negotiatedRates = {
      ...negotiatedRates,
    };
    createNegotiatedRate(negotiatedRates)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to add Negotiated Rate.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        hasClient && fetchClientNegotiatedRates(singleClient.clientId);
        !hasClient && fetchNegotiatedRates();
        handleClose();
      });
  };

  const handleEdit = (negotiatedRates, rateId) => {
    updateNegotiatedRate(negotiatedRates, rateId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to edit Negotiated Rate.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchSingleNegotiatedRate(rateId);
        handleClose();
      });
  };

  return (
    <Dialog
      className='medium-dialog'
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <NegotiatedRateForm
        appUser={appUser}
        singleClient={singleClient}
        hasClient={hasClient}
        bankList={bankList}
        clientList={clientList}
        title={title}
        negotiatedRate={negotiatedRate}
        quoteTypeList={quoteTypeList}
        closeDialog={handleClose}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  clientList: state.clients.allClientList,
  bankList: state.banks.bankNames,
  quoteTypeList: state.administration.quoteTypeList,
});

const NegotiatedRateDialog = connect(mapStateToProps, {
  fetchClientList,
  fetchBankNames,
  fetchQuoteTypesList,
  fetchNegotiatedRates,
  fetchClientNegotiatedRates,
  fetchSingleNegotiatedRate,
})(NegotiatedRateDialogBase);

export default NegotiatedRateDialog;
