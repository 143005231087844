import React, { useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import StartReportForm from '../../forms/client-profile-forms/StartReportForm';

const StartReportDialog = ({
  isOpen,
  handleClose,
  clientReportStatus,
  handleStartReport,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [analysisReportId, setAnalysisReportId] = useState('');

  useEffect(() => {
    if (clientReportStatus) {
      setStartDate(clientReportStatus.reportStartDate || null);
      setEndDate(clientReportStatus.reportEndDate || null);
      setDueDate(clientReportStatus.reportDueDate || null);
      setAnalysisReportId(clientReportStatus.analysisOpenReportId || '');
    }
  }, [clientReportStatus]);

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Start Report'}
      isCloseButtonShown={false}
    >
      <StartReportForm
        startDate={startDate}
        endDate={endDate}
        dueDate={dueDate}
        analysisReportId={analysisReportId}
        handleClose={handleClose}
        handleStartReport={handleStartReport}
      />
    </Dialog>
  );
};

export default StartReportDialog;
