import React, { useState } from 'react';
import UserHeader from '../../../components/headers/UserHeader';
import { ComponentProfileBody } from '../../../components/styled-components';
import AccountDetails from './AccountDetails/AccountDetails';

const UserProfile = (props) => {
  const [userProfileComponent, setUserProfileComponent] = useState(0);

  const handleUserProfileComponentChange = (value) => {
    setUserProfileComponent(value);
  };
  return (
    <>
      <UserHeader
        header={'My Account'}
        hasButton={false}
        hasUserProfileSubNav={true}
        handleUserProfileComponentChange={handleUserProfileComponentChange}
        userProfileComponent={userProfileComponent}
        {...props}
      />
      <ComponentProfileBody padding='0'>
        {userProfileComponent === 0 && <AccountDetails {...props} />}
      </ComponentProfileBody>
    </>
  );
};

export default UserProfile;
