import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
  /*Essentially, useRef is like a “box” that can hold a mutable value in its .current property.
    useRef() creates a plain JavaScript object. The only difference between useRef() and creating a `{current: ...}` 
    object yourself is that `useRef` will give you the same ref object on every render. 
    Mutating the .current property doesn’t cause a re-render
    */
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
