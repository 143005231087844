import React from 'react';
import iconValue from '../utils/icons';
import colors from '../utils/colors';
import { Icon, Tooltip } from '@blueprintjs/core';

const LabelIcon = ({ labelName, text }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p style={{ marginRight: '8px' }}>{labelName}</p>
      <Tooltip content={text}>
        <Icon
          icon={iconValue.info}
          iconSize={16}
          color={colors.primary}
          style={{ display: 'flex', marginTop: '3px', cursor: 'pointer' }}
        />
      </Tooltip>
    </div>
  );
};

export default LabelIcon;
