import React from 'react';
import { SubNavWrapper, NavItem } from '../../styled-components';

const AccountSubNavigation = ({
  handleUserProfileComponentChange,
  userProfileComponent,
}) => {
  return (
    <>
      <SubNavWrapper>
        <NavItem
          onClick={() => handleUserProfileComponentChange(0)}
          className={userProfileComponent === 0 && 'active'}
        >
          Account Details
        </NavItem>
      </SubNavWrapper>
    </>
  );
};

export default AccountSubNavigation;
