import React from 'react';
import {
  MessageText,
  BorderComponentContainer,
  InfoWidthContainer,
  FormWidthContainer,
} from '../../styled-components';
import PasswordForm from '../../forms/user-profile-forms/PasswordForm';

const PasswordComponent = ({
  currentPassword,
  newPassword,
  confirmPassword,
  currentPasswordError,
  newPasswordError,
  confirmPasswordError,
  verifyPasswordError,
  cognitoError,
  showCognitoError,
  samePasswordError,
  showSamePasswordError,
  handleChangePassword,
  handleResetForm,
  ...props
}) => {
  return (
    <>
      <InfoWidthContainer>
        <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
          Password
        </MessageText>
        <MessageText>Update your password, here.</MessageText>
      </InfoWidthContainer>
      <FormWidthContainer>
        <BorderComponentContainer padding='2rem'>
          <PasswordForm
            currentPassword={currentPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            currentPasswordError={currentPasswordError}
            newPasswordError={newPasswordError}
            confirmPasswordError={confirmPasswordError}
            verifyPasswordError={verifyPasswordError}
            cognitoError={cognitoError}
            showCognitoError={showCognitoError}
            samePasswordError={samePasswordError}
            showSamePasswordError={showSamePasswordError}
            handleChangePassword={handleChangePassword}
            handleResetForm={handleResetForm}
            {...props}
          />
        </BorderComponentContainer>
      </FormWidthContainer>
    </>
  );
};

export default PasswordComponent;
