import React from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  WidgetHeader,
  Label,
  BoldText,
  TextValue,
  CardDiv,
} from '../../styled-components';
import LoadSpinner from '../../LoadSpinner';
import { amountFormatter } from '../../../utils/functions';
import { ResponsiveLine } from '@nivo/line';
import colors from '../../../utils/colors';
import iconValue from '../../../utils/icons';
import NonIdealStateComp from '../../Non-Ideal-State';

const InvestmentBalanceWidget = ({
  investmentAccountBalance,
  isInvestmentAccountGraphFetching,
  investmentClientBalance,
  isInvestmentClientGraphFetching,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget-summary'}
    >
      {(isInvestmentAccountGraphFetching ||
        isInvestmentClientGraphFetching) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {/* account level balance graph */}
      {!isInvestmentAccountGraphFetching &&
        investmentAccountBalance &&
        investmentAccountBalance.length < 1 && (
          <>
            <WidgetHeader>
              <Label>Investment Balance</Label>
            </WidgetHeader>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        )}

      {!isInvestmentAccountGraphFetching &&
        investmentAccountBalance &&
        investmentAccountBalance[4] &&
        investmentAccountBalance[4][0].data.length > 0 && (
          <>
            <WidgetHeader>
              <Label>Investment Balance</Label>
            </WidgetHeader>
            <WidgetHeader padding='10px 20px' display='block'>
              <BoldText fontSize='1rem'>
                Balance as of:{' '}
                {investmentAccountBalance && (
                  <TextValue>{investmentAccountBalance[0]}</TextValue>
                )}
              </BoldText>
              <BoldText fontSize='1rem'>
                Total:{' '}
                <TextValue>
                  {amountFormatter(investmentAccountBalance[1], 2)}
                </TextValue>
              </BoldText>
            </WidgetHeader>

            <div
              style={{
                height: '15.3rem',
                display: 'flex',
                background: `${colors.containerBackground}`,
              }}
            >
              <ResponsiveLine
                data={
                  investmentAccountBalance.length > 0
                    ? investmentAccountBalance[4]
                    : []
                }
                margin={{
                  top:
                    investmentAccountBalance[4][0].data.length === 1 ? 80 : 20,
                  right: 40,
                  bottom: 60,
                  left: 100,
                }}
                colors={colors.primary}
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                enableGridY={false}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: investmentAccountBalance[3],
                  max: investmentAccountBalance[2],
                }}
                yFormat={(v) => amountFormatter(v, 2)}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 8,
                  tickPadding: 10,
                  // need to check how many points there will be in order to display correct values on x axis
                  tickValues:
                    investmentAccountBalance &&
                    investmentAccountBalance.length > 0 &&
                    investmentAccountBalance[4][0].data.length === 1
                      ? [investmentAccountBalance[4][0].data[0].x]
                      : investmentAccountBalance[4][0].data.length === 2
                      ? [
                          investmentAccountBalance[4][0].data[0].x,
                          investmentAccountBalance[4][0].data[1].x,
                        ]
                      : investmentAccountBalance[4][0].data.length === 3
                      ? [
                          investmentAccountBalance[4][0].data[0].x,
                          investmentAccountBalance[4][0].data[1].x,
                          investmentAccountBalance[4][0].data[2].x,
                        ]
                      : investmentAccountBalance[4][0].data.length === 4
                      ? [
                          investmentAccountBalance[4][0].data[0].x,
                          investmentAccountBalance[4][0].data[1].x,
                          investmentAccountBalance[4][0].data[2].x,
                          investmentAccountBalance[4][0].data[3].x,
                        ]
                      : investmentAccountBalance[4][0].data.length === 5
                      ? [
                          investmentAccountBalance[4][0].data[0].x,
                          investmentAccountBalance[4][0].data[1].x,
                          investmentAccountBalance[4][0].data[2].x,
                          investmentAccountBalance[4][0].data[3].x,
                          investmentAccountBalance[4][0].data[4].x,
                        ]
                      : investmentAccountBalance[4][0].data.length === 6
                      ? [
                          investmentAccountBalance[4][0].data[0].x,
                          investmentAccountBalance[4][0].data[1].x,
                          investmentAccountBalance[4][0].data[2].x,
                          investmentAccountBalance[4][0].data[3].x,
                          investmentAccountBalance[4][0].data[4].x,
                          investmentAccountBalance[4][0].data[5].x,
                        ]
                      : '',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 10,
                  format: (v) => amountFormatter(v, 0),
                }}
                // needed for interactivity
                useMesh={true}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: '#a9acbe',
                        strokeWidth: 1,
                      },
                    },
                  },
                  tooltip: {
                    container: { color: colors.boldText, fontWeight: 700 },
                  },
                }}
              />
            </div>
          </>
        )}

      {/* client level balance graph */}
      {!isInvestmentClientGraphFetching &&
        investmentClientBalance &&
        investmentClientBalance.length < 1 && (
          <>
            <WidgetHeader>
              <Label>Investment Balance</Label>
            </WidgetHeader>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        )}

      {!isInvestmentClientGraphFetching &&
        investmentClientBalance &&
        investmentClientBalance[4] &&
        investmentClientBalance[4][0].data.length > 0 && (
          <>
            <WidgetHeader>
              <Label>Investment Balance</Label>
            </WidgetHeader>
            <WidgetHeader padding='10px 20px' display='block'>
              <BoldText fontSize='1rem'>
                Balance as of:{' '}
                {investmentClientBalance && (
                  <TextValue>{investmentClientBalance[0]}</TextValue>
                )}
              </BoldText>
              <BoldText fontSize='1rem'>
                Total:
                <TextValue>
                  {amountFormatter(investmentClientBalance[1], 2)}
                </TextValue>
              </BoldText>
            </WidgetHeader>

            <div
              style={{
                height: '15.3rem',
                display: 'flex',
                background: `${colors.containerBackground}`,
              }}
            >
              <ResponsiveLine
                data={
                  investmentClientBalance.length > 0
                    ? investmentClientBalance[4]
                    : []
                }
                margin={{
                  top:
                    investmentClientBalance[4][0].data.length === 1 ? 80 : 20,
                  right: 40,
                  bottom: 60,
                  left: 100,
                }}
                colors={colors.primary}
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                enableGridY={false}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: investmentClientBalance[3],
                  max: investmentClientBalance[2],
                }}
                yFormat={(v) => amountFormatter(v, 2)}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 8,
                  tickPadding: 10,
                  // need to check how many points there will be in order to display correct values on x axis
                  tickValues:
                    investmentClientBalance &&
                    investmentClientBalance.length > 0 &&
                    investmentClientBalance[4][0].data.length === 1
                      ? [investmentClientBalance[4][0].data[0].x]
                      : investmentClientBalance[4][0].data.length === 2
                      ? [
                          investmentClientBalance[4][0].data[0].x,
                          investmentClientBalance[4][0].data[1].x,
                        ]
                      : investmentClientBalance[4][0].data.length === 3
                      ? [
                          investmentClientBalance[4][0].data[0].x,
                          investmentClientBalance[4][0].data[1].x,
                          investmentClientBalance[4][0].data[2].x,
                        ]
                      : investmentClientBalance[4][0].data.length === 4
                      ? [
                          investmentClientBalance[4][0].data[0].x,
                          investmentClientBalance[4][0].data[1].x,
                          investmentClientBalance[4][0].data[2].x,
                          investmentClientBalance[4][0].data[3].x,
                        ]
                      : investmentClientBalance[4][0].data.length === 5
                      ? [
                          investmentClientBalance[4][0].data[0].x,
                          investmentClientBalance[4][0].data[1].x,
                          investmentClientBalance[4][0].data[2].x,
                          investmentClientBalance[4][0].data[3].x,
                          investmentClientBalance[4][0].data[4].x,
                        ]
                      : investmentClientBalance[4][0].data.length === 6
                      ? [
                          investmentClientBalance[4][0].data[0].x,
                          investmentClientBalance[4][0].data[1].x,
                          investmentClientBalance[4][0].data[2].x,
                          investmentClientBalance[4][0].data[3].x,
                          investmentClientBalance[4][0].data[4].x,
                          investmentClientBalance[4][0].data[5].x,
                        ]
                      : '',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 10,
                  format: (v) => amountFormatter(v, 0),
                }}
                // needed for interactivity
                useMesh={true}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: '#a9acbe',
                        strokeWidth: 1,
                      },
                    },
                  },
                  tooltip: {
                    container: { color: colors.boldText, fontWeight: 700 },
                  },
                }}
              />
            </div>
          </>
        )}
    </CardDiv>
  );
};

export default InvestmentBalanceWidget;
