import React from 'react';
import {
  BoldText,
  AlignCenter,
  ActionIconButton,
  NameLink,
  DisabledButton,
  TransparentButton,
  BorderComponentContainer,
} from '../../styled-components';
import colors from '../../../utils/colors';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import permissions from '../../../utils/permissions';
import { checkPermissions } from '../../../utils/functions';

const AccountLinkSection = ({
  accountLinks,
  openAddAccountLinkDialog,
  openEditAccountLinkDialog,
  appUser,
}) => {
  return (
    <>
      <AlignCenter justifyContent='space-between'>
        <BoldText>Account Link</BoldText>
        {accountLinks.length === 0 &&
          checkPermissions(appUser.permList, [
            permissions.ADD_ACCOUNT_LINKS,
          ]) && (
            <TransparentButton onClick={openAddAccountLinkDialog}>
              Add Account Link
            </TransparentButton>
          )}
        {accountLinks.length !== 0 && (
          <DisabledButton disabled={true}>Add Account Link</DisabledButton>
        )}
      </AlignCenter>

      {accountLinks.length !== 0 && (
        <BorderComponentContainer marginTop='1rem'>
          <AlignCenter justifyContent='space-between' padding='0 0 15px 0'>
            <div style={{ display: 'flex' }}>
              <BoldText>This account links to:</BoldText>

              <BoldText color={colors.purpleText} margin='0 5px'>
                {accountLinks[0].linkedToAccountName}
              </BoldText>
            </div>

            <BoldText color={colors.normalText} fontWeight='400'>
              {accountLinks[0].linkedDate}
            </BoldText>
          </AlignCenter>

          <AlignCenter justifyContent='space-between'>
            <NameLink
              to={{
                pathname: `${accountLinks[0].linkedToId}`,
              }}
            >
              View Linked Account
            </NameLink>
            {checkPermissions(appUser.permList, [
              permissions.EDIT_ACCOUNT_LINKS,
            ]) && (
              <ActionIconButton>
                <Icon
                  icon={iconValue.pen}
                  iconSize={16}
                  color={colors.primary}
                  onClick={openEditAccountLinkDialog}
                />
              </ActionIconButton>
            )}
          </AlignCenter>
        </BorderComponentContainer>
      )}
    </>
  );
};

export default AccountLinkSection;
