import React from 'react';
import {
  ComponentHeader,
  HeaderText,
  MainSubNavContainer,
} from '../styled-components';
import GlobalSettingsSubNavigation from '../navigation/SubNavigation/GlobalSettingsSubNavigation';
import QuandlData from './header-data/QuandlData';

const GlobalSettingsHeader = ({
  header,
  handleCurrentComponentChange,
  currentComponent,
  appUser,
  ...props
}) => {
  const { open } = props;
  return (
    <>
      <ComponentHeader className={open}>
        <QuandlData {...props} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <HeaderText>{header}</HeaderText>
        </div>
      </ComponentHeader>
      <MainSubNavContainer className={open}>
        <GlobalSettingsSubNavigation
          handleCurrentComponentChange={handleCurrentComponentChange}
          currentComponent={currentComponent}
          appUser={appUser}
        />
      </MainSubNavContainer>
    </>
  );
};

export default GlobalSettingsHeader;
