import React, { useMemo } from 'react';
import {
  StyleColumnCell,
  ActionIconButton,
  TransparentButton,
  AlignCenter,
} from '../styled-components';
import MakeTable from './components/MakeTable';
import SortableHeader from './components/SortableHeader';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import { Icon } from '@blueprintjs/core';
import { checkPermissions, localDateFormatter, computeCollectionType, getCollectionTypeColor } from '../../utils/functions';
import permissions from '../../utils/permissions';
import { TableContainer } from './components/table-styled-components';

const OpenReportsTable = ({
  openReports,
  isFetching,
  appUser,
  handleDeleteDialogOpen,
  ...props
}) => {

  const columns = useMemo(
    () => [
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Clients' />
        ),
        accessor: 'clientName',
        Cell: ({ cell: { value }, row }) => {
          return (
            <div style={{ display: 'flex' }}>
              {value}
              {row.original.hasNotes && (
                <Icon
                  icon={iconValue.comment}
                  iconSize={20}
                  style={{ paddingLeft: '8px' }}
                />
              )}
            </div>
          );
        },
        width: '20%',
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Created Date' />
        ),
        accessor: 'createdAt',
        width: '15%',
        sortType: 'customDateTimeSort',
        isVisible: true,
        Cell: ({ cell: { value } }) => localDateFormatter(value),
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Sources Required' />
        ),
        accessor: 'sourcesRequired',
        width: '10%',
        sortType: 'basic',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Collection Type' />
        ),
        accessor: (row) => `${computeCollectionType(row.accountsCount, row.accountsSentIn)}`,
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell color={getCollectionTypeColor(value)}>
              {value}
            </StyleColumnCell>
          );
        },
        id: 'collectionType',
        width: '15%',
        sortType: 'basic',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Status' />
        ),
        accessor: 'status',
        width: '10%',
        Cell: ({ cell: { value } }) => {
          return (
            <StyleColumnCell
              color={
                value === 'NOT STARTED'
                  ? colors.greyPlaceholderText
                  : colors.green
              }
            >
              {value}
            </StyleColumnCell>
          );
        },
        sortType: 'caseInsensitiveSort',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Actions' />
        ),
        id: 'actions',
        width: '5%',
        Cell: ({ row }) => (
          <AlignCenter justifyContent='start'>
            {checkPermissions(appUser.permList, [permissions.VIEW_BATCHES]) && (
              <TransparentButton
                padding='7px'
                width='150px'
                onClick={() =>
                  props.history.push(`/data-ingestion/${row.original.clientId}`)
                }
              >
                View Dashboard
              </TransparentButton>
            )}
            {checkPermissions(appUser.permList, [
              permissions.DELETE_OPEN_REPORTS,
            ]) && (
                <ActionIconButton
                  margin='0 0 0 8px'
                  style={{ padding: '5px' }}
                  onClick={() => handleDeleteDialogOpen(row.original)}
                >
                  <Icon
                    icon={iconValue.trash}
                    iconSize={20}
                    color={colors.primary}
                  />
                </ActionIconButton>
              )}
          </AlignCenter>
        ),
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.DELETE_OPEN_REPORTS,
          permissions.VIEW_BATCHES,
        ]),
      },
    ],
    [appUser, handleDeleteDialogOpen, props.history]
  );
  return (
    <TableContainer padding='14rem 2rem 4rem'>
      <MakeTable
        data={openReports}
        columns={columns}
        isFetching={isFetching}
        tableTitle='Clients with Reports in Progress'
        {...props}
      />
    </TableContainer>
  );
};

export default OpenReportsTable;
