import React, { useState } from 'react';
import {
  MessageText,
  GlobalSettingsDescription,
  ResponsiveCenter,
} from '../styled-components';
import KeywordsTable from '../../components/table/KeywordsTable';
import KeywordsDeleteDialogBox from '../dialog/global-settings-dialog/KeywordsDeleteDialogBox';
import KeywordsDialogBox from '../dialog/global-settings-dialog/KeywordsDialogBox';

const Managekeywords = ({
  appUser,
  keywords,
  isFetching,
  fetchAllKeywords,
  fetchSingleKeyword,
  ...props
}) => {
  const [title, setTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [keywordObj, setKeywordObj] = useState({});
  const [idDeleteOpen, setIsDeleteOpen] = useState(false);
  const [keywordIds, setKeywordIds] = useState([]);

  const openAdd = () => {
    setTitle('Add Keyword');
    setIsOpen(true);
  };

  const openEdit = (selectedRow) => {
    setKeywordObj(selectedRow);
    setTitle('Edit Keyword');
    setIsOpen(true);
  };

  const handleClose = () => {
    setTitle('');
    setIsOpen(false);
    setKeywordObj({});
  };

  const closeDeleteDialog = () => {
    setKeywordIds([]);
    setIsDeleteOpen(false);
  };

  const openDeleteDialog = (selectedRows) => {
    if (selectedRows instanceof Array) {
      setKeywordIds(selectedRows.map((row) => row.keywordId));
    } else {
      setKeywordIds([selectedRows.keywordId]);
    }
    setIsDeleteOpen(true);
  };

  return (
    <>
      <KeywordsDeleteDialogBox
        isOpen={idDeleteOpen}
        handleClose={closeDeleteDialog}
        title={
          keywordIds.length > 1
            ? `Delete selected Keywords (${keywordIds.length})`
            : 'Delete Keyword'
        }
        keywordIds={keywordIds}
        fetchAllKeywords={fetchAllKeywords}
      />
      <KeywordsDialogBox
        handleDialogClose={handleClose}
        isOpen={isOpen}
        title={title}
        keywordObj={keywordObj}
        fetchAllKeywords={fetchAllKeywords}
        fetchSingleKeyword={fetchSingleKeyword}
      />
      <ResponsiveCenter className={'global-setting'} minWidth={'765px'}>
        <GlobalSettingsDescription>
          <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
            Manage Keywords
          </MessageText>
          <MessageText>
            Create and manage keywords for use in the application.
          </MessageText>
        </GlobalSettingsDescription>
        <KeywordsTable
          appUser={appUser}
          keywords={keywords}
          isFetching={isFetching}
          openEdit={openEdit}
          openAdd={openAdd}
          openDeleteDialog={openDeleteDialog}
          {...props}
        />
      </ResponsiveCenter>
    </>
  );
};

export default Managekeywords;
