import { Form, Formik } from 'formik';
import React from 'react';
import ModalTextArea from '../../formik-text-fields/ModalTextArea';
import { AlignCenter } from '../../styled-components';
import FormActions from '../FormActions';
import * as Yup from 'yup';

const reportSchema = Yup.object().shape({
  comment: Yup.string(),
});

const EditReportCommentForm = ({ comment, handleEdit, closeDialog }) => {
  return (
    <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
      <Formik
        initialValues={{
          comment,
        }}
        onSubmit={(values) => {
          handleEdit(values, closeDialog);
        }}
        validationSchema={reportSchema}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ padding: '1rem 2rem' }}>
              <AlignCenter
                justifyContent='space-evenly'
                padding='20px 20px 0px 20px'
              >
                <ModalTextArea
                  name={'comment'}
                  label={'Comment'}
                  value={values.comment || ''}
                />
              </AlignCenter>
            </div>
            <FormActions isSubmitting={isSubmitting} onClose={closeDialog} />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default EditReportCommentForm;
