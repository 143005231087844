import React from 'react';
import { Header, Data, Text, Number } from './styled-components';

const TreasuryData = ({ quandlData }) => {
  return (
    <>
      <div style={{ flexBasis: '200px', flexGrow: 1, marginRight: '10px' }}>
        <div style={{ borderBottom: '1px solid white', marginBottom: '5px' }}>
          <Header>US Treasury</Header>
        </div>
        <div style={{ width: '100%', display: 'flex' }}>
          <Data>
            {quandlData && quandlData.length !== 0 && (
              <Number>{quandlData[0].usTreasury[0]}</Number>
            )}
            <Text>30-Day</Text>
          </Data>
          <Data>
            {quandlData && quandlData.length !== 0 && (
              <Number>{quandlData[0].usTreasury[1]}</Number>
            )}
            <Text>60-Day</Text>
          </Data>
          <Data>
            {quandlData && quandlData.length !== 0 && (
              <Number>{quandlData[0].usTreasury[2]}</Number>
            )}
            <Text>90-Day</Text>
          </Data>
          <Data>
            {quandlData && quandlData.length !== 0 && (
              <Number>{quandlData[0].usTreasury[3]}</Number>
            )}
            <Text>1-Year</Text>
          </Data>
          <Data>
            {quandlData && quandlData.length !== 0 && (
              <Number>{quandlData[0].usTreasury[4]}</Number>
            )}
            <Text>2-Year</Text>
          </Data>
        </div>
      </div>
    </>
  );
};

export default TreasuryData;
