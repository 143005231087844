import { Dialog } from '@blueprintjs/core';
import React, { useState } from 'react';
import {
  dateRangeChecker,
  dateToFilterText,
  numberRangeToFilterText,
} from '../../../utils/functions';
import DateSelectPlainField from '../../select-fields/DateSelectPlainField';
import NumberRangeField from '../../select-fields/NumberRangeField';
import TableFilterActions from './TableFilterActions';

const CashVestHistoryFilterDialogBox = ({
  isOpen,
  handleClose,
  setFilter,
  setAllFilters,
  setFilterSummary,
}) => {
  const [dateRange, setDateRange] = useState([]);
  const [totalScoreLow, setTotalScoreLow] = useState('');
  const [totalScoreHigh, setTotalScoreHigh] = useState('');

  const getFilterSummary = () => {
    const filters = [];
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Date'));
    (totalScoreLow || totalScoreHigh) &&
      filters.push(
        numberRangeToFilterText(totalScoreLow, totalScoreHigh, 'Rate')
      );
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('createdAt', dateRangeChecker(dateRange) ? dateRange : undefined);
    setFilter(
      'totalScore',
      !totalScoreLow && !totalScoreHigh
        ? undefined
        : [totalScoreLow || undefined, totalScoreHigh || undefined]
    );
    handleClose();
  };

  const handleClearFilters = () => {
    setAllFilters([]);
    setFilterSummary([]);
    setDateRange([]);
    setTotalScoreHigh('');
    setTotalScoreLow('');
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={'Set Filters'}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div
        style={{
          padding: '2rem 3rem 0',
          marginBottom: '10px',
        }}
      >
        <>
          <div style={{ marginBottom: '20px' }}>
            <DateSelectPlainField
              labelName={'Created Date Range'}
              dateRange={dateRange}
              handleDateChange={setDateRange}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <NumberRangeField
              labelName='Total Score'
              selectLow={setTotalScoreLow}
              selectHigh={setTotalScoreHigh}
              low={totalScoreLow}
              high={totalScoreHigh}
            />
          </div>
        </>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default CashVestHistoryFilterDialogBox;
