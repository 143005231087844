import React from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { AlignCenter } from '../../styled-components';
import FormActions from '../FormActions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import * as Yup from 'yup';
import { find } from 'lodash';

//This is a schema for any non ecr objects in balance
const nonECRSchema = Yup.object().shape({
  standardizedDescription: Yup.string().required('Required!'),
  //Amount is array so we do Array.of.
  amounts: Yup.array().of(
    Yup.object().shape({
      //And we validate the second item of that array here.
      1: Yup.string()
        .required('Required!')
        .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
        .nullable(),
    })
  ),
});

//This is a schema for any ecr objects in balance
const ECRSchema = Yup.object().shape({
  standardizedDescription: Yup.string().required('Required!'),
  //Amount is array so we do Array.of.
  amounts: Yup.array().of(
    Yup.object().shape({
      //And we validate the second item of that array here.
      1: Yup.number()
        .required('Required!')
        .min(0, 'ECR can not be less than 0%.')
        .nullable(),
    })
  ),
});

const transformValues = (values, standardizedDescriptionList, isECR) => {
  //Here we are finding the standardizedDescription and setting its key
  values.standardDescriptionId = find(standardizedDescriptionList, [
    'label',
    values.standardizedDescription,
  ]).value;
  //For ecr value we divide the enter percentage by 100 and send it back to backend.
  values.balanceAmounts = isECR
    ? values.amounts.map((item) => [item[0], item[1] / 100])
    : values.amounts;
  delete values.standardizedDescription;
  delete values.amounts;
  return values;
};

const EditBalanceForm = ({
  closeDialog,
  amounts,
  dynamicHeaders,
  standardizedDescription,
  standardizedDescriptionList,
  handleEditBalance,
  isECR,
  indexes,
}) => {
  const headerArray = indexes.map((i) => dynamicHeaders[i]);
  return (
    <>
      <AlignCenter flexDirection='column'>
        <Formik
          initialValues={{ standardizedDescription, amounts }}
          validationSchema={isECR ? ECRSchema : nonECRSchema}
          onSubmit={(values) => {
            handleEditBalance(
              transformValues(values, standardizedDescriptionList, isECR)
            );
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  maxHeight: '700px',
                  overflowY: 'auto',
                  padding: '30px 0 20px 0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    padding: '0px 50px',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      flexBasis: '271px',
                      flexGrow: 1,
                      paddingBottom: '10px',
                      paddingRight: '30px',
                    }}
                  >
                    <SelectFieldFormik
                      setFieldValue={setFieldValue}
                      value={values.standardizedDescription || ''}
                      labelName='Standardized Description'
                      items={standardizedDescriptionList}
                      name='standardizedDescription'
                      useLabel
                    />
                  </div>
                  <FieldArray
                    name='amounts'
                    render={(arrayHelpers) =>
                      headerArray.map((header, index) => (
                        <div
                          style={{
                            flexBasis: '271px',
                            flexGrow: 1,
                          }}
                          key={index}
                        >
                          <ModalTextField
                            width='271px'
                            type='text'
                            name={`amounts[${index}][1]`}
                            placeholder='Amount'
                            label={header}
                            isCurrency={!isECR}
                            isPercentage={isECR}
                            autoComplete='off'
                          />
                        </div>
                      ))
                    }
                  />
                </div>
              </div>
              <FormActions
                isSubmitting={isSubmitting}
                onClose={closeDialog}
                margin='0'
              />
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default EditBalanceForm;
