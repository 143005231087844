import React, { useMemo, useState, useEffect } from 'react';
import SortableHeader from './components/SortableHeader';
import { ActionIconButton, NameLink } from '../styled-components';
import MakeTable from './components/MakeTable';
import {
  Icon,
  Popover,
  Position,
  Menu,
  MenuItem,
  Intent,
} from '@blueprintjs/core';
import colors from '../../utils/colors';
import iconValue from '../../utils/icons';
import permissions from '../../utils/permissions';
import RowSelector from './components/RowSelector';
import {
  amountFormatter,
  checkPermissions,
  getMonthRange,
  downloadFiles,
  localDateFormatter,
} from '../../utils/functions';
import axios from 'axios';
import { downloadFile } from '../../api/data-ingestion';
import AppToast from '../../components/Toast';
import uniqBy from 'lodash/uniqBy';
import InvestmentDeleteDialogBox from '../dialog/investments/InvestmentDeleteDialogBox';
import {
  getInvestmentTypes,
  fetchAllInvestmentGroups,
  updateSingleInvestment,
} from '../../api/investments';
import { fetchSingleInvestment } from '../../actions/investments';
import InvestmentDialog from '../dialog/investments/InvestmentDialog';
import { connect } from 'react-redux';
import { fetchClientsForUser } from '../../actions/dashboard';
import { TableContainer } from './components/table-styled-components';

const InvestmentTableBase = ({
  appUser,
  singleClient,
  accountId,
  groupId,
  investments,
  isAllInvestmentsFetching,
  title,
  fetchSingleInvestment,
  fetchClientsForUser,
  userClients,
  showUsersClients,
  isCurrentInvestments,
  maturityLimit,
  isClientInvestments,
  fromMaturingInvestmentsWidget,
  updatePaginate,
  resultCount,
  pageCount,
  isServerSidePagination,
  pageSize,
  pageIndex,
  ...props
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [investment, setInvestment] = useState({});
  const [selectedInvestments, setSelectedInvestments] = useState([]);
  const [isShow, setIsShow] = useState(false);

  //For investment Form
  const [investmentDialogTitle, setInvestmentDialogTitle] = useState('');
  const [isInvestmentDialogOpen, setInvestmentDialogOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState({});
  const [investmentTypes, setInvestmentTypes] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  // This state is needed for tables that will have filter on initial load.
  // We need to setup this state to false when opening any action items.
  // and after action is completed, the original filter will be persisted.
  // InvestmentTable -> MakeTable -> GlobalFilter -> NotesFilterDialogBox
  const [resetInitialFilter, setResetInitialFilter] = useState(true);

  useEffect(() => {
    if (isInvestmentDialogOpen) {
      getInvestmentTypes()
        .then((res) => {
          setInvestmentTypes(res.data);
        })
        .catch((err) => {
          setInvestmentTypes([]);
        });
      fetchAllInvestmentGroups()
        .then((res) => {
          setAllGroups(res.data);
        })
        .catch((err) => {
          setAllGroups([]);
        });
    }
    fetchClientsForUser(appUser.userId);
  }, [isInvestmentDialogOpen, fetchClientsForUser, appUser]);

  const handleDeleteDialogOpen = (investment) => {
    investment.length
      ? setSelectedInvestments(investment)
      : setInvestment(investment);
    setIsDeleteOpen(true);
    setResetInitialFilter(false);
  };

  const handleDeleteDialogClose = () => {
    setSelectedInvestments([]);
    setInvestment({});
    setIsDeleteOpen(false);
  };

  const openEdit = (investment) => {
    setInvestmentDialogTitle('Edit Investment');
    setInvestmentDialogOpen(true);
    setSelectedInvestment(investment);
    setResetInitialFilter(false);
  };

  const closeInvestmentDialog = () => {
    setInvestmentDialogOpen(false);
    setInvestmentDialogTitle('');
    setSelectedInvestment({});
  };
  /**
   * Handles the edit of a single investment
   * @param {obj} investment
   */
  const handleEditInvestment = (investment) => {
    const investmentType = investmentTypes.find(
      (x) => x.label === investment.investmentTypeName
    );
    if (!investmentType) {
      AppToast.show({
        message: 'Selected investment type is not active.',
        intent: Intent.DANGER,
      });
      closeInvestmentDialog();
    } else {
      delete investment.investmentType;
      investment.investmentTypeId = investmentType.value;
      updateSingleInvestment(selectedInvestment.id, investment)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
          updatePaginate({ pageSize, pageIndex });
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to edit Investment.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          setResetInitialFilter(true);
          closeInvestmentDialog();
        });
    }
  };

  const handleDownloadStatement = async (investment) => {
    setResetInitialFilter(false);
    const fileId = investment.fileId;
    // (value 1 maps to empty in backend)
    if (fileId === 1) {
      AppToast.show({
        message: 'No file associated with this investment.',
        intent: Intent.DANGER,
      });
      return;
    } else {
      // call api to get presigned url
      const presignedURL = await downloadFile(fileId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => console.error(err));

      delete axios.defaults.headers.common['Authorization'];

      const name = presignedURL.substring(
        presignedURL.indexOf('Investment/'),
        presignedURL.indexOf('?')
      );
      const fileName = name;
      // Initiating the GET request to upload file
      axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => {
          // call download from utils function
          downloadFiles(res, fileName);
          AppToast.show({
            message: 'File downloaded successfully!',
            intent: Intent.SUCCESS,
          });
        })
        .catch(() => {
          AppToast.show({
            message: 'Failed to download file.',
            intent: Intent.DANGER,
          });
        });
    }
  };

  const handleBulkDownloadInvestment = async (investmentList) => {
    // remove duplicate file ids to download only one file
    // and remove any items from list that have empty file names (value 1 maps to empty)
    const list = uniqBy(investmentList, 'fileId').filter(
      (item) => item.fileId !== 1
    );

    for (const file of list) {
      // call api to get presigned url
      const presignedURL = await downloadFile(file.fileId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
        });

      delete axios.defaults.headers.common['Authorization'];

      const name = presignedURL.substring(
        presignedURL.indexOf('Investment/'),
        presignedURL.indexOf('?')
      );
      const fileName = name;
      // Initiating the GET request to download file
      await axios
        .get(presignedURL, { responseType: 'blob' })
        .then((res) => {
          // call download from utils function
          downloadFiles(res, fileName);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleChangeShow = () => {
    setResetInitialFilter(false);
    setIsShow(!isShow);
  };

  const hiddenColumns =
    'Columns: Fund, Settlement Date, Call Date, Settlement Amount, Premium / Discount, Coupon Rate';

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <RowSelector
            {...getToggleAllRowsSelectedProps()}
            style={{ height: '18px', width: '18px' }}
          />
        ),
        Cell: ({ row }) => (
          <RowSelector
            {...row.getToggleRowSelectedProps()}
            style={{ height: '15px', width: '15px' }}
          />
        ),
        isVisible: true,
        width: '1%',
        sticky: 'left',
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header={
              isClientInvestments
                ? 'Account Name'
                : 'Client / Account Relationship'
            }
          />
        ),
        accessor: 'clientAccountRelationship',
        width: '10%',
        sortType: 'caseInsensitiveSort',
        filter: 'text',
        isVisible: true,
        Cell: ({ cell: { value }, row }) => {
          // account is after the ','
          // adding 2 gets us past the ', ' to the first letter of the account name.
          const accountIndexStart = value.indexOf(',') + 2;
          const clientInvestmentsValue = value.slice(accountIndexStart);

          return (
            <NameLink
              to={{
                pathname: `/client-management/${row.original.clientId}/${row.original.accountId}`,
              }}
            >
              {isClientInvestments ? clientInvestmentsValue : value}
            </NameLink>
          );
        },
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Face Value'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'faceValue',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Maturity Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'maturityDate',
        width: '8%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Investment Type'
            sizeFont={'0.85rem'}
          />
        ),
        accessor: 'investmentTypeName',
        width: '8%',
        sortType: 'caseInsensitiveSort',
        filter: 'text',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Fund' sizeFont={'0.85rem'} />
        ),
        accessor: 'fund',
        width: '8%',
        sortType: 'caseInsensitiveSort',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Interest Rate'
            sizeFont={'0.85rem'}
          />
        ),
        accessor: 'interestRate',
        Cell: ({ cell: { value } }) => `${value}`,
        width: '5%',
        sortType: 'caseInsensitiveSort',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Liquidity Level'
            sizeFont={'0.75rem'}
            toolTipText={'This field is determined by Analytics.'}
          />
        ),
        accessor: 'liquidityLevel',
        width: '5%',
        sortType: 'basic',
        filter: 'text',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Days Until Maturity'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'daysUntilMaturity',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <>
            <SortableHeader
              column={column}
              header='ID'
              toolTipText={
                'Market Identifier for the Security (Examples: CUSIP, SEDOL, ISIN, etc.).'
              }
            />
          </>
        ),
        accessor: 'investmentId',
        width: '5%',
        sortType: 'basic',
        isVisible: true,
      },

      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Coupon Rate'
            sizeFont={'0.85rem'}
          />
        ),
        Cell: ({ cell: { value } }) => (value ? `${value}%` : ''),
        accessor: 'couponRate',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        isVisible: true,
      },

      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Call Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'callDate',
        width: '8%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Settlement Date'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'settlementDate',
        width: '5%',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Settlement Amount'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'settlementAmount',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Interest at Maturity'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'interestAtMaturity',
        width: '5%',
        sortType: 'basic',
        filter: 'numberRange',
        Cell: ({ cell: { value } }) => amountFormatter(value),
        isVisible: true,
      },
      {
        Header: ({ column }) => (
          <SortableHeader
            column={column}
            header='Premium or Discount'
            sizeFont={'0.75rem'}
          />
        ),
        accessor: 'premiumDiscount',
        width: '8%',
        sortType: 'basic',
        filter: 'text',
        isVisible: isShow,
      },
      {
        Header: ({ column }) => (
          <SortableHeader column={column} header='Bank' sizeFont={'0.75rem'} />
        ),
        accessor: 'bank',
        width: '5%',
        sortType: 'caseInsensitiveSort',
        filter: 'text',
        isVisible: true,
      },
      {
        accessor: 'clientId',
        filter: 'usersInvestments',
        isVisible: false,
      },
      {
        accessor: 'createdAt',
        sortType: 'customDateTimeSort',
        filter: 'dateRange',
        isVisible: false,
        Cell: ({ cell: { value } }) => localDateFormatter(value),
      },
      {
        Header: () => 'Actions',
        id: 'actions',
        width: '2%',
        Cell: ({ row }) => {
          return (
            <Popover
              className='table-action-menu'
              interactionKind='click'
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              content={
                <Menu>
                  {checkPermissions(appUser.permList, [
                    permissions.EDIT_INVESTMENTS,
                  ]) && (
                    <MenuItem
                      text='Edit Investment'
                      onClick={() => openEdit(row.original)}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {checkPermissions(appUser.permList, [
                    permissions.DOWNLOAD_FILES,
                  ]) && (
                    <MenuItem
                      text='Download Source'
                      onClick={() => {
                        handleDownloadStatement(row.original);
                      }}
                      style={{
                        color: `${colors.purpleText}`,
                        fontWeight: '700',
                      }}
                    />
                  )}
                  {((groupId &&
                    checkPermissions(appUser.permList, [
                      permissions.REMOVE_INVESTMENT_GROUP_ASSOCIATIONS,
                    ])) ||
                    checkPermissions(appUser.permList, [
                      permissions.DELETE_INVESTMENTS,
                    ])) && (
                    <>
                      <Menu.Divider />
                      <MenuItem
                        text={
                          groupId ? 'Remove Investment' : 'Delete Investment'
                        }
                        onClick={() => {
                          handleDeleteDialogOpen(row.original);
                        }}
                        style={{
                          color: `${colors.red}`,
                          fontWeight: '700',
                          paddingTop: ' 5px',
                          paddingBottom: ' 5px',
                          marginBottom: '10px',
                        }}
                      />
                    </>
                  )}
                </Menu>
              }
            >
              <ActionIconButton>
                <Icon
                  icon={iconValue.menu}
                  iconSize={16}
                  color={colors.primary}
                />
              </ActionIconButton>
            </Popover>
          );
        },
        sticky: 'right',
        isVisible: checkPermissions(appUser.permList, [
          permissions.VIEW_INVESTMENTS,
          permissions.EDIT_INVESTMENTS,
          permissions.DELETE_INVESTMENTS,
          permissions.REMOVE_INVESTMENT_GROUP_ASSOCIATIONS,
        ]),
      },
    ],
    [appUser, groupId, isShow, isClientInvestments]
  );

  return (
    <TableContainer padding='0rem'>
      <MakeTable
        appUser={appUser}
        data={investments}
        columns={columns}
        groupId={groupId}
        tableTitle={title}
        isFetching={isAllInvestmentsFetching}
        investments={investments}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        handleBulkDownloadInvestment={handleBulkDownloadInvestment}
        rangeFilter={showUsersClients ? [] : getMonthRange(1)}
        rangeFilterName='Created Date'
        resetInitialFilter={resetInitialFilter}
        isShow={isShow}
        hiddenColumns={hiddenColumns}
        handleChangeShow={handleChangeShow}
        userClients={userClients}
        showUsersClients={showUsersClients}
        isCurrentInvestments={isCurrentInvestments}
        maturityLimit={maturityLimit}
        fromMaturingInvestmentsWidget={fromMaturingInvestmentsWidget}
        updatePaginate={updatePaginate}
        resultCount={resultCount}
        pageCount={pageCount}
        isServerSidePagination={isServerSidePagination}
        setResetInitialFilter={setResetInitialFilter}
        {...props}
      />
      <InvestmentDialog
        isOpen={isInvestmentDialogOpen}
        title={investmentDialogTitle}
        closeDialog={closeInvestmentDialog}
        handleEditInvestment={handleEditInvestment}
        investment={selectedInvestment}
        investmentTypes={investmentTypes}
        allGroups={allGroups}
      />
      <InvestmentDeleteDialogBox
        isOpen={isDeleteOpen}
        title={
          // if investment is not an object use bulk title
          Object.keys(investment).length > 0
            ? 'Delete Investment'
            : `Delete Selected Investments (${selectedInvestments.length})`
        }
        singleClient={singleClient}
        accountId={accountId}
        groupId={groupId}
        handleClose={handleDeleteDialogClose}
        investment={investment}
        currentInvestment={true}
        selectedInvestments={selectedInvestments}
        setResetInitialFilter={setResetInitialFilter}
        updatePaginate={updatePaginate}
        pageSize={pageSize}
        pageIndex={pageIndex}
      />
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({
  userClients: state.dashboard.userClients,
});

const InvestmentTable = connect(mapStateToProps, {
  fetchSingleInvestment,
  fetchClientsForUser,
})(InvestmentTableBase);

export default InvestmentTable;
