import React from 'react';
import { Dialog } from '@blueprintjs/core';
import StartIngestionForm from '../../forms/client-profile-forms/StartIngestionForm';

const StartIngestionDialog = ({
  isOpen,
  closeDialog,
  handleStartIngestion,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={closeDialog}
      title={'Start Ingestion'}
      isCloseButtonShown={false}
    >
      <StartIngestionForm 
        handleClose={closeDialog}
        handleStartIngestion={handleStartIngestion}
      />
    </Dialog>
  );
};

export default StartIngestionDialog;
