import * as api from '../api/dashboard';

export const fetchClientsForUser = (userId) => ({
  type: 'FETCH_USER_CLIENTS',
  payload: api.fetchClientsForUser(userId),
});

export const fetchReportsInProgress = (userId) => ({
  type: 'FETCH_REPORTS_IN_PROGRESS',
  payload: api.fetchReportsInProgress(userId),
});

export const fetchNegotiatedRates = () => ({
  type: 'FETCH_NEGOTIATED_RATES',
  payload: api.fetchNegotiatedRates(),
});

export const fetchSingleNegotiatedRate = (rateId) => ({
  type: 'FETCH_SINGLE_NEGOTIATED_RATE',
  payload: api.fetchSingleNegotiatedRate(rateId),
});

export const fetchMaturingInvestments = (userId) => ({
  type: 'FETCH_MATURING_INVESTMENTS',
  payload: api.fetchMaturingInvestments(userId),
});
