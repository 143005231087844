import {
  startIngestionTransactionsSingleBank,
  startIngestionTransactionsClient,
  startIngestionTreasuryServicesSingleBank,
  startIngestionTreasuryServicesClient,
  startIngestionInvestmentsSingleBank,
  startIngestionInvestmentsClient,
  startIngestionHybridAccountSingleBank,
  startIngestionHybridAccountClient
} from '../../../../../api/data-ingestion';
import { Intent } from '@blueprintjs/core';
import AppToast from '../../../../../components/Toast';

export const handleIngestionTransactionsSingleBank = (
  clientBankRelationshipId,
  batch
) => {
  startIngestionTransactionsSingleBank(clientBankRelationshipId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start transactions ingestion for bank.',
        intent: Intent.DANGER,
      });
    });
};

export const handleIngestionTransactionsClient = (clientId, batch) => {
  startIngestionTransactionsClient(clientId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start transactions ingestion for client.',
        intent: Intent.DANGER,
      });
    });
};

export const handleIngestionTreasuryServicesSingleBank = (
  clientBankRelationshipId,
  batch
) => {
  startIngestionTreasuryServicesSingleBank(clientBankRelationshipId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start treasury services ingestion for bank.',
        intent: Intent.DANGER,
      });
    });
};

export const handleIngestionTreasuryServicesClient = (clientId, batch) => {
  startIngestionTreasuryServicesClient(clientId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start treasury services ingestion for client.',
        intent: Intent.DANGER,
      });
    });
};

export const handleIngestionInvestmentsSingleBank = (
  clientBankRelationshipId,
  batch
) => {
  startIngestionInvestmentsSingleBank(clientBankRelationshipId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start investments ingestion for bank.',
        intent: Intent.DANGER,
      });
    });
};

export const handleIngestionInvestmentsClient = (clientId, batch) => {
  startIngestionInvestmentsClient(clientId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start investments ingestion for client.',
        intent: Intent.DANGER,
      });
    });
};

export const handleIngestionHybridAccountSingleBank = (
  clientBankRelationshipId,
  batch
) => {
  startIngestionHybridAccountSingleBank(clientBankRelationshipId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start hybrid account ingestion for bank.',
        intent: Intent.DANGER,
      });
    });
};

export const handleIngestionHybridAccountClient = (clientId, batch) => {
  startIngestionHybridAccountClient(clientId, batch)
    .then((res) => {
      AppToast.show({
        message: res.data.msg,
        intent: Intent.SUCCESS,
      });
    })
    .catch((err) => {
      AppToast.show({
        message:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : 'Failed to start hybrid account ingestion for client.',
        intent: Intent.DANGER,
      });
    });
};
