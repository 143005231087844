import React, { useState, useEffect, useRef } from 'react';
import { Dialog, RadioGroup, Radio } from '@blueprintjs/core';
import SelectField from '../../../components/select-fields/SelectField';
import { AlignCenter } from '../../../components/styled-components';
import NumberRangeField from '../../../components/select-fields/NumberRangeField';
import DateSelectPlainField from '../../../components/select-fields/DateSelectPlainField';
import RadioPagination from '../../../components/RadioPagination';
import {
  amountToFilterText,
  numberRangeToFilterText,
  percentageToFilterText,
  dateToFilterText,
  textToFilterText,
  dateRangeChecker,
  getXDaysFromNow,
} from '../../../utils/functions';
import TableFilterActions from './TableFilterActions';
import SelectFiled from '../../../components/select-fields/SelectField';

const InvestmentsFilterDialogBox = ({
  isOpen,
  title,
  handleClose,
  investments,
  setFilter,
  setAllFilters,
  setFilterSummary,
  rangeFilter,
  resetInitialFilter,
  userClients,
  showUsersClients,
  isCurrentInvestments,
  maturityLimit,
  fromMaturingInvestmentsWidget,
  updatePaginate,
  pageSize,
  pageIndex,
  setResetInitialFilter,
  searchTerm,
  setGetFilters,
  sortOrder,
  stopFunc,
  prevSearchTerm,
  prevSort,
  checkSearchValue,
  moreNextPrev,
  setMoreNextPrev,
  ...props
}) => {
  //Values
  const [clientAccount, setClientAccount] = useState('');
  const [faceValueLow, setFaceValueLow] = useState('');
  const [faceValueHigh, setFaceValueHigh] = useState('');
  const [investmentTypeName, setInvestmentTypeName] = useState('');
  const [maturityDateRange, setMaturityDateRange] = useState([]);
  const [interestRateHigh, setInterestRateHigh] = useState('');
  const [interestRateLow, setInterestRateLow] = useState('');
  const [liquidityLevel, setLiquidityLevel] = useState('');
  const [daysActiveHigh, setDaysActiveHigh] = useState('');
  const [daysActiveLow, setDaysActiveLow] = useState('');
  const [id, setId] = useState('');
  const [callDateRange, setCallDateRange] = useState([]);
  const [settlementDate, setSettlementDate] = useState([]);
  const [settlementAmountHigh, setSettlementAmountHigh] = useState('');
  const [settlementAmountLow, setSettlementAmountLow] = useState('');
  const [interestAtMaturityHigh, setInterestAtMaturityHigh] = useState('');
  const [interestAtMaturityLow, setInterestAtMaturityLow] = useState('');
  const [bank, setBank] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [createdDateRange, setCreatedDateRange] = useState([]);
  const [premiumDiscount, setPremiumDiscount] = useState('');
  const [couponRateLow, setCouponRateLow] = useState('');
  const [couponRateHigh, setCouponRateHigh] = useState('');
  const [eitherOr, setEitherOr] = useState('Yes');

  //Items
  const [clientAccountItems, setClientAccountItems] = useState([]);
  const [investmentTypeItems, setInvestmentTypeItems] = useState([]);
  const [liquidityLevelItems, setLiquidityLevelItems] = useState([]);
  const [idItems, setIdItems] = useState([]);
  const [bankItems, setBankItems] = useState([]);

  const oldSortValue = useRef(sortOrder);
  const clickedClearFilter = useRef();
  const filters = useRef([]);

  useEffect(() => {
    if (resetInitialFilter || moreNextPrev) {
      if (
        (!showUsersClients && !investments.length) ||
        (!showUsersClients && sortOrder)
      ) {
        if (
          clickedClearFilter.current !== true ||
          clickedClearFilter.current === undefined
        ) {
          setCreatedDateRange(rangeFilter);
        }
        // push to filter for pagination
        clickedClearFilter.current !== true &&
          rangeFilter &&
          filters.current.push(dateToFilterText(rangeFilter, 'createdAt'));
        resetInitialFilter && setResetInitialFilter(false);
        setGetFilters(filters.current);
        if (prevSort === sortOrder && stopFunc === true) {
          checkSearchValue(prevSearchTerm);
        }
        if (
          (moreNextPrev ||
            (stopFunc === false && prevSearchTerm === searchTerm)) &&
          (oldSortValue.current !== sortOrder ||
            (moreNextPrev && oldSortValue.current === sortOrder) ||
            (oldSortValue.current.length === 0 && sortOrder.length === 0))
        ) {
          updatePaginate(
            { pageSize, pageIndex },
            filters.current,
            searchTerm,
            sortOrder
          );
          oldSortValue.current = sortOrder;
          setMoreNextPrev(false);
        }
      }
      if (
        (isCurrentInvestments && showUsersClients && !investments.length) ||
        (isCurrentInvestments && showUsersClients && sortOrder)
      ) {
        //will be true when navigating from the client profile widget
        if (!fromMaturingInvestmentsWidget) {
          setFilter(
            'clientId',
            userClients.map((client) => client.clientId)
          );
          setEitherOr('No');
        }
        clickedClearFilter.current !== true &&
          maturityLimit &&
          setMaturityDateRange(
            getXDaysFromNow(Number(maturityLimit.globalSettingValue))
          );
        // push to filter for pagination
        if (
          maturityLimit &&
          (clickedClearFilter.current !== true ||
            clickedClearFilter.current === undefined)
        )
          filters.current.push(
            dateToFilterText(
              getXDaysFromNow(Number(maturityLimit.globalSettingValue)),
              'maturityDate'
            )
          );
        resetInitialFilter && setResetInitialFilter(false);
        setGetFilters(filters.current);
        if (prevSort === sortOrder && stopFunc === true) {
          checkSearchValue(prevSearchTerm);
        }
        if (
          (moreNextPrev ||
            (stopFunc === false && prevSearchTerm === searchTerm)) &&
          (oldSortValue.current !== sortOrder ||
            (moreNextPrev && oldSortValue.current === sortOrder) ||
            (oldSortValue.current.length === 0 && sortOrder.length === 0))
        ) {
          updatePaginate(
            { pageSize, pageIndex },
            filters.current,
            searchTerm,
            sortOrder
          );
          oldSortValue.current = sortOrder;
          setMoreNextPrev(false);
        }
      }
    }
    if (investments.length) {
      const clientAccountRelItems = investments
        .map((investment) => investment.clientAccountRelationship)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((name) => ({
          value: name,
          label: name,
        }));
      setClientAccountItems(clientAccountRelItems);

      const investmentTypes = investments
        .map((investment) => investment.investmentTypeName)
        .filter(
          (value, index, self) => self.indexOf(value) === index && value != null
        )
        .map((name) => ({
          value: name,
          label: name,
        }));
      setInvestmentTypeItems(investmentTypes);

      const liquidityLevels = investments
        .map((investment) => investment.liquidityLevel)
        .filter(
          (value, index, self) => self.indexOf(value) === index && value != null
        )
        .map((name) => ({
          value: name,
          label: name,
        }));
      setLiquidityLevelItems(liquidityLevels);

      const ids = investments
        .map((investment) => investment.investmentId)
        .filter(
          (value, index, self) => self.indexOf(value) === index && value != null
        )
        .map((name) => ({
          value: name,
          label: name,
        }));
      setIdItems(ids);

      const banks = investments
        .map((investment) => investment.bank)
        .filter(
          (value, index, self) => self.indexOf(value) === index && value != null
        )
        .map((name) => ({
          value: name,
          label: name,
        }));
      setBankItems(banks);
    }
  }, [
    investments,
    rangeFilter,
    setFilter,
    userClients,
    resetInitialFilter,
    isCurrentInvestments,
    showUsersClients,
    maturityLimit,
    setFilterSummary,
    fromMaturingInvestmentsWidget,
    updatePaginate,
    pageSize,
    pageIndex,
    setResetInitialFilter,
    searchTerm,
    checkSearchValue,
    prevSearchTerm,
    prevSort,
    sortOrder,
    stopFunc,
    setGetFilters,
    moreNextPrev,
    setMoreNextPrev,
  ]);

  const getFilterSummary = () => {
    const filters = [];
    dateRangeChecker(createdDateRange) &&
      filters.push(dateToFilterText(createdDateRange, 'Created date'));
    filters.push({
      name: 'Show All Investments',
      value:
        eitherOr === 'No'
          ? 'No, only show my clients investments'
          : 'Yes, show all investments',
    });
    clientAccount &&
      filters.push(textToFilterText(clientAccount, 'Client Account'));
    (faceValueHigh || faceValueLow) &&
      filters.push(
        amountToFilterText(faceValueLow, faceValueHigh, 'Face Value')
      );
    dateRangeChecker(maturityDateRange) &&
      filters.push(dateToFilterText(maturityDateRange, 'Maturity date'));
    (interestRateHigh || interestRateLow) &&
      filters.push(
        percentageToFilterText(
          interestRateLow,
          interestRateHigh,
          'Interest Rate'
        )
      );
    liquidityLevel &&
      filters.push(textToFilterText(liquidityLevel, 'Liquidity Level'));
    (daysActiveLow || daysActiveHigh) &&
      filters.push(
        numberRangeToFilterText(daysActiveLow, daysActiveHigh, 'Days Active')
      );
    id && filters.push(textToFilterText(id, 'ID'));
    dateRangeChecker(callDateRange) &&
      filters.push(dateToFilterText(callDateRange, 'Call date'));
    dateRangeChecker(settlementDate) &&
      filters.push(dateToFilterText(settlementDate, 'Purchase date'));
    (settlementAmountHigh || settlementAmountLow) &&
      filters.push(
        amountToFilterText(
          settlementAmountLow,
          settlementAmountHigh,
          'Settlement Amount'
        )
      );
    (interestAtMaturityHigh || interestAtMaturityLow) &&
      filters.push(
        amountToFilterText(
          interestAtMaturityLow,
          interestAtMaturityHigh,
          'Yield to Maturity'
        )
      );
    premiumDiscount &&
      filters.push(textToFilterText(premiumDiscount, 'Premium/Discount'));
    bank && filters.push(textToFilterText(bank, 'Bank'));
    (couponRateLow || couponRateHigh) &&
      filters.push(
        amountToFilterText(couponRateLow, couponRateHigh, 'Coupon Rate')
      );
    investmentTypeName &&
      filters.push(textToFilterText(investmentTypeName, 'Investment Type'));
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());

    // manual filter to send to backend for server side pagination
    dateRangeChecker(createdDateRange) &&
      filters.current.push(dateToFilterText(createdDateRange, 'createdAt'));
    filters.current.push({
      name: 'Show All Investments',
      value:
        eitherOr === 'No'
          ? 'No, only show my clients investments'
          : 'Yes, show all investments',
    });
    clientAccount &&
      filters.current.push(
        textToFilterText(clientAccount, 'clientAccountRelationship')
      );
    (faceValueHigh || faceValueLow) &&
      filters.current.push(
        amountToFilterText(faceValueLow, faceValueHigh, 'faceValue')
      );
    dateRangeChecker(maturityDateRange) &&
      filters.current.push(dateToFilterText(maturityDateRange, 'maturityDate'));
    (interestRateHigh || interestRateLow) &&
      filters.current.push(
        percentageToFilterText(
          interestRateLow,
          interestRateHigh,
          'interestRate'
        )
      );
    liquidityLevel &&
      filters.current.push(textToFilterText(liquidityLevel, 'liquidityLevel'));
    (daysActiveLow || daysActiveHigh) &&
      filters.current.push(
        numberRangeToFilterText(
          daysActiveLow,
          daysActiveHigh,
          'daysUntilMaturity'
        )
      );
    id && filters.current.push(textToFilterText(id, 'investmentId'));
    dateRangeChecker(callDateRange) &&
      filters.current.push(dateToFilterText(callDateRange, 'callDate'));
    dateRangeChecker(settlementDate) &&
      filters.current.push(dateToFilterText(settlementDate, 'settlementDate'));
    (settlementAmountHigh || settlementAmountLow) &&
      filters.current.push(
        amountToFilterText(
          settlementAmountLow,
          settlementAmountHigh,
          'settlementAmount'
        )
      );
    (interestAtMaturityHigh || interestAtMaturityLow) &&
      filters.current.push(
        amountToFilterText(
          interestAtMaturityLow,
          interestAtMaturityHigh,
          'interestAtMaturity'
        )
      );
    premiumDiscount &&
      filters.current.push(
        textToFilterText(premiumDiscount, 'premiumDiscount')
      );
    bank && filters.current.push(textToFilterText(bank, 'bank'));
    (couponRateLow || couponRateHigh) &&
      filters.current.push(
        amountToFilterText(couponRateLow, couponRateHigh, 'couponRate')
      );
    investmentTypeName &&
      filters.current.push(
        textToFilterText(investmentTypeName, 'investmentType')
      );
    setGetFilters(filters.current);
    updatePaginate(
      { pageSize, pageIndex },
      filters.current,
      searchTerm,
      sortOrder
    );
    closeReset();
  };

  const handleClearFilters = () => {
    setClientAccount('');
    setFaceValueLow('');
    setFaceValueHigh('');
    setInvestmentTypeName('');
    setMaturityDateRange([]);
    setInterestRateHigh('');
    setInterestRateLow('');
    setLiquidityLevel('');
    setDaysActiveHigh('');
    setDaysActiveLow('');
    setId('');
    setCallDateRange([]);
    setSettlementDate([]);
    setSettlementAmountHigh('');
    setSettlementAmountLow('');
    setInterestAtMaturityHigh('');
    setInterestAtMaturityLow('');
    setBank('');
    setAllFilters([]);
    setCreatedDateRange([]);
    setFilterSummary([]);
    setPremiumDiscount('');
    setCouponRateHigh('');
    setCouponRateLow('');
    setGetFilters([]);
    setResetInitialFilter(true);
    clickedClearFilter.current = true;
    filters.current = [];
    updatePaginate(
      { pageSize, pageIndex },
      filters.current,
      searchTerm,
      sortOrder
    );
    closeReset();
  };
  const closeReset = () => {
    handleClose();
    setPageNumber(0);
  };

  const handleChangeEitherOr = (event) => {
    setEitherOr(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={closeReset}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 0 20px 0' }}>
        <div style={{ marginBottom: '10px' }}>
          {pageNumber === 0 && (
            <>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <DateSelectPlainField
                  labelName={'Created Date'}
                  dateRange={createdDateRange}
                  handleDateChange={setCreatedDateRange}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <SelectField
                  selectValueFromList={setBank}
                  value={bank}
                  labelName={'Bank'}
                  items={bankItems}
                />
                <SelectField
                  selectValueFromList={setClientAccount}
                  value={clientAccount}
                  labelName={'Client Account'}
                  items={clientAccountItems}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Face Value'}
                  selectLow={setFaceValueLow}
                  selectHigh={setFaceValueHigh}
                  high={faceValueHigh}
                  low={faceValueLow}
                  isCurrency={true}
                />
              </AlignCenter>
              {props && props.match && props.match.path.includes('investment') && (
                <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                  <div style={{ width: '271px' }} className='radio-group'>
                    <RadioGroup
                      label={'Show All Investments?'}
                      onChange={handleChangeEitherOr}
                      selectedValue={eitherOr}
                      inline={true}
                      disabled={
                        !isCurrentInvestments || fromMaturingInvestmentsWidget
                      }
                    >
                      <div
                        style={{ width: '271px' }}
                        className='radio-group'
                      ></div>
                      <Radio
                        label='Yes, show me all investments.'
                        value='Yes'
                        style={{ marginRight: '20px' }}
                      />
                      <Radio
                        label='No, only show me investments for my clients.'
                        value='No'
                      />
                    </RadioGroup>
                  </div>
                  <div style={{ width: '271px' }}></div>
                </AlignCenter>
              )}
            </>
          )}
          {pageNumber === 1 && (
            <>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <DateSelectPlainField
                  labelName={'Maturity Date'}
                  dateRange={maturityDateRange}
                  handleDateChange={setMaturityDateRange}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Interest Rate'}
                  selectLow={setInterestRateLow}
                  selectHigh={setInterestRateHigh}
                  high={interestRateHigh}
                  low={interestRateLow}
                  isPercentage={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Days Until Maturity'}
                  selectLow={setDaysActiveLow}
                  selectHigh={setDaysActiveHigh}
                  high={daysActiveHigh}
                  low={daysActiveLow}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <DateSelectPlainField
                  labelName={'Call Date'}
                  dateRange={callDateRange}
                  handleDateChange={setCallDateRange}
                />
              </AlignCenter>
            </>
          )}
          {pageNumber === 2 && (
            <>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <SelectField
                  selectValueFromList={setId}
                  value={id}
                  labelName={'ID'}
                  items={idItems}
                />
                <SelectField
                  selectValueFromList={setLiquidityLevel}
                  value={liquidityLevel}
                  labelName={'Liquidity Level'}
                  items={liquidityLevelItems}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <DateSelectPlainField
                  labelName={'Settlement Date'}
                  dateRange={settlementDate}
                  handleDateChange={setSettlementDate}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Settlement Amount'}
                  selectLow={setSettlementAmountLow}
                  selectHigh={setSettlementAmountHigh}
                  high={settlementAmountHigh}
                  low={settlementAmountLow}
                  isCurrency={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Interest at Maturity'}
                  selectLow={setInterestAtMaturityLow}
                  selectHigh={setInterestAtMaturityHigh}
                  high={interestAtMaturityHigh}
                  low={interestAtMaturityLow}
                  isCurrency={true}
                />
              </AlignCenter>
            </>
          )}
          {pageNumber === 3 && (
            <>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <NumberRangeField
                  labelName={'Coupon Rate'}
                  selectLow={setCouponRateLow}
                  selectHigh={setCouponRateHigh}
                  high={couponRateHigh}
                  low={couponRateLow}
                  isPercentage={true}
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='10px 0'>
                <SelectFiled
                  labelName='Premium/Discount'
                  items={[
                    { label: 'Premium', value: 'Premium' },
                    { label: 'Discount', value: 'Discount' },
                  ]}
                  selectValueFromList={setPremiumDiscount}
                  value={premiumDiscount}
                />
                <SelectField
                  selectValueFromList={setInvestmentTypeName}
                  value={investmentTypeName}
                  labelName={'Investment Type'}
                  items={investmentTypeItems}
                />
              </AlignCenter>
              <div style={{ flexGrow: '1' }}></div>
            </>
          )}
        </div>
      </div>
      <RadioPagination
        pages={4}
        onClickSetPage={setPageNumber}
        page={pageNumber}
      />
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

export default InvestmentsFilterDialogBox;
