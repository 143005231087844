import React from 'react';
import { Dialog } from '@blueprintjs/core';
import AccountLinkForm from '../../forms/account-profile-forms/AccountLinkForm';

const AccountLinkDialogBox = ({
  isOpen,
  title,
  setTitle,
  closeDialog,
  linkedToAccountId,
  clientAccounts,
  selectLinkedAccountFromList,
  accountLinkError,
  handleCreateAccountLink,
  handleEditAccountLink,
  handleDeleteAccountLink,
  appUser,
  isDeleting,
}) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title}
        isCloseButtonShown={false}
      >
        <AccountLinkForm
          isOpen={isOpen}
          title={title}
          setTitle={setTitle}
          closeDialog={closeDialog}
          linkedToAccountId={linkedToAccountId}
          clientAccounts={clientAccounts}
          accountLinkError={accountLinkError}
          selectLinkedAccountFromList={selectLinkedAccountFromList}
          handleCreateAccountLink={handleCreateAccountLink}
          handleEditAccountLink={handleEditAccountLink}
          handleDeleteAccountLink={handleDeleteAccountLink}
          appUser={appUser}
          isDeleting={isDeleting}
        />
      </Dialog>
    </>
  );
};

export default AccountLinkDialogBox;
