import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AlignCenter } from '../../styled-components';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import DateSelectField from '../../select-fields/DateSelectField';
import { stringToDate, dateToString } from '../../../utils/functions';
import GroupsMultiSelectField from '../../multi-select-fields/GroupsMultiSelectField';
import FormActions from '../FormActions';

const investmentSchema = Yup.object().shape({
  faceValue: Yup.string()
    .required('Required!')
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  maturityDate: Yup.string().required('Required!').nullable(),
  investmentTypeName: Yup.string().required('Required!'),
  interestRate: Yup.string()
    .required('Required!')
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  investmentId: Yup.string(),
  callDate: Yup.string().required('Required!').nullable(),
  settlementDate: Yup.string().required('Required!').nullable(),
  settlementAmount: Yup.string()
    .required('Required!')
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  fund: Yup.string(),
  couponRate: Yup.string().matches(
    /^[-]?\d*\.?\d*$/,
    'Field must be a number.'
  ),
});

const transformData = (investment, investmentTypes) => {
  investment.investmentGroupIdList = investment.investmentGroupIdList.map(
    (group) => group.groupId
  );
  investment.callDate = dateToString(investment.callDate, 'YYYY-MM-DD', true);
  investment.maturityDate = dateToString(investment.maturityDate, 'YYYY-MM-DD', true);
  investment.settlementDate = dateToString(investment.settlementDate, 'YYYY-MM-DD', true);
  return investment;
};

const removePercentage = (interestRate) => {
  if (!interestRate) return interestRate;
  return interestRate.replace('%', '');
};

const InvestmentForm = ({
  closeDialog,
  clientAccountRelationship,
  faceValue,
  maturityDate,
  investmentTypeName,
  investmentTypes,
  interestRate,
  liquidityLevel,
  daysUntilMaturity,
  investmentId,
  callDate,
  settlementDate,
  settlementAmount,
  interestAtMaturity,
  premiumDiscount,
  bank,
  investmentGroupIdList,
  setInvestmentGroupIdList,
  allGroups,
  handleEditInvestment,
  fund,
  couponRate,
  isStagingInvestment,
}) => {
  return (
    <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
      <Formik
        initialValues={{
          clientAccountRelationship,
          faceValue,
          maturityDate: stringToDate(maturityDate),
          investmentTypeName,
          interestRate: removePercentage(String(interestRate)),
          liquidityLevel,
          daysUntilMaturity,
          investmentId,
          callDate: stringToDate(callDate),
          settlementDate: stringToDate(settlementDate),
          settlementAmount,
          interestAtMaturity,
          premiumDiscount,
          bank,
          investmentGroupIdList,
          fund,
          couponRate,
        }}
        onSubmit={(values) => {
          handleEditInvestment(transformData(values, investmentTypes));
        }}
        validationSchema={investmentSchema}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={values.clientAccountRelationship || ''}
                labelName={'Account Relationship'}
                items={[]}
                isDisabled={true}
                name='clientAccountRelationship'
              />
              <ModalTextField
                type='text'
                name='faceValue'
                placeholder='Face Value'
                label='Face Value'
                value={values.faceValue}
                autoComplete='off'
                isCurrency={true}
              />
              <DateSelectField
                setFieldValue={setFieldValue}
                value={values.maturityDate || null}
                labelName={'Maturity Date'}
                name='maturityDate'
              />
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={values.investmentTypeName || ''}
                labelName={'Investment Type'}
                items={investmentTypes}
                name='investmentTypeName'
                // send use label as true to show name of type in input field and not the id
                useLabel={true}
              />
            </AlignCenter>

            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <ModalTextField
                type='text'
                name='interestRate'
                placeholder='Interest Rate'
                label='Interest Rate'
                value={values.interestRate || ''}
                autoComplete='off'
                isPercentage={true}
              />
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={values.liquidityLevel || ''}
                labelName={'Liquidity Level'}
                items={[]}
                name='liquidityLevel'
                isDisabled={true}
                toolTipText={'This field is determined by Analytics.'}
              />
              <ModalTextField
                type='text'
                name='daysUntilMaturity'
                placeholder='Days Until Maturity'
                label='Days Until Maturity'
                value={values.daysUntilMaturity || 0}
                autoComplete='off'
                isDisabled={true}
              />
              <ModalTextField
                type='text'
                name='investmentId'
                placeholder='ID'
                label='ID'
                value={values.investmentId || ''}
                autoComplete='off'
                toolTipText={
                  'Market Identifier for the Security (Examples: CUSIP, SEDOL, ISIN, etc.).'
                }
              />
            </AlignCenter>

            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <DateSelectField
                setFieldValue={setFieldValue}
                value={values.callDate || null}
                labelName={'Call Date'}
                name='callDate'
              />
              <DateSelectField
                setFieldValue={setFieldValue}
                value={values.settlementDate || null}
                labelName={'Settlement Date'}
                name='settlementDate'
              />
              <ModalTextField
                type='text'
                name='settlementAmount'
                placeholder='Settlement Amount'
                label='Settlement Amount'
                value={values.settlementAmount || ''}
                autoComplete='off'
                isCurrency={true}
              />
              <ModalTextField
                type='text'
                name='interestAtMaturity'
                placeholder='Interest at Maturity'
                label='Interest at Maturity'
                value={values.interestAtMaturity || ''}
                autoComplete='off'
                isDisabled={true}
                isCurrency={true}
              />
            </AlignCenter>

            <AlignCenter justifyContent='space-evenly' padding='20px 0 0 0'>
              <ModalTextField
                type='text'
                name='premiumDiscount'
                placeholder='Premium / Discount'
                label='Premium / Discount'
                value={values.premiumDiscount || ''}
                autoComplete='off'
                isDisabled={true}
              />
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={values.bank || ''}
                labelName={'Bank'}
                items={[]}
                isDisabled={true}
                name='bank'
              />
              <ModalTextField
                type='text'
                name='fund'
                placeholder='Fund'
                label='Fund'
                value={values.fund || ''}
                autoComplete='off'
                maxLength='50'
              />
              <ModalTextField
                type='text'
                name='couponRate'
                placeholder='Coupon Rate'
                label='Coupon Rate'
                value={values.couponRate || ''}
                autoComplete='off'
                isPercentage={true}
              />
            </AlignCenter>
            {!isStagingInvestment && (
              <AlignCenter justifyContent='space-evenly' padding='20px 24px'>
                <GroupsMultiSelectField
                  setSelectedItems={setInvestmentGroupIdList}
                  items={allGroups}
                  selectedItems={values.investmentGroupIdList}
                  label='Investment Group(s)'
                  isSelect={true}
                  fill={true}
                  wide={true}
                />
              </AlignCenter>
            )}
            {/* {Form Footer} */}
            <FormActions isSubmitting={isSubmitting} onClose={closeDialog} />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default InvestmentForm;
