import React, { useState } from 'react';
import {
  MessageText,
  GlobalSettingsDescription,
  ResponsiveCenter,
} from '../styled-components';
import QuoteTypesTable from '../table/QuoteTypesTable';
import QuoteTypesDeactivateDialogBox from '../dialog/global-settings-dialog/QuoteTypesDeactivateDialogBox';
import QuoteTypesDialogBox from '../dialog/global-settings-dialog/QuoteTypesDialogBox';

const ManageQuoteTypes = ({
  appUser,
  quoteTypes,
  isFetching,
  fetchAllQuoteTypes,
  fetchSingleQuoteType,
  ...props
}) => {
  const [title, setTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [quoteTypeObj, setQuoteTypeObj] = useState({});

  const [isDeactivate, setIsDeactivate] = useState(false);
  const [quoteTypeIds, setQuoteTypeIds] = useState([]);

  const handleOpenAddDialog = () => {
    setTitle('Add Quote Type');
    setIsOpen(true);
  };

  const handleOpenEditDialog = (selectedRow) => {
    setQuoteTypeObj(selectedRow);
    setTitle('Edit Quote Type');
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setTitle('');
    setIsOpen(false);
    setQuoteTypeObj({});
  };

  const openDeactivateDialog = (selectedRows) => {
    if (selectedRows instanceof Array) {
      setQuoteTypeIds(selectedRows.map((row) => row.quoteTypeId));
    } else {
      setQuoteTypeIds([selectedRows.quoteTypeId]);
    }
    setIsDeactivate(true);
  };

  const closeDeactivateDialog = () => {
    setQuoteTypeIds([]);
    setIsDeactivate(false);
  };

  return (
    <>
      <QuoteTypesDeactivateDialogBox
        isOpen={isDeactivate}
        handleCloseDialog={closeDeactivateDialog}
        title={
          quoteTypeIds.length > 1
            ? `Deactivate selected Quote Types (${quoteTypeIds.length})`
            : 'Deactivate Quote Type'
        }
        quoteTypeIds={quoteTypeIds}
        fetchAllQuoteTypes={fetchAllQuoteTypes}
      />
      <QuoteTypesDialogBox
        handleCloseDialog={handleCloseDialog}
        isOpen={isOpen}
        title={title}
        quoteTypeObj={quoteTypeObj}
        fetchAllQuoteTypes={fetchAllQuoteTypes}
        fetchSingleQuoteType={fetchSingleQuoteType}
      />
      <ResponsiveCenter className={'global-setting'} minWidth={'765px'}>
        <GlobalSettingsDescription>
          <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
            Manage Quote Types
          </MessageText>
          <MessageText>
            Create and manage quote types for use in the application.
          </MessageText>
        </GlobalSettingsDescription>
        <QuoteTypesTable
          appUser={appUser}
          quoteTypes={quoteTypes}
          isFetching={isFetching}
          handleOpenAddDialog={handleOpenAddDialog}
          openDeactivateDialog={openDeactivateDialog}
          handleOpenEditDialog={handleOpenEditDialog}
          {...props}
        />
      </ResponsiveCenter>
    </>
  );
};

export default ManageQuoteTypes;
