import React from 'react';
import {
  AlignCenter,
  WhiteButton,
  RegularButton,
} from '../../styled-components';
import colors from '../../../utils/colors';

const TableFilterActions = ({
  handleClose,
  handleApplyFilters,
  handleClearFilters,
  handleRestoreDefaultFilters,
  filterType,
}) => {
  if (filterType === 'Notes') {
    return (
      <AlignCenter
        justifyContent='flex-end'
        padding='10px 20px 20px 0'
        borderTop={`1px solid ${colors.lightText}`}
      >
        <WhiteButton
          type='button'
          padding='0 35px 0 0'
          onClick={handleRestoreDefaultFilters}
        >
          RESTORE DEFAULTS
        </WhiteButton>
        <WhiteButton padding='10px 30px' onClick={handleClose}>
          CANCEL
        </WhiteButton>
        <RegularButton padding='10px 30px' onClick={handleApplyFilters}>
          APPLY FILTERS
        </RegularButton>
      </AlignCenter>
    );
  } else {
    return (
      <AlignCenter
        justifyContent='flex-end'
        padding='10px 20px 20px 0'
        borderTop={`1px solid ${colors.lightText}`}
      >
        <WhiteButton type='button' onClick={handleClose}>
          CLOSE
        </WhiteButton>
        <RegularButton padding='10px 30px' onClick={handleApplyFilters}>
          APPLY FILTERS
        </RegularButton>
        <RegularButton padding='10px 30px' onClick={handleClearFilters}>
          CLEAR FILTERS
        </RegularButton>
      </AlignCenter>
    );
  }
};

export default TableFilterActions;
