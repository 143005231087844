import React, { useEffect, useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import LoadSpinner from '../../LoadSpinner';
import { AlignCenter, StatusBox, WhiteButton } from '../../styled-components';
import StagingFeesTable from '../../table/StagingFeesTable';
import colors from '../../../utils/colors';
import StagingBalanceTable from '../../table/StagingBalanceTable';
import { connect } from 'react-redux';
import {
  closeStagingDialogs,
  fetchBankLevelExceptionList,
  fetchClientLevelExceptionList,
  fetchFeeCategoryList,
  fetchStagingAccountBalances,
  fetchStagingBalances,
  fetchStagingFees,
  fetchStandardizedDescriptions,
} from '../../../actions/treasuryServices';
import EditBalanceDialog from '../treasury-services-dialog/EditBalanceDialog';
import {
  validateStagingBalance,
  invalidateStagingBalance,
  validateStagingFee,
  invalidateStagingFee,
  validateStagingAccountBalance,
  invalidateStagingAccountBalance,
} from '../../../api/treasuryServices';
import AppToast from '../../Toast';
import TreasuryServicesDeleteDialog from '../treasury-services-dialog/TreasuryServicesDeleteDialog';
import EditFeeDialog from '../treasury-services-dialog/EditFeeDialog';
import StagingAccountBalanceTable from '../../table/StagingAccountBalanceTable';
import EditAccountBalanceDialog from '../treasury-services-dialog/EditAccountBalanceDialog';
import BulkEditFeeDialog from '../treasury-services-dialog/BulkEditFeeDialog';

const StagingTreasuryServicesDialogBase = ({
  isOpen,
  batchAccount,
  handleClose,
  stagingTreasuryServices = [],
  isFetching,
  clientName,
  bankName,
  appUser,
  batchId,
  fetchStagingBalances,
  stagingBalances,
  isFetchingStagingBalances,
  closeStagingDialogs,
  fetchStandardizedDescriptions,
  standardizedDescriptionList,
  stagingFees,
  fetchStagingFees,
  isFetchingStagingFees,
  fetchFeeCategoryList,
  fetchClientLevelExceptionList,
  fetchBankLevelExceptionList,
  bankLevelExceptionList,
  clientLevelExceptionList,
  feeCategoryList,
  fetchStagingAccountBalances,
  stagingAccountBalances,
  isFetchingStagingAccountBalances,
  ...props
}) => {
  const [dynamicHeaders, setDynamicHeaders] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [stagingFeeIds, setStagingFeeIds] = useState([])
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isBulkEditDialogOpen, setIsBulkEditDialogOpen] = useState(false);

  useEffect(() => {
    if (batchAccount.type === 'Balance') {
      fetchStagingBalances(batchId);
      fetchStandardizedDescriptions();
    } else if (batchAccount.type === 'Fees') {
      fetchStagingFees(batchId);
      fetchBankLevelExceptionList();
      fetchClientLevelExceptionList();
      fetchFeeCategoryList();
    } else if (batchAccount.type === 'Account Balance') {
      fetchStagingAccountBalances(batchId);
    }
  }, [
    fetchStagingBalances,
    batchAccount,
    batchId,
    fetchStagingFees,
    fetchStandardizedDescriptions,
    fetchFeeCategoryList,
    fetchClientLevelExceptionList,
    fetchBankLevelExceptionList,
    fetchStagingAccountBalances,
  ]);

  const Title = () => {
    stagingTreasuryServices = stagingBalances.length
      ? stagingBalances
      : stagingFees.length
      ? stagingFees
      : stagingAccountBalances;
    const status = stagingTreasuryServices.filter(
      (treasuryService) => treasuryService.validationStatus === 'No'
    ).length
      ? 'Needs Validation'
      : 'Validated';

    const showStatus = !isFetching && stagingTreasuryServices.length !== 0;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {`Validate/Inspect/Edit: ${clientName} / ${bankName} / Analysis Statements `}
        {showStatus && (
          <StatusBox
            backgroundColor={
              status === 'Needs Validation'
                ? colors.lightText
                : colors.purpleText
            }
          >
            {status}
          </StatusBox>
        )}
      </div>
    );
  };

  const handleOpenEditDialog = (selectedRow) => {
    setSelectedRow(selectedRow);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedRow({});
  };

  const handleOpenDeleteDialog = (selectedRow) => {
    setIsDeleteDialogOpen(true);
    setSelectedRow(selectedRow);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedRow({});
  };

  const handleOpenBulkEditFeeDialog = (selectedRows) => {
    const stagingFeeIds = getBulkVolumeIds(selectedRows)
    setStagingFeeIds(stagingFeeIds)
    setIsBulkEditDialogOpen(true)
  }

  const handleCloseBulkEditFeeDialog = () => {
    setIsBulkEditDialogOpen(false);
    setStagingFeeIds([])
  }

  // This function returns the ids of selected rows
  // @params selectedRows: selected rows either object or array
  // @params status: validation status
  const getAmountIds = (selectedRows, status) => {
    const isArray = Array.isArray(selectedRows);
    let ids;
    if (isArray) {
      //First it filters row with validation status yes and map all amounts
      //And the for every amount in amounts we get its id that will return
      //array of array and .flat will flatten that array.
      ids = selectedRows
        .filter((row) => row.validationStatus === status)
        .map((row) => row.amounts)
        .map((items) => items.map((item) => Number(item[0])))
        .flat();
    } else {
      ids = selectedRows.amounts.map((item) => Number(item[0]));
    }
    return ids;
  };

  const getVolumeIds = (selectedRows, status) => {
    const isArray = Array.isArray(selectedRows);
    let ids;
    if (isArray) {
      ids = selectedRows
        .filter((row) => row.validationStatus === status)
        .map((row) => row.volumes)
        .map((items) => items.map((item) => Number(item[0])))
        .flat();
      //This is to make the id unique
      ids = [...new Set(ids)];
    } else {
      ids = selectedRows.volumes.map((item) => Number(item[0]));
    }
    return ids;
  };

  const getBulkVolumeIds = (selectedRows) => {
    const isArray = Array.isArray(selectedRows);
    let ids;
    if (isArray) {
      ids = selectedRows
        .map((row) => row.volumes)
        .map((items) => items.map((item) => item[0]))
        .flat();
      ids = [...new Set(ids)];
    } else {
      ids = selectedRows.volumes.map((item) => item[0]);
    }
    return ids;
  }

  const handleInvalidateBalance = (selectedRows) => {
    let stagingBalanceIds = getAmountIds(selectedRows, 'Yes');
    const balance = { stagingBalanceIds };
    invalidateStagingBalance(balance)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to invalidate balances.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingBalances(batchId);
      });
  };

  const handleValidateBalance = (selectedRows) => {
    let stagingBalanceIds = getAmountIds(selectedRows, 'No');
    const balance = { stagingBalanceIds };
    validateStagingBalance(balance)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to validate balances.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingBalances(batchId);
      });
  };

  const handleValidateFee = (selectedRows) => {
    let stagingFeesIds = getVolumeIds(selectedRows, 'No');
    const fees = { stagingFeesIds };
    validateStagingFee(fees)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to validate balances.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingFees(batchId);
      });
  };

  const handleInvalidateFee = (selectedRows) => {
    let stagingFeesIds = getVolumeIds(selectedRows, 'Yes');
    const fees = { stagingFeesIds };
    invalidateStagingFee(fees)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to invalidate balances.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingFees(batchId);
      });
  };


  const handleValidateAccountBalance = (selectedRows) => {
    let stagingAccountBalanceIds = getAmountIds(selectedRows, 'No');
    const accountBalances = { stagingAccountBalanceIds };
    validateStagingAccountBalance(accountBalances)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to validate account balances.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingAccountBalances(batchId);
      });
  };

  const handleInvalidateAccountBalance = (selectedRows) => {
    let stagingAccountBalanceIds = getAmountIds(selectedRows, 'Yes');
    const accountBalances = { stagingAccountBalanceIds };
    invalidateStagingAccountBalance(accountBalances)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to validate balances.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchStagingAccountBalances(batchId);
      });
  };

  const onClose = () => {
    handleClose();
    closeStagingDialogs();
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title={<Title />}
        className={'custom-dialog-header large-dialog'}
        isCloseButtonShown={false}
      >
        <AlignCenter justifyContent='space-between'>
          {isFetchingStagingBalances ||
          isFetchingStagingFees ||
          isFetchingStagingAccountBalances ? (
            <div style={{ width: '100%' }}>
              <LoadSpinner size={100} />
            </div>
          ) : batchAccount && batchAccount.type === 'Fees' ? (
            <StagingFeesTable
              appUser={appUser}
              stagingFees={stagingFees}
              isFetching={isFetchingStagingFees}
              setDynamicHeaders={setDynamicHeaders}
              handleOpenEditDialog={handleOpenEditDialog}
              handleValidateFee={handleValidateFee}
              handleInvalidateFee={handleInvalidateFee}
              handleBulkEditFee={handleOpenBulkEditFeeDialog}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              {...props}
            />
          ) : batchAccount && batchAccount.type === 'Balance' ? (
            <StagingBalanceTable
              stagingBalances={stagingBalances}
              appUser={appUser}
              isFetching={isFetchingStagingBalances}
              setDynamicHeaders={setDynamicHeaders}
              handleOpenEditDialog={handleOpenEditDialog}
              handleValidateBalance={handleValidateBalance}
              handleInvalidateBalance={handleInvalidateBalance}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              {...props}
            />
          ) : batchAccount && batchAccount.type === 'Account Balance' ? (
            <StagingAccountBalanceTable
              appUser={appUser}
              stagingAccountBalances={stagingAccountBalances}
              isFetching={isFetchingStagingAccountBalances}
              setDynamicHeaders={setDynamicHeaders}
              handleOpenEditDialog={handleOpenEditDialog}
              handleValidateAccountBalance={handleValidateAccountBalance}
              handleInvalidateAccountBalance={handleInvalidateAccountBalance}
              {...props}
            />
          ) : null}
        </AlignCenter>
        <AlignCenter justifyContent='flex-end' padding='0px 20px 0px 0px'>
          <WhiteButton type='button' onClick={onClose}>
            CLOSE
          </WhiteButton>
        </AlignCenter>
      </Dialog>
      {batchAccount.type === 'Balance' && (
        <>
          {isEditDialogOpen && (
            <EditBalanceDialog
              isOpen={isEditDialogOpen}
              balance={selectedRow}
              dynamicHeaders={dynamicHeaders}
              handleClose={handleCloseEditDialog}
              standardizedDescriptionList={standardizedDescriptionList}
              batchId={batchId}
              fetchStagingBalances={fetchStagingBalances}
              {...props}
            />
          )}
          <TreasuryServicesDeleteDialog
            isOpen={isDeleteDialogOpen}
            title='Delete Staging Balance'
            handleClose={handleCloseDeleteDialog}
            type={batchAccount.type}
            balance={selectedRow}
            batchId={batchId}
            fetchStagingBalances={fetchStagingBalances}
            {...props}
          />
        </>
      )}
      {batchAccount.type === 'Fees' && (
        <>
          {isEditDialogOpen && (
            <EditFeeDialog
              isOpen={isEditDialogOpen}
              fee={selectedRow}
              batchId={batchId}
              handleClose={handleCloseEditDialog}
              fetchStagingFees={fetchStagingFees}
              bankLevelExceptionList={bankLevelExceptionList}
              clientLevelExceptionList={clientLevelExceptionList}
              feeCategoryList={feeCategoryList}
              {...props}
            />
          )}
          {isBulkEditDialogOpen && (
            <BulkEditFeeDialog 
              isOpen={isBulkEditDialogOpen}
              handleClose={handleCloseBulkEditFeeDialog}
              feeIds={stagingFeeIds}
              batchId={batchId}
              fetchStagingFees={fetchStagingFees}
              feeCategoryList={feeCategoryList}
            />
          )}
          <TreasuryServicesDeleteDialog
            isOpen={isDeleteDialogOpen}
            title='Delete Staging Fee'
            handleClose={handleCloseDeleteDialog}
            type={batchAccount.type}
            fee={selectedRow}
            batchId={batchId}
            fetchStagingFees={fetchStagingFees}
          />
        </>
      )}
      {batchAccount.type === 'Account Balance' && (
        <>
          {isEditDialogOpen && (
            <EditAccountBalanceDialog
              isOpen={isEditDialogOpen}
              accountBalance={selectedRow}
              dynamicHeaders={dynamicHeaders}
              batchId={batchId}
              handleClose={handleCloseEditDialog}
              fetchStagingAccountBalances={fetchStagingAccountBalances}
              {...props}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  stagingBalances: state.treasuryServices.stagingBalances,
  stagingFees: state.treasuryServices.stagingFees,
  isFetchingStagingFees: state.treasuryServices.isFetchingStagingFees,
  isFetchingStagingBalances: state.treasuryServices.isFetchingStagingBalances,
  standardizedDescriptionList:
    state.treasuryServices.standardizedDescriptionList,
  bankLevelExceptionList: state.treasuryServices.bankLevelExceptionList,
  clientLevelExceptionList: state.treasuryServices.clientLevelExceptionList,
  feeCategoryList: state.treasuryServices.feeCategoryList,
  stagingAccountBalances: state.treasuryServices.stagingAccountBalances,
  isFetchingStagingAccountBalances:
    state.treasuryServices.isFetchingStagingAccountBalances,
});

const StagingTreasuryServicesDialog = connect(mapStateToProps, {
  fetchStagingBalances,
  closeStagingDialogs,
  fetchStagingFees,
  fetchStandardizedDescriptions,
  fetchFeeCategoryList,
  fetchClientLevelExceptionList,
  fetchBankLevelExceptionList,
  fetchStagingAccountBalances,
})(StagingTreasuryServicesDialogBase);

export default StagingTreasuryServicesDialog;
