import * as api from '../api/templates';

export const fetchAllTemplates = () => ({
  type: 'FETCH_TEMPLATES',
  payload: api.fetchAllTemplates(),
});

export const fetchSingleTemplate = (templateId) => ({
  type: 'FETCH_SINGLE_TEMPLATE',
  payload: api.fetchSingleTemplate(templateId),
});
