import React, { useState } from 'react';
import { initiateStartReport, updateClient } from '../../../api/clients';
import ClientReportDialog from '../../dialog/client-profile-dialog/ClientReportDialog';
import AppToast from '../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import { checkSameName } from '../../../utils/functions';
import ClientsDialogBox from '../../../components/dialog/ClientsDialogBox';
import ClientSummaryWidget from '../../widgets/ClientSummaryWidget';
import ClientsReportStatusWidget from '../../../components/widgets/ClientsReportStatusWidget';
import ClientsMaturingInvestmentsWidget from '../../../components/widgets/ClientsMaturingInvestmentsWidget';
import ClientDayToDayVarianceGraph from '../../widgets/ClientDayToDayVarianceGraph';
import CashVestWidget from '../../widgets/CashVestWidget';
import AssetsWidget from '../../widgets/AssetsWidget';
import STMForecastWidget from '../../../components/widgets/STMForecastWidget';
import AmountsInvestedWidget from '../../widgets/AmountsInvestedWidget';
import ShortTermCashWidget from '../../widgets/ShortTermCashWidget';
import InterestRateComparisonWidget from '../../widgets/InterestRateComparisonWidget';

const SummaryInfoSection = ({
  singleClient,
  appUser,
  fetchSingleClient,
  categories,
  types,
  clientNames,
  isSummaryFetching,
  handleCurrentComponentChange,
  setFromMaturingInvestmentsWidget,
  maturityLimit,
  dayToDayVariance,
  isDayToDayVarianceFetching,
  cashVestScores,
  isCashVestScoreFetching,
  assetWidgetData,
  isAssetWidgetDataFetching,
  handleCashVestDialogOpen,
  shortTermModelForecast,
  isShortTermModelForecastFetching,
  amountsInvested,
  isAmountsInvestedFetching,
  shortTermForecast,
  isShortTermForecastFetching,
  interestRateComparisons,
  isFetchingInterestRateComparisons,
  handleGenerateReportDialogOpen,
  ...props
}) => {
  const [isReportBtnEnabled, setReportBtn] = useState(true);

  /* For the Client Report Modal */
  const [isReportModalEnabled, setReportModalFlag] = useState(false);
  const [errorsArray, setErrorsArray] = useState([]);
  const [successesArray, setSuccessesArray] = useState([]);

  //For the Client Edit Modal
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [warningClientName, setWarningClientName] = useState('');
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);
  const [clientState, setClientState] = useState({});
  const [clientId, setClientId] = useState(undefined);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [isOverwriting, setIsOverWriting] = useState(false);
  const allowedReportFrequencies = singleClient.allowedReportFrequencies;

  /* Client Report Modal Set up */
  const onCloseReset = () => {
    setReportModalFlag(false);
    setReportBtn(!isReportBtnEnabled);
    setErrorsArray([]);
    setSuccessesArray([]);
  };

  //'Start-report' handler. Calls api on action
  const handleStartReport = (client, handleStartReportDialogClose) => {
    setReportModalFlag(false);
    setReportBtn(false);
    initiateStartReport(singleClient.clientId, client)
      .then((res) => {
        setSuccessesArray((successesArray) => [
          ...successesArray,
          `${singleClient.clientName} - ${res.data.msg}`,
        ]);
      })
      .catch((err) => {
        setErrorsArray((errorsArray) => [
          ...errorsArray,
          `${singleClient.clientName} - ${
            err.response && err.response.data.msg ? err.response.data.msg : err
          }`,
        ]);
      })
      .finally(() => {
        handleStartReportDialogClose();
        setReportModalFlag(true);
      });
  };
  //end of client report modal functionality

  //Client Edit  - modal dialog functions
  const openEditClientDialog = (client) => {
    const copiedClient = Object.assign({}, client);
    copiedClient.status = client.status === 'active' ? 1 : 0;
    setSelectedClient(copiedClient);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setIsWarningDialogOpen(false);
    setSelectedClient({});
    setClientState({});
    setClientId(undefined);
    setWarningClientName('');
    setIsTryingToSubmit(false);
    setIsOverWriting(false);
  };

  /**
   * Submits the updated client
   * If the name given already belongs to another client it throws an exception and enables a confirmation modal
   * When run with confirmation modal enabled (overwrite confirmed) it updates the client as well
   * @param {Obj} values  - fields of the submit, if none specified it defaults to what is saved in the ongoing client state (on confirm)
   * @param {string} clientId  -  the id of the client to update, if none is specified it defaults to what is save in the ongoing id state (on confirm)
   */
  const handleEditClient = (values = clientState, id = clientId) => {
    const client = {
      ...values,
    };
    setIsTryingToSubmit(true);

    const isNameOverwritten =
      checkSameName(client.clientName, clientNames) &&
      selectedClient.clientName !== client.clientName &&
      !isWarningDialogOpen;

    try {
      if (isNameOverwritten) throw new Error('NAME_OVERWRITE');
      setIsOverWriting(true);
      //No conflicts update client
      updateClient(id, client)
        .then((res) => {
          AppToast.show({
            message: res.data.msg,
            intent: Intent.SUCCESS,
          });
          fetchSingleClient(id);
        })
        .catch((err) => {
          AppToast.show({
            message:
              err.response && err.response.data.msg
                ? err.response.data.msg
                : 'Failed to edit client.',
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          closeDialog();
        });
    } catch (err) {
      if (err.message === 'NAME_OVERWRITE') {
        setIsWarningDialogOpen(true);
        setClientState(client);
        setClientId(selectedClient.clientId);
        setWarningClientName(client.clientName);
      }
    }
  };

  const handleWarningDialogClose = () => {
    setIsTryingToSubmit(false);
    setIsWarningDialogOpen(false);
  };
  //End of Client Edit  - modal dialog functions

  return (
    <>
      <ClientsDialogBox
        isOpen={isOpen}
        appUser={appUser}
        title={'Edit Client'}
        closeDialog={closeDialog}
        categories={categories}
        types={types}
        selectedClient={selectedClient}
        handleEditClient={handleEditClient}
        isTryingToSubmit={isTryingToSubmit}
        isWarningDialogOpen={isWarningDialogOpen}
        warningName={warningClientName}
        handleWarningDialogClose={handleWarningDialogClose}
        isOverwriting={isOverwriting}
        allowedReportFrequencies={allowedReportFrequencies}
      />
      <ClientReportDialog
        isReportModalEnabled={isReportModalEnabled}
        onCloseReset={onCloseReset}
        title={'Report Summary'}
        clientId={singleClient.clientId}
        errorsArray={errorsArray}
        successesArray={successesArray}
        fetchSingleClient={fetchSingleClient}
      />
      <ClientSummaryWidget
        singleClient={singleClient}
        appUser={appUser}
        categories={categories}
        types={types}
        clientNames={clientNames}
        openEditClientDialog={openEditClientDialog}
        isSummaryFetching={isSummaryFetching}
      />
      <ClientsReportStatusWidget
        handleStartReport={handleStartReport}
        singleClient={singleClient}
        handleGenerateReportDialogOpen={handleGenerateReportDialogOpen}
        {...props}
      />
      <ClientsMaturingInvestmentsWidget
        singleClient={singleClient}
        maturityLimit={maturityLimit}
        handleCurrentComponentChange={handleCurrentComponentChange}
        setFromMaturingInvestmentsWidget={setFromMaturingInvestmentsWidget}
        {...props}
      />
      <div style={{ height: '100%' }}>
        <STMForecastWidget
          clientId={singleClient.clientId}
          shortTermModelForecast={shortTermModelForecast}
          isShortTermModelForecastFetching={isShortTermModelForecastFetching}
        />
        <ClientDayToDayVarianceGraph
          dayToDayVariance={dayToDayVariance}
          isDayToDayVarianceFetching={isDayToDayVarianceFetching}
        />
        <AmountsInvestedWidget
          amountsInvested={amountsInvested}
          isAmountsInvestedFetching={isAmountsInvestedFetching}
        />
        <CashVestWidget
          cashVestScores={cashVestScores}
          isCashVestScoreFetching={isCashVestScoreFetching}
        />
        <ShortTermCashWidget
          shortTermForecast={shortTermForecast}
          isShortTermForecastFetching={isShortTermForecastFetching}
        />
        <InterestRateComparisonWidget
          isFetching={isFetchingInterestRateComparisons}
          interestRates={interestRateComparisons}
          isClientProfile={true}
          handleCurrentComponentChange={handleCurrentComponentChange}
        />
        <AssetsWidget
          assetWidgetData={assetWidgetData}
          isAssetWidgetDataFetching={isAssetWidgetDataFetching}
        />
      </div>
    </>
  );
};

export default SummaryInfoSection;
