import React from 'react';
import {
  ComponentHeader,
  HeaderText,
  MainSubNavContainer,
  WhiteButton,
} from '../styled-components';
import AnalysisSubNav from '../navigation/SubNavigation/AnalysisSubNavigation';
import QuandlData from './header-data/QuandlData';
import { completeAnalysis } from '../../api/analysis';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';
import { checkPermissions } from '../../utils/functions';
import permissions from '../../utils/permissions';

const AnalysisHeader = ({
  appUser,
  header,
  hasSubNav,
  currentComponent,
  handleCurrentComponentChange,
  clientId,
  status,
  ...props
}) => {
  const { open } = props;

  const handleCompleteAnalysis = () => {
    completeAnalysis(clientId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        props.history.push(`/client-management/${clientId}`);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to complete analysis.',
          intent: Intent.DANGER,
        });
      });
  };

  return (
    <>
      <ComponentHeader className={open}>
        <QuandlData {...props} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <HeaderText>{header}</HeaderText>

          {currentComponent === 1 &&
            checkPermissions(appUser.permList, permissions.COMPLETE_ANALYSIS) &&
            status === 'Analysis' && (
              <WhiteButton
                width='18rem'
                margin='0 1rem 0 0'
                onClick={handleCompleteAnalysis}
              >
                {'Complete Analysis'}
              </WhiteButton>
            )}
        </div>
      </ComponentHeader>
      {hasSubNav && (
        <MainSubNavContainer className={open}>
          <AnalysisSubNav
            handleCurrentComponentChange={handleCurrentComponentChange}
            currentComponent={currentComponent}
            appUser={appUser}
          />
        </MainSubNavContainer>
      )}
    </>
  );
};

export default AnalysisHeader;
