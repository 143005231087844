import React, { useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { AlignCenter } from '../../../components/styled-components';
import { connect } from 'react-redux';
import {
  dateToFilterText,
  textToFilterText,
  numberRangeToFilterText,
  dateRangeChecker,
} from '../../../utils/functions';
import DateSelectPlainField from '../../../components/select-fields/DateSelectPlainField';
import SelectField from '../../../components/select-fields/SelectField';
import NumberRangeField from '../../../components/select-fields/NumberRangeField';
import TableFilterActions from './TableFilterActions';

const BatchesFilterDialogBoxBase = ({
  isOpen,
  title,
  handleClose,
  setFilter,
  setAllFilters,
  clientBatches,
  setFilterSummary,
}) => {
  const [bankName, setBankName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [templateType, setTemplateType] = useState('');
  const [fileNumberLow, setFileNumberLow] = useState('');
  const [fileNumberHigh, setFileNumberHigh] = useState('');

  const [dateRange, setDateRange] = useState([]);
  const [validationStatus, setValidationStatus] = useState('');

  const banks = clientBatches
    .map((batch) => {
      return batch.bankName;
    })
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((bankName) => {
      return { value: bankName, label: bankName };
    });

  const accountTypes = clientBatches
    .map((batch) => {
      return batch.accountName;
    })
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((accountName) => {
      return { value: accountName, label: accountName };
    });

  //[Transaction, Analysis, Investment, Balance]
  const templateTypes = [
    { value: 'Transaction', label: 'Transaction' },
    { value: 'Analysis', label: 'Analysis' },
    { value: 'Investment', label: 'Investment' },
    { value: 'Balance', label: 'Balance' },
  ];

  const statusTypes = [
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Not Started', label: 'Not Started' },
  ];

  const getFilterSummary = () => {
    const filters = [];
    bankName && filters.push(textToFilterText(bankName, 'Bank'));
    accountType && filters.push(textToFilterText(accountType, 'Account Type'));
    templateType &&
      filters.push(textToFilterText(templateType, 'Template Type'));
    dateRangeChecker(dateRange) &&
      filters.push(dateToFilterText(dateRange, 'Created date'));
    validationStatus &&
      filters.push(textToFilterText(validationStatus, 'Validation Status'));
    (fileNumberLow || fileNumberHigh) &&
      filters.push(
        numberRangeToFilterText(
          fileNumberLow,
          fileNumberHigh,
          'Number of Files'
        )
      );
    return filters;
  };

  const handleApplyFilters = () => {
    setFilterSummary(getFilterSummary());
    setFilter('bankName', bankName || undefined);
    setFilter('accountName', accountType || undefined);
    setFilter('templateType', templateType || undefined);
    setFilter(
      'sourcesRequired',
      !fileNumberLow && !fileNumberHigh
        ? undefined
        : [fileNumberLow || undefined, fileNumberHigh || undefined]
    );
    setFilter(
      'createdAt',
      !dateRangeChecker(dateRange) ? undefined : dateRange
    );
    setFilter('status', validationStatus || undefined);
    handleClose();
  };

  const handleClearFilters = () => {
    setBankName('');
    setAccountType('');
    setTemplateType('');
    setFileNumberLow('');
    setFileNumberHigh('');
    setDateRange([]);
    setValidationStatus('');
    handleClose();
    setFilterSummary([]);
    setAllFilters([]);
  };

  const handleDateChange = (value) => {
    setDateRange(value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 0 20px 0' }}>
        <AlignCenter justifyContent='space-evenly' padding='10px 0'>
          <SelectField
            selectValueFromList={setBankName}
            value={bankName}
            labelName={'Bank'}
            items={banks}
          />
          <SelectField
            selectValueFromList={setAccountType}
            value={accountType}
            labelName={'Account Type'}
            items={accountTypes}
          />
        </AlignCenter>
        <AlignCenter justifyContent='space-evenly' padding='10px 0'>
          <SelectField
            selectValueFromList={setTemplateType}
            value={templateType}
            labelName={'Template Type'}
            items={templateTypes}
          />
          <SelectField
            selectValueFromList={setValidationStatus}
            value={validationStatus}
            labelName={'Validation Status'}
            items={statusTypes}
          />
        </AlignCenter>
        <AlignCenter justifyContent='space-start' padding='10px 0 0 3.5rem'>
          <DateSelectPlainField
            labelName={'Creation Date'}
            dateRange={dateRange}
            handleDateChange={handleDateChange}
          />
        </AlignCenter>
        <AlignCenter justifyContent='space-evenly' padding='10px 0'>
          <NumberRangeField
            labelName={'Number of Files'}
            selectLow={setFileNumberLow}
            selectHigh={setFileNumberHigh}
            high={fileNumberHigh}
            low={fileNumberLow}
          />
        </AlignCenter>
      </div>
      <TableFilterActions
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  clientBatches: state.dataIngestion.clientBatches,
});

const BatchesFilterDialogBox = connect(mapStateToProps)(
  BatchesFilterDialogBoxBase
);

export default BatchesFilterDialogBox;
