import React from 'react';
import { Icon, Tooltip } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import colors from '../../../utils/colors';

const SortableHeader = ({ column, header, toolTipText }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {/* Add a icon before header if column is being filtered */}
      {column.filterValue && (
        <Icon
          icon={iconValue.filter}
          iconSize={16}
          color={colors.boldText}
          style={{ paddingRight: '5px' }}
        />
      )}
      {header}
      {toolTipText && (
        <div
          style={{
            display: 'inline-block',
            padding: '0px 0px 4px 5px',
            fontSize: '1rem',
          }}
        >
          <Tooltip content={toolTipText}>
            <Icon icon={iconValue.info} iconSize={16} color={colors.primary} />
          </Tooltip>
        </div>
      )}
      {!column.isSorted ? (
        ''
      ) : (
        <Icon
          icon={iconValue.doubleCaretVertical}
          iconSize={16}
          color={colors.boldText}
          style={{ paddingLeft: '5px' }}
        />
      )}
    </div>
  );
};
export default SortableHeader;
