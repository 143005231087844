import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { MessageText, RedText } from '../../styled-components';
import colors from '../../../utils/colors';
import DialogDeleteActions from '../DialogDeleteActions';

const BatchAccountDeleteDialog = ({
  isOpen,
  title,
  handleClose,
  handleDelete,
  isDeleting,
  accountName,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <>
          <MessageText color={colors.boldText}>
            Are you sure you want to delete this Batch Account?
          </MessageText>
          <MessageText>{accountName}</MessageText>
        </>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default BatchAccountDeleteDialog;
