import * as api from '../api/auth';

export const fetchAppUser = () => ({
  type: 'FETCH_APP_USER',
  payload: api.fetchAppUser(),
});

export const logoutAppUser = () => ({
  type: 'LOGOUT',
  payload: '',
});
