import * as api from '../api/investments';

export const fetchAllInvestments = () => ({
  type: 'FETCH_ALL_INVESTMENTS',
  payload: api.fetchAllInvestments(),
});

export const fetchAllAccountInvestments = (accountId) => ({
  type: 'FETCH_ALL_ACCOUNT_INVESTMENTS',
  payload: api.fetchAllAccountInvestments(accountId),
});

export const fetchClientInvestments = (clientId) => ({
  type: 'FETCH_CLIENT_INVESTMENTS',
  payload: api.fetchClientInvestments(clientId),
});

export const fetchSingleInvestment = (investmentId) => ({
  type: 'FETCH_SINGLE_INVESTMENT',
  payload: api.fetchSingleInvestment(investmentId),
});

export const fetchInvestmentGroups = () => ({
  type: 'FETCH_INVESTMENT_GROUPS',
  payload: api.fetchInvestmentGroups(),
});

export const fetchGroupInvestments = (groupId) => ({
  type: 'FETCH_GROUP_INVESTMENTS',
  payload: api.fetchGroupInvestments(groupId),
});

export const fetchSingleInvestmentGroup = (groupId) => ({
  type: 'FETCH_SINGLE_INVESTMENT_GROUP',
  payload: api.fetchSingleInvestmentGroup(groupId),
});

export const fetchClientUnrealizedInterest = (clientId) => ({
  type: 'FETCH_CLIENT_UNREALIZED_INTEREST',
  payload: api.fetchClientUnrealizedInterest(clientId),
});

export const fetchAccountUnrealizedInterest = (accountId) => ({
  type: 'FETCH_ACCOUNT_UNREALIZED_INTEREST',
  payload: api.fetchAccountUnrealizedInterest(accountId),
});

export const fetchAccountInvestmentBalance = (accountId) => ({
  type: 'FETCH_INVESTMENT_ACCOUNT_BALANCE',
  payload: api.fetchAccountInvestmentBalance(accountId),
});

export const fetchClientInvestmentBalance = (clientId) => ({
  type: 'FETCH_INVESTMENT_CLIENT_BALANCE',
  payload: api.fetchClientInvestmentBalance(clientId),
});

export const fetchStagingInvestments = (batchAccountId) => ({
  type: 'FETCH_STAGING_INVESTMENTS',
  payload: api.fetchStagingInvestments(batchAccountId),
});

export const fetchSingleStagingInvestment = (stagingInvestmentId) => ({
  type: 'FETCH_SINGLE_STAGING_INVESTMENT',
  payload: api.fetchSingleStagingInvestment(stagingInvestmentId),
});

export const fetchAmountsInvested = (id, type) => ({
  type: 'FETCH_AMOUNTS_INVESTED',
  payload: api.fetchAmountsInvested(id, type),
});

export const fetchAmountsInvestedAccounts = (id, type) => ({
  type: 'FETCH_AMOUNTS_INVESTED_ACCOUNTS',
  payload: api.fetchAmountsInvested(id, type),
});

export const fetchAccountInterestRates = (accountId) => ({
  type: 'FETCH_ACCOUNT_INTEREST_RATES',
  payload: api.fetchAccountInterestRates(accountId),
});
