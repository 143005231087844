import { Dialog, Intent } from '@blueprintjs/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { fetchMonteCarloHistory } from '../../../actions/analysis';
import { deleteMonteCarlo } from '../../../api/analysis';
import { MessageText, RedText } from '../../styled-components';
import AppToast from '../../Toast';
import DialogDeleteActions from '../DialogDeleteActions';

const MonteCarloDeleteDialogBoxBase = ({
  isOpen,
  title,
  clientId,
  handleClose,
  selectedMonteCarlo,
  fetchMonteCarloHistory,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    deleteMonteCarlo(selectedMonteCarlo.monteCarloId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete Monté Carlo.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        fetchMonteCarloHistory(clientId);
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete this Monté Carlo.
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Dialog>
  );
};

const mapStateToProps = () => ({});

const MonteCarloDeleteDialogBox = connect(mapStateToProps, {
  fetchMonteCarloHistory,
})(MonteCarloDeleteDialogBoxBase);

export default MonteCarloDeleteDialogBox;
