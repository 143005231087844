import React from 'react';
import { Dialog } from '@blueprintjs/core';
import BankClientRelationshipForm from '../../../components/forms/client-profile-forms/BankClientRelationshipForm';

const BankClientRelationshipDialog = ({
  isOpen,
  closeDialog,
  status,
  collectionType,
  handleEditClientBankRelationship,
}) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        isCloseButtonShown={false}
        title='Edit Client Bank Relationship'
      >
        <BankClientRelationshipForm
          isOpen={isOpen}
          status={status}
          collectionType={collectionType}
          closeDialog={closeDialog}
          handleEditClientBankRelationship={handleEditClientBankRelationship}
        />
      </Dialog>
    </>
  );
};

export default BankClientRelationshipDialog;
