import React from 'react';
import { MultiSelect } from '@blueprintjs/select';
import { Button, NonIdealState, MenuItem } from '@blueprintjs/core';
import { Label, FlexColumn, BlueprintInputWrapper } from '../styled-components';

//Finds and returns duplicates in an array
const findDuplicates = (arr) =>
  arr.filter((item, index) => arr.indexOf(item) !== index);

const GroupsMultiSelectField = ({
  selectedItems,
  items,
  setSelectedItems,
  label,
  wide,
  fill,
}) => {
  const names = items.map((item) => item.label);
  const duplicateNames = findDuplicates(names);

  //Adds item to the selected list
  const selectItem = (item) => {
    const newItem = { groupId: item.value, groupName: item.label };
    selectedItems.push(newItem);
  };

  //How to render dropdown items
  //In case the item is not unique show its id(group.value) as well
  //The id helps differentiate groups with the same name
  const renderItems = (group, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = `${group.label}`;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        icon={isItemSelected(group) ? 'tick' : 'blank'}
        label={duplicateNames.includes(group.label) ? group.value : ''}
        key={group.value}
        onClick={handleClick}
        text={text}
      />
    );
  };

  // this for the select fields with filters
  const filterList = (query, items) => {
    return items.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  //Handle selecting items
  const handleItemSelect = (item) => {
    if (!isItemSelected(item)) {
      selectItem(item);
    } else {
      deselectItem(item);
    }
  };

  //Returns true if item is selected
  const isItemSelected = (item) => {
    return (
      selectedItems.length &&
      selectedItems.some((i) => i.groupId === item.value)
    );
  };

  //Removes the item from selected list
  const deselectItem = (item) => {
    let index = selectedItems.indexOf(item);
    selectedItems.splice(index, 1);
    setSelectedItems(selectedItems);
  };

  //Renders input tag
  const tagRenderer = (item) => {
    return item.groupName;
  };

  //Removes selected element on clicking items cross
  const handleTagRemove = (item) => {
    deselectItem(item);
  };

  //Handle clicking the large right cross button
  const handleClear = () => {
    setSelectedItems([]);
  };

  //Jxs for right element on inputProps
  const clearButton = (
    <Button icon='cross' minimal={true} onClick={handleClear} />
  );

  return (
    <FlexColumn style={wide ? { width: '100%' } : {}}>
      <Label>{label}</Label>
      <BlueprintInputWrapper>
        <MultiSelect
          items={items}
          itemRenderer={renderItems}
          itemPredicate={filterList}
          onItemSelect={handleItemSelect}
          tagRenderer={tagRenderer}
          scrollToActiveItem={true}
          placeholder='Select Multiple Items'
          noResults={<NonIdealState title='No Results Found...' />}
          popoverProps={{ minimal: true }}
          tagInputProps={{
            onRemove: handleTagRemove,
            rightElement: clearButton,
          }}
          selectedItems={selectedItems}
          fill={fill}
        ></MultiSelect>
      </BlueprintInputWrapper>
    </FlexColumn>
  );
};

export default GroupsMultiSelectField;
