import React from 'react';
import { Elevation, Icon, Tooltip } from '@blueprintjs/core';
import {
  Label,
  WidgetHeader,
  WidgetBody,
  TransparentButton,
  TextValue,
  BoldText,
  CardDiv,
} from '../styled-components';
import iconValue from '../../utils/icons';
import { amountFormatter, formattedMonthsList, calculateCycleOptions } from '../../utils/functions';
import colors from '../../utils/colors';
import LoadSpinner from '../LoadSpinner';
import find from 'lodash/find';

const ClientSummaryWidget = ({
  singleClient,
  openEditClientDialog,
  isSummaryFetching,
}) => {
  let tooltipText;
  if (singleClient && singleClient.assignTo) {
    tooltipText = singleClient.assignTo.join(', ');
  }
  const getFormattedFrequency = (client) =>{
    const formattedMonths = formattedMonthsList(client.allowedReportFrequencies);
    return find(formattedMonths, ['value', client.reportFrequency]).label
  };

  const getReportCycle = (client) => {
    return find(calculateCycleOptions(client.reportFrequency), ['value', client.reportCycle]).label
  };
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ flexBasis: '300px', flexGrow: '1', justifyContent: 'center' }}
    >
      {isSummaryFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}
      {!isSummaryFetching && (
        <>
          <WidgetHeader padding='10px 20px'>
            {singleClient && <Label>{singleClient.clientName}</Label>}
            <TransparentButton
              margin='0 0 0 15px'
              onClick={() => openEditClientDialog(singleClient)}
            >
              <Icon
                icon={iconValue.annotation}
                iconSize={16}
                color={colors.primary}
              />
            </TransparentButton>
          </WidgetHeader>
          <WidgetHeader padding='10px 20px'>
            {singleClient && (
              <BoldText fontSize='1rem'>
                Assign to:{' '}
                <TextValue>
                  {/* need to check if singleClient exists for each .length check
                  show N/A if there are no users, and one if there is one or more, 
                  should never enter final else of empty string */}
                  {singleClient.assignTo && singleClient.assignTo.length >= 1
                    ? singleClient.assignTo[0]
                    : singleClient.assignTo === null
                    ? 'N/A'
                    : ''}
                  {singleClient.assignTo && singleClient.assignTo.length > 1 && (
                    <Tooltip content={tooltipText}>
                      <Icon
                        icon={iconValue.info}
                        iconSize={16}
                        color={colors.primary}
                        style={{ marginLeft: '8px', verticalAlign: 'middle' }}
                      />
                    </Tooltip>
                  )}
                </TextValue>
              </BoldText>
            )}
          </WidgetHeader>
          <WidgetBody padding='10px 20px'>
            {singleClient && (
              <>
                <BoldText fontSize='1rem'>
                  State: <TextValue>{singleClient.state}</TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  Client Category:{' '}
                  <TextValue>{singleClient.clientCategory}</TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  Client Type: <TextValue>{singleClient.clientType}</TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  Budget Size(m):{' '}
                  <TextValue>
                    {singleClient.budget !== 0
                      ? amountFormatter(singleClient.budget, 0)
                      : `$${0}`}
                  </TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  12 Month Avg:{' '}
                  <TextValue>
                    {amountFormatter(singleClient.last12MonthAvgBalance, 0)}
                  </TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  Creation Date:{' '}
                  <TextValue>
                    {singleClient && singleClient.createdAt
                      ? singleClient.createdAt.split(' ')[0]
                      : ''}
                  </TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  Data Until:{' '}
                  <TextValue>
                    {singleClient && singleClient.dataUntil
                      ? singleClient.dataUntil.split(' ')[0]
                      : 'N/A'}
                  </TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  Report Frequency: <TextValue>{singleClient && singleClient.reportFrequency
                                                ?getFormattedFrequency(singleClient)
                                                :'N/A'
                                    }</TextValue>
                </BoldText>
                <BoldText fontSize='1rem'>
                  Report Cycle: <TextValue>{singleClient && singleClient.reportCycle
                                            ?getReportCycle(singleClient)
                                            :'N/A'
                                            }</TextValue>
                </BoldText>
              </>
            )}
          </WidgetBody>
        </>
      )}
    </CardDiv>
  );
};

export default ClientSummaryWidget;
