import React from 'react';
import { Dialog } from '@blueprintjs/core';
import colors from '../../../utils/colors';
import {
  AlignCenter,
  MessageText,
  RegularButton,
  RedText,
  ResponseMessage,
} from '../../styled-components';

// dialog for upload success/error
const UploadConfirmDialog = ({
  closeDialog,
  isOpen,
  uploadAmount,
  uploadErrorAmount,
  uploadErrorList,
}) => {
  return (
    <>
      <Dialog
        className='small-dialog'
        isOpen={isOpen}
        title='Upload Summary'
        onClose={closeDialog}
        isCloseButtonShown={false}
      >
        <div
          style={{
            padding: '30px 50px 20px',
          }}
        >
          <MessageText color={colors.green}>
            {uploadAmount} files uploaded successfully!
          </MessageText>
          <MessageText color={colors.green}>
            A notification on the status of data extraction will appear shortly.
          </MessageText>
          {uploadErrorList.length ? (
            <RedText>
              {uploadErrorAmount} files failed to upload.
              <ResponseMessage>
                {uploadErrorList.map((error, i) => (
                  <span
                    style={{ padding: '5px 0px', display: 'block' }}
                    key={i}
                  >
                    {error}
                  </span>
                ))}
              </ResponseMessage>
            </RedText>
          ) : null}
        </div>
        <AlignCenter
          padding='10px 0 10px 0'
          borderTop={`1px solid ${colors.lightText}`}
          margin='50px 0 0 0'
        >
          <RegularButton
            width='13rem'
            padding='10px 30px'
            onClick={closeDialog}
          >
            OK
          </RegularButton>
        </AlignCenter>
      </Dialog>
    </>
  );
};

export default UploadConfirmDialog;
