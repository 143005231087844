import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import rootReducer from './reducers/index';
import { createLogger } from 'redux-logger';
import { compose } from 'recompose';
import { persistStore, persistReducer } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import { Auth } from 'aws-amplify';

// redux promise middleware allows for handling of async actions used in reducer, appends FULFILLED, PENDING, REJECTED to the action type

// shows log of actions fired off in redux in console
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
  collapsed: true, // Auto-collapses all log entries
});

/**
 * Middleware to check if the token used by the user is valid
 * In case of token expiration this middleware should dispatch a logout action
 * and call auth signout
 * This Middleware is checking every action when its being dispatched
 * @param {Obj} store - holds redux reducers
 */
const checkTokenExpirationMiddleware = (store) => (next) => async (action) => {
  // persisting actions & logout- no need to check they just get the redux state
  if (
    action.type === 'persist/PERSIST' ||
    action.type === 'persist/REHYDRATE' ||
    action.type === 'LOGOUT'
  )
    next(action);
  else {
    await Auth.currentAuthenticatedUser()
      .then(() => next(action))
      .catch((err) => {
        const currentState = store.getState();
        // if the redux state is not cleared yet clear it
        if (currentState.auth.appUser) {
          console.error(err);
          if (err.toLowerCase().includes('not authenticated')) {
            Auth.signOut();
            return next({
              type: 'LOGOUT',
              payload: '',
            });
          } else {
            next(action);
          }
        } else {
          next(action);
        }
      });
  }
};

// initial middleware, promise from redux-promise-middleware
let middleware = [promise, checkTokenExpirationMiddleware];
// this hides logger on production build
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

//Attributes to be persisted on a refresh - AppUser from the Auth middleware
const saveSubsetFilter = createFilter('auth', ['appUser']);
const saveSubsetFilterTwo = createFilter('quandl', ['quandlData']);

const persistConfig = {
  key: 'authType',
  storage: storage,
  whitelist: ['auth', 'quandl'],
  transforms: [saveSubsetFilter, saveSubsetFilterTwo],
};

//Redux-persist , persists selected state on refresh
const pReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const store = createStore(
    pReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store);
  return { persistor, store };
};

export default configureStore;
