import React, { useState, useEffect } from 'react';
import { ComponentProfileBody } from '../../../components/styled-components';
import Summary from './Summary/Summary';
import ClientAccountGroups from './ClientAccountGroups/ClientAccountGroups';
import Accounts from './Accounts/Accounts';
import ClientHeader from '../../../components/headers/ClientHeader';
import { connect } from 'react-redux';
import {
  fetchAllClients,
  fetchSingleClient,
  fetchAllCategories,
  fetchAllTypes,
  fetchDayToDayVariance,
  fetchCashVestScores,
  fetchClientReportStatus,
  fetchAssetWidgetData,
  fetchClientShortTermForecast,
  fetchShortTermModelForecast,
  fetchInterestRateComparisons,
} from '../../../actions/clients';
import {
  fetchClientAccounts,
  fetchClientAccountGroups,
} from '../../../actions/accounts';
import { fetchAmountsInvested } from '../../../actions/investments';
import { fetchMaturityLimit } from '../../../actions/administration';
import AppToast from '../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import LoadSpinner from '../../../components/LoadSpinner';
import Investments from './Investments/Investments';
import AccountGroupDialogBox from '../../../components/dialog/account-profile-dialog/AccountGroupDialogBox';
import ReportList from './ReportsList/ReportList';
import GenerateReportDialog from '../../../components/dialog/client-profile-dialog/GenerateReportDialog';

const ClientProfileBase = ({
  clients,
  singleClient,
  categories,
  types,
  isFetching,
  maturityLimit,
  isSummaryFetching,
  dayToDayVariance,
  isDayToDayVarianceFetching,
  cashVestScores,
  isCashVestScoreFetching,
  assetWidgetData,
  isAssetWidgetDataFetching,
  shortTermModelForecast,
  isShortTermModelForecastFetching,
  amountsInvested,
  isAmountsInvestedFetching,
  clientReportStatus,
  shortTermForecast,
  isShortTermForecastFetching,
  interestRateComparisons,
  isFetchingInterestRateComparisons,
  clientAccounts,
  clientAccountGroups,
  ...props
}) => {
  const [currentComponent, setCurrentComponent] = useState(
    Number(props.history.location.state) || 0
  );
  const [isGenerateReportDialogOpen, setIsGenerateReportDialogOpen] = useState(
    false
  );
  const [isReportGenerated, setIsReportGenerated] = useState(false)

  const [
    fromMaturingInvestmentsWidget,
    setFromMaturingInvestmentsWidget,
  ] = useState(false);

  const [
    isAddAccountGroupDialogOpen,
    setIsAddAccountGroupDialogOpen,
  ] = useState(false);

  const [isServerSidePagination, setIsServerSidePagination] = useState(false);

  props.location &&
    Number(props.location.state) !== 0 &&
    props.history.replace(props.history.location.state, 0);

  const {
    fetchAllClients,
    fetchAllCategories,
    fetchAllTypes,
    fetchSingleClient,
    fetchMaturityLimit,
    fetchDayToDayVariance,
    fetchCashVestScores,
    fetchAssetWidgetData,
    fetchShortTermModelForecast,
    fetchAmountsInvested,
    fetchClientReportStatus,
    fetchClientShortTermForecast,
    fetchInterestRateComparisons,
    fetchClientAccounts,
    fetchClientAccountGroups,
  } = props;

  const clientId = props.match.params.id;

  const clientNames = clients.map((client) => client.clientName.toLowerCase());

  useEffect(() => {
    fetchAllClients();
    fetchAllCategories();
    fetchAllTypes();
    fetchMaturityLimit();
    fetchSingleClient(clientId);
    fetchDayToDayVariance(clientId);
    fetchCashVestScores(clientId);
    fetchAssetWidgetData(clientId);
    fetchShortTermModelForecast(clientId, 5);
    fetchAmountsInvested(clientId, 'client');
    fetchClientShortTermForecast(clientId);
    fetchInterestRateComparisons(clientId);
    fetchClientAccounts(clientId);
    fetchClientAccountGroups(clientId);

    // when clicking out of the subnav within the same page
    // need to reset fromMaturing to false
    if (
      currentComponent === 0 ||
      currentComponent === 1 ||
      currentComponent === 2
    ) {
      setFromMaturingInvestmentsWidget(false);
    }
  }, [
    fetchAllClients,
    fetchAllCategories,
    fetchAllTypes,
    fetchMaturityLimit,
    clientId,
    setFromMaturingInvestmentsWidget,
    currentComponent,
    fetchSingleClient,
    fetchDayToDayVariance,
    fetchCashVestScores,
    fetchShortTermModelForecast,
    fetchAmountsInvested,
    fetchClientShortTermForecast,
    fetchAssetWidgetData,
    fetchInterestRateComparisons,
    fetchClientAccounts,
    fetchClientAccountGroups,
  ]);

  useEffect(() => {
    if (singleClient && singleClient.clientId)
      fetchClientReportStatus(singleClient.clientId);
  }, [fetchClientReportStatus, singleClient, isReportGenerated]);

  // use this to check if client exists in useEffect below
  const client = clients.find((client) => {
    return client.clientId.toString() === clientId.toString();
  });

  // if client is not fetching and returns undefined, it does not exist
  // redirect to client management page and show toast
  useEffect(() => {
    if (clients.length > 0 && !isFetching && client === undefined) {
      props.history.push('/client-management');
      AppToast.show({
        message: 'Client not found.',
        intent: Intent.DANGER,
      });
    }
  }, [client, clients, props.history, isFetching]);

  const handleCurrentComponentChange = (value) => {
    setCurrentComponent(value);
    if (value === 3) setIsServerSidePagination(true);
    else setIsServerSidePagination(false);
  };

  const handleBackToClientList = () => {
    props.history.push('/client-management');
  };

  const { appUser } = props;

  const handleAddAccountGroupDialogOpen = () => {
    setIsAddAccountGroupDialogOpen(true);
  };

  const handleAddAccountGroupDialogClose = () => {
    setIsAddAccountGroupDialogOpen(false);
  };

  const handleGenerateReportDialogOpen = () => {
    setIsGenerateReportDialogOpen(true);
  };

  // in order to not flash header while the fetch for the clients and client occurs,
  // we need to show the spinner and either show the page or redirect (from useEffect)
  return (
    <>
      {isFetching ||
        (isSummaryFetching && (
          <div>
            <LoadSpinner size={200} />
          </div>
        ))}
      {!isFetching && !isSummaryFetching && (
        <>
          <ClientHeader
            appUser={appUser}
            header={'Client'}
            clientName={singleClient ? singleClient.clientName : null}
            hasButton={true}
            action={handleBackToClientList}
            buttonText={'Back to Clients Table'}
            hasClientSubNav={true}
            handleCurrentComponentChange={handleCurrentComponentChange}
            currentComponent={currentComponent}
            handleAddAccountGroupDialogOpen={handleAddAccountGroupDialogOpen}
            {...props}
          />
          <ComponentProfileBody padding='0'>
            {currentComponent === 0 && (
              <Summary
                clientNames={clientNames}
                singleClient={singleClient}
                categories={categories}
                types={types}
                maturityLimit={maturityLimit}
                handleCurrentComponentChange={handleCurrentComponentChange}
                setFromMaturingInvestmentsWidget={
                  setFromMaturingInvestmentsWidget
                }
                isSummaryFetching={isSummaryFetching}
                dayToDayVariance={dayToDayVariance}
                isDayToDayVarianceFetching={isDayToDayVarianceFetching}
                cashVestScores={cashVestScores}
                isCashVestScoreFetching={isCashVestScoreFetching}
                assetWidgetData={assetWidgetData}
                isAssetWidgetDataFetching={isAssetWidgetDataFetching}
                shortTermModelForecast={shortTermModelForecast}
                isShortTermModelForecastFetching={
                  isShortTermModelForecastFetching
                }
                amountsInvested={amountsInvested}
                isAmountsInvestedFetching={isAmountsInvestedFetching}
                shortTermForecast={shortTermForecast}
                isShortTermForecastFetching={isShortTermForecastFetching}
                interestRateComparisons={interestRateComparisons}
                isFetchingInterestRateComparisons={
                  isFetchingInterestRateComparisons
                }
                handleGenerateReportDialogOpen={handleGenerateReportDialogOpen}
                clientReportStatus={clientReportStatus}
                {...props}
              />
            )}
            {currentComponent === 1 && (
              <Accounts singleClient={singleClient} {...props} />
            )}
            {currentComponent === 2 && (
              <>
                <ClientAccountGroups singleClient={singleClient} {...props} />
                <AccountGroupDialogBox
                  isOpen={isAddAccountGroupDialogOpen}
                  title='Add Group'
                  handleClose={handleAddAccountGroupDialogClose}
                  isClientProfile={true}
                  clientId={clientId}
                />
              </>
            )}
            {currentComponent === 3 && (
              <Investments
                client={client}
                clientId={clientId}
                maturityLimit={maturityLimit[0]}
                fromMaturingInvestmentsWidget={fromMaturingInvestmentsWidget}
                isClientInvestments={true}
                singleClient={singleClient}
                // amounts invested and fetching variables below used to investment widget on the client level investments subnav
                amountsInvested={amountsInvested}
                isAmountsInvestedFetching={isAmountsInvestedFetching}
                currentComponent={currentComponent}
                isServerSidePagination={isServerSidePagination}
                {...props}
              />
            )}
            {currentComponent === 4 && (
              <ReportList
                client={client}
                clientId={clientId}
                singleClient={singleClient}
                currentComponent={currentComponent}
                clientReportStatus={clientReportStatus}
                handleGenerateReportDialogOpen={handleGenerateReportDialogOpen}
                {...props}
              />
            )}
            <GenerateReportDialog
              isOpen={isGenerateReportDialogOpen}
              clientReportStatus={clientReportStatus && clientReportStatus[0]}
              singleClient={singleClient}
              clientAccounts={clientAccounts}
              setIsGenerateReportDialogOpen={setIsGenerateReportDialogOpen}
              setIsReportGenerated={setIsReportGenerated}
              clientAccountGroups={clientAccountGroups}
              {...props}
            />
          </ComponentProfileBody>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients.allClients,
  isFetching: state.clients.isAllClientsFetching,
  categories: state.clients.allClientCategories,
  types: state.clients.allClientTypes,
  maturityLimit: state.administration.maturityLimit,
  singleClient: state.clients.summary,
  isSummaryFetching: state.clients.isSummaryFetching,
  dayToDayVariance: state.clients.dayToDayVariance,
  isDayToDayVarianceFetching: state.clients.isDayToDayVarianceFetching,
  cashVestScores: state.clients.cashVestScores,
  isCashVestScoreFetching: state.clients.isCashVestScoreFetching,
  assetWidgetData: state.clients.assetWidgetData,
  isAssetWidgetDataFetching: state.clients.isAssetWidgetDataFetching,
  // The two props below are for the table widget on the client summary page
  shortTermModelForecast: state.clients.shortTermModelForecast,
  isShortTermModelForecastFetching:
    state.clients.isShortTermModelForecastFetching,
  amountsInvested: state.investments.amountsInvested,
  isAmountsInvestedFetching: state.investments.isAmountsInvestedFetching,
  clientReportStatus: state.clients.clientReportStatus,
  shortTermForecast: state.clients.shortTermForecast,
  isShortTermForecastFetching: state.clients.isShortTermForecastFetching,
  interestRateComparisons: state.clients.interestRateComparisons,
  isFetchingInterestRateComparisons:
    state.clients.isFetchingInterestRateComparisons,
  clientAccounts: state.accounts.clientAccounts,
  clientAccountGroups: state.accounts.clientAccountGroups,
});

const ClientProfile = connect(mapStateToProps, {
  fetchAllClients,
  fetchSingleClient,
  fetchAllCategories,
  fetchAllTypes,
  fetchMaturityLimit,
  fetchDayToDayVariance,
  fetchCashVestScores,
  fetchAssetWidgetData,
  fetchShortTermModelForecast,
  fetchAmountsInvested,
  fetchClientReportStatus,
  fetchClientShortTermForecast,
  fetchInterestRateComparisons,
  fetchClientAccounts,
  fetchClientAccountGroups,
})(ClientProfileBase);

export default ClientProfile;
