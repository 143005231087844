import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchMonteCarloHistory } from '../../actions/analysis';
import LoadSpinner from '../../components/LoadSpinner';
import MonteCarloHistoryTable from '../../components/table/MonteCarloHistoryTable';
import { getOneYearRange } from '../../utils/functions';
import RunMonteCarloAnalysisDialog from '../../components/dialog/monte-carlo/RunMonteCarloAnalysisDialog';

const MonteCarloBase = ({
  appUser,
  clientId,
  monteCarloHistory,
  isFetchingMonteCarloHistory,
  ...props
}) => {
  const { fetchMonteCarloHistory } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [endRange, setEndRange] = useState(null);
  const [accountGroup, setAccountGroup] = useState('');
  const [accountGroupId, setAccountGroupId] = useState('');
  const [name, setName] = useState('');
  const [numberOfYears, setNumberOfYears] = useState(1);

  const handleRunAnalysisDialogOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleEditAndRerun = (row) => {
    setEndRange(row.endDateRange);
    setAccountGroup(row.groupName);
    setAccountGroupId(row.groupId);
    setName(row.monteCarloName);
    const endYear = Number(row.endDateRange.slice(6));
    const startYear = Number(row.startDateRange.slice(6));
    const numberOfYears = endYear - startYear;
    setNumberOfYears(numberOfYears);
    handleRunAnalysisDialogOpen();
  };

  useEffect(() => {
    fetchMonteCarloHistory(clientId);
  }, [fetchMonteCarloHistory, clientId]);

  return (
    <>
      {isFetchingMonteCarloHistory && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}
      {!isFetchingMonteCarloHistory && (
        <>
          <RunMonteCarloAnalysisDialog
            isOpen={isOpen}
            handleDialogClose={handleClose}
            monteCarloHistory={monteCarloHistory}
            clientId={clientId}
            endRange={endRange}
            accountGroup={accountGroup}
            accountGroupId={accountGroupId}
            name={name}
            numberOfYears={numberOfYears}
            {...props}
          />
          <MonteCarloHistoryTable
            appUser={appUser}
            clientId={clientId}
            monteCarloHistory={monteCarloHistory}
            isFetching={isFetchingMonteCarloHistory}
            rangeFilter={getOneYearRange()}
            handleRunAnalysisDialogOpen={handleRunAnalysisDialogOpen}
            handleEditAndRerun={handleEditAndRerun}
            {...props}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  monteCarloHistory: state.analysis.monteCarloHistory,
  isFetchingMonteCarloHistory: state.analysis.isFetchingMonteCarloHistory,
});

const MonteCarlo = connect(mapStateToProps, {
  fetchMonteCarloHistory,
})(MonteCarloBase);

export default MonteCarlo;
