import React from 'react';
import { Label, FlexColumn } from '../styled-components';
import { Select } from '@blueprintjs/select';
import { showListItems, filterList } from '../../utils/functions';
import { Button, Icon } from '@blueprintjs/core';
import {
  SelectAccountOverflowStyle,
  SelectDefaultInputStyle,
  SelectDisabledInputStyle,
} from '../../utils/fieldStyles';
import iconValue from '../../utils/icons';

const SelectField = ({
  selectValueFromList,
  value,
  labelName,
  items,
  isDisabled,
}) => {
  return (
    <FlexColumn>
      <Label>{labelName}</Label>
      <Select
        items={items}
        itemRenderer={showListItems}
        itemPredicate={filterList}
        onItemSelect={(item) => {
          selectValueFromList(item.value);
        }}
        filterable={true}
        popoverProps={{ minimal: true }}
      >
        <Button
          text={value ? value : labelName}
          style={
            items.length === 0 || isDisabled
              ? SelectDisabledInputStyle
              : value
              ? SelectAccountOverflowStyle
              : SelectDefaultInputStyle
          }
          disabled={items.length === 0 || isDisabled}
          rightIcon={
            items.length === 0 || isDisabled ? null : (
              <Icon
                icon={iconValue.caretDown}
                iconSize={20}
                style={{ marginRight: '10px' }}
              />
            )
          }
        />
      </Select>
    </FlexColumn>
  );
};

export default SelectField;
