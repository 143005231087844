import * as api from '../api/analysis';

export const fetchOpenReports = () => ({
  type: 'FETCH_OPEN_REPORTS',
  payload: api.fetchOpenReports(),
});

export const fetchMonteCarloHistory = (clientId) => ({
  type: 'FETCH_MONTE_CARLO_HISTORY',
  payload: api.fetchMonteCarloHistory(clientId),
});

export const fetchStepOneShortTermAnalysis = (clientId) => ({
  type: 'FETCH_STEP_ONE_SHORT_TERM_ANALYSIS',
  payload: api.fetchStepOneShortTermAnalysis(clientId),
});

export const fetchStepTwoShortTermAnalysis = (clientId) => ({
  type: 'FETCH_STEP_TWO_SHORT_TERM_ANALYSIS',
  payload: api.fetchStepTwoShortTermAnalysis(clientId),
});

export const fetchCashVestScores = (clientId) => ({
  type: 'FETCH_CASH_VEST_SCORES',
  payload: api.fetchCashVestScores(clientId),
});

export const fetchShortTermModelList = () => ({
  type: 'FETCH_SHORT_TERM_MODEL_LIST',
  payload: api.fetchShortTermModelList(),
});

export const fetchCashVestSubScores = (cashVestId) => ({
  type: 'FETCH_CASH_VEST_SUB_SCORES',
  payload: api.fetchCashVestSubScores(cashVestId),
});
