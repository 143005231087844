import React from 'react';
import { AlignCenter } from '../../../components/styled-components';
import { Formik, Form } from 'formik';
import ModalTextField from '../../../components/formik-text-fields/ModalTextField';
import SelectFieldFormik from '../../../components/select-fields/SelectFieldFormik';
import * as Yup from 'yup';
import FormActions from '../FormActions';
import {
  dateToString,
  getXYearsAgo,
  checkForDisable,
  stringToDate,
} from '../../../utils/functions';
import DateSelectField from '../../select-fields/DateSelectField';

const monteCarloSchema = Yup.object().shape({
  name: Yup.string().required('Required!'),
  accountGroup: Yup.string().required('Required!'),
  endRange: Yup.date().required('Required!').nullable(),
  numberOfYears: Yup.string()
    .required('Required!')
    .matches(/^[-]?\d*$/, 'Field must be a number.'),
});

const transformValues = (values, accountGroups) => {
  values.startRange = dateToString(
    getXYearsAgo(values.numberOfYears, values.endRange)
  );
  values.endRange = dateToString(values.endRange);

  if (values.accountGroup) {
    const accountGroupId = accountGroups.find(
      (x) => x.label === values.accountGroup
    ).value;
    values.accountGroupId = accountGroupId;
  }

  delete values.numberOfYears;
  return values;
};

const MonteCarloForm = ({
  title,
  onClose,
  handleRunAnalysis,
  endRange,
  clientAccountGroups,
  accountGroup,
  accountGroupId,
  name,
  clientId,
  numberOfYears,
}) => {
  return (
    <AlignCenter flexDirection='column' padding='10px 0'>
      <Formik
        initialValues={{
          endRange: stringToDate(endRange),
          accountGroup,
          accountGroupId,
          name,
          clientId,
          numberOfYears,
        }}
        validationSchema={monteCarloSchema}
        onSubmit={(values) => {
          if (title === 'Settings')
            handleRunAnalysis(transformValues(values, clientAccountGroups));
        }}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          // checks if the end date is at least 12 months after the minDate
          // the minDate is the first date in the account that has data.
          const isSaveDisabled = checkForDisable(
            values.endRange,
            values.accountGroup,
            clientAccountGroups
          );
          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter
                alignItems='flex-start'
                justifyContent='space-evenly'
                padding='20px 0'
              >
                <DateSelectField
                  setFieldValue={setFieldValue}
                  value={values.endRange || null}
                  labelName={'End Date'}
                  name='endRange'
                  maxDate={new Date()}
                />
                <ModalTextField
                  type='text'
                  name='numberOfYears'
                  placeholder='Number of Years'
                  label='Number of Years'
                  value={values.numberOfYears || ''}
                  autoComplete='off'
                />
              </AlignCenter>
              <AlignCenter
                alignItems='flex-start'
                justifyContent='space-evenly'
                padding='20px 0'
              >
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  value={values.accountGroup || ''}
                  labelName={'Account Group'}
                  items={clientAccountGroups}
                  name='accountGroup'
                  useLabel={true}
                />
                <ModalTextField
                  type='text'
                  name='name'
                  placeholder='Name'
                  label='Name'
                  value={values.name || ''}
                  autoComplete='off'
                />
              </AlignCenter>
              <FormActions
                isSaveDisabled={isSaveDisabled}
                saveText={'RUN WITH SETTINGS'}
                isSubmitting={isSubmitting}
                onClose={onClose}
              />
            </Form>
          );
        }}
      </Formik>
    </AlignCenter>
  );
};

export default MonteCarloForm;
