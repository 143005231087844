import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { RedText, MessageText } from '../../styled-components';
import { deleteKeyword } from '../../../api/administration';
import AppToast from '../../Toast';
import DialogDeleteActions from '../DialogDeleteActions';

const KeywordsDeleteDialogBox = ({
  isOpen,
  handleClose,
  title,
  keywordIds,
  fetchAllKeywords,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteKeywords = () => {
    setIsDeleting(true);
    deleteKeyword(keywordIds)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAllKeywords();
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to delete keyword.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete
          {keywordIds.length > 1 ? ' these keywords' : ' this keyword'}?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleDelete={handleDeleteKeywords}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default KeywordsDeleteDialogBox;
