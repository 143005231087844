import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import DialogDeleteActions from '../DialogDeleteActions';
import { connect } from 'react-redux';
import { RedText, MessageText } from '../../styled-components';
import { deleteNote } from '../../../api/notes';
import AppToast from '../../Toast';
import { fetchResolveNotes } from '../../../actions/notes';

const NoteDeleteDialogBoxBase = ({
  isOpen,
  title,
  note,
  handleClose,
  selectedNotes,
  fetchResolveNotes,
  isViewNoteDialogOpen,
  handleCloseViewDialog,
  setResetInitialFilter,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    let noteIds;
    if (selectedNotes.length > 0) {
      noteIds = selectedNotes.map((note) => note.noteId);
    } else {
      noteIds = [note.noteId];
    }
    note = {
      noteIds,
    };
    deleteNote(note)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete note(s)',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        fetchResolveNotes();
        setResetInitialFilter(true);
        handleClose();
        isViewNoteDialogOpen && handleCloseViewDialog();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete these{' '}
          {selectedNotes.length ? 'notes' : 'note'}?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const NoteDeleteDialogBox = connect(mapStateToProps, { fetchResolveNotes })(
  NoteDeleteDialogBoxBase
);

export default NoteDeleteDialogBox;
