import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { WidgetHeader, BoldText, CardDiv } from '../styled-components';
import { amountFormatter } from '../../utils/functions';
import colors from '../../utils/colors';
import LoadSpinner from '../LoadSpinner';
import { ResponsiveLine } from '@nivo/line';
import NonIdealStateComp from '../Non-Ideal-State';
import iconValue from '../../utils/icons';

const ClientAccountBalanceGraph = ({
  accountGraph,
  isAccountGraphFetching,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ width: '100%', height: '100%' }}
    >
      {isAccountGraphFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '20px 0',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {/* need to check to make sure the graph data exists, and there is sufficient data to display in the graph */}
      {/* if there is no accountGraph data or insufficient data, show nothing */}
      {(!isAccountGraphFetching &&
        accountGraph[2] &&
        accountGraph[2][0].data.length <= 1) ||
        (!isAccountGraphFetching && accountGraph.length === 0 && (
          <>
            <WidgetHeader padding='10px 20px'>
              {accountGraph && (
                <BoldText fontSize='1rem'>Account Balance</BoldText>
              )}
            </WidgetHeader>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        ))}

      {!isAccountGraphFetching &&
        accountGraph[2] &&
        accountGraph[2][0].data.length > 1 && (
          <>
            <WidgetHeader padding='10px 20px'>
              {accountGraph && (
                <BoldText fontSize='1rem'>Account Balance</BoldText>
              )}
            </WidgetHeader>

            <div
              style={{
                height: '20rem',
                display: 'flex',
                background: `${colors.containerBackground}`,
              }}
            >
              <ResponsiveLine
                data={accountGraph.length > 0 ? accountGraph[2] : []}
                margin={{ top: 20, right: 90, bottom: 50, left: 100 }}
                colors={colors.primary}
                axisTop={null}
                axisRight={null}
                enablePoints={false}
                enableGridX={false}
                enableGridY={false}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: accountGraph[1],
                  max: accountGraph[0],
                }}
                yFormat={(v) => amountFormatter(v, 2)}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 8,
                  tickPadding: 10,
                  tickValues:
                    accountGraph && accountGraph.length > 0
                      ? [
                          accountGraph[2][0].data[0].x,
                          accountGraph[2][0].data[
                            accountGraph[2][0].data.length - 1
                          ].x,
                        ]
                      : '',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 10,
                  format: (v) => amountFormatter(v, 0),
                }}
                // needed for interactivity
                useMesh={true}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: '#a9acbe',
                        strokeWidth: 1,
                      },
                    },
                  },
                  tooltip: {
                    container: { color: colors.boldText, fontWeight: 700 },
                  },
                }}
              />
            </div>
          </>
        )}
    </CardDiv>
  );
};

export default ClientAccountBalanceGraph;
