import axios from 'axios';
import { setHeader } from './index';

const NOTE_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/notes`;

export const fetchNoteComments = async (noteId) => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/comments/all/${noteId}`);
};

export const addComment = async (note) => {
  const request = await setHeader(axios);
  return request.post(`${NOTE_API}/comments`, { note });
};

export const editComment = async (note, commentId) => {
  const request = await setHeader(axios);
  return request.put(`${NOTE_API}/comments/${commentId}`, { note });
};

export const deleteComment = async (commentId) => {
  const request = await setHeader(axios);
  return request.delete(`${NOTE_API}/comments/${commentId}`);
};

export const fetchNoteCategories = async () => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/categories/list`);
};

export const createNote = async (note) => {
  const request = await setHeader(axios);
  return request.post(`${NOTE_API}/add`, { note });
};

export const updateNote = async (note, noteId) => {
  const request = await setHeader(axios);
  return request.put(`${NOTE_API}/${noteId}`, { note });
};

export const fetchSingleNote = async (noteId) => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/${noteId}`);
};

export const fetchClientNotes = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/client/${clientId}`);
};

export const fetchAllNotes = async () => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/all`);
};

export const resolveNotes = async (note) => {
  const request = await setHeader(axios);
  return request.put(`${NOTE_API}/resolve`, { note });
};

export const fetchResolveNotes = async () => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/resolve`);
};

export const restoreNote = async (note) => {
  const request = await setHeader(axios);
  return request.put(`${NOTE_API}/restore`, { note });
};

export const deleteNote = async (note) => {
  const request = await setHeader(axios);
  return request.delete(`${NOTE_API}/remove`, { data: { note } });
};

export const fetchAccountNotes = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/account/${accountId}`);
};

export const fetchBatchNotes = async (batchId) => {
  const request = await setHeader(axios);
  return request.get(`${NOTE_API}/batch/${batchId}`);
};
