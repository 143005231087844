import React from 'react';
import { Form, Formik } from 'formik';
import DateSelectField from '../../select-fields/DateSelectField';
import {
  AlignCenter,
  FlexColumn,
  MessageText,
  Label,
} from '../../styled-components';
import FormActions from '../FormActions';
import * as Yup from 'yup';
import { stringToDate } from '../../../utils/functions';
import FormikMultiSelectField from '../../multi-select-fields/FormikMultiSelectField';

const schema = Yup.object().shape({
  highYieldTargetAccountId: Yup.string()
    .test('checkAfterSet', 'Required!', function (value) {
      if (this.parent.liquidTargetAccount === true && !value) return false;
      return true;
    })
    .nullable(),
  highYieldLiquidBenchmarkOverride: Yup.string().nullable(),
});

//This function transform date values to string date for backend
const transformValues = (
  values,
  stmPrevQuarterResults,
  zbaAccountStructure,
  electronicTransacTemplate
) => {
  let report = Object.assign({}, values);
  delete report.startDate;
  delete report.endDate;
  report.allAccountsGraph = report.allAccountsGraph.map((item) => item.value);
  report.stressTestResult = report.stressTestResult.map((item) => item.value);
  report.stackedAreaAndStats = report.stackedAreaAndStats.map(
    (item) => item.value
  );
  report.includeAllGraphs = report.includeAllGraphs.map((item) => item.value);
  report.stmPrevQuarterResults = Number(stmPrevQuarterResults);
  report.zbaAccountStructure = Number(zbaAccountStructure);
  report.liquidTargetAccount = report.liquidTargetAccount === true ? 1 : 0;
  report.electronicTransacTemplate = Number(electronicTransacTemplate);
  if (values.liquidTargetAccount === false && values.highYieldTargetAccountId) {
    report.highYieldTargetAccountId = null;
  }
  return report;
};

const GenerateReportForm = ({
  startDate,
  endDate,
  liquidityLevelAmounts,
  stmNetGraph,
  stmPrevQuarterResults,
  stmMinTable,
  zbaAccountStructure,
  liquidTargetAccount,
  highYieldTargetAccountId,
  electronicTransacTemplate,
  highYieldLiquidBenchmarkOverride,
  allAccountsGraph,
  stressTestResult,
  stackedAreaAndStats,
  includeAllGraphs,
  minTransacForClosureGroupId,
  singleClient,
  clientAccountGroups,
  handleGenerateReportDialogClose,
  handleGeneratePdf,
}) => {
  return (
    <>
      <AlignCenter flexDirection='column' padding='10px 0'>
        <Formik
          initialValues={{
            startDate: stringToDate(startDate),
            endDate: stringToDate(endDate),
            highYieldTargetAccountId,
            highYieldLiquidBenchmarkOverride,
            allAccountsGraph,
            stressTestResult,
            stackedAreaAndStats,
            includeAllGraphs,
            minTransacForClosureGroupId,
            liquidTargetAccount,
          }}
          onSubmit={(values) => {
            handleGeneratePdf(
              transformValues(
                values,
                liquidityLevelAmounts,
                stmNetGraph,
                stmPrevQuarterResults,
                stmMinTable,
                zbaAccountStructure,
                liquidTargetAccount,
                electronicTransacTemplate
              )
            );
          }}
          validationSchema={schema}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  padding: '20px',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <FlexColumn style={{ flex: 1, paddingRight: '20px' }}>
                    <DateSelectField
                      name={'startDate'}
                      setFieldValue={setFieldValue}
                      value={values.startDate}
                      labelName={'Start Date'}
                      style={{ marginBottom: '20px' }}
                      isDateDisabled={true}
                    />
                    <DateSelectField
                      name={'endDate'}
                      setFieldValue={setFieldValue}
                      value={values.endDate}
                      labelName={'End Date'}
                      minDate={values.startDate}
                      isDateDisabled={true}
                    />
                  </FlexColumn>

                  <FlexColumn style={{ flex: 1, paddingLeft: '20px' }}>
                    <div>
                      <Label>Client ID</Label>
                      <MessageText>{singleClient.clientName}</MessageText>
                    </div>
                  </FlexColumn>
                </div>

                <FlexColumn style={{ gap: '20px' }}>
                  <FormikMultiSelectField
                    setSelectedItems={setFieldValue}
                    items={clientAccountGroups}
                    selectedItems={values.allAccountsGraph}
                    label='Cashboard Group(s)'
                    fill={true}
                    name='allAccountsGraph'
                  />
                  <FormikMultiSelectField
                    setSelectedItems={setFieldValue}
                    items={clientAccountGroups}
                    selectedItems={values.stressTestResult}
                    label='Long Term Liquidity Group(s)'
                    fill={true}
                    name='stressTestResult'
                  />
                  <FormikMultiSelectField
                    setSelectedItems={setFieldValue}
                    items={clientAccountGroups}
                    selectedItems={values.stackedAreaAndStats}
                    label='Account Optimization Group(s)'
                    fill={true}
                    name='stackedAreaAndStats'
                  />
                </FlexColumn>

                <FormActions
                  isSubmitting={isSubmitting}
                  onClose={handleGenerateReportDialogClose}
                />
              </Form>
            );
          }}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default GenerateReportForm;
