import React, { useEffect } from 'react';
import { Elevation } from '@blueprintjs/core';
import {
  WidgetHeader,
  Label,
  MessageText,
  BoldText,
  CardDiv,
} from '../../styled-components';
import LabelIcon from '../../LabelIcon';
import {
  fetchClientUnrealizedInterest,
  fetchAccountUnrealizedInterest,
} from '../../../actions/investments';
import { connect } from 'react-redux';
import { amountFormatter } from '../../../utils/functions';
import colors from '../../../utils/colors';
import LoadSpinner from '../../LoadSpinner';
import NonIdealState from '../../Non-Ideal-State';
import iconValue from '../../../utils/icons';

const UnrealizedInterestWidgetBase = ({
  clientUnrealizedInterest,
  accountUnrealizedInterest,
  isUnrealizedInterestFetching,
  fetchClientUnrealizedInterest,
  fetchAccountUnrealizedInterest,
  accountId,
  clientId,
  ...props
}) => {
  useEffect(() => {
    // since this is a shared component, only call function that is needed
    clientId && fetchClientUnrealizedInterest(clientId);
    accountId && fetchAccountUnrealizedInterest(accountId);
  }, [
    fetchClientUnrealizedInterest,
    clientId,
    accountId,
    fetchAccountUnrealizedInterest,
  ]);

  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget-summary'}
    >
      <WidgetHeader>
        <Label>
          <LabelIcon
            labelName={'Accrued Interest'}
            text={'Denotes interest from investments that haven’t matured yet.'}
          />
        </Label>
      </WidgetHeader>
      {/* if interest is fetching show spinner */}
      {isUnrealizedInterestFetching ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      ) : null}
      {/* if client id exists and object is not empty and interest is not fetching show values */}
      {!isUnrealizedInterestFetching &&
      clientId &&
      clientUnrealizedInterest &&
      Object.keys(clientUnrealizedInterest).length > 0 ? (
        <div
          style={{
            padding: '20px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              borderBottom: `1px solid ${colors.lightText}`,
            }}
          >
            <MessageText fontSize='1rem' margin='0 0 10px 0'>
              Most Recent (Last Month)
            </MessageText>
            <BoldText
              margin='0 0 50px 0'
              color={colors.boldText}
              fontSize='1.5rem'
            >
              {amountFormatter(clientUnrealizedInterest.lastMonth, 2)}
            </BoldText>
          </div>
          <div>
            <MessageText fontSize='1rem' margin='20px 0 10px 0'>
              Last Quarter
            </MessageText>
            <BoldText color={colors.boldText} fontSize='1.5rem'>
              {amountFormatter(clientUnrealizedInterest.lastQuarter, 2)}
            </BoldText>
          </div>
        </div>
      ) : // if account id exists and object is not empty and is not fetching, show values
      !isUnrealizedInterestFetching &&
        accountId &&
        accountUnrealizedInterest &&
        Object.keys(accountUnrealizedInterest).length > 0 ? (
        <div
          style={{
            padding: '20px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              borderBottom: `1px solid ${colors.lightText}`,
            }}
          >
            <MessageText fontSize='1rem' margin='0 0 10px 0'>
              Most Recent (Last Month)
            </MessageText>
            <BoldText
              margin='0 0 50px 0'
              color={colors.boldText}
              fontSize='1.5rem'
            >
              {amountFormatter(accountUnrealizedInterest.lastMonth, 2)}
            </BoldText>
          </div>
          <div>
            <MessageText fontSize='1rem' margin='20px 0 10px 0'>
              Last Quarter
            </MessageText>
            <BoldText color={colors.boldText} fontSize='1.5rem'>
              {amountFormatter(accountUnrealizedInterest.lastQuarter, 2)}
            </BoldText>
          </div>
        </div>
      ) : (
        // if interest is not fetching show non ideal state
        !isUnrealizedInterestFetching && (
          <NonIdealState
            icon={iconValue.warning}
            title={'No data.'}
            description={'There are no results to be displayed.'}
          />
        )
      )}
    </CardDiv>
  );
};

const mapStateToProps = (state) => ({
  clientUnrealizedInterest: state.investments.clientUnrealizedInterest,
  accountUnrealizedInterest: state.investments.accountUnrealizedInterest,
  isUnrealizedInterestFetching: state.investments.isUnrealizedInterestFetching,
});

const UnrealizedInterestWidget = connect(mapStateToProps, {
  fetchClientUnrealizedInterest,
  fetchAccountUnrealizedInterest,
})(UnrealizedInterestWidgetBase);

export default UnrealizedInterestWidget;
