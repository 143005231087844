import React from 'react';
import { AlignCenter } from '../styled-components';
import { Formik, Form } from 'formik';
import ModalTextField from '../formik-text-fields/ModalTextField';
import { checkPermissions, formattedMonthsList, calculateCycleOptions } from '../../utils/functions';
import permissions from '../../utils/permissions';
import * as Yup from 'yup';
import find from 'lodash/find';
import { statusList, stateList, allowedDataGatheringFrequenciesList } from '../../utils/constantsList';
import FormActions from './FormActions';
import SelectFieldFormik from '../select-fields/SelectFieldFormik';

//Regex: /^[0-9]*$/ -> Allow 0, no decimals, only positive numbers
const clientSchema = Yup.object().shape({
  clientName: Yup.string().required('Required!'),
  budget: Yup.string()
    .matches(/^[0-9]*$/, 'Positive number without decimal!')
    .required('Required!'),
  clientCategory: Yup.string().required('Required!'),
  clientType: Yup.string().required('Required!'),
  state: Yup.string().required('Required!'),
  initialInterest: Yup.string().matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  dataGatheringFrequency: Yup.number().required('Required!'),
  reportFrequency: Yup.number().required('Required!'),
  reportCycle: Yup.number().required('Required!'),
});

//add client api expects different parameter names for these fields
//could possibly change the api to accept the same
const onAddTransform = (values) => {
    let copiedValues = Object.assign({}, values);

    // Change field names
    const addClientName = copiedValues.clientName;
    delete copiedValues.clientName;
    copiedValues = { ...copiedValues, addClientName };

    const addBudget = copiedValues.budget;
    delete copiedValues.budget;
    copiedValues = { ...copiedValues, addBudget };

  return copiedValues;
};

const setSelectFieldValue = (propertyItemsList, property) => {
    return (propertyItemsList.map(item=>item.value)).includes(property)
                            ?find(propertyItemsList, ['value', property]).label
                            : property===undefined ? 'Not Set'
                            : String(property);
};

const ClientForm = ({
  title,
  appUser,
  clientName,
  clientCategory,
  categories,
  clientType,
  types,
  budget,
  status,
  initialInterest,
  state,
  clientId,
  dataGatheringFrequency,
  reportFrequency,
  reportCycle,
  allowedReportFrequencies,
  handleCreateClient,
  handleEditClient,
  isTryingToSubmit,
  closeDialog,
}) => {
  const allowedReportFrequenciesList = formattedMonthsList(allowedReportFrequencies);
  return (
    <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
      <Formik
        initialValues={{
          clientName,
          budget,
          clientCategory,
          clientType,
          state,
          status,
          initialInterest,
          dataGatheringFrequency: !dataGatheringFrequency ?3 : dataGatheringFrequency,
          reportFrequency,
          reportCycle,
        }}
        validationSchema={clientSchema}
        onSubmit={(values) => {
          if (title === 'Add New Client')
            handleCreateClient(onAddTransform(values));
          else handleEditClient(values, clientId);
        }}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, handleSelectChange, hasHandleFunction }) => {
          const reportCycleOptions = calculateCycleOptions(Number(values.reportFrequency));

          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <ModalTextField
                  type='text'
                  name='clientName'
                  placeholder='Client Name'
                  label='Client Name'
                  value={values.clientName || ''}
                  autoComplete='off'
                />
                <ModalTextField
                  type='text'
                  name='budget'
                  placeholder='Budget Size'
                  label='Budget Size (millions)'
                  value={values.budget || ''}
                  autoComplete='off'
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  name='clientCategory'
                  value={values.clientCategory || ''}
                  items={categories}
                  labelName='Client Category'
                />
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  name='clientType'
                  value={values.clientType || ''}
                  items={types}
                  labelName='Client Type'
                />
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  name='state'
                  value={values.state || ''}
                  items={stateList}
                  labelName='State'
                />
                {checkPermissions(
                  appUser.permList,
                  permissions.DEACTIVATE_CLIENTS
                ) ? (
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='status'
                    value={
                      values.status >= 0
                        ? find(statusList, ['value', values.status]).label
                        : 'Active'
                    }
                    items={statusList}
                    labelName='Active Status'
                  />
                ) : (
                  <div style={{ minWidth: '271px' }}></div>
                )}
              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <ModalTextField
                  type='text'
                  name='initialInterest'
                  placeholder='Initial Interest'
                  label='Initial Interest'
                  value={values.initialInterest || ''}
                  autoComplete='off'
                />
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  name='dataGatheringFrequency'
                  value={setSelectFieldValue(allowedDataGatheringFrequenciesList, values.dataGatheringFrequency)}
                  items={allowedDataGatheringFrequenciesList}
                  labelName='Data Gathering Frequency'
                />

              </AlignCenter>
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  hasHandleFunction={1}
                  name='reportFrequency'
                  handleSelectChange={(item)=>{setFieldValue('reportFrequency', item.value);
                                               setFieldValue('reportCycle', undefined)}}
                  value={setSelectFieldValue(allowedReportFrequenciesList, values.reportFrequency)}
                  items={allowedReportFrequenciesList}
                  labelName='Report Frequency'
                />
                <SelectFieldFormik
                  setFieldValue={setFieldValue}
                  isDisabled={!values.reportFrequency}
                  name='reportCycle'
                  value={setSelectFieldValue(reportCycleOptions, values.reportCycle)}
                  items={reportCycleOptions}
                  labelName='Report Cycle'
                />
              </AlignCenter>

              <FormActions
                isSubmitting={isTryingToSubmit}
                onClose={closeDialog}
              />
            </Form>
          );
        }}
      </Formik>
    </AlignCenter>
  );
};

export default ClientForm;
