import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AlignCenter } from '../../styled-components';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import { RadioGroup, Radio } from '@blueprintjs/core';
import ModalTextArea from '../../formik-text-fields/ModalTextArea';
import { find } from 'lodash';
import FormActions from '../FormActions';
import { stateList } from '../../../utils/constantsList';

const negotiatedRateSchema = Yup.object().shape({
  clientId: Yup.string().required('Required!'),
  bankId: Yup.string().required('Required!'),
  state: Yup.string().required('Required!'),
  amount: Yup.string().matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  term: Yup.string().matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.'),
  rate: Yup.string()
    .matches(/^[-]?\d*\.?\d*$/, 'Field must be a number.')
    .required('Required!'),
  realized: Yup.string().required('Required!'),
  quoteType: Yup.string(),
  comments: Yup.string(),
});

const NegotiatedRateForm = ({
  title,
  singleClient,
  hasClient,
  closeDialog,
  handleCreate,
  handleEdit,
  clientList,
  bankList,
  appUser,
  quoteTypeList,
  negotiatedRate,
}) => {
  return (
    <AlignCenter flexDirection='column' padding='10px 0 10px 0'>
      <Formik
        initialValues={{
          clientId: hasClient
            ? singleClient.clientId
            : negotiatedRate
            ? negotiatedRate.clientId
            : '',
          bankId: negotiatedRate ? negotiatedRate.bankId : '',
          state: negotiatedRate ? negotiatedRate.state : '',
          amount: negotiatedRate ? negotiatedRate.amount : '',
          term: negotiatedRate ? negotiatedRate.term : '',
          rate: negotiatedRate ? negotiatedRate.rate : '',
          quoteTypeId: negotiatedRate ? negotiatedRate.quoteTypeId : '',
          userName: negotiatedRate
            ? negotiatedRate.userName
            : `${appUser.firstName} ${appUser.lastName}`,
          realized: negotiatedRate ? String(negotiatedRate.realized) : '1',
          comments: negotiatedRate ? negotiatedRate.comments : '',
        }}
        validationSchema={negotiatedRateSchema}
        onSubmit={(values) => {
          title === 'Add Negotiated Rate'
            ? handleCreate(values)
            : handleEdit(values, negotiatedRate.negotiatedRateId);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={
                  values.clientId === ''
                    ? ''
                    : find(clientList, ['value', Number(values.clientId)]).label
                }
                labelName='Client Name'
                items={hasClient ? [] : clientList}
                name='clientId'
                isDisabled={hasClient}
              />
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={
                  values.bankId === ''
                    ? ''
                    : find(bankList, ['value', Number(values.bankId)]).label
                }
                labelName='Bank Name'
                items={bankList}
                name='bankId'
              />
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                name='state'
                value={values.state || ''}
                items={stateList}
                labelName='State'
              />
            </AlignCenter>
            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
              <ModalTextField
                type='text'
                name='amount'
                placeholder='Amount'
                label='Amount'
                value={values.amount || ''}
                isCurrency={true}
                autoComplete='off'
              />
              <ModalTextField
                type='text'
                name='term'
                placeholder='Term'
                label='Term'
                value={values.term || ''}
                autoComplete='off'
              />
              <ModalTextField
                className='percentage'
                type='text'
                name='rate'
                placeholder='Rate'
                label='Rate'
                value={values.rate || ''}
                isPercentage={true}
                autoComplete='off'
              />
            </AlignCenter>
            <AlignCenter justifyContent='space-evenly' padding='20px 0'>
              <SelectFieldFormik
                setFieldValue={setFieldValue}
                value={
                  values.quoteTypeId === ''
                    ? ''
                    : find(quoteTypeList, ['value', Number(values.quoteTypeId)])
                        .label
                }
                labelName='Quote Type'
                items={quoteTypeList}
                name='quoteTypeId'
              />
              <ModalTextField
                setFieldValue={setFieldValue}
                value={values.userName}
                label='Who Captured Rate'
                isDisabled={true}
                name='userName'
              />
              <div className='radio-group' style={{ width: '271px' }}>
                <RadioGroup
                  label={'Realized?'}
                  onChange={(e) => setFieldValue('realized', e.target.value)}
                  selectedValue={values.realized}
                  inline={true}
                >
                  <Radio
                    label='Yes'
                    value='1'
                    style={{ marginRight: '20px' }}
                  />
                  <Radio label='No' value='0' />
                </RadioGroup>
              </div>
            </AlignCenter>
            <div style={{ padding: '1rem 2rem' }}>
              <ModalTextArea
                label='Comments'
                name='comments'
                value={values.comments || ''}
              />
            </div>
            {/* {Form Footer} */}
            <FormActions isSubmitting={isSubmitting} onClose={closeDialog} />
          </Form>
        )}
      </Formik>
    </AlignCenter>
  );
};

export default NegotiatedRateForm;
