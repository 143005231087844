import React from 'react';
import { Dialog } from '@blueprintjs/core';
import UserForm from '../forms/UserForm';

const UsersDialogBox = ({
  isOpen,
  title,
  closeDialog,
  firstName,
  lastName,
  email,
  phoneNumber,
  status,
  role,
  roles,
  resetMfaMessage,
  showGreenMessage,
  setClientAssociation,
  clientList,
  clientAssociation,
  handleCreateUser,
  handleEditUser,
  resetUserMfa,
  appUser,
  isResetting,
  resendConfirmationCode,
  isResendingCode,
}) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title}
        isCloseButtonShown={false}
      >
        <UserForm
          title={title}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          status={status}
          role={role}
          roles={roles}
          resetMfaMessage={resetMfaMessage}
          showGreenMessage={showGreenMessage}
          setClientAssociation={setClientAssociation}
          clientList={clientList}
          clientAssociation={clientAssociation}
          handleCreateUser={handleCreateUser}
          handleEditUser={handleEditUser}
          closeDialog={closeDialog}
          resetUserMfa={resetUserMfa}
          appUser={appUser}
          isResetting={isResetting}
          resendConfirmationCode={resendConfirmationCode}
          isResendingCode={isResendingCode}
        />
      </Dialog>
    </>
  );
};

export default UsersDialogBox;
