import { combineReducers } from 'redux';

const allOpenReports = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_OPEN_REPORTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_OPEN_REPORTS_PENDING':
    case 'FETCH_OPEN_REPORTS_REJECTED':
      return [];
    default:
      return state;
  }
};

const clientValidationHistory = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_VALIDATION_HISTORY_FULFILLED':
      return action.payload.data;
    case 'FETCH_VALIDATION_HISTORY_PENDING':
    case 'FETCH_VALIDATION_HISTORY_REJECTED':
      return [];
    default:
      return state;
  }
};

const isValidationHistoryFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_VALIDATION_HISTORY_PENDING':
      return true;
    case 'FETCH_VALIDATION_HISTORY_FULFILLED':
    case 'FETCH_VALIDATION_HISTORY_REJECTED':
      return false;
    default:
      return state;
  }
};

const isOpenReportsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_OPEN_REPORTS_REJECTED':
    case 'FETCH_OPEN_REPORTS_FULFILLED':
      return false;
    case 'FETCH_OPEN_REPORTS_PENDING':
      return true;
    default:
      return state;
  }
};

const clientBatches = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_BATCHES_FULFILLED':
      return action.payload.data;
    case 'FETCH_CLIENT_BATCHES_PENDING':
    case 'FETCH_CLIENT_BATCHES_REJECTED':
      return [];
    case 'FETCH_SINGLE_BATCH_FULFILLED':
      return state.map((batch) => {
        if (batch.batchId === action.payload.data[0].batchId) {
          return action.payload.data[0];
        }
        return batch;
      });
    case 'FETCH_SINGLE_BATCH_PENDING':
    case 'FETCH_SINGLE_BATCH_REJECTED':
      return state;
    default:
      return state;
  }
};

const isClientBatchesFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_BATCHES_REJECTED':
    case 'FETCH_CLIENT_BATCHES_FULFILLED':
      return false;
    case 'FETCH_CLIENT_BATCHES_PENDING':
      return true;
    default:
      return state;
  }
};

const batchAccounts = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_BATCH_ACCOUNTS_FULFILLED':
      return action.payload.data;
    case 'FETCH_BATCH_ACCOUNTS_PENDING':
    case 'FETCH_BATCH_ACCOUNTS_REJECTED':
      return [];
    case 'FETCH_SINGLE_BATCH_ACCOUNT_FULFILLED':
      return state.map((batchAccount) => {
        if (
          batchAccount.batchAccountId === action.payload.data[0].batchAccountId
        ) {
          return action.payload.data[0];
        }

        return batchAccount;
      });
    case 'FETCH_SINGLE_BATCH_ACCOUNT_REJECTED':
      return state;
    case 'FETCH_SINGLE_BATCH_ACCOUNT_PENDING':
      return state;
    default:
      return state;
  }
};

const isBatchAccountsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_BATCH_ACCOUNTS_REJECTED':
    case 'FETCH_BATCH_ACCOUNTS_FULFILLED':
      return false;
    case 'FETCH_BATCH_ACCOUNTS_PENDING':
      return true;
    default:
      return state;
  }
};

const dataIngestion = combineReducers({
  allOpenReports,
  isOpenReportsFetching,
  clientValidationHistory,
  isValidationHistoryFetching,
  clientBatches,
  isClientBatchesFetching,
  batchAccounts,
  isBatchAccountsFetching,
});

export default dataIngestion;
