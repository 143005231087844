import React from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { RedText, MessageText } from '../../../components/styled-components';
import AppToast from '../../../components/Toast';
import DialogDeleteActions from '../DialogDeleteActions';
import { deleteComment } from '../../../api/notes';

const DeleteCommentDialog = ({
  isDeleteDialogOpen,
  handleCloseDeleteDialog,
  isDeleting,
  setIsDeleting,
  commentToDelete,
  fetchNoteComments,
  fetchSingleNote,
  noteId,
}) => {
  const handleDeleteComment = () => {
    setIsDeleting(true);
    deleteComment(commentToDelete.commentId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to delete comment.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        handleCloseDeleteDialog();
        fetchNoteComments(noteId);
        fetchSingleNote(noteId);
      });
  };
  return (
    <Dialog
      isOpen={isDeleteDialogOpen}
      onClose={handleCloseDeleteDialog}
      title='Delete Comment'
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>Are you sure you want to delete this comment?</MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleDelete={handleDeleteComment}
        handleClose={handleCloseDeleteDialog}
      />
    </Dialog>
  );
};

export default DeleteCommentDialog;
