import axios from 'axios';
import { setHeader } from './index';

const ACCOUNT_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/accounts`;

export const fetchCompleteAccounts = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/all/${clientId}`);
};

export const fetchReviewAccounts = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/review/${clientId}`);
};

export const fetchAccountCategories = async () => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/categories`);
};

export const fetchAccountTypes = async () => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/types`);
};

export const fetchAccount = async (clientId, accountId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/single/${clientId}/${accountId}`);
};

export const createAccount = async (account) => {
  const request = await setHeader(axios);
  return request.post(`${ACCOUNT_API}/add`, { account });
};

export const updateAccount = async (accountId, account) => {
  const request = await setHeader(axios);
  return request.put(`${ACCOUNT_API}/${accountId}`, { account });
};

export const fetchClientAccounts = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/list/${clientId}`);
};

export const fetchAccountLinks = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/link/${accountId}`);
};

export const createAccountLink = async (account) => {
  const request = await setHeader(axios);
  return request.post(`${ACCOUNT_API}/link`, { account });
};

export const updateAccountLink = async (accountId, account) => {
  const request = await setHeader(axios);
  return request.put(`${ACCOUNT_API}/link/${accountId}`, { account });
};

export const createAccountGroup = async (account) => {
  const request = await setHeader(axios);
  return request.post(`${ACCOUNT_API}/groups/`, { account });
};
export const removeAccountLink = async (accountId) => {
  const request = await setHeader(axios);
  return request.delete(`${ACCOUNT_API}/link/${accountId}`);
};

export const fetchClientAccountGroups = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/groups/list/${clientId}`);
};

export const fetchAccountGroupAssociations = async (accountId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/groups/association/${accountId}`);
};

export const assignAccountGroupAssociation = async (account, accountId) => {
  const request = await setHeader(axios);
  return request.put(`${ACCOUNT_API}/groups/association/${accountId}`, {
    account,
  });
};

export const fetchAccountGroups = async (clientId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/groups/all/${clientId}`);
};

export const deleteAccountGroups = async (account) => {
  const request = await setHeader(axios);
  return request.delete(`${ACCOUNT_API}/groups`, { data: { account } });
};

export const updateAccountGroup = async (account, groupId) => {
  const request = await setHeader(axios);
  return request.put(`${ACCOUNT_API}/groups/${groupId}`, { account });
};

export const fetchAccountsByGroup = async (accountGroupId) => {
  const request = await setHeader(axios);
  return request.get(`${ACCOUNT_API}/groups/${accountGroupId}`);
};

export const addAccountsToAccountGroup = async (
  accountGroupId,
  accountIdList
) => {
  const request = await setHeader(axios);
  return request.post(`${ACCOUNT_API}/groups/${accountGroupId}`, {
    account: { accountIdList },
  });
};

export const removeAccountGroupAssociation = async (account) => {
  const request = await setHeader(axios);
  return request.delete(`${ACCOUNT_API}/groups/association`, {
    data: { account },
  });
};

export const bulkUploadAccounts = async (fileUpload) => {
  const request = await setHeader(axios);
  return request.post(`${ACCOUNT_API}/upload`, { fileUpload });
};

export const deleteAccounts = async (account) => {
  const request = await setHeader(axios);
  return request.delete(`${ACCOUNT_API}/review`, {
    data: { account },
  });
};
