import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { RedText, MessageText } from '../../styled-components';
import DialogDeleteActions from '../DialogDeleteActions';
import { deleteAccountGroups } from '../../../api/accounts';
import AppToast from '../../Toast';
import { connect } from 'react-redux';
import { fetchAccountGroups } from '../../../actions/accounts';

const AccountGroupDeleteDialogBoxBase = ({
  isOpen,
  handleClose,
  title,
  clientId,
  selectedAccountGroups,
  accountGroup,
  fetchAccountGroups,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    let groupIds;
    if (selectedAccountGroups.length) {
      groupIds = selectedAccountGroups.map(
        (accountGroup) => accountGroup.groupID
      );
    } else {
      groupIds = [accountGroup.groupID];
    }
    const account = { groupIds };
    deleteAccountGroups(account)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete group(s)',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchAccountGroups(clientId);
        setIsDeleting(false);
        handleClose();
      });
  };
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      isCloseButtonShown={false}
      onClose={handleClose}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete{' '}
          {selectedAccountGroups.length ? 'these' : 'this'}{' '}
          {selectedAccountGroups.length ? 'groups' : 'group'}?
          This operation may result in deletion of Treasury Data, Liquidity Levels information, Short Term Model and Monte Carlo results.
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const AccountGroupDeleteDialogBox = connect(mapStateToProps, {
  fetchAccountGroups,
})(AccountGroupDeleteDialogBoxBase);

export default AccountGroupDeleteDialogBox;
