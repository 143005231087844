import React, { useState } from 'react';
import {
  ComponentBody,
  ComponentProfileBody,
  SecondarySubNavContainer,
  WidgetContainer,
} from '../../../../components/styled-components';
import ClientSecondarySubNavigation from '../../../../components/navigation/SubNavigation/ClientSecondarySubNavigation';
import Banks from './Banks/Banks';
import SummaryInfoSection from '../../../../components/client/client-profile/SummaryInfoSection';
import NegotiatedRates from './NegotiatedRates/NegotiatedRates';
import Notes from './Notes/Notes';

const Summary = ({
  singleClient,
  fetchSingleClient,
  categories,
  types,
  clientNames,
  maturityLimit,
  handleCurrentComponentChange,
  setFromMaturingInvestmentsWidget,
  isSummaryFetching,
  dayToDayVariance,
  isDayToDayVarianceFetching,
  cashVestScores,
  isCashVestScoreFetching,
  assetWidgetData,
  isAssetWidgetDataFetching,
  shortTermModelForecast,
  isShortTermModelForecastFetching,
  amountsInvested,
  isAmountsInvestedFetching,
  shortTermForecast,
  isShortTermForecastFetching,
  interestRateComparisons,
  isFetchingInterestRateComparisons,
  handleGenerateReportDialogOpen,
  clientReportStatus,
  ...props
}) => {
  const [currentComponent, setCurrentComponent] = useState(0);

  const handleComponentChange = (value) => {
    setCurrentComponent(value);
  };
  const { appUser, open } = props;

  return (
    <ComponentBody padding='0'>
      <WidgetContainer padding='14rem 0 0' margin='0'>
        <SummaryInfoSection
          singleClient={singleClient}
          fetchSingleClient={fetchSingleClient}
          categories={categories}
          types={types}
          clientNames={clientNames}
          isSummaryFetching={isSummaryFetching}
          handleCurrentComponentChange={handleCurrentComponentChange}
          setFromMaturingInvestmentsWidget={setFromMaturingInvestmentsWidget}
          maturityLimit={maturityLimit}
          dayToDayVariance={dayToDayVariance}
          isDayToDayVarianceFetching={isDayToDayVarianceFetching}
          cashVestScores={cashVestScores}
          isCashVestScoreFetching={isCashVestScoreFetching}
          assetWidgetData={assetWidgetData}
          isAssetWidgetDataFetching={isAssetWidgetDataFetching}
          shortTermModelForecast={shortTermModelForecast}
          isShortTermModelForecastFetching={isShortTermModelForecastFetching}
          amountsInvested={amountsInvested}
          isAmountsInvestedFetching={isAmountsInvestedFetching}
          shortTermForecast={shortTermForecast}
          isShortTermForecastFetching={isShortTermForecastFetching}
          interestRateComparisons={interestRateComparisons}
          isFetchingInterestRateComparisons={isFetchingInterestRateComparisons}
          handleGenerateReportDialogOpen={handleGenerateReportDialogOpen}
          {...props}
        />
      </WidgetContainer>
      <SecondarySubNavContainer className={open}>
        <ClientSecondarySubNavigation
          currentComponent={currentComponent}
          handleComponentChange={handleComponentChange}
          appUser={appUser}
        />
      </SecondarySubNavContainer>
      <ComponentProfileBody>
        {currentComponent === 0 && (
          <Banks clientReportStatus={clientReportStatus} singleClient={singleClient} {...props} />
        )}
        {currentComponent === 1 && (
          <NegotiatedRates singleClient={singleClient} {...props} />
        )}
        {currentComponent === 2 && (
          <Notes appUser={appUser} singleClient={singleClient} {...props} />
        )}
      </ComponentProfileBody>
    </ComponentBody>
  );
};

export default Summary;
