import React, { useEffect } from 'react';
import {
  fetchAllClients,
  fetchAllCategories,
  fetchAllTypes,
  fetchSingleClient,
  fetchAllowedReportFrequencies,
} from '../../actions/clients';
import { connect } from 'react-redux';
import ClientTable from '../../components/table/ClientTable';
import { ComponentBody } from '../../components/styled-components';
import ClientHeader from '../../components/headers/ClientHeader';

const ClientManagementBase = ({
  appUser,
  clients,
  categories,
  types,
  isAllClientsFetching,
  allowedReportFrequencies,
  ...props
}) => {
  const {
    fetchAllClients,
    fetchAllCategories,
    fetchAllTypes,
    fetchSingleClient,
    fetchAllowedReportFrequencies,
  } = props;

  useEffect(() => {
    fetchAllClients();
    fetchAllCategories();
    fetchAllTypes();
    fetchAllowedReportFrequencies();
  }, [
    fetchAllClients,
    fetchAllCategories,
    fetchAllTypes,
    fetchAllowedReportFrequencies,
  ]);

  const clientNames = clients.map((client) => client.clientName.toLowerCase());
  return (
    <>
      <ClientHeader
        appUser={appUser}
        header={'Manage Clients'}
        clientName={''}
        buttonText={'Add New Client'}
        hasButton={true}
        types={types}
        categories={categories}
        clientNames={clientNames}
        fetchAllClients={fetchAllClients}
        allowedReportFrequencies={allowedReportFrequencies}
        {...props}
      />
      <ComponentBody>
        <ClientTable
          appUser={appUser}
          clients={clients}
          isFetching={isAllClientsFetching}
          fetchAllClients={fetchAllClients}
          fetchSingleClient={fetchSingleClient}
          types={types}
          categories={categories}
          clientNames={clientNames}
          allowedReportFrequencies={allowedReportFrequencies}
          {...props}
        />
      </ComponentBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients.allClients,
  categories: state.clients.allClientCategories,
  types: state.clients.allClientTypes,
  allowedReportFrequencies: state.clients.allowedReportFrequencies,
  isAllClientsFetching: state.clients.isAllClientsFetching,
});

const ClientManagement = connect(mapStateToProps, {
  fetchSingleClient,
  fetchAllClients,
  fetchAllCategories,
  fetchAllTypes,
  fetchAllowedReportFrequencies,
})(ClientManagementBase);

export default ClientManagement;
