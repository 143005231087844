import * as api from '../api/users';

export const fetchAllUsers = () => ({
  type: 'FETCH_ALL_USERS',
  payload: api.fetchAllUsers(),
});

export const fetchSingleUser = (userId) => ({
  type: 'FETCH_SINGLE_USER',
  payload: api.fetchSingleUser(userId),
});

export const fetchUserList = () => ({
  type: 'FETCH_USER_LIST',
  payload: api.fetchUserList(),
});
