import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { WidgetHeader, Label, TextValue, CardDiv } from '../styled-components';
import LoadSpinner from '../LoadSpinner';
import { ResponsiveLine } from '@nivo/line';
import colors, { colorList } from '../../utils/colors';
import iconValue from '../../utils/icons';
import { amountFormatter } from '../../utils/functions';
import NonIdealStateComp from '../Non-Ideal-State';
import moment from 'moment';

const ShortTermCashWidget = ({
  shortTermForecast,
  isShortTermForecastFetching,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget'}
      style={{ width: '500px' }}
    >
      {isShortTermForecastFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadSpinner size={100} />
        </div>
      )}

      {!isShortTermForecastFetching &&
        shortTermForecast &&
        shortTermForecast.length === 0 && (
          <>
            <WidgetHeader>
              <Label>Short-Term Cash Position Forecast</Label>
            </WidgetHeader>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        )}

      {!isShortTermForecastFetching &&
        shortTermForecast &&
        shortTermForecast[3] &&
        shortTermForecast[3].length > 0 && (
          <>
            <WidgetHeader display='block'>
              <Label>Short-Term Cash Position Forecast</Label>
              <TextValue as='p' padding='5px 0 0 0'>
                As of {moment(shortTermForecast[2]).format('MM-DD-YYYY')}
              </TextValue>
            </WidgetHeader>

            <div
              style={{
                height: '18.3rem',
                display: 'flex',
                paddingTop: '1rem',
                background: `${colors.containerBackground}`,
              }}
            >
              <ResponsiveLine
                data={shortTermForecast[3]}
                margin={{ top: 40, right: 50, bottom: 50, left: 100 }}
                colors={colorList}
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                enableGridY={false}
                enablePoints={false}
                areaOpacity={1}
                xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false }}
                xFormat='time:%b-%Y'
                yScale={{
                  type: 'linear',
                  min: shortTermForecast[1],
                  max: shortTermForecast[0],
                }}
                yFormat={(v) => amountFormatter(v, 2)}
                axisBottom={{
                  format: '%b-%Y',
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 10,
                  tickValues: 'every 2 months',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 10,
                  format: (v) => amountFormatter(v, 0),
                }}
                // needed for interactivity
                useMesh={true}
                legends={[
                  {
                    dataForm: 'keys',
                    anchor: 'top',
                    direction: 'row',
                    translateX: 20,
                    translateY: -40,
                    itemsSpacing: 2,
                    itemWidth: 110,
                    itemHeight: 30,
                    itemDirection: 'left-to-right',
                    symbolSize: 10,
                    symbolShape: 'square',
                  },
                ]}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: '#a9acbe',
                        strokeWidth: 1,
                      },
                    },
                  },
                  tooltip: {
                    container: { color: colors.boldText, fontWeight: 700 },
                  },
                }}
              />
            </div>
          </>
        )}
    </CardDiv>
  );
};

export default ShortTermCashWidget;
