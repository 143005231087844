import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { WidgetHeader, Label, CardDiv } from '../../styled-components';
import LoadSpinner from '../../LoadSpinner';
import { ResponsiveLine } from '@nivo/line';
import colors, { colorList } from '../../../utils/colors';
import iconValue from '../../../utils/icons';
import { amountFormatter } from '../../../utils/functions';
import NonIdealStateComp from '../../Non-Ideal-State';

const CashPositionForecastWidget = ({
  shortTermCashPosition,
  isShortTermCashPositionFetching,
}) => {
  return (
    <CardDiv
      interactive={false}
      elevation={Elevation.TWO}
      className={'widget-summary'}
    >
      {isShortTermCashPositionFetching && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <LoadSpinner size={100} />
          </div>
        </>
      )}

      {!isShortTermCashPositionFetching &&
        shortTermCashPosition &&
        shortTermCashPosition.length === 0 && (
          <>
            <WidgetHeader>
              <Label>Short-Term Cash Position Forecast</Label>
            </WidgetHeader>
            <NonIdealStateComp
              icon={iconValue.warning}
              title={'No Results.'}
              description={'There are no results to be displayed.'}
            />
          </>
        )}

      {!isShortTermCashPositionFetching &&
        shortTermCashPosition &&
        shortTermCashPosition[3] &&
        shortTermCashPosition[3].length > 0 && (
          <>
            <WidgetHeader display='block'>
              <Label>
                Short-Term Cash Position Forecast as of{' '}
                {shortTermCashPosition[2]}
              </Label>
            </WidgetHeader>

            <div
              style={{
                height: '19.7rem',
                display: 'flex',
                paddingTop: '1rem',
                background: `${colors.containerBackground}`,
              }}
            >
              <ResponsiveLine
                data={shortTermCashPosition[3]}
                margin={{ top: 40, right: 50, bottom: 50, left: 100 }}
                colors={colorList}
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                enableGridY={false}
                enablePoints={false}
                areaOpacity={1}
                xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false }}
                xFormat='time:%b-%Y'
                yScale={{
                  type: 'linear',
                  min: shortTermCashPosition[1],
                  max: shortTermCashPosition[0],
                }}
                yFormat={(v) => amountFormatter(v, 2)}
                axisBottom={{
                  format: '%b-%Y',
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 10,
                  tickValues: 'every 2 months',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 10,
                  format: (v) => amountFormatter(v, 0),
                }}
                // needed for interactivity
                useMesh={true}
                legends={[
                  {
                    dataForm: 'keys',
                    anchor: 'top',
                    direction: 'row',
                    translateX: 20,
                    translateY: -40,
                    itemsSpacing: 2,
                    itemWidth: 110,
                    itemHeight: 30,
                    itemDirection: 'left-to-right',
                    symbolSize: 10,
                    symbolShape: 'square',
                  },
                ]}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: '#a9acbe',
                        strokeWidth: 1,
                      },
                    },
                  },
                  tooltip: {
                    container: { color: colors.boldText, fontWeight: 700 },
                  },
                }}
              />
            </div>
          </>
        )}
    </CardDiv>
  );
};

export default CashPositionForecastWidget;
