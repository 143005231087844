import React from 'react';
import {
  MessageText,
  BorderComponentContainer,
  FlexColumn,
  Label,
  RegularButton,
  InfoWidthContainer,
  FormWidthContainer,
} from '../../styled-components';
import { Icon } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import colors from '../../../utils/colors';
import styled from 'styled-components';

const MultiFactorAuthComponent = ({ openResetDialog, ...props }) => {
  return (
    <>
      <InfoWidthContainer>
        <MessageText margin='0 0 10px 0' fontSize='1.5rem'>
          Multi-Factor Authentication (MFA)
        </MessageText>
        <MessageText>Click Reset MFA to reset your MFA.</MessageText>
      </InfoWidthContainer>
      <FormWidthContainer>
        <BorderComponentContainer padding='2rem'>
          <FlexDiv>
            <FlexColumn>
              <Label>Reset MFA</Label>
              <IconContainer>
                <Icon
                  icon={iconValue.info}
                  iconSize={16}
                  color={colors.primary}
                  style={{ padding: '0 10px' }}
                />
                <MessageText
                  fontSize='1rem'
                  color={`${colors.black}`}
                  margin='0 10px 0 0'
                >
                  Resetting your MFA will require you to log out and log in
                  again.
                </MessageText>
              </IconContainer>
            </FlexColumn>
            <ButtonContainer>
              <RegularButton width='13rem' margin='0' onClick={openResetDialog}>
                Reset MFA
              </RegularButton>
            </ButtonContainer>
          </FlexDiv>
        </BorderComponentContainer>
      </FormWidthContainer>
    </>
  );
};

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.lightPurple};
  margin-top: 5px;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  margin-left: 20px;
`;

export default MultiFactorAuthComponent;
