import { combineReducers } from 'redux';

const allTransactions = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_TRANSACTIONS_FULFILLED':
      return action.payload.data;
    case 'FETCH_ALL_TRANSACTIONS_PENDING':
    case 'FETCH_ALL_TRANSACTIONS_REJECTED':
      return [];
    default:
      return state;
  }
};

const isAllTransactionsFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_ALL_TRANSACTIONS_PENDING':
      return true;
    case 'FETCH_ALL_TRANSACTIONS_REJECTED':
    case 'FETCH_ALL_TRANSACTIONS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const allTransactionKeywords = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_TRANSACTION_KEYWORDS_FULFILLED':
      return action.payload.data;
    case 'FETCH_TRANSACTION_KEYWORDS_PENDING':
    case 'FETCH_TRANSACTION_KEYWORDS_REJECTED':
      return [];
    default:
      return state;
  }
};

const stagingTransactions = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STAGING_TRANSACTIONS_FULFILLED':
      return action.payload.data;
    case 'FETCH_STAGING_TRANSACTIONS_REJECTED':
    case 'FETCH_STAGING_TRANSACTIONS_PENDING':
      return [];
    case 'FETCH_SINGLE_STAGING_TRANSACTION_FULFILLED':
      return state.map((stagingTransaction) => {
        if (
          stagingTransaction.transactionId ===
          action.payload.data[0].transactionId
        ) {
          return action.payload.data[0];
        }

        return stagingTransaction;
      });
    case 'FETCH_SINGLE_STAGING_TRANSACTION_REJECTED':
    case 'FETCH_SINGLE_STAGING_TRANSACTION_PENDING':
      return state;
    default:
      return state;
  }
};

const isFetchingStagingTransactions = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_STAGING_TRANSACTIONS_PENDING':
      return true;
    case 'FETCH_STAGING_TRANSACTIONS_REJECTED':
    case 'FETCH_STAGING_TRANSACTIONS_FULFILLED':
      return false;
    default:
      return state;
  }
};

const transactions = combineReducers({
  allTransactions,
  allTransactionKeywords,
  isAllTransactionsFetching,
  stagingTransactions,
  isFetchingStagingTransactions,
});

export default transactions;
