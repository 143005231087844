import React, { useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';
import { RedText, MessageText } from '../styled-components';
import { deleteNegotiatedRate } from '../../api/dashboard';
import AppToast from '../Toast';
import DialogDeleteActions from './DialogDeleteActions';

const NegotiatedRatesDeleteDialogBox = ({
  isOpen,
  handleClose,
  title,
  negotiatedRateId,
  fetchNegotiatedRates,
  singleClient,
  hasClient,
  fetchClientNegotiatedRates,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteNegotiatedRates = () => {
    setIsDeleting(true);
    deleteNegotiatedRate(negotiatedRateId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        hasClient && fetchClientNegotiatedRates(singleClient.clientId);
        !hasClient && fetchNegotiatedRates();
      })
      .catch((err) => {
        AppToast.show({
          message: err.response.data.msg
            ? err.response.data.msg
            : 'Failed to delete Negotiated Rate.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        handleClose();
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isCloseButtonShown={false}
    >
      <div style={{ padding: '30px 50px 20px' }}>
        <RedText>WARNING: This action cannot be undone.</RedText>
        <MessageText>
          Are you sure you want to delete this negotiated rate?
        </MessageText>
      </div>
      <DialogDeleteActions
        isDeleting={isDeleting}
        handleDelete={handleDeleteNegotiatedRates}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default NegotiatedRatesDeleteDialogBox;
