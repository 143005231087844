import styled from 'styled-components';
import colors from '../utils/colors';
import backgroundImage from '../assets/login-page-image.jpg';
import { Button, Card } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';

export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const LoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  margin-top: 1rem;
  border: 1px solid ${colors.lightText};
  border-radius: 10px;
  box-shadow: 0 3px 10px ${colors.lightText};
  padding: ${(props) => props.padding || '3rem 4rem 3rem'};
`;

export const LoginLogoContainer = styled.div`
  width: 33%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const LoginResponsiveContainer = styled.div`
  @media screen and (max-width: 1200px) {
    width: 85%;
  }
  @media screen and (min-width: 1201px) {
    width: 65%;
  }
`;

export const LoginWrapperStyle = styled.span`
  margin: 1rem 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InputWrapper = styled.span`
  margin: ${(props) => props.margin || '5px 0 1rem 0'};
  padding: 10px;
  border-radius: 10px;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  width: ${(props) => props.width || '271px'};
  background: ${(props) => props.background};
`;

export const TextAreaWrapper = styled.span`
  margin: ${(props) => props.margin || '5px 0 1rem 0'};
  padding: ${(props) => props.padding};
  textarea {
    min-height: ${(props) => props.height || '100px'};
    line-height: 1.5rem !important;
    overflow: hidden;
    width: ${(props) => props.width || '100%'};
    font-size: 1.2rem;
    border: 1px solid grey;
    border-radius: 10px;
    &:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    &:disabled {
      background: ${colors.greyDisabled};
      cursor: not-allowed;
    }
  }
`;

export const Input = styled.input`
  border: none;
  margin-left: 8px;
  font-size: 1.2rem;
  width: 100%;
  padding: 5px 0;
  outline: none;

  &:disabled {
    background: ${(props) => props.disabledBackground || colors.greyDisabled};
    cursor: not-allowed;
  }
`;

// this is wrapper for blueprint inputs
export const BlueprintInputWrapper = styled.div`
  .bp3-control-group {
    justify-content: space-between;
  }

  .bp3-input,
  .bp3-tag-input {
    border: ${(props) => props.border || '1px solid grey'};
    margin: 5px 0 1rem 0;
    font-size: 1.2rem;
    min-width: ${(props) => props.minWidth || '271px'};
    padding: 10px 10px 10px 20px;
    outline: none;
    border-radius: 10px;
    width: 100%;
    min-height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.background || 'transparent'};
    box-shadow: none;
    color: ${(props) => props.color || '#000'};
    cursor: ${(props) => props.cursor};

    .bp3-tag-input-values {
      input {
        font-size: 1.2rem;
      }
      .bp3-tag-remove {
        &:focus {
          outline: 0;
        }
      }
      .bp3-tag {
        background: ${(props) => !props.intent && colors.primary};
        margin-right: 10px;
        span {
          padding: 10px;
          font-size: 1rem;
          svg {
            box-shadow: none;
            border: none;
          }
        }
      }
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ComponentContainer = styled.div`
  &.open {
    width: 100%;
    min-height: 100%;
    background: ${colors.containerBackground};
    padding-left: 15rem;
    display: flex;
    justify-content: center;
    right: 0;
    transition: 0.3s;
  }

  &.close {
    width: 100%;
    min-height: 100%;
    background: ${colors.containerBackground};
    padding-left: 0;
    display: flex;
    justify-content: center;
    right: 0;
    transition: 0.3s;
  }
`;

export const ErrorMessageDiv = styled.div`
  color: ${(props) => props.color || colors.red};
  font-size: ${(props) => props.fontSize || '1.2rem'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
`;

export const AlignCenter = styled.div`
  width: ${(props) => props.width || '100%'};
  height: 100%;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  border-top: ${(props) => props.borderTop || '0'};
  border-bottom: ${(props) => props.borderBottom || '0'};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding};
`;

export const RegularButton = styled.button`
  color: ${(props) => props.color || '#fff'};
  background: ${(props) => props.background || colors.primary};
  width: ${(props) => props.width || 'fit-content'};
  font-size: ${(props) => props.fontSize || '1.25rem'};
  cursor: pointer;
  margin: ${(props) => props.margin || '10px 15px 10px 0'};
  padding: ${(props) => props.padding || '10px'};
  border: ${(props) => props.border || 'none'};
  border-radius: ${(props) => props.borderRadius || '50px'};
  display: ${(props) => props.display || 'block'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  font-weight: ${(props) => props.fontWeight || '700'};
  outline: none;

  &:hover {
    background: ${(props) => props.backgroundHover || colors.purpleHover};
    color: ${(props) => props.color || '#fff'};
    transition: 0.3s;
  }

  &:disabled {
    color: ${colors.greyPlaceholderText};
    background: ${colors.white};
    border: 1px solid ${colors.greyPlaceholderText};
    cursor: not-allowed;
  }
`;

// take styles from Regular Button unless specified here below
export const WhiteButton = styled(RegularButton)`
  font-size: ${(props) => props.fontSize || '1.25rem'};
  color: ${(props) => props.color || colors.purpleText};
  background: ${(props) => props.background || '#fff'};
  display: ${(props) => props.display || 'block'};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};

  &:hover {
    background: #fff;
    color: ${(props) => props.hoverColor || colors.purpleHover};
  }

  &:disabled {
    color: ${colors.greyPlaceholderText};
    background: ${colors.white};
    border: 1px solid ${colors.greyPlaceholderText};
    cursor: not-allowed;
  }

  @media screen and (max-width: 1299px) {
    &.compact-actions {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 1299px) {
    &.header-button {
      font-size: 0.85rem;
      width: 11rem;
    }
  }

  @media screen and (min-width: 1300px) and (max-width: 1399px) {
    &.header-button {
      font-size: 1rem;
      width: 13rem;
    }
  }

  @media screen and (min-width: 1400px) and (max-width: 1530px) {
    &.header-button {
      width: 15rem;
    }
  }

  @media screen and (max-width: 1259px) {
    &.investment-header-button {
      font-size: 0.82rem;
      width: 12rem;
    }
  }

  @media screen and (min-width: 1260px) and (max-width: 1360px) {
    &.investment-header-button {
      font-size: 0.85rem;
      width: 13rem;
    }
  }

  @media screen and (min-width: 1361px) and (max-width: 1500px) {
    &.investment-header-button {
      font-size: 1rem;
      width: 15rem;
    }
  }
`;

export const TransparentButton = styled(WhiteButton)`
  margin: ${(props) => props.margin || '0'};
  background: transparent;
  width: ${(props) => props.width || 'fit-content'};
  font-size: 1rem;
  border: 1px solid ${colors.primary};
  border-radius: ${(props) => props.borderRadius || '5px'};
  padding: ${(props) => props.padding || '10px'}

  &:disabled {
    color: ${colors.greyPlaceholderText};
    background: ${colors.white};
    border: 1px solid ${colors.greyPlaceholderText};
    cursor: not-allowed;
  }

  &:hover {
    color: ${(props) =>
      props.disabled ? colors.greyPlaceholderText : colors.purpleHover};
  }
`;

export const ActionIconButton = styled(Button)`
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
  border: 1px solid ${colors.primary};
  border-radius: ${(props) => props.borderRadius || '8px'};
  margin: ${(props) => props.margin || '0'};
  outline: none;

  &.active.active {
    background-color: ${colors.primary} !important;
  }

  &:disabled {
    border: 1px solid ${colors.greyPlaceholderText};
    cursor: not-allowed;

    svg {
      fill: ${colors.greyPlaceholderText};
    }
  }
`;

export const DisabledButton = styled.button`
  color: ${colors.greyPlaceholderText};
  background: ${colors.white};
  width: fit-content;
  font-size: 1rem;
  cursor: not-allowed;
  padding: 10px;
  border: 1px solid ${colors.greyPlaceholderText};
  border-radius: 5px;
  display: 'block';
  justify-content: 'center';
  font-weight: 700;
  opacity: 0.5;
  outline: none;

  &:hover {
    background: ${colors.white};
    color: ${colors.greyPlaceholderText};
    transition: 0.3s;
  }
`;

export const HeadingText = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  color: ${colors.boldText};
  margin: 10px 0;
`;

// use this for profile page headings or outside of login screens
export const ProfileHeadingText = styled(HeadingText)`
  font-size: 2.3rem;
  margin: ${(props) => props.margin || '0 0 10px 0'};
`;

// styles for container header text
export const HeaderText = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  color: ${colors.white};
  margin: 0 0 0 2rem;
`;

export const MessageText = styled.p`
  font-size: ${(props) => props.fontSize || '1.125rem'};
  color: ${(props) => props.color || colors.normalText};
  margin: ${(props) => props.margin || '10px 0'};
  padding: ${(props) => props.padding};
  min-width: ${(props) => props.minWidth || '160px'};
  line-height: 24px;
  border: ${(props) => props.border};
  border-left: ${(props) => props.borderLeft};
`;

export const BoldText = styled(MessageText)`
  color: ${(props) => props.color || colors.black};
  font-weight: ${(props) => props.fontWeight || '700'};
  min-width: fit-content;
  margin: ${(props) => props.margin || '0'};
`;

export const ForgotText = styled.p`
  font-size: 1.125rem;
  color: ${(props) => props.color || '#000'};
  cursor: pointer;
  width: fit-content;
  padding: ${(props) => props.padding || '0'};

  &:hover {
    color: ${(props) => props.hoverColor || colors.red};
    transition: 0.3s;
  }
`;

// text for warning/error in dialogs
export const RedText = styled.p`
  border: ${(props) => props.border || `1px solid ${colors.red}`};
  border-left: ${(props) => props.borderLeft || `5px solid ${colors.red}`};
  padding: ${(props) => props.padding || '5px'};
  color: ${(props) => props.color || colors.red};
  width: ${(props) => props.width || 'fit-content'};
`;

// text for ALERT
export const PurpleText = styled.p`
  border: 1px solid ${colors.purpleText};
  border-left: 5px solid ${colors.purpleText};
  padding: 5px;
  color: ${colors.purpleText};
`;

export const LogoBox = styled.img`
  margin: ${(props) => props.margin || '60px 0 0 35px'};
  width: ${(props) => props.width || '11rem'};
  height: ${(props) => props.height || '5rem'};
  cursor: ${(props) => props.cursor || 'normal'};

  @media screen and (max-height: 660px) {
    margin: 30px auto 60px;
  }
`;

export const Label = styled.label`
  font-size: ${(props) => props.fontSize || '1.125rem'};
  color: ${(props) => props.color || colors.boldText};
  font-weight: 700;
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => props.width};
  display: ${(props) => props.display};
  align-items: ${(props) => props.alignItems};
  padding-bottom: ${(props) => props.paddingBottom};
`;

export const LightLabel = styled.label`
  font-size: 1.125rem;
  color: ${colors.lightText};
  font-weight: 700;
  margin: ${(props) => props.margin || '0'};
`;

export const NameLink = styled(NavLink)`
  color: ${colors.purpleText};
  text-decoration: none;
  font-weight: normal;
  &:hover {
    color: ${colors.purpleHover};
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }
  width: ${(props) => props.width};
`;

export const ComponentHeader = styled.div`
  &.open {
    position: fixed;
    width: calc(100% - 15rem);
    height: 9rem;
    background: ${colors.boldText};
    display: flex;
    align-items: ${(props) => props.alignItems || 'start'};
    justify-content: space-evenly;
    flex-direction: column;
    z-index: 10;
    right: 0;
    transition: 0.3s;
  }
  &.close {
    position: fixed;
    width: 100%;
    height: 9rem;
    background: ${colors.boldText};
    display: flex;
    align-items: ${(props) => props.alignItems || 'start'};
    justify-content: space-evenly;
    flex-direction: column;
    z-index: 10;
    right: 0;
    transition: 0.3s;
  }
`;

export const ComponentBody = styled.div`
  width: 100%;
  height: 100%;
  padding: ${(props) => props.padding || '9rem 0 0 0'};
`;

export const ComponentProfileBody = styled.div`
  width: 100%;
  height: 100%;
  padding: ${(props) => props.padding || '2rem 2rem'};
`;

export const StyleColumnCell = styled.span`
  font-weight: ${(props) => props.fontWeight || '700'};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.borderRadius};
`;

export const MainSubNavContainer = styled.div`
  &.open {
    width: calc(100% - 15rem);
    position: fixed;
    z-index: 9;
    padding-top: 9rem;
    box-shadow: 0 5px 5px -3px ${colors.lightText};
    right: 0;
    transition: 0.3s;
  }

  &.close {
    width: 100%;
    position: fixed;
    z-index: 9;
    padding-top: 9rem;
    box-shadow: 0 5px 5px -3px ${colors.lightText};
    background: ${colors.containerBackground};
    right: 0;
    transition: 0.3s;
  }
`;

export const SecondarySubNavContainer = styled.div`
  &.open {
    width: 100%;
    padding-top: 2rem;
    box-shadow: 0 5px 5px -3px ${colors.lightText};
  }

  &.close {
    width: 100%;
    padding-top: 2rem;
    box-shadow: 0 5px 5px -3px ${colors.lightText};
    background: ${colors.containerBackground};
  }
`;

export const SubNavWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background: ${(props) => props.backgroundColor || colors.containerBackground};
  padding: ${(props) => props.padding || '0 32px'};
`;

export const NavItem = styled.a`
  text-decoration: none;
  color: ${colors.normalText};
  font-size: 1.125rem;
  margin-right: 20px;
  font-weight: 700;
  position: relative;

  &:hover {
    text-decoration: none;
    color: ${colors.purpleText};
  }

  &.disabled:hover {
    color: ${colors.normalText};
    cursor: not-allowed;
  }

  &.active {
    color: ${colors.purpleText};
  }

  &.active::after {
    position: absolute;
    content: '';
    background: ${colors.purpleText};
    width: 100%;
    left: 0;
    top: 110%;
    height: 3px;
  }
`;

// containers with box shadow inside container components
export const BorderComponentContainer = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  padding: ${(props) => props.padding || '1rem'};
  background: ${(props) => props.background || `${colors.white}`};
  border-top: ${(props) => props.borderTop || `1px solid ${colors.lightText}`};
  border-bottom: ${(props) =>
    props.borderBottom || `1px solid ${colors.lightText}`};
  border-left: ${(props) =>
    props.borderLeft || `1px solid ${colors.lightText}`};
  border-right: ${(props) =>
    props.borderRight || `1px solid ${colors.lightText}`};
  border-radius: ${(props) => props.borderRadius || '5px'};
  box-shadow: ${(props) =>
    props.boxShadow || `0px 3px 10px ${colors.lightText}`};
  margin-top: ${(props) => props.marginTop || '0'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  min-width: ${(props) => props.minWidth};
`;

// box in account group list, leave here in case we need to reuse this style
export const PurpleBox = styled.div`
  color: #fff;
  background: ${colors.purpleText};
  width: fit-content;
  font-size: 1rem;
  margin: 15px 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  display: block;
  justify-content: center;
`;

export const TextBox = styled.div`
  color: ${(props) => props.color || `${colors.white}`};
  background: ${(props) => props.background || `${colors.primary}`};
  width: fit-content;
  font-size: ${(props) => props.fontSize || '1rem'};
  padding: ${(props) => props.padding || '0px'};
  margin: ${(props) => props.margin || '0px'};
  border: none;
  border-radius: ${(props) => props.borderRadius || '5px'};
  display: ${(props) => props.display || 'block'};
  justify-content: center;
`;

export const InfoWidthContainer = styled.div`
  width: 40%;
  height: ${(props) => props.height};
  padding-top: ${(props) => props.paddingTop};
`;

export const FormWidthContainer = styled.div`
  width: 60%;

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const TextValue = styled.span`
  color: ${colors.normalText};
  font-size: 1.125rem;
  font-weight: 400;
  padding: ${(props) => props.padding || '0 0 0 5px'};
`;

export const ResponseMessage = styled.span`
  max-height: 200px;
  overflow-y: auto;
  display: block;
  padding-right: 25px;
  margin-top: ${(props) => props.marginTop || '25px'};
`;

/**Styled divs for widgets*/
export const WidgetContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${(props) => props.margin || '0 1rem'};
  padding: ${(props) => props.padding};
`;

export const WidgetHeader = styled.div`
  display: ${(props) => props.display || 'flex'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.lightText};
  padding: ${(props) => props.padding || '20px'};
`;

export const WidgetBody = styled.div`
  background-color: ${(props) =>
    props.backgroundColor || colors.containerBackground};
  flex: 1;
  border-top: ${(props) => props.borderTop};
  padding: ${(props) => props.padding};
`;

export const WidgetBodyHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) => props.padding || '20px'};
`;

export const WidgetRow = styled.div`
  display: ${(props) => props.display || 'inline-flex'};
  width: 100%;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  padding: 10px 20px;
`;
/**End of Styled divs for widgets*/
/**For global settings */
export const ResponsiveCenter = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'normal'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  border-bottom: ${(props) =>
    props.borderBottom || `1px solid ${colors.lightText}`};
  &:last-child {
    border: 0;
  }
  margin: ${(props) => props.margin || '0rem 0rem 2rem 0rem'};
  padding: ${(props) => props.padding || '0rem 0rem 2rem 0rem'};
  min-width: ${(props) => props.minWidth};
  @media (max-width: 1780px) {
    flex-direction: column;
  }
`;

export const GlobalSettingsDescription = styled.div`
  width: 40%;
  height: 121px;
  padding-top: 1rem;
  padding-right: 6rem;
  margin-right: 4rem;
  min-width: 475px;
  @media (max-width: 1780px) {
    min-width: 700px;
  }
`;
export const FooterContainer = styled.div`
  display: flex;
  justify-content: end;
  border-top: 1px solid ${colors.lightText};
  padding: 1rem;
`;

/**End of global settings */

//For staging Dialog title's status box
export const StatusBox = styled.div`
  color: #fff;
  background-color: ${(props) => props.backgroundColor};
  padding: 10px 7px;
  margin-left: 15px;
  border-radius: 5px;
  font-weight: 400;
`;

export const StarIcon = styled.div`
  display: inline-block;
  font-size: ${(props) => props.fontSize || '20px'};
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: ${(props) => `linear-gradient(
      90deg,
      ${colors.primary} calc(${props.starPercentage}),
      white calc(${props.starPercentage})
    )`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const CardDiv = styled(Card)`
  &.widget {
    margin: 2rem 2rem 1rem 2rem;
    padding: 0px !important;
    display: inline-flex;
    vertical-align: top;
    height: ${(props) => props.height || '375px'};
    flex-direction: column;
    color: #9d9ba3;
  }

  &.widget-summary {
    margin: 2rem 2rem 1rem 2rem;
    padding: 0px !important;
    display: inline-flex;
    vertical-align: top;
    height: ${(props) => props.height || '375px'};
    flex-direction: column;
    color: #9d9ba3;
    width: 500px;

    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      flex-basis: 500px;
      flex-grow: 1;
    }

    @media screen and (min-width: 1351px) and (max-width: 1635px) {
      width: 800px;
    }

    @media screen and (min-width: 1636px) {
      width: 45%;
    }
  }

  &.widget-monte {
    margin: 2rem 2rem 1rem 2rem;
    padding: 0px !important;
    display: inline-flex;
    vertical-align: top;
    height: 375px;
    flex-direction: column;
    color: #9d9ba3;
    width: 500px;

    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      flex-basis: 500px;
      flex-grow: 1;
    }

    @media screen and (min-width: 1351px) {
      width: 1000px;
    }
  }
`;
