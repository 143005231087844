import * as api from '../api/notes';

export const fetchNoteComments = (noteId) => ({
  type: 'FETCH_NOTE_COMMENTS',
  payload: api.fetchNoteComments(noteId),
});

export const fetchNoteCategories = () => ({
  type: 'FETCH_NOTE_CATEGORIES',
  payload: api.fetchNoteCategories(),
});

export const fetchSingleNote = (noteId) => ({
  type: 'FETCH_SINGLE_NOTE',
  payload: api.fetchSingleNote(noteId),
});

export const fetchClientNotes = (clientId) => ({
  type: 'FETCH_CLIENT_NOTES',
  payload: api.fetchClientNotes(clientId),
});

export const fetchAllNotes = () => ({
  type: 'FETCH_ALL_NOTES',
  payload: api.fetchAllNotes(),
});

export const fetchResolveNotes = () => ({
  type: 'FETCH_RESOLVE_NOTES',
  payload: api.fetchResolveNotes(),
});

export const fetchAccountNotes = (accountId) => ({
  type: 'FETCH_ACCOUNT_NOTES',
  payload: api.fetchAccountNotes(accountId),
});

export const fetchBatchNotes = (batchId) => ({
  type: 'FETCH_BATCH_NOTES',
  payload: api.fetchBatchNotes(batchId),
});
