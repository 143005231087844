import React, { useEffect, useState } from 'react';
import { ComponentBody } from '../../components/styled-components';
import { connect } from 'react-redux';
import InvestmentGroupsTable from '../../components/table/InvestmentGroupsTable';
import {
  fetchInvestmentGroups,
  fetchSingleInvestmentGroup,
} from '../../actions/investments';
import InvestmentGroupDeleteDialogBox from '../../components/dialog/investments/InvestmentGroupDeleteDialogBox';
import { deleteInvestmentGroups } from '../../api/investments';
import AppToast from '../../components/Toast';
import { Intent } from '@blueprintjs/core';
import InvestmentGroupDialog from '../../components/dialog/investments/InvestmentGroupDialog';

const InvestmentGroupsBase = ({
  appUser,
  fetchInvestmentGroups,
  investmentGroups,
  isFetching,
  ...props
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [investmentGroup, setInvestmentGroup] = useState({});
  const [selectedInvestmentGroup, setSelectedInvestmentGroup] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    fetchInvestmentGroups();
  }, [fetchInvestmentGroups]);

  const handleDeleteDialogOpen = (investmentGroup) => {
    investmentGroup.length
      ? setSelectedInvestmentGroup(investmentGroup)
      : setInvestmentGroup(investmentGroup);
    setIsDeleteOpen(true);
  };

  const handelDeleteDialogClose = () => {
    setSelectedInvestmentGroup([]);
    setInvestmentGroup({});
    setIsDeleteOpen(false);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    let groupIds =
      selectedInvestmentGroup.length > 0
        ? selectedInvestmentGroup.map(
            (investmentGroup) => investmentGroup.groupId
          )
        : [investmentGroup.groupId];
    const investment = {
      groupIds,
    };
    deleteInvestmentGroups(investment)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to delete investment groups',
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        fetchInvestmentGroups();
        setIsDeleting(false);
        handelDeleteDialogClose();
      });
  };

  const handleEditDialogOpen = (investmentGroup) => {
    setInvestmentGroup(investmentGroup);
    setIsEditOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditOpen(false);
  };

  return (
    <ComponentBody padding='5rem 2rem 3rem'>
      <InvestmentGroupsTable
        appUser={appUser}
        isFetching={isFetching}
        investmentGroups={investmentGroups}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        handleEditDialogOpen={handleEditDialogOpen}
        {...props}
      />
      <InvestmentGroupDeleteDialogBox
        isOpen={isDeleteOpen}
        title={
          // if investment is not an object use bulk title
          Object.keys(investmentGroup).length > 0
            ? 'Delete Investment Group'
            : `Delete Selected Investments Group (${selectedInvestmentGroup.length})`
        }
        investmentGroup={investmentGroup}
        handleClose={handelDeleteDialogClose}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
      />
      <InvestmentGroupDialog
        isOpen={isEditOpen}
        handleClose={handleEditDialogClose}
        investmentGroup={investmentGroup}
        title='Edit Investment Group'
      />
    </ComponentBody>
  );
};

const mapStateToProps = (state) => ({
  investmentGroups: state.investments.investmentGroups,
  isFetching: state.investments.isInvestmentGroupsFetching,
});

const InvestmentGroups = connect(mapStateToProps, {
  fetchInvestmentGroups,
  fetchSingleInvestmentGroup,
})(InvestmentGroupsBase);

export default InvestmentGroups;
