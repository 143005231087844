import React from 'react';
import styled from 'styled-components';
import DetailsComponent from './user-details/DetailsComponent';
import MultiFactorAuthComponent from './user-details/MultiFactorAuthComponent';
import PasswordComponent from './user-details/PasswordComponent';
import colors from '../../utils/colors';

const UserDetails = ({
  firstName,
  lastName,
  email,
  currentPassword,
  newPassword,
  confirmPassword,
  currentPasswordError,
  newPasswordError,
  confirmPasswordError,
  verifyPasswordError,
  cognitoError,
  showCognitoError,
  samePasswordError,
  showSamePasswordError,
  handleChangeUserDetails,
  handleResetForm,
  openResetDialog,
  handleChangePassword,
  ...props
}) => {
  return (
    <>
      <ComponentContainer>
        <ComponentWrapper>
          <DetailsComponent
            firstName={firstName}
            lastName={lastName}
            email={email}
            handleChangeUserDetails={handleChangeUserDetails}
            handleResetForm={handleResetForm}
            {...props}
          />
        </ComponentWrapper>
      </ComponentContainer>

      <ComponentContainer>
        <ComponentWrapper>
          <MultiFactorAuthComponent openResetDialog={openResetDialog} />
        </ComponentWrapper>
      </ComponentContainer>

      <ComponentContainer>
        <ComponentWrapper>
          <PasswordComponent
            currentPassword={currentPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            currentPasswordError={currentPasswordError}
            newPasswordError={newPasswordError}
            confirmPasswordError={confirmPasswordError}
            verifyPasswordError={verifyPasswordError}
            cognitoError={cognitoError}
            showCognitoError={showCognitoError}
            samePasswordError={samePasswordError}
            showSamePasswordError={showSamePasswordError}
            handleChangePassword={handleChangePassword}
            handleResetForm={handleResetForm}
          />
        </ComponentWrapper>
      </ComponentContainer>
    </>
  );
};

const ComponentContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.lightText};
  padding: 2rem 0;
`;

const ComponentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    flex-direction: column;
  }
`;

export default UserDetails;
