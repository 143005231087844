import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BorderComponentContainer } from '../../../../../../components/styled-components';
import AccountGroupDialogBox from '../../../../../../components/dialog/account-profile-dialog/AccountGroupDialogBox';
import { assignAccountGroupAssociation } from '../../../../../../api/accounts';
import AppToast from '../../../../../../components/Toast';
import { Intent } from '@blueprintjs/core';
import AccountGroupSection from '../../../../../../components/accounts/account-profile/AccountGroupSection';
import {
  fetchClientAccountGroups,
  fetchAccountGroupAssociations,
} from '../../../../../../actions/accounts';
import find from 'lodash/find';

const AccountGroupsBase = ({
  accountId,
  clientId,
  clientAccountGroups,
  accountGroupAssociations,
  appUser,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [groupAssociations, setGroupAssociations] = useState([]);
  const { fetchClientAccountGroups, fetchAccountGroupAssociations } = props;

  useEffect(() => {
    fetchClientAccountGroups(clientId);
    fetchAccountGroupAssociations(accountId);
  }, [
    fetchClientAccountGroups,
    fetchAccountGroupAssociations,
    clientId,
    accountId,
  ]);

  const openAddAccountGroupDialog = () => {
    setTitle('Create Group');
    setIsOpen(true);
  };

  const openAssignEditGroupDialog = () => {
    setTitle('Assign/Edit Groups');
    setGroupAssociations(accountGroupAssociations.map((val) => val.label));
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setTitle('');
    setGroupAssociations([]);
  };

  const handleEditGroupAssociation = async () => {
    const account = {
      groupAssociations,
    };
    const allAccountsId = clientAccountGroups.find(
      (group) => group.label === 'All Accounts'
    ).value;
    const groupAssociationIds = account.groupAssociations.map(
      (label) => find(clientAccountGroups, ['label', label]).value
    );
    //Checks if selected account group got all accounts
    //and adds it if there is no all account groups
    if (!groupAssociationIds.includes(allAccountsId))
      groupAssociationIds.push(allAccountsId);
    account.groupAssociations = groupAssociationIds;
    assignAccountGroupAssociation(account, accountId)
      .then((res) => {
        AppToast.show({
          message: res.data.msg,
          intent: Intent.SUCCESS,
        });
        fetchAccountGroupAssociations(accountId);
      })
      .catch((err) => {
        AppToast.show({
          message:
            err.response && err.response.data.msg
              ? err.response.data.msg
              : 'Failed to create group association.',
          intent: Intent.DANGER,
        });
      })
      .finally(() => closeDialog());
  };

  return (
    <BorderComponentContainer>
      <AccountGroupSection
        openAddAccountGroupDialog={openAddAccountGroupDialog}
        openAssignEditGroupDialog={openAssignEditGroupDialog}
        accountGroupAssociations={accountGroupAssociations}
        appUser={appUser}
      ></AccountGroupSection>
      <AccountGroupDialogBox
        isOpen={isOpen}
        title={title}
        handleClose={closeDialog}
        clientId={clientId}
        clientAccountGroups={clientAccountGroups}
        groupAssociations={groupAssociations}
        setGroupAssociations={setGroupAssociations}
        isAccountProfile={true}
        handleEditGroupAssociation={handleEditGroupAssociation}
      />
    </BorderComponentContainer>
  );
};

const mapStateToProps = (state) => ({
  clientAccountGroups: state.accounts.clientAccountGroups,
  accountGroupAssociations: state.accounts.accountGroupAssociations,
});

const AccountGroups = connect(mapStateToProps, {
  fetchClientAccountGroups,
  fetchAccountGroupAssociations,
})(AccountGroupsBase);

export default AccountGroups;
