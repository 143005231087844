import axios from 'axios';
import { setHeader } from './index';

const USER_API = `${process.env.REACT_APP_GATEWAY_ENDPOINT}/users`;

export const fetchAllUsers = async () => {
  const request = await setHeader(axios);
  return request.get(`${USER_API}/all`);
};

export const fetchSingleUser = async (userId) => {
  const request = await setHeader(axios);
  return request.get(`${USER_API}/${userId}`);
};

export const createUser = async (user) => {
  const request = await setHeader(axios);
  return request.post(`${USER_API}/add`, { user });
};

export const updateUser = async (id, user) => {
  const request = await setHeader(axios);
  return request.put(`${USER_API}/${id}`, { user });
};

export const updateUserProfile = async (id, user) => {
  const request = await setHeader(axios);
  return request.put(`${USER_API}/profile/${id}`, { user });
};

export const resetMfa = async (email) => {
  const request = await setHeader(axios);
  return request.put(`${USER_API}/reset-mfa/${email}`);
};

export const fetchUserClientAssociation = async (userId) => {
  const request = await setHeader(axios);
  return request.get(`${USER_API}/client-association/${userId}`);
};

export const fetchUserList = async () => {
  const request = await setHeader(axios);
  return request.get(`${USER_API}/list`);
};

export const resendCode = async (email) => {
  const request = await setHeader(axios);
  return request.post(`${USER_API}/resend-invite/${email}`);
};
