import React from 'react';
import { AlignCenter, FlexColumn } from '../../styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ModalTextField from '../../formik-text-fields/ModalTextField';
import FormActions from '../FormActions';
import SelectFieldFormik from '../../select-fields/SelectFieldFormik';
import { yearsList } from '../../../utils/constantsList';
import { find } from 'lodash';

const transactionSchema = Yup.object().shape({
  amount: Yup.number('Field must be a number.'),
  transactionDescription: Yup.string(),
  keyword: Yup.string(),
  transactionDate: Yup.string()
    .matches(/^\d*$/, 'Field must be a number.')
    .length(4, 'Must be four digit'),
});

const BulkEditTransactionForm = ({
  handleBulkEditTransaction,
  handleClose,
  keywords,
}) => {
  const transactionDescription = '';
  const amount = '';
  const transactionDate = '';
  const keyword = '';

  return (
    <>
      <AlignCenter flexDirection='column' padding='30px 0 20px 0'>
        <Formik
          initialValues={{
            transactionDescription,
            transactionDate,
            amount,
            keyword,
          }}
          validationSchema={transactionSchema}
          onSubmit={(transaction, actions) => {
            transaction.transactionDate = Number(transaction.transactionDate);
            handleBulkEditTransaction(transaction, actions);
          }}
          validateOnBlur={false}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <FlexColumn>
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='transactionDate'
                    value={values.transactionDate}
                    items={yearsList}
                    labelName='Transaction Year'
                  />
                </FlexColumn>
                <ModalTextField
                  type='text'
                  name='transactionDescription'
                  placeholder='Transaction Description'
                  label='Transaction Description'
                  value={values.transactionDescription || ''}
                  autoComplete='off'
                />
              </AlignCenter>

              <AlignCenter justifyContent='space-evenly' padding='20px 0'>
                <FlexColumn>
                  <SelectFieldFormik
                    setFieldValue={setFieldValue}
                    name='keyword'
                    value={
                      values.keyword !== ''
                        ? find(keywords, ['value', values.keyword]).label
                        : ''
                    }
                    items={keywords}
                    labelName='Transaction Keyword'
                  />
                </FlexColumn>
                <FlexColumn>
                  <ModalTextField
                    type='number'
                    name='amount'
                    placeholder='Amount'
                    label='Amount'
                    value={values.amount}
                    autoComplete='off'
                  />
                </FlexColumn>
              </AlignCenter>
              <FormActions
                isSubmitting={isSubmitting}
                onClose={handleClose}
                saveText='UPDATE ALL'
              />
            </Form>
          )}
        </Formik>
      </AlignCenter>
    </>
  );
};

export default BulkEditTransactionForm;
