import React, { useState, useRef, useCallback } from 'react';
import { InputGroup, Button, Icon, Tooltip, Checkbox } from '@blueprintjs/core';
import iconValue from '../../../utils/icons';
import {
  AlignCenter,
  BoldText,
  TransparentButton,
  Label,
  MessageText,
} from '../../styled-components';
import colors from '../../../utils/colors';
import TransactionFilterDialogBox from '../../dialog/table-filter/TransactionFilterDialogBox';
import BatchesFilterDialogBox from '../../dialog/table-filter/BatchesFilterDialogBox';
import { checkPermissions } from '../../../utils/functions';
import permissions from '../../../utils/permissions';
import InvestmentsFilterDialogBox from '../../dialog/table-filter/InvestmentsFilterDialogBox';
import InvestmentGroupsFilterDialogBox from '../../dialog/table-filter/InvestmentGroupsFilterDialogBox';
import ValidationHistoryFilterDialogBox from '../../dialog/table-filter/ValidationHistoryFilterDialogBox';
import NegotiatedRateFilterDialogBox from '../../dialog/table-filter/NegotiatedRateFilterDialogBox';
import NotesFilterDialogBox from '../../dialog/table-filter/NotesFilterDialogBox';
import AccountGroupsFilterDialogBox from '../../dialog/table-filter/AccountGroupsFilterDialogBox';
import AccountFilterDialogBox from '../../dialog/table-filter/AccountFilterDialogBox';
import StagingInvestmentsFilterDialogBox from '../../dialog/table-filter/StagingInvestmentsFilterDialogBox';
import MonteCarloFilterDialogBox from '../../dialog/table-filter/MonteCarloFilterDialogBox';
import StepOneAnalysisFilterDialogBox from '../../dialog/table-filter/StepOneAnalysisFilterDialogBox';
import { TableFilterWrapper } from './table-styled-components';
import CashVestHistoryFilterDialogBox from '../../dialog/table-filter/CashVestHistoryFilterDialogBox';
import StagingBalancesFilterDialogBox from '../../dialog/table-filter/StagingBalancesFilterDialogBox';
import StagingFeesTableFilterDialogBox from '../../dialog/table-filter/StagingFeesTableFilterDialogBox';
import StagingAccountBalanceFilterDialogBox from '../../dialog/table-filter/StagingAccountBalanceFilterDialogBox';
import { useAsyncDebounce } from 'react-table';

const GlobalFilter = ({
  appUser,
  groupId,
  resetInitialFilter,
  setFilter,
  setAllFilters,
  globalFilter,
  setGlobalFilter,
  tableTitle,
  selectedRows,
  openBulkEditDialog,
  handleDeleteDialogOpen,
  handleBulkDownload,
  startBulkReports,
  isBulkFetching,
  validate,
  handleValidateBatchAccount,
  checkIgnore,
  handleValidateTransaction,
  handleInvalidateTransaction,
  removeMultipleFiles,
  toggleAllowOverwrite,
  investments,
  initialData,
  handleBulkDownloadInvestment,
  setFilterSummary,
  rangeFilter,
  openAddKeywords,
  handleOpenAddDialog,
  handleAddDialogOpen,
  openAddNoteCategories,
  isDashboard,
  handleResolveDialogOpen,
  handleRestoreDialogOpen,
  handleChangeShow,
  isShow,
  hiddenColumns,
  userClients,
  showUsersClients,
  isCurrentInvestments,
  maturityLimit,
  isStagingInvestment,
  handleValidateInvestment,
  handleInvalidateInvestment,
  fromMaturingInvestmentsWidget,
  handleOpenRunAnalysisDialog,
  isModelingForecastWidget,
  handleCheckAccountValidation,
  handleSetStepToRun,
  isTreasuryServices,
  dynamicHeaders,
  handleBulkInspectDialogOpen,
  handleBulkEditStagingTable,
  handleValidateStagingTable,
  handleInvalidateStagingTable,
  handleGenerateReportDialogOpen,
  handleStartIngestionDialogOpen,
  clientReportStatus,
  handleOpenTemplate,
  handleOpenExtractionWarningDialog,
  updatePaginate,
  pageSize,
  pageIndex,
  setResetInitialFilter,
  isServerSidePagination,
  getFilters,
  setGetFilters,
  sortOrder,
  prevSort,
  moreNextPrev,
  setMoreNextPrev,
  runFilterPaginate,
  setRunFilterPaginate,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(globalFilter);
  // if user types only run update paginate from below and not from filter dialog
  const stopFunc = useRef(false);
  const prevSearchTerm = useRef();

  // for server side paginated tables, use debounce to call backend when search bar is used
  const searchQuery = useAsyncDebounce((getFilters, searchTerm, sortOrder) => {
    if (tableTitle === 'All Investments' || tableTitle === 'Client Investments')
      setResetInitialFilter(true);
    updatePaginate({ pageSize, pageIndex }, getFilters, searchTerm, sortOrder);
    prevSearchTerm.current = searchTerm;
    setRunFilterPaginate(false);
  }, 800);

  // need to set stopFunc to false if the user types and does another table function (sort, filter etc)
  const checkSearchValue = useCallback(
    (value) => {
      if (prevSearchTerm.current === value) {
        stopFunc.current = false;
      }
    },
    [prevSearchTerm, stopFunc]
  );

  const handleClear = () => {
    setGlobalFilter('');
    setSearchTerm('');
    searchQuery(getFilters, '', sortOrder);
    stopFunc.current = false;
  };

  const crossButton = (
    <>
      <Button icon={iconValue.cross} minimal={true} onClick={handleClear} />
    </>
  );
  const handleOpenFilter = () => {
    setIsOpen(true);
  };

  const handleCloseFilter = () => {
    setIsOpen(false);
  };
  return (
    <>
      {tableTitle && (
        <AlignCenter
          justifyContent='space-between'
          padding='20px'
          style={{
            border: `1px solid ${colors.lightText}`,
            borderBottom: 0,
            background: `${colors.white}`,
          }}
        >
          {isModelingForecastWidget && (
            <div>
              <Label>{tableTitle}</Label>
              <MessageText margin='10px 0 0 0'>
                Maximum Negative Net Change in Cash
              </MessageText>
            </div>
          )}
          {!isModelingForecastWidget && <BoldText>{tableTitle}</BoldText>}

          {tableTitle === 'Transactions' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.DELETE_TRANSACTIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleDeleteDialogOpen(selectedRows)}
                  disabled={!selectedRows.length}
                  className='compact-actions'
                >
                  Bulk Delete
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.EDIT_TRANSACTIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => openBulkEditDialog(selectedRows)}
                  disabled={!selectedRows.length}
                  className='compact-actions'
                >
                  Bulk Edit
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.DOWNLOAD_FILES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleBulkDownload(selectedRows)}
                  disabled={!selectedRows.length}
                  className='compact-actions'
                >
                  Bulk Download
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <TransactionFilterDialogBox
                isOpen={isOpen}
                title={'Set Filters'}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
                updatePaginate={updatePaginate}
                pageSize={pageSize}
                pageIndex={pageIndex}
                searchTerm={searchTerm}
                setGetFilters={setGetFilters}
                sortOrder={sortOrder}
                stopFunc={stopFunc.current}
                prevSearchTerm={prevSearchTerm.current}
                prevSort={prevSort}
                checkSearchValue={checkSearchValue}
                moreNextPrev={moreNextPrev}
                setMoreNextPrev={setMoreNextPrev}
                runFilterPaginate={runFilterPaginate}
                setRunFilterPaginate={setRunFilterPaginate}
              />
            </div>
          )}
          {tableTitle === 'Files to Upload' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '16px',
              }}
            >
              <Checkbox
                label='Allow Overwrite'
                large={true}
                onChange={() => toggleAllowOverwrite()}
              />
              <TransparentButton
                onClick={() => removeMultipleFiles(selectedRows)}
                disabled={!selectedRows.length}
              >
                Bulk Clear Files
              </TransparentButton>
            </div>
          )}
          {tableTitle === 'Batches Ready for Files' && (
            <>
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <BatchesFilterDialogBox
                isOpen={isOpen}
                title={'Set Filters'}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
              />
            </>
          )}

          {tableTitle === 'Staging Transactions' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.INVALIDATE_STAGING_TRANSACTIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleInvalidateTransaction(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Invalidate
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.VALIDATE_STAGING_TRANSACTIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleValidateTransaction(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Validate
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.EDIT_STAGING_TRANSACTIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => openBulkEditDialog(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Edit
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.DELETE_STAGING_TRANSACTIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleDeleteDialogOpen(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Delete
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <TransactionFilterDialogBox
                isOpen={isOpen}
                title={'Set Filters'}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
                updatePaginate={updatePaginate}
                pageSize={pageSize}
                pageIndex={pageIndex}
                searchTerm={searchTerm}
                setGetFilters={setGetFilters}
                sortOrder={sortOrder}
                stopFunc={stopFunc.current}
                prevSearchTerm={prevSearchTerm.current}
                checkSearchValue={checkSearchValue}
                moreNextPrev={moreNextPrev}
                setMoreNextPrev={setMoreNextPrev}
                runFilterPaginate={runFilterPaginate}
                setRunFilterPaginate={setRunFilterPaginate}
              />
            </div>
          )}

          {tableTitle === 'Inspect Staging Transactions' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.EDIT_STAGING_TRANSACTIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => openBulkEditDialog(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Edit
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <TransactionFilterDialogBox
                isOpen={isOpen}
                title={'Set Filters'}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
                updatePaginate={updatePaginate}
                pageSize={pageSize}
                pageIndex={pageIndex}
                searchTerm={searchTerm}
                setGetFilters={setGetFilters}
                sortOrder={sortOrder}
                stopFunc={stopFunc.current}
                prevSearchTerm={prevSearchTerm.current}
                checkSearchValue={checkSearchValue}
                moreNextPrev={moreNextPrev}
                setMoreNextPrev={setMoreNextPrev}
                runFilterPaginate={runFilterPaginate}
                setRunFilterPaginate={setRunFilterPaginate}
              />
            </div>
          )}

          {tableTitle.endsWith('Investments') && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.VALIDATE_STAGING_INVESTMENTS,
              ]) &&
                tableTitle === 'Staging Investments' && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={() => {
                      handleValidateInvestment(selectedRows);
                    }}
                    disabled={!selectedRows.length}
                    className='compact-actions'
                  >
                    Validate
                  </TransparentButton>
                )}
              {checkPermissions(appUser.permList, [
                permissions.VALIDATE_STAGING_INVESTMENTS,
              ]) &&
                tableTitle === 'Staging Investments' && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={() => {
                      handleInvalidateInvestment(selectedRows);
                    }}
                    disabled={!selectedRows.length}
                    className='compact-actions'
                  >
                    Invalidate
                  </TransparentButton>
                )}
              {((groupId &&
                checkPermissions(appUser.permList, [
                  permissions.REMOVE_INVESTMENT_GROUP_ASSOCIATIONS,
                ])) ||
                checkPermissions(appUser.permList, [
                  permissions.DELETE_INVESTMENTS,
                ])) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => {
                    handleDeleteDialogOpen(selectedRows);
                  }}
                  disabled={!selectedRows.length}
                  className='compact-actions'
                >
                  {groupId ? 'Bulk Remove' : 'Bulk Delete'}
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.DOWNLOAD_FILES,
              ]) &&
                tableTitle !== 'Staging Investments' && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={() => {
                      handleBulkDownloadInvestment(selectedRows);
                    }}
                    disabled={!selectedRows.length}
                    className='compact-actions'
                  >
                    Bulk Download
                  </TransparentButton>
                )}
              <TransparentButton
                margin='0 10px 0 0'
                onClick={handleChangeShow}
                display='flex'
                style={{ alignItems: 'center' }}
                className='compact-actions'
              >
                {isShow ? 'Hide' : 'Show all'}
                <Tooltip content={hiddenColumns} style={{ width: '25rem' }}>
                  <Icon
                    icon={iconValue.info}
                    iconSize={12}
                    color={colors.primary}
                    style={{ marginLeft: '5px', display: 'flex' }}
                  />
                </Tooltip>
              </TransparentButton>
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              {!isStagingInvestment && (
                <InvestmentsFilterDialogBox
                  isOpen={isOpen}
                  title={'Set Filters'}
                  handleClose={handleCloseFilter}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  investments={initialData}
                  setFilterSummary={setFilterSummary}
                  rangeFilter={rangeFilter}
                  resetInitialFilter={resetInitialFilter}
                  userClients={userClients}
                  showUsersClients={showUsersClients}
                  isCurrentInvestments={isCurrentInvestments}
                  maturityLimit={maturityLimit}
                  fromMaturingInvestmentsWidget={fromMaturingInvestmentsWidget}
                  updatePaginate={updatePaginate}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  setResetInitialFilter={setResetInitialFilter}
                  searchTerm={searchTerm}
                  setGetFilters={setGetFilters}
                  sortOrder={sortOrder}
                  stopFunc={stopFunc.current}
                  prevSearchTerm={prevSearchTerm.current}
                  prevSort={prevSort}
                  checkSearchValue={checkSearchValue}
                  moreNextPrev={moreNextPrev}
                  setMoreNextPrev={setMoreNextPrev}
                  {...props}
                />
              )}
              {isStagingInvestment && (
                <StagingInvestmentsFilterDialogBox
                  isOpen={isOpen}
                  title={'Set Filters'}
                  handleClose={handleCloseFilter}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  setFilterSummary={setFilterSummary}
                  investments={initialData}
                  updatePaginate={updatePaginate}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  searchTerm={searchTerm}
                  setGetFilters={setGetFilters}
                  sortOrder={sortOrder}
                  stopFunc={stopFunc.current}
                  prevSearchTerm={prevSearchTerm.current}
                  prevSort={prevSort}
                  checkSearchValue={checkSearchValue}
                  moreNextPrev={moreNextPrev}
                  setMoreNextPrev={setMoreNextPrev}
                  runFilterPaginate={runFilterPaginate}
                  setRunFilterPaginate={setRunFilterPaginate}
                />
              )}
            </div>
          )}
          {tableTitle === 'Manage Keywords' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.DELETE_KEYWORDS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => {
                    handleDeleteDialogOpen(selectedRows);
                  }}
                  disabled={!selectedRows.length}
                >
                  Delete
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.ADD_KEYWORDS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={openAddKeywords}
                >
                  Add Keyword
                </TransparentButton>
              )}
            </div>
          )}
          {tableTitle === 'Manage Keyword Categories' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.DELETE_KEYWORDS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => {
                    handleDeleteDialogOpen(selectedRows);
                  }}
                  disabled={!selectedRows.length}
                >
                  Deactivate
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.ADD_KEYWORDS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={openAddKeywords}
                >
                  Add Category
                </TransparentButton>
              )}
            </div>
          )}
          {tableTitle === 'Manage Note Categories' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.DEACTIVATE_NOTE_CATEGORIES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => {
                    handleDeleteDialogOpen(selectedRows);
                  }}
                  disabled={!selectedRows.length}
                >
                  Deactivate
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.ADD_NOTE_CATEGORIES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={openAddNoteCategories}
                >
                  Add Category
                </TransparentButton>
              )}
            </div>
          )}
          {tableTitle === 'Manage Quote Types' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.DEACTIVATE_QUOTE_TYPES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleDeleteDialogOpen(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Deactivate
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.ADD_QUOTE_TYPES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={handleOpenAddDialog}
                >
                  Add Quote Type
                </TransparentButton>
              )}
            </div>
          )}

          {isTreasuryServices && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.EDIT_STAGING_FEES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleBulkEditStagingTable(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Edit
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.INVALIDATE_STAGING_BALANCES,
                permissions.INVALIDATE_STAGING_FEES,
                permissions.INVALIDATE_STAGING_ACCOUNT_BALANCES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleInvalidateStagingTable(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Invalidate
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.VALIDATE_STAGING_BALANCES,
                permissions.VALIDATE_STAGING_FEES,
                permissions.VALIDATE_STAGING_ACCOUNT_BALANCES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleValidateStagingTable(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Validate
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              {tableTitle === 'Balance' && (
                <StagingBalancesFilterDialogBox
                  isOpen={isOpen}
                  dynamicHeaders={dynamicHeaders}
                  initialData={initialData}
                  handleClose={handleCloseFilter}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  setFilterSummary={setFilterSummary}
                />
              )}
              {tableTitle === 'Fees' && (
                <StagingFeesTableFilterDialogBox
                  isOpen={isOpen}
                  dynamicHeaders={dynamicHeaders}
                  initialData={initialData}
                  handleClose={handleCloseFilter}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  setFilterSummary={setFilterSummary}
                />
              )}
              {tableTitle === 'Account Balance' && (
                <StagingAccountBalanceFilterDialogBox
                  isOpen={isOpen}
                  dynamicHeaders={dynamicHeaders}
                  initialData={initialData}
                  handleClose={handleCloseFilter}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  setFilterSummary={setFilterSummary}
                />
              )}
            </div>
          )}

          {tableTitle === 'Current Accounts' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.REMOVE_ACCOUNT_GROUP_ASSOCIATIONS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleDeleteDialogOpen(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  {tableTitle === 'Current Accounts'
                    ? 'Bulk Remove'
                    : 'Bulk Delete'}
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <AccountFilterDialogBox
                isOpen={isOpen}
                title='Set Filters'
                handleClose={handleCloseFilter}
                initialData={initialData}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
              />
            </div>
          )}

          {tableTitle === 'These accounts require additional setup' &&
            checkPermissions(appUser.permList, [
              permissions.DELETE_ACCOUNTS,
            ]) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleDeleteDialogOpen(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Bulk Delete
                </TransparentButton>
              </div>
            )}

          {(tableTitle === 'Investment Groups' ||
            tableTitle === 'Account Groups') && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {((tableTitle === 'Investment Groups' &&
                checkPermissions(appUser.permList, [
                  permissions.DELETE_INVESTMENT_GROUPS,
                ])) ||
                (tableTitle === 'Account Groups' &&
                  checkPermissions(appUser.permList, [
                    permissions.DELETE_ACCOUNT_GROUPS,
                  ]))) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => {
                    handleDeleteDialogOpen(selectedRows);
                  }}
                  disabled={!selectedRows.length}
                >
                  Bulk Delete
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              {tableTitle === 'Account Groups' && (
                <AccountGroupsFilterDialogBox
                  isOpen={isOpen}
                  title='Set Filters'
                  handleClose={handleCloseFilter}
                  initialData={initialData}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  setFilterSummary={setFilterSummary}
                />
              )}
              {tableTitle === 'Investment Groups' && (
                <InvestmentGroupsFilterDialogBox
                  isOpen={isOpen}
                  title={'Set Filters'}
                  initialData={initialData}
                  handleClose={handleCloseFilter}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  investments={investments}
                  setFilterSummary={setFilterSummary}
                />
              )}
            </div>
          )}

          {(tableTitle === 'Notes' || tableTitle === 'Resolved Notes') && (
            <>
              {tableTitle === 'Notes' &&
                checkPermissions(appUser.permList, [permissions.ADD_NOTES]) && (
                  <TransparentButton
                    margin='0 0 0 15px'
                    onClick={handleAddDialogOpen}
                  >
                    <Icon
                      icon={iconValue.annotation}
                      iconSize={16}
                      color={colors.primary}
                    />
                  </TransparentButton>
                )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {tableTitle === 'Resolved Notes' &&
                  checkPermissions(appUser.permList, [
                    permissions.DELETE_NOTES,
                  ]) && (
                    <TransparentButton
                      margin='0 10px 0 0'
                      onClick={() => {
                        handleDeleteDialogOpen(selectedRows);
                      }}
                      disabled={!selectedRows.length}
                    >
                      Delete
                    </TransparentButton>
                  )}
                {((tableTitle === 'Notes' &&
                  checkPermissions(appUser.permList, [
                    permissions.RESOLVE_NOTES,
                  ])) ||
                  checkPermissions(appUser.permList, [
                    permissions.RESTORE_NOTES,
                  ])) && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={() => {
                      tableTitle === 'Notes'
                        ? handleResolveDialogOpen(selectedRows)
                        : handleRestoreDialogOpen(selectedRows);
                    }}
                    disabled={!selectedRows.length}
                  >
                    {tableTitle === 'Notes' ? 'Resolve Notes' : 'Restore Notes'}
                  </TransparentButton>
                )}
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={handleOpenFilter}
                >
                  <Icon
                    icon={iconValue.filter}
                    iconSize={16}
                    color={colors.primary}
                  />
                </TransparentButton>
                <NotesFilterDialogBox
                  isOpen={isOpen}
                  resetInitialFilter={resetInitialFilter}
                  title={'Filter Notes'}
                  initialData={initialData}
                  handleClose={handleCloseFilter}
                  setFilter={setFilter}
                  setAllFilters={setAllFilters}
                  setFilterSummary={setFilterSummary}
                  userId={appUser.userId}
                  isDashboard={isDashboard}
                  rangeFilter={rangeFilter}
                />
              </div>
            </>
          )}

          {tableTitle === 'Negotiated Rates' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.ADD_NEGOTIATED_RATES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={handleAddDialogOpen}
                >
                  Add Rate
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <NegotiatedRateFilterDialogBox
                isOpen={isOpen}
                title={'Set Filters'}
                initialData={initialData}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
              />
            </div>
          )}

          {tableTitle === 'Banks' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={handleStartIngestionDialogOpen}
                >
                  Start Ingestion
                </TransparentButton>
            </div>
          )}

          {tableTitle === 'Monté Carlo History' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.VIEW_ACCOUNT_GROUPS,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={handleCheckAccountValidation}
                  className='compact-actions'
                >
                  Check Account Validation
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.RUN_MONTE_CARLO_MODEL,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={handleOpenRunAnalysisDialog}
                  className='compact-actions'
                >
                  Run Analysis
                </TransparentButton>
              )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <MonteCarloFilterDialogBox
                isOpen={isOpen}
                title={'Set Filters'}
                initialData={initialData}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
                rangeFilter={rangeFilter}
                resetInitialFilter={resetInitialFilter}
              />
            </div>
          )}
          {(tableTitle === 'Step 1 Analysis' ||
            tableTitle === 'Step 2 Analysis') && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.VIEW_ACCOUNT_GROUPS,
              ]) &&
                tableTitle === 'Step 1 Analysis' && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={handleCheckAccountValidation}
                    className='compact-actions'
                  >
                    Check Account Validation
                  </TransparentButton>
                )}
              {checkPermissions(appUser.permList, [
                permissions.RUN_SHORT_TERM_MODEL,
              ]) &&
                tableTitle === 'Step 1 Analysis' && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={() => {
                      handleSetStepToRun(1);
                      handleOpenRunAnalysisDialog();
                    }}
                    className='compact-actions'
                  >
                    Run Analysis
                  </TransparentButton>
                )}
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <StepOneAnalysisFilterDialogBox
                isOpen={isOpen}
                initialData={initialData}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                rangeFilter={rangeFilter}
                setAllFilters={setAllFilters}
                resetInitialFilter={resetInitialFilter}
                setFilterSummary={setFilterSummary}
              />
            </div>
          )}

          {tableTitle === 'cashVest Score History' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <TransparentButton margin='0 10px 0 0' onClick={handleOpenFilter}>
                <Icon
                  icon={iconValue.filter}
                  iconSize={16}
                  color={colors.primary}
                />
              </TransparentButton>
              <CashVestHistoryFilterDialogBox
                isOpen={isOpen}
                initialData={initialData}
                handleClose={handleCloseFilter}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                setFilterSummary={setFilterSummary}
              />
            </div>
          )}

          {tableTitle === 'Manage Investment Types' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {checkPermissions(appUser.permList, [
                permissions.DEACTIVATE_INVESTMENT_TYPES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={() => handleDeleteDialogOpen(selectedRows)}
                  disabled={!selectedRows.length}
                >
                  Deactivate
                </TransparentButton>
              )}
              {checkPermissions(appUser.permList, [
                permissions.ADD_INVESTMENT_TYPES,
              ]) && (
                <TransparentButton
                  margin='0 10px 0 0'
                  onClick={handleAddDialogOpen}
                >
                  Add
                </TransparentButton>
              )}
            </div>
          )}

          {tableTitle !== 'Files to Upload' &&
            tableTitle !== 'Batches Ready for Files' && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {validate &&
                  checkPermissions(appUser.permList, [
                    permissions.VALIDATE_ACCOUNTS_IN_BATCH,
                  ]) && (
                    <TransparentButton
                      margin='0 10px 0 0'
                      onClick={() => handleValidateBatchAccount(selectedRows)}
                      disabled={!selectedRows.length}
                    >
                      Bulk Validate
                    </TransparentButton>
                  )}
                {validate && tableTitle === 'Accounts' && checkPermissions(appUser.permList, [
                  permissions.IGNORE_ACCOUNTS_IN_BATCH,
                ]) && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={() => checkIgnore(selectedRows)}
                    disabled={!selectedRows.length}
                  >
                    Bulk Ignore
                  </TransparentButton>                
                )}
                {validate && tableTitle === 'Accounts' && checkPermissions(appUser.permList, [
                  permissions.EDIT_STAGING_TRANSACTIONS,
                ]) && (
                  <TransparentButton
                    margin='0 10px 0 0'
                    onClick={() => handleBulkInspectDialogOpen(selectedRows)}
                    disabled={!selectedRows.length}
                  >
                    Bulk Inspect
                  </TransparentButton>                
                )}
                {tableTitle === 'Ingested Batches' && (
                  <>
                    <TransparentButton
                      margin='0 10px 0 0'
                      onClick={handleOpenFilter}
                    >
                      <Icon
                        icon={iconValue.filter}
                        iconSize={16}
                        color={colors.primary}
                      />
                    </TransparentButton>
                    <ValidationHistoryFilterDialogBox
                      isOpen={isOpen}
                      title={'Set Filters'}
                      initialData={initialData}
                      handleClose={handleCloseFilter}
                      setFilter={setFilter}
                      setAllFilters={setAllFilters}
                      rangeFilter={rangeFilter}
                      setFilterSummary={setFilterSummary}
                    />
                  </>
                )}
                {tableTitle === 'Reports List' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'flex-end',
                      width: '100%',
                    }}
                  >
                    {checkPermissions(appUser.permList, [
                      permissions.GENERATE_REPORTS,
                    ]) && (
                      <TransparentButton
                        margin='0 10px 0 0'
                        onClick={() =>
                          handleGenerateReportDialogOpen(selectedRows)
                        }
                        disabled={
                          clientReportStatus &&
                          clientReportStatus[0] &&
                          clientReportStatus[0].reportStatus !== 'Reporting'
                        }
                      >
                        Generate Report PDF
                      </TransparentButton>
                    )}
                  </div>
                )}
                {tableTitle === 'Templates' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'flex-end',
                      width: '100%',
                    }}
                  >
                    {checkPermissions(appUser.permList, [
                      permissions.MANAGE_TEMPLATES,
                    ]) && (
                      <TransparentButton
                        margin='0 10px 0 0'
                        onClick={() => handleOpenTemplate()}
                      >
                        Add First Cell Template
                      </TransparentButton>
                    )}
                  </div>
                )}
                {tableTitle === 'Extraction Progress' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'flex-end',
                      width: '100%',
                    }}
                  >
                    {checkPermissions(appUser.permList, [
                      permissions.MANAGE_TEMPLATES,
                    ]) && (
                      <TransparentButton
                        margin='0 10px 0 0'
                        onClick={() =>
                          handleOpenExtractionWarningDialog(selectedRows)
                        }
                      >
                        Bulk Retry Extraction
                      </TransparentButton>
                    )}
                  </div>
                )}

                {/* NEEDS TO BE AT END */}
                <TableFilterWrapper>
                  <InputGroup
                    style={{
                      width: '300px',
                      fontSize: '1.125rem',
                      paddingLeft: '32px',
                    }}
                    leftIcon={iconValue.search}
                    placeholder='Search'
                    rightElement={crossButton}
                    type='text'
                    value={
                      isServerSidePagination
                        ? searchTerm || ''
                        : globalFilter || ''
                    }
                    // if server side pagination table, when user types stopFunc set to true, set searchTerm and call query function to run updatePaginate
                    onChange={(e) => {
                      if (isServerSidePagination) {
                        stopFunc.current = true;
                        setSearchTerm(e.target.value);
                        searchQuery(getFilters, e.target.value, sortOrder);
                        checkSearchValue(e.target.value);
                      } else setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                    }}
                  />
                </TableFilterWrapper>
              </div>
            )}
        </AlignCenter>
      )}
    </>
  );
};

export default GlobalFilter;
